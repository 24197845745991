import { EntityMappingTable, ExistingEntityTable } from 'pages';
import { getQueryError, handleApiResponse, ShortOption, useAppSelector, useGetSurgeonsQuery } from 'store';
import { useFilters, useToast } from 'context';
import {
	useGetReconciliationQuery,
	useGetReconciliationRoomsQuery,
	useSetSurgeonMappingMutation,
} from 'store/services/MappingService';
import { LogoOverlay } from 'components';

export function Surgeon() {
	const toast = useToast();
	const { selectedSystem, selectedFacility } = useAppSelector((state) => state.userState);
	const { surgeons: surgeonPickables } = useFilters();
	const { data, isLoading } = useGetReconciliationQuery({
		facility_id: selectedFacility,
		healthsystem_id: selectedSystem,
		group: 'surgeons',
	});
	const { data: surgeons } = useGetSurgeonsQuery({
		healthsystem_id: selectedSystem,
	});

	const [setSurgeonMapping] = useSetSurgeonMappingMutation();

	const surgeonData = data?.unMappedEntities.map((entry) => ({
		imported_as: { id: entry.id, name: entry.name },
		date_found: entry.date_found,
		sim_level: {
			percentage: entry.similarity_score,
			closest_option: findOptionName(entry.similar_id, surgeons?.surgeons ?? [], entry.name),
		},
	}));

	const saveSurgeonMapping = async (fromSurgeon: number, toSurgeon: number) => {
		const response = await setSurgeonMapping({
			healthsystem_id: selectedSystem,
			facility_id: selectedFacility,
			method: 'save_mapping',
			fromId: fromSurgeon,
			toId: toSurgeon,
		});

		handleApiResponse(response, {
			success: (payload) => {
				!payload &&
					toast.createToast({
						title: `Surgeon mapping saved.`,
						variant: 'success',
					});
			},
			error: (payload) => {
				toast.createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
			},
		});
	};

	const createSurgeon = async (surgeon: number) => {
		const response = await setSurgeonMapping({
			healthsystem_id: selectedSystem,
			facility_id: selectedFacility,
			method: 'create',
			fromId: surgeon,
		});

		handleApiResponse(response, {
			success: () => {
				toast.createToast({
					title: `New surgeon created.`,
					variant: 'success',
				});
			},
			error: (payload) => {
				toast.createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
			},
		});
	};

	return (
		<div className='pt-4'>
			{isLoading && <LogoOverlay backgroundColor='white' />}
			<EntityMappingTable
				title='New Surgeon Mapping & Resolution'
				keepPageIndexOnLeave={'surgeon_new'}
				tooltipContent={
					'Use this table to validate the accuracy of newly found surgeon names. Small, hard to notice changes like a missing comma or an extra space can affect how blocks and other metrics are credited. We developed a similarity score so you can easily map a newly found name to an existing surgeon name. You can designate a newly found surgeon name as an actual “New Surgeon”, or, match this with any other previously mapped surgeon in the database.'
				}
				goToHelpID='accountingsurgeons'
				emptyEntityMessage='No results found for this surgeon name'
				entityCreateText='New Surgeon'
				data={surgeonData ?? []}
				entityName='Surgeon'
				saveEntityAs={(fromSurgeon, toSurgeon) => {
					saveSurgeonMapping(fromSurgeon, toSurgeon);
				}}
				createEntity={(surgeon) => {
					createSurgeon(surgeon);
				}}
				entityOptions={surgeons?.surgeons ?? []}
			/>
			<div className='pt-8'>
				<ExistingEntityTable
					title='Existing Surgeon Mapping'
					tooltipContent={'Use this table to keep track of surgeon and alias mappings.'}
					keepPageIndexOnLeave={'surgeon_existing'}
					goToHelpID='accountingsurgeons'
					entityName='Surgeon'
					aliasesData={data?.aliasEntities ?? []}
					save={saveSurgeonMapping}
					entityOptions={surgeons?.surgeons ?? []}
					pickableEntities={surgeonPickables.all}
				/>
			</div>
		</div>
	);
}

export function findOptionName(id: number | undefined, options: ShortOption[], name: string) {
	for (const option of options) {
		if (option.id === id && option.name !== name) {
			return option.name;
		}
	}
	return undefined;
}

export default Surgeon;
