// Third-party modules
import { useState } from 'react';
import { format } from 'date-fns';

import { Accordion, Button, DataTable, NumberField, Select, Timepicker } from 'components';
import { FacilityConfigAccordionItemProps, LoadingScreen, scheduleRecordTypes } from 'pages';
import { Facility, FacilityLicense, UserRole } from 'models';
import { getFacilityName } from 'utils';
import { getQueryError, handleApiResponse, useAppSelector, useSetFacilitySettingMutation, useSystem } from 'store';
import { useToast } from 'context';

// Type imports
import { useGetReconciliationQuery, useSetEntityMappingMutation } from 'store/services/MappingService';

export function FacilityScheduleAccordionItem({ facility, otherFacilities }: FacilityConfigAccordionItemProps) {
	const group = 'schedule_record_types';
	const { data: facility_data } = useSystem();
	const [setupTime, setSetupTime] = useState<number>(facility?.planned_setup_time ?? 0);
	const [cleanupTime, setCleanupTime] = useState<number>(facility?.planned_cleanup_time ?? 0);
	const { createToast } = useToast();
	const [isDirty, setIsDirty] = useState<boolean>(false);
	const [settingLoading, setSettingLoading] = useState<boolean>(false);

	const isAdmin = facility_data?.user.role === UserRole.admin;
	const { selectedSystem, selectedFacility: selectedFacilityId } = useAppSelector((state) => state.userState);
	const selectedFacility = facility_data?.facilities?.filter((f) => f.id === selectedFacilityId)[0];

	const { data, isLoading } = useGetReconciliationQuery({
		facility_id: selectedFacility?.id ?? 0,
		healthsystem_id: selectedSystem,
		group: group,
	});

	const [setFacilitySetting] = useSetFacilitySettingMutation();

	// Add a custom holiday to all facilities in the health system
	const saveSettings = async () => {
		if (facility) {
			setSettingLoading(true);
			const payload = {
				facility_id: facility?.id,
				fcots_target: facility?.fcots_target,
				addon_rate_target: facility?.addon_rate_target,
				facility_utilization_target: facility?.facility_utilization_target,
				turnover_target: facility?.turnover_target,
				fcots_timestamp: facility?.fcots_timestamp,
				fcots_window_time_start: facility?.fcots_window_time_start,
				fcots_window_time_end: facility?.fcots_window_time_end,
				sunday_rooms_count: facility?.sunday_rooms_count,
				monday_rooms_count: facility?.monday_rooms_count,
				tuesday_rooms_count: facility?.tuesday_rooms_count,
				wednesday_rooms_count: facility?.wednesday_rooms_count,
				thursday_rooms_count: facility?.thursday_rooms_count,
				friday_rooms_count: facility?.friday_rooms_count,
				saturday_rooms_count: facility?.saturday_rooms_count,
				sunday_prime_time_start: facility?.sunday_prime_time_start,
				sunday_prime_time_end: facility?.sunday_prime_time_end,
				monday_prime_time_start: facility?.monday_prime_time_start,
				monday_prime_time_end: facility?.monday_prime_time_end,
				tuesday_prime_time_start: facility?.tuesday_prime_time_start,
				tuesday_prime_time_end: facility?.tuesday_prime_time_end,
				wednesday_prime_time_start: facility?.wednesday_prime_time_start,
				wednesday_prime_time_end: facility?.wednesday_prime_time_end,
				thursday_prime_time_start: facility?.thursday_prime_time_start,
				thursday_prime_time_end: facility?.thursday_prime_time_end,
				friday_prime_time_start: facility?.friday_prime_time_start,
				friday_prime_time_end: facility?.friday_prime_time_end,
				saturday_prime_time_start: facility?.saturday_prime_time_start,
				saturday_prime_time_end: facility?.saturday_prime_time_end,
				is_primetime_strict: !!facility?.is_primetime_strict,
				holidays_included: facility?.holidays_included,
				procedure_filtering_options: facility?.procedure_filtering_options ?? [],
				planned_setup_time: setupTime ?? 0,
				planned_cleanup_time: cleanupTime ?? 0,
			};

			const response = await setFacilitySetting(payload);

			handleApiResponse(response, {
				success: (data) => {
					createToast({
						title: `The facility was successfully updated.`,
						variant: 'success',
					});
					setSettingLoading(false);
					setIsDirty(false);
				},
				error: (error) => {
					createToast({
						title: getQueryError(error),
						variant: 'error',
					});
					setSettingLoading(false);
				},
			});
		}
	};

	// TODO: replace with loading animation state one day
	if (isLoading) {
		return <LoadingScreen message='Loading...' />;
	}
	return (
		<Accordion type='multiple' itemStyle={facility?.name.includes('*') ? 'dark' : 'contained'} className='my-2'>
			<Accordion.Item value={getFacilityName(facility)}>
				<div className='grid lg:grid-cols-2 lg:w-2/4 gap-y-5 sm:grid-cols-1 sm:max-w-max'>
					<div>
						<p className='font-semibold text-h6'>Planned Setup & Cleanup</p>
						<p className='text-p3 text-gray-400 py-1 italic w-10/12'>
							Planned setup time is the amount of time added to the beginning of each case to account for room setup. Planned
							cleanup time is the amount of time added to the end of each case to account for room cleanup. Both of these
							values are (sometimes) automatically set by the EHR scheduling module and the same value should be entered here
							(i.e. if the EHR scheduling module adds 15 minutes of setup and 15 minutes of cleanup time to each case, then the
							same values should be entered here.)
						</p>
					</div>
					<div className='flex justify-between z-10'>
						<div className='mr-10 flex flex-col'>
							<p className='text-p3 text-gray-700 tracking-wider uppercase mb-1 font-secondary whitespace-nowrap'>
								Planned Setup Time (m)
							</p>
							<NumberField
								label={''}
								value={setupTime}
								min={0}
								max={100}
								step={1}
								onChange={(e) => {
									if (!e.currentTarget.value) return;
									setIsDirty(true);
									setSetupTime(Number(e.currentTarget.value));
								}}
							/>
						</div>

						<div className='flex flex-col'>
							<p className='text-p3 text-gray-700 tracking-wider uppercase mb-1 font-secondary whitespace-nowrap'>
								Planned Cleanup Time (m)
							</p>
							<NumberField
								label={''}
								value={cleanupTime}
								min={0}
								max={100}
								step={1}
								onChange={(e) => {
									if (!e.currentTarget.value) return;
									setIsDirty(true);
									setCleanupTime(Number(e.currentTarget.value));
								}}
							/>
						</div>
					</div>
				</div>
				{!facility?.name.includes('*') && isAdmin && <></>}
				<div className='flex justify-end pt-8'>
					<Button
						variant='primary'
						sizeX='md'
						sizeY='md'
						disabled={!isDirty}
						isWorking={settingLoading}
						onClick={saveSettings}
					>
						Save
					</Button>
				</div>
			</Accordion.Item>
		</Accordion>
	);
}
