import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import { Fragment, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import image from 'assets/images/empty.svg';
import { Tooltip } from 'components';

import type { ColumnDef, Row, SortingState } from '@tanstack/react-table';
import type { TableHTMLAttributes } from 'react';
import type { TooltipProps } from 'components';

declare module '@tanstack/table-core' {
	// Since we're extending this interface, it needs to have the exact same type
	// parameters as the base, even if we're not directly using them.
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	interface ColumnMeta<TData, TValue> {
		/** A string of additional classes to apply to this column's body cells. */
		bodyClass?: string;

		/** A string of additional classes to apply to all cells in this column. */
		columnClass?: string;

		/** A string of additional classes to apply to this column's footer cells. */
		footerClass?: string;

		/** A string of additional classes to apply to this column's header cells. */
		headerClass?: string;

		/** Tooltip content to apply to this column's header. */
		tooltipContent?: TooltipProps['content'];
	}
}

interface DataTableProps<TableRow> extends TableHTMLAttributes<HTMLTableElement> {
	/**
	 * The column definitions for this data table, which determine how the raw data are parsed and displayed. For more
	 * information on column definitions, see [the TanStack Tabledocumentation](https://tanstack.com/table/v8/docs/guide/column-defs).
	 */
	columns: ColumnDef<TableRow>[];

	/** An array of data points with which to populate the rows of this data table. */
	data: TableRow[];

	/** Whether to disable pagination for this data table and display all available rows at once. */
	disablePagination?: boolean;

	/** Whether to disable the "X of N items" row counter for this data table. */
	disableRowCounter?: boolean;

	/** A custom message to show in this table when it has no data to display. */
	emptyMessage?: string;

	/** Additional content or controls to display in the center area of this data table's header. */
	headerContentCenter?: React.ReactNode;

	/**
	 * The desired positioning of content in the center area of this data table's header. When set to `center`, the
	 * content will stay aligned with the center of this data table; when set to `left`, the content will align itself
	 * next to this data table's title.
	 */
	headerContentCenterAlignment?: 'center' | 'left';

	/** Additional content or controls to display to the rightmost area of this data table's header. */
	headerContentRight?: React.ReactNode;

	/**
	 * The options for how many rows should be displayed on each page of this data table. The first option will always be
	 * the default selection.
	 */
	pageSizes?: number[];

	/**
	 * A layout to display when expanding one of this data table's rows. In callback form, the row's data are supplied as
	 * the first argument, allowing them to be referenced within the subview.
	 */
	rowSubview?: React.ReactNode | ((row: Row<TableRow>) => React.ReactNode);

	/** The title to apply to this data table. */
	title: string;

	/** The tooltipContent to apply to this data table. */
	tooltipContent?: string;

	/** The subtext to display below title */
	subtext?: string;

	/** A static min height for the table, Ex: 28em */
	minHeight?: string;

	/** A static max height for the table, Ex: 80 */
	maxHeight?: string;

	/** Removes header and some borders, compressing the table to be more minimalistic. */
	minimalStyle?: boolean;

	clickableRows?: (row: TableRow) => void | undefined;

	goToHelpID?: string;

	wrapperStyle?: string;
	tableStyle?: string;
}

export function PrintableTable<TableRow>({
	title,
	tooltipContent,
	subtext,
	data,
	columns,
	minHeight,
	maxHeight,
	minimalStyle,
	clickableRows,
	goToHelpID = 'default',
	headerContentCenter,
	headerContentCenterAlignment = 'center',
	headerContentRight,
	pageSizes = [10, 25, 50],
	emptyMessage = 'This table has no data to display.',
	rowSubview,
	disablePagination = false,
	disableRowCounter = false,
	...props
}: DataTableProps<TableRow>) {
	const [sorting, setSorting] = useState<SortingState>([]);
	const [globalFilter, setGlobalFilter] = useState('');
	const [rowSelection, setRowSelection] = useState({});

	const tableContainerRef = useRef<HTMLDivElement>(null);

	const table = useReactTable({
		data,
		columns,
		state: {
			rowSelection,
			sorting,
			globalFilter,
		},
		onRowSelectionChange: setRowSelection,
		onSortingChange: setSorting,
		onGlobalFilterChange: setGlobalFilter,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: disablePagination ? undefined : getPaginationRowModel(),
	});
	const headerGroups = table.getHeaderGroups();
	const rowModel = table.getRowModel();

	// Determine if the data changes in table, it should clear the search bar.
	useEffect(() => {
		setGlobalFilter('');
		table.setPageSize(25);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<div ref={tableContainerRef} className={'bg-white rounded-sm text-p1 font-primary mt-[8rem]'}>
			<p className='font-semibold pl-2 pb-6 text-h4'>{title}</p>
			<table className='border-collapse border-spacing-0 mr-16 pt-8' {...props}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr key={headerGroup.id} className='border-b border-gray-200'>
							{headerGroup.headers.map((header) => (
								<th
									key={header.id}
									colSpan={header.colSpan}
									className={classNames(
										'h-12 p-0 px-2 first:pl-8 last:pr-8',
										'text-p3 uppercase font-semibold text-bluegray-900',
										header.column.getCanSort() && 'cursor-pointer select-none',
										header.column.columnDef.meta?.columnClass,
										header.column.columnDef.meta?.headerClass
									)}
									onClick={header.column.getToggleSortingHandler()}
								>
									{header.isPlaceholder ? null : (
										<div
											className={`inline-flex pb-6 items-center gap-1 ${
												header.column.columnDef.meta?.headerClass ? header.column.columnDef.meta?.headerClass : 'truncate'
											}`}
										>
											{flexRender(header.column.columnDef.header, header.getContext())}

											{header.column.columnDef.meta?.tooltipContent && (
												<Tooltip content={header.column.columnDef.meta.tooltipContent} contentProps={{ className: 'normal-case' }}>
													<div className='material-symbol-sm'>info</div>
												</Tooltip>
											)}
										</div>
									)}
								</th>
							))}
						</tr>
					))}
				</thead>

				<tbody>
					{rowModel.rows.length > 0 ? (
						rowModel.rows.map((row) => (
							<Fragment key={row.id}>
								<tr
									className={classNames('group border-t first:border-t-0 border-gray-100')}
									onClick={() => {
										if (clickableRows) {
											clickableRows(row.original);
										}
										return undefined;
									}}
								>
									{row.getVisibleCells().map((cell) => (
										<td
											key={cell.id}
											className={classNames(
												'h-12 p-0 px-2 first:pl-8 last:pr-8 text-p2',
												cell.column.columnDef.meta?.columnClass,
												cell.column.columnDef.meta?.bodyClass
											)}
										>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</td>
									))}
								</tr>
							</Fragment>
						))
					) : (
						<tr>
							<td colSpan={columns.length} className='p-8 text-p2 text-gray-600'>
								<div className='flex flex-col items-center gap-6'>
									<img className='w-44' alt='An empty clipboard' src={image} />
									{globalFilter ? 'No rows matched your filter.' : emptyMessage}
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
}

export { PrintableTable as default };
