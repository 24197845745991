import { useEffect, useState } from 'react';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import * as Tabs from '@radix-ui/react-tabs';
import {
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryLabel,
	VictoryLine,
	VictoryPie,
	VictoryTooltip,
	VictoryZoomContainer,
} from 'victory';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import logo from 'assets/images/branding/logo.svg';
import {
	border,
	checkLicenseBits,
	determineBarWidth,
	determineDomainPadding,
	dynamicFontSizeStyles,
	fontFamily,
	formatSecondsToTime,
	intersects,
	userIs,
} from 'utils';
import { Facility, FacilityLicense, UserRole } from 'models';
import { ArtificialFacilityConfiguration, FacilityNurseStaffingAccordionItem } from 'pages/Settings/Tabs';
import { useAppSelector, useGetDefaultStartEndDateQuery, useGetSystemsQuery, useSystem } from 'store';
import {
	Accordion,
	Button,
	ButtonStack,
	DataTable,
	Datepicker,
	DateToggle,
	ExportButton,
	Select,
	SortedColumns,
	Tooltip,
	WorkingIndicator,
} from 'components';
import { Routes as NavigationRoutes } from 'models/navigation';
import { useFilters } from 'context';
import { tabContentClassname } from 'pages/Block';
import {
	useGetLoginAnalyticsQuery,
	useGetLoginGraphQuery,
	useGetPageAnalyticsQuery,
	useGetPageGraphQuery,
	useGetUploadAnalyticsQuery,
	useGetUploadGraphQuery,
} from 'store/services/Analytics';
import { LoadingIndicator } from 'components/Select/subcomponents';
import Logo from 'assets/images/branding/logo.svg';

interface User {
	email: string;
	last_login: number; // Representing days since last login
	duration: number; // Duration on app in minutes
	logins?: number; // Optional property for logins
	visited?: number; // Optional property for pages visited
	interactions: number;
	saved_views?: number; // Optional property for saved views
	most_visited?: string; // Optional property for most visited pages
}

interface Page {
	page: string;
	visits: number; // Representing days since last login
	duration: number; // Duration on app in minutes
}

const tabTriggerClassname = classNames(
	'py-2 px-6 relative padding-x-3 text-slate-300',
	'data-active:text-sky-500 data-active:border-b-2 data-active:border-sky-500 data-active:top-[1px]'
);

const xAxisStyle = {
	axis: { stroke: border },
	axisLabel: { fontFamily: fontFamily, fontSize: 4, fontWeight: 600 },
	tickLabels: { fontFamily: fontFamily, fontSize: 4, padding: 7 },
};

export function Analytics() {
	const [currentTab, setCurrentTab] = useState('logins');
	const { selectedSystem } = useAppSelector((state) => state.userState);
	const { data: system } = useSystem();
	const healthsystem_name = system?.healthsystems.find((h) => h.id === selectedSystem)?.name;
	const [globalView, setGlobalView] = useState(selectedSystem ?? 0); // -1 if global view enabled, else contians healthsystem id
	const [printing, setPrinting] = useState(false);
	const [exportableContent, setExportableContent] = useState<any>([null]);

	// Filters
	const { dateRange, dropDowns, saveDropdown, earliest_date } = useFilters();
	const dateRangeLimit = 3;
	const [searchParams] = useSearchParams();
	const view_by_param =
		searchParams.get('view_by') ??
		(dropDowns.viewBy.value !== 'undefined' ? dropDowns.viewBy.value : undefined) ??
		'month';
	const view_by_label_pararm =
		searchParams.get('view_by_label') ??
		(dropDowns.viewBy.value !== 'undefined' ? dropDowns.viewBy.label : undefined) ??
		'Month';

	const viewBy = view_by_param;
	const viewByLabel = view_by_label_pararm;

	const generatePDF = async () => {
		// Select the div element with the id 'yourElementId'
		const element = document.getElementById('graph-container');

		if (!element) {
			return;
		}

		try {
			// Create a new jsPDF instance
			const doc = new jsPDF();

			// Options for html2canvas
			const html2canvasOptions = {
				useCORS: true, // Necessary for cross-origin images
				allowTaint: true, // Allows manipulation of the canvas
				dpi: 200, // Higher DPI for better quality
				scrollY: -window.scrollY, // Capture full content even if not visible on the screen
			};

			// Render the content in a canvas
			const canvas = await html2canvas(element, html2canvasOptions);

			// Convert the canvas to an image
			const imageData = canvas.toDataURL('image/jpeg');

			// Embed the image into the PDF
			doc.addImage(imageData, 'PNG', 10, 10, 190, 0); // Adjust these parameters as needed
			// Save the PDF
			doc.save(`${currentTab}_${globalView < 0 ? 'Merlin' : healthsystem_name}.pdf`);

			setPrinting(false);
		} catch (error) {
			console.error('Error generating PDF:', error); // keeping for easy error troubleshooting
		}
	};

	return (
		<div className='flex flex-col' id='graph-container'>
			{printing && (
				<div className='flex justify-between mb-16'>
					<div className='flex items-center'>
						<img className='m-auto h-12' src={logo} alt='The Merlin logo.' />
						<p className='text-[2em] ml-4'>Analytics - {globalView < 0 ? 'Merlin' : healthsystem_name}</p>
					</div>
				</div>
			)}
			<Tabs.Root value={currentTab} orientation='vertical'>
				<div className='flex w-full justify-between'>
					<Tabs.List aria-label='logins' className='flex border-b border-bluegray-100'>
						<Tabs.Trigger
							value='logins'
							className={tabTriggerClassname}
							onClick={() => {
								setCurrentTab('logins');
							}}
						>
							Logins
						</Tabs.Trigger>
						<Tabs.Trigger
							value='visits'
							className={tabTriggerClassname}
							onClick={() => {
								setCurrentTab('visits');
							}}
						>
							Pages Visited
						</Tabs.Trigger>
						<Tabs.Trigger
							value='uploads'
							className={tabTriggerClassname}
							onClick={() => {
								setCurrentTab('uploads');
							}}
						>
							Files Uploaded
						</Tabs.Trigger>
					</Tabs.List>

					<div className='flex items-end gap-2 justify-end'>
						{!printing && (
							<Select
								label='View By'
								options={[
									{ label: 'Daily', value: 'daily' },
									{ label: 'Week', value: 'week' },
									{ label: 'Month', value: 'month' },
									{ label: 'Quarter', value: 'quarter' },
									{ label: 'Year', value: 'year' },
								]}
								sizeX='sm'
								onChange={(opt) => {
									if (opt) {
										dropDowns.update({ ...dropDowns, viewBy: opt });
										saveDropdown({ ...dropDowns, viewBy: opt });
									}
								}}
								value={{ label: viewByLabel, value: viewBy }}
							/>
						)}
						<div className='flex text-center bg-blue-500 items-center justify-center h-9 rounded-md cursor-pointer hover:bg-blue-600'>
							<span className='text-b3 font-semibold px-6 text-white'>
								<Datepicker
									type='range'
									shortcuts
									popoverContentProps={{ side: 'left' }}
									onApplyRange={(startDate, endDate) => {
										dateRange.update({ selected: { startDate, endDate }, applied: { startDate, endDate } });
									}}
									selectedRange={[dateRange.selected.startDate, dateRange.selected.endDate]}
									defaultEndDate={new Date()}
									defaultStartDate={earliest_date}
									dateRangeLimit={dateRangeLimit}
								>
									<div>{`${format(dateRange.selected.startDate, 'M/d/yyyy')} - ${format(
										dateRange.selected.endDate,
										'M/d/yyyy'
									)}`}</div>
								</Datepicker>
							</span>
						</div>
						{!printing && (
							<>
								<div className='z-30 block'>
									<ButtonStack>
										<Button
											sizeX='md'
											sizeY='md'
											variant='primary'
											className='z-30'
											onClick={() => {
												setPrinting(true);
												setTimeout(() => {
													generatePDF();
												}, 0);
											}}
										>
											Export PDF
										</Button>
										<Button sizeX='md' sizeY='md' variant='primary' className='z-30'>
											<ExportButton no_button={true} sizeX='md' sizeY='md' variant='primary' contents={exportableContent}>
												Export CSV
											</ExportButton>
										</Button>
									</ButtonStack>
								</div>
								<div>
									<Tooltip content={'Global'}>
										<Button
											sizeX='square'
											sizeY='md'
											variant={globalView < 0 ? 'primary' : 'primary-ghost'}
											onClick={() => (globalView < 0 ? setGlobalView(selectedSystem ?? 0) : setGlobalView(-1))}
										>
											<span className='material-symbols-outlined'>globe</span>
										</Button>
									</Tooltip>
								</div>
							</>
						)}
					</div>
				</div>

				<Tabs.Content value='logins' className={tabContentClassname}>
					<LoginsBarChart stroke={'#6366f1'} group={viewBy} healthsystem_id={globalView} />
				</Tabs.Content>

				<Tabs.Content value='uploads' className={tabContentClassname}>
					<UploadsBarChart stroke={'#10b981'} group={viewBy} healthsystem_id={globalView} />
				</Tabs.Content>

				<Tabs.Content value='visits' className={tabContentClassname}>
					<VisitBarChart stroke={'#0ea5e9'} group={viewBy} healthsystem_id={globalView} />
				</Tabs.Content>
			</Tabs.Root>
			<div className='flex gap-4'>
				{' '}
				{currentTab === 'logins' && (
					<LoginTable setExportableData={setExportableContent} healthsystem_id={globalView} />
				)}{' '}
				{currentTab === 'visits' && <VisitTable setExportableData={setExportableContent} healthsystem_id={globalView} />}{' '}
				{currentTab === 'uploads' && <UploadTable setExportableData={setExportableContent} healthsystem_id={globalView} />}
			</div>
		</div>
	);
}

interface WidgetProps {
	group: string;
	stroke: string;
	healthsystem_id: number;
}

interface TableProps {
	healthsystem_id: number;
	setExportableData: (payload: any) => void; // this format can be anything
}

function Loader() {
	return (
		<div className='h-[40vh] w-full'>
			{' '}
			<div className='animate-pulse mt-10'>
				<img className='h-8 w-8 animate-bounce block mx-auto' src={Logo} alt='Merlin' />
			</div>
		</div>
	);
}

function LoginsBarChart({ group, stroke, healthsystem_id }: WidgetProps) {
	const { dateRange } = useFilters();
	const { data, isFetching } = useGetLoginGraphQuery({
		start_date: format(dateRange.applied.startDate, 'M/d/yyyy'),
		end_date: format(new Date().setDate(dateRange.applied.endDate.getDate() + 1), 'M/d/yyyy'), // Add 1 day to end dat,
		group: group,
		healthsystem_id: healthsystem_id,
	});
	const max_y_domain = Math.max(...(data?.data_points?.map((value) => value.y) ?? [10]));
	const dynamicStyles = dynamicFontSizeStyles();
	const timeAxisStyles = dynamicStyles.timeAxisStyles;

	return (
		<div className='max-w-screen-2xl m-auto cursor-grab relative'>
			{isFetching ? (
				<Loader />
			) : (
				<VictoryChart
					domainPadding={determineDomainPadding(data?.data_points.length ?? 0)}
					height={window.innerHeight / 6}
					minDomain={{ y: 0 }}
					maxDomain={{ y: max_y_domain * 1.3 }}
					padding={{ top: 5, bottom: 15, right: 0, left: 25 }}
					containerComponent={<VictoryZoomContainer zoomDimension={'x'} />}
				>
					{/* Y-Axis */}
					<VictoryAxis sortOrder='descending' dependentAxis padding={{ left: 0 }} style={timeAxisStyles} />
					{/* X-Axis */}
					<VictoryAxis style={xAxisStyle} tickFormat={(t) => `${t}`} />
					<VictoryBar
						style={{
							data: {
								fill: stroke,
							},
							labels: {
								fontSize: 5,
							},
						}}
						data={data?.data_points ?? []}
						barWidth={determineBarWidth(data?.data_points.length ?? 0) * 0.9}
						cornerRadius={2}
						labels={({ datum }) => datum.y}
					/>
				</VictoryChart>
			)}
		</div>
	);
}

function VisitBarChart({ group, stroke, healthsystem_id }: WidgetProps) {
	const { dateRange } = useFilters();
	const { data, isFetching } = useGetPageGraphQuery({
		start_date: format(dateRange.applied.startDate, 'M/d/yyyy'),
		end_date: format(new Date().setDate(dateRange.applied.endDate.getDate() + 1), 'M/d/yyyy'), // Add 1 day to end dat,
		group: group,
		healthsystem_id: healthsystem_id,
	});
	const max_y_domain = Math.max(...(data?.data_points?.map((value) => value.y) ?? [10]));
	const dynamicStyles = dynamicFontSizeStyles();
	const timeAxisStyles = dynamicStyles.timeAxisStyles;

	return (
		<div className='max-w-screen-2xl m-auto cursor-grab relative'>
			{isFetching ? (
				<Loader />
			) : (
				<VictoryChart
					domainPadding={determineDomainPadding(data?.data_points.length ?? 0)}
					height={window.innerHeight / 6}
					minDomain={{ y: 0 }}
					maxDomain={{ y: max_y_domain * 1.3 }}
					padding={{ top: 5, bottom: 15, right: 0, left: 25 }}
					containerComponent={<VictoryZoomContainer zoomDimension={'x'} />}
				>
					{/* Y-Axis */}
					<VictoryAxis sortOrder='descending' dependentAxis padding={{ left: 0 }} style={timeAxisStyles} />
					{/* X-Axis */}
					<VictoryAxis style={xAxisStyle} tickFormat={(t) => `${t}`} />
					<VictoryBar
						style={{
							data: {
								fill: stroke,
							},
							labels: {
								fontSize: 5,
							},
						}}
						data={data?.data_points ?? []}
						barWidth={determineBarWidth(data?.data_points.length ?? 0) * 0.9}
						cornerRadius={2}
						labels={({ datum }) => datum.y}
					/>
				</VictoryChart>
			)}
		</div>
	);
}

function UploadsBarChart({ group, stroke, healthsystem_id }: WidgetProps) {
	const { dateRange } = useFilters();
	const { data, isFetching } = useGetUploadGraphQuery({
		start_date: format(dateRange.applied.startDate, 'M/d/yyyy'),
		end_date: format(new Date().setDate(dateRange.applied.endDate.getDate() + 1), 'M/d/yyyy'), // Add 1 day to end dat,
		group: group,
		healthsystem_id: healthsystem_id,
	});
	const max_y_domain = Math.max(...(data?.data_points?.map((value) => value.y) ?? [10]));
	const dynamicStyles = dynamicFontSizeStyles();
	const timeAxisStyles = dynamicStyles.timeAxisStyles;

	return (
		<div className='max-w-screen-2xl m-auto overflow-x-auto cursor-grab relative'>
			{isFetching ? (
				<Loader />
			) : (
				<VictoryChart
					domainPadding={determineDomainPadding(data?.data_points.length ?? 0)}
					height={window.innerHeight / 6}
					minDomain={{ y: 0 }}
					maxDomain={{ y: max_y_domain * 1.3 }}
					padding={{ top: 5, bottom: 15, right: 0, left: 25 }}
					containerComponent={<VictoryZoomContainer zoomDimension={'x'} />}
				>
					{/* Y-Axis */}
					<VictoryAxis
						tickFormat={(t) => `${t}`}
						sortOrder='descending'
						dependentAxis
						padding={{ left: 0 }}
						style={timeAxisStyles}
					/>
					{/* X-Axis */}
					<VictoryAxis style={xAxisStyle} tickFormat={(t) => `${t}`} />
					<VictoryBar
						style={{
							data: {
								fill: stroke,
							},
							labels: {
								fontSize: 5,
							},
						}}
						data={data?.data_points ?? []}
						barWidth={determineBarWidth(data?.data_points.length ?? 3)}
						cornerRadius={1}
						labels={({ datum }) => datum.y}
					/>
				</VictoryChart>
			)}
		</div>
	);
}

function LoginTable({ healthsystem_id, setExportableData }: TableProps) {
	const [sort, setSort] = useState<SortedColumns[]>([]);
	const { dateRange } = useFilters();
	const { data, isFetching } = useGetLoginAnalyticsQuery({
		start_date: format(dateRange.applied.startDate, 'M/d/yyyy'),
		end_date: format(dateRange.applied.endDate, 'M/d/yyyy'),
		sort: sort,
		healthsystem_id: healthsystem_id,
	});

	useEffect(() => {
		setExportableData(data?.stats ?? [null]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<div className='w-full flex flex-col'>
			<div className='flex justify-between items-baseline bg-gray-50 mt-4 p-1 pb-2.5 rounded-md px-3'>
				<div className='font-semibold'>User Breakdown</div>
				<div>
					{isFetching ? (
						<div className='text-blue-500 w-12 flex justify-center h-4 align-middle items-center pt-4'>
							<LoadingIndicator />
						</div>
					) : (
						<div className='text-blue-500 opacity-0 w-12 flex justify-center h-4 align-middle items-center pt-4'>
							<LoadingIndicator />
						</div>
					)}
				</div>
			</div>
			<DataTable
				minimalStyle
				disableRowCounter
				disablePagination
				onSort={(arr) => {
					setSort(arr);
				}}
				columns={[
					{
						accessorKey: 'name',
						header: 'Name',
						enableGlobalFilter: false,
						enableSorting: false,
					},
					{
						accessorKey: 'email',
						header: 'User',
						enableGlobalFilter: false,
						enableSorting: false,
					},
					{
						accessorKey: 'healthsystem_name',
						header: 'System',
						enableGlobalFilter: false,
						enableSorting: false,
					},
					{
						accessorKey: 'days_since_last_login',
						header: 'Last Login',
						enableGlobalFilter: false,
						cell: ({ row }) => {
							return `${row.original.days_since_last_login} days`;
						},
					},
					{
						accessorKey: 'number_of_unique_days',
						header: 'Logins',
						enableGlobalFilter: false,
					},
					{
						accessorKey: 'interactions',
						header: 'Interactions',
						enableGlobalFilter: false,
					},
					{
						accessorKey: 'session_time',
						header: 'Session Duration',
						enableGlobalFilter: false,
						cell: ({ row }) => {
							return `${row.original.session_time}s`;
						},
					},
				]}
				data={data?.stats ?? []}
				title={''}
			/>
		</div>
	);
}

function UploadTable({ healthsystem_id, setExportableData }: TableProps) {
	const [sort, setSort] = useState<SortedColumns[]>([]);
	const { dateRange } = useFilters();
	const { data, isFetching } = useGetUploadAnalyticsQuery({
		start_date: format(dateRange.applied.startDate, 'M/d/yyyy'),
		end_date: format(dateRange.applied.endDate, 'M/d/yyyy'),
		sort: sort,
		healthsystem_id: healthsystem_id,
	});

	useEffect(() => {
		setExportableData(data?.stats ?? [null]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<div className='w-full flex flex-col'>
			<div className='flex justify-between items-baseline bg-gray-50 mt-4 p-1 pb-2.5 rounded-md px-3'>
				<div className='font-semibold'>Upload Receipts</div>
				<div>
					{isFetching ? (
						<div className='text-blue-500 w-12 flex justify-center h-4 align-middle items-center pt-4'>
							<LoadingIndicator />
						</div>
					) : (
						<div className='text-blue-500 opacity-0 w-12 flex justify-center h-4 align-middle items-center pt-4'>
							<LoadingIndicator />
						</div>
					)}
				</div>
			</div>
			<DataTable
				minimalStyle
				disableRowCounter
				disablePagination
				onSort={(arr) => {
					setSort(arr);
				}}
				columns={[
					{
						accessorKey: 'container_name',
						header: 'Container',
						enableGlobalFilter: false,
						enableSorting: false,
					},
					{
						accessorKey: 'uploaded_at',
						header: 'Uploaded At',
						enableGlobalFilter: false,
						enableSorting: false,
					},
					{
						accessorKey: 'filename',
						header: 'Filename',
						enableGlobalFilter: false,
						enableSorting: false,
					},
					{
						accessorKey: 'file_type',
						header: 'Type',
						enableGlobalFilter: false,
						enableSorting: false,
					},
				]}
				data={data?.stats ?? []}
				title={''}
			/>
		</div>
	);
}

function VisitTable({ healthsystem_id, setExportableData }: TableProps) {
	const [sort, setSort] = useState<SortedColumns[]>([]);
	const { dateRange } = useFilters();
	const { data, isFetching } = useGetPageAnalyticsQuery({
		start_date: format(dateRange.applied.startDate, 'M/d/yyyy'),
		end_date: format(dateRange.applied.endDate, 'M/d/yyyy'),
		sort: sort,
		healthsystem_id: healthsystem_id,
	});

	useEffect(() => {
		setExportableData(data?.stats ?? [null]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);
	return (
		<div className='w-full flex flex-col'>
			<div className='flex justify-between items-baseline bg-gray-50 mt-4 p-1 pb-2.5 rounded-md px-3'>
				<div className='font-semibold'>Page Breakdown</div>
				<div>
					{isFetching ? (
						<div className='text-blue-500 w-12 flex justify-center h-4 align-middle items-center pt-4'>
							<LoadingIndicator />
						</div>
					) : (
						<div className='text-blue-500 opacity-0 w-12 flex justify-center h-4 align-middle items-center pt-4'>
							<LoadingIndicator />
						</div>
					)}
				</div>
			</div>
			<DataTable
				minimalStyle
				disableRowCounter
				disablePagination
				onSort={(arr) => {
					setSort(arr);
				}}
				columns={[
					{
						accessorKey: 'page_url',
						header: 'Page',
						enableGlobalFilter: false,
						enableSorting: false,
					},
					{
						accessorKey: 'page_url',
						header: 'Module',
						enableGlobalFilter: false,
						enableSorting: false,
						cell: ({ row }) => <p>{row.original.page_url.split('/').at(1)}</p>,
					},
					{
						accessorKey: 'visits',
						header: 'Visits',
						enableGlobalFilter: false,
					},
					{
						accessorKey: 'interactions',
						header: 'Interactions',
						enableGlobalFilter: false,
					},
					{
						accessorKey: 'session_time',
						header: 'Session Duration',
						enableGlobalFilter: false,
					},
				]}
				data={data?.stats ?? []}
				title={''}
			/>
		</div>
	);
}

export default Analytics;
