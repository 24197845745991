/**
 * WARNING: The utilities within this file make use of development-only
 * libraries that are not included in the project in production builds. They
 * should only ever be used on developer-only pages such as the StyleGuide, and
 * never on those that could be accessed in a production environment.
 */

import { faker } from '@faker-js/faker';

/**
 * Creates a set of mock Case Detail records for use in protyping data-driven
 * components and views.
 *
 * @param recordCount The number of mock records to generate.
 * @returns An array of mock Case Detail data objects.
 */
export const generateCaseDetailRecords = (recordCount: number) =>
	[...Array(recordCount)].map(() => ({
		case_id: faker.random.numeric(5),
		date: faker.date.past(8),
		surgeon: faker.name.fullName(),
		procedure: `${faker.word.adverb()} ${faker.word.verb()}`,
		block_start: `${faker.datatype.number({ min: 1, max: 12 })}:${faker.datatype.number({ min: 10, max: 59 })}`,
		block_end: `${faker.datatype.number({ min: 1, max: 12 })}:${faker.datatype.number({ min: 10, max: 59 })}`,
		patient_in: `${faker.datatype.number({ min: 1, max: 12 })}:${faker.datatype.number({ min: 10, max: 59 })}`,
		patient_out: `${faker.datatype.number({ min: 1, max: 12 })}:${faker.datatype.number({ min: 10, max: 59 })}`,
		duration: `${faker.random.numeric(1)}h ${faker.random.numeric(2)}m`,
		turnover: `${faker.random.numeric(1)}h ${faker.random.numeric(2)}m`,
		flip_indicator: faker.datatype.boolean(),
		add_on_volume: faker.datatype.number({ min: 0, max: 99 }),
	}));

/**
 * Creates a set of mock option objects for use when prototyping layouts with
 * Select inputs.
 *
 * @param optionCount The number of mock options to generate.
 * @returns An array of mock Select option objects.
 */
export const generateSelectOptions = (optionCount: number) =>
	faker.helpers.uniqueArray(faker.random.word, optionCount).map((word) => ({
		label: word,
		value: word,
	}));

/**
 * Creates a set of mock heatmap data objects for use when prototyping layouts
 * containing Heatmap components.
 *
 * @param yValues A set of values to use for y-axis labels.
 * @param minimumValue The minimum possible randomized value to include in the x-axis dataset.
 * @param maximumValue The maximum possible randomized value to include in the x-axis dataset.
 * @returns An array of mock heatmap data objects.
 */
export const generateHeatmapData = (yValues: string[], minimumValue: number, maximumValue: number) =>
	yValues.map((yValue) => ({
		y: yValue,
		x: [...Array(24)].map(() => faker.datatype.number({ min: minimumValue, max: maximumValue })),
	}));
