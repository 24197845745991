import * as Tabs from '@radix-ui/react-tabs';
import { useEffect, useState } from 'react';
import { data } from 'cypress/types/jquery';
import classNames from 'classnames';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Dialog from 'components/Dialog';
import { tabContentClassname, tabTriggerClassname } from 'pages';
import DataTable from 'components/DataTable';
import { Button } from 'components/Button';
import TextField from 'components/TextField';
import TextArea from 'components/TextArea';
import { requireValidFieldValue } from 'utils';
import { useFilters, useToast } from 'context';
import Checkbox from 'components/Checkbox';
import Tooltip from 'components/Tooltip';

interface FiltersModalProps {
	children: React.ReactNode;
}

export const addViewSchema = yup.object({
	viewName: yup
		.string()
		.trim()
		.required('A name is required for a View')
		.test('valid', 'This field value is not valid.', requireValidFieldValue)
		.max(64, 'View name cannot be more than 100 characters.'),
	notes: yup.string().trim().max(512, 'Notes cannot be more than 512 characters.'),
	dynamic_date: yup.boolean(),
});

export type addViewFormInputs = yup.InferType<typeof addViewSchema>;

function SaveView({ children }: FiltersModalProps) {
	const [openViewModal, setOpenViewModal] = useState(false);
	const [submited, setSubmitted] = useState(false);
	const toast = useToast();

	// set up all of the form logic/handlers
	const {
		register,
		handleSubmit,
		watch,
		control,
		setValue,
		getValues,
		reset,
		formState: { errors },
	} = useForm<addViewFormInputs>({
		resolver: yupResolver(addViewSchema),
		mode: 'onChange',
		defaultValues: {
			viewName: '',
			notes: '',
			dynamic_date: true,
		},
	});

	// Filters
	const { saveCurrentView, setFilterStatus } = useFilters();

	// need this for conditional rendering of weekends in primetime settings table, otherwise weekend rows stop being rendered
	// on checkbox events. Watches all inputs and refreshes when change detected.
	watch();

	useEffect(() => {
		if (setFilterStatus === 'fulfilled' && submited) {
			toast.createToast({
				title: 'Successfully saved view',
				variant: 'success',
			});
			setSubmitted(false);
			setOpenViewModal(false);
		}
		if (setFilterStatus === 'rejected' && submited) {
			toast.createToast({
				title: 'Failed to save view',
				variant: 'error',
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setFilterStatus]);

	useEffect(() => {
		reset({
			viewName: '',
			notes: '',
			dynamic_date: true,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openViewModal]);

	return (
		<Dialog
			sizeX='sm'
			title='Save Filter View'
			subtitle={`Created On: ${new Date().toLocaleString()}`}
			trigger={children}
			open={openViewModal}
			onOpenChange={async (isBeingOpened) => {
				if (isBeingOpened) {
					setOpenViewModal(true);
					return;
				}
				if (!isBeingOpened) {
					setOpenViewModal(false);
				}
			}}
			actionButtons={[
				{
					children: 'Save View',
					onClick: () => {
						saveCurrentView(getValues().viewName, getValues().notes, getValues().dynamic_date);
						setSubmitted(true);
					},
					disabled: getValues().viewName.length < 5 ? true : false,
					isWorking: setFilterStatus === 'pending' ? true : false,
				},
				{
					children: 'Cancel',
					onClick: () => {
						setOpenViewModal(false);
					},
					disabled: setFilterStatus === 'pending' ? true : false,
				},
			]}
		>
			<div className='text-p3'>
				<TextField
					label='View Name'
					type='text'
					sizeY='md'
					defaultValue={''}
					disabled={setFilterStatus === 'pending' ? true : false}
					errorMessage={errors.viewName?.message}
					{...register('viewName')}
					className='mb-4'
				/>
				<TextArea
					label={'Notes'}
					defaultValue={''}
					disabled={setFilterStatus === 'pending' ? true : false}
					errorMessage={errors.notes?.message}
					{...register('notes')}
					className='text-gray-500 h-[6em] mb-3'
				/>
				<Checkbox
					value='dynamic_date'
					{...register('dynamic_date')}
					className='gap-1'
					label={
						<div className='flex flex-row items-center text-left'>
							<p>Dynamic Date Window</p>
							<div className='mt-1'>
								<Tooltip content='If the checkbox is selected, the date will dynamically update as new data become available'>
									<span className='material-symbol-sm text-blue-500 h-fit pl-1 mt-1'>info</span>
								</Tooltip>
							</div>
						</div>
					}
				/>
			</div>
		</Dialog>
	);
}

export default SaveView;
