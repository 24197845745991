import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { SerializedError } from '@reduxjs/toolkit';

export * from './BlockSettingsService';
export * from './BlockScheduleService';
export * from './BlockUtilizationService';
export * from './FacilityUtilizationService';
export * from './CoreService';
export * from './FacilityService';
export * from './FileService';
export * from './FilterService';
export * from './InitService';
export * from './UserService';
export * from './FacilityUtilizationService';
export * from './FilterService';
export * from './SurgeryService';
export * from './SchedulingPatternsService';
export * from './ReleasePatternsService';
export * from './AnesthesiaService';

/**
 * Shorthand util to cast the error to the right type and return the message (if there is one)
 * @param err the error
 * @returns string
 */
export function getQueryError(err: FetchBaseQueryError | SerializedError) {
	const error = err as FetchBaseQueryError;
	return typeof error.data === 'string' ? error.data : JSON.stringify(error);
}

/**
 * A function that allows quick and simple bundling of handling API endpoint responses
 * @param response the response from awaiting a mutation function
 * @param handlers the functions to call depending on success/error of the request
 */
export function handleApiResponse<ValidResponse, ErrorResponse>(
	response: { data: ValidResponse } | { error: ErrorResponse },
	handlers: {
		success: (data: ValidResponse) => void;
		error: (error: ErrorResponse) => void;
	}
) {
	if ('error' in response) {
		handlers.error(response.error);
		return;
	}

	handlers.success(response.data);
}
