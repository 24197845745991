import { ApiEndpoints } from 'models/api';
import { StaffingGridItem } from 'pages/Anesthesia/components';
import { standardApi } from 'store/api';

export interface ShortOption {
	name: string;
	id: number;
}

export const refreshTagTypes = 'Anesthesia';

export interface BlockShortOption {
	name: string;
	id: number;
	has_active_pattern?: boolean;
	had_pattern?: boolean;
}

export interface SurgeonOptionApiResponse {
	surgeons: ShortOption[];
}

export interface AnesthesiologistOptionApiResponse {
	anesthesiologists: ShortOption[];
}

export interface AnesthetistOptionApiResponse {
	anesthetists: ShortOption[];
}

export interface BlockOptionApiResponse {
	blocks: BlockShortOption[];
}

export interface ServiceLineOptionApiResponse {
	service_lines: ShortOption[];
}

export interface ProcedureOptionApiResponse {
	procedures: string[];
}

export interface EncounterTypeOptionApiResponse {
	encounter_types: ShortOption[];
}

export interface RoomOptionApiResponse {
	rooms: ShortOption[];
}

export interface HolidayOptionApiResponse {
	holidays: ShortOption[];
}

export interface DefaultStartEndDateResponse {
	start_date: string;
	end_date: string;
	earliest_date: string;
	schedule_end_date?: string;
}

export interface SavedFilter {
	filter_id?: number;
	id?: number;
	name?: string;
	notes?: string;
	created_at?: Date;
	updated_at?: Date;
	page: string;
	facility_id: number | null;
	surgeon: ShortOption | undefined;
	block: ShortOption | undefined;
	blocks: ShortOption[] | undefined;
	surgeons: ShortOption[] | undefined;
	anesthesiologists: ShortOption[] | undefined;
	anesthetists: ShortOption[] | undefined;
	rooms: ShortOption[] | undefined;
	service_lines: ShortOption[] | undefined;
	encounter_types: ShortOption[] | undefined;
	add_ons: 'All' | 'Add-Ons' | 'Scheduled' | undefined;
	primetime: 'All' | 'Primetime' | 'Non-Primetime' | undefined;
	block_time_slot: ('Full Day' | 'Morning' | 'Afternoon')[] | undefined;
	block_type: 'Scheduled' | 'Available' | undefined;
	utilization_type: 'Adjusted' | 'Raw' | undefined;
	abandoned_days_toggle: 'Included' | 'Excluded' | undefined;
	date: Date | undefined;
	percentile: number | undefined;
	date_range_start: Date | undefined;
	date_range_end: Date | undefined;
	days_of_week: ('Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday')[] | undefined;
	turnover_upper_bound: number | undefined;
	fcots_grace: number | undefined;
	dropdowns?: dropDowns | undefined;
	is_trailing_date_range?: boolean | undefined;
	heatmap_method: ShortOption;
	nurse_staff_ratios: NurseStaffRatio;
	salaries?: Salary;
	mda_cover_room?: boolean;
	procedures: string[] | undefined;
	turnover_concurrency: 'Excluded' | 'Included' | 'Only Turnover' | undefined;
	indirect_staff_window: StaffWindow;
	variable_staff_window: StaffWindow;
}

export interface dropDowns {
	viewBy: {
		label: string;
		value: string;
	};
	groupBy: {
		label: string;
		value: string;
	};
	sortBy: {
		label: string;
		value: string;
	};
	medical_ratio?: number;
	eight_hour_shift?: string;
	ten_hour_shift?: string;
	twelve_hour_shift?: string;
	fourteen_hour_shift?: string;
	carousel_day_of_week_selected?: string;
	staffing_grid?: StaffingGridItem[] | undefined;
	legend?: {
		heatmap?: boolean;
		ideal?: boolean;
		custom?: boolean;
	};
}

export interface Salary {
	anesthesiologist: number;
	anesthetist: number;
	registered_nurse: number;
	surgical_tech: number;
	variable_staff: number;
	indirect_staff: number;
}

export interface NurseStaffRatio {
	variable: number;
	indirect: number;
}

export interface StaffWindow {
	start: string;
	end: string;
}

export interface SavedFilterApiResponse {
	page: string;
	filters: SavedFilter | undefined;
}

export interface SetNewView {
	facility_id: number | null;
	filter_id: number | null;
	page: string;
	set_is_public?: boolean;
	set_is_archived?: boolean;
}

export interface SavedView {
	page: string;
	filters: {
		filter_id: number;
		name?: string;
		notes?: string;
		created_at?: Date;
		updated_at?: Date;
		page: string;
		facility_id: number | null;
		surgeon: ShortOption | undefined;
		block: ShortOption | undefined;
		author_name: string;
		author_id?: number;
		blocks: ShortOption[] | undefined;
		surgeons: ShortOption[] | undefined;
		anesthesiologists: ShortOption[] | undefined;
		anesthetists: ShortOption[] | undefined;
		rooms: ShortOption[] | undefined;
		service_lines: ShortOption[] | undefined;
		encounter_types: ShortOption[] | undefined;
		add_ons: 'All' | 'Add-Ons' | 'Scheduled' | undefined;
		primetime: 'All' | 'Primetime' | 'Non-Primetime' | undefined;
		block_time_slot: ('Full Day' | 'Morning' | 'Afternoon')[] | undefined;
		block_type: 'Scheduled' | 'Available' | undefined;
		utilization_type: 'Adjusted' | 'Raw' | undefined;
		abandoned_days_toggle: 'Included' | 'Excluded' | undefined;
		date: Date | undefined;
		percentile: number | undefined;
		date_range_start: Date | undefined;
		date_range_end: Date | undefined;
		days_of_week: ('Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday')[] | undefined;
		turnover_upper_bound: number | undefined;
		fcots_grace: number | undefined;
		dropdowns?: dropDowns | undefined;
		salaries?: Salary;
		nurse_staff_ratios?: NurseStaffRatio;
		mda_cover_room?: boolean;
		is_public: boolean | undefined;
		is_applied: boolean | undefined;
		is_archived: boolean | undefined;
		is_trailing_date_range?: boolean | undefined;
		turnover_concurrency: 'Excluded' | 'Included' | 'Only Turnover';
		indirect_staff_window: StaffWindow;
		variable_staff_window: StaffWindow;
		heatmap_method: ShortOption;
	};
}

export interface SavedViewApiResponse {
	filters: SavedView[];
}

const filterApi = standardApi.enhanceEndpoints({ addTagTypes: ['Core', 'Filter', 'Anesthesia', 'BlockPatterns'] });

/**
 * Provides endpoints for data values in database
 */
export const filterService = filterApi.injectEndpoints({
	endpoints: (build) => ({
		getDefaultStartEndDate: build.query<DefaultStartEndDateResponse, { facility_id?: number | null }>({
			query: (body) => ({
				url: ApiEndpoints.GET_DEFAULT_START_END_DATE,
				method: 'POST',
				body,
			}),
			providesTags: ['Core'],
		}),
		getSurgeonOptions: build.query<
			SurgeonOptionApiResponse,
			{ facility_id?: number | null; healthsystem_id?: number | null }
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_SURGEON_OPTIONS,
				method: 'POST',
				body,
			}),
			providesTags: ['Core'],
		}),
		getAnesthesiologistOptions: build.query<
			AnesthesiologistOptionApiResponse,
			{ facility_id?: number | null; healthsystem_id?: number | null }
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_ANESTHESIOLOGIST_OPTIONS,
				method: 'POST',
				body,
			}),
			providesTags: ['Core'],
		}),
		getAnesthetistOptions: build.query<
			AnesthetistOptionApiResponse,
			{ facility_id?: number | null; healthsystem_id?: number | null }
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_ANESTHETIST_OPTIONS,
				method: 'POST',
				body,
			}),
			providesTags: ['Core'],
		}),
		getServiceLineOptions: build.query<ServiceLineOptionApiResponse, { facility_id?: number | null }>({
			query: (body) => ({
				url: ApiEndpoints.GET_SERVICE_LINE_OPTIONS,
				method: 'POST',
				body,
			}),
			providesTags: ['Core'],
		}),
		getProcedureOptions: build.query<ProcedureOptionApiResponse, { facility_id?: number | null }>({
			query: (body) => ({
				url: ApiEndpoints.GET_PROCEDURE_OPTIONS,
				method: 'POST',
				body,
			}),
			providesTags: ['Core'],
		}),
		getAllServiceLineOptions: build.query<ServiceLineOptionApiResponse, { healthsystem_id?: number | null }>({
			query: (body) => ({
				url: ApiEndpoints.GET_ALL_SERVICE_LINE_OPTIONS,
				method: 'POST',
				body,
			}),
			providesTags: ['Core'],
		}),
		getEncounterTypeOptions: build.query<EncounterTypeOptionApiResponse, { facility_id?: number | null }>({
			query: (body) => ({
				url: ApiEndpoints.GET_ENCOUNTER_TYPE_OPTIONS,
				method: 'POST',
				body,
			}),
			providesTags: ['Core'],
		}),
		getRoomOptions: build.query<RoomOptionApiResponse, { facility_id?: number | null }>({
			query: (body) => ({
				url: ApiEndpoints.GET_ROOM_OPTIONS,
				method: 'POST',
				body,
			}),
			providesTags: ['Core'],
		}),
		getHolidayOptions: build.query<HolidayOptionApiResponse, null>({
			query: () => ({
				url: ApiEndpoints.GET_HOLIDAY_OPTIONS,
				method: 'GET',
			}),
			providesTags: ['Core'],
		}),
		getSavedFilter: build.query<SavedFilterApiResponse, { facility_id?: number | null; page: string }>({
			query: ({ facility_id, page }) => ({
				url: ApiEndpoints.GET_SAVED_FILTERS,
				method: 'GET',
				params: { facility_id, page },
			}),
			providesTags: ['Core', 'Filter'],
		}),
		getSavedView: build.query<
			SavedViewApiResponse,
			{ healthsystem_id?: number | null; facility_id?: number | null; page: string }
		>({
			query: ({ healthsystem_id, facility_id, page }) => ({
				url: ApiEndpoints.GET_SAVED_VIEWS,
				method: 'GET',
				params: { healthsystem_id, facility_id, page },
			}),
			providesTags: ['Core', 'Filter'],
		}),
		setFilter: build.mutation<
			{ name: string | undefined; updated_at: Date | undefined },
			{
				update_view?: boolean;
				set_filter?: SavedFilter;
				delete_filter?: { facility_id: number | null; page: string };
				refreshTag?: typeof refreshTagTypes;
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.SET_SAVED_FILTERS,
				method: 'POST',
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				if (arg.refreshTag) {
					return ['Filter', arg.refreshTag];
				} else {
					return ['Filter'];
				}
			},
		}),
		applyView: build.mutation<{ message: string | undefined }, SetNewView>({
			query: (body) => ({
				url: ApiEndpoints.APPLY_VIEW,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Filter'],
		}),
		getBlockNameOptions: build.query<
			BlockOptionApiResponse,
			{ healthsystem_id?: number | null; facility_id?: number | null }
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_BlOCK_NAME_OPTIONS,
				method: 'POST',
				body,
			}),
			providesTags: ['Core', 'BlockPatterns'],
		}),
	}),
});

export const {
	useGetDefaultStartEndDateQuery,
	useGetSurgeonOptionsQuery,
	useGetAnesthesiologistOptionsQuery,
	useGetAnesthetistOptionsQuery,
	useGetServiceLineOptionsQuery,
	useGetEncounterTypeOptionsQuery,
	useGetProcedureOptionsQuery,
	useGetRoomOptionsQuery,
	useGetHolidayOptionsQuery,
	useGetSavedFilterQuery,
	useSetFilterMutation,
	useGetBlockNameOptionsQuery,
	useGetAllServiceLineOptionsQuery,
	useGetSavedViewQuery,
	useApplyViewMutation,
} = filterService;

export const {
	endpoints: {
		getDefaultStartEndDate,
		getSurgeonOptions,
		getAnesthesiologistOptions,
		getAnesthetistOptions,
		getServiceLineOptions,
		getEncounterTypeOptions,
		getProcedureOptions,
		getRoomOptions,
		getHolidayOptions,
		getSavedFilter,
		getSavedView,
		setFilter,
		getBlockNameOptions,
		getAllServiceLineOptions,
		applyView,
	},
} = filterService;
