import classNames from 'classnames';
import { forwardRef } from 'react';
import ReactSelect from 'react-select';

import {
	Control,
	IndicatorsContainer,
	IndicatorSeparator,
	LoadingIndicator,
	Menu,
	MenuListWithBulkSelect,
	MultiValue,
	Option,
	SingleValue,
	ValueContainer,
} from 'components/Select/subcomponents';
import { Tooltip } from 'components';

import type { MultiSelectProps } from 'components/Select/types';
import type { Ref } from 'react';
import type { SelectInstance } from 'react-select';

function MultiSelectWithRef<Option = unknown>(
	{
		label,
		hideLabel = false,
		options,
		getOptionLabel,
		getOptionValue,
		onChange,
		sizeX = 'full',
		sizeY = 'sm',
		errorMessage,
		errorMessageArea = 'none',
		isWorking = false,
		isKnownValid = false,
		instructions,
		value,
		defaultValue,
		disabled,
		reactSelectProps,
	}: MultiSelectProps<Option>,
	forwardedRef: Ref<SelectInstance<Option, true>>
) {
	return (
		<label className={classNames('relative', sizeX === 'full' ? 'block' : 'inline-block')}>
			<div
				className={classNames('mb-2', {
					hidden: hideLabel,
				})}
			>
				<span className='font-preset-label'>{label}</span>
				{instructions && (
					<Tooltip content={instructions}>
						<span className='material-symbol-[1rem] ml-2 align-bottom cursor-help'>info</span>
					</Tooltip>
				)}
			</div>

			<ReactSelect
				className={classNames('font-primary', reactSelectProps?.className, {
					'text-p3': sizeY === 'sm',
					'text-p2': sizeY === 'md',
					'text-p1': sizeY === 'lg',
					'opacity-50 !pointer-events-auto cursor-not-allowed': disabled,
				})}
				options={options}
				getOptionLabel={getOptionLabel}
				getOptionValue={getOptionValue}
				onChange={onChange}
				value={value}
				defaultValue={defaultValue}
				isLoading={isWorking}
				isDisabled={disabled}
				isMulti
				closeMenuOnSelect={false}
				hideSelectedOptions={false}
				backspaceRemovesValue={false}
				isClearable={false}
				menuShouldScrollIntoView={false}
				// Completely remove the styles for each component we want to use Tailwind classes on.
				styles={{
					control: () => ({}),
					valueContainer: () => ({}),
					multiValue: () => ({}),
					indicatorsContainer: () => ({}),
					dropdownIndicator: () => ({}),
					menu: () => ({}),
					menuList: () => ({}),
					option: () => ({}),
					singleValue: () => ({}),
				}}
				// Reimplement specific subcomponents to control their styles and functionality.
				components={{
					Control,
					ValueContainer,
					MultiValue,
					IndicatorsContainer,
					IndicatorSeparator,
					LoadingIndicator,
					Menu,
					MenuList: MenuListWithBulkSelect,
					Option,
					SingleValue,
				}}
				{...reactSelectProps}
				wrapperProps={{
					label,
					hideLabel,
					options,
					getOptionLabel,
					getOptionValue,
					sizeX,
					sizeY,
					errorMessage,
					errorMessageArea,
					isWorking,
					isKnownValid,
					instructions,
					disabled,
				}}
				ref={forwardedRef}
			/>

			<p
				className={classNames('mt-2 font-primary text-p2 text-red-400', {
					'absolute top-full': errorMessageArea === 'floating',
					hidden: !errorMessage && errorMessageArea !== 'reserved',
				})}
			>
				{errorMessage}
				{!errorMessage && errorMessageArea === 'reserved' && <>&nbsp;</>}
			</p>
		</label>
	);
}

// The `forwardRef` function doesn't play nicely with generic types, which we
// need in order to benefit from type safety when using React Select. Cast the
// ref-forwarded component back to its original type to prevent unexpected
// issues with the props provided by React Select.
const MultiSelect = forwardRef(MultiSelectWithRef) as typeof MultiSelectWithRef;

export { MultiSelect, MultiSelect as default };
