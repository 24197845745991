import { ApiEndpoints } from 'models/api';
import { apiWithCore } from 'store/api';
import { ShortOption } from 'store';

export interface SurgeryVolumeItem {
	date: string;
	name: string;
	service_line: string;
	surgeon_id: number;
	volume: number;
	fullDate: string;
}

export interface SurgeryVolumeResponse {
	volume_data: SurgeryVolumeItem[];
	group_by: string;
	latest_date: string;
	start_date: string;
	surgeon_procedure_data: {
		[key: number | string]: TopProcedureVolumeItem[];
	};
}

export interface TopProcedureVolumeItem {
	avg_case_duration: number;
	procedure: string;
	service_line_avg_case_duration: number;
	std_case_time: number;
	volume: number;
}

export interface FilterParameters {
	facility_id: number | null;
	groupby: string;
	viewby?: string;
	filters: {
		surgeons: ShortOption[];
		days_of_week: string[];
		service_lines: ShortOption[];
		encounter_types?: ShortOption[];
		rooms: ShortOption[];
		procedures: string[];
		start_date: string;
		end_date: string;
		turnover_threshold?: number;
		primetime: string;
		add_ons: string;
	};
	label?: string;
}

/**
 * Provides endpoints for data values in database
 */
export const surgeryService = apiWithCore.injectEndpoints({
	endpoints: (build) => ({
		getSurgicalVolume: build.query<SurgeryVolumeResponse, FilterParameters>({
			query: (body) => ({
				url: ApiEndpoints.GET_SURGICAL_VOLUME,
				method: 'POST',
				body,
			}),
			providesTags: ['Core'],
		}),
	}),
});

export const { useGetSurgicalVolumeQuery } = surgeryService;

export const {
	endpoints: { getSurgicalVolume },
} = surgeryService;
