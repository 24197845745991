import { Fragment, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { clamp } from 'utils';
import image from 'assets/images/empty.svg';
import PopOver from 'components/PopOver/PopOver';

import type { HTMLAttributes } from 'react';

export type heatmapColorSchemes = 'magma' | 'viridis' | 'blueish' | 'inferno' | 'plasma' | 'cividis' | 'rocket';

interface HeatmapProps extends HTMLAttributes<HTMLDivElement> {
	/** The labels to apply to the x- and y-axes of this heatmap. */
	axisLabels?: { y?: string; x?: string };

	/** The data to display in this heatmap, where y-values represent row headings and x-values represent arrays of 24 datum to display as values in each hourly column. */
	data: { y: string; x: number[] }[];

	/** A formatting function to apply to each datum. Useful for truncating long values into more readable formats. */
	datumFormat?: (datum: HeatmapProps['data'][number]['x'][number]) => string;

	/** A custom message to show in place of this heatmap when it has no data to display. */
	emptyMessage?: string;

	/** A custom value at which the minimum intensity of this heatmap's gradient scale should be applied. Defaults to 0. Passing 'lowest-value' will instead use the lowest individual datum value passed to this heatmap. */
	scaleLowerBound?: number | 'lowest-value';

	/** A prop which will check if popover is needed or not in heatmap cells. */
	showPopover?: boolean;

	/** A custom value at which the maximum intensity of this heatmap's gradient scale should be applied. Defaults to the highest individual datum value passed to this heatmap. */
	scaleUpperBound?: number;

	/** A custom color coding of heatmap's gradient scale that should applied differently. */
	colorScheme?: heatmapColorSchemes;

	/** Title displayed in the top right of the heatmap. */
	title?: string;

	/** Whether or not to show the x-axis name on the y-axis. */
	showFullYAxisName?: boolean;

	/** Adds extra verticle padding to cells */
	customCellPadding?: number;
}

// Define a set of classes to apply to heatmap value cells based on their value
// relative to the overall scale. For example, if a cell has a value of 25 on a
// scale of -50 to 50, it would fall into the 75th percentile. In a 10-item
// gradient array, the 75th percentile is item 7.5, which will be rounded to the
// 8th set of gradient classes.
const viridis = [
	'bg-viridis-9 text-white heatmapCellLight',
	'bg-viridis-8 text-white heatmapCellLight',
	'bg-viridis-7 text-white heatmapCellLight',
	'bg-viridis-6 text-white heatmapCellLight',
	'bg-viridis-5 text-white heatmapCellLight',
	'bg-viridis-4 heatmapCellDark',
	'bg-viridis-3 heatmapCellDark',
	'bg-viridis-2 heatmapCellDark',
	'bg-viridis-1 heatmapCellDark',
	'bg-viridis-0 heatmapCellDark',
];

const magma = [
	'bg-magma-9 text-white heatmapCellLight',
	'bg-magma-8 text-white heatmapCellLight',
	'bg-magma-7 text-white heatmapCellLight',
	'bg-magma-6 text-white heatmapCellLight',
	'bg-magma-5 text-white heatmapCellLight',
	'bg-magma-4 heatmapCellDark',
	'bg-magma-3 heatmapCellDark',
	'bg-magma-2 heatmapCellDark',
	'bg-magma-1 heatmapCellDark',
	'bg-magma-0 heatmapCellDark',
];

const inferno = [
	'bg-inferno-9 text-white heatmapCellLight',
	'bg-inferno-8 text-white heatmapCellLight',
	'bg-inferno-7 text-white heatmapCellLight',
	'bg-inferno-6 text-white heatmapCellLight',
	'bg-inferno-5 text-white heatmapCellLight',
	'bg-inferno-4 heatmapCellDark',
	'bg-inferno-3 heatmapCellDark',
	'bg-inferno-2 heatmapCellDark',
	'bg-inferno-1 heatmapCellDark',
	'bg-inferno-0 heatmapCellDark',
];

const plasma = [
	'bg-plasma-9 text-white heatmapCellLight',
	'bg-plasma-8 text-white heatmapCellLight',
	'bg-plasma-7 text-white heatmapCellLight',
	'bg-plasma-6 text-white heatmapCellLight',
	'bg-plasma-5 text-white heatmapCellLight',
	'bg-plasma-4 heatmapCellDark',
	'bg-plasma-3 heatmapCellDark',
	'bg-plasma-2 heatmapCellDark',
	'bg-plasma-1 heatmapCellDark',
	'bg-plasma-0 heatmapCellDark',
];

const cividis = [
	'bg-cividis-9 text-white heatmapCellLight',
	'bg-cividis-8 text-white heatmapCellLight',
	'bg-cividis-7 text-white heatmapCellLight',
	'bg-cividis-6 text-white heatmapCellLight',
	'bg-cividis-5 text-white heatmapCellLight',
	'bg-cividis-4 heatmapCellDark',
	'bg-cividis-3 heatmapCellDark',
	'bg-cividis-2 heatmapCellDark',
	'bg-cividis-1 heatmapCellDark',
	'bg-cividis-0 heatmapCellDark',
];

const rocket = [
	'bg-rocket-9 text-white heatmapCellLight',
	'bg-rocket-8 text-white heatmapCellLight',
	'bg-rocket-7 text-white heatmapCellLight',
	'bg-rocket-6 text-white heatmapCellLight',
	'bg-rocket-5 text-white heatmapCellLight',
	'bg-rocket-4 heatmapCellDark',
	'bg-rocket-3 heatmapCellDark',
	'bg-rocket-2 heatmapCellDark',
	'bg-rocket-1 heatmapCellDark',
	'bg-rocket-0 heatmapCellDark',
];

const blueish = [
	'bg-blue-50 heatmapCellLight',
	'bg-blue-100 heatmapCellLight',
	'bg-blue-200 heatmapCellLight',
	'bg-blue-300 heatmapCellLight',
	'bg-blue-400 heatmapCellLight',
	'bg-blue-500 text-white heatmapCellDark opacity-30',
	'bg-blue-500 text-white heatmapCellDark opacity-40',
	'bg-blue-500 text-white heatmapCellDark opacity-50',
	'bg-blue-500 text-white heatmapCellDark opacity-70',
	'bg-blue-500 text-white heatmapCellDark',
];

function Heatmap({
	axisLabels,
	data,
	datumFormat,
	emptyMessage = 'This heatmap has no data to display.',
	scaleLowerBound = 0,
	showPopover = false,
	scaleUpperBound,
	showFullYAxisName = false,
	customCellPadding = 0,
	title,
	colorScheme = 'viridis',
	...props
}: HeatmapProps) {
	const [onHeatMap, setOnHeatMap] = useState(false);
	// Keep track of the size of the intersection cell (at the top left of the
	// heatmap) so we know how much to indent our axis labels.
	const intersectionCellRef = useRef<HTMLDivElement>(null);
	const [intersectionCellRect, setIntersectionCellRect] = useState<DOMRect>();
	useEffect(() => {
		setIntersectionCellRect(intersectionCellRef.current?.getBoundingClientRect());
	}, [intersectionCellRef]);

	if (data.length === 0)
		return (
			<div className='flex flex-col items-center'>
				<div className='flex flex-col w-full items-center gap-6 p-8 text-p2 text-gray-600'>
					<img className='w-44' alt='An empty clipboard' src={image} />
					{emptyMessage}
				</div>
			</div>
		);

	// Determine the total range of our value scale so we know how to apply our
	// gradient to each value.
	const allValues = data.flatMap((dataRow) => dataRow.x);
	let upperBound = scaleUpperBound || Math.max(...allValues);
	const lowerBound = scaleLowerBound === 'lowest-value' ? Math.min(...allValues) : scaleLowerBound;

	if (upperBound <= lowerBound) {
		upperBound = 1;
	}

	const totalRange = upperBound - lowerBound;
	const gradientClasses = {
		viridis: viridis,
		magma: magma,
		blueish: blueish,
		inferno: inferno,
		plasma: plasma,
		cividis: cividis,
		rocket: rocket,
	}[colorScheme];
	return (
		<>
			{' '}
			{title && (
				<p className='text-p3 text-gray-700 tracking-wider uppercase text-right font-semibold pr-1 mb-1'>{title}</p>
			)}
			<div {...props} className={classNames('grid grid-cols-[max-content_auto]', props.className)}>
				<div></div>

				<div className='grid grid-cols-[max-content_repeat(24,1fr)] border border-blue-400 text-p3 overflow-x-auto rounded-md'>
					<div ref={intersectionCellRef} className='sticky left-0 bg-blue-50 border-b border-r border-blue-400'></div>

					{[...Array(24)].map((_, columnIndex) => (
						<div
							key={columnIndex}
							className={classNames(
								'py-1 border-b border-b-blue-400 text-center bg-blue-50 text-blue-500',
								columnIndex < data[0].x.length - 1 && 'border-r border-r-blue-200'
							)}
						>
							{String(columnIndex).padStart(2, '0')}
						</div>
					))}

					{data.map(({ x, y }, rowIndex) => {
						// Ensure our x-axis has at least 24 values.
						while (x.length < 24) x = [...x, 0];

						// Ensure our x-axis has no more than 24 values.
						if (x.length > 24) x = x.slice(0, 24);

						return (
							<Fragment key={y}>
								<div
									className={classNames(
										`left-0 p-2 border-r border-r-blue-400 bg-blue-50 text-center text-blue-500 ${
											customCellPadding !== 0 && `pt-${customCellPadding * 0.5 + 2}`
										}`,
										rowIndex < data.length - 1 && 'border-b border-b-blue-200'
									)}
								>
									{showFullYAxisName ? y : y.slice(0, 1)}
								</div>

								{x.map((datum, columnIndex) => {
									// Get the appropriate gradient classes to apply by finding the
									// percentile position of this datum within the total scale
									// range and rounding it to a matching index in the gradient
									// class array.
									const gradientClassesIndex = clamp(
										Math.round(((datum - lowerBound) / totalRange) * gradientClasses.length - 1),
										0,
										gradientClasses.length - 1
									);

									return (
										<div onMouseOut={() => setOnHeatMap(false)} onMouseOver={() => setOnHeatMap(true)} key={columnIndex}>
											<PopOver
												trigger={
													<div
														className={classNames(
															`${
																customCellPadding !== 0 ? `py-${customCellPadding}` : 'py-2'
															} px-0.5 text-center transition-colors cursor-default`,
															rowIndex < data.length - 1 && 'border-b',
															columnIndex < x.length - 1 && 'border-r',
															gradientClasses[gradientClassesIndex],
															!showPopover && '!shadow-none'
														)}
													>
														{typeof datumFormat === 'function' ? datumFormat(datum) : String(datum)}
													</div>
												}
												content={
													onHeatMap &&
													showPopover && (
														<div className='overflow-visible'>
															<div className='bg-white flex flex-col w-[12rem]'>
																<div className='bg-white p-2.5 text-[0.6rem] px-3 pl-1'>
																	<p className='text-left font-semibold'>{y}</p>
																</div>
																<div className='bg-blue-900 p-2.5 text-[0.6rem] flex justify-between'>
																	<p className='whitespace-nowrap text-left font-semibold text-white'>Hours</p>
																	<p className='text-right text-white'>{datum}%</p>
																</div>
																<div className='bg-blue-900 p-2.5 text-[0.6rem] flex justify-between'>
																	<p className='whitespace-nowrap text-left font-semibold text-white'>Hours * 2</p>
																	<p className='text-right text-white'>{datum * 2}</p>
																</div>
																<div className='bg-blue-900 p-2.5 text-[0.6rem] flex justify-between'>
																	<p className='whitespace-nowrap text-left font-semibold text-white'>Hours * 4</p>
																	<p className='text-right text-white'>{datum * 4}</p>
																</div>
																<div className='bg-blue-900 p-2.5 text-[0.6rem] flex justify-between'>
																	<p className='whitespace-nowrap text-left font-semibold text-white'>Hours * 6</p>
																	<p className='text-right text-white'>{datum * 6}</p>
																</div>
															</div>
														</div>
													)
												}
											/>
										</div>
									);
								})}
							</Fragment>
						);
					})}
				</div>
			</div>
		</>
	);
}

export { Heatmap, Heatmap as default };
