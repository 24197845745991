import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryLine } from 'victory';
import classNames from 'classnames';
import { Fragment, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Tabs from '@radix-ui/react-tabs';
import { debounce } from 'lodash';

import {
	Button,
	ChartLegend,
	DataTable,
	GaugeChart,
	LogoOverlay,
	PageHeading,
	Panel,
	TextField,
	Tooltip,
} from 'components';
import { determineBarWidth, getColor } from 'utils';
import image from 'assets/images/empty.svg';
import { Routes } from 'models';
import { BlockShortOption, useAppSelector, useGetBlockPatternReleasesQuery } from 'store';
import { useGetBlockDashboardQuery } from 'store/services/BlockDashboardService';
import {
	BlockPatternRowItem,
	BulkReleaseDates,
	FacilityNameDisplay,
	getDatesWithWeekAndDayInfo,
	ReleaseMap,
} from 'pages/Settings';
import { useFilters } from 'context';

import { BlockFlow, BlockPatternFlow, tabContentClassname } from './components';

import type { ColumnDef } from '@tanstack/react-table';
import type { TableHTMLAttributes } from 'react';

const PROJECTED_DATES = getDatesWithWeekAndDayInfo();

const tabTriggerClassname = classNames(
	'py-2 px-6 relative padding-x-3 text-bluegray-300 whitespace-nowrap',
	'data-active:text-blue-500 data-active:border-b-2 data-active:border-blue-500 data-active:top-[1px]'
);

export function Block() {
	const { selectedSystem, selectedFacility } = useAppSelector((state) => state.userState);
	const { data: dashBoardData, isFetching } = useGetBlockDashboardQuery({
		month: new Date().getMonth() + 1,
		facility_id: selectedFacility,
		healthsystem_id: selectedSystem,
	});

	const { blockNames: block_names_from_filter } = useFilters();
	const [nameQuery, setNameQuery] = useState('');
	const blockNames = block_names_from_filter.all.filter((block) => block.name.toLocaleLowerCase().includes(nameQuery));

	const navigate = useNavigate();

	const legend = [
		{
			label: 'Under Target',
			color: getColor('red'),
		},
		{
			label: 'On Target',
			color: getColor('green'),
		},
		{
			label: 'Over Target',
			color: getColor('yellow'),
		},
	];

	const dataMaxY =
		(dashBoardData && Math.ceil(Math.max(...dashBoardData.aggregate_block_utilization.map((d) => d.y)) + 10)) ?? 100;

	const latestRecordDate = new Date(dashBoardData ? dashBoardData.latest_record_date : '');

	const updateQueryText = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
		const url = new URL(window.location.href);
		const params = new URLSearchParams(url.search);
		params.set('block_active', '0');
		params.set('block_attention', '0');
		params.set('block_inactive', '0');
		url.search = params.toString();
		window.history.pushState({}, '', url.toString());
		setNameQuery(e.target.value.toLocaleLowerCase());
	}, 400);

	return (
		<div>
			<PageHeading>Aggregate Block Utilization</PageHeading>

			{isFetching ? (
				<LogoOverlay backgroundColor='white' />
			) : (
				dashBoardData && (
					<>
						<Panel
							title='Month Overview'
							headerContentCenter={
								<div className='flex items-center gap-2'>
									<Tag>{`${new Date(latestRecordDate.getFullYear(), latestRecordDate.getMonth(), 1).toLocaleString('en-US', {
										month: 'numeric',
										day: 'numeric',
										year: 'numeric',
									})}`}</Tag>{' '}
									&ndash;{' '}
									<Tag>
										{latestRecordDate.toLocaleString('en-US', {
											month: 'numeric',
											day: 'numeric',
											year: 'numeric',
										})}
									</Tag>
								</div>
							}
							headerContentCenterAlignment='left'
						>
							<div className='flex flex-col xl:flex-row gap-8 py-2'>
								<div className='justify-self-center basis-3/5'>
									<div className='flex items-center gap-2'>
										Adjusted Block Utilization by Month
										<Tooltip content='Use this visualization to track the overall block utilization trend.'>
											<div
												className='material-symbol-sm text-blue-500 pr-8'
												onClick={() => navigate(Routes.HELP_FAQS + `?goToId=${'commonDefinitions'}`)}
											>
												info
											</div>
										</Tooltip>
										<ChartLegend options={legend} />
									</div>

									<div className='max-h-full w-full'>
										<VictoryChart
											domainPadding={determineBarWidth(dashBoardData.aggregate_block_utilization?.length / 1.8)}
											maxDomain={{ y: dataMaxY < 100 ? 100 : dataMaxY }}
											height={300}
											width={window.innerWidth / 2}
											standalone={true}
											padding={{ left: 40, right: 70, top: 40, bottom: 40 }}
											animate
										>
											<VictoryAxis
												style={{
													axis: { stroke: 'hsl(0 0% 90%)' },
													tickLabels: {
														fontFamily: 'Inter',
														fontSize: 10,
													},
												}}
												tickLabelComponent={
													<VictoryLabel
														data={dashBoardData.aggregate_block_utilization}
														style={{
															fontFamily: 'Inter',
															fontSize: 12,
															fillOpacity: ({ index, data }) => (index === (data?.length ?? 0) - 1 ? 1 : 0.65),
														}}
													/>
												}
											/>
											<VictoryAxis
												dependentAxis
												tickFormat={(t) => `${t}%`}
												style={{
													axis: { stroke: 'hsl(0 0% 90%)' },
													grid: { stroke: 'hsl(0 0% 90%)' },
													tickLabels: { fontFamily: 'Inter', fontSize: 12 },
												}}
											/>
											<VictoryBar
												// barWidth={determineBarWidth(dashBoardData.aggregate_block_utilization?.length / 1.5)}
												barRatio={0.8}
												style={{
													data: {
														// Use RGB values for the bar color to trigger a more natural color space interpolation while
														// animating, as HSL hue rotation tends to feel too extreme.
														// https://github.com/d3/d3-interpolate#color-spaces
														fill: ({ datum }) =>
															datum.y >= dashBoardData.utilization_target_percent && datum.y <= 85
																? 'rgb(64, 206, 152)' // tailwind.config.js/theme.colors.green.500
																: datum.y < dashBoardData.utilization_target_percent
																? 'rgb(246, 126, 126)'
																: 'hsl(34 100% 81%)',
														fillOpacity: ({ index, data }) => (index === (data?.length ?? 0) - 1 ? 1 : 0.65),
													},
													labels: {
														fontFamily: 'Inter',
														fontSize: 12,
														fillOpacity: ({ index, data }) => (index === (data?.length ?? 0) - 1 ? 1 : 0.65),
													},
												}}
												cornerRadius={{ top: 4 }}
												data={dashBoardData.aggregate_block_utilization}
												labels={({ datum }) => `${Math.round(datum.y)}%`}
												animate
											/>

											<VictoryLine
												y={() => dashBoardData.utilization_target_percent}
												style={{ data: { strokeDasharray: 2, strokeWidth: 1, stroke: 'hsl(211 36% 59%)' } }}
												animate
											/>
											<VictoryLine
												y={() => 85}
												style={{ data: { strokeDasharray: 2, strokeWidth: 1, stroke: 'hsl(211 36% 59%)' } }}
												animate
											/>
											<VictoryAxis
												dependentAxis
												orientation='right'
												tickValues={[dashBoardData.utilization_target_percent]}
												tickFormat={(targetPercent) => [`${Math.round(targetPercent)}% - 85%`, 'Target Range']}
												style={{ axis: { strokeWidth: 0 }, tickLabels: { padding: 2 } }}
												tickLabelComponent={
													<VictoryLabel
														dy={-dashBoardData.utilization_target_percent * 0.2}
														lineHeight={[1.75, 1.25, 1.25]}
														style={[
															{ fontFamily: 'Inter', fontSize: 12, fontWeight: 600 },
															{ fontFamily: 'Inter', fontSize: 10 },
															{ fontFamily: 'Inter', fontSize: 10 },
														]}
													/>
												}
												animate
											/>
										</VictoryChart>
									</div>
								</div>

								<div className='h-px xl:h-auto xl:w-px bg-gray-200' />
								<div className='flex-col'>
									<div className='flex items-center gap-2'>
										On-Target Block Days (%)
										<Tooltip content='Use this table to evaluate how frequently an allocated block meets the specified target per block day. In general, a higher percentage of block days on-target means allocated blocks are meeting the efficiency goals set by the organization.'>
											<div className='material-symbol-sm text-blue-500'>info</div>
										</Tooltip>
									</div>

									<div className='flex flex-col xl:flex-row gap-8 py-2'>
										<div className='basis-full bg-gray-50 rounded-b-md'>
											{dashBoardData.on_target_blocks.current_month && (
												<div className='flex items-center justify-center gap-6 my-6 font-semibold text-center'>
													{dashBoardData.on_target_blocks.previous_month !== null && (
														<div className='xl:p-6 p-2'>
															<span className='font-secondary text-gray-500'>{'Last Month'}</span>
															<div className='xl:p-6 p-2 mt-4'>
																<GaugeChart
																	percentage={Math.round(
																		(dashBoardData.on_target_blocks.previous_month / dashBoardData.number_of_blocks.previous_month) * 100
																	)}
																	textColor={'black'}
																	radius={50}
																	strokeWidth={8}
																/>
															</div>
														</div>
													)}
													{dashBoardData.on_target_blocks.current_month !== null && (
														<div className='xl:p-6 p-2'>
															<span className='font-secondary'>{'This Month'}</span>
															<div className='xl:p-6 p-2 mt-4 bg-gray-100 rounded-lg'>
																<GaugeChart
																	percentage={Math.round(
																		(dashBoardData.on_target_blocks.current_month / dashBoardData.number_of_blocks.current_month) * 100
																	)}
																	textColor={'black'}
																	radius={50}
																	strokeWidth={8}
																/>
															</div>
														</div>
													)}
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</Panel>
						<div className='w-100 h-8'></div>
						<div className='p-4 border border-blue-500 rounded-sm'>
							<Tabs.Root defaultValue='active' orientation='vertical'>
								<Tabs.List aria-label='Active' className='flex border-b border-bluegray-100'>
									<Tabs.Trigger value='active' className={tabTriggerClassname}>
										Active Blocks
									</Tabs.Trigger>
									<Tabs.Trigger value='needs_attention' className={tabTriggerClassname}>
										Needs Allocation
									</Tabs.Trigger>
									<Tabs.Trigger value='inactive' className={tabTriggerClassname}>
										Inactive Blocks
									</Tabs.Trigger>
									<div className='flex justify-end w-full items-end pb-4'>
										<div className='flex mr-2'>
											<BlockFlow facility_id={selectedFacility} healthsystem_id={selectedSystem}>
												<Button sizeX='md' sizeY='sm' variant='primary-ghost' className='mr-1'>
													Add/Edit Block
												</Button>
											</BlockFlow>

											<BulkReleaseDates facility_id={selectedFacility} healthsystem_id={selectedSystem}>
												<Button sizeX='md' sizeY='sm' variant='primary-ghost'>
													Bulk Release Dates
												</Button>
											</BulkReleaseDates>
										</div>
										<div className='pr-2'>
											<TextField label={''} sizeX='lg' onChange={updateQueryText} placeholder='Search by Block Names' />
										</div>
									</div>
								</Tabs.List>
								<Tabs.Content value='active' className={tabContentClassname}>
									<BlockTabTables table_name='block_active' blockNames={blockNames.filter((b) => b.has_active_pattern)} />
								</Tabs.Content>
								<Tabs.Content value='needs_attention' className={tabContentClassname}>
									<BlockTabTables table_name='block_attention' blockNames={blockNames.filter((b) => !b.had_pattern)} />
								</Tabs.Content>
								<Tabs.Content value='inactive' className={tabContentClassname}>
									<BlockTabTables table_name='block_inactive' blockNames={blockNames.filter((b) => !b.has_active_pattern)} />
								</Tabs.Content>
							</Tabs.Root>
						</div>
					</>
				)
			)}

			{/* <Panel
				className='mt-8'
				title='Calendar'
				headerContentRight={
					<div className='flex gap-4'>
						<Button sizeX='sm' variant='primary-ghost'>
							<span className='material-symbol-sm-fill'>filter_alt</span>
							Filters
						</Button>

						<Button sizeX='sm' variant='secondary'>
							Release
						</Button>
					</div>
				}
			></Panel> */}
		</div>
	);
}

interface BlockTabTableProps {
	blockNames: BlockShortOption[];
	table_name: string;
}

function BlockTabTables({ blockNames, table_name }: BlockTabTableProps) {
	const { selectedSystem, selectedFacility } = useAppSelector((state) => state.userState);
	const { data: releasePatternsData } = useGetBlockPatternReleasesQuery({
		healthsystem_id: selectedSystem,
	});
	const release_map: ReleaseMap = {};
	releasePatternsData?.releases.forEach((r) => {
		if (!release_map[r.block_pattern_id]) {
			release_map[r.block_pattern_id] = [r];
		} else {
			release_map[r.block_pattern_id].push(r);
		}
	});

	const navigate = useNavigate();

	// Need empty values for data table columns
	const block_pattern_table_data = blockNames.map((option) => ({
		block_name: option.name,
		block_id: option.id,
		assigned_facility: '',
		pattern_summary: [],
		actions: '',
	}));

	return (
		<DataTable
			noHover={true}
			pageSizes={[5]}
			minHeight='50em'
			minimalStyle
			keepPageIndexOnLeave={table_name}
			columns={[
				{
					header: 'Block Name',
					accessorKey: 'block_name',
					enableColumnFilter: true,
					cell: ({ row }) => (
						<p
							className='cursor-pointer text-p2 w-24 uppercase font-semibold text-bluegray-800 hover:underline'
							onClick={() => {
								navigate(`/block-scorecard?block_scorecard_id=${row.original.block_id}`);
							}}
						>
							{row.original.block_name}
						</p>
					),
				},
				{
					header: 'Assigned Facility',
					accessorKey: 'assigned_facility',
					cell: ({ row }) => (
						<FacilityNameDisplay block_option={{ id: row.original.block_id, name: row.original.block_name }} />
					),
					enableGlobalFilter: false,
				},
				{
					header: 'Pattern Summary',
					accessorKey: 'pattern_summary',
					cell: ({ row }) => (
						<div className=''>
							<BlockPatternRowItem
								facility_id={selectedFacility ?? 0}
								healthsystem_id={selectedSystem ?? 0}
								release_map={release_map}
								dates={PROJECTED_DATES}
								block_option={{ id: row.original.block_id, name: row.original.block_name }}
							/>
						</div>
					),
					enableGlobalFilter: false,
				},
				{
					header: 'Actions',
					accessorKey: 'actions',
					enableGlobalFilter: false,
					cell: ({ row }) => (
						<div className='flex flew-row w-48 mb-4'>
							<BlockPatternFlow
								default_block_option={{ id: row.original.block_id, name: row.original.block_name }}
								facility_id={selectedFacility}
								healthsystem_id={selectedSystem}
							>
								<Button
									sizeX='sm'
									variant='transparent'
									className='p-0 m-0 text-black border-[0.5px] border-r-0 rounded-r-none border-blue-500'
								>
									Add
								</Button>
							</BlockPatternFlow>
							<BlockPatternFlow
								default_pattern_flow='Edit'
								default_block_option={{ id: row.original.block_id, name: row.original.block_name }}
								facility_id={selectedFacility}
								healthsystem_id={selectedSystem}
							>
								<Button
									sizeX='sm'
									variant='transparent'
									className='text-black text-i3 border-[0.5px] rounded-l-none rounded-r-none border-blue-500'
								>
									Edit
								</Button>
							</BlockPatternFlow>
							<BlockPatternFlow
								default_pattern_flow='Delete'
								default_block_option={{ id: row.original.block_id, name: row.original.block_name }}
								facility_id={selectedFacility}
								healthsystem_id={selectedSystem}
							>
								<Button
									sizeX='sm'
									variant='transparent'
									className='text-black text-i3 border-[0.5px] border-l-0 rounded-l-none rounded-r-none border-blue-500'
								>
									Delete
								</Button>
							</BlockPatternFlow>
							<BlockPatternFlow
								default_pattern_flow='End'
								default_block_option={{ id: row.original.block_id, name: row.original.block_name }}
								facility_id={selectedFacility}
								healthsystem_id={selectedSystem}
							>
								<Button
									sizeX='sm'
									variant='transparent'
									className='text-i3 text-black border-[0.5px] border-l-0 rounded-l-none border-blue-500'
								>
									End
								</Button>
							</BlockPatternFlow>
						</div>
					),
				},
			]}
			data={block_pattern_table_data}
			title={'Block Information'}
		/>
	);
}

function Tag({ children }: { children: string }) {
	return <div className='bg-blue-500 font-secondary text-p2 text-white px-2 py-0.5 rounded-sm'>{children}</div>;
}

// TODO: Consider if this is the best place for/way to handle this. It
// technically applies to all instances of the table throughout the project,
// not just in this file.
declare module '@tanstack/table-core' {
	// Since we're extending this interface, it needs to have the exact same type
	// parameters as the base, even if we're not directly using them.
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	interface ColumnMeta<TData, TValue> {
		/**
		 * Whether the cells in this column should act headers for their rows. This option is currently exclusive to the
		 * `BlockOnTargetTable` component.
		 */
		isHeaderColumn?: boolean;
	}
}

interface BlockOnTargetTableProps<TableRow> extends TableHTMLAttributes<HTMLTableElement> {
	/**
	 * The column definitions for this data table, which determine how the raw data are parsed and displayed. For more
	 * information on column definitions, see [the TanStack Tabledocumentation](https://tanstack.com/table/v8/docs/guide/column-defs).
	 */
	columns: ColumnDef<TableRow>[];

	/** An array of data points with which to populate the rows of this data table. */
	data: TableRow[];
}

function BlockOnTargetTable<TableRow>({ columns, data, ...props }: BlockOnTargetTableProps<TableRow>) {
	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
	});

	const headerGroups = table.getHeaderGroups();
	const rowModel = table.getRowModel();
	const footerGroups = table.getFooterGroups();

	const footerGroupsWithContent = footerGroups.filter(
		(footerGroup) => !footerGroup.headers.every((header) => typeof header.column.columnDef.footer === 'undefined')
	);

	return (
		<div className='text-p1 font-primary overflow-x-auto'>
			<table className='w-full border-collapse border-spacing-0' {...props}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => (
								<th
									key={header.id}
									colSpan={header.colSpan}
									className={classNames(
										'h-9 py-0 px-2 first:pl-0 last:pr-0',
										header.colSpan > 1 ? 'text-p2 font-semibold' : 'text-p3',
										header.column.columnDef.meta?.columnClass,
										header.column.columnDef.meta?.headerClass
									)}
								>
									{header.isPlaceholder ? null : (
										<div className='inline-flex items-center gap-1 truncate'>
											{flexRender(header.column.columnDef.header, header.getContext())}

											{header.column.columnDef.meta?.tooltipContent && (
												<Tooltip content={header.column.columnDef.meta.tooltipContent} contentProps={{ className: 'normal-case' }}>
													<div className='material-symbol-sm'>info</div>
												</Tooltip>
											)}
										</div>
									)}
								</th>
							))}
						</tr>
					))}
				</thead>

				<tbody>
					{rowModel.rows.length > 0 ? (
						rowModel.rows.map((row, rowIndex) => (
							<Fragment key={row.id}>
								<tr className='group'>
									{row.getVisibleCells().map((cell, cellIndex) =>
										cellIndex === 0 && cell.column.columnDef.meta?.isHeaderColumn ? (
											<th
												key={cell.id}
												className={classNames(
													'py-2.5 px-2 first:pl-0 last:pr-0 text-p3',
													'border-r-2 border-blue-500',
													rowIndex === rowModel.rows.length - 1 && 'pb-4',
													cell.column.columnDef.meta?.columnClass,
													cell.column.columnDef.meta?.bodyClass
												)}
											>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</th>
										) : (
											<td
												key={cell.id}
												className={classNames(
													'py-2.5 px-2 first:pl-0 last:pr-0 text-p2',
													rowIndex === rowModel.rows.length - 1 && 'pb-4',
													cell.column.columnDef.meta?.columnClass,
													cell.column.columnDef.meta?.bodyClass
												)}
											>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</td>
										)
									)}
								</tr>
							</Fragment>
						))
					) : (
						<tr>
							<td colSpan={columns.length} className='p-8 text-p2 text-gray-600'>
								<div className='flex flex-col w-full items-center gap-6'>
									<img className='w-44' alt='An empty clipboard' src={image} />
									No data to display.
								</div>
							</td>
						</tr>
					)}
				</tbody>

				{footerGroupsWithContent.length > 0 && (
					<tfoot>
						{footerGroupsWithContent.map((footerGroup) => (
							<tr key={footerGroup.id} className='border-t-2 border-blue-500'>
								{footerGroup.headers.map((header) => (
									<th
										key={header.id}
										colSpan={header.colSpan}
										className={classNames(
											'py-4 px-2 first:pl-0 last:pr-0',
											header.column.columnDef.meta?.isHeaderColumn ? 'text-p3' : 'text-p2',
											header.column.columnDef.meta?.isHeaderColumn && 'border-r-2 border-blue-500',
											header.column.columnDef.meta?.columnClass,
											header.column.columnDef.meta?.footerClass
										)}
									>
										{header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
									</th>
								))}
							</tr>
						))}
					</tfoot>
				)}
			</table>
		</div>
	);
}

export default Block;
