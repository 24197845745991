import { useState } from 'react';

import { EntityMappingTable, ExistingEntityTable, findOptionName } from 'pages';
import { getQueryError, handleApiResponse, useAppSelector } from 'store';
import { useFilters, useToast } from 'context';
import { useGetReconciliationQuery, useSetEntityMappingMutation } from 'store/services/MappingService';
import { LogoOverlay, ToggleGroup } from 'components';
import { capitalizeFirstLetter } from 'utils';

export function AnesthesiaProvider() {
	const toast = useToast();
	const { selectedSystem, selectedFacility } = useAppSelector((state) => state.userState);
	const [group, setGroup] = useState<'anesthesiologists' | 'anesthetists'>('anesthesiologists');
	const { anesthesiologists, anesthetists } = useFilters();
	const { data, isLoading } = useGetReconciliationQuery({
		facility_id: selectedFacility,
		healthsystem_id: selectedSystem,
		group: group,
	});

	const [setAnesthesiaMapping] = useSetEntityMappingMutation();

	const anesthesiaData = data?.unMappedEntities.map((entry) => ({
		imported_as: { id: entry.id, name: entry.name },
		date_found: entry.date_found,
		sim_level: {
			percentage: entry.similarity_score,
			closest_option: findOptionName(entry.similar_id, data?.allEntities ?? [], entry.name),
		},
	}));

	const saveAnesthesiaMapping = async (fromEntity: number, toEntity: number) => {
		const response = await setAnesthesiaMapping({
			healthsystem_id: selectedSystem,
			facility_id: selectedFacility,
			method: 'save_mapping',
			fromId: fromEntity,
			toId: toEntity,
			group: group,
		});

		handleApiResponse(response, {
			success: (payload) => {
				!payload &&
					toast.createToast({
						title: `${capitalizeFirstLetter(group)} mapping saved.`,
						variant: 'success',
					});
			},
			error: (payload) => {
				toast.createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
			},
		});
	};

	const createEntity = async (entity: number) => {
		const response = await setAnesthesiaMapping({
			healthsystem_id: selectedSystem,
			facility_id: selectedFacility,
			method: 'create',
			fromId: entity,
			group: group,
		});

		handleApiResponse(response, {
			success: () => {
				toast.createToast({
					title: `New ${capitalizeFirstLetter(group)} created.`,
					variant: 'success',
				});
			},
			error: (payload) => {
				toast.createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
			},
		});
	};

	return (
		<div className='pt-4'>
			{isLoading && <LogoOverlay backgroundColor='white' />}
			<EntityMappingTable
				title='New Anesthesia Provider Mapping & Resolution'
				keepPageIndexOnLeave={'anesthesia_new'}
				tooltipContent={
					'Use this table to validate the accuracy of newly found provider names. Small, hard to notice changes like a missing comma or an extra space can affect how blocks and other metrics are credited. We developed a similarity score so you can easily map a newly found name to an existing provider name. You can designate a newly found provider name as an actual “New Provider, or, match this with any other previously mapped provider in the database.'
				}
				swapEntity={
					<ToggleGroup
						label=''
						className='w-[20em]'
						hideLabel
						defaultValue={group}
						options={[
							{ label: 'Anesthesiologist', value: 'anesthesiologists' },
							{ label: 'Anesthetist', value: 'anesthetists' },
						]}
						onValueChange={(e: 'anesthesiologists' | 'anesthetists') => {
							setGroup(e);
						}}
					/>
				}
				goToHelpID='accountingsurgeons'
				emptyEntityMessage='No results found for this anesthesia provider name'
				entityCreateText='New Provider'
				entityName='Provider'
				data={anesthesiaData ?? []}
				saveEntityAs={(fromEntity, toEntity) => {
					saveAnesthesiaMapping(fromEntity, toEntity);
				}}
				createEntity={(entity) => {
					createEntity(entity);
				}}
				entityOptions={data?.allEntities ?? []}
			/>
			<div className='pt-8'>
				<ExistingEntityTable
					title='Existing Provider Mapping'
					tooltipContent={'Use this table to keep track of provider and alias mappings.'}
					goToHelpID='accountingsurgeons'
					keepPageIndexOnLeave={'anesthesia_existing'}
					entityName='Provider'
					aliasesData={data?.aliasEntities ?? []}
					save={saveAnesthesiaMapping}
					entityOptions={data?.allEntities ?? []}
					pickableEntities={group === 'anesthesiologists' ? anesthesiologists.all : anesthetists.all}
				/>
			</div>
		</div>
	);
}

export default AnesthesiaProvider;
