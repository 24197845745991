import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button, TextField } from 'components';
import {
	defaultResetPasswordFormInputValues,
	passwordChecklistItems,
	resetPasswordFormSchema,
} from 'models/authentication';
import { AuthPage } from 'pages/Auth';
import { getOktaErrorMessage } from 'utils/authentication';
import { Routes } from 'models/navigation';
import { useAuth } from 'context';

import type { AnyAuthContext } from 'context';
import type { ResetPasswordFormInputs } from 'models/authentication';
import type { SubmitHandler } from 'react-hook-form';

export function ResetPassword() {
	const auth = useAuth<AnyAuthContext>();
	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		reset,
		getValues,
		formState: { errors, isDirty, isSubmitting, isSubmitted },
	} = useForm<ResetPasswordFormInputs>({ resolver: yupResolver(resetPasswordFormSchema) });
	const [errorMessage, setErrorMessage] = useState('');
	const [formValues, setFormValues] = useState({ email: '', password: '' });

	const onSubmit: SubmitHandler<ResetPasswordFormInputs> = async (formData) => {
		navigate(Routes.HOME);
	};

	// When an authentication error occurs, keep the form's values but reset its
	// dirty state. Then when the form becomes dirty again, clear the error since
	// the user has indicated they are trying again.
	useEffect(() => {
		const clearAuthError = auth.clearError;

		if (auth.status === 'api_error') {
			if (isSubmitted) {
				reset(defaultResetPasswordFormInputValues, { keepValues: true });
			} else if (isDirty) {
				clearAuthError();
			}
		}
	}, [auth.status, auth.clearError, isSubmitted, isDirty, reset]);

	// Provide a more detailed authentication error message based on the error
	// code returned by the Okta API.
	useEffect(() => {
		if (auth.status === 'api_error') {
			setErrorMessage(getOktaErrorMessage(auth.data.error.errorCode));
		} else {
			setErrorMessage('');
		}
	}, [auth.status, auth.data]);

	return (
		<AuthPage>
			<div className='flex'>
				<div className='flex max-w-xl w-full'>
					<div className='m-auto pt-7 px-8 max-w-md w-full'>
						<h1 className='font-semibold text-h4'>Submit Your New Password</h1>

						<form className='flex flex-col mt-8' onSubmit={handleSubmit(onSubmit)} noValidate autoComplete='off'>
							<TextField
								containerProps={{ className: 'my-2' }}
								label='Create Password'
								type='password'
								sizeY='md'
								defaultValue={defaultResetPasswordFormInputValues.password}
								errorMessage={errors.password?.message}
								onInput={() => {
									setFormValues({
										...formValues,
										password: getValues('password'),
									});
								}}
								{...register('password')}
							/>
							<div className='my-2'>
								{passwordChecklistItems.map((item, index) => (
									<div key={index} className='flex my-2 text-p2'>
										<div className='flex-initial w-8'>
											<span
												className={classNames(
													'material-symbol relative -top-[2px]',
													item.test(formValues.password, formValues.email) ? 'text-green-500' : 'text-gray-400'
												)}
											>
												check_circle
											</span>
										</div>
										<div className='grow'>{item.text}</div>
									</div>
								))}
							</div>

							<Button className='mt-12' sizeY='lg' isWorking={isSubmitting} disabled={!isDirty}>
								{isSubmitting ? 'Submitting' : 'Submit'}
							</Button>

							<p className='mt-2 text-p2 text-red-400'>{errorMessage || <>&nbsp;</>}</p>

							<div>
								<Link to={Routes.HOME} className='self-start font-secondary text-p2'>
									<span className='material-symbol-sm relative top-[5px] mr-1'>keyboard_backspace</span>
									Back to Login
								</Link>
							</div>
						</form>
					</div>
				</div>
			</div>

			<div className='bg-merlinBlue'></div>
		</AuthPage>
	);
}

export default ResetPassword;
