import {
	Action,
	Cancel,
	Content,
	Description,
	Overlay,
	Portal,
	Root,
	Title,
	Trigger,
} from '@radix-ui/react-alert-dialog';
import classNames from 'classnames';

import { Button } from 'components';

import type { ArrayOf } from 'types';
import type { AlertDialogProps as RadixAlertDialogProps } from '@radix-ui/react-alert-dialog';

interface AlertResponseOption {
	/** The value to assign to this alert response. */
	value: string;

	/** The label to use for the button that returns the associated response value. */
	label: string;
}

export interface AlertProps extends RadixAlertDialogProps {
	/** The title text to display at the top of this alert. */
	title?: string;

	type?: 'Error' | 'Warning';

	body?: JSX.Element;

	/** The content of this alert, outlining the decision the user needs to make before proceeding. */
	description?: string;

	/** The response options to present to the user in this alert. */
	responseOptions: ArrayOf<'at least', 1, AlertResponseOption>;

	/** The settings for the "do nothing" (or otherwise least-destructive) response option in this alert. */
	doNothingResponse?: AlertResponseOption;

	/** The handler function to invoke when the user selects a response from this alert. */
	onResponse: (value: string) => void;
}

/**
 * Applies a modal dialog to an element that interrupts the user with important
 * information that requires a response.
 */
export function Alert({
	title = 'Alert',
	description,
	type,
	body,
	responseOptions,
	doNothingResponse = { value: 'do_nothing', label: 'Cancel' },
	onResponse: responseHandler = (value: string) => value,
	children,
	...props
}: AlertProps) {
	return (
		<Root {...props}>
			{children && <Trigger asChild>{children}</Trigger>}

			<Portal>
				<Overlay
					className={classNames(
						'fixed inset-0 bg-black/40 z-alert',
						'data-open:animate-displayOverlay data-closed:animate-dismissOverlay'
					)}
				/>

				<div className='fixed inset-0 grid place-items-center z-alert'>
					<Content
						className={classNames(
							'max-w-2xl bg-white border border-blue-500 rounded-sm',
							'data-open:animate-displayAlert data-closed:animate-dismissAlert'
						)}
						onEscapeKeyDown={() => responseHandler(doNothingResponse.value)}
					>
						<Title className='flex items-center gap-3 py-4 px-6 bg-blue-50 border-b border-inherit'>
							{type === 'Error' ? (
								<span className='material-symbol-[2rem/fill] text-red-700'>error</span>
							) : (
								<span className='material-symbol-[2rem/fill] text-yellow-700'>warning</span>
							)}
							<span className='text-h5 font-semibold'>{title}</span>
						</Title>

						<div className='p-6'>
							{description && <Description className='py-2 leading-relaxed'>{description}</Description>}

							{body}

							<div className='flex justify-end gap-2 mt-6'>
								<Cancel onClick={() => responseHandler(doNothingResponse.value)} asChild>
									<Button variant='secondary' sizeX='sm' sizeY='sm'>
										{doNothingResponse.label}
									</Button>
								</Cancel>

								{responseOptions.map(({ value, label }) => (
									<Action key={value} onClick={() => responseHandler(value)} asChild>
										<Button sizeX='sm' sizeY='sm'>
											{label}
										</Button>
									</Action>
								))}
							</div>
						</div>
					</Content>
				</div>
			</Portal>
		</Root>
	);
}

export default Alert;
