import { useSearchParams } from 'react-router-dom';
import { scrollIntoView } from 'react-select/dist/declarations/src/utils';

import primetime_settings1 from 'assets/images/primetime_settings1.png';
import { Accordion } from 'components';
import block_credit_breakdown from 'assets/images/block_minute_credit_bd.png';
import datepicker from 'assets/images/datepicker.png';
import flip_room_example from 'assets/images/flip_rooms_example.png';
import flip_room_example_2 from 'assets/images/flip_rooms_example_2.png';
import flip_room_example_3 from 'assets/images/flip_rooms_example_3.png';
import heatmap from 'assets/images/heatmap.png';
import primetime_settings2 from 'assets/images/primetime_settings2.png';
import request_a_demo from 'assets/images/request_a_demo.svg';
import submit_a_ticket from 'assets/images/submit_a_ticket.svg';

export function Help() {
	const [searchParams] = useSearchParams();
	const goToId = searchParams.get('goToId') ?? '';

	enum AccordionaValue {
		timelystarts = 'First Case On-Time Starts',
		turnovertime = 'Turnover Time',
		facilityutilization = 'Facility Utilization',
		schedulingpatterns = 'Add-Ons & Scheduling Patterns',
		surgonscorecards = 'Surgeon Scorecards',
		commonDefinitions = 'commonDefinitions',
		fliprooms = 'Flip Rooms',
		blockutilization = 'Block Utilization',
		heatmap = 'Heatmap tips & tricks',
		uploads = 'Inserting data into Merlin',
		manageusers = 'Adding new users',
		facilitylicense = "Viewing your account's license details",
		accountingsurgeons = 'Accounting for new Surgeons',
		newrooms = 'Accounting for new procedural locations',
	}
	const openAccordion = AccordionaValue[goToId as keyof typeof AccordionaValue];

	const elementToScroll = document.querySelector('[data-state="open"]') ?? document.getElementById('commonDefinitions');

	elementToScroll &&
		openAccordion &&
		elementToScroll.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
			inline: 'nearest',
		});

	return (
		<div className='flex flex-col w-3/4 m-auto' id='top'>
			<h1 className='font-bold text-h3 mb-6'>How can we help you?</h1>
			<div className='flex flex-col justify-between sm:flex-col md:flex-col lg:flex-col-reverse xl:flex-row 2xl:flex-row'>
				<div
					onClick={() => {
						window.open(
							`mailto:Merlinhelp@surgicaldirections.com?subject=Merlin Support Request&body=Please enter a detailed description of the problem you are facing. If possible, try to give an example of the steps required to reproduce the issue. Also, feel free to attach a screenshot of the issue to this message. Please allow at least 1 business day for the Merlin team to respond to your issue.`
						);
					}}
					className='transition-colors duration-250 border-blue-500 border-2 rounded-sm flex justify-between px-6 min-w-96 w-full py-2 items-center cursor-pointer hover:bg-gray-50 sm:w-full lg:w-full xl:w-1/2 2xl:w-1/2'
				>
					<img className='w-24 mr-6' alt='No facility is selected' src={submit_a_ticket} />
					<div className='flex flex-col w-3/4'>
						<p className='font-bold mb-1'>Submit a Support Ticket</p>
						<p className='text-p2'>
							Does something look wrong? Notice an issue with how Merlin is working? Let us know here!
						</p>
					</div>
				</div>

				<div className='w-16'>
					<p className='opacity-0'>-</p>
				</div>

				<div
					onClick={() => {
						window.open(
							`mailto:Merlinhelp@surgicaldirections.com?subject=Merlin Training Session Request&body=Are you looking for expertise to help tackle your operating room’s toughest challenges? Send us a brief description of what kind of support you’re looking for, and we’ll be in touch about how we can help. Please allow between 1-3 business days for the Merlin team to respond to your request. We’ll be in touch shortly!`
						);
					}}
					className='transition-colors duration-250 border-blue-500 border-2 rounded-sm flex justify-between px-6 min-w-96 w-full py-2 items-center cursor-pointer hover:bg-gray-50 sm:w-full lg:w-full xl:w-1/2 2xl:w-1/2'
				>
					<img className='w-24 mr-6' alt='No facility is 2' src={request_a_demo} />
					<div className='flex flex-col w-3/4'>
						<p className='font-bold mb-1'>Request Consultative Support</p>
						<p className='text-p2'>
							Unsure about how to maximize Merlin&apos;s value? We&apos;re here to help! Contact us here if you&apos;d like to
							tap into Surgical Directions&apos; years of healthcare expertise.
						</p>
					</div>
				</div>
			</div>

			<h1 className='font-bold text-h4 my-6'>Getting Started</h1>
			<Accordion type='multiple' itemStyle='contained'>
				<Accordion.Item value='Basic site navigation'>
					<p className='font-semibold text-p1 mb-3'>Dashboard</p>
					<p className='mb-3'>
						Welcome to Merlin! This will be the first page you see when logging in. From this page, you&apos;ll be able to see
						all the reports you have access to. You&apos;ll also be able to see the date range of your most recent data
						upload, along with some simple summary statistics for the time period of interest.
					</p>
					<p className='mb-3'>
						Click a box underneath the module header to go to the report of interest. You can also get to these pages using
						the left navigation panel.
					</p>
					<p className='mb-3'>
						If you&apos;re interested in more of what Merlin has to offer, you can also contact us directly to set up a demo.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Left Navigation</p>
					<p className='mb-3'>
						Hover your mouse over the left navigation panel to open the left navigation drawer. You can easily pin the drawer
						open by hitting the circular arrow icon near the top left portion of your screen. Click on a module to see its
						contents. You&apos;ll be taken to the dashboard landing page for the module you clicked, but you can easily
						navigate to another page by clicking on it in the left navigation bar, or via links on the dashboard page itself.
					</p>
					<p className='mb-3'>
						From here, you also have quick access to our File Upload and Help pages, as well as a quick logout button at the
						bottom of the left navigation bar.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Top Navigation</p>
					<p className='mb-3'>
						Along the top of your screen, you will see text saying “Your facility is set to __” . This is a dropdown menu that
						allows you select from the one or multiple facilities you&apos;ve been provisioned access to. Open the dropdown
						many and select the facility you want to see metrics for. For ease of navigation, you can also search for a
						facility name within the dropdown, or scroll until you find the one you want to select.{' '}
					</p>
					<p className='mb-3'>
						You are able to change the facility in your view at any time, although you&apos;ll only be able to view data for
						facilities that you&apos;ve been provisioned access to. If you think something is missing, contact your
						administrator or a member of the Merlin support team.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Settings</p>
					<p className='mb-3'>
						If you&apos;re interested in changing the settings in Merlin, click the circular badge with your initials in the
						top right corner of the page. A dropdown menu with a link to the settings page should appear.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>No Data Scenario</p>
					<p className='mb-3'>
						If you navigate to a page with no data, check the center of your screen. There will either be a message telling
						you there&apos;s nothing to see, or you&apos;ll get instructions about where to go next, like the File Upload
						page.
					</p>
				</Accordion.Item>

				<Accordion.Item value='Overview of the Merlin Modules'>
					<p className='font-semibold text-p1 mb-3'>Core</p>
					<p className='mb-3'>
						The OR is the financial engine of high-performing health systems, but perioperative leaders are often forced to
						make decisions in the dark, unsure how to appropriately measure OR performance. Surgical Directions&apos; Merlin
						Core solution simplifies this process by helping organizations transform their troves of perioperative data into
						targeted, actionable insights. Leveraging our decades of clinical and operational experience, we&apos;ve built a
						reporting package that hospital executives, surgeons, and OR clinical leaders can depend on. Our configurable
						suite of best-practice perioperative KPIs will arm you with the data you need to sustainably improve OR efficiency
						and throughput.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Block</p>
					<p className='mb-3'>
						Operating room time is a precious resource, and hospital administrators are often asked to choose between
						competing block requests that can be difficult to reconcile. Merlin Block helps bring objectivity to these
						challenging conversations, keeping surgeons engaged with personalized reports while providing perioperative
						leaders with the insights they need to maximize the productivity of the OR. Merlin Block also provides an
						expedited pathway for surgeons to release unneeded block, freeing up time for additional surgeons and helping to
						raise the overall efficiency of the OR. Connect with us today to learn how to maximize the use of your OR time.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Heatmap</p>
					<p className='mb-3'>
						Staffing a modern perioperative department is a complex balancing act. If you understaff the department, you run
						the risk of burning out your staff and unnecessarily verticalizing your daily schedule. If you overstaff the
						department, you waste your already scarce resources keeping empty rooms open each day. Finding a happy medium
						requires sophisticated analytical tools that can illuminate the precise needs of your operating rooms. Merlin
						Heatmap offers first-in-kind insights into the day-by-day and hour-by-hour patterns of the operating room,
						allowing you to construct a daily schedule that satisfies your staff and preserves your bottom line.
					</p>
					<p className='font-semibold text-p1 mb-3 mt-8'>Extras</p>
					<p className='mb-3'>
						Efficiently matching resources with demand is one of the most challenging operational problems in healthcare.
						Merlin Extras gives you cutting-edge tools that allows you to visualize coverage scenarios for nuanced staffing
						scenarios, like anesthesia services and perioperative nursing.
					</p>
					<p className='font-semibold text-p1 mb-3 mt-8'>Merlin Anesthesia</p>
					<p className='mb-3'>
						Merlin Anesthesia puts advanced anesthesia staffing tools right at your fingertips. Drill down on
						provider-specific productivity metrics to help make day-to-day anesthesia operations more efficient.
					</p>
					<p className='font-semibold text-p1 mb-3 mt-8'>Merlin Perioperative Nursing</p>
					<p className='mb-3'>
						Merlin Perioperative Nursing helps you manage your day-to-day staffing needs efficiently. It also gives you
						flexible tools to manage your variable, indirect, and support staff and track the productivity of your resources
						at a granular level.
					</p>
				</Accordion.Item>
			</Accordion>

			<h1 className='font-bold text-h4 my-6'>How-To Basics</h1>
			<Accordion type='multiple' itemStyle='contained'>
				<Accordion.Item value='User Roles & Access Levels'>
					<p className='mb-3'>Different settings are available to different access levels in Merlin.</p>
					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<colgroup span={2}></colgroup>
							<tr>
								<th colSpan={3} scope='colgroup'></th>
								<th className='py-3 px-6 text-center font-semibold' colSpan={2} scope='colgroup'>
									Access Level
								</th>
							</tr>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Tab Name
								</th>
								<th className='py-3 px-6' scope='col'>
									Modules
								</th>
								<th className='py-3 px-6' scope='col'>
									Description
								</th>

								<th className='py-3 px-6' scope='col'>
									Administrator
								</th>

								<th className='py-3 px-6' scope='col'>
									User
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 whitespace-nowrap font-semibold'>User Profile</td>
								<td className='py-4 px-6'>All</td>
								<td className='py-4 px-6'>View general information about your user profile</td>
								<td className='py-4 px-6 whitespace-nowrap'>View Only</td>
								<td className='py-4 px-6 whitespace-nowrap'>View Only</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold'>Account Settings</td>
								<td className='py-4 px-6'>All</td>
								<td className='py-4 px-6'>
									View account-specific information like which users can access Merlin and facility license details
								</td>
								<td className='py-4 px-6'>Edit</td>
								<td className='py-4 px-6'>View Only</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold'>Facility Settings</td>
								<td className='py-4 px-6'>All</td>
								<td className='py-4 px-6'>
									View metric targets, adjust desired time windows for analyses, and change other general settings in Merlin.
									Some settings will only impact Core-specific metrics, others will affect metrics globally for Core or Block
									modules across your health system.
								</td>
								<td className='py-4 px-6'>Edit</td>
								<td className='py-4 px-6'>View Only</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 whitespace-nowrap font-semibold'>Surgeons & Rooms</td>
								<td className='py-4 px-6'>All</td>
								<td className='py-4 px-6'>
									This tab is primarily used to manage overall data quality and accuracy. Often times, EHRs utilize legacy data
									sources or do not have adequate data entry controls, causing the potential for mixup both on surgeon and room
									labels. We&apos;ve built tools in Merlin to help mitigate these issues, which can be handled in this settings
									tab.
								</td>
								<td className='py-4 px-6'>Edit</td>
								<td className='py-4 px-6'>View Only</td>
							</tr>

							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold'>Block Settings</td>
								<td className='py-4 px-6'>Block</td>
								<td className='py-4 px-6'>
									Modify rules about how block utilization is calculated and edit, modify, or create new blocks on this tab.
								</td>
								<td className='py-4 px-6'>Edit</td>
								<td className='py-4 px-6'>View Only</td>
							</tr>
						</tbody>
					</table>
				</Accordion.Item>
			</Accordion>

			<Accordion type='multiple' itemStyle='contained' className='mt-2'>
				<Accordion.Item value='Viewing basic user information'>
					<p className='mb-3'>
						To view your basic user information like your name, access level, health system, and which facilities you&apos;re
						permitted to see, navigate to your initials in the top right corner of the page and select “Account”. You should
						arrive at a page with the “User Profile” tab where you can view relevant information.
					</p>
				</Accordion.Item>
			</Accordion>

			<Accordion type='multiple' itemStyle='contained' className='mt-2' defaultValue={[openAccordion]}>
				<Accordion.Item value='Inserting data into Merlin'>
					<p className='mb-3'>
						Click the “Upload” tab in the sidebar. If you&apos;re looking to put new data into Merlin, or see what data has
						recently sent to us automatically, this is your place!
					</p>
					<p className='mb-3'>
						In the center of the page, you can either drag and drop or browse for files to be added into Merlin. Merlin
						accepts .xls, .xlsx, and .csv files for upload. Files must be uploaded one at a time, and no one file can exceed
						200 MB in size.
					</p>
					<p className='mb-3'>
						Below the drag and drop area, you&apos;ll find a table showing the most recently uploaded files, along with some
						status processing information.
					</p>
					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Status Message
								</th>
								<th className='py-3 px-6' scope='col'>
									Description
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 text-green-500 whitespace-nowrap'>Successfully Processed</td>
								<td className='py-4 px-6'>Your data have been successfully processed and inserted into the Merlin system.</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 text-blue-500 whitespace-nowrap'>Processing</td>
								<td className='py-4 px-6'>
									Your data have been received successfully, but have yet to be processed and inserted in Merlin. Depending on
									the amount of data sent, you should check back in an hour or two to see if your data have been successfully
									processed.
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 text-red-500 whitespace-nowrap'>Error</td>
								<td className='py-4 px-6'>
									Oops! Something didn&apos;t go right. Either you sent us some data we couldn&apos;t validate, or we ran into an
									error while processing your data. Send us a message if you need further assistance.
								</td>
							</tr>
						</tbody>
					</table>
					<p className='font-semibold text-p1 mb-3 mt-8'>Understanding data fidelity</p>
					<p className='mb-3'>
						Merlin has tools to help you understand the quality of data received. To get more insights into overall data
						quality, select a row from the uploads table and expand it using the arrow on the right hand side. From there,
						you&apos;ll see a table that lists the overall fidelity of the case records received.
					</p>
					<p className='mb-3'>
						<ul>
							<li>Successful records meet Merlin&apos;s data quality standards and are inserted into the database.</li>
						</ul>
					</p>
					<p className='mb-3'>
						<ul>
							<li>
								Failed records do not meet Merlin’s data quality standards and are excluded from the Merlin database. This can
								cause discrepancies between volume reports directly out of your EHR, but ensures that the perioperative metrics
								are reflective of high quality data, with passing metadata about the case like patient in/out room, room name,
								etc.
							</li>
						</ul>
					</p>
					<p className='mb-3'>
						In cases where you’re submitting data for multiple facilities or locations at a time, you can quickly see a
						drilldown view of the data fidelity by selecting or deselecting by clicking on a facility name from the list in
						the Overall Upload Facility Metrics table on the left hand side. Cases with a green check processed successfully.
						Cases with a red &quot;do not enter&quot; sign did not process successfully.
					</p>
					<p className='mb-3'>
						If you’d like to learn more about data integrity in Merlin, visit the Data Integrity portion of the Advanced
						Functionality section in the Help Center.
					</p>
				</Accordion.Item>
			</Accordion>

			<Accordion type='multiple' itemStyle='contained' className='mt-2' defaultValue={[openAccordion]}>
				<Accordion.Item value='Adding new users'>
					<p className='mb-3'>
						Want to add new users to Merlin? It’s easy! Navigate to your initials in the top right corner of the page and
						select “Account”. From there, select the “Account Settings” tab.
					</p>
					<p className='mb-3'>Note: only hospital administrators can add users to Merlin.</p>
					<p className='mb-3'>
						From the Account Settings tab, click the blue “Add User” button on the top right of the table on the screen. From
						here, fill out some basic information about the user, including First and Last Name, and email address.
					</p>
					<p className='mb-3'>
						The access level dropdown determines the access level the user will have. To learn more about roles and user
						access levels, visit the Settings Overview pane.
					</p>
					<p className='mb-3'>
						In the facilities dropdown, select which facilities that this new user will have access to. Users will not be able
						to see reports from facilities they are not provided access to. Once you’ve selected the facilities that a user
						should be able to see, you need to set a default facility for them. The default facility will be the first
						facility they see when they log in to Merlin.
					</p>
					<p className='mb-3'>
						If a user would like to change their default facility, a health system administrator must enact this change. If a
						user has difficulties contacting their designated administrator, contact the Merlin team.
					</p>
				</Accordion.Item>
			</Accordion>

			<Accordion type='multiple' itemStyle='contained' className='mt-2'>
				<Accordion.Item value='Modify user access levels'>
					<p className='mb-3'>
						Only administrators can change user permissions. Navigate to your initials in the top right corner of the page and
						select “Account”. From there, select the “Account Settings” tab. Identify the user that you’d like to modify
						permissions for. Hit the “Edit” button. From there, select the desired access level from the “Access Level (Role)”
						dropdown menu. Hit “Update User” to complete the change.
					</p>
				</Accordion.Item>
			</Accordion>

			<Accordion type='multiple' itemStyle='contained' className='mt-2'>
				<Accordion.Item value='Delete a user'>
					<p className='mb-3'>
						Only administrators can delete users. Navigate to your initials in the top right corner of the page and select
						“Account”. From there, select the “Account Settings” tab. Identify the user that you’d like to remove from Merlin.
						Hit the “Delete” button. The user will no longer be permitted to access Merlin.
					</p>
					<p className='mb-3'>
						If you’ve deleted a user’s access by mistake, you can simply re-add them. If you experience any difficulty
						re-adding a mistakenly deleted user, contact a member of the Merlin team.
					</p>
				</Accordion.Item>
			</Accordion>

			<Accordion type='multiple' itemStyle='contained' className='mt-2' defaultValue={[openAccordion]}>
				<Accordion.Item value="Viewing your account's license details">
					<p className='mb-3'>
						If you want to see how many active licenses you have for a given facility, navigate to your initials in the top
						right corner of the page and select “Account”. From there, go to the “Account Settings” page and go to the
						“Facility License Details” table.
					</p>
					<p className='mb-3'>
						This table includes basic information about your facility licenses, including module type and the number of room
						licenses. If you want to see which rooms are licensed or unlicensed, expand the row by hitting the circular button
						to on the right side of the table. This will tell you the name and license status of each room.
					</p>
				</Accordion.Item>
			</Accordion>

			<Accordion type='multiple' itemStyle='contained' className='mt-2'>
				<Accordion.Item value='Changing facility settings'>
					<p className='mb-3'>
						Only Health System Administrators can change facility settings. There are several options a user has to change
						their facility settings. To view or modify settings, navigate to your initials in the top right corner of the page
						and select “Settings”. From there, select the “Facility Settings” tab.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>First Case On-Time Starts Target</p>
					<p className='mb-3'>
						Use the slider to set the FCOTS Rate (%) target for your organization. Changing the target should change the
						position of the target line on the Timely Starts page.
					</p>
					<p className='font-semibold text-p1 mb-3 mt-8'>First Case On-Time Starts Window</p>
					<p className='mb-3'>
						This value changes the window of time in which a case can be considered a first case. For example, a case with a
						patient in room time at 04:37 AM is likely not a first case of the day, but rather, an emergent case starting
						early in the morning. Similarly, a case starting at 11:00 AM might be the first case in that particular room, but
						is not subject to the same constraints experienced in pre-op preparation areas.
					</p>
					<p className='mb-3'>
						While these values are static for now, a user will be able to modify them in the future to more adequately capture
						cases that could be considered “first cases”
					</p>
					<p className='mb-3'>
						Note: this window is not the sole determinant of what is considered a “first case”. For more information, visit
						the First Case On-Time Starts section in the <strong>Common Definitions</strong> section.
					</p>
					<p className='font-semibold text-p1 mb-3 mt-8'>First Case On-Time Starts Timestamp</p>
					<p className='mb-3'>
						This setting allows the user to specify which timestamp should be used to determine whether a case is on-time or
						not. This change is applied for all FCOTS rate calculations for the facility of interest. So, for example,
						Facility A could use “Patient In Room”, and Facility B could use “Procedure Start” if so desired.
					</p>
					<p className='font-semibold text-p1 mb-3 mt-8'>Turnover Time Target</p>
					<p className='mb-3'>
						The turnover time target affects several properties throughout the application and can be set at the individual
						facility level. A turnover is considered “on target” if it is at or below the target set for that particular
						facility. Generally, decreasing the turnover time target will reduce the quantity of “on target” turnovers, and
						increasing the target will increase the quantity of “on target turnovers”. The value ranges from 5 to 60 minutes.{' '}
					</p>
					<p className='mb-3'>
						<span className='font-semibold text-p2 text-yellow-600 whitespace-nowrap'>User Warning</span>: using a turnover
						target that is above the turnover threshold can potentially result in uninterpretable results. It&apos;s
						recommended that the turnover time target is always greater than the turnover time threshold value (where
						applicable).
					</p>
					<p className='mb-3'>
						The turnover target impacts calculations and other visual management features on the following pages:
					</p>

					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-6'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Page
								</th>
								<th className='py-3 px-6' scope='col'>
									Turnover Target Effect
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Core Dashboard</td>
								<td className='py-4 px-6'>
									<p>The core dashboard provides a gauge chart showing the percentage of turnovers that were “on target”.</p>
									<p className='opacity-0'>-</p>
									<p>
										Note: the turnover threshold defaults to 60m on this page. There are currently no filters available to change
										this value.
									</p>
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Timeline View</td>
								<td className='py-4 px-6'>
									<p>
										The turnover target determines whether a room turnover was considered “on target” or “off target”. If the
										turnover was at or below the target specified in the settings, page, it is flagged as green. If it was above
										the target value, it will be flagged as yellow.
									</p>
									<p className='opacity-0'>-</p>
									<p>
										If the turnover was above the threshold specified in the filters pane, it will not appear, meaning it is not
										considered a “valid” turnover. For more on turnover thresholds, visit the Turnover Time area in the{' '}
										<strong>Common Definitions</strong> section.
									</p>
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Turnover Time</td>
								<td className='py-4 px-6'>The target line can be seen as a dotted line on the Turnover Time page.</td>
							</tr>

							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Surgeon Scorecard</td>
								<td className='py-4 px-6'>
									“On target” turnovers are reported on this page. Changing the turnover target will thus impact the rate at
									which an individual surgeon was “on target”.
								</td>
							</tr>
						</tbody>
					</table>
					<p className='font-semibold text-p1 mb-3 mt-8'>Facility Utilization Target</p>
					<p className='mb-3'>
						The facility utilization target impacts the dotted line on the “Primetime Facility Utilization” bar chart.
					</p>
					<p className='font-semibold text-p1 mb-3 mt-8'>Facility Add-On Rate Target</p>
					<p className='mb-3'>
						The target add-on rate is the quotient of add-ons and total case volume. Typically, a lower add-on rate can
						signify higher daily predictability, as add-ons are often a source of unplanned schedule variation.
					</p>
					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-6'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Page
								</th>
								<th className='py-3 px-6' scope='col'>
									Add-On Target Effect
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Core Dashboard</td>
								<td className='py-4 px-6'>
									<p>
										The core dashboard provides a calculated add-on rate and target add-on rate. Changing this setting will affect
										the add-on rate shown on this page.
									</p>
								</td>
							</tr>
						</tbody>
					</table>

					<p className='font-semibold text-p1 mb-3 mt-8'>Primetime Settings</p>
					<p className='mb-3'>
						Changing these settings will impact the Facility Utilization Report. You can adjust the primetime window and
						available rooms to adjust available minutes depending on the day of the week.
					</p>
					<p className='mb-3'>
						Select which time periods are considered primetime by clicking the box above the desired time period. A minimum of
						one time period must be selected as primetime. Primetime periods must be adjacent to one another. Any
						discontinuities in time periods may result in inaccurate results, so be sure to check for a red broken link icon
						in case time periods aren&apos;t continuous.
					</p>

					<p className='mb-3 mt-6'>
						By default, weekends are not included in the facility utilization report. The user can choose to include them by
						checking the “Include Weekends” box. If this box is checked, the lowest “Staffed Rooms during Primetime” value is
						1.
					</p>
					<p className='font-semibold text-p1 mb-3 mt-8'>How are case minutes classified?</p>
					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-6'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Minutes
								</th>
								<th className='py-3 px-6' scope='col'>
									In Primetime
								</th>
								<th className='py-3 px-6' scope='col'>
									Non-Primetime
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Case Minutes</td>
								<td className='py-4 px-6'>
									Case minutes (minutes between patient in room and patient out room) that occur within the primetime start and
									primetime end window.
								</td>
								<td className='py-4 px-6'>
									Case minutes (minutes between patient in room and patient out room) that occur outside of the primetime start
									and primetime end window.
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Turnovers</td>
								<td className='py-4 px-6'>
									Room turnover minutes (minutes between patient out room and patient in room for next case in the same room)
									that occur within the primetime start and primetime end time window.
								</td>
								<td className='py-4 px-6'>
									<p>
										Room turnover minutes (minutes between patient out room and patient in room for next case in the same room)
										that occur outside the primetime start and primetime end time window.
									</p>
									<p className='opacity-0'>-</p>
									<p>
										While there are technically “non-primetime” turnover minutes, these do not count toward primetime facility
										utilization, and are thus not reported on the facility utilization page.{' '}
									</p>
									<p className='opacity-0'>-</p>
									<p>
										For more details on how to view non-primetime turnovers read the Primetime Classification Strictness section.
									</p>
								</td>
							</tr>
						</tbody>
					</table>
					<p className='font-semibold text-p1 mb-3 mt-8'>Primetime Classification Strictness</p>
					<p className='mb-3'>
						We classify cases as “in primetime” or “non-primetime” dependent on where the case minutes happen relative to the
						primetime window set in the Primetime Settings section. Depending on the “Strict” or “Lenient” setting, certain
						cases will be labeled as “in primetime” or “non-primetime” and can be filtered on certain pages, such as the
						Turnover Time, Surgical Volume, and Flip Rooms pages.
					</p>
					<p className='mb-3'>
						This setting will determine which cases and turnovers for those cases are considered in primetime or not in
						primetime.
					</p>
					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-6'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Level
								</th>
								<th className='py-3 px-6' scope='col'>
									Classification
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Strict</td>
								<td className='py-4 px-6'>
									<p>
										{
											'Cases with > 80% of their total case minutes in primetime. Thus, if a case starts in primetime and less than 80% of its total minutes occurred within primetime, it will be classified as “non-primetime”.'
										}
									</p>
									<p className='opacity-0'>-</p>
									<p>
										For example, if a facility&apos;s primetime window ends at 3:30, and a case begins at 3:15 and ends at 4:17
										PM, this would be labeled a “non-primetime” case because only 24% (15/62) of its total case minutes were in
										primetime. This is more strict because it will label fewer edge cases as “in primetime”.
									</p>
									<p className='opacity-0'>-</p>
									<p className='mb-3'>
										When using the primetime filter, using the Strict setting may reduce the sample size of interest, as it
										imposes harder limits on which cases can be considered “primetime”.
									</p>
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Lenient</td>
								<td className='py-4 px-6'>
									<p>
										{
											'Cases with > 20% of their total case minutes in primetime. Thus, if a case starts in primetime and less than 20% of its total minutes occurred within primetime, it will be classified as “non-primetime”.'
										}
									</p>
									<p className='opacity-0'>-</p>
									<p>
										For example, if the primetime start window is at 7:30 AM and an emergency case starts at 6:45 AM and completes
										at 7:52 AM, this would be labeled as a “in primetime” case because 32% (22/67) of its total case minutes were
										in primetime. This is more lenient because it will label more edge cases as “in primetime”.
									</p>
								</td>
							</tr>
						</tbody>
					</table>
					<p className='mt-6'>
						Average turnover times for cases that are labeled as “non-primetime” via the primetime classification strictness
						setting mentioned above can be viewed pages that have a primetime filter.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-6'>Holidays</p>
					<p className='mb-3'>
						By default, all US holidays listed in the “Holidays Included” dropdown menu are included. If you know that your
						facility honors specific holidays and wish for them to be excluded, uncheck the name of the relevant holidays.
					</p>

					<p className='mb-3'>
						<span className='font-semibold text-p2 text-yellow-600 whitespace-nowrap'>User Warning</span>: unselected holidays
						will not be included in any reports on Merlin for the facility selected. This could cause discrepancies between
						numbers reported in Merlin and other reports produced by your hospital or health system. Also, we recognize that
						some health systems span different geographic regions and may honor different holidays, so make sure relevant
						holidays are accounted for at each facility.
					</p>
				</Accordion.Item>
			</Accordion>

			<Accordion type='multiple' itemStyle='contained' className='mt-2' defaultValue={[openAccordion]}>
				<Accordion.Item value='Changing Schedule Settings'>
					<p className='mb-3'>
						To change schedule settings, navigate to your initials in the top right of the page. Select “Settings &gt;
						Schedule Settings”.
					</p>
					<p className='font-semibold text-p1 mb-3 mt-6'>Record Type Mapping</p>
					<p className='mb-3'>
						Many scheduling systems use log-style databases that name certain actions taken on scheduled cases. For example,
						if a case is created to be scheduled in the future, this record type might be “CREATION”. If that same case is
						canceled, the record type may list “CANCELED”. This helps the system keep a log of actions performed on each case
					</p>
					<p className='mb-3'>
						The record type mapping feature allows users to combine record types if they follow similar definitions from a
						reporting standpoint. To map to a known record type, use the dropdown menu to map the found record type to the
						known record type. To save this, hit the “Save” button.
					</p>
					<p className='font-semibold text-p1 mb-3 mt-6'>Planned Setup & Cleanup</p>
					<p className='mb-3'>
						Some scheduling systems include setup and cleanup to the scheduled case time. Others do not include this and count
						on the system to add a buffer automatically. If setup and cleanup time is not included in the scheduled case time,
						users can add buffer time to each scheduled case using these sliders.
					</p>
				</Accordion.Item>
			</Accordion>

			<Accordion type='multiple' itemStyle='contained' className='mt-2'>
				<Accordion.Item value='Changing block settings'>
					<p className='mb-3'>
						To change block settings, navigate to your initials in the top right of the page. Select “Settings &gt; Block
						Settings”. Block settings are organized into global “Health System Settings” and facility-specific settings.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Health System Settings</p>
					<p className='mb-3'>
						Health system settings are applied globally, meaning they affect all facilities in the same way. If you have
						questions about how these settings may impact your utilization, please contact the Merlin team for more details.
					</p>

					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-6'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Setting
								</th>
								<th className='py-3 px-6' scope='col'>
									Description
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Give Block Credit Regardless of Facility</td>
								<td className='py-4 px-6'>
									<p>
										This setting allows blocks to be credited regardless of which facility a case was performed in, as long as it
										happened within the bounds of the allocated date and time range.
									</p>
									<p className='opacity-0'>-</p>
									<p>
										Setting ON: this setting is useful in cases where Merlin has been configured with a Main OR with another
										department, such as an attached HOPD, listed as a separate facility. Surgeons may use rooms in either
										facility, and may want their block credited regardless of which facility they use. In this case, the setting
										should be set to ON.
									</p>
									<p className='opacity-0'>-</p>
									<p className='mb-3'>
										Setting OFF: if blocks are assigned to different facilities and the system does NOT want a block to get credit
										if cases were performed in a facility that is different from where the block was assigned, this setting should
										be set to OFF.
									</p>
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Allow Block Overbooking</td>
								<td className='py-4 px-6'>
									<p>
										Set whether a configuration of the schedule should allow or disallow the multi-booking of a surgeon/surgeons
										on the same day if they belong to multiple blocks.
									</p>
									<p className='opacity-0'>-</p>
									<p>
										We strongly encourage users to not overbook blocks, ensuring that surgeons are only allocated one block per
										day in one location. We understand situations arise where this may not be the case, but assigning surgeons to
										two different blocks at the same time creates scenarios where either both or neither block is credited,
										resulting in utilization that is either over-inflated or under-inflated, depending on how the overbooking is
										configured.
									</p>
								</td>
							</tr>
						</tbody>
					</table>
					<p className='font-semibold text-p1 mb-3 mt-8'>Facility-Specific Settings</p>
					<p className='mb-3'>Facility-specific settings impact only the facility they are applied to.</p>

					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-6'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Setting
								</th>
								<th className='py-3 px-6' scope='col'>
									Description
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Negate Voluntary Release Credit</td>
								<td className='py-4 px-6'>
									<p>Negate voluntary release credit if a case is performed in the released block.</p>
									<p className='opacity-0'>-</p>
									<p>
										{`Setting ON: in the scenario where a surgeon releases their block and ends up working during their allocated time (meaning in block minutes > 0), this setting will reverse the release and treat it like a normally allocated day. This setting is useful in scenarios where surgeons have a high incidence of release (or partial release) in an attempt to “inflate” their utilization percentage.`}
									</p>
									<p className='opacity-0'>-</p>
									<p className='mb-3'>
										Setting OFF: in the same scenario as above, the case performed in block would not could toward utilization,
										and the block would stay voluntarily released. The in block minutes performed would not contribute towards
										overall utilization. This is useful in scenarios when a surgeon meant to release their block but needed to
										perform an urgent or emergent case which happened to fall within their regularly allocated block time.
									</p>
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Allow Partial Voluntary Block Release</td>
								<td className='py-4 px-6'>
									<p>
										Some institutions allow for partial release of block, meaning a surgeon can release some or all of the block
										&apos;ve been allocated on a certain day.
									</p>
									<p className='opacity-0'>-</p>
									<p>
										Setting ON: users can release portions of an allocated block. Proceed with caution, however - this can result
										in under-utilized blocks appearing well utilized because of retroactive/proactive partial release. If block is
										not released judiciously, often times a hospital will have difficulty filling the released time with volume,
										resulting in a block that appears well-utilized despite lower case volume than expected.
									</p>
									<p className='opacity-0'>-</p>
									<p>
										Setting OFF: when releasing a block, the entire allocation for a given day must be released. This provides the
										hospital with more flexibility with respect to staffing or filling the time with urgent or emergent cases.
									</p>
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Block Utilization Target Percentage</td>
								<td className='py-4 px-6'>
									<p>Change this setting to modify what the minimum block utilization percentage should be.</p>
								</td>
							</tr>
						</tbody>
					</table>
				</Accordion.Item>
			</Accordion>

			<Accordion type='multiple' itemStyle='contained' className='mt-2' defaultValue={[openAccordion]}>
				<Accordion.Item value='Changing Merlin Extras Settings'>
					<p className='mb-3'>Merlin Extras have several different settings that will impact results found on its pages.</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Anesthesia Settings</p>
					<p className='mb-3'>
						Use these settings to modify how anesthesiology staffing is modeled in Merlin Anesthesia. To change Merlin
						Anesthesia settings, navigate to your initials in the top right of the page. Select “Settings &gt; Anesthesia
						Settings”.
					</p>

					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-6'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Setting
								</th>
								<th className='py-3 px-6' scope='col'>
									Description
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>Medical Direction or Supervision Model</td>
								<td className='py-4 px-6'>
									Change whether the facility staffs via a “Physician Only”, “CRNA Only”, or “Care Team” model. Generally, health
									systems are interested to see what staffing model is most cost effective and provides the highest quality
									outcomes for patients.
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>Average weekly clinical hours per provider</td>
								<td className='py-4 px-6'>
									This value represents the average expected clinical hours by provider type. Generally, higher values will
									result in lower FTEs.
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>Average vacation weeks per provider</td>
								<td className='py-4 px-6'>
									This value represents the average expected weeks of vacation by provider type. This decreases the amount of
									clinical coverage provided. Generally, higher values will result in higher FTEs.
								</td>
							</tr>
						</tbody>
					</table>
					<p className='font-semibold text-p1 mb-3 mt-8'>Perioperative Nursing Settings</p>
					<p className='mb-3'>
						Use these settings to modify how perioperative nurse staffing is modeled. To change Merlin Perioperative Nurse
						Staff settings, navigate to your initials in the top right of the page. Select “Settings &gt; Perioperative
						Nursing Settings”.
					</p>
					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-6'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Setting
								</th>
								<th className='py-3 px-6' scope='col'>
									Description
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>Average weekly clinical hours per staff type</td>
								<td className='py-4 px-6'>
									This value represents the average expected clinical hours by provider type. Generally, higher values will
									result in lower FTEs.
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>Average vacation weeks per provider</td>
								<td className='py-4 px-6'>
									This value represents the average expected weeks of vacation by provider type. This decreases the amount of
									clinical coverage provided. Generally, higher values will result in higher FTEs.
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>Productivity Bounds</td>
								<td className='py-4 px-6'>
									These bounds represent the target range for total staff productivity. Changing the bounds impacts color coding
									in the visualization.
								</td>
							</tr>
						</tbody>
					</table>
				</Accordion.Item>
			</Accordion>

			<Accordion type='multiple' itemStyle='contained' className='mt-2' defaultValue={[openAccordion]}>
				<Accordion.Item value='Accounting for new Surgeons'>
					<p className='mb-3'>
						To create or modify surgeon aliases, navigate to your initials in the top right of the page. Select “Aliases &gt;
						Surgeons”. Sometimes, surgeons have multiple aliases stored in the EHR. It may be an alternate spelling of their
						name, or even just additional whitespace. For example:
					</p>
					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-6'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Initial Surgeon Name
								</th>
								<th className='py-3 px-6' scope='col'>
									Similar Surgeon Name
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>John Smith, MD</td>
								<td className='py-4 px-6'>John Smith , MD</td>
							</tr>
						</tbody>
					</table>
					<p className='mb-3 mt-6'>
						It&apos;s important to be able to catch these minor differences, especially if Dr. Smith has block time. If new
						data are inserted into Merlin and Dr. Smith&apos;s cases are performed as John Smith , MD instead of John Smith,
						MD, they will be interpreted as different surgeons and Dr. Smith&apos;s block will not be credited appropriately.
					</p>
					<p className='mb-3'>
						Merlin has tools to catch these minor differences and allows users to assign surgeons with a high similarity score
						as an alias of a previously found surgeon name. To check for this discrepancy, navigate to the top right corner of
						the page and select the circular icon with your initials. Select the “Settings” button, and navigate to the
						“Surgeons & Rooms” page. Find the “New Surgeon Mapping & Resolution” table.
					</p>
					<p className='mb-3'>
						In this table, you will find a list of surgeons that have a high similarity scores (above 80%) with other surgeons
						in the intraoperative record. If you recognize that a surgeon with a high similarity score is a surgeon
						that&apos;s already been accounted for, you can map them to an existing surgeon name by using the searchable
						dropdown menu in that surgeon&apos;s row.
					</p>
					<p className='mb-3'>
						If the surgeon you found is actually a unique surgeon, you can just leave them be. This could be the case if you
						have a John Smith, MD and Josh Smith, MD who are both distinct surgeons.
					</p>
					<p className='mb-3'>
						If you notice something particularly anomalous that needs to be resolved, contact a member of the Merlin team.
					</p>
					<p className='font-semibold text-p1 mb-3 mt-8'>Changing or Correcting an Existing Surgeon Mapping</p>
					<p className='mb-3'>
						Merlin keeps a record of all these mappings that can be edited. To add or modify a mapping, go to the “Existing
						Surgeon Mapping” table and select. This will open a popup window. Select the surgeon you want to map an alias to
						in the “EXISTING SURGEON” dropdown. Then, use the MULTIPLE ALIASES dropdown to pick the surgeon names that you
						want to be aliases of the existing name. Select Submit to create the mapping.
					</p>
					<p className='opacity-0'>-</p>
					<p>
						Once this is submitted, all instances of the selected aliases will be shown as the name picked in the EXISTING
						SURGEON dropdown.
					</p>
				</Accordion.Item>
			</Accordion>

			<Accordion type='multiple' itemStyle='contained' className='mt-2' defaultValue={[openAccordion]}>
				<Accordion.Item value='Accounting for new anesthesia providers'>
					<p className='mb-3'>
						To create or modify anesthesia provider aliases, navigate to your initials in the top right of the page. Select
						“Aliases &gt; Anesthesia Provider”.
					</p>
					<p className='mb-3'>
						Sometimes, anesthesia providers (anesthesiologists and anesthetists) have multiple aliases stored in the EHR. It
						may be an alternate spelling of their name, or even just additional whitespace. For example:
					</p>
					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-6'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Initial Anesthesia Provider Name
								</th>
								<th className='py-3 px-6' scope='col'>
									Similar Anesthesia Provider Name
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>John Smith, MD</td>
								<td className='py-4 px-6'>John Smith , MD</td>
							</tr>
						</tbody>
					</table>
					<p className='mb-3 mt-6'>
						It&apos;s important to be able to catch these minor differences. If new data are inserted into Merlin and Dr.
						Smith&apos;s cases are performed as John Smith , MD instead of John Smith, MD, they will be interpreted as
						different anesthesia providers and Dr. Smith&apos;s time will not be credited appropriately.
					</p>
					<p className='mb-3'>
						Merlin has tools to catch these minor differences and allows users to assign anesthesia providers with a high
						similarity score as an alias of a previously found anesthesia provider name. To check for this discrepancy,
						navigate to the top right corner of the page and select the circular icon with your initials. Select the
						“Settings” button, and navigate to the “Anesthesia Provider” tab. Find the “New Anesthesia Provider Mapping &
						Resolution” table. Use the button on the right to choose “Anesthesiologist” or “Anesthetist”.
					</p>
					<p className='mb-3'>
						In this table, you will find a list of anesthesia providers that have a high similarity scores (above 80%) with
						other anesthesia providers in the intraoperative record. If you recognize that an anesthesia provider with a high
						similarity score is an anesthesia provider that&apos;s already been accounted for, you can map them to an existing
						anesthesia provider name by using the searchable dropdown menu in that anesthesia provider&apos;s row.
					</p>
					<p className='mb-3'>
						If the anesthesia provider you found is unique, hit the blue “New Provider” text on the right hand side of the
						table. This could be the case if you have a John Smith, MD and Josh Smith, MD who are both distinct anesthesia
						providers.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Changing or Correcting an Anesthesia Provider Mapping</p>
					<p className='mb-3'>
						Merlin keeps a record of all these mappings that can be edited. To add or modify a mapping, go to the “Existing
						Provider Mapping” table and select. This will open a popup window. Select the provider you want to map an alias to
						in the “EXISTING PROVIDER” dropdown. Then, use the MULTIPLE ALIASES dropdown to pick the surgeon names that you
						want to be aliases of the existing name. Select Submit to create the mapping.
					</p>
					<p className='opacity-0'>-</p>
					<p>
						Once this is submitted, all instances of the selected aliases will be shown as the name picked in the EXISTING
						PROVIDER dropdown.
					</p>
				</Accordion.Item>
			</Accordion>

			<Accordion type='multiple' itemStyle='contained' className='mt-2' defaultValue={[openAccordion]}>
				<Accordion.Item value='Accounting for new procedural locations'>
					<p className='mb-3'>
						To create or modify room aliases, navigate to your initials in the top right of the page. Select “Aliases &gt;
						Rooms”.
					</p>
					<p className='mb-3'>
						Sometimes, the name of your procedural area in your intraoperative data changes. You may add new information like
						a room id number, or just change the name for easy identification. For Example:
					</p>
					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-6'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Initial Room Name
								</th>
								<th className='py-3 px-6' scope='col'>
									Similar Room Name
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>OR 01 [12345]</td>
								<td className='py-4 px-6'>OR 01</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>OR 05</td>
								<td className='py-4 px-6'>OR 05 L</td>
							</tr>
						</tbody>
					</table>
					<p className='mb-3 mt-6'>
						Merlin has tools to catch these minor differences and allows users to map newly found unique room names to
						existing room licenses, provided they represent the same physical location as an existing licensed room. To check
						for this discrepancy, navigate to the top right corner of the page and select the circular icon with your
						initials. Select the “Settings” button, and navigate to the “Rooms” page. Find the “New Rooms” table.
					</p>
					<p className='mb-3'>
						If the new room name is actually just an alias for an existing licensed room, you can map the similar room name to
						an existing licensed room using the searchable dropdown menu in the row of the newly found room. This way, a
						licensed room and its alias are treated the same.
					</p>
					<p className='mb-3'>
						There may be incidences where you want different names for the rooms. Mapping is by no means required. If you have
						questions about how this might impact your total number of licensed locations, please contact the Merlin team and
						&apos;d be happy to assist you.
					</p>
					<p className='font-semibold text-p1 mb-3 mt-8'>Changing or Correcting an Existing Room Mapping</p>
					<p className='mb-3'>
						Merlin keeps a record of all these mappings that can be edited. To add or modify a mapping, go to the “Existing
						Room Mapping” table and select. This will open a popup window. Select the room you want to map an alias to in the
						“EXISTING ROOM” dropdown. Then, use the MULTIPLE ROOM ALIASES dropdown to pick the ROOM names that you want to be
						aliases of the existing name. Select Submit to create the mapping.
					</p>
					<p className='opacity-0'>-</p>
					<p>
						Once this is submitted, all instances of the selected aliases will be shown as the name picked in the EXISTING
						ROOM dropdown.
					</p>

					<p className='mb-3 text-gray-400 italic text-p3'>
						Disclaimer: This self-service feature is intended to allow users to maintain data integrity by ensuring newly
						found procedural area names are accurately accounted for, and is not intended to allow users to consolidate
						multiple distinct procedural areas under one pre-existing license. Doing so would be a misuse of the feature, and
						could result in lost or inaccurate data within Merlin. Surgical Directions reserves the right to alias or de-alias
						a room at its sole discretion. Distinct or separate procedural areas require separate licenses in accordance with
						&apos;Surgical Directions&apos; Master Services Agreement. Providing new licenses for newly found procedural areas
						may come at an additional cost.
					</p>
				</Accordion.Item>
			</Accordion>

			<Accordion type='multiple' itemStyle='contained' className='mt-2'>
				<Accordion.Item value='Combining Facilities'>
					<p className='mb-3'>
						Sometimes there is a need to view metrics for facilities together. This could be because the separate “facilities”
						are really just departments within one larger facility, or because you’re interested at looking at metrics in
						aggregate across numerous combinations of different facilities.
					</p>
					<p className='opacity-0'>-</p>
					<p>
						In either scenario, Merlin provides you with tools to combine any number of facilities into a custom “combined”
						facility. This combined facility is referred to as a “parent”, and the facilities included in the grouping are
						referred to as “child” facilities. There is currently no limit to the number of parent facilities that can be
						created, but if you create numerous combined facilities with the same children facilities, it could lead to
						confusion, so proceed with caution and always check to ensure while child facilities have been included in the
						combination facility.
					</p>
					<p className='font-semibold text-p1 mb-3 mt-8'>Creating a Combined Facility</p>
					<p className='mb-3'>
						To create a new combined facility, click the green accordion row called “Add new combined facility”. Name your
						combined facility and select which child facilities will be included from the dropdown menu. Click the “Create”
						button. You will receive a confirmation that your combined facility was successfully created.
					</p>
					<p className='font-semibold text-p1 mb-3 mt-8'>License Types</p>
					<p className='mb-3'>Combined facilities are limited to the lowest license type of any of the child facilities.</p>
					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-6'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Facility
								</th>
								<th className='py-3 px-6' scope='col'>
									License Type
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>Facility A</td>
								<td className='py-4 px-6'>Core</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>Facility B</td>
								<td className='py-4 px-6'>Block</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>Facility C</td>
								<td className='py-4 px-6'>Pro</td>
							</tr>
						</tbody>
					</table>
					<p className='mb-3 mt-6'>Combined facility license types work as follows:</p>
					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-6'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Combination Facility
								</th>
								<th className='py-3 px-6' scope='col'>
									Child Facilities
								</th>
								<th className='py-3 px-6' scope='col'>
									License Type
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>Combo 1</td>
								<td className='py-4 px-6'>Facility A, Facility B</td>
								<td className='py-4 px-6'>Core</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>Combo 2</td>
								<td className='py-4 px-6'>Facility A, Facility C</td>
								<td className='py-4 px-6'>Core</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>Combo 3</td>
								<td className='py-4 px-6'>Facility B, Facility C</td>
								<td className='py-4 px-6'>Block</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>Combo 4</td>
								<td className='py-4 px-6'>Facility A, Facility B, Facility C</td>
								<td className='py-4 px-6'>Core</td>
							</tr>
						</tbody>
					</table>
					<p className='font-semibold text-p1 mb-3 mt-8'>Blended Settings</p>
					<p className='mb-3'>
						Combined facilities allow for some settings to be set for the combined facility itself. Other settings rely on the
						facility settings from the child facilities, and may be “blended” together.
					</p>

					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-6'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Setting
								</th>
								<th className='py-3 px-6' scope='col'>
									Description
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>First Case On-Time Starts Target</td>
								<td className='py-4 px-6'>Unique to the combined facility</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>First Case On-Time Starts Window</td>
								<td className='py-4 px-6'>Earliest START and latest END of all child facilities</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>First Case On-Time Starts Timestamp</td>
								<td className='py-4 px-6'>Uses the timestamp setting from each respective child facility</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>Turnover Time Target</td>
								<td className='py-4 px-6'>Unique to the combined facility</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>Facility Utilization Target</td>
								<td className='py-4 px-6'>Unique to the combined facility</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>Facility Add-On Rate Target</td>
								<td className='py-4 px-6'>Unique to the combined facility</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>Primetime Settings</td>
								<td className='py-4 px-6'>
									<p className='mb-3'>
										The primetime start window takes the earliest primetime start value and the latest primetime end value by day
										of week from all respective child facilities.
									</p>
									<p className='opacity-0'>-</p>
									<p className='mb-3'>
										Staffed rooms during primetime is the combination of all values from all respective child facilities by day of
										week.
									</p>
									<p className='opacity-0'>-</p>
									<p className='mb-3'>
										The primetime start window takes the earliest primetime start value and the latest primetime end value by day
										of week from all respective child facilities.
									</p>
									<p className='opacity-0'>-</p>
									<p className='mb-3'>
										If there are weekend days included for any child facility, it will be counted in the combined facility.
									</p>
									<p className='opacity-0'>-</p>
									<p className='mb-3'>
										If you wish to change the number of staffed rooms for the combined facility, you must change the number of
										staffed rooms for child facilities such that they sum up to the desired number of rooms.
									</p>
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>Primetime Classification Strictness</td>
								<td className='py-4 px-6'>
									If any child facility uses the “Lenient” setting, the combined setting will be set to “Lenient”. If you want to
									use the “Strict” setting for a combined facility, all child facilities must use the “Strict” setting.
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6'>Holidays</td>
								<td className='py-4 px-6'>
									<p className='mb-3'>Included holidays are a combination of holidays from child facilities.</p>
									<p className='opacity-0'>-</p>
									<p className='mb-3'>
										In some cases, this may cause the volumes to not be perfectly additive. For example, if one facility includes
										Thanksgiving Day, and another includes Christmas Day, the combined facility will include both Thanksgiving and
										Christmas Day, resulting in the possibilities that the combined volume does not match perfectly because of the
										exclusion of certain holidays in the combined facility.
									</p>
									<p className='opacity-0'>-</p>
									<p className='mb-3'>
										One way to ensure that parent/combined facility volume is additive from child facilities is to make sure that
										the included holidays match exactly for all child facilities.
									</p>
								</td>
							</tr>
						</tbody>
					</table>

					<p className='font-semibold text-p1 mb-3 mt-8'>Rebuilding Facility</p>
					<p className='mb-3'>
						A combined facility must be “rebuilt” any time settings that rely on child facilities are changed. To be safe, it
						is good practice to rebuild the combination facility any time settings in Combined or child facilities are
						changed, respectively. This ensures things like the Primetime Settings for each child facility carries through to
						the combination facility.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Block Settings</p>
					<p className='mb-3'>Note: this section applies to customers with Block and Pro facilities only..</p>
					<p className='opacity-0'>-</p>
					<p className='mb-3'>
						In Block Settings, there is a “Give Block Credit Regardless of Facility” setting. This allows a block to be
						credited regardless of which facility the case was performed in, even if the block was assigned to a facility
						different from where the case was performed.{' '}
					</p>
					<p className='opacity-0'>-</p>
					<p className='mb-3'>
						If this setting is set to “OFF” and a combined facility is selected, the block scorecard will behave as if the
						setting were “ON”. For example, if a surgeon is assigned block in Facility A, but they do some cases in Facility
						B, with an “OFF” setting, the cases from Facility B will not appear and thus not count toward their block
						utilization. However, if Facility A and B are children in a combined facility, cases from both facilities will
						still appear in the combined facility, essentially negating the setting.
					</p>
				</Accordion.Item>
			</Accordion>

			<h1 className='font-bold text-h4 my-6' id='commonDefinitions'>
				Common Definitions
			</h1>
			<Accordion type='multiple' itemStyle='contained' defaultValue={[openAccordion]}>
				<Accordion.Item value='First Case On-Time Starts'>
					<p className='mb-3'>
						The Timely Starts page refers to an organization&apos;s First Case On-Time Starts (FCOTS) performance. The First
						Case On-Time Start Rate (FCOTS Rate) is the sum total of on-time first cases divided by sum of first case starts
						for the day.{' '}
					</p>
					<p className='mb-3'>
						A first case is defined as the minimum patient in room timestamp for a given room and surgeon between the hours of
						the designated “First Case On Time Starts Window”. You can change the START and END values for the window. Cases
						on or after the START window and before the END window will be included as “possible” first cases.
					</p>
					<p className='mb-3'>
						In order to determine what is a first case, we look at the earliest case for a surgeon and a room between the
						START and END time period denoted by the First Case On-Time Starts Window. Because Merlin accounts for the minimum
						timestamp for a given room and surgeon, we do not penalize surgeons with cases occurring in this time window for
						being late for their “second” scheduled case of the day. This can impact surgeons with flip rooms or quick morning
						cases.
					</p>
					<p className='mb-3'>
						A case is deemed on-time if one of the following timestamps, patient in room timestamp (i.e. &quot;wheels
						in&quot;) or procedure start (i.e. “cut time”), occurs before or on the scheduled start timestamp, adjusted for
						the grace minutes allowed. Use the slider in the filter drawer to adjust the quantity of grace minutes to be
						provided. The desired timestamp can be selected from the Facility Settings tab on the Settings page.
					</p>
					<p className='mb-3'>
						It is best-practice to compare patient in room to scheduled start. If your organization instead compares procedure
						start to scheduled start to determine if a case started on time, then this setting can be adjusted in Facility
						Settings (First Case On Time Starts Timestamp).
					</p>
					<p className='mb-3'>
						First case starts can only be on-time or late. Following cases that occur within the first case start time window
						are excluded.
					</p>
				</Accordion.Item>
				<Accordion.Item value='Turnover Time'>
					<p className='mb-3'>
						The general definition of turnover time is the time difference in minutes between one patient exiting the OR
						patient out room to the next patient entering the OR patient in room for cases occurring on the same date of
						service.{' '}
					</p>
					<p className='mb-3'>
						There is a subtle difference between Room Turnover time and Surgeon Turnover time, however. More simply, Room
						Turnover orders the information by Day, Room, and Patient In Room time. Surgeon Turnover orders information based
						on Day, Surgeon, and Patient In Room time. This difference gives us more granular insights into cases in
						succession for a given room, or a surgeon&apos;s successive cases throughout the day.
					</p>
					<p className='mb-3'>
						In order to account for this difference both metrics are available in Merlin, but only Room Turnover will be
						incorporated into facility- or room-aggregated metrics such as average turnover for the facility or facility
						utilization. Similarly, only Surgeon Turnover is used when reporting surgeon-specific turnover figures, like on
						the Surgeon Scorecard, for example.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Room Turnover Time</p>
					<p className='mb-3'>
						Room Turnover calculations improve our ability to understand turnover as it accounts for different surgeons
						following each other in the same OR throughout the day. It also gives a more holistic picture of when the OR sat
						empty between cases. Group blocks, in particular, often involve multiple surgeons sharing a room(s) in a given
						day. Room turnovers follow a room and are surgeon agnostic.
					</p>
					<p className='mb-3'>
						{
							'The difference in minutes between the patient in room timestamp of a following case and the patient out of room timestamp for the preceding case on the same date of service, in the same room. Turnover times above the turnover upper bound are excluded. Turnover times < 0 minutes are excluded. Turnover upper bound can be found in the Filters drawer and can be adjusted to the user&apos;s preference to determine which turnovers should be excluded'
						}
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Surgeon Turnover Time</p>
					<p className='mb-3'>
						While room turnover times give a holistic picture of a facility&apos;s average turnover times, it does not
						adequately reflect how a surgeon experiences turnovers throughout the day. For example, many surgeons begin work
						in one room and are later moved to a different room, which is not captured in room turnover time. Surgeon
						turnovers instead follow a surgeon and are room agnostic.
					</p>
					<p className='mb-3'>
						{
							'The difference in minutes between the patient in room timestamp of a following case and the patient out of room timestamp for the preceding case on the same date of service, for the same surgeon. Turnover times above the turnover upper bound are excluded. Turnover times < 0 minutes are excluded. Turnover upper bound can be found in the Filters drawer and can be adjusted to the user&apos;s preference to determine which turnovers should be excluded.'
						}
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Turnover Thresholds</p>
					<p className='mb-3'>
						A turnover threshold number slider is commonly found on pages that provide turnover metrics. The threshold is used
						to determine what is considered a “valid” turnover. Turnovers above the threshold value are removed from the
						calculation and do not impact the calculation.
					</p>
					<p className='mb-3'>
						There is wide variation between facilities about what constitutes a “valid” turnover. Some institutions have
						planned gaps in their schedule based on surgeon or staff availability. This allows a user to dynamically change
						the threshold to ensure their facility, room, or individual surgeon isn&apos;t unfairly penalized for gaps in the
						schedule that may appear to be a turnover, but were actually a planned.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Flip Rooms</p>
					<p className='mb-3'>
						In both Room and Surgeon Turnover calculations, cases flagged as a “flip case” will receive no turnover credit. A
						flip case is an any case where there is a timestamp overlap for an individual surgeon on a given day. For more
						illustrative flip room scenarios, visit the Flip Rooms subsection.
					</p>
				</Accordion.Item>
				<Accordion.Item value='Flip Rooms'>
					<p className='mb-3'>
						Flip rooms occur when surgeons schedule two or more simultaneously or back-to-back in different rooms. When one
						case is closing, the surgeon &quot;flips&quot; to the different room to begin the following case. Flip room
						turnover time credit varies between organizations. By default, Merlin does not provide turnover credit for either
						room or surgeon turnovers if a surgeon was flipped.
					</p>
					<p className='mb-3'>
						<strong>Example 1</strong>: Surgeon is flipped with overlapping cases
					</p>
					<img className='m-auto mb-6' alt='flip room example' src={flip_room_example} />
					<p className='mb-3'>
						The flip scenario in OR 05 and OR 06 is a common one. The cases performed in these rooms were done by a single
						surgeon. All cases have some degree of overlap, and as such, none are receiving room or surgeon turnover credit.
					</p>
					<p className='mb-3'>
						<strong>Example 2</strong>: Surgeon changes rooms, but is not “flipped” because of no overlapping cases
					</p>
					<img className='m-auto mb-6' alt='flip room example' src={flip_room_example_2} />
					<p className='mb-3'>
						In this scenario, even though the same surgeon had a sequence of cases in different rooms, they were not “flipped”
						because there was no overlapping timestamps between cases. The vertical purple bars highlight the time difference
						between Case 1, 2, and 3, respectively.
					</p>
					<p className='mb-3'>
						<strong>Example 3</strong>: Surgeon 3 changes rooms between cases, room turnover in OR 01
					</p>
					<img className='m-auto mb-6' alt='flip room example' src={flip_room_example_3} />
					<p className='mb-3'>
						Similar to scenario 2, Surgeon 3 will experience a surgeon turnover between case 1 and case 2 even though they
						changed rooms, but is not shown because only room turnover is shown on the timeline view. In this example, the
						turnover threshold was changed to the maximum value (120 minutes), and as such, there will be a room turnover
						found between Surgeon 3 Case 1 and Surgeon 3 Case 3, as room turnover is agnostic to surgeon, it only looks for
						sequential cases in a room.
					</p>
				</Accordion.Item>
				<Accordion.Item value='Add-Ons & Scheduling Patterns'>
					<p className='mb-3'>
						By default, we use the binary add-on label found in the intraoperative EHR data provided by the client. We
						currently do not determine what is an add on via any computational methods. Case data flagging certain cases as
						elective, urgent, or emergent are currently not taken into account.
					</p>
					<p className='mb-3'>
						Some facilities do not label cases as add-ons in their EHR. In this case, several pages will be affected:
					</p>

					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-6'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Page
								</th>
								<th className='py-3 px-6' scope='col'>
									Add-On Label Missing Effect
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Core Dashboard</td>
								<td className='py-4 px-6'>
									The Add-On rate container will return no results and will appear as an empty container.
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Scheduling Patterns</td>
								<td className='py-4 px-6'>This page will return no results.</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Surgeon Scorecard</td>
								<td className='py-4 px-6'>Add-On Rate values and other add-on related metrics will return no results.</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Surgical Volume</td>
								<td className='py-4 px-6'>Selecting the “Add-Ons” label in the filter drawer will return no data.</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Timeline View</td>
								<td className='py-4 px-6'>
									On this page, add-ons are flagged in light blue. If no add-on label is present, none of the cases will be
									flagged in this light blue color.
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Turnover Time</td>
								<td className='py-4 px-6'>Selecting the “Add-Ons” label in the filter drawer will return no data.</td>
							</tr>
						</tbody>
					</table>

					<p className='mb-3 mt-8'>
						The target add-on rate is the quotient of add-ons and total case volume. Typically, a lower add-on rate can
						signify higher daily predictability, as add-ons are often a source of unplanned schedule variation.
					</p>
				</Accordion.Item>
				<Accordion.Item value='Facility Utilization'>
					<p className='mb-3'>
						Facility Utilization, also known as “Prime Time Utilization” or “OR Utilization”, is a measure of how well used
						the facility&apos;s ORs are during a set prime time window. Facility Utilization can be used as a proxy metric for
						block utilization.
					</p>
					<p className='mb-3'>
						Facility Utilization is defined as the total number of surgical minutes within the prime time window plus room
						turnover time divided by the total number of available minutes within the prime time window.
					</p>
					<p className='mb-3'>
						The prime time window is set for each day of the week in Facility Settings and is used to determine the available
						minutes in the facility utilization calculation. Surgical minutes or turnover time occurring any time outside of
						the prime time window do not count toward the utilization percentage, and are documented as &apos;non-primetime
						minutes&apos; instead.
					</p>
					<p className='mb-3'>
						See below for a screenshot of the Primetime settings that determine the available minutes used in the Facility
						Utilization calculation:
					</p>
					<img className='m-auto mb-6' alt='draw down example' src={primetime_settings1} />
					<p className='mb-3'>
						Users now have the option to flexibly change the start and end times for the primetime window depending on the day
						of week and time of day. This is particularly helpful if the facility has educational days that require later
						start times. See the example below:
					</p>
					<img className='m-auto mb-6' alt='late start example' src={primetime_settings2} />
					<p className='mb-3'>
						Also, users can choose to include or exclude weekend days from the utilization calculation. Weekends are excluded
						by default, and will not count towards facility utilization unless specified otherwise.
					</p>
				</Accordion.Item>
				<Accordion.Item value='Block Utilization'>
					<p className='mb-3'>
						Block Utilization is a measure of how well each block (individual, group, service, or other resource) uses the
						designated block time allocated.
					</p>
					<p className='mb-3'>
						Multiple block allocations on a given day is not recommended and can cause issues reporting block usage
						accurately. For instance, a robotic surgeon has an individual block but also participates in a group robotic block
						on Tuesdays. Because these blocks occur in the same day, it is impossible to know which block to credit. Thus, we
						strongly suggest that surgeons only be assigned to one block on any given day, or there is a risk of in block
						minutes being misattributed.
					</p>
					<p className='mb-3'>
						Additionally, many ORs have large “open” blocks or “resource” blocks that are often first-come-first-serve and
						shared amongst many different surgeons. While this keeps valuable OR time reserved for certain specialties, it can
						often be difficult to truly measure the performance of this block due to diffuse accountability. Also,
						institutions also seldom realize that certain members of this service line also have individual blocks that
						conflict with a designated service block, which can cause misattribution of minutes as mentioned above.
					</p>
					<p className='mb-3'>
						Blocks can also be fluid from one facility to another. Merlin is room agnostic, and can be configured in the Block
						Settings tab to also be facility agnostic if a user so chooses. While this can be particularly convenient in
						attached HOPD or nearby ASC scenarios, it can become more problematic between facilities that do not share staff.
						It&apos;s Surgical Directions&apos; general recommendation that surgeons have block in only one location per day,
						as transit time, staffing challenges, and other factors can contribute to inefficiencies when blocks are split
						between facilities on an operative day.
					</p>
					<p className='mb-3'>
						Thus, it is our general recommendation that blocks be configured in a manner such that a surgeon only has one
						block in one facility on a given day. If the hospital uses group blocks, it&apos;s our recommendation that the
						group remain relatively small (generally, up to 5 members is manageable), and that large service blocks or
						resource blocks be kept to a minimum, or assigned to the main user of that time as an individual block instead.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Raw Block Utilization</p>
					<p className='mb-3'>
						Raw Utilization is defined as the sum of in-block minutes divided by the sum of allocated block minutes for a
						specified time window or method of aggregation (e.g., monthly, weekly, etc.).
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Adjusted Block Utilization</p>
					<p className='mb-3'>
						Adjusted Utilization is defined as the sum of in-block minutes plus block turnover time divided by the sum of
						allocated block minutes for a specified time window or method of aggregation (e.g., monthly, weekly, etc.).
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Block Minute Credit Breakdown</p>
					<p className='mb-3'>Please see the figure below to understand how blocks are credited:</p>
					<img className='m-auto mb-3 w-4/5' alt='flip room example' src={block_credit_breakdown} />
					<p className='text-gray-400 italic text-p3 text-center'>
						Copyright Surgical Directions, LLC 2022. All Rights Reserved.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>In-Block Minutes</p>
					<p className='mb-3'>
						Minutes that fall on or between block start and block end timestamps for cases occurring on a day where block is
						allocated. Minutes that fall outside of block start and block end timestamps are excluded. Minutes that occur on a
						day where block is not allocated are excluded.
					</p>
					<p className='font-semibold text-p1 mb-3 mt-8'>Out-Block Minutes</p>
					<p className='mb-3'>
						Minutes that fall outside of block start and block end timestamps for cases occurring on a day where block is
						allocated. Minutes that fall inside of block start and block end timestamps are excluded. Minutes that occur on a
						day where block is not allocated are excluded.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Block Turnover</p>
					<p className='mb-3'>
						The sum total of surgeon turnover minutes that fall on or between block start and block end timestamps for cases
						occurring on a day where block is allocated.
					</p>
					<p className='mb-3'>
						For individual surgeons, this number would be the sum total of their surgeon turnover minutes that were between
						the block start and block end for a given block day. Turnovers occurring outside the block start and block end
						window do not count toward adjusted block utilization. Flipped cases do not receive turnover credit. Important
						note: a flipped case is only deemed as such if there is an actual overlap between timestamps. If there is no
						overlap present and there is a change in rooms, a turnover will still count toward a block&apos;s adjusted
						utilization for that day.
					</p>
					<p className='mb-3'>
						For group blocks, this number would be the sum total of all surgeon turnover minutes between the block start and
						block end time for the a given block day. Turnover time between cases for different surgeons is not counted.
						Turnovers are also not applied in flip room scenarios for this case.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Non Block Minutes</p>
					<p className='mb-3'>Minutes that fall on a day where block is not allocated.</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Allocated Block Minutes</p>
					<p className='mb-3'>
						Number of minutes allocated to a block name for a given day. Typically, organizations allocate blocks in 4, 8, 10,
						or 12 hour increments. Merlin currently imposes no limits on block length other than it must have non-negative
						duration. Additional rooms can also be allocated with different allocated time range, for example:
					</p>
					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-6'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th className='py-3 px-6' scope='col'>
									{''}
								</th>
								<th className='py-3 px-6' scope='col'>
									Block Start
								</th>
								<th className='py-3 px-6' scope='col'>
									Block End
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Room 1</td>
								<td className='py-4 px-6'>07:30</td>
								<td className='py-4 px-6'>15:00</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Room 2</td>
								<td className='py-4 px-6'>08:00</td>
								<td className='py-4 px-6'>15:30</td>
							</tr>
						</tbody>
					</table>

					<p className='font-semibold text-p1 mb-3 mt-8'>Allocated Days</p>
					<p className='mb-3'>
						{
							'Sum of the number days where block allocation > 0 for a specified time window or method of aggregation (e.g. monthly, weekly, by surgeon, by service, etc.).'
						}
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Block Release</p>
					<p className='mb-3'>
						Block release refers to the process of reallocating block time for either open use or other surgeon(s) besides the
						original block owner(s). Note: if a surgeon is a part of a group or shared block, they do not need to formally
						“release” their block to another group member. The other group member can simply use the block as scheduled, since
						group blocks mean each group member has mathematically equivalent “access” to the block as determined by the
						members of the group or shared block.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Voluntary Release</p>
					<p className='mb-3'>
						Also known as &quot;elective&quot; or “scheduled” release. Refers to block owner(s) providing sufficient advance
						notice (typically 2+ weeks or 10 business days) to the hospital scheduling office of their intent to release their
						allocated block time. If sufficient notice is not provided and cases are not scheduled into their allocated block
						time, the block owner could be subject to an automatic block release.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Automatic Release</p>
					<p className='mb-3'>
						Refers to the hospital scheduling office re-allocating block time if sufficient volume is not scheduled into the
						block. Typically, automatic block release time is shorter than scheduled release time, and often varies by
						surgical specialty. Criteria for automatic release varies by organization.
					</p>
					<p className='mb-3'>
						In Merlin, a block is “Automatically Released” if a block was allocated, not voluntarily released in advance, and
						not used on the day on which it was allocated. This is effectively a “no show” penalty to overall block
						utilization. Automatic Releases can be “undone” by retroactively releasing the block.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Partial Release</p>
					<p className='mb-3'>
						Scenario when an organization permits block owners to schedule the release of part or all of their block if they
						provide sufficient advance notice (typically 2+ weeks or 10 business days).
					</p>
					<p className='mb-3'>
						This approach is not recommended as best practice, as blocks with insufficient volume can be partially released in
						order to inflate utilization metrics. Instead, scheduled and automatic release should release all allocated block
						minutes.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Released Days</p>
					<p className='mb-3'>
						Sum of the number of released days with allocated block minutes in a specified time window or method of
						aggregation (e.g. monthly, weekly, by surgeon, by service, etc.).
					</p>
				</Accordion.Item>

				<Accordion.Item value='Used Days'>
					<p className='mb-3'>
						A day with allocated block time where in block minutes or out of block minutes were recorded.
					</p>
				</Accordion.Item>

				<Accordion.Item value='Unused Days'>
					<p className='mb-3'>
						A day with allocated block time where no in block minutes or out of block minutes were recorded.
					</p>
				</Accordion.Item>

				<Accordion.Item value='Anesthesia Staffing'>
					<p className='mb-3'>
						Merlin Anesthesia provides a flexible anesthesia staffing tool that helps you model different staffing scenarios
						for anesthesia coverage.
					</p>
					<p className='font-semibold text-p1 mb-3 mt-8'>Medical Direction / Supervision Ratio</p>
					<p className='mb-3'>
						Medical Direction and Medical Supervision are often used interchangeably when referring to an anesthesiologist
						working within an Anesthesia Care Team model. CMS guidelines provide important distinctions between the two,
						however.
					</p>

					<p className='mb-3'>
						Medical direction is reported by appending specific anesthesia billing modifiers QK and QY to the anesthesia code.
					</p>

					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-6'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Modifier
								</th>
								<th className='py-3 px-6' scope='col'>
									Description
								</th>
								<th className='py-3 px-6' scope='col'>
									Reimbursement % of Maximum Allowable Fee Schedule
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>AA</td>
								<td className='py-4 px-6'>Anesthesia services personally performed by an anesthesiologist</td>
								<td className='py-4 px-6'>100%</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>AD</td>
								<td className='py-4 px-6'>
									Medical Supervision by a physician; more than four concurrent anesthesia procedures
								</td>
								<td className='py-4 px-6'>100%</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>QK</td>
								<td className='py-4 px-6'>
									Medical direction of two, three, or four concurrent anesthesia procedures involving qualified individuals
								</td>
								<td className='py-4 px-6'>50%</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>QS</td>
								<td className='py-4 px-6'>Monitored anesthesia care provided by an anesthesiologist</td>
								<td className='py-4 px-6'>100%</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>QX</td>
								<td className='py-4 px-6'>CRNA Service with medical direction by a physician</td>
								<td className='py-4 px-6'>50%</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>QY</td>
								<td className='py-4 px-6'>Medical direction of one CRNA by an anesthesiologist</td>
								<td className='py-4 px-6'>50%</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>QZ</td>
								<td className='py-4 px-6'>CRNA service without medical direction by a physician</td>
								<td className='py-4 px-6'>100%</td>
							</tr>
						</tbody>
					</table>

					<p className='mb-3 mt-8'>Currently, Merlin provides staffing models based on AA, QK, QY, and QZ modifiers.</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Shift Type</p>
					<p className='mb-3'>
						Often times, practices will organize work into “shifts” of varying length to provide structure and predictability
						to work hours. Merlin currently provides support for different shift lengths. All shift lengths use a standardized
						start time of 7 AM, but we plan to add flexibility to this start time in future updates.
					</p>
					<p className='mb-3'>
						Shift Types can be mixed and matched as desired. There must always be at least one shift length selected.
						Selecting different combinations of shift types may impact the results of your model types. See the{' '}
						<strong>Model Types</strong> section to learn more about what is impacted.
					</p>
					<p className='font-semibold text-p1 mb-3 mt-8'>Model Types</p>
					<p className='mb-3'>There are a few different model types available in the Anesthesia Staffing dashboard.</p>

					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-6'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Model Type
								</th>
								<th className='py-3 px-6' scope='col'>
									Description
								</th>
								<th className='py-3 px-6' scope='col'>
									Interpretation
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Heatmap</td>
								<td className='py-4 px-6'>
									<p className='mb-3'>
										This model type is represented by the curvy, filled volume. The x-axis represents the time of day, and the
										y-axis represents the number of providers required to cover the concurrently running rooms at that particular
										hour of day. The ideal model is based on the values in this model type.
									</p>
									<p className='mb-3'>
										Changing the medical direction / supervision ratio can potentially impact the MD FTE coverage by shift type.
									</p>
									<p className='mb-3'>
										Primetime FTE is based on 7 AM to the end of the longest selected shift. For example, if there are 10 and 12
										hour shifts, “Primetime” is considered 7 - 19. Non-Primetime FTE is the total clinical coverage required for
										all hours outside of Primetime. There is a more detailed breakdown if you expand the accordion row in the
										table.
									</p>
								</td>
								<td className='py-4 px-6'>
									{' '}
									<p className='mb-3'>
										The FTE coverage of this model would represent essentially “perfect” coverage for demand, meaning the FTE need
										is a nearly-perfect representation of the amount of clinical coverage required based on the concurrent
										patients in operating rooms (pre- and post-op duties are not represented here).
									</p>
									<p className='mb-3'>
										While it is helpful to know the exact coverage based on room concurrency, intraday variability often precludes
										a practice from being able to perfectly match staffing coverage to demand. Thus, this model is not often
										prescriptive, but merely a baseline reference to use for comparison to other, more realistic models.
									</p>
									<p className='mb-3'>Changing filter values will directly impact the output of this model type.</p>
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Ideal</td>
								<td className='py-4 px-6'>
									<p className='mb-3'>
										This model is represented by the light blue line. Changing the shift type(s) will change which shifts are
										included in this model.
									</p>
									<p className='mb-3'>
										Changing the medical direction / supervision ratio can potentially impact the MD FTE coverage by shift type.
									</p>
									<p className='mb-3'>
										Primetime FTE is based on 7 AM to the end of the longest selected shift. For example, if there are 10 and 12
										hour shifts, “Primetime” is considered 7 - 19. Non-Primetime FTE is the total clinical coverage required for
										all hours outside of Primetime. There is a more detailed breakdown if you expand the accordion row in the
										table.
									</p>
								</td>
								<td className='py-4 px-6'>
									<p className='mb-3'>
										The FTE coverage of this model seeks to minimize the excess coverage while never dipping below the coverage
										required by the Heatmap model in adherence with the shift type options selected.
									</p>

									<p className='mb-3'>
										Essentially, the model shows the amount of FTEs required to cover the heatmap while using the reasonable
										assumption that the personnel have predictable shift lengths, e.g. providers don’t leave the hospital the
										moment a room closes.
									</p>
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Custom</td>
								<td className='py-4 px-6'>
									<p className='mb-3'>
										This model is represented by the light purple line. Use the “Staffing” button near the “Filters” button to
										create a desired staffing grid. You can use this model without any intraoperative data.
									</p>
									<p className='mb-3'>Changing the shift type(s) will change which shifts are included in this model.</p>
									<p className='mb-3'>
										Changing the medical direction / supervision ratio can potentially impact the MD FTE coverage by shift type.
									</p>
									<p className='mb-3'>
										Primetime FTE is based on 7 AM to the end of the longest selected shift. For example, if there are 10 and 12
										hour shifts, “Primetime” is considered 7 - 19. Non-Primetime FTE is the total clinical coverage required for
										all hours outside of Primetime. There is a more detailed breakdown if you expand the accordion row in the
										table.
									</p>
								</td>
								<td className='py-4 px-6'>
									<p className='mb-3'>
										The resulting FTE coverage is much like the Ideal model, but you can specify the number of rooms needed
										manually. This makes this model type particularly useful in scenarios where there is a contracted amount of
										coverage by department or area. You can then compare the manually input coverage to the Heatmap and Ideal
										models.
									</p>
								</td>
							</tr>
						</tbody>
					</table>
					<p className='font-semibold text-p1 mb-3 mt-8'>Anesthesiologists provide solo coverage</p>
					<p className='mb-3'>
						This setting affects whether or not a physician will cover their own room solo in coverage scenarios that would
						reduce the medical direction ratio. Selecting “No” may result in a nominally higher number of CRNA FTEs.
					</p>
					<p className='mb-3'>
						Example: A care team staffs a 4 OR department in a 1 : 3 Medical Direction ratio. If the anesthesiologists provide
						solo coverage, the second anesthesiologist may cover the fourth room solo instead of working with an APP to cover
						the room, thus reducing the Medical Direction ratio to 1 : 2.
					</p>
					<p className='font-semibold text-p1 mb-3 mt-8'>Average annual salary per provider</p>
					<p className='mb-3'>
						This value represents the average annual salary by provider type. This number can be used to approximate the cost
						of staffing the practice.
					</p>
					<p className='font-semibold text-p1 mb-3 mt-8'>Staffing Grid</p>
					<p className='mb-3'>
						As mentioned above, you can use this grid to manually input the amount of desired coverage by hour of day or shift
						period. Shift periods do not overlap.
					</p>
					<p className='font-semibold text-p1 mb-3 mt-8'>Anesthesiologist FTE</p>
					<p className='mb-3'>
						Depending on the model, the FTE represents the amount of clinical anesthesiologist FTEs required to cover the
						desired model. This does not account for other duties such as pre- and post-op time, untimed procedures, etc.
					</p>
					<p className='mb-3'>
						Depending on the start date included in the filter, the FTE amount is calculated looking 1 year into the future.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Anesthetist FTE</p>
					<p className='mb-3'>
						Depending on the model, the FTE represents the amount of clinical anesthetist FTEs required to cover the desired
						model. This does not account for other duties such as pre- and post-op time, untimed procedures, etc.
					</p>
					<p className='mb-3'>
						Depending on the start date included in the filter, the FTE amount is calculated looking 1 year into the future.
					</p>
				</Accordion.Item>

				<Accordion.Item value='Perioperative Nurse Staffing'>
					<p className='mb-3'>
						Merlin perioperative nursing provides a flexible staffing tool that helps you model different staffing scenarios
						for perioperative nursing coverage.
					</p>
					<p className='font-semibold text-p1 mb-3 mt-8'>Staff Type</p>
					<p className='mb-3'>
						Perioperative departments are staffed with a variety of resources with varying specializations. Merlin uses AORN
						guidelines to organize these resources into different “Staff Types” to better generalize how a department is
						staffed.
					</p>

					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-6'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Staff Type
								</th>
								<th className='py-3 px-6' scope='col'>
									Description
								</th>
								<th className='py-3 px-6' scope='col'>
									Time Window
								</th>
								<th className='py-3 px-6' scope='col'>
									Staff Ratios (AORN)
								</th>
								<th className='py-3 px-6' scope='col'>
									Salary Range
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Registered Nurse (RN)</td>
								<td className='py-4 px-6'>Perioperative Registered Nurse staffed to a room.</td>
								<td className='py-4 px-6'>RN role is applied uniformly to any active room</td>
								<td className='py-4 px-6'>1 RN for every active running room</td>
								<td className='py-4 px-6'>Suggested salary range depends on the average salary range for this role type.</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Surgical Technician (ST)</td>
								<td className='py-4 px-6'>Perioperative Surgical Technician staffed to a room. </td>
								<td className='py-4 px-6'>ST is applied uniformly to any active room</td>
								<td className='py-4 px-6'>1 ST for every active room</td>
								<td className='py-4 px-6'>Suggested salary range depends on the average salary range for this role type.</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Variable Staff</td>
								<td className='py-4 px-6'>
									This staff type represents numerous kinds of clinical roles that are typically staffed to help relieve existing
									resources (like giving lunch or breaks), or provide additional support during a surgical case, like an
									additional scrub RN or ST, Physician Assistant (PA-C), RN First Assistant (RNFA), Surgical First Assistant
									(STFA), etc.
								</td>
								<td className='py-4 px-6'>
									You can add variable staff to a continuous set time periods for each day. For example, you may only have
									additional variable staff from 07:00 - 19:00.
								</td>
								<td className='py-4 px-6'>0.5 for every active room. Merlin supports increasing this up to 5.</td>
								<td className='py-4 px-6'>
									Suggested salary range could vary widely depending on the kinds of roles at your institution and their
									applicable salary range. For further support, contact a member of the Merlin team for guidance.
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Indirect Staff</td>
								<td className='py-4 px-6'>
									This staff type represents numerous non-clinical support roles that are staffed within perioperative services.
									This could include an OR Director, managers, charge nurses, environmental support, etc.
								</td>
								<td className='py-4 px-6'>
									You can add indirect staff to a continuous set of time periods for each day. For example, you may only have
									additional indirect staff from 07:00 - 19:00.
								</td>
								<td className='py-4 px-6'>
									33% of total staff. You can increase this to 1, which would be 1 indirect staff role for each direct staff
									role.
								</td>
								<td className='py-4 px-6'>
									Suggested salary range could vary widely depending on the kinds of roles at your institution and their
									applicable salary range. For further support, contact a member of the Merlin team for guidance.
								</td>
							</tr>
						</tbody>
					</table>

					<p className='font-semibold text-p1 mb-3 mt-8'>Shift Type</p>
					<p className='mb-3'>
						Often times, practices will organize work into “shifts” of varying length to provide structure and predictability
						to work hours. Merlin currently provides support for different shift lengths. All shift lengths use a standardized
						start time of 7 AM, but we plan to add flexibility to this start time in future updates.
					</p>
					<p className='mb-3'>
						Shift Types can be mixed and matched as desired. There must always be at least one shift length selected.
						Selecting different combinations of shift types may impact the results of your model types. See the{' '}
						<strong>Model Types</strong> section to learn more about what is impacted.
					</p>
					<p className='font-semibold text-p1 mb-3 mt-8'>Model Types</p>
					<p className='mb-3'>There are a few different model types available in the Anesthesia Staffing dashboard.</p>

					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-6'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Model Type
								</th>
								<th className='py-3 px-6' scope='col'>
									Description
								</th>
								<th className='py-3 px-6' scope='col'>
									Interpretation
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Heatmap</td>
								<td className='py-4 px-6'>
									<p className='mb-3'>
										This model type is represented by the curvy, filled volume. The x-axis represents the time of day, and the
										y-axis represents the number of staff required to cover the concurrently running rooms at that particular hour
										of day. The ideal model is based on the values in this model type.
									</p>
									<p className='mb-3'>
										Primetime FTE is based on 7 AM to the end of the longest selected shift. For example, if there are 10 and 12
										hour shifts, “Primetime” is considered 7 - 19. Non-Primetime FTE is the total clinical coverage required for
										all hours outside of Primetime. There is a more detailed breakdown if you expand the accordion row in the
										table.
									</p>
								</td>
								<td className='py-4 px-6'>
									{' '}
									<p className='mb-3'>
										The FTE coverage of this model would represent essentially “perfect” coverage for demand, meaning the FTE need
										is a nearly-perfect representation of the amount of clinical coverage required based on the concurrent
										patients in operating rooms.
									</p>
									<p className='mb-3'>
										While it is helpful to know the exact coverage based on room concurrency, intraday variability often precludes
										a practice from being able to perfectly match staffing coverage to demand. Thus, this model is not often
										prescriptive, but merely a baseline reference to use for comparison to other, more realistic models.
									</p>
									<p className='mb-3'>Changing filter values will directly impact the output of this model type.</p>
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Ideal</td>
								<td className='py-4 px-6'>
									<p className='mb-3'>
										This model is represented by the light blue line. Changing the shift type(s) will change which shifts are
										included in this model.
									</p>
									<p className='mb-3'>
										Primetime FTE is based on 7 AM to the end of the longest selected shift. For example, if there are 10 and 12
										hour shifts, “Primetime” is considered 7 - 19. Non-Primetime FTE is the total clinical coverage required for
										all hours outside of Primetime. There is a more detailed breakdown if you expand the accordion row in the
										table.
									</p>
								</td>
								<td className='py-4 px-6'>
									<p className='mb-3'>
										The FTE coverage of this model seeks to minimize the excess coverage while never dipping below the coverage
										required by the Heatmap model in adherence with the shift type options selected.
									</p>

									<p className='mb-3'>
										Essentially, the model shows the amount of FTEs required to cover the heatmap while using the reasonable
										assumption that the personnel have predictable shift lengths, e.g. providers don’t leave the hospital the
										moment a room closes.
									</p>
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Custom</td>
								<td className='py-4 px-6'>
									<p className='mb-3'>
										This model is represented by the light purple line. Use the “Staffing” button near the “Filters” button to
										create a desired staffing grid. You can use this model without any intraoperative data.
									</p>
									<p className='mb-3'>Changing the shift type(s) will change which shifts are included in this model.</p>

									<p className='mb-3'>
										Primetime FTE is based on 7 AM to the end of the longest selected shift. For example, if there are 10 and 12
										hour shifts, “Primetime” is considered 7 - 19. Non-Primetime FTE is the total clinical coverage required for
										all hours outside of Primetime. There is a more detailed breakdown if you expand the accordion row in the
										table.
									</p>
								</td>
								<td className='py-4 px-6'>
									<p className='mb-3'>
										The resulting FTE coverage is much like the Ideal model, but you can specify the number of rooms needed
										manually. This makes this model type particularly useful in scenarios where there is a contracted amount of
										coverage by department or area. You can then compare the manually input coverage to the Heatmap and Ideal
										models.
									</p>
								</td>
							</tr>
						</tbody>
					</table>
					<p className='font-semibold text-p1 mb-3 mt-8'>Staffing Grid</p>
					<p className='mb-3'>
						As mentioned above, you can use this grid to manually input the amount of desired coverage by hour of day or shift
						period. Shift periods do not overlap.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Staff Type FTE</p>
					<p className='mb-3'>
						Depending on the model, the FTE represents the amount of FTEs required to cover the desired model by staff type.
						Depending on the start date included in the filter, the FTE amount is calculated looking 1 year into the future.
					</p>
				</Accordion.Item>
			</Accordion>

			<h1 className='font-bold text-h4 my-6'>Advanced Functionality</h1>
			<Accordion type='multiple' itemStyle='contained' className='mt-0' defaultValue={[openAccordion]}>
				<Accordion.Item value='Date Pickers'>
					<p className='mb-3'>
						The date picker allows the user to select a date range to visualize the data for each visualization in Merlin.
						Dates must be selected from earlier (start) to later (end). Only continuous date ranges can be picked.
					</p>
					<p className='mb-3'>
						Users have the option of selecting a custom date range or use one of the <strong>Quick Select</strong> options.
						The <strong>Quick Select</strong>
						options are based on the last date of available data that we have for the facility (e.g., if we have data through
						September 30th, “past 7 days” will show the data for September 24 - September 30)
					</p>
					<img className='m-auto mb-6' alt='flip room example' src={datepicker} />
					<p className='mb-3'>
						Overall, Merlin will store a maximum of 5 years of data per facility. All date ranges selected are continuous
						(meaning you can&apos;t select different portions of a range). The amount of data that can be selected varies
						slightly by page and is outlined below:
					</p>

					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<colgroup span={2}></colgroup>
							<tr>
								<th colSpan={1} scope='colgroup'></th>
								<th className='py-3 px-6 text-center font-semibold' colSpan={2} scope='colgroup'>
									Date Range Limits
								</th>
							</tr>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Page
								</th>

								<th className='py-3 px-6 text-center' scope='col'>
									Minimum
								</th>

								<th className='py-3 px-6 text-center' scope='col'>
									Maximum
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 whitespace-nowrap font-semibold'>Timely Starts</td>
								<td className='py-4 px-6 text-center'>1 day</td>
								<td className='py-4 px-6 text-center'>3 years</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 whitespace-nowrap font-semibold'>Timeline View</td>
								<td className='py-4 px-6 text-center'>1 day</td>
								<td className='py-4 px-6 text-center'>1 day</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 whitespace-nowrap font-semibold'>Turnover Time</td>
								<td className='py-4 px-6 text-center'>1 day</td>
								<td className='py-4 px-6 text-center'>3 years</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 whitespace-nowrap font-semibold'>Facility Utilization</td>
								<td className='py-4 px-6 text-center'>1 day</td>
								<td className='py-4 px-6 text-center'>3 years</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 whitespace-nowrap font-semibold'>Scheduling Patterns</td>
								<td className='py-4 px-6 text-center'>1 day</td>
								<td className='py-4 px-6 text-center'>3 years</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 whitespace-nowrap font-semibold'>Surgeon Scorecard</td>
								<td className='py-4 px-6 text-center'>1 calendar month</td>
								<td className='py-4 px-6 text-center'>1 calendar month</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 whitespace-nowrap font-semibold'>Surgical Volume</td>
								<td className='py-4 px-6 text-center'>1 day</td>
								<td className='py-4 px-6 text-center'>3 years</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 whitespace-nowrap font-semibold'>Flip Rooms</td>
								<td className='py-4 px-6 text-center'>1 day</td>
								<td className='py-4 px-6 text-center'>3 years</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 whitespace-nowrap font-semibold'>Utilization Overview</td>
								<td className='py-4 px-6 text-center'>1 day</td>
								<td className='py-4 px-6 text-center'>3 years</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 whitespace-nowrap font-semibold'>Release Patterns</td>
								<td className='py-4 px-6 text-center'>1 day</td>
								<td className='py-4 px-6 text-center'>1 year</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 whitespace-nowrap font-semibold'>Block Scorecard</td>
								<td className='py-4 px-6 text-center'>1 day</td>
								<td className='py-4 px-6 text-center'>1 year</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 whitespace-nowrap font-semibold'>Heatmap</td>
								<td className='py-4 px-6 text-center'>1 day</td>
								<td className='py-4 px-6 text-center'>1 year</td>
							</tr>
						</tbody>
					</table>
				</Accordion.Item>

				<Accordion.Item value='Filtering'>
					<p className='mb-3'>
						Filters are available across nearly all Merlin pages. Filters apply at the Facility, Page, and User level, meaning
						each individual user can set their own filters for a given facility and page, like turnover time, for example.
					</p>
					<p className='mb-3'>
						This is different than the Facility Settings tab, which will apply certain options at the facility level. Only
						Health System Administrators can modify Facility Settings, but Health System Viewers can always modify filters for
						the facilities they have access to. If you need access to a different set of facilities, or need your role
						modified, contact either your Health System Administrator or a member of the Merlin Team.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Using filters</p>
					<p className='mb-3'>
						To open the filter drawer, click the “Filter” button present on the page. This button should open a filter drawer
						with all filters available for that page. If there is no filter button present, filtering is not available on that
						page.{' '}
					</p>
					<p className='mb-3'>
						Filters are not applied automatically, and must be applied and saved to work. Filters must be changed from their
						current state to be applied and changed.
					</p>
					<p className='mb-3'>
						If you wish to know the last time you saved a filter for a certain facility and page, look for the green “Saved
						On” label at the top of the filter drawer. This will be the date and time the filter was last saved for the
						facility and page of interest.
					</p>
					<p className='mb-3'>
						Some text-based dropdown filters are searchable. To search them, put your mouse cursor inside the dropdown and
						start typing for what you&apos;re searching for. You can do this for multiple items you&apos;re trying to select.
					</p>
					<p className='mb-3'>
						You can quickly select/deselect items in a dropdown filter by hitting the blue Select/Deselect button at the
						bottom of a dropdown menu.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Changing filters</p>
					<p className='mb-3'>
						Filtering can get complex quite often, so Merlin gives you tools to monitor which filters have been changed.
						Whenever you&apos;ve changed a filter, the filter label will show a “[Filter Name] (CHANGED)” state. This helps
						you keep track of what has changed from your last filter state to ensure that you&apos;re getting the data you
						need.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Reverting filters</p>
					<p className='mb-3'>
						If you want to revert the changes you made to your last saved setting, hit the reverse icon at the top of the
						filter drawer. This will revert any changed filter states back to their last saved state. This is helpful if you
						want to quickly undo a change you made a filter but want to keep your previously saved state.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Resetting filters</p>
					<p className='mb-3'>
						Need a fresh start? You can reset all your filters to their default by hitting the circular icon at the top of the
						filter drawer. This will clear your save and reset all page filters to their default state. Note: this only works
						for the facility and page you&apos;re currently on and is not an entire system reset.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Filter Details</p>

					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-6'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Name
								</th>
								<th className='py-3 px-6' scope='col'>
									Description
								</th>
								<th className='py-3 px-6' scope='col'>
									Default Values
								</th>
								<th className='py-3 px-6' scope='col'>
									Range
								</th>
								<th className='py-3 px-6' scope='col'>
									Increment
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Date</td>
								<td className='py-4 px-6'>Single Data Picker</td>
								<td className='py-4 px-6'>Most recent case date for selected facility</td>
								<td className='py-4 px-6'>Up to 5 years (max)</td>
								<td className='py-4 px-6'>1d</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Date Range</td>
								<td className='py-4 px-6'>Date Picker Range</td>
								<td className='py-4 px-6'>6 month range from most recent case date for selected facility</td>
								<td className='py-4 px-6'>1 day (min) - 3 years (max)</td>
								<td className='py-4 px-6'>1d</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Days of Week</td>
								<td className='py-4 px-6'>Multi-Select Dropdown</td>
								<td className='py-4 px-6'>All (Sun - Sat)</td>
								<td className='py-4 px-6'>Sun - Sat</td>
								<td className='py-4 px-6'>1d</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Surgeons</td>
								<td className='py-4 px-6'>Multi-Select Dropdown</td>
								<td className='py-4 px-6'>All</td>
								<td className='py-4 px-6'>All surgeons who have performed cases in licensed rooms</td>
								<td className='py-4 px-6'></td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Surgeon</td>
								<td className='py-4 px-6'>Single-Select Dropdown</td>
								<td className='py-4 px-6'>None</td>
								<td className='py-4 px-6'>All surgeons who have performed cases in licensed rooms</td>
								<td className='py-4 px-6'></td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Service Lines</td>
								<td className='py-4 px-6'>Multi-Select Dropdown</td>
								<td className='py-4 px-6'>All</td>
								<td className='py-4 px-6'>All service lines associated with cases performed in licensed rooms.</td>
								<td className='py-4 px-6'></td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Rooms</td>
								<td className='py-4 px-6'>Multi-Select Dropdown</td>
								<td className='py-4 px-6'>All</td>
								<td className='py-4 px-6'>All licensed rooms.</td>
								<td className='py-4 px-6'></td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Encounter Types</td>
								<td className='py-4 px-6'>Multi-Select Dropdown</td>
								<td className='py-4 px-6'>All</td>
								<td className='py-4 px-6'>All encounter types associated with cases performed in licensed rooms.</td>
								<td className='py-4 px-6'></td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Primetime</td>
								<td className='py-4 px-6'>Radio Group</td>
								<td className='py-4 px-6'>Primetime</td>
								<td className='py-4 px-6'>All labeled primetime cases performed in licensed rooms.</td>
								<td className='py-4 px-6'></td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Add-Ons</td>
								<td className='py-4 px-6'>Radio Group</td>
								<td className='py-4 px-6'>All</td>
								<td className='py-4 px-6'>All labeled add-on cases performed in licensed rooms.</td>
								<td className='py-4 px-6'></td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Utilization Type</td>
								<td className='py-4 px-6'>Radio Group</td>
								<td className='py-4 px-6'>Adjusted</td>
								<td className='py-4 px-6'></td>
								<td className='py-4 px-6'></td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Turnover Time Threshold</td>
								<td className='py-4 px-6'>Number input with slider</td>
								<td className='py-4 px-6'>60</td>
								<td className='py-4 px-6'>30 (min) - 120 (max)</td>
								<td className='py-4 px-6'>5</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>FCOTS Grace Minutes</td>
								<td className='py-4 px-6'>Number input with slider</td>
								<td className='py-4 px-6'>0</td>
								<td className='py-4 px-6'>0 (min) - 30 (max)</td>
								<td className='py-4 px-6'>1</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Block Names</td>
								<td className='py-4 px-6'>Multi-Select Dropdown</td>
								<td className='py-4 px-6'>All</td>
								<td className='py-4 px-6'>
									All block names associated with surgeons that have performed cases in licensed rooms.
								</td>
								<td className='py-4 px-6'></td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Block Name</td>
								<td className='py-4 px-6'>Single Select Dropdown</td>
								<td className='py-4 px-6'>None</td>
								<td className='py-4 px-6'>
									All block names associated with surgeons that have performed cases in licensed rooms.
								</td>
								<td className='py-4 px-6'></td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Block Timeslot Type</td>
								<td className='py-4 px-6'>Single Select Dropdown</td>
								<td className='py-4 px-6'>All</td>
								<td className='py-4 px-6'>All configured block types.</td>
								<td className='py-4 px-6'></td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Timeslot Type</td>
								<td className='py-4 px-6'>Radio Group</td>
								<td className='py-4 px-6'>Scheduled</td>
								<td className='py-4 px-6'></td>
								<td className='py-4 px-6'></td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Percentile</td>
								<td className='py-4 px-6'>Number input with slider</td>
								<td className='py-4 px-6'>Default: 95</td>
								<td className='py-4 px-6'>{`50 (min) - 100 (max)`}</td>
								<td className='py-4 px-6'></td>
							</tr>
						</tbody>
					</table>
				</Accordion.Item>

				<Accordion.Item value='View By, Group By, and Sort By Options'>
					<p className='mb-3'>
						View By, Group By, and Sort By options are features that can be found on different pages within the application.
					</p>
					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<colgroup span={3}></colgroup>
							<tr>
								<th colSpan={1} scope='colgroup'></th>
								<th className='py-3 px-6 text-center font-semibold' colSpan={3} scope='colgroup'>
									Description of Functionality
								</th>
							</tr>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Page
								</th>
								<th className='py-3 px-6 text-center' scope='col'>
									View By
								</th>

								<th className='py-3 px-6 text-center' scope='col'>
									Group By
								</th>

								<th className='py-3 px-6 text-center' scope='col'>
									Sort By
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 whitespace-nowrap font-semibold'>Timely Starts</td>
								<td className='py-4 px-6 text-center'>Changes the y-axis grouping variable.</td>
								<td className='py-4 px-6 text-center'>
									<p>Groups y-axis variables into expandable accordions.</p>
									<p className='opacity-0'>-</p>
									<p>Available for all time-based View By options, including Day of Week, Month, and Quarter.</p>
								</td>
								<td className='py-4 px-6 text-center'>
									Changes the order of the y-axis in the following order: Group By, View By. Sort By options vary based on
									selected View By and Group By options.
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 whitespace-nowrap font-semibold'>Turnover Time</td>
								<td className='py-4 px-6 text-center'>
									<p>
										Changes the y-axis grouping variable and turnover type, depending on the option selected. The turnover type in
										use is displayed on the top left corner of the visualization.
									</p>
									<p className='opacity-0'>-</p>
									<p>Room Turnover Options: Day of Week, Month, Quarter, Room</p>
									<p className='opacity-0'>-</p>
									<p>Surgeon Turnover Options: Surgeon, Service Line</p>
								</td>
								<td className='py-4 px-6 text-center'>
									<p>Groups y-axis variables into expandable accordions.</p>
									<p className='opacity-0'>-</p>
									<p>Available for all time-based View By options, including Day of Week, Month, and Quarter.</p>
								</td>
								<td className='py-4 px-6 text-center'>
									Changes the order of the y-axis in the following order: Group By, View By. Sort By options vary based on
									selected View By and Group By options.
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 whitespace-nowrap font-semibold'>Facility Utilization</td>
								<td className='py-4 px-6 text-center'>Changes the x-axis grouping variable for the vertical bar chart.</td>
								<td className='py-4 px-6 text-center'></td>
								<td className='py-4 px-6 text-center'></td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 whitespace-nowrap font-semibold'>Scheduling Patterns</td>
								<td className='py-4 px-6 text-center'>Changes the x-axis grouping variable for the vertical bar chart.</td>
								<td className='py-4 px-6 text-center'></td>
								<td className='py-4 px-6 text-center'></td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 whitespace-nowrap font-semibold'>Surgical Volume</td>
								<td className='py-4 px-6 text-center'>Changes the column grouping variable for the table.</td>
								<td className='py-4 px-6 text-center'></td>
								<td className='py-4 px-6 text-center'></td>
							</tr>
						</tbody>
					</table>
				</Accordion.Item>

				<Accordion.Item value='Surgeon Scorecards'>
					<p className='mb-3'>
						Surgeon scorecards are useful for viewing operational metrics for an individual surgeon at a calendar month level.
						You can quickly browse these metrics by hitting the arrows next the month displayed, or open up a date picker and
						select the month of interest.
					</p>
					<p className='mb-3'>
						You can expand each metric displayed to get more insights as well. Use the arrow button to the right of each row
						to see which other metrics are available.
					</p>
				</Accordion.Item>

				<Accordion.Item value='Flip Rooms'>
					<p className='mb-3'>
						Flip rooms are used strategically across the country to increase individual surgeon productivity. Sometimes,
						however, their use is not optimally efficient. The flip rooms page gives users cutting-edge insights into how flip
						rooms are being used at a given facility.
					</p>
					<ul className='list-disc pl-5 mb-3'>
						<li className='mb-3'>
							Flip rate shows how often a surgeon is flipped by taking the quotient of the count of times a surgeon was flipped
							over their total case volume. Naturally, surgeons that receive true flips more often will have a higher flip
							rate.
						</li>
						<li className='mb-3'>
							It&apos;s also important to know the average case duration for cases when a surgeon is flipped. If the average
							case duration for these cases is higher than the facility of service line average, it calls into question the
							opportunity cost of providing a flip room to that particular surgeon. In other words, if you could perform
							another case in the same amount of time (or less) as a flipped case, it might not be worth the additional
							resources required to provide a flip room.
						</li>
						<li className='mb-3'>
							We also show the average case volume performed on days when a surgeon receives a flip. Like the point above, if
							you meet the case duration criteria, it&apos;s in the organization&apos;s best interest to also set a minimum
							case volume requirement so that both rooms being used are well-utilized. If a surgeon has efficient case
							durations but low case volume, it&apos;s likely that they finish their day early and the rooms that were reserved
							for flipped cases may sit empty for the rest of the day.
						</li>
					</ul>
					<p className='mb-3'>
						Thus, it&apos;s important to take these factors into considerations when assigning flip rooms. While flipping
						surgeons is undoubtedly a productivity boost for surgeons, the organization should evaluate the tradeoffs
						they&apos;re making with respect to patient throughput, resource capacity, and overall efficiency.
					</p>
				</Accordion.Item>

				<Accordion.Item value='Heatmap tips & tricks'>
					<p className='mb-3'>
						The heatmap visualizes operating room demand by day of week (DOW), and hour of day. To do so, our rooms running
						algorithm transforms timestamp information to display the concurrent number of operating room resources in use
						over a defined time period. This time period can be yearly, quarterly, monthly, or even daily, but we typically
						recommend longer time windows (at least monthly) to get a better representative sample.
					</p>
					<p className='mb-3'>
						The goal of this visualization is to align resource allocation match demand. Historically, it was difficult to
						measure the number of resources needed at the 15:00, 17:00, or 19:00 hours as operating rooms began to draw down.
						The heatmap makes determining this need easier by expressing this demand probabilistically, expediting resource
						planning and the creation of accurate staffing models.
					</p>

					<p className='font-semibold text-p1 mb-3 mt-8'>Concurrency Method</p>
					<p className='mb-3'>
						You can choose from a few different statistical methods to evaluate how many rooms are running concurrently. The
						default method, “Percentile”, gives you the ability to select which percentile of the distribution of concurrently
						running rooms is represented. For example, if the heatmap shows 10 rooms running at 8 AM on Tuesday at the 95th
						percentile, it means that 95% of the time there are 10 or fewer rooms running concurrently, and only 5% of the
						time there are more than 10 rooms running concurrently. This helps the user to ignore anomalous peaks and provides
						a more accurate picture of the level of coverage required to run the operating room.
					</p>
					<p className='mb-3'>
						Another concurrency method you can select is “Average”. While this method is more computationally straightforward
						and easier to explain, often times it does not reflect how many resources are needed as accurately due to
						underlying asymmetric probability distributions. Using the average rooms running typically results in staffing
						levels that are either too high or too low, which can create sub-optimal staffing levels and unnecessary costs. We
						have chosen to include this method due to its historical usage, though we would not recommend relying on it to
						represent concurrent room usage accurately.
					</p>
					<p className='mb-3'>
						The final concurrency method available is called “Excalibur”. This method takes the midpoint between the average
						and maximum concurrency. This method was developed over years of developing anesthesiology staffing models, and is
						generally an adequate reflection of the number of anesthetizing locations needed for adequate coverage. When
						compared to the “Percentile” method, “Excalibur” typically achieves results between the 75th-85th percentiles.
						Another concurrency method you can select is “Average”. While this method is more computationally straightforward
						and easier to explain, often times it does not reflect how many resources are needed as accurately due to
						underlying asymmetric probability distributions. Using the average rooms running typically results in staffing
						levels that are either too high or too low, which can create sub-optimal staffing levels and unnecessary costs. We
						have chosen to include this method due to its historical usage, though we would not recommend relying on it to
						represent concurrent room usage accurately.
					</p>

					<p className='mb-3 italic'>Midwest Community Hospital - Main OR, Q1 2022</p>
					<img className='m-auto mb-1' alt='flip room example' src={heatmap} />
					<p className='text-gray-400 italic text-p3 mb-4'>
						A heatmap for the main operating room of a Southeastern community hospital for Q1 2022. Brighter yellow color
						represents a higher number of concurrent rooms running, darker purple represents a lower number of concurrent
						rooms running.
					</p>
					<p className='mb-3'>
						An important consideration is that the timestamp information we pull from the intraoperative record does not
						include turnover time, so adjustments are made accordingly when generating staffing models.
					</p>
					<p className='mb-3'>
						We&apos;ve recently expanded the functionality so that users can filter out data upstream before the heatmap
						visualization is generated. Thus, if you only want to see a heatmap for a subset of data available, just use the
						filter drawer to make relevant selections.
					</p>
					<p className='mb-3'>
						Important Note: since the heatmap uses an underlying probability distribution to generate the visualization, small
						sample sizes can, at times, distort results. For this reason, we recommend users proceed with caution using
						extensive filtering and short time periods (typically, date ranges under 1 month). One step to mitigate this would
						be to switch the percentile from 95% to 100%, so you&apos;re essentially viewing the maximum concurrent rooms for
						a given time period. An irregular underlying distribution could over-emphasize edge events due to the smaller
						sample size available.
					</p>
				</Accordion.Item>

				<Accordion.Item value='Anesthesia Staffing'>
					<p className='mb-3'>
						Staffing is a nuanced topic. It can be helpful to understand what tools are available to get a better
						understanding of how the dashboard is intended to work. The examples below are by no means exhaustive, but merely
						outline some scenarios where you could use the anesthesia staffing dashboard effectively.
					</p>
					<ul className='list-disc pl-5 mb-3'>
						<li className='mb-3'>
							A health system has decided to employ a group of anesthesiologists. They want to understand how the practice
							staffs so they can estimate the cost of anesthesia services.
						</li>
						<li className='mb-3'>
							An anesthesiology group is requesting an increase in stipend. You want to see if there are any operational
							factors that could be driving this request.
						</li>
						<li className='mb-3'>
							An anesthesiology group wants to compare the service levels of their current contract with their projected
							staffing patterns.
						</li>
						<li className='mb-3'>
							An anesthesiology group is interested in seeing provider-level metrics like the average shift length on worked
							days.
						</li>
					</ul>
				</Accordion.Item>

				<Accordion.Item value='Exporting data'>
					<p className='mb-3'>
						It&apos;s easy to export data from Merlin. You can quickly get data in .csv format by hitting the blue “Export”
						button on the right hand side of many pages.
					</p>
					<p className='mb-3'>The table below outlines the data export functionality for each relevant page.</p>

					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-6'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th className='py-3 px-6' scope='col'>
									Page
								</th>
								<th className='py-3 px-6' scope='col'>
									Export Description
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Timely Starts</td>
								<td className='py-4 px-6'>
									Exports data from the visualization with all filter, view by, group by, and sort by properties applied.
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Timeline View</td>
								<td className='py-4 px-6'>Exports data from the visualization with all filter properties applied.</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Turnover Time</td>
								<td className='py-4 px-6'>
									Exports data from the visualization with all filter, view by, group by, and sort by properties applied.
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Facility Utilization</td>
								<td className='py-4 px-6'>Exports data from the bottom table with all filter properties applied.</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Scheduling Patterns</td>
								<td className='py-4 px-6'>
									Exports data from the visualization with all filter and view by properties applied.
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Surgeon Scorecard</td>
								<td className='py-4 px-6'>
									Exports all data from the table (including expanded rows) with all filter properties applied.
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Surgical Volume</td>
								<td className='py-4 px-6'>Exports all data from the table with all filter and group by properties applied.</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Flip Rooms</td>
								<td className='py-4 px-6'>Exports all data from the table with all filter properties applied.</td>
							</tr>
						</tbody>
					</table>
				</Accordion.Item>
				<Accordion.Item value='Data Integrity'>
					<p className='mb-3'>
						Merlin has advanced data integrity controls that help manage overall data quality. Merlin is intended to be used
						as a preoperative analytics suite, not as a medical record. As such, we exclude some of the data we receive to
						ensure that data manipulations and operational analytics can be provided.
					</p>

					<table className='w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-6 table-auto'>
						<thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
							<tr>
								<th className='py-3 px-6 w-2/5' scope='col'>
									Exclusion Reason
								</th>
								<th className='py-3 px-6' scope='col'>
									Description
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Patient In Room Timestamp Missing</td>
								<td className='py-4 px-6'>
									If we do not receive this timestamp for a record, the record is excluded from our reporting database. This is a
									required field that is necessary to compute case duration, on time starts, turnover time, block contributions,
									etc. It is also sometimes parsed for date of service information.
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Patient In Room Timestamp Invalid Format</td>
								<td className='py-4 px-6'>
									If this timestamp is not in a parseable date or datetime format, the record may be excluded as it is a required
									field. Sometimes, this field may have corrupted encoding or stray characters like “!@#$%^&*()&quot;, or is just
									not in any kind of parseable format “1-01/20222 12343256423”.
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Patient Out Room Timestamp Missing</td>
								<td className='py-4 px-6'>
									If we do not receive this timestamp for a record, the record is excluded from our reporting database. This is a
									required field that is necessary to compute case duration, on time starts, turnover time, block contributions,
									etc.
								</td>
							</tr>

							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Patient Out Room Timestamp Invalid Format</td>
								<td className='py-4 px-6'>
									If this timestamp is not in a parseable date or datetime format, the record may be excluded as it is a required
									field. Sometimes, this field may have corrupted encoding or stray characters like “!@#$%^&*()&quot;, or is just
									not in any kind of parseable format “1-01/20222 12343256423”.
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Room Label Missing</td>
								<td className='py-4 px-6'>
									If a record is received with no room label, Merlin cannot validate that the record is attributable to a
									licensed room. These records are excluded from our reporting database. Similarly,
									<span className='font-semibold text-p2'>
										{`records with unlicensed room labels will not be available in Merlin.`}
									</span>
									{`If you’d like to see operational information from these procedural areas, contact your Merlin administrator
									about licensing options. `}
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Surgeon Label Missing</td>
								<td className='py-4 px-6'>
									If a record is received with no primary surgeon label, it is excluded from our reporting database.
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Surgery Date Missing</td>
								<td className='py-4 px-6'>
									If a record is received with missing or un-parsable surgery date or date of service information, it is excluded
									from our reporting database.{' '}
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Surgery Date Invalid Format</td>
								<td className='py-4 px-6'>{`Sometimes, we parse the Surgery Date information from the Patient In Room timestamp. If we do not receive the Patient In Room timestamps in datetime or other parsable format, the record is excluded from our database. Sometimes, this field may have corrupted encoding or stray characters like “!@#$%^&*()", or is just not in any kind of parseable format “1-01/20222”.`}</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Duration Error</td>
								<td className='py-4 px-6'>
									{`Sometimes there are potential charting errors that create case duration errors, like negative durations (Patient Out Room comes before Patient In Room) or case durations >1440m (24h). The default time window for a duration error is 1440m. We understand there are some exceptions where surgical case durations can exceed 24h. Merlin can dynamically adjust this tolerance window, so if you’d like to customize what an acceptable duration window error is, contact your Merlin administrator.`}
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>Scheduled Start Missing (Deprecated)</td>
								<td className='py-4 px-6'>
									If we do not receive this timestamp for a record, the record is excluded from our reporting database. This is a
									required field that is necessary to compute on time starts.
									<span className='font-semibold text-p2'>
										{`This rule has been deprecated since Merlin V1 and is not used in Merlin V2. These records are only excluded
										for calculations where Scheduled Start is required, e.g. First Case On Time Starts rate.`}
									</span>
								</td>
							</tr>
							<tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
								<td className='py-4 px-6 font-semibold text-p2 whitespace-nowrap'>
									Scheduled Start Invalid Format (Deprecated)
								</td>
								<td className='py-4 px-6'>
									{`If this timestamp is not in a parseable date or datetime format, the record may be excluded. Sometimes, this
									field may have corrupted encoding or stray characters like “!@#$%^&*()", or is just not in any kind of
									parseable format “1-01/20222 12343256423”. `}
									<span className='font-semibold text-p2'>{`This rule has been deprecated since Merlin V1 and is not used in
									Merlin V2. These records are only excluded for calculations where Scheduled Start is required, e.g. First Case
									On Time Starts rate.`}</span>
								</td>
							</tr>
						</tbody>
					</table>
				</Accordion.Item>
			</Accordion>
			<h1 className='font-bold text-h4 my-6'>Frequently Asked Questions</h1>
			<Accordion type='multiple' itemStyle='contained' className='mt-0'>
				<Accordion.Item value='How are data ingested?'>
					<p className='mb-3'>
						We receive, process, and ingest data one of several ways: direct upload via our web application, secure file
						transfer, or encrypted email transmission. Once data are received, they are processed and uploaded to our secure
						cloud data warehouse.
					</p>
				</Accordion.Item>

				<Accordion.Item value='How often are the data refreshed?'>
					<p className='mb-3'>
						Data are refreshed shortly after new data are received. Institutions typically produce extracts either weekly or
						biweekly, but we can receive data at any cadence. If all data received are in a clean format with minimal
						discrepancies, data are typically processed and uploaded to the portal within 1-2 business day(s) of receipt.
					</p>
					<p className='mb-3'>
						A fully automated data processing pipeline is under active development, and we expect to be able to process data
						within 1-2 hours of receipt, depending on size.
					</p>
				</Accordion.Item>

				<Accordion.Item value='How are data secured and managed?'>
					<p className='mb-3'>
						Data are secured using industry-leading security infrastructure by our cloud and authentication providers. For
						more specific information regarding information security, contact a member of the Merlin team.
					</p>
				</Accordion.Item>

				<Accordion.Item value='What happens if PHI is inadvertently included in the data transfer?'>
					<p className='mb-3'>
						We do not currently warehouse PHI. If PHI is sent to us inadvertently, it will not be inserted into our database.
						If PHI exists even in the raw data sent to Merlin, it will not be accessible by end users for security purposes,
						and will be removed from our server once relevant data have been processed and uploaded.
					</p>
				</Accordion.Item>

				<Accordion.Item value='How customizable are the reports in Merlin?'>
					<p className='mb-3'>
						Our application is configurable, and clients are encouraged to leverage current configuration options to meet
						their needs. If clients are unable to do so, we encourage them to connect with a member of the Surgical Directions
						or Merlin team, and we will incorporate their feedback into our product strategy and future product development
						efforts.
					</p>
				</Accordion.Item>

				<Accordion.Item value='I have a PI team, could Merlin still be useful to my organization?'>
					<p className='mb-3'>
						Absolutely! Reliable, actionable data are the key to any successful process improvement effort, and Merlin arms PI
						teams with the insights they need to transform hospital processes. By automating reports that are manually
						generated at many facilities, Merlin also frees up time for PI teams, giving them the confidence and bandwidth to
						focus on other critical areas.
					</p>
				</Accordion.Item>

				<Accordion.Item value='How much does Merlin cost? Are there discounts for bundling?'>
					<p className='mb-3'>
						Merlin&apos;s subscription cost is based on the number of procedural areas (operating rooms or non-operating room
						areas) that are included in the reporting package. Please click{' '}
						<a target={'_blank'} href={'https://www.surgicaldirections.com/products/pricing-calculator/'} rel='noreferrer'>
							here
						</a>{' '}
						to view our pricing.
					</p>
					<p className='mb-3'>
						Merlin Pro subscribers receive discounted access to all of the reporting functionality of Merlin Core and Merlin
						Block. Discounts are also available for bulk purchases and subscriptions longer than 12 months.
					</p>
				</Accordion.Item>

				<Accordion.Item value='How do I schedule a demo for my organization?'>
					<p className='mb-3'>
						To learn more about how Merlin can enhance your perioperative reporting, please request a demo{' '}
						<a href={'https://www.surgicaldirections.com/products/'} target={'_blank'} rel='noreferrer'>
							here
						</a>
						. Also, you can contact us directly via{' '}
						<a target='_blank' href={`mailto:Merlinhelp@surgicaldirections.com?subject=Demo Request`} rel='noreferrer'>
							merlinhelp@surgicaldirections.com
						</a>
					</p>
				</Accordion.Item>

				<Accordion.Item value='How long is the Merlin installation process?'>
					<p className='mb-3'>
						Merlin&apos;s installation time varies depending on the product purchased and the size of the facility (number of
						procedural rooms). Merlin Core is typically installed and running within 3-5 business day(s) of purchase. Merlin
						Block, Pro, and Heatmap are typically installed and running within 5-10 business days of purchase.
					</p>
				</Accordion.Item>

				<Accordion.Item value='How much effort does someone from my facility have to dedicate to maintain Merlin?'>
					<p className='mb-3'>
						Once installed, Merlin Core requires minimal monthly maintenance to update. A user typically needs to spend less
						than 1 hour per month to ensure that data were transferred appropriately.
					</p>
					<p className='mb-3'>
						Merlin Block and Pro require slightly more monthly maintenance if there are changes to the block schedule that
						need to be logged into Merlin that month. A user typically needs to spend between 1 to 8 hours per month,
						depending on the extent and complexity of the block changes.
					</p>
					<p className='mb-3'>
						If you need additional support, contact Surgical Directions about other ways we can save your valuable resources
						time.
					</p>
				</Accordion.Item>

				<Accordion.Item value='Is Merlin only for operating rooms?'>
					<p className='mb-3'>
						Although our customers most frequently use Merlin to gain insights into operating room performance, it can also be
						used with non-operating room area (NORA) locations, including endoscopy suites, cardiac catheterization labs,
						interventional radiology sites, and procedure rooms.
					</p>
				</Accordion.Item>

				<Accordion.Item value='If I buy Merlin, how will I get in touch with Surgical Directions if I need technical support?'>
					<p className='mb-3'>
						Merlin Core, Block, or Pro subscribers will have access to an in-app contact link to submit support requests that
						the Surgical Directions team will respond to within 1-2 business days
					</p>
					<p className='mb-3'>
						Merlin Heatmap be assigned a Customer Success Manager to discuss findings, surface insights, and help maximize the
						value of Merlin&apos;s software offerings.
					</p>
				</Accordion.Item>

				<Accordion.Item value='Can Merlin help me create better staffing models?'>
					<p className='mb-3'>
						Merlin Heatmap can provide you the information you need to match staff resources and shifts to the busiest times
						in your organization based on day of week and time of day. Merlin Customer Success Managers have experience with
						staffing models and can serve as an extension of your team during your process improvement journey.
					</p>
				</Accordion.Item>

				<Accordion.Item value='Is Merlin mobile friendly?'>
					<p className='mb-3'>
						Yes, Merlin is build using modern web frameworks and should work well on Desktop and Mobile Tablets. While it can
						be used on a smart phone, we recommend more screen real estate for improved usability.
					</p>
				</Accordion.Item>

				<Accordion.Item value='Who in my organization would benefit most from Merlin?'>
					<p className='mb-3'>
						Merlin&apos;s clear, actionable reporting provides value at multiple levels of an organization. It enables
						executives to keep a pulse on the performance of the OR by providing a tailored snapshot of perioperative KPIs. It
						helps surgeons understand their block needs and simplifies the process for releasing block. It makes it easy for
						the OR&apos;s clinical leaders to get the data they need to drive lasting change
					</p>
				</Accordion.Item>
			</Accordion>
		</div>
	);
}

export default Help;
