import { Link, Location } from 'react-router-dom';
import classNames from 'classnames';
import { format } from 'date-fns';
import { Tooltip } from 'react-tooltip';
import { useEffect, useState } from 'react';
import { Parser } from 'json2csv';

import { FacilityLicense, Routes, UserRole } from 'models';
import { checkLicenseBits, env, intersects } from 'utils';
import { handleApiResponse, ShortOption, useSystem } from 'store';
import TextField from 'components/TextField';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import { MultiSelect } from 'components/Select';
import { Datepicker } from 'components/Datepicker';
import { useFilters } from 'context';
import FilterFields from 'components/FilterFields';
import { useRequestBlockForecastMutation } from 'store/services/BlockService';
import ToggleGroup from 'components/ToggleGroup';
import NumberField from 'components/NumberField';

export interface NavMenuProps {
	menu: NavigationMenu;
	location: Location;
	hovering: boolean;
	facility_license?: string;
	has_schedule?: boolean;
}

export interface NavigationItem {
	name: string;
	href: string;
	icon: string;
	component?: string;
	required_license?: number;
	children?: NavigationItem[];
	schedule_required?: boolean;
}

export interface NavigationMenu {
	items: NavigationItem[];
}

export function NavMenu({ menu, location, hovering, facility_license, has_schedule }: NavMenuProps) {
	const { data } = useSystem();
	const isAdmin = data?.user.role === UserRole.admin;
	const earlyAccessPages = data?.user.page_list ?? [];

	return (
		<nav>
			<ul>
				{menu.items.map((item: NavigationItem, index: number) => {
					const children = item.children ?? [];
					const children_href_list = children.map((child) => child.href);
					const isCurrentPage = location.pathname === item.href;
					const isCurrentPageParent = children?.find((s) => location.pathname === s.href);
					const validatedByLicenseType = facility_license
						? checkLicenseBits(facility_license, item?.required_license ?? -1)
						: false;
					const validatedByScheduleData = item.schedule_required ? has_schedule === item.schedule_required : true;
					const validatedByPageList =
						intersects(children_href_list, earlyAccessPages) || earlyAccessPages.includes(item.href);
					const allowAccess = (validatedByLicenseType && validatedByScheduleData) || validatedByPageList;
					const showChildren = children.length > 0 && (isCurrentPage || isCurrentPageParent) && allowAccess;

					if (item.href === Routes.STYLEGUIDE && !env('SHOW_STYLEGUIDE', true)) return null;
					if (item.href === Routes.ANESTHESIA_STAFFING && !isAdmin && !validatedByPageList) return null;
					if (item.href === Routes.NURSE_STAFFING && !isAdmin && !validatedByPageList) return null;
					if (item.href === Routes.ANESTHESIA && !isAdmin && !validatedByPageList) return null;
					if (item.component && item.component === 'block-forecast' && !isAdmin) return null;
					if (item.component ? item.component === 'block-forecast' : false)
						return (
							<BlockForecastTab
								isCurrentPage={false}
								isCurrentPageParent={false}
								allowAccess
								icon={item.icon}
								name={item.name}
								hovering={hovering}
							/>
						);

					return (
						<li key={index} className='group'>
							<Link
								to={allowAccess ? item.href : location.pathname}
								className={classNames(
									'flex items-center gap-3 text-blue-700 pl-8 whitespace-nowrap text-sm py-3 my-2 transition-colors',
									(isCurrentPage || isCurrentPageParent) && allowAccess
										? 'bg-blue-50 border-r-4 border-r-blue-500'
										: allowAccess
										? 'hover:bg-blue-50 font-regular'
										: 'font-regular opacity-50',
									!allowAccess ? 'cursor-not-allowed' : ''
								)}
							>
								<span className='material-symbol'>{item.icon}</span>
								<span className={classNames('transition-opacity duration-300', hovering ? 'opacity-1' : 'opacity-0')}>
									{item.name}
								</span>
							</Link>
							{showChildren && (
								<ul className='my-4'>
									{children.map((child, childIndex) => {
										if (item.href === Routes.STYLEGUIDE && !env('SHOW_STYLEGUIDE', true)) return null;
										if (item.href === Routes.ANESTHESIA_STAFFING && !isAdmin && !validatedByPageList) return null;
										if (item.href === Routes.ANESTHESIA && !isAdmin && !validatedByPageList) return null;
										if (child.schedule_required === true && has_schedule === false) return null;

										// for early access, if facility does not have license but user has special access
										if (!validatedByLicenseType && !earlyAccessPages.includes(child.href)) return null;

										return (
											<li key={childIndex}>
												<Link
													to={child.href}
													className={classNames(
														'flex items-center gap-4 border-0 text-p2 pl-9 py-1 my-1',
														'transition-[opacity,transform,color] whitespace-nowrap hover:font-bold',
														hovering && 'text-blue-700 translate-x-3',
														location.pathname === child.href ? 'text-blue-700 font-bold' : 'font-regular',
														location.pathname !== child.href && !hovering && 'text-bluegray-200'
													)}
												>
													<span className='material-symbol-sm'>{child.icon}</span>
													<span className={classNames('transition-opacity duration-300', hovering ? 'opacity-1' : 'opacity-0')}>
														{child.name}
													</span>
												</Link>
											</li>
										);
									})}
								</ul>
							)}
						</li>
					);
				})}
			</ul>
		</nav>
	);
}

interface BlockForecastTabProps {
	isCurrentPage: boolean;
	isCurrentPageParent: boolean;
	allowAccess: boolean;
	icon: string;
	name: string;
	hovering: boolean;
}

function BlockForecastTab({
	isCurrentPage,
	isCurrentPageParent,
	allowAccess,
	icon,
	name,
	hovering,
}: BlockForecastTabProps) {
	const [sumbitForecast] = useRequestBlockForecastMutation();
	const { dateRange, rooms, procedures, turnoverTimeThreshold } = useFilters();
	const [openViewModal, setOpenViewModal] = useState(false);

	const { data: facility_data } = useSystem();
	const [selectedFacilities, setSelectedFacilities] = useState(
		facility_data?.facilities
			.filter((f) => !f.name.includes('*'))
			.map((f) => {
				return { value: f.id, label: f.name };
			})
	);
	const [facilityBreakdown, setFacilityBreakdown] = useState('yes');
	const [growthFactor, setGrowthFactor] = useState(0);
	const [blockLength, setBlockLength] = useState(480);
	const [cystoRooms, setCystoRooms] = useState<ShortOption[]>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setSelectedFacilities(
			facility_data?.facilities
				.filter((f) => !f.name.includes('*'))
				.map((f) => {
					return { value: f.id, label: f.name };
				})
		);
	}, [facility_data]);

	const submitForecastRequest = async () => {
		setLoading(true);
		const response = await sumbitForecast({
			start_date: format(dateRange.selected.startDate, 'MM/dd/yyyy'),
			end_date: format(dateRange.selected.endDate, 'MM/dd/yyyy'),
			procedures: procedures.selected.map((x) => x.name),
			tot_threshold: turnoverTimeThreshold.selected,
			growth_factor: growthFactor,
			block_length: blockLength,
			cysto_rooms: cystoRooms,
			facility_breakdown: facilityBreakdown === 'yes' ? true : false,
			facilities: selectedFacilities?.map((f) => f.value) ?? [],
		});

		handleApiResponse(response, {
			success: (payload) => {
				// create and download csv file
				const parser = new Parser();
				const blob = new Blob([parser.parse(payload.forecast)], { type: 'text/csv;charset=utf-8;' });
				const link = document.createElement('a');
				link.style.visibility = 'hidden';
				const url = URL.createObjectURL(blob);
				link.setAttribute('href', url);
				link.setAttribute('download', `Block_Forecast_${Math.floor(Math.random() * 1000)}.csv`);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);

				// update states
				setLoading(false);
				setTimeout(() => {
					setOpenViewModal(false);
				}, 0);
			},
			error: (payload) => {
				setLoading(false);
			},
		});
	};

	return (
		<div className=''>
			<Dialog
				sizeX='lg'
				title='Block Forecast'
				subtitle='Use this tool to export a block forecast. If you experience any issues, contact a member of the Merlin team.'
				trigger={
					<div
						className={classNames(
							'cursor-pointer flex items-center gap-3 text-blue-700 pl-8 whitespace-nowrap text-sm py-3 my-2 transition-colors',
							(isCurrentPage || isCurrentPageParent) && allowAccess
								? 'bg-blue-50 border-r-4 border-r-blue-500'
								: allowAccess
								? 'hover:bg-blue-50 font-regular'
								: 'font-regular opacity-50',
							!allowAccess ? 'cursor-not-allowed' : ''
						)}
					>
						<span className='material-symbol'>{icon}</span>
						<span className={classNames('transition-opacity duration-300', hovering ? 'opacity-1' : 'opacity-0')}>
							{name}
						</span>
					</div>
				}
				actionButtons={[
					{
						children: 'Submit',
						isWorking: loading,
						onClick: () => {
							submitForecastRequest();
						},
					},
					{
						children: 'Cancel',
						onClick: () => setOpenViewModal(false),
					},
				]}
				open={openViewModal}
				onOpenChange={(isBeingOpened) => {
					if (isBeingOpened) {
						setOpenViewModal(true);
					} else {
						setOpenViewModal(false);
					}
				}}
			>
				<div className='flex overflow-auto max-h-[30em]'>
					<div className='flex flex-col pt-5 text-[0.8em] text-gray-500 italic'>
						<div className='bg-blue-50 p-2 rounded-md flex w-fit mt-2'>
							<p className='text-blue-500 font-semibold text-[1em] pr-1 not-italic'>1</p>
							<p>Which facilities are included in the block forecast?</p>
						</div>

						<div className='bg-blue-50 p-2 rounded-md flex w-fit mt-10'>
							<p className='text-blue-500 font-semibold text-[1em] pr-1 not-italic'>2</p>
							<p>Should the forecast be broken down by facility?</p>
						</div>

						<div className='bg-blue-50 p-2 rounded-md flex w-fit mt-10'>
							<p className='text-blue-500 font-semibold text-[1em] pr-1 not-italic'>3</p>
							<p>What growth factor would you like projected?</p>
						</div>

						<div className='bg-blue-50 p-2 rounded-md flex w-fit mt-[5.8em]'>
							<p className='text-blue-500 font-semibold text-[1em] pr-1 not-italic'>4</p>
							<p>Which rooms are cysto?</p>
						</div>

						<div className='bg-blue-50 p-2 rounded-md flex w-fit mt-[4.5em]'>
							<p className='text-blue-500 font-semibold text-[1em] pr-1 not-italic'>5</p>
							<p>What&apos;s the block length?</p>
						</div>

						<div className='bg-blue-50 p-2 rounded-md flex w-fit mt-[5em]'>
							<p className='text-blue-500 font-semibold text-[1em] pr-1 not-italic'>6</p>
							<p>What date range would you like included in the forecast?</p>
						</div>

						<div className='bg-blue-50 p-2 rounded-md flex w-fit mt-[4.5em]'>
							<p className='text-blue-500 font-semibold text-[1em] pr-1 not-italic'>7</p>
							<p>What&apos;s the turnover time threshold?</p>
						</div>

						<div className='bg-blue-50 p-2 rounded-md flex w-fit mt-[4em]'>
							<p className='text-blue-500 font-semibold text-[1em] pr-1 not-italic'>8</p>
							<p>Is there robotic case filtering?</p>
						</div>
					</div>
					<div className='flex flex-col h-[25em] w-[20em]'>
						<div className='px-4'>
							<MultiSelect
								label={'Included Facilities'}
								options={
									facility_data?.facilities
										.filter((f) => !f.name.includes('*'))
										.map((f) => {
											return { value: f.id, label: f.name };
										}) ?? []
								}
								onChange={(selections) => {
									setSelectedFacilities(selections.map(({ value: id, label: name }) => ({ value: id, label: name })));
								}}
								value={selectedFacilities}
							/>
						</div>
						<div className='px-4 pt-3'>
							<ToggleGroup
								label='Facility Breakdown'
								className='w-28'
								options={[
									{ value: 'yes', label: 'Yes' },
									{ value: 'no', label: 'No' },
								]}
								defaultValue={facilityBreakdown}
								onValueChange={(selected) => setFacilityBreakdown(selected)}
							/>
						</div>
						<div className='px-4 pt-3'>
							<NumberField
								label='Growth Factor'
								min={0}
								max={10}
								value={growthFactor}
								onChange={(e) => {
									if (!e.currentTarget.value) return;
									setGrowthFactor(Number(e.currentTarget.value));
								}}
							/>
						</div>
						<div className='px-4 my-4'>
							<MultiSelect
								label={'Cysto Rooms'}
								options={
									rooms.all.map((f) => {
										return { value: f.id, label: f.name };
									}) ?? []
								}
								onChange={(selections) => {
									setCystoRooms(selections.map(({ value: id, label: name }) => ({ id, name })));
								}}
								value={cystoRooms.map(({ id, name }) => ({ value: id, label: name }))}
							/>
						</div>
						<div className='px-4 pt-3'>
							<NumberField
								label='Block Length'
								min={240}
								max={840}
								value={blockLength}
								onChange={(e) => {
									if (!e.currentTarget.value) return;
									setBlockLength(Number(e.currentTarget.value));
								}}
							/>
						</div>
						<FilterFields fields={['dateRange', 'procedures', 'turnoverTimeThreshold']} />
					</div>
				</div>
			</Dialog>
		</div>
	);
}

export default NavMenu;
