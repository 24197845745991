import { Children, isValidElement } from 'react';
import classNames from 'classnames';

import type { HTMLAttributes } from 'react';

type PropTableProps<D, C> = HTMLAttributes<HTMLDivElement> & {
	columnWidths?: [`${number}px` | `${number}%` | 'auto', `${number}px` | `${number}%` | 'auto'];
	defaults?: D;
	children: C | C[];
};

/**
 * Creates an HTML-friendly representation of a provided React node prop object.
 *
 * @param propObject The prop object to convert to a string.
 * @param defaultPropValues A prop object where each value should be considered the default for that prop. Default
 * 												  values are surrounded with square brackets to indicate they can usually be omitted.
 * @returns An array of any valid props as strings within preformatted `<code>` elements.
 */
function getPropOutput<PO, DPV>(propObject: PO, defaultPropValues: DPV) {
	return Object.entries(propObject).map(([k, v]) => {
		if (k === 'children') return null;

		let propAsString = `${k}='${v}'`;

		if (typeof v === 'boolean' && v) {
			propAsString = k;
		} else if (typeof v === 'object') {
			propAsString = `${k}={${JSON.stringify(v, null, 2)}}`;
		} else if (typeof v === 'function') {
			propAsString = `${k}={${v}}`;
		}

		if (defaultPropValues && defaultPropValues[k as keyof typeof defaultPropValues] === v) {
			propAsString = `[${propAsString}]`;
		}

		return <code key={k}>{propAsString}</code>;
	});
}

/**
 * Creates a documentation table out of any nested React components. Each child
 * component will receive its own row where the first column displays the props
 * passed to it and the second column displays its actual output. Note that the
 * `children` prop is omitted for brevity.
 *
 * You can supply a `defaults` prop (an object of key/value pairs) to set the
 * default values for specific props on any nested components. Any child prop
 * that matches a default will be surrounded with square brackets in the Props
 * column to indicate to the reader that it can normally be omitted.
 */
export function PropTable<D, C>({
	columnWidths = ['auto', '100%'],
	defaults,
	className,
	children,
	...props
}: PropTableProps<D, C>) {
	return (
		<div className={classNames('overflow-hidden rounded border border-gray-400 bg-white', className)} {...props}>
			<table className='w-full'>
				<colgroup>
					<col width={columnWidths[0]} />
					<col width={columnWidths[1]} />
				</colgroup>

				<thead>
					<tr className='font-bold bg-blue-50 border-b border-gray-400'>
						<td className='p-2'>Props</td>
						<td className='p-2 border-l border-gray-400'>Output</td>
					</tr>
				</thead>

				<tbody>
					{children &&
						Children.toArray(children).map((child, i) => (
							<tr key={`${i}`} className='border-b border-gray-400 last:border-0'>
								<td className='p-2 text-p2 font-semibold bg-blue-50'>
									<pre className='flex flex-1 flex-col gap-1'>
										{isValidElement(child) && getPropOutput<typeof child['props'], typeof defaults>(child.props, defaults)}
									</pre>
								</td>
								<td className='p-2 border-l border-gray-400'>{child}</td>
							</tr>
						))}
				</tbody>
			</table>
		</div>
	);
}

export default PropTable;

/**
 * This component is generically typed and can be used to create PropTables
 * specific to a particular child component, enhancing type annotations for that
 * component. The following example shows how you could do this for the
 * <TextField /> component.
 */
// import { TextField } from 'components';
// import type { TextFieldProps } from 'components';
//
// export const TextFieldPropTable = (props: PropTableProps<TextFieldProps, ReturnType<typeof TextField>>) =>
// 	PropTable<TextFieldProps, ReturnType<typeof TextField>>(props);
