import { license16Bit, reverseLicenseMap } from 'models';

/**
 * Converts a given string to a boolean value by comparing against a set list of
 * "truthy" string values.
 *
 * @param stringValueToParse The string value to parse into a boolean.
 * @returns `true` for the values "true", "on", "yes", and "1", otherwise `false`.
 */
export function parseStringValueAsBoolean(stringValueToParse: string) {
	const truthyStrings = ['true', 'on', 'yes', '1'];
	const normalizedStringValue = stringValueToParse.toLowerCase();

	if (truthyStrings.includes(normalizedStringValue)) {
		return true;
	}

	return false;
}

/**
 * Adds a comma to a string representation of a number.
 */
export function numberWithCommas(x: string) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * Create 16bit string for a specific license
 *
 * @param index - The zero-based index of the character to be replaced.
 * @returns A new 16bit string
 */
export function createLicenseBits(index: number): string {
	const arr: string[] = license16Bit.split('');
	arr[arr.length - 1 - index] = '1';
	return arr.join('');
}

/**
 * Checks if license type in 16bit string
 *
 * @param index - Index of license
 * @returns boolena
 */
export function checkLicenseBits(current_license: string = license16Bit, index: number): boolean {
	if (index === -1) {
		return true;
	}

	const arr: string[] = current_license.split('');
	return arr[arr.length - 1 - index] === '1';
}

/**
 * Converts an integer to a 16-bit binary string representation.
 *
 * This function first checks if the input integer is within the 16-bit range.
 * If it is, it converts the integer to a binary string using toString(2).
 * Then, it pads the binary string with leading zeros to ensure it's always 16 characters long,
 * representing a full 16-bit value in binary form.
 *
 * @param {number} input - The integer to be converted to a 16-bit binary string.
 * @returns {string} A 16-bit binary string representation of the input integer.
 * @throws Will throw an error if the input value is out of the 16-bit range.
 */
export function intTo16BitBin(input: number): string {
	// Ensure the input is within the 16-bit range
	if (input < -32768 || input > 32767) {
		throw new Error('Input value is out of the 16-bit range.');
	}

	// Convert the integer to a binary string
	let binStr = input.toString(2);

	// Pad the binary string to ensure it's 16 bits wide
	// Note: We subtract 1 because padStart adds one character for the padding
	binStr = binStr.padStart(16, '0');

	return binStr;
}

/**
 * Converts license string to license name
 *
 * @param {string} input - The license 16-bit string
 * @returns {string} Readable license names
 * @throws Will throw an error if the input value is out of the 16-bit range.
 */
export function getLicenseNames(input: string): string {
	// Convert the integer to a binary string
	const reversed_string = input.split('').reverse().join('').split('');

	let res = '';

	for (let i = 0; i < reversed_string.length; i++) {
		if (reversed_string[i] === '1') {
			if (res.length !== 0) {
				res += ', ';
			}

			res += reverseLicenseMap[i];
		}
	}

	return res;
}
