import * as Tabs from '@radix-ui/react-tabs';
import classNames from 'classnames';
import { Key, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

import { Button, LogoOverlay, MultiSelect, PageHeading, ToggleGroup, Tooltip } from 'components';
import { truncateLabel, userIs, xorArrays } from 'utils';
import { FacilityLicense, UserRole } from 'models';
import { useAppSelector, useGetUsersQuery, useSystem } from 'store';
import {
	NotificationInboxItem,
	useGetNotificationInboxQuery,
	useGetNotificationSubscriptionOptionsQuery,
	useGetNotificationSubscriptionQuery,
	useSetNotificationScheduleMutation,
	useUpdateNotificationMutation,
} from 'store/services/NotificationService';
import { LoadingIndicator } from 'components/Select/subcomponents';

const tabTriggerClassname = classNames(
	'py-2 px-6 relative padding-x-3 text-bluegray-300',
	'data-active:text-blue-500 data-active:border-b-2 data-active:border-blue-500 data-active:top-[1px]'
);
const tabContentClassname = 'py-4';

export function Notifications() {
	const { data, isLoading } = useGetNotificationInboxQuery(undefined);
	const read_notification = data?.notifications.filter((n) => n.read_status === true);
	const unread_notification = data?.notifications.filter((n) => !n.read_status);
	const unread_notifications_number = data?.notifications?.filter((n) => !n.read_status).length ?? 0;

	return (
		<div>
			<PageHeading>Notifications</PageHeading>

			<Tabs.Root defaultValue='unread_notifications' orientation='vertical'>
				<Tabs.List aria-label='Settings Tabs' className='flex border-b border-bluegray-100'>
					<Tabs.Trigger value='unread_notifications' className={tabTriggerClassname}>
						Unread Notifications {unread_notifications_number > 0 ? `(${unread_notifications_number})` : ''}
					</Tabs.Trigger>
					<Tabs.Trigger value='read_notifications' className={tabTriggerClassname}>
						Read Notifications
					</Tabs.Trigger>
					<Tabs.Trigger value='settings' className={tabTriggerClassname}>
						Settings
					</Tabs.Trigger>
				</Tabs.List>
				<Tabs.Content value='unread_notifications' className={tabContentClassname}>
					{isLoading && <LogoOverlay backgroundColor='white' />}
					<NotificationDashBoard data={unread_notification ?? []} />
				</Tabs.Content>
				<Tabs.Content value='read_notifications' className={tabContentClassname}>
					<NotificationDashBoard data={read_notification ?? []} />
				</Tabs.Content>
				<Tabs.Content value='settings' className={tabContentClassname}>
					<NotificationSettings />
				</Tabs.Content>
			</Tabs.Root>
		</div>
	);
}

interface NotificationInboxItemProps {
	id: number;
	category: string;
	subject: string;
	message: string;
	date: string;
	recipient: number;
	merlin_page_url?: string;
	read_status?: boolean;
}

// used to convert tag value to some int
function stringToSum(str: string): number {
	return str.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
}

const tagColors = ['bg-blue-500', 'bg-green-500', 'bg-purple-500', 'bg-bluegray-500', 'bg-teal-500'];

export function NotificationItem({
	id,
	category,
	subject,
	message,
	date,
	read_status,
	merlin_page_url,
}: NotificationInboxItemProps) {
	const [setNotificationStatus] = useUpdateNotificationMutation();
	const [loading, setLoading] = useState(false);

	const update_notification = () => {
		setLoading(true);
		setNotificationStatus({ notification_id: id, read_status: true });
	};

	const dateObj = new Date(date);

	// Format the date and time
	const formattedDate = dateObj.toLocaleString('en-US', {
		day: '2-digit',
		month: 'short',
		year: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		hour12: true,
	});

	const navigate = useNavigate();

	return (
		<div
			className={`${
				read_status ? 'bg-gray-50' : 'bg-blue-50 border border-white hover:border-blue-500 cursor-pointer'
			} rounded-md m-1 mb-3 flex w-full justify-between p-5 pb-6 z-10`}
		>
			<div className={`w-5 h-5 mr-10 z-30`}>
				{loading ? (
					<div className='ml-1 h-4 w-4 justify-center items-center'>
						<LoadingIndicator />
					</div>
				) : !read_status ? (
					<span
						className='text-bluegray-400 material-symbols-outlined cursor-pointer hover:text-bluegray-600'
						onClick={() => update_notification()}
					>
						close
					</span>
				) : (
					<span className='text-bluegray-400 material-symbols-outlined'>mark_email_read</span>
				)}
			</div>
			<div
				onClick={() => (merlin_page_url && !read_status ? navigate(merlin_page_url) : undefined)}
				className='flex flex-col w-3/4'
			>
				<p
					className={`text-[0.8em] ${
						tagColors[stringToSum(`${category.charAt(0)}${category.charAt(category.length - 1)}`) % 4]
					} text-white w-fit px-2 rounded-lg p-0.5 text-center ${read_status && 'bg-gray-400'}`}
				>
					{category}
				</p>
				<p className='font-semibold mt-2'>{subject}</p>
				<p className='text-[0.9em] text-gray-600'>{truncateLabel(message, 240)}</p>
			</div>
			<div
				onClick={() => (merlin_page_url && !read_status ? navigate(merlin_page_url) : undefined)}
				className='text-right mr-1 text-[0.9em] text-gray-400'
			>
				{formattedDate}
			</div>
		</div>
	);
}

export function NotificationItemMinimal({
	id,
	category,
	subject,
	message,
	date,
	read_status,
	merlin_page_url,
}: NotificationInboxItemProps) {
	const [setNotificationStatus] = useUpdateNotificationMutation();
	const [loading, setLoading] = useState(false);

	const update_notification = () => {
		setLoading(true);
		setNotificationStatus({ notification_id: id, read_status: true });
	};

	const dateObj = new Date(date);

	// Format the date and time
	const formattedDate = dateObj.toLocaleString('en-US', {
		day: '2-digit',
		month: 'short',
		year: 'numeric',
	});

	const navigate = useNavigate();

	return (
		<div
			className={`${
				read_status
					? 'bg-gray-50'
					: 'bg-sky-50 border border-blue-500 hover:bg-sky-100 cursor-pointer text-blue-500 h-10 items-center'
			} rounded-md mb-2 flex w-full justify-between p-1`}
		>
			<div className={`w-5 h-5 mr-5 z-30`}>
				{loading ? (
					<div className='ml-1 h-2 w-2 justify-center items-center'>
						<LoadingIndicator />
					</div>
				) : !read_status ? (
					<span
						className='text-bluegray-400 material-symbols-outlined cursor-pointer hover:text-bluegray-600'
						onClick={() => update_notification()}
					>
						close
					</span>
				) : (
					<span className='text-bluegray-400 material-symbols-outlined'>mark_email_read</span>
				)}
			</div>
			<div
				onClick={() => (merlin_page_url && !read_status ? navigate(merlin_page_url) : undefined)}
				className='flex flex-col w-3/4'
			>
				<p className='text-[0.8em]'>{subject}</p>
			</div>
			<div
				onClick={() => (merlin_page_url && !read_status ? navigate(merlin_page_url) : undefined)}
				className='text-right mr-1 text-[0.7em] text-gray-400'
			>
				{formattedDate}
			</div>
		</div>
	);
}

interface NotificationDashBoardProps {
	data: NotificationInboxItem[];
}

export function NotificationDashBoard({ data }: NotificationDashBoardProps) {
	return (
		<div className='flex flex-col w-3/4 justify-center'>
			{data.map((item, i) => (
				<NotificationItem
					key={item.id}
					category={item.category}
					subject={item.subject}
					message={item.message}
					date={item.date}
					recipient={item.recipient}
					id={item.id}
					read_status={item.read_status}
					merlin_page_url={item.merlin_page_url}
				/>
			))}
			{data.length === 0 && <p>No Notifications</p>}
		</div>
	);
}

interface NotificationInboxItemType {
	title: string;
	description: string;
	merlin_page_url: string;
	subscribed_notifications: string[];
	type: string;
}

export function NotificationSettingItem({
	title,
	description,
	merlin_page_url,
	type,
	subscribed_notifications,
}: NotificationInboxItemType) {
	const [setNotificationSchedule, { isLoading }] = useSetNotificationScheduleMutation();
	const { data: currentUser } = useSystem();

	// seperate notifcation state for self and others
	const active_self = subscribed_notifications.includes(`${title}:${currentUser?.user.id}`);
	const original_active_others_hash = subscribed_notifications.filter(
		(e) => e !== `${title}:${currentUser?.user.id}` && e.includes(`${title}:`)
	);
	const original_active_others_ids = Array.from(
		new Set(original_active_others_hash.map((e) => parseInt(e.split(':')[1])))
	).filter((e) => e !== (currentUser?.user.id ?? 0));

	const { selectedSystem } = useAppSelector((state) => state.userState);
	const { data: usersData, isLoading: loadingUsers } = useGetUsersQuery({
		healthsystem_id: selectedSystem,
		short_with_local: true, // request all admins as well, regardless of hs
	});

	// handle schedule for others
	const delete_schedule_others = (newEntry: number[]) => {
		setNotificationSchedule({
			notification_name: title,
			healthsystem_id: selectedSystem,
			delete_schedule: true,
			merlin_page_url: merlin_page_url,
			user_ids: newEntry,
		});
	};

	const create_schedule_others = (newEntry: number[]) => {
		setNotificationSchedule({
			notification_name: title,
			healthsystem_id: selectedSystem,
			merlin_page_url: merlin_page_url,
			user_ids: newEntry,
		});
	};

	// handle schedule for self
	const create_schedule_self = () => {
		setNotificationSchedule({
			notification_name: title,
			healthsystem_id: selectedSystem,
			merlin_page_url: merlin_page_url,
			user_ids: [currentUser?.user.id ?? 0],
		});
	};

	const delete_schedule_self = () => {
		setNotificationSchedule({
			notification_name: title,
			healthsystem_id: selectedSystem,
			delete_schedule: true,
			merlin_page_url: merlin_page_url,
			user_ids: [currentUser?.user.id ?? 0],
		});
	};

	return (
		<div className='w-1/4 shadow-md border-2 border-blue-500 p-3 rounded-md flex flex-col justify-between h-48'>
			<div className='flex justify-between items-end'>
				<p className='mb-1 font-semibold text-[1.1em] text-gray-700'>{title}</p>
				<Tooltip content={active_self ? 'Disable for yourself' : 'Enable for yourself'}>
					<Button
						sizeX='square'
						sizeY='sm'
						variant={active_self ? 'primary' : 'primary-ghost'}
						className='rounded-md'
						onClick={() => (active_self ? delete_schedule_self() : create_schedule_self())}
						isWorking={loadingUsers || isLoading}
					>
						<span className='material-symbols-outlined'>check</span>
					</Button>
				</Tooltip>
			</div>

			<p className='text-[0.75em] text-gray-500'>{description}</p>
			{currentUser?.user.role === UserRole.admin && (
				<div className='flex justify-between gap-2 items-end'>
					<div className='w-full'>
						<MultiSelect
							sizeX='full'
							label={`Additional Recipients`}
							options={
								usersData?.users
									.filter((e) => e.id !== currentUser?.user.id)
									.map((u) => {
										return { value: u.id, label: u.email };
									}) ?? []
							}
							onChange={(selections) => {
								const selectedUsers = selections.map((s) => s.value);

								if (selectedUsers.length <= original_active_others_ids.length) {
									delete_schedule_others(xorArrays(original_active_others_ids, selectedUsers));
								} else {
									create_schedule_others(xorArrays(original_active_others_ids, selectedUsers));
								}
							}}
							value={usersData?.users
								.map((u) => {
									return { value: u.id, label: u.email };
								})
								.filter((e) => original_active_others_ids.includes(e.value))}
							disabled={loadingUsers || isLoading}
						/>
					</div>
				</div>
			)}
			<p className='text-[0.8em] text-blue-500 text-right m-0 mt-1'>{type}</p>
		</div>
	);
}

export function NotificationSettings() {
	const { selectedSystem } = useAppSelector((state) => state.userState);
	const { data, isLoading: loadingSubscriptions } = useGetNotificationSubscriptionQuery({
		healthsystem_id: selectedSystem,
	});
	const { data: notifications, isLoading: loadingSubscriptionOptions } = useGetNotificationSubscriptionOptionsQuery({
		healthsystem_id: selectedSystem,
	});
	return (
		<>
			{(loadingSubscriptions || loadingSubscriptionOptions) && <LogoOverlay backgroundColor='white' />}
			<div className='flex flex-wrap gap-4'>
				{notifications?.subscriptionOptions.map((item, i) => (
					<NotificationSettingItem
						key={`${data?.subscriptions}_${i}`}
						title={item.title}
						type={item.type}
						description={item.description}
						merlin_page_url={item.merlin_page_url}
						subscribed_notifications={data?.subscriptions ?? []}
					/>
				))}
			</div>
		</>
	);
}

export default Notifications;
