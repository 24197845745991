import classNames from 'classnames';

import type { HTMLAttributes } from 'react';

interface ColorTableProps extends HTMLAttributes<HTMLDivElement> {
	colors: {
		classSuffix: string;
		bg: string;
		text: string;
	}[];
}

/**
 * A table that provides a set of color swatches and Tailwind CSS class suffixes
 * for each color in a project's design system.
 */
export function ColorTable({ colors, className, ...props }: ColorTableProps) {
	return (
		<div className={classNames('overflow-hidden rounded border border-gray-400 bg-white', className)} {...props}>
			<table className='w-full'>
				<colgroup>
					<col width='auto' />
					<col width='100%' />
				</colgroup>

				<thead>
					<tr className='font-bold border-b border-gray-400 bg-blue-50'>
						<td className='p-2'>Swatches</td>
						<td className='p-2 border-l border-gray-400'>Tailwind Suffix</td>
					</tr>
				</thead>

				<tbody>
					{colors.map((color, i) => (
						<tr key={i} className='border-b border-gray-400 last:border-0'>
							<td className='flex gap-2 p-2'>
								<div className={`h-12 w-12 grid place-items-center shadow rounded-lg ${color.bg} text-white`}>Aa</div>
								<div className={`h-12 w-12 grid place-items-center shadow rounded-lg ${color.bg} text-black`}>Aa</div>
								<div className={`h-12 w-12 grid place-items-center shadow rounded-lg bg-white ${color.text}`}>Aa</div>
								<div className={`h-12 w-12 grid place-items-center shadow rounded-lg bg-black ${color.text}`}>Aa</div>
							</td>
							<td className='p-2 border-l border-gray-400'>
								<pre>
									<code>{color.classSuffix}</code>
								</pre>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

export default ColorTable;
