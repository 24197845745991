import { VictoryLabel, VictoryPie } from 'victory';

import { colorMaps, getColor, getColorForType } from 'utils';

import type { ChartColor } from 'utils';

export interface GaugeChartProps {
	/** The percentage amount that the chart should fill to. */
	percentage: number;

	/** The color the line needs to be. */
	progressColor?: ChartColor | keyof typeof colorMaps;

	/** The color of the text. */
	textColor: 'white' | 'black';

	/** The size of the radius of this gauge. */
	radius: number;

	/** The stroke width to be used when rendering the circular SVG. */
	strokeWidth: number;
}

export function GaugeChart({ percentage, progressColor, radius, strokeWidth, textColor }: GaugeChartProps) {
	if (progressColor && progressColor in colorMaps) {
		switch (progressColor) {
			case 'fcots':
				progressColor = getColorForType('fcots', percentage);
				break;
			case 'addon':
				progressColor = getColorForType('addon', percentage);
				break;
			case 'tot':
				progressColor = getColorForType('tot', percentage);
				break;
		}
	}
	const fontSizes: [number, number] = radius > 25 ? [16, 12] : [12, 8];
	return (
		<svg width={radius * 2} height={radius * 2}>
			<VictoryPie
				standalone={false}
				height={radius * 2}
				width={radius * 2}
				data={[
					{ x: 'Complete', y: percentage },
					{ x: 'Incomplete', y: 100 - percentage },
				]}
				radius={({ datum }) => (datum.x === 'Complete' ? radius : radius - 1)}
				innerRadius={({ datum }) => (datum.x === 'Complete' ? radius - strokeWidth : radius - strokeWidth + 1)}
				cornerRadius={({ datum }) => (datum.x === 'Complete' ? strokeWidth / 2 : 0)}
				labels={[]}
				animate={true}
				colorScale={[getColor(progressColor as ChartColor), 'black']}
				labelRadius={radius / 2}
			/>
			<VictoryLabel
				textAnchor='middle'
				inline
				style={[
					{ fontFamily: 'Inter', fontWeight: '600', fontSize: fontSizes[0], fill: textColor },
					{ fontFamily: 'Inter', fontWeight: '600', fontSize: fontSizes[1], fill: textColor },
				]}
				x={radius}
				y={radius}
				text={percentage === null ? '--' : `${percentage}%`}
			/>
		</svg>
	);
}

export default GaugeChart;
