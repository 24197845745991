/**
 * Keys used to update specific properties in localStorage
 */
export type LocalStorageKey = 'selectedSystem' | 'selectedFacility';

/**
 * Interface defining what the output will look like when sent through JSON.parse()
 */
export interface LocalStorageState {
	selectedSystem: number;
	selectedFacility: number;
}

/**
 * Export constant key for saving state in localstorage
 */
export const merlinStorageKey = 'merlin-state';
