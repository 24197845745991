import classNames from 'classnames';

import Logo from 'assets/images/branding/logo.svg';
import { WorkingIndicator } from 'components';

export interface LogoOverlayProps {
	backgroundColor: 'transparent' | 'white';
	pulseBackground?: boolean;
	printing?: boolean;
}

export function LogoOverlay({ backgroundColor, pulseBackground = false, printing }: LogoOverlayProps) {
	return (
		<div
			className={classNames('absolute -top-[2px] -bottom-[2px] -left-[2px] -right-[2px] z-10', {
				'bg-white': backgroundColor === 'white',
				'animate-pulse': pulseBackground,
			})}
		>
			<div className={`absolute left-1/2 flex flex-col justify-center items-center ${!printing ? 'top-32' : 'top-72'}`}>
				<div className='animate-pulse'>
					<img className='h-8 w-8 animate-bounce block mx-auto' src={Logo} alt='Merlin' />
				</div>
				<div className={`${!printing ? 'm-0 mb-2' : 'm-0'}`}>
					{!printing ? <WorkingIndicator size='lg' /> : <p className='text-gray-400 text-p3 m-0 pl-2'>Loading...</p>}
				</div>
			</div>
		</div>
	);
}

export default LogoOverlay;
