import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import { AlertProvider, AuthProvider, FilterProvider, reactPlugin, ToastProvider, TooltipProvider } from 'context';
import store from 'store';

import type { ReactNode } from 'react';

export function AppProviders({ children }: { children: ReactNode }) {
	return (
		<Provider store={store}>
			<AuthProvider>
				<AlertProvider>
					<ToastProvider>
						<TooltipProvider>
							<BrowserRouter>
								<FilterProvider>
									<AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>
								</FilterProvider>
							</BrowserRouter>
						</TooltipProvider>
					</ToastProvider>
				</AlertProvider>
			</AuthProvider>
		</Provider>
	);
}

export default AppProviders;
