import classNames from 'classnames';
import { HTMLAttributes } from 'react';

interface WorkingIndicatorProps extends HTMLAttributes<HTMLDivElement> {
	/** The preset size to apply to this working indicator. */
	size?: 'sm' | 'lg';
}

export function WorkingIndicator({ size, className, ...props }: WorkingIndicatorProps) {
	return (
		<div
			className={classNames('loader', { '[--loader-width:16px] [--loader-bar-length:4px]': size === 'sm' }, className)}
			{...props}
		/>
	);
}

export default WorkingIndicator;
