import { Fragment, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { bottomNav, Routes, topNav } from 'models/navigation';

interface BreadcrumbsProps {
	useURL?: boolean;
}

export function Breadcrumbs({ useURL }: BreadcrumbsProps) {
	const location = useLocation();

	let links = useMemo(() => {
		let found = false;
		const list = [topNav.items[0]];

		// build the structure only when routes change
		topNav.items.forEach((item) => {
			if (item.href === Routes.HOME) return;
			if (item.children) {
				item.children.forEach((child) => {
					if (child.href === location.pathname) {
						found = true;
						list.push(item);
						list.push(child);
						// found the current page, append it and its parent and stop looping
						return;
					}
				});

				if (item.href === location.pathname) {
					found = true;
					list.push(item);
				}
			}
		});

		if (!found) {
			bottomNav.items.forEach((item) => {
				if (item.href === location.pathname) {
					found = true;
					list.push(item);
				}
			});

			// after checking bottomNav as well, check if user is on settings page, which is not in the sidebar navigation
			if (location.pathname === Routes.SETTINGS) {
				list.push({ name: 'Settings', href: Routes.SETTINGS, icon: '' });
			}
		}

		return list;
	}, [location]);

	if (useURL) {
		const newList = [topNav.items[0]];
		const urlList = location.pathname.split('/');
		urlList.forEach((value, i) => {
			if (value) {
				const title = value.split('-').join(' ');
				newList.push({
					name: title,
					href: i > 1 ? `/${urlList[i - 1]}/${value}` : `/${value}`,
					icon: 'string',
				});
			}
		});
		links = newList;
	}

	const listLength = links.length;

	return (
		<div className='flex items-center justify-start mb-2'>
			{location.pathname !== Routes.HOME &&
				links.map((item, indx) => (
					<Fragment key={indx}>
						<Link className='text-black font-regular capitalize' to={item.href}>
							{item.name}
						</Link>
						{indx + 1 < listLength && <span className='material-symbol mx-1'>chevron_right</span>}
					</Fragment>
				))}
		</div>
	);
}
