import * as RadixPopover from '@radix-ui/react-popover';
import classNames from 'classnames';

import { Button } from 'components';

import type { ArrayOf } from 'types';

interface ButtonStackProps extends RadixPopover.PopoverProps {
	children: ArrayOf<'at least', 2, React.ReactElement>;
	inverted?: boolean;
}

export function ButtonStack({ children, inverted, ...props }: ButtonStackProps) {
	const [topmostButton, ...additionalButtons] = children;

	return (
		<RadixPopover.Root {...props}>
			<RadixPopover.Anchor
				className={classNames('flex items-stretch gap-px', {
					'w-button-sm': topmostButton.props.sizeX === 'sm',
					'w-button-md': topmostButton.props.sizeX === 'md',
					'w-button-lg': topmostButton.props.sizeX === 'lg',
					'w-full': topmostButton.props.sizeX === 'full',
				})}
			>
				<Button {...topmostButton.props} squareCorners='right' sizeX='full' />

				<div
					className={classNames({
						'basis-8': !topmostButton.props.sizeY || topmostButton.props.sizeY === 'sm',
						'basis-10': topmostButton.props.sizeY === 'md',
						'basis-12': topmostButton.props.sizeY === 'lg',
					})}
				>
					<RadixPopover.Trigger asChild>
						<Button squareCorners='left' variant={topmostButton.props.variant} sizeY={topmostButton.props.sizeY}>
							<div className={`material-symbol-sm ${inverted && 'transform rotate-180'}`}>expand_more</div>
						</Button>
					</RadixPopover.Trigger>
				</div>
			</RadixPopover.Anchor>

			<RadixPopover.Content
				align='start'
				sideOffset={1}
				className={classNames('w-12', 'data-above:animate-displayTooltipAbove data-below:animate-displayTooltipBelow')}
			>
				{additionalButtons.map((child, i) => (
					<Button key={i} sizeX='md' {...child.props} />
				))}
			</RadixPopover.Content>
		</RadixPopover.Root>
	);
}

export { ButtonStack as default };
