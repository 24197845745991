import * as React from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@radix-ui/react-popover';

type CustomPopoverProps = {
	trigger: React.ReactNode;
	content: React.ReactNode;
};

const PopOver: React.FC<CustomPopoverProps> = ({ trigger, content }) => {
	const [open, setOpen] = React.useState(false);

	return (
		<Popover open={open} onOpenChange={() => setOpen(false)}>
			<PopoverTrigger asChild onMouseOver={() => setOpen(true)} onMouseOut={() => setOpen(false)}>
				{trigger}
			</PopoverTrigger>
			<PopoverContent side='right'>{content}</PopoverContent>
		</Popover>
	);
};

export default PopOver;
