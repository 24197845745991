import * as Popover from '@radix-ui/react-popover';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

export interface Link {
	label: string;
	url: string;
	focusDot?: boolean;
}

export interface DropdownMenuProps {
	children: React.ReactNode;
	options: Link[];
	onOpenOrClose?: (open: boolean) => void;
}

export function DropdownMenu({ children, options, onOpenOrClose }: DropdownMenuProps) {
	const navigate = useNavigate();
	return (
		<div className='relative'>
			<Popover.Root onOpenChange={onOpenOrClose}>
				<Popover.Trigger asChild>{children}</Popover.Trigger>
				<Popover.Anchor />
				<Popover.Content
					className={classNames(
						'relative top-[5px] right-[15px]',
						'bg-white rounded border border-bluegray-100 drop-shadow-md'
					)}
				>
					<ul className='p-2 text-p2 max-w-[275px]'>
						{options.map((item: Link, index: number) => {
							return (
								<li key={index}>
									<Popover.Close
										onClick={() => navigate(item.url)}
										className={classNames(
											'w-full min-w-[125px] block my-1 p-3',
											'text-left rounded font-regular text-blue-600',
											'hover:bg-blue-50 hover:text-blue-700'
										)}
									>
										<div className='flex justify-between text-center items-center'>
											<p>{item.label}</p>
											{item.focusDot && <span className='bg-red-500 h-2 w-2 rounded-full'></span>}
										</div>
									</Popover.Close>
								</li>
							);
						})}
					</ul>
				</Popover.Content>
			</Popover.Root>
		</div>
	);
}

export default DropdownMenu;
