import { DAYS_OF_WEEK_LIST, MONTHS } from 'utils/constants';
import { Node, NodeSortableByDayOfWeek, NodeSortableByMonth, NodeSortableByYear } from 'types/nodes';

/**
 * Distills an array of nodes into an array of those nodes' values.
 *
 * @param nodes The nodes to pluck values from.
 * @returns An array of node values.
 */
export const getNodeValues = (nodes: Node[]) => nodes.map((n) => n.value);

/**
 * Sorts an array of nodes, automatically determining how to sort them based on
 * the nodes' collective properties.
 *
 * @param nodes Nodes to be sorted.
 * @returns Sorted nodes.
 */
export const sortNodes = (nodes: Node[]) => {
	if (nodes.every((node) => typeof node.month === 'string')) {
		if (nodes.every((node) => typeof node.year === 'string')) {
			return sortNodesByMonthAndYear(nodes as (NodeSortableByMonth & NodeSortableByYear)[]);
		}

		return sortNodesByMonth(nodes as NodeSortableByMonth[]);
	}

	if (nodes.every((node) => typeof node.dayOfWeek === 'string')) {
		if (nodes.every((node) => typeof node.year === 'string')) {
			return sortNodesByDayOfWeekAndYear(nodes as (NodeSortableByDayOfWeek & NodeSortableByYear)[]);
		}

		return sortNodesByDayOfWeek(nodes as NodeSortableByDayOfWeek[]);
	}

	// The provided nodes didn't match any of our defined sorting functions.
	return nodes;
};

/**
 * Sorts an array of nodes by month.
 *
 * @param nodes Nodes to be sorted.
 * @returns Sorted nodes.
 */
export const sortNodesByMonth = (nodes: NodeSortableByMonth[]) => {
	nodes.sort((a, b) => MONTHS.indexOf(a.month) - MONTHS.indexOf(b.month));

	return nodes;
};

/**
 * Sorts an array of nodes by month and year.
 *
 * @param nodes Nodes to be sorted.
 * @returns Sorted nodes.
 */
export const sortNodesByMonthAndYear = (nodes: (NodeSortableByMonth & NodeSortableByYear)[]) => {
	nodes.sort((a, b) => parseInt(a.year) - parseInt(b.year) || MONTHS.indexOf(a.month) - MONTHS.indexOf(b.month));

	return nodes;
};

/**
 * Sorts an array of nodes by day of the week.
 *
 * @param nodes Nodes to be sorted.
 * @returns Sorted nodes.
 */
export const sortNodesByDayOfWeek = (nodes: NodeSortableByDayOfWeek[]) => {
	nodes.sort((a, b) => DAYS_OF_WEEK_LIST.indexOf(a.dayOfWeek) - DAYS_OF_WEEK_LIST.indexOf(b.dayOfWeek));

	return nodes;
};

/**
 * Sorts an array of nodes by day of the week and by year.
 *
 * @param nodes Nodes to be sorted.
 * @returns Sorted nodes.
 */
export const sortNodesByDayOfWeekAndYear = (nodes: (NodeSortableByDayOfWeek & NodeSortableByYear)[]) => {
	nodes.sort(
		(a, b) =>
			parseInt(a.year) - parseInt(b.year) ||
			DAYS_OF_WEEK_LIST.indexOf(a.dayOfWeek) - DAYS_OF_WEEK_LIST.indexOf(b.dayOfWeek)
	);

	return nodes;
};
