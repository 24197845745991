import { useRef, useState } from 'react';

import { ExistingRoomsTable, NewRoomTable } from 'pages';
import { getQueryError, handleApiResponse, ShortOption, useAppSelector } from 'store';
import { LogoOverlay, Select } from 'components';
import { useFilters, useToast } from 'context';
import { useGetReconciliationRoomsQuery, useSetRoomMappingMutation } from 'store/services/MappingService';

export interface NewEntityData {
	imported_as: ShortOption;
	date_found: Date;
	sim_level: {
		percentage: number | undefined;
		closest_option: string | undefined;
	};
}

export interface NewRoomData {
	imported_as: ShortOption;
	date_found: Date;
}

interface MappingInputProps {
	allOptions: ShortOption[];
	save: (selection: ShortOption) => void;
	create?: () => void;
	createText: string | undefined;
}
export const MappingInput = ({ allOptions, save, create, createText }: MappingInputProps) => {
	const [selectedOption, setSelectOption] = useState<ShortOption | undefined>(undefined);
	const ref = useRef<null | HTMLDivElement>(null);
	const handleClick = () => {
		ref.current?.scrollIntoView({
			behavior: 'smooth',
		});
	};
	return (
		<div className='flex items-baseline pb-2'>
			{' '}
			<div className='w-60' onClick={handleClick} ref={ref}>
				<Select
					label=''
					options={allOptions.map((item) => ({ value: item.id, label: item.name }))}
					onChange={(newSelection) => {
						if (newSelection) {
							setSelectOption({
								id: newSelection.value,
								name: newSelection.label,
							});
						}
					}}
				/>
			</div>
			{selectedOption ? (
				<p
					onClick={() => {
						save(selectedOption);
					}}
					className='text-blue-500 text-p3 cursor-pointer underline pl-5'
				>
					Save
				</p>
			) : (
				<p className='text-p3 cursor-none underline pl-5 opacity-0'>Save</p>
			)}
			{createText && (
				<p
					onClick={() => {
						if (create) {
							create();
						}
					}}
					className='text-blue-500 text-p3 cursor-pointer underline pl-5'
				>
					{createText}
				</p>
			)}
		</div>
	);
};

export function Room() {
	const toast = useToast();
	const { rooms } = useFilters();
	const { selectedSystem, selectedFacility } = useAppSelector((state) => state.userState);
	const { data, isLoading } = useGetReconciliationRoomsQuery({
		facility_id: selectedFacility,
		healthsystem_id: selectedSystem,
	});
	const [setRoomMapping] = useSetRoomMappingMutation();

	const newRooms = data?.rooms.map((room) => ({
		imported_as: { id: room.id, name: room.name },
		date_found: room.date_found,
	}));

	const saveRoomMapping = async (fromRoom: number, toRoom: number) => {
		const response = await setRoomMapping({
			healthsystem_id: selectedSystem,
			facility_id: selectedFacility,
			method: 'save_mapping',
			fromId: fromRoom,
			toId: toRoom,
		});

		handleApiResponse(response, {
			success: (payload) => {
				!payload &&
					toast.createToast({
						title: `Room mapping saved.`,
						variant: 'success',
					});
			},
			error: (payload) => {
				toast.createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
			},
		});
	};

	return (
		<div className='pt-4'>
			{isLoading && <LogoOverlay backgroundColor='white' />}
			<NewRoomTable
				data={newRooms ?? []}
				saveRoomAs={(fromRoom, toRoom) => {
					saveRoomMapping(fromRoom, toRoom);
				}}
				roomOptions={rooms.all}
			/>
			<div className='pt-8'>
				<ExistingRoomsTable
					aliasesData={data?.aliasesRooms ?? []}
					save={saveRoomMapping}
					roomOptions={data?.allRooms ?? rooms.all}
				/>
			</div>
		</div>
	);
}

export default Room;
