import { Parser } from 'json2csv';

import { Button } from 'components';

export interface ExportButtonProps {
	/** The filename for the file to be downloaded by the user. */
	filename?: string;

	/** List-like structure of flattened data thats easy to parse to a CSV. */
	contents: Array<unknown>;

	sizeX?: string;
	sizeY?: string;
	variant?: string;
	children?: string;
	no_button?: boolean;
}

export function ExportButton({ no_button, children, contents = [], filename = 'export.csv' }: ExportButtonProps) {
	if (contents.length === 0) return null;

	const downloadFileContents = () => {
		const parser = new Parser();
		const blob = new Blob([parser.parse(contents)], { type: 'text/csv;charset=utf-8;' });
		const link = document.createElement('a');
		link.style.visibility = 'hidden';
		const url = URL.createObjectURL(blob);
		link.setAttribute('href', url);
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<>
			{!no_button && (
				<Button onClick={downloadFileContents} sizeX='md' sizeY='md' variant='primary'>
					{children ? children : 'Export'}
				</Button>
			)}
			{no_button && <p onClick={downloadFileContents}>{children ? children : 'Export'}</p>}
		</>
	);
}

export function createCSV(contents: any[], filename = 'export.csv') {
	const parser = new Parser();
	const blob = new Blob([parser.parse(contents)], { type: 'text/csv;charset=utf-8;' });
	const link = document.createElement('a');

	link.style.visibility = 'hidden';

	const url = URL.createObjectURL(blob);

	link.setAttribute('href', url);
	link.setAttribute('download', filename);

	document.body.appendChild(link);

	link.click();

	document.body.removeChild(link);
}
