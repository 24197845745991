import { ApiEndpoints } from 'models/api';
import { ShortOption } from 'store';
import { standardApi } from 'store/api';

export interface BlockUtilizationObject {
	groupValue: number;
	values: {
		x: string;
		y: number;
	}[];
}
export interface BlockUtilizationResponse {
	data: BlockUtilizationObject[];
	block_utilization_target: number;
}
export interface BlockUtilizationRequest {
	facility_id: number | null;
	healthsystem_id: number | null;
	sortby: string;
	groupby: string;
	viewby: string;
	filters: {
		block_names: ShortOption[];
		days_of_week: string[];
		start_date: string;
		utilization_type: string;
		end_date: string;
		block_timeslot: ('Full Day' | 'Morning' | 'Afternoon')[];
		turnover_threshold: number;
		abandoned_days_toggle: string;
	};
}
/**
 * Provides endpoints for retrieving and setting block settings
 */
export const blockUtilizationApi = standardApi.injectEndpoints({
	endpoints: (build) => ({
		getBlockUtilization: build.query<BlockUtilizationResponse, BlockUtilizationRequest>({
			query: (body) => ({
				url: ApiEndpoints.GET_BLOCK_UTILZATION_OVERVIEW,
				method: 'POST',
				body,
			}),
		}),
	}),
});
export const { useGetBlockUtilizationQuery } = blockUtilizationApi;
export const {
	endpoints: { getBlockUtilization },
} = blockUtilizationApi;
