import classNames from 'classnames';
import { useRef, useState } from 'react';
import { format } from 'date-fns';

import {
	getQueryError,
	handleApiResponse,
	ShortOption,
	useDeleteBlockPatternMutation,
	useEndBlockPatternMutation,
	useGetBlockPatternMutation,
	useGetBlockPatternPreviewQuery,
	useGetBlockServiceLineQuery,
	useSystem,
} from 'store';
import { useFilters, useToast } from 'context';
import { Button, Datepicker, Select, ToggleGroup } from 'components';
import { UserRole } from 'models';

import { BlockPatternDetailsPOST, dowConst, Step1CacheProps } from './BlockPatternFlow';
import { BlockPreviewHeader } from '../BlockScorecard';
import { increaseDateByYear } from 'utils';

function BlockPatternFlowChoice(props: {
	setFlowMode: (mode: 'Add' | 'Edit' | 'Delete') => void;
	closeModule: () => void;
	wipeCache: () => void;
	submit: (data: Step1CacheProps) => void;
	setSelectedBlockPattern: (data: BlockPatternDetailsPOST) => void;
	facility_id: number | null;
	cachedData?: {
		mode: 'Add' | 'Edit' | 'Delete' | 'End';
		block_option: ShortOption | null;
		dow_selected: number | null;
		dow_selected_index?: number | null;
	};
	healthsystem_id: number | null;
}) {
	const [flowMode, setFlowMode] = useState<'Add' | 'Edit' | 'Delete' | 'End'>(
		props.cachedData ? props.cachedData.mode : 'Add'
	);
	const [showPastPatterns, setShowPastPatterns] = useState('active');
	const [gettingPattern, setGettingPattern] = useState<boolean>(false);
	const { createToast } = useToast();
	const { data: facility_data } = useSystem();
	const isAdmin = facility_data?.user.role === UserRole.admin;

	const [blockSelected, setBlock] = useState<ShortOption | null>(
		props.cachedData ? props.cachedData.block_option : null
	);
	const [dowSelected, setDowSelected] = useState<number | null>(props.cachedData ? props.cachedData.dow_selected : null);
	const [dowSelectedIndex, setDowSelectedIndex] = useState<number | null>(
		props.cachedData?.dow_selected_index !== undefined ? props.cachedData?.dow_selected_index : null
	);
	const [getBlockPattern] = useGetBlockPatternMutation();
	const [deleteBlockPattern] = useDeleteBlockPatternMutation();
	const [endBlockPattern] = useEndBlockPatternMutation();
	const [selectedPattern, setSelectedPattern] = useState<number | null>(null); // stores block pattern id

	// Filters
	const { blockName, date } = useFilters();
	const { data: blockPatternPreview, isFetching } = useGetBlockPatternPreviewQuery(
		{
			selected_block: blockSelected, // contains block_id
			facility_id: props.facility_id,
			healthsystem_id: props.healthsystem_id,
			show_previous_only: showPastPatterns === 'expired',
		},
		{
			skip: !blockSelected,
		}
	);

	// options for add pattern toggle
	const addablePatternDoW = [
		{ label: 'Sunday', value: '1', disabled: false },
		{ label: 'Monday', value: '2', disabled: false },
		{ label: 'Tuesday', value: '3', disabled: false },
		{ label: 'Wednesday', value: '4', disabled: false },
		{ label: 'Thursday', value: '5', disabled: false },
		{ label: 'Friday', value: '6', disabled: false },
		{ label: 'Saturday', value: '7', disabled: false },
	];
	const existingBlockPatterns = blockPatternPreview?.data ?? [];

	// get service line
	const { data: serviceline_data } = useGetBlockServiceLineQuery(
		{
			healthsystem_id: props.healthsystem_id,
			selected_block: blockSelected ?? undefined,
		},
		{
			skip: !blockSelected,
		}
	);
	const [endDateLimit, setEndDateLimit] = useState<Date>(date.selected);
	const getCurrentBlockPattern = async (block_pattern_id: number) => {
		setGettingPattern(true);
		const response = await getBlockPattern({
			block_pattern_id: block_pattern_id,
		});

		handleApiResponse(response, {
			success: (payload) => {
				props.setSelectedBlockPattern(payload);
				setGettingPattern(false);
				setEndDateLimit(new Date(payload.effective_date));
			},
			error: (payload) => {
				setGettingPattern(false);
			},
		});
	};
	const [endDate, setEndDate] = useState(new Date());
	const endSelectedBlockPattern = async () => {
		setGettingPattern(true);

		const response = await endBlockPattern({
			block_pattern_id: selectedPattern,
			effective_date: format(endDate, 'M/d/yyyy'),
		});

		handleApiResponse(response, {
			success: (payload) => {
				createToast({
					title: 'Block Pattern Terminated',
					variant: 'success',
				});

				// last thing to execute
				setTimeout(() => {
					props.closeModule();
				}, 0);
			},
			error: (payload) => {
				createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
			},
		});
	};

	const deleteSelectedBlockPattern = async () => {
		setGettingPattern(true);

		const response = await deleteBlockPattern({
			block_pattern_id: selectedPattern,
		});

		handleApiResponse(response, {
			success: (payload) => {
				createToast({
					title: 'Block Pattern Deleted',
					variant: 'success',
				});

				// last thing to execute
				setTimeout(() => {
					props.closeModule();
				}, 0);
			},
			error: (payload) => {
				createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
			},
		});
	};

	return (
		<>
			<div className='flex my-8 items-center'>
				<div className='w-96 pr-12'>
					<p className='font-bold mb-2 uppercase text-p2'>Action</p>
					<p className='text-gray-400 italic text-p3'>
						Use this option to determine which action you would like to take to edit the block schedule.
					</p>
				</div>
				<div className='min-w-[550px]'>
					<ToggleGroup
						label='Add or Edit Block'
						hideLabel
						defaultValue={flowMode}
						options={[
							{ label: 'Add Block Pattern', value: 'Add' },
							{ label: 'Edit Block Pattern', value: 'Edit' },
							{ label: 'Delete Block Pattern', value: 'Delete' },
							{ label: 'End Block Pattern', value: 'End' },
						]}
						onValueChange={(e: 'Add' | 'Edit' | 'Delete') => {
							setFlowMode(e);
							setDowSelected(null);
							setBlock(null);
							setDowSelectedIndex(null);
							props.wipeCache();
							props.setFlowMode(e);
						}}
					/>
				</div>
			</div>
			<div className='flex items-center mb-4 mt-6'>
				<Select
					label={`Block Name`}
					options={blockName.all.map(({ id, name }) => ({ value: id, label: name }))}
					onChange={(selection) => {
						if (!selection) return;
						const selectedBlockName = { id: selection.value, name: selection.label };
						setDowSelected(null);
						setBlock(selectedBlockName);
					}}
					sizeX='lg'
					sizeY='sm'
					value={blockSelected ? { value: blockSelected.id, label: blockSelected.name } : null}
					isWorking={blockName.isFetching || isFetching}
				/>
				<div className='w-2/3 ml-7'>
					{flowMode === 'Add' &&
						blockSelected &&
						!isFetching &&
						(addablePatternDoW.length > 0 ? (
							<ToggleGroup
								label='Day of Week of block pattern'
								defaultValue={`${dowSelected}` ?? undefined}
								options={addablePatternDoW}
								onValueChange={(e: string) => {
									setDowSelected(parseInt(e));
								}}
							/>
						) : (
							<p className='mt-7 text-gray-300 text-i3 w-3/4'>
								Patterns already exist for all days of week. Edit block patterns by clicking the Edit Block Pattern button.
							</p>
						))}
				</div>
			</div>
			<div className='flex my-6 items-center'>
				{(flowMode === 'Edit' || flowMode === 'Delete' || flowMode === 'End') && blockSelected && (
					<>
						<div className='w-96 pr-12'>
							<p className='font-bold mb-2 uppercase text-p2 whitespace-nowrap'>{`Select ${
								showPastPatterns === 'expired' ? 'Expired' : 'Existing'
							} Block Pattern`}</p>
							<p className='text-gray-400 italic text-p3'>
								These are the existing block patterns with this block name. You cannot add a block day on a day where a block
								allocation already exists.
							</p>
						</div>
						<div className='overflow-x-auto mr-4'>
							{!isFetching &&
								(existingBlockPatterns.length > 0 ? (
									<BlockPreviewHeader
										selectMode={true}
										onSelect={(day, i, block_pattern_id, start_date) => {
											setDowSelectedIndex(i);
											setDowSelected(dowConst.indexOf(day) + 1);
											getCurrentBlockPattern(block_pattern_id);
											setSelectedPattern(block_pattern_id);
											setEndDate(increaseDateByYear(new Date(start_date), 1));
										}}
										data={existingBlockPatterns}
										defaultSelected={dowSelectedIndex !== null ? dowSelectedIndex : undefined}
									/>
								) : (
									<p className='text-gray-300 text-i3 w-3/4'>
										No block patterns exist for this block. Add block patterns by clicking the Add Block Pattern button.
									</p>
								))}
							{isFetching && <div className='animate-pulse bg-gray-50 rounded-md h-[117px] w-96'></div>}
						</div>
					</>
				)}
			</div>
			{isAdmin && flowMode === 'Edit' && (
				<div>
					<ToggleGroup
						label='Type of Block Pattern'
						className='w-1/6'
						options={[
							{ label: 'Active', value: 'active' },
							{ label: 'Expired', value: 'expired' },
						]}
						value={showPastPatterns}
						onValueChange={(option) => {
							setShowPastPatterns(option);
						}}
					/>
				</div>
			)}

			<div className='flex my-6 items-center'>
				{flowMode === 'End' &&
					blockSelected &&
					!isFetching &&
					(addablePatternDoW.length > 0 ? (
						<div className='flex flex-col text-left'>
							<p className='text-p3 text-gray-700 tracking-wider uppercase mb-2 font-secondary'>Termination Date</p>
							<div className={classNames('min-w-[250px] z-[300]')}>
								<Datepicker
									type='date'
									popoverContentProps={{ side: 'left' }}
									onApply={(newDate) => {
										setEndDate(newDate);
									}}
									selected={endDate}
									customLimit={{ minLimit: endDateLimit, maxLimit: false }}
								>
									<div
										className={classNames(
											'flex justify-center border rounded-sm border-gray-300 p-2 text-p3 w-48',
											!selectedPattern ? 'opacity-25 !cursor-not-allowed' : '!cursor-pointer'
										)}
										onClick={(e) => !selectedPattern && e.preventDefault()}
									>
										<div className='rounded text-white bg-blue-500 py-1 px-6'>{format(endDate, 'M/d/yyyy')}</div>
									</div>
								</Datepicker>
							</div>
						</div>
					) : (
						<p className='mt-7 text-gray-300 text-i3 w-3/4'>
							Patterns already exist for all days of week. Edit block patterns by clicking the Edit Block Pattern button.
						</p>
					))}
			</div>
			<div className='absolute bottom-8 right-11'>
				<div className='w-full flex justify-end mt-20'>
					<Button
						sizeX='md'
						sizeY='sm'
						variant='secondary'
						className='mr-4'
						onClick={() => {
							props.closeModule();
						}}
					>
						Cancel
					</Button>
					<Button
						sizeX='md'
						sizeY='sm'
						disabled={
							!(blockSelected && dowSelected) || isFetching || ((flowMode === 'Edit' || flowMode === 'End') && gettingPattern)
						}
						isWorking={(flowMode === 'Edit' || flowMode === 'End') && gettingPattern}
						onClick={() => {
							if (flowMode !== 'End' && flowMode !== 'Delete') {
								props.submit({
									block_option: blockSelected,
									dow_selected: dowSelected,
									dow_selected_index: dowSelectedIndex,
									patterns: blockPatternPreview?.data ?? [],
									flow: flowMode,
									service_line_id: serviceline_data?.serviceline_id,
								});
							} else {
								if (flowMode === 'End') {
									// we do this so all the states are settled before sumbit
									setTimeout(() => {
										endSelectedBlockPattern();
									}, 0);
								} else {
									// we do this so all the states are settled before sumbit
									setTimeout(() => {
										deleteSelectedBlockPattern();
									}, 0);
								}
							}
						}}
					>
						{flowMode !== 'End' && flowMode !== 'Delete' ? 'Next' : 'Submit'}
					</Button>
				</div>
			</div>
		</>
	);
}

export default BlockPatternFlowChoice;
