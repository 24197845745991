import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';

import { AppProviders, useAuth } from 'context';
import { LoadingScreen, Login, LoginCallback, Register, RequestResetPassword, ResetPassword, Terms } from 'pages';
import { Routes as AppRoutes } from 'models/navigation';
import { LogoOverlay, ToastContainer } from 'components';

import type { AnyAuthContext } from 'context';

const AuthenticatedApp = lazy(() => import('./AuthenticatedApp'));

function PreauthenticatedApp() {
	const { status } = useAuth<AnyAuthContext>();
	const location = useLocation();
	const appInsights = useAppInsightsContext();
	const trackPageChange = useTrackEvent(appInsights, 'Page View', { location: location.pathname });

	useEffect(() => {
		trackPageChange({ location: location.pathname });
	}, [location.pathname, trackPageChange]);

	// Select the appropriate component to show at the root of the app based on
	// the current authentication status.
	let basePathComponent: React.ReactNode;

	// Used to hide header for printing purposes
	const [searchParams] = useSearchParams();
	const printable = searchParams.get('printable') === 'true' ?? null;

	if (status === 'initialized') {
		basePathComponent = !printable ? (
			<LoadingScreen message='Loading...' />
		) : (
			<div className='block h-screen w-screen z-50 bg-white'>
				<LogoOverlay backgroundColor='white' printing={printable} />
			</div>
		);
	} else if (status === 'authenticating') {
		basePathComponent = <LoadingScreen message='Authenticating user...' />;
	} else if (status === 'authenticated') {
		basePathComponent = <AuthenticatedApp />;
	} else {
		basePathComponent = <Login />;
	}

	return (
		<>
			<Suspense fallback={<LoadingScreen message='Loading...' />}>
				<Routes>
					<Route path='*' element={basePathComponent} />
					<Route path={AppRoutes.REQUEST_RESET_PASSWORD} element={<RequestResetPassword />} />
					<Route path={AppRoutes.RESET_PASSWORD} element={<ResetPassword />} />
					<Route path={AppRoutes.REGISTER} element={<Register />} />
					<Route path={AppRoutes.TERMS} element={<Terms />} />
					<Route
						path='/login/callback'
						element={
							<LoginCallback>
								<LoadingScreen message='Logging in...' />
							</LoginCallback>
						}
					/>
				</Routes>
			</Suspense>
		</>
	);
}

function App() {
	return (
		<AppProviders>
			<PreauthenticatedApp />
			<ToastContainer />
		</AppProviders>
	);
}

export default App;
