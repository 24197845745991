import { Children, isValidElement } from 'react';

/**
 * Categorizes an array of React nodes into a namespaced object according to
 * each node's display name. Helpful for organizing subcomponents that are used
 * as slots in a top-level component.
 *
 * @param childNodes The React nodes to categorize.
 * @param validDisplayNames A list of valid display names to categorize by. Nodes with names not in this list will be ignored.
 */
export function categorizeNodes(childNodes: React.ReactNode, validDisplayNames: string[]) {
	return Children.toArray(childNodes).reduce((carry: Record<string, typeof childNodes[]>, child) => {
		// Only categorize valid, non-string nodes.
		if (!isValidElement(child) || typeof child.type === 'string') {
			return carry;
		}

		// Only categorize nodes with a valid display name.
		if (!validDisplayNames.includes(child.type.name)) {
			return carry;
		}

		// Ensure a key exists for this node.
		if (!carry[child.type.name]) {
			carry[child.type.name] = [];
		}

		carry[child.type.name].push(child);

		return carry;
	}, {});
}
