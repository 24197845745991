// import { Arrow, Content, Root, Trigger } from '@radix-ui/react-tooltip';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import classNames from 'classnames';

import type {
	TooltipContentProps as RadixTooltipContentProps,
	TooltipProps as RadixTooltipRootProps,
} from '@radix-ui/react-tooltip';

export interface TooltipProps extends RadixTooltipRootProps {
	/** The text or markup to display within this tooltip. */
	content: React.ReactNode;

	/** A prop object to pass to this tooltip's undelying Radix `<Content>` component. */
	contentProps?: RadixTooltipContentProps;

	/** The name of a Material Symbol to use as an icon for this tooltip. */
	icon?: string;

	/** Whether this tooltip's icon should use a filled version, if available. */
	fillIcon?: boolean;

	/** The HTML element to use for the tooltip's trigger. This is a `button` by default, but can be changed to allow tooltips to work in different layout scenarios. */
	triggerType?: 'button' | 'span' | 'div';

	/** Whether to remove the default "help" cursor when hovering over this tooltip. */
	disableHelpCursor?: boolean;

	/** Force tip to appear below tooltip. */
	forceBelow?: boolean;
}

/**
 * Applies a small, informational popup to an element that provides extra
 * context to the user when they hover over it or focus it via the keyboard.
 */
export function Tooltip({
	content,
	contentProps,
	icon,
	fillIcon,
	triggerType = 'button',
	disableHelpCursor = false,
	children,
	...props
}: TooltipProps) {
	let triggerChild = children;

	if (triggerType === 'span') {
		triggerChild = <span className='inline-block'>{children}</span>;
	} else if (triggerType === 'div') {
		triggerChild = <div>{children}</div>;
	}

	return (
		<RadixTooltip.Root {...props}>
			<RadixTooltip.Trigger
				asChild={triggerType !== 'button'}
				className={classNames({ 'cursor-help': !disableHelpCursor })}
			>
				{triggerChild}
			</RadixTooltip.Trigger>

			<RadixTooltip.Content
				{...contentProps}
				className={classNames(
					'bg-blue-500 text-white px-4 py-3 rounded text-p1 max-w-md z-tooltip',
					'data-above:animate-displayTooltipAbove data-below:animate-displayTooltipBelow',
					contentProps?.className
				)}
			>
				<div className='flex items-center gap-2'>
					{icon && <span className={fillIcon ? 'material-symbol-fill' : 'material-symbol'}>{icon}</span>}
					{content}
				</div>
				<RadixTooltip.Arrow className='fill-blue-500' height={12} width={24} />
			</RadixTooltip.Content>
		</RadixTooltip.Root>
	);
}

export default Tooltip;
