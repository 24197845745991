import { createContext, useContext, useState } from 'react';

import { Alert } from 'components';

import type { AlertProps } from 'components';
import type { ProviderProps } from 'react';

interface AlertContextValue {
	/** The currently active alert. */
	currentAlert?: ReturnType<typeof Alert>;

	/** Generates an alert and sets it as currently active. */
	createAlert: (props: AlertProps) => void;

	/** Removes the currently active alert. */
	dismissAlert: () => void;

	/** Generates an alert and returns a Promise that will resolve to the string value associated with the user's response. */
	getAlertResponse: (props: Omit<AlertProps, 'onResponse'>) => Promise<string>;
}

type AlertProviderProps = Omit<ProviderProps<AlertContextValue>, 'value'>;

const AlertContext = createContext<AlertContextValue>({} as AlertContextValue);

export function AlertProvider({ children }: AlertProviderProps) {
	const [currentAlert, setCurrentAlert] = useState<AlertContextValue['currentAlert']>();

	/**
	 * Generates an alert and sets it as currently active.
	 *
	 * @param props A prop object to pass to the Alert component.
	 */
	const createAlert = (props: AlertProps) => {
		const newAlert = <Alert defaultOpen onOpenChange={(isOpen) => !isOpen && dismissAlert()} {...props} />;
		setCurrentAlert(newAlert);
	};

	/**
	 * Removes the currently active alert.
	 */
	const dismissAlert = () => {
		setCurrentAlert(undefined);
	};

	/**
	 * Generates an alert and returns a Promise that will resolve to the string
	 * value associated with the user's response.
	 *
	 * @param props A prop object to pass to the Alert component, omitting the `onResponse` prop which is replaced by a
	 * 						  simple Promise resolver.
	 * @returns A Promise that will resolve to the string value of the user-selected response.
	 */
	const getAlertResponse = (props: Omit<AlertProps, 'onResponse'>) => {
		return new Promise<string>((resolve) => createAlert({ onResponse: resolve, ...props }));
	};

	return (
		<AlertContext.Provider value={{ currentAlert, createAlert, dismissAlert, getAlertResponse }}>
			{children}
			{currentAlert}
		</AlertContext.Provider>
	);
}

export const useAlert = () => useContext(AlertContext);

export default AlertProvider;
