import { useEffect, useState } from 'react';

import { Facility, FacilityLicense, UserRole } from 'models';
import { ArtificialFacilityConfiguration, FacilityAnesthesiaAccordionItem } from 'pages/Settings/Tabs';
import { checkLicenseBits, intersects, userIs } from 'utils';
import { useAppSelector, useGetSystemsQuery, useSystem } from 'store';
import { Accordion, WorkingIndicator } from 'components';
import { Routes as NavigationRoutes } from 'models/navigation';

export function AnesthesiaSettings() {
	// automatically gets current "selected" system information
	const { data, isFetching } = useSystem();
	const { selectedSystem } = useAppSelector((state) => state.userState);
	const { data: userData } = useGetSystemsQuery({
		healthsystem_id: selectedSystem,
	});
	const isAdministrator = userIs([UserRole.admin, UserRole.healthsystem_admin], data?.user);
	const [rebuildingArtificial, setRebuildingArtificial] = useState<boolean | number>(false);

	// This is required to unmount the accordion when fetching new artificial facilities
	useEffect(() => {
		if (!isFetching) {
			setRebuildingArtificial(false);
		}
	}, [isFetching]);

	const earlyAccessValidation = intersects(userData?.user.page_list ?? [], [
		NavigationRoutes.ANESTHESIA_STAFFING,
		NavigationRoutes.PROVIDER_PATTERNS,
	]);

	const anesthesiaFacilitiesCount =
		data?.facilities?.filter((fac) => checkLicenseBits(fac.license, FacilityLicense.anesthesia)).length ||
		earlyAccessValidation
			? 1
			: 0;

	return (
		<div>
			{anesthesiaFacilitiesCount === 0 && <p>No facilites have an anesthesia license</p>}
			{isFetching && (
				<div className='w-full flex justify-center my-4'>
					<div>
						<WorkingIndicator />
					</div>
				</div>
			)}
			{!isFetching &&
				data?.facilities
					?.filter((fac) => checkLicenseBits(fac.license, FacilityLicense.anesthesia) || earlyAccessValidation)
					.map((facility: Facility, index: number) => {
						if (rebuildingArtificial !== false) {
							if (index !== rebuildingArtificial) {
								return (
									<FacilityAnesthesiaAccordionItem
										key={index}
										facility={facility}
										readonly={!isAdministrator}
										fetching={isFetching}
										rebuildingArtificial={() => setRebuildingArtificial(index)}
									/>
								);
							} else {
								return (
									<Accordion type='multiple' itemStyle='yellow' className='mb-2'>
										<Accordion.Item value={`[Rebuilding] ${facility.name}`}></Accordion.Item>
									</Accordion>
								);
							}
						} else {
							return (
								<FacilityAnesthesiaAccordionItem
									key={index}
									facility={facility}
									readonly={!isAdministrator}
									fetching={isFetching}
									rebuildingArtificial={() => setRebuildingArtificial(index)}
								/>
							);
						}
					})}

			{!isFetching && anesthesiaFacilitiesCount > 1 && (
				<ArtificialFacilityConfiguration
					facilities={data?.facilities?.filter((fac) => checkLicenseBits(fac.license, FacilityLicense.anesthesia)) ?? []}
				></ArtificialFacilityConfiguration>
			)}
		</div>
	);
}

export default AnesthesiaSettings;
