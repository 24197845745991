import * as Popover from '@radix-ui/react-popover';
import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import merge from 'lodash.merge';

import { Button, Select } from 'components';
import { clone, getFirstDateOfWeek, increaseDateByYear, isSameDay } from 'utils';

import type { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

export interface DatepickerProps {
	/** The content that makes the trigger for the datepicker popover. */
	children?: JSX.Element;

	/** The option to hide the title altogether. */
	hideTitle?: boolean;

	/** The properties to be passed to the Popover.Content component inside this component. */
	popoverContentProps?: Partial<Popover.PopoverContentProps>;

	/** The pre-selected date/dates for the pickers to have. */
	selected?: Date | null;

	/** The pre-selected date/dates for the range-pickers to have. */
	selectedRange?: [Date | null, Date | null];

	/** The max Surgery Date for the shortcuts. */
	defaultEndDate?: Date | null;

	/** The min Surgery Date for the shortcuts. */
	defaultStartDate?: Date | null;

	/** The year limit for the range-pickers to have (in number). */
	dateRangeLimit?: number;

	/** The option to include the shortcuts in a sidebar for the rangepicker (Last 7 Days, etc.) */
	shortcuts?: boolean;

	/** The option to show the currently selected date below the title (can be shown even if title is hidden). */
	showSelectedDate?: boolean;

	/** A unique title to apply to the header of the datepicker component, otherwise defaults to generics like "Date Selector", "Date Range Selector", etc. */
	title?: string;

	/** The type of the datepicker, the only required property */
	type: 'date' | 'month' | 'quarter' | 'range' | 'week' | 'year';

	/** The handler function to invoke when clicking "apply" and using a date-picker */
	onApply?: (d: Date) => void;

	/** The handler function to invoke when clicking "apply" and using any range-picker */
	onApplyRange?: (d: Date, d2: Date) => void;

	/** The handler function to invoke when selecting a new single date. */
	onChooseDate?: (d: Date) => void;

	/** The handler function to invoke when selecting a month in the month picker. */
	onChooseMonth?: (monthYear: { m: number; y: number }) => void;

	/** The handler function to invoke when selecting a quarter in the quarter picker. */
	onChooseQuarter?: (quarterYear: { q: number; y: number }) => void;

	/** The handler function to invoke when selecting a range of dates in the range picker. */
	onChooseRange?: (start: Date, end: Date) => void;

	/** The prop which allow daterange picker to set min-max limit. */
	customLimit?: { minLimit?: Date | false; maxLimit?: Date | false };
}

export interface ShortcutWrapperProps {
	children: JSX.Element;
	condition: boolean;
}

const defaultPopoverContentProps: DatepickerProps['popoverContentProps'] = {
	side: 'bottom',
	sideOffset: 5,
	align: 'start',
};

export function Datepicker({
	children,
	hideTitle,
	popoverContentProps = defaultPopoverContentProps,
	selected = null,
	selectedRange = [null, null],
	shortcuts = false,
	showSelectedDate,
	title,
	defaultEndDate,
	defaultStartDate,
	dateRangeLimit = 1,
	customLimit = { minLimit: false, maxLimit: false },
	type,
	onApply,
	onApplyRange,
	onChooseDate,
	onChooseMonth,
	onChooseQuarter,
	onChooseRange,
}: DatepickerProps) {
	// pre-determined date-ranges for all shortcuts, relative to "max surgery date"
	const today = new Date();
	const maxSurgeryDate = defaultEndDate ?? today;
	const minSurgeryDate = defaultStartDate ?? today;
	const tomorrow = clone(maxSurgeryDate);
	tomorrow.setDate(maxSurgeryDate.getDate() + 1);
	const lastSunday = getFirstDateOfWeek(maxSurgeryDate);
	const previousSunday = clone(lastSunday);
	previousSunday.setDate(maxSurgeryDate.getDate() - 7);
	const lastSaturday = clone(previousSunday);
	lastSaturday.setDate(previousSunday.getDate() + 6);
	const sevenDaysAgo = clone(maxSurgeryDate);
	sevenDaysAgo.setDate(maxSurgeryDate.getDate() - 6);
	const pastWeekShortcuts = [sevenDaysAgo, maxSurgeryDate];
	const thirtyDaysAgo = new Date(clone(maxSurgeryDate));
	thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 29);
	const currentQuarter = Math.floor(maxSurgeryDate.getMonth() / 3);
	const currentQuarterStartDate = new Date(maxSurgeryDate.getFullYear(), currentQuarter * 3, 1);
	const halfYearAgoStartDate = clone(maxSurgeryDate);
	halfYearAgoStartDate.setMonth(halfYearAgoStartDate.getMonth() - 6);
	halfYearAgoStartDate.setDate(halfYearAgoStartDate.getDate());
	const fullYearAgoStartDate = clone(maxSurgeryDate);
	fullYearAgoStartDate.setDate(fullYearAgoStartDate.getDate() - 365);

	// merge the developer-supplied props with the default props we apply to the popover
	popoverContentProps = merge(defaultPopoverContentProps, popoverContentProps);

	const originalSelectedDate = selected;
	const originalSelectedDates = selectedRange;

	// some global state that all pickers rely on or use
	const [touched, setTouched] = useState(false);

	// state for keeping track of what year is selected in the year dropdown in all pickers
	const [selectedYear, setSelectedYear] = useState(
		type === 'date'
			? selected && {
					value: selected.getFullYear(),
					label: `${selected.getFullYear()}`,
			  }
			: selectedRange[0] && {
					value: selectedRange[0].getFullYear(),
					label: `${selectedRange[0].getFullYear()}`,
			  }
	);

	// use the "past 5 years from latest surgery date" for options in all year dropdowns
	const pastFiveYears = [...Array(6).reverse()].map((item, i) => ({
		value: maxSurgeryDate.getFullYear() - i,
		label: `${maxSurgeryDate.getFullYear() - i}`,
	}));

	// state and handler for selecting a single date
	const [selectedSingleDate, setSingleSelectedDate] = useState<Date | null>(selected);
	const onChooseSingleDateHandler = useCallback(
		(d: Date) => {
			setTouched(true);
			setSingleSelectedDate(d);
			if (onChooseDate) {
				onChooseDate(d);
			}
		},
		[onChooseDate, setSingleSelectedDate, setTouched]
	);

	// state and handler for selecting a date range
	const [startDate, setStartDate] = useState<Date | null>(selectedRange[0]);
	const [endDate, setEndDate] = useState<Date | null>(selectedRange[1]);
	const [showApplyBtn, setShowApplyBtn] = useState<boolean>(false);
	const [showInfo, setShowInfo] = useState<boolean>(
		(selectedRange[1] && selectedRange[1] > maxSurgeryDate) || (selectedRange[0] && selectedRange[0] < minSurgeryDate)
			? true
			: false
	);
	//logic for auto select the date-range when using reset filter button
	useEffect(() => {
		selectedRange[0] && setStartDate(selectedRange[0]);
		selectedRange[1] && setEndDate(selectedRange[1]);
		selectedRange[0] &&
			setSelectedYear({
				value: selectedRange[0].getFullYear(),
				label: `${selectedRange[0].getFullYear()}`,
			});

		if (selectedRange[1] && selectedRange[0]) {
			selectedRange[1] > maxSurgeryDate || selectedRange[0] < minSurgeryDate ? setShowInfo(true) : setShowInfo(false);
		}
		// eslint-disable-next-line
	}, [selectedRange]);

	//logic for auto select the date when using reset filter button
	useEffect(() => {
		selected && setSingleSelectedDate(selected);
		selected &&
			setSelectedYear({
				value: selected.getFullYear(),
				label: `${selected.getFullYear()}`,
			});
	}, [selected]);

	const onChooseRangeHandler = useCallback(
		(dates: [Date | null, Date | null]) => {
			setTouched(true);
			const [start, end] = dates;
			setStartDate(start);
			setEndDate(end);
			if (onChooseRange && start && end) {
				onChooseRange(start, end);
			}
			if (dateRangeLimit && start) {
				const increasedDate = increaseDateByYear(start, dateRangeLimit);
				end && end > increasedDate ? setShowApplyBtn(true) : setShowApplyBtn(false);
			}
			start && setShowInfo(false);
			if (start && end) {
				end > maxSurgeryDate || start < minSurgeryDate ? setShowInfo(true) : setShowInfo(false);
			}
		},
		[onChooseRange, setTouched, setStartDate, setEndDate, setShowInfo, dateRangeLimit, maxSurgeryDate, minSurgeryDate]
	);

	// state and handler for selecting a week
	const [selectedWeekStartDate, setWeekStartDate] = useState<Date | null>(selectedRange[0]);
	const [selectedWeekEndDate, setWeekEndDate] = useState<Date | null>(selectedRange[1]);
	const onChooseWeekHandler = useCallback(
		(dates: [Date | null, Date | null]) => {
			setTouched(true);
			const [start] = dates;
			if (start) {
				const weekStart = getFirstDateOfWeek(start);
				const weekEnd = new Date(weekStart.getTime());
				weekEnd.setDate(weekStart.getDate() + 6);
				setWeekStartDate(weekStart);
				setWeekEndDate(weekEnd);
				if (onChooseRange) {
					onChooseRange(weekStart, weekEnd);
				}
			}
		},
		[onChooseRange, setTouched, setWeekStartDate, setWeekEndDate]
	);

	// state and handler for selecting a month
	const [selectedMonth, setMonth] = useState<{ start: Date | null; end: Date | null }>({
		start: selectedRange[0],
		end: selectedRange[1],
	});
	const onChooseMonthHandler = useCallback(
		(d: Date) => {
			setTouched(true);
			setMonth({ start: d, end: new Date(d.getFullYear(), d.getMonth() + 1, 0) });
			if (onChooseMonth) {
				onChooseMonth({
					m: d.getMonth() + 1,
					y: d.getFullYear(),
				});
			}
			if (onChooseRange) {
				onChooseRange(d, new Date(d.getFullYear(), d.getMonth() + 1, 0));
			}
		},
		[onChooseMonth, onChooseRange, setMonth, setTouched]
	);

	// state and handler for selecting a quarter
	const [selectedQuarter, setQuarterRange] = useState<{ start: Date | null; end: Date | null }>({
		start: selectedRange[0],
		end: selectedRange[1],
	});
	const onChooseQuarterHandler = useCallback(
		(d: Date) => {
			setTouched(true);
			setQuarterRange({ start: d, end: new Date(d.getFullYear(), d.getMonth() + 3, 0) });
			if (onChooseQuarter) {
				onChooseQuarter({
					q: Math.floor(d.getMonth() / 3 + 1),
					y: d.getFullYear(),
				});
			}
			if (onChooseRange) {
				onChooseRange(d, new Date(d.getFullYear(), d.getMonth() + 3, 0));
			}
		},
		[onChooseQuarter, onChooseRange, setQuarterRange, setTouched]
	);

	// more common state/functions used by ALL pickers
	const onMonthChange = useCallback(
		(viewing: Date) => {
			setSelectedYear({
				label: `${viewing.getFullYear()}`,
				value: viewing.getFullYear(),
			});
		},
		[setSelectedYear]
	);

	// event handler to bubble back to caller when "apply" button is clicked, confirming user's action
	const applyDateSelection = useCallback(() => {
		if (type === 'date') {
			if (onApply && selectedSingleDate) {
				onApply(selectedSingleDate);
				setSelectedYear({
					label: `${selectedSingleDate.getFullYear()}`,
					value: selectedSingleDate.getFullYear(),
				});
			}
		}
		if (type === 'range') {
			if (onApplyRange && startDate && endDate && !showApplyBtn) {
				onApplyRange(startDate, endDate);
				setSelectedYear({
					label: `${startDate.getFullYear()}`,
					value: startDate.getFullYear(),
				});
			}
		}
	}, [onApply, onApplyRange, selectedSingleDate, startDate, endDate, type, showApplyBtn]);

	// event handler to reset the datepicker to its original state when being opened
	const cancelDateSelection = useCallback(() => {
		if (type === 'date') {
			setSingleSelectedDate(originalSelectedDate);
			originalSelectedDate &&
				setSelectedYear({
					label: `${originalSelectedDate.getFullYear()}`,
					value: originalSelectedDate.getFullYear(),
				});
		}
		if (type === 'range') {
			setShowApplyBtn(false);
			if (originalSelectedDates[1] && originalSelectedDates[0]) {
				originalSelectedDates[1] > maxSurgeryDate || originalSelectedDates[0] < minSurgeryDate
					? setShowInfo(true)
					: setShowInfo(false);
			}
			setStartDate(originalSelectedDates[0]);
			setEndDate(originalSelectedDates[1]);
			originalSelectedDates[0] &&
				setSelectedYear({
					label: `${originalSelectedDates[0].getFullYear()}`,
					value: originalSelectedDates[0].getFullYear(),
				});
		}
	}, [
		originalSelectedDate,
		originalSelectedDates,
		setSingleSelectedDate,
		setStartDate,
		setEndDate,
		type,
		minSurgeryDate,
		maxSurgeryDate,
	]);

	// assortment of states needed for the various types of pickers
	const calendarHeadingClassnames = `selected-date font-secondary block font-semibold px-7 pt-1 pb-3 text-gray-900 relative`;
	const nextMonthClassnames = `month-next text-blue-500 absolute bottom-[5px] right-0 z-10`;
	const prevMonthClassnames = `month-previous text-blue-500 absolute bottom-[5px] left-0 z-10`;
	const prevYearClassnames = `year-previous text-blue-500 absolute bottom-[5px] left-0 z-10`;
	const yearDropdownClassnames = `year-navigation absolute top-[5px] left-0 right-0 flex items-center justify-between`;

	// assortment of states needed for the various types of pickers
	const isRange = type === 'range';
	const isDate = type === 'date';
	const isMonth = type === 'month';
	const isQuarter = type === 'quarter';
	const isWeek = type === 'week';

	const pastMonthShortcuts = [thirtyDaysAgo, maxSurgeryDate];
	const pastQuarterShortcuts = [currentQuarterStartDate, maxSurgeryDate];
	const halfYearShortcuts = [halfYearAgoStartDate, maxSurgeryDate];
	const pastYearShortcuts = [fullYearAgoStartDate, maxSurgeryDate];

	// assortment of states needed for the various types of pickers that depend on the current selected values
	const isWeekSelected = isSameDay(startDate, pastWeekShortcuts[0]) && isSameDay(endDate, pastWeekShortcuts[1]);
	const isMonthSelected = isSameDay(startDate, pastMonthShortcuts[0]) && isSameDay(endDate, pastMonthShortcuts[1]);
	const isQuarterSelected = isSameDay(startDate, pastQuarterShortcuts[0]) && isSameDay(endDate, pastQuarterShortcuts[1]);
	const isHalfYearSelected = isSameDay(startDate, halfYearShortcuts[0]) && isSameDay(endDate, halfYearShortcuts[1]);
	const isYearSelected = isSameDay(startDate, pastYearShortcuts[0]) && isSameDay(endDate, pastYearShortcuts[1]);
	const noShortcutSelected =
		!isWeekSelected && !isMonthSelected && !isQuarterSelected && !isHalfYearSelected && !isYearSelected;

	const shortcutMap = [
		{
			label: 'Past 7 Days',
			active: isWeekSelected,
			selectDate: () => {
				pastWeekShortcuts[1] > maxSurgeryDate || pastWeekShortcuts[0] < minSurgeryDate
					? setShowInfo(true)
					: setShowInfo(false);
				setShowApplyBtn(false);
				setSelectedYear({
					label: `${pastWeekShortcuts[0].getFullYear()}`,
					value: pastWeekShortcuts[0].getFullYear(),
				});
				setStartDate(pastWeekShortcuts[0]);
				setEndDate(pastWeekShortcuts[1]);
			},
		},
		{
			label: 'Past 30 Days',
			active: isMonthSelected,
			selectDate: () => {
				pastMonthShortcuts[1] > maxSurgeryDate || pastMonthShortcuts[0] < minSurgeryDate
					? setShowInfo(true)
					: setShowInfo(false);
				setShowApplyBtn(false);
				setSelectedYear({
					label: `${pastMonthShortcuts[0].getFullYear()}`,
					value: pastMonthShortcuts[0].getFullYear(),
				});
				setStartDate(pastMonthShortcuts[0]);
				setEndDate(pastMonthShortcuts[1]);
			},
		},
		{
			label: 'Quarter to Date',
			active: isQuarterSelected,
			selectDate: () => {
				pastQuarterShortcuts[1] > maxSurgeryDate || pastQuarterShortcuts[0] < minSurgeryDate
					? setShowInfo(true)
					: setShowInfo(false);
				setShowApplyBtn(false);
				setSelectedYear({
					label: `${pastQuarterShortcuts[0].getFullYear()}`,
					value: pastQuarterShortcuts[0].getFullYear(),
				});
				setStartDate(pastQuarterShortcuts[0]);
				setEndDate(pastQuarterShortcuts[1]);
			},
		},
		{
			label: 'Past 6 Months',
			active: isHalfYearSelected,
			selectDate: () => {
				halfYearShortcuts[1] > maxSurgeryDate || halfYearShortcuts[0] < minSurgeryDate
					? setShowInfo(true)
					: setShowInfo(false);
				setShowApplyBtn(false);
				setSelectedYear({
					label: `${halfYearShortcuts[0].getFullYear()}`,
					value: halfYearShortcuts[0].getFullYear(),
				});
				setStartDate(halfYearShortcuts[0]);
				setEndDate(halfYearShortcuts[1]);
			},
		},
		{
			label: 'Past Year',
			active: isYearSelected,
			selectDate: () => {
				pastYearShortcuts[1] > maxSurgeryDate || pastYearShortcuts[0] < minSurgeryDate
					? setShowInfo(true)
					: setShowInfo(false);
				setShowApplyBtn(false);
				setSelectedYear({
					label: `${pastYearShortcuts[0].getFullYear()}`,
					value: pastYearShortcuts[0].getFullYear(),
				});
				setStartDate(pastYearShortcuts[0]);
				setEndDate(pastYearShortcuts[1]);
			},
		},
	];

	const pickerCustomHeader = ({
		monthDate,
		decreaseMonth,
		increaseMonth,
		prevMonthButtonDisabled,
		nextMonthButtonDisabled,
		changeYear,
	}: ReactDatePickerCustomHeaderProps) => (
		<div className='relative pt-12'>
			<div className={yearDropdownClassnames}>
				<Select
					label='Year'
					hideLabel
					sizeX='full'
					sizeY='sm'
					reactSelectProps={{
						onChange: (option) => {
							if (option) {
								setSelectedYear({
									label: option.label,
									value: option.value,
								});
								changeYear(option.value);
							}
						},
					}}
					value={selectedYear}
					options={pastFiveYears}
				/>
			</div>
			<div>
				<button
					aria-label='Previous Month'
					className={classNames(prevMonthClassnames, prevMonthButtonDisabled && 'cursor-not-allowed')}
					onClick={() => decreaseMonth()}
					disabled={prevMonthButtonDisabled}
				>
					<span className={classNames('material-symbol', prevMonthButtonDisabled && 'opacity-50')}>arrow_back_ios</span>
				</button>
				<span className={calendarHeadingClassnames}>
					{monthDate.toLocaleString('en-US', {
						month: 'long',
						year: 'numeric',
					})}
				</span>
				<button
					aria-label='Next Month'
					className={classNames(nextMonthClassnames, nextMonthButtonDisabled && 'cursor-not-allowed')}
					onClick={() => increaseMonth()}
					disabled={nextMonthButtonDisabled}
				>
					<span className={classNames('material-symbol', nextMonthButtonDisabled && 'opacity-50')}>arrow_forward_ios</span>
				</button>
			</div>
		</div>
	);

	const yearCustomHeader = ({
		monthDate,
		decreaseYear,
		increaseYear,
		prevYearButtonDisabled,
		nextYearButtonDisabled,
	}: ReactDatePickerCustomHeaderProps) => (
		<div className='relative'>
			<div>
				<button
					aria-label='Previous Year'
					className={classNames(prevYearClassnames, prevYearButtonDisabled && 'cursor-not-allowed')}
					onClick={() => decreaseYear()}
					disabled={prevYearButtonDisabled}
				>
					<span className={classNames('material-symbol', prevYearButtonDisabled && 'opacity-50')}>arrow_back_ios</span>
				</button>
				<span className={calendarHeadingClassnames}>
					{monthDate.toLocaleString('en-US', {
						year: 'numeric',
					})}
				</span>
				<button
					aria-label='Next Year'
					className={classNames(nextMonthClassnames, nextYearButtonDisabled && 'cursor-not-allowed')}
					onClick={() => increaseYear()}
					disabled={nextYearButtonDisabled}
				>
					<span className={classNames('material-symbol', nextYearButtonDisabled && 'opacity-50')}>arrow_forward_ios</span>
				</button>
			</div>
		</div>
	);

	// store each datepicker in it's own variable
	// const minLimit = new Date(`${maxSurgeryDate.getFullYear() - 5}-Jan`);
	const minLimit =
		customLimit.minLimit instanceof Date ? customLimit.minLimit : new Date(`${maxSurgeryDate.getFullYear() - 5}-Jan`);
	const maxLimit = customLimit.maxLimit instanceof Date ? customLimit.maxLimit : today;

	const datePicker = (
		<DatePicker
			onMonthChange={onMonthChange}
			renderCustomHeader={pickerCustomHeader}
			inline
			selected={selectedSingleDate}
			onChange={onChooseSingleDateHandler}
			minDate={customLimit.minLimit === false ? undefined : minLimit}
			maxDate={customLimit.maxLimit === false ? undefined : maxLimit}
		/>
	);

	const rangePicker = (
		<DatePicker
			onMonthChange={onMonthChange}
			renderCustomHeader={pickerCustomHeader}
			selectsRange
			inline
			selected={startDate}
			startDate={startDate}
			endDate={endDate}
			minDate={minLimit}
			maxDate={defaultEndDate ?? endDate}
			monthsShown={2}
			onChange={onChooseRangeHandler}
		/>
	);

	const weekPicker = (
		<DatePicker
			onMonthChange={onMonthChange}
			renderCustomHeader={pickerCustomHeader}
			selectsRange
			inline
			startDate={selectedWeekStartDate}
			endDate={selectedWeekEndDate}
			onChange={onChooseWeekHandler}
		/>
	);

	const monthPicker = (
		<DatePicker
			onMonthChange={onMonthChange}
			inline
			renderCustomHeader={yearCustomHeader}
			selected={selectedRange[0]}
			onChange={onChooseMonthHandler}
			dateFormat='dd/mm/yyyy'
			showMonthYearPicker
			minDate={minLimit}
			maxDate={today}
		/>
	);

	const quarterPicker = (
		<DatePicker
			onMonthChange={onMonthChange}
			inline
			renderCustomHeader={yearCustomHeader}
			selected={selectedQuarter?.start}
			onChange={onChooseQuarterHandler}
			dateFormat='dd/mm/yyyy'
			showQuarterYearPicker
		/>
	);

	return (
		<Popover.Root>
			<Popover.Anchor>
				<Popover.Trigger asChild>{children}</Popover.Trigger>
			</Popover.Anchor>
			<Popover.Portal>
				<Popover.Content className='z-datepicker' {...popoverContentProps} onInteractOutside={cancelDateSelection}>
					<div
						className={classNames('mt-2 drop-shadow-md rounded bg-offWhite border border-blue-500 overflow-hidden', {
							'max-w-[800px] rangepicker': isRange && shortcuts,
							'max-w-[600px] rangepicker': isRange && !shortcuts,
							'max-w-[310px]': isMonth || isQuarter,
							'max-w-[340px]': isDate,
							'max-w-[355px] weekpicker': isWeek,
						})}
					>
						<div
							className={classNames({
								// why is the width class needed below? Shouldn't the children naturally "fill up" the width of the parent (defined with max-widths above)
								'w-[770px]': isRange && shortcuts,
							})}
						>
							<div className='p-4 pl-8 bg-white border-b border-gray-200'>
								<h5 className='w-full text-h5 font-secondary font-semibold my-0 mb-1'>
									{title ? title : ''}
									{!title && !hideTitle && isRange && `Date Range Selector`}
									{!title && !hideTitle && isDate && `Date Selector`}
									{!title && !hideTitle && isMonth && `Month Selector`}
									{!title && !hideTitle && isQuarter && `Quarter Selector`}
									{!title && !hideTitle && isWeek && `Week Selector`}
								</h5>
								{showSelectedDate &&
									(touched ||
										(startDate && endDate) ||
										selectedSingleDate ||
										(selectedMonth?.start && selectedMonth?.end) ||
										(selectedQuarter?.start && selectedQuarter?.end) ||
										(selectedWeekStartDate && selectedWeekEndDate)) && (
										<span className='block text-p2 uppercase leading font-secondary text-blue-600 animate-slideDownToShow'>
											{isRange && `${getSelectedDateLabel(startDate, endDate)}`}
											{isDate && `${getSelectedDateLabel(selectedSingleDate)}`}
											{isMonth && `${getSelectedDateLabel(selectedMonth?.start, selectedMonth?.end)}`}
											{isQuarter && `${getSelectedDateLabel(selectedQuarter?.start, selectedQuarter?.end)}`}
											{isWeek && `${getSelectedDateLabel(selectedWeekStartDate, selectedWeekEndDate)}`}
										</span>
									)}
							</div>
							<div className={classNames('pt-4 px-8 pb-4', type)}>
								<div className='flex'>
									{shortcuts && (
										<div className='w-1/4 pt-12'>
											<h4 className='selected-date font-secondary block font-semibold px-7 pt-1 text-gray-900 relative text-left pl-0'>
												Quick Select
											</h4>
											<span className='font-secondary text-p3'>Based on latest surgery date.</span>
											<ul className='relative -left-4 font-secondary text-p2 mt-2'>
												{shortcutMap.map((item, indx) => (
													<li key={indx}>
														<button
															className={classNames('hover:bg-blue-50 rounded-lg px-4 py-1 my-1', {
																'text-blue-500 bg-blue-50': item.active,
															})}
															onClick={() => {
																item.selectDate();
															}}
														>
															{item.label}
														</button>
													</li>
												))}
												<li>
													<button
														className={classNames('hover:bg-blue-50 rounded-lg px-4 py-1 my-1', {
															'text-blue-500 bg-blue-50': noShortcutSelected,
														})}
													>
														Custom
													</button>
												</li>
											</ul>
										</div>
									)}
									<div
										className={classNames({
											'w-3/4': shortcuts,
											'w-full': !shortcuts,
										})}
									>
										{isDate && datePicker}
										{isRange && rangePicker}
										{isMonth && monthPicker}
										{isQuarter && quarterPicker}
										{isWeek && weekPicker}
									</div>
								</div>
								{isRange && showInfo && !showApplyBtn && (
									<p className='mt-4 text-sm text-blue-600 text-right'>
										<span className='font-secondary'>{'No case data present for some (or all) of the date range selected'}</span>
									</p>
								)}
								{isRange && showApplyBtn && (
									<p className='mt-4 text-sm text-red-600 text-right'>
										<span className='font-secondary'>{`Please select a date range up to ${dateRangeLimit} ${
											dateRangeLimit > 1 ? 'years' : 'year'
										}`}</span>
									</p>
								)}
							</div>
						</div>
						<div className='px-8 pb-4 flex justify-end gap-4'>
							<Popover.Close asChild>
								<Button variant='secondary' sizeX='md' sizeY='md' onClick={cancelDateSelection}>
									Cancel
								</Button>
							</Popover.Close>
							<Popover.Close asChild>
								<Button variant='primary' sizeX='md' sizeY='md' onClick={applyDateSelection} disabled={showApplyBtn}>
									Apply
								</Button>
							</Popover.Close>
						</div>
					</div>
				</Popover.Content>
			</Popover.Portal>
		</Popover.Root>
	);
}

/**
 * Utility function to return human-friendly date text
 * @param d1 the date selected, or the first date if a range is selected
 * @param d2 the end date, only provided if a range is selected
 */
function getSelectedDateLabel(d1: Date | undefined | null, d2?: Date | null): string {
	if (d1 && !d2) {
		return `${d1.toLocaleDateString('en-US', { weekday: 'short' })} ${d1.toLocaleDateString('en-US')}`;
	}

	if (d1 && d2) {
		return `${d1.toLocaleDateString('en-US', { weekday: 'short' })} ${d1.toLocaleDateString(
			'en-US'
		)} - ${d2.toLocaleDateString('en-US', { weekday: 'short' })} ${d2.toLocaleDateString('en-US')}`;
	}

	return ``;
}
