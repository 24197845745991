import { useEffect } from 'react';

import { useAuth } from 'context/AuthProvider';

import type { ReactNode } from 'react';

interface LoginCallbackProps {
	children: ReactNode;
}

export function LoginCallback({ children }: LoginCallbackProps) {
	const { loginCallback } = useAuth();

	useEffect(() => {
		loginCallback();
	}, [loginCallback]);

	return <>{children}</>;
}

export default LoginCallback;
