import { TooltipContent } from '@radix-ui/react-tooltip';

import { MappingInput, NewEntityData } from 'pages/Settings';
import { DataTable, ToggleGroup } from 'components';
import { ShortOption } from 'store';

interface NewEntityTableProps {
	data: NewEntityData[];
	saveEntityAs: (fromSurgeon: number, toSurgeon: number) => void;
	createEntity: (surgeon: number) => void;
	entityOptions: ShortOption[];
	title: string;
	tooltipContent: string;
	goToHelpID: string;
	emptyEntityMessage: string;
	entityCreateText: string;
	entityName: string;
	swapEntity?: JSX.Element;
	keepPageIndexOnLeave?: string;
}

export function EntityMappingTable({
	data,
	title,
	tooltipContent,
	saveEntityAs,
	createEntity,
	entityOptions,
	goToHelpID,
	emptyEntityMessage,
	entityCreateText,
	entityName,
	keepPageIndexOnLeave,
	swapEntity,
}: NewEntityTableProps) {
	return (
		<div className='h-50'>
			<DataTable
				title={title}
				tooltipContent={tooltipContent}
				goToHelpID={goToHelpID}
				minHeight={'28em'}
				keepPageIndexOnLeave={keepPageIndexOnLeave}
				headerContentRight={swapEntity}
				columns={[
					{
						header: 'Imported As',
						accessorKey: 'imported_as',
						cell: ({ row }) => (
							<span className='py-1 text-p2 text-center font-semibold'>{row.original.imported_as.name}</span>
						),
						sortingFn: (rowA, rowB) => {
							const a = rowA.original.imported_as.name;
							const b = rowB.original.imported_as.name;
							return b > a ? 1 : -1;
						},
					},
					{
						header: 'Date Found',
						accessorKey: 'date_found',
						cell: ({ row }) => (
							<span className='py-1 text-p2 text-center'>
								{new Date(row.original.date_found).toLocaleString('en-US', {
									month: 'numeric',
									day: 'numeric',
									year: '2-digit',
									hour: 'numeric',
									minute: 'numeric',
									hour12: true,
								})}
							</span>
						),
						sortingFn: (rowA, rowB, columnId) => {
							const a = new Date(rowA.getValue(columnId)).getTime();
							const b = new Date(rowB.getValue(columnId)).getTime();
							return b > a ? 1 : -1;
						},
						enableGlobalFilter: false,
					},
					{
						header: 'Similarity Level',
						accessorKey: 'sim_level',
						cell: ({ row }) => {
							if (row.original.sim_level.closest_option) {
								return (
									<div>
										<span className='text-p2 font-semibold'>{`${row.original.sim_level.percentage}% `}</span>
										<span className='text-p2'>similar to</span>
										<span className='text-p2 font-semibold'>{` ${row.original.sim_level.closest_option}`}</span>
									</div>
								);
							} else {
								return (
									<div>
										<span className='text-gray-400 italic text-p2'>{emptyEntityMessage}</span>
									</div>
								);
							}
						},
						enableGlobalFilter: false,
					},
					{
						header: `Map to Existing ${entityName} Name`,
						accessorKey: '',
						cell: ({ row }) => (
							<MappingInput
								allOptions={entityOptions.filter((o) => o.name !== row.original.imported_as.name)}
								save={(selection) => {
									saveEntityAs(row.original.imported_as.id, selection.id);
								}}
								create={() => {
									createEntity(row.original.imported_as.id);
								}}
								createText={entityCreateText}
							/>
						),
						enableGlobalFilter: false,
					},
				]}
				data={data}
			/>
		</div>
	);
}

export default EntityMappingTable;
