import { ArrayOf } from 'types';

interface tableOfContentsEntry {
	name: string;
	url: string;
	subEntries?: ArrayOf<'at least', 1, tableOfContentsEntry>;
}

/**
 * A simple table of contents listing for use within the StyleGuide.
 */
export function TableOfContents({ entries }: { entries: tableOfContentsEntry[] }) {
	return (
		<ul className='list-disc list-inside ml-3'>
			{entries.map(({ name, url, subEntries }: tableOfContentsEntry) => (
				<li key={url} className='mt-1'>
					<a href={url}>{name}</a>
					{subEntries && <TableOfContents entries={subEntries} />}
				</li>
			))}
		</ul>
	);
}

export default TableOfContents;
