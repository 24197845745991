import { useCallback, useState } from 'react';
import classNames from 'classnames';

import { Button, Datepicker } from 'components';
import { getNextDate, getPreviousDate, toFullDate } from 'utils';

import type { DatepickerProps } from 'components';

interface TempDateToggleProps extends DatepickerProps {
	disabled?: boolean;
	minLimit?: Date;
}

type DateToggleProps = Omit<TempDateToggleProps, 'type'>;

export function DateToggle(props: DateToggleProps) {
	const [selectedDate, setSelectedDate] = useState(props.selected ?? new Date());
	const preBtnDisable = props.minLimit && getPreviousDate(selectedDate) < props.minLimit ? true : false;
	const nxtBtnDisable = getNextDate(selectedDate) > new Date() ? true : false;

	// handler for clicking the "previous" arrow
	// this might behave differently for other datepickers (month/quarter/range)
	const handlePrevClick = useCallback(() => {
		const prev = getPreviousDate(selectedDate);
		setSelectedDate(prev);
		props.onApply && props.onApply(prev);
	}, [props, selectedDate, setSelectedDate]);

	// handler for clicking the "next" arrow
	// this might behave differently for other datepickers (month/quarter/range)
	const handleNextClick = useCallback(() => {
		const next = getNextDate(selectedDate);
		setSelectedDate(next);
		props.onApply && props.onApply(next);
	}, [props, selectedDate, setSelectedDate]);

	// handler for applying the selected date in the picker and also returning it to the user
	// via the onApply() method they pass via the props
	const handleApply = useCallback(
		(d: Date) => {
			setSelectedDate(d);
			props.onApply && props.onApply(d);
		},
		[props, setSelectedDate]
	);

	return (
		<div
			className={classNames(
				'inline-flex items-center justify-between relative bg-blue-500 h-10',
				'border border-blue-500 rounded',
				'text-white'
			)}
		>
			{!props.disabled ? (
				<>
					<Button
						sizeX='square'
						sizeY='sm'
						variant='transparent'
						className='text-white my-1'
						onClick={handlePrevClick}
						disabled={preBtnDisable}
					>
						<span className='material-symbol'>navigate_before</span>
					</Button>
					<div className='font-semibold'>
						<Datepicker key={selectedDate.getTime()} {...props} selected={selectedDate} type='date' onApply={handleApply}>
							<Button sizeX='sm' sizeY='sm' variant='transparent'>
								{toFullDate(selectedDate)}
							</Button>
						</Datepicker>
					</div>
					<Button
						sizeX='square'
						sizeY='sm'
						variant='transparent'
						className='text-white my-1'
						onClick={handleNextClick}
						disabled={nxtBtnDisable}
					>
						<span className='material-symbol'>navigate_next</span>
					</Button>
				</>
			) : (
				<span className='text-b3 font-semibold py-3 px-6'>
					{props.selectedRange &&
						props.selectedRange[0] &&
						props.selectedRange[1] &&
						`${toFullDate(props.selectedRange[0])} - ${toFullDate(props.selectedRange[1])}`}
					{props.selected && props.selected !== null && `${toFullDate(props.selected)}`}
				</span>
			)}
		</div>
	);
}

export default DateToggle;
