import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button, TextField } from 'components';
import { defaultRequestResetPasswordFormInputValues, requestResetPasswordFormSchema } from 'models/authentication';
import { AuthPage } from 'pages/Auth';
import { getOktaErrorMessage } from 'utils/authentication';
import { Routes } from 'models/navigation';
import { useAuth } from 'context';

import type { AnyAuthContext } from 'context';
import type { RequestResetPasswordFormInputs } from 'models/authentication';
import type { SubmitHandler } from 'react-hook-form';

export function RequestResetPassword() {
	const auth = useAuth<AnyAuthContext>();
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors, isDirty, isSubmitting, isSubmitted },
	} = useForm<RequestResetPasswordFormInputs>({ resolver: yupResolver(requestResetPasswordFormSchema) });
	const [errorMessage, setErrorMessage] = useState('');
	const [showSuccess, setShowSuccess] = useState(false);

	const onSubmit: SubmitHandler<RequestResetPasswordFormInputs> = async (formData) => {
		await auth.resetPassword(formData.email);
		setShowSuccess(true);
	};

	// When an authentication error occurs, keep the form's values but reset its
	// dirty state. Then when the form becomes dirty again, clear the error since
	// the user has indicated they are trying again.
	useEffect(() => {
		const clearAuthError = auth.clearError;

		if (auth.status === 'api_error') {
			if (isSubmitted) {
				reset(defaultRequestResetPasswordFormInputValues, { keepValues: true });
			} else if (isDirty) {
				clearAuthError();
			}
		}
	}, [auth.status, auth.clearError, isSubmitted, isDirty, reset]);

	// Provide a more detailed authentication error message based on the error
	// code returned by the Okta API.
	useEffect(() => {
		if (auth.status === 'api_error') {
			setErrorMessage(getOktaErrorMessage(auth.data.error.errorCode));
		} else {
			setErrorMessage('');
		}
	}, [auth.status, auth.data]);

	return (
		<AuthPage>
			<div className='flex'>
				<div className='flex max-w-xl w-full'>
					<div className='m-auto pt-7 px-8 max-w-md w-full'>
						<h1 className='font-semibold text-h4'>Reset Your Password</h1>

						<form className='flex flex-col mt-8' onSubmit={handleSubmit(onSubmit)} noValidate autoComplete='off'>
							{showSuccess && (
								<div className='bg-green-500 rounded text-white p-4 mb-8'>
									<p>Request submitted. You should receive an email message with a link to reset your password.</p>
								</div>
							)}

							<TextField
								label='Email Address'
								type='email'
								sizeY='lg'
								defaultValue={defaultRequestResetPasswordFormInputValues.email}
								errorMessage={errors.email?.message}
								{...register('email')}
							/>

							<Link to={Routes.HOME} className='self-start font-secondary text-p2 mt-3'>
								<span className='material-symbol-sm relative top-[5px] mr-1'>keyboard_backspace</span>
								Back to Login
							</Link>

							<Button className='mt-12' sizeY='lg' isWorking={isSubmitting} disabled={!isDirty}>
								{isSubmitting ? 'Submitting' : 'Submit'}
							</Button>

							<p className='mt-2 text-p2 text-red-400'>{errorMessage || <>&nbsp;</>}</p>
						</form>
					</div>
				</div>
			</div>

			<div className='bg-merlinBlue'></div>
		</AuthPage>
	);
}

export default RequestResetPassword;
