import { maxHeaderSize } from 'http';

import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Accordion, Button, Select, TextField, ToggleGroup, Tooltip } from 'components';
import image from 'assets/images/empty.svg';
import { Routes } from 'models';
import { countOccurrences, numberWithCommas } from 'utils';
import { useAppSelector } from 'store';

import type { ColumnDef, ColumnSort, Row, SortingState, Updater } from '@tanstack/react-table';
import type { TableHTMLAttributes } from 'react';
import type { TooltipProps } from 'components';

export interface SortedColumns {
	name: string;
	direction: string;
}

declare module '@tanstack/table-core' {
	// Since we're extending this interface, it needs to have the exact same type
	// parameters as the base, even if we're not directly using them.
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	interface ColumnMeta<TData, TValue> {
		/** A string of additional classes to apply to this column's body cells. */
		bodyClass?: string;

		/** A string of additional classes to apply to all cells in this column. */
		columnClass?: string;

		/** A string of additional classes to apply to this column's footer cells. */
		footerClass?: string;

		/** A string of additional classes to apply to this column's header cells. */
		headerClass?: string;

		/** Tooltip content to apply to this column's header. */
		tooltipContent?: TooltipProps['content'];
	}
}

export interface DataTableProps<TableRow> extends TableHTMLAttributes<HTMLTableElement> {
	/**
	 * The column definitions for this data table, which determine how the raw data are parsed and displayed. For more
	 * information on column definitions, see [the TanStack Tabledocumentation](https://tanstack.com/table/v8/docs/guide/column-defs).
	 */
	columns: ColumnDef<TableRow>[];

	/** An array of data points with which to populate the rows of this data table. */
	data: TableRow[];

	/** Whether to disable pagination for this data table and display all available rows at once. */
	disablePagination?: boolean;

	/** Whether to disable the "X of N items" row counter for this data table. */
	disableRowCounter?: boolean;

	/** A custom message to show in this table when it has no data to display. */
	emptyMessage?: string;

	/** Additional content or controls to display in the center area of this data table's header. */
	headerContentCenter?: React.ReactNode;

	/**
	 * The desired positioning of content in the center area of this data table's header. When set to `center`, the
	 * content will stay aligned with the center of this data table; when set to `left`, the content will align itself
	 * next to this data table's title.
	 */
	headerContentCenterAlignment?: 'center' | 'left';

	/** Additional content or controls to display to the rightmost area of this data table's header. */
	headerContentRight?: React.ReactNode;

	/**
	 * The options for how many rows should be displayed on each page of this data table. The first option will always be
	 * the default selection.
	 */
	pageSizes?: number[];

	/**
	 * A layout to display when expanding one of this data table's rows. In callback form, the row's data are supplied as
	 * the first argument, allowing them to be referenced within the subview.
	 */
	rowSubview?: React.ReactNode | ((row: Row<TableRow>) => React.ReactNode);

	/** The title to apply to this data table. */
	title: string;

	/** The tooltipContent to apply to this data table. */
	tooltipContent?: string;

	/** The subtext to display below title */
	subtext?: string;

	/** A static min height for the table, Ex: 28em */
	minHeight?: string;

	/** A static max height for the table, Ex: 80 */
	maxHeight?: string;

	/** Removes header and some borders, compressing the table to be more minimalistic. */
	minimalStyle?: boolean;

	// Show column/row sums in the table
	displayColSums?: boolean;
	displayRowSums?: boolean;
	displayColAvg?: boolean;
	displayRowAvg?: boolean;
	addCommaToSums?: boolean;
	addPrefixToSums?: string;

	// default sort of table
	defaultSort?: ColumnSort;

	// These columns will be ommitted from column wise and row wise sums
	omittedColumns?: string[];

	/** Makes cursor into arrow for row clicking. */
	clickableRows?: (row: TableRow) => void | undefined;

	/** Renders table in a print friendly format. */
	printable?: boolean;

	keepPageIndexOnLeave?: string;

	goToHelpID?: string;

	// ** what headers will show when the user expands the table */
	expansionHeaders?: string[];

	/** This contains a mapping of string to div to style headers */
	headerIconHeaderMap?: {
		[key: string]: {
			element: React.ReactNode;
			arrow_style: string;
		};
	};

	/** Determines if to show hover effect on mouse over. */
	noHover?: boolean;

	/** Rows that should be highlighted */
	highlightedRows?: string[];

	/** On sort this function is called */
	onSort?: (arr: SortedColumns[]) => void;

	/** Open all accordions */
	openAllSubviews?: boolean;
}

export function DataTable<TableRow>({
	title,
	tooltipContent,
	subtext,
	data,
	columns,
	minHeight,
	omittedColumns,
	maxHeight,
	headerIconHeaderMap,
	expansionHeaders,
	minimalStyle,
	defaultSort,
	printable,
	highlightedRows,
	addCommaToSums,
	addPrefixToSums = '',
	clickableRows,
	noHover,
	keepPageIndexOnLeave,
	goToHelpID = 'default',
	headerContentCenter,
	headerContentCenterAlignment = 'center',
	headerContentRight,
	pageSizes = [10, 25, 50],
	emptyMessage = 'This table has no data to display.',
	rowSubview,
	onSort,
	disablePagination = false,
	disableRowCounter = false,
	displayColSums = false,
	displayRowSums = false,
	displayColAvg = false,
	displayRowAvg = false,
	openAllSubviews = false,
	...props
}: DataTableProps<TableRow>) {
	const [sorting, setSorting] = useState<SortingState>(defaultSort ? [defaultSort] : []);
	const [sortStack, setSortStack] = useState<string[]>([]);
	const [globalFilter, setGlobalFilter] = useState('');
	const [rowSelection, setRowSelection] = useState({});
	const [sumState, setSumState] = useState<'sum' | 'percent'>('sum');
	const [sumRow, setSumRow] = useState<{ [key: string]: any }>({});
	const [sumCol, setSumCol] = useState<{ [key: string]: any }>({});
	const [avgRow, setAvgRow] = useState<{ [key: string]: any }>({});
	const [avgCol, setAvgCol] = useState<{ [key: string]: any }>({});
	const { selectedFacility } = useAppSelector((state) => state.userState);
	const tableContainerRef = useRef<HTMLDivElement>(null);

	// If a row subview has been provided, insert a column containing controls to
	// select/open each row to display its subview.
	const lastColumn = columns.at(-1);
	const secondToLastColumn = columns.at(-2);

	const [expandedMode, setExpandedMode] = useState(true);
	const expansionColumns = expansionHeaders ? expansionHeaders : [];
	const filterColumns = columns.filter((column) => !expansionColumns.includes(`${column.header}`) || !expandedMode);

	const handleMultiColumnSortChange = (newState: any) => {
		const newSortArray = newState(); // Assuming newState returns an array of objects with id and sort properties
		const existingSortArray = sorting; // Assuming sorting is a state variable holding the current sort configuration
		const newSortIds = newSortArray.map((elm: { id: string }) => elm.id);
		let newSort = existingSortArray.filter((val) => !newSortIds.includes(val.id)).concat(newSortArray);
		const hash: { [key: string]: number } = {};

		// if any id has 3 items in stack, it means the user wants no sort for that column (they have clicked it 3 times)
		for (let i = 0; i < sortStack.length; i++) {
			if (hash[sortStack[i]]) {
				hash[sortStack[i]] += 1;
			} else {
				hash[sortStack[i]] = 1;
			}

			if (hash[sortStack[i]] === 3) {
				newSort = newSort.filter((elm) => !(elm.id === sortStack[i]));
				setSortStack((prevState) => {
					return prevState.filter((elm) => !(elm === sortStack[i]));
				});
			}
		}

		// Update the sorting state with the new configuration
		setSorting(newSort);
	};

	const table = useReactTable({
		data,
		columns: filterColumns,
		state: {
			rowSelection,
			sorting,
			globalFilter,
		},
		onRowSelectionChange: setRowSelection,
		onSortingChange: handleMultiColumnSortChange,
		onGlobalFilterChange: setGlobalFilter,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: disablePagination ? undefined : getPaginationRowModel(),
	});

	const headerGroups = table.getHeaderGroups();
	const rowModel = table.getRowModel();

	// Generate a set of page size options for use with a select field.
	const pageSizeOptions = pageSizes.map((pageSize) => ({
		label: `${pageSize} rows per page`,
		value: pageSize,
	}));

	// Determine if the filter/search input should be displayed based on whether
	// there are any filterable columns in this table.
	const displayFilterInput = !headerGroups.every((headerGroup) =>
		headerGroup.headers.every((header) => !header.column.getCanGlobalFilter())
	);

	// Determine if the controls area at the bottom of the table should be
	// displayed based on whether all of the features it contains are disabled.
	const displayControls = !(disableRowCounter && disablePagination);

	// Get a list of the names of columns where filtering is enabled for this
	// table so that they can be communicated to the user.
	const filterableColumnHeaders = useMemo(
		() =>
			headerGroups.flatMap((headerGroup) =>
				headerGroup.headers.reduce<string[]>((acc, header) => {
					if (
						!header.isPlaceholder &&
						header.column.getCanGlobalFilter() &&
						typeof header.column.columnDef.header === 'string'
					)
						acc.push(header.column.columnDef.header);

					return acc;
				}, [])
			),
		[headerGroups]
	);

	const headers = headerGroups[0].headers.map((header) => header.id);

	useEffect(() => {
		rowModel.rows.map((row) => {
			// open all
			if (openAllSubviews) {
				row.toggleSelected(true);
			} else {
				row.toggleSelected(false);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openAllSubviews]);

	useEffect(() => {
		// Calculate sums per column/row
		const sumCol: { [key: string]: any } = {};
		const sumRow: { [key: string]: any } = {};
		const avgCol: { [key: string]: any } = {};
		const avgRow: { [key: string]: any } = {};

		// index is row number
		data.forEach((row, row_index) => {
			// key is column name
			headers.forEach((key, col_index) => {
				if (!omittedColumns?.includes(key)) {
					// sum column wise
					const currentValue = parseFloat(`${row[key as keyof TableRow] ?? 0}`);
					sumCol[key] = currentValue + (sumCol[key] || 0);
					// console.log(currentValue);
					// console.log(avgCol[key]);
					avgCol[key] = currentValue + (avgCol[key] || 0);
					if (key === '$AVGCOLUMN') {
						avgCol[key] = avgCol[key] / col_index ?? 0; // this is correct
					}
					// sum row wise
					sumRow[row_index] = currentValue + (sumRow[row_index] || 0);
					avgRow[row_index] = currentValue + (avgRow[row_index] || 0);
				}
			});
		});

		// calulate percentage for each column/row
		if (sumState === 'percent') {
			// sum values from sumCol
			let totalSumCol = 0;
			let totalRowSum = 0;

			Object.keys(sumCol).forEach((key) => {
				totalSumCol += sumCol[key];
			});

			Object.keys(sumRow).forEach((key) => {
				totalRowSum += sumRow[key];
			});

			Object.keys(sumCol).forEach((key) => {
				sumCol[key] = (sumCol[key] / totalSumCol) * 100;
			});

			// sum values from sumRow
			Object.keys(sumRow).forEach((key) => {
				sumRow[key] = (sumRow[key] / totalRowSum) * 100;
			});
		}

		// Create cum column for each row
		if (
			secondToLastColumn &&
			secondToLastColumn.id !== '$SUMCOLUMN' &&
			lastColumn &&
			lastColumn.id !== '$SUMCOLUMN' &&
			displayRowSums
		) {
			columns.push({
				id: '$SUMCOLUMN',
				header: '$SUMHEADER',
				meta: { headerClass: 'w-16' },
				sortingFn: (a, b) => {
					return sumRow[parseInt(a.id)] - sumRow[parseInt(b.id)];
				},
				accessorKey: 'SUMHEADER',
			});
		}

		// Create cum column for each row
		if (
			secondToLastColumn &&
			secondToLastColumn.id !== '$AVGCOLUMN' &&
			lastColumn &&
			lastColumn.id !== '$AVGCOLUMN' &&
			displayRowAvg
		) {
			columns.push({
				id: '$AVGCOLUMN',
				header: '$AVGHEADER',
				meta: { headerClass: 'w-16' },
				sortingFn: (a, b) => {
					return avgRow[parseInt(a.id)] - avgRow[parseInt(b.id)];
				},
				accessorKey: 'AVGHEADER',
			});
		}

		// Create accordion drop down if option selected
		if (
			rowSubview &&
			lastColumn &&
			lastColumn.id !== 'select' &&
			lastColumn.id !== '$SUMCOLUMN' &&
			lastColumn.id !== '$AVGCOLUMN'
		) {
			columns.push({
				id: 'select',
				cell: ({ row }) => {
					return (
						<div className='text-right'>
							<button className='inline-block' onClick={row.getToggleSelectedHandler()}>
								<span
									className={classNames(
										'h-[20px] w-[20px] grid place-items-center text-blue-600 bg-blue-50 rounded-full ml-auto',
										noHover ? '' : 'hover:bg-blue-600 hover:text-blue-50 hover:before:border-t-blue-50',
										'transition-[transform,color,background-color]',
										// `:before` pseudo-element styles
										'before:mt-px before:border-t-[5px] before:border-x-[5px] before:border-x-transparent before:border-t-blue-600',
										'before:transition-colors',
										{
											'bg-blue-600 text-blue-50 -rotate-180 before:border-t-blue-50': row.getIsSelected(),
										}
									)}
								/>
							</button>
						</div>
					);
				},
				meta: { headerClass: 'w-0' },
			});
		}

		setSumCol(sumCol);
		setSumRow(sumRow);
		// console.log(avgCol);
		// console.log(avgRow);
		setAvgCol(avgCol);
		setAvgRow(avgRow);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sumState, data, displayRowSums, displayRowAvg, secondToLastColumn, lastColumn]);

	// Determine if the data changes in table, it should clear the search bar.
	useEffect(() => {
		setGlobalFilter('');
		table.setPageSize(pageSizes[0]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const navigate = useNavigate();

	// This is used to return to a page when the user clicks on the "Go back" button.
	let paramValue: string | null = '0';
	if (keepPageIndexOnLeave) {
		// Get page query parameters from the URL
		const url = new URL(window.location.href);
		const params = new URLSearchParams(url.search);
		paramValue = params.get(keepPageIndexOnLeave);

		if (paramValue !== null) {
			const pageIndex = parseInt(`${paramValue}`) - 1;

			if (table.getState().pagination.pageIndex !== pageIndex && pageIndex >= 0) {
				table.setPageIndex(pageIndex);
			}
		}
	}

	// if facility changes, reset page index
	useEffect(() => {
		if (keepPageIndexOnLeave) {
			// update url as user navigates through pages
			const url = new URL(window.location.href);
			const params = new URLSearchParams(url.search);
			params.set(keepPageIndexOnLeave, '0');
			url.search = params.toString();
			window.history.pushState({}, '', url.toString());
		}
		table.setPageIndex(0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFacility]);

	// echos sorting actions
	useEffect(() => {
		if (onSort) {
			onSort(
				sorting.map((elem) => ({
					name: elem.id,
					direction: !elem.desc ? 'asc' : 'desc',
				}))
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sorting]);

	return (
		<div
			ref={tableContainerRef}
			className={classNames(
				{ 'border-0 rounded-md': minimalStyle },
				'bg-white border border-blue-500 rounded-sm text-p1 font-primary'
			)}
		>
			{!minimalStyle && (
				<div
					className={classNames('grid gap-8 items-center py-4 px-8 border-b border-gray-200 overflow-x-clip', {
						'grid-cols-[1fr_auto_1fr]': headerContentCenterAlignment === 'center',
						'grid-cols-[max-content_auto_max-content]': headerContentCenterAlignment === 'left',
					})}
				>
					<div>
						<div className='font-semibold text-p1 flex items-center gap-1'>
							{title}{' '}
							{tooltipContent && (
								<Tooltip content={tooltipContent}>
									<div
										className='material-symbol-sm text-blue-500'
										onClick={() => navigate(Routes.HELP_FAQS + `?goToId=${goToHelpID}`)}
									>
										info
									</div>
								</Tooltip>
							)}
						</div>

						{subtext && <div className='text-p3 pt-2'>{subtext}</div>}
					</div>

					<div>{headerContentCenter}</div>

					<div className='justify-self-end flex flex-col lg:flex-row gap-2'>
						{headerContentRight}

						{displayFilterInput && (
							<div className='flex shrink-0 items-center'>
								<TextField
									label='Search'
									icon='search'
									iconClass='text-blue-500'
									hideLabel
									value={globalFilter}
									onChange={(e) => setGlobalFilter(e.currentTarget.value)}
									placeholder='Search rows'
								/>

								<Tooltip
									content={
										<div className='text-p2'>
											This table allows searching within the following columns:
											<ul className='list-disc pl-6 pt-1'>
												{filterableColumnHeaders.map((columnHeader, i) => (
													<li key={i}>{columnHeader}</li>
												))}
											</ul>
										</div>
									}
								>
									<div className='material-symbol-sm text-gray-500 pl-1'>info</div>
								</Tooltip>
							</div>
						)}
					</div>
				</div>
			)}

			<div
				className={classNames(
					minHeight && `overflow-x-auto min-h-[${minHeight}]`,
					maxHeight && `overflow-x-auto max-h-[${maxHeight}]`,
					'overflow-x-auto flex'
				)}
				style={{ minHeight, maxHeight }}
			>
				<table className='w-full border-collapse border-spacing-0 overflow-clip overflow-y-auto' {...props}>
					<thead>
						{headerGroups.map((headerGroup, header_index) => (
							<tr key={headerGroup.id} className='border-b border-gray-200 h-fit'>
								{headerGroup.headers.map((header) => (
									<th
										key={header.id}
										colSpan={header.colSpan}
										className={classNames(
											'h-12 p-0 px-2 first:pl-8 last:pr-8',
											'text-p3 uppercase font-semibold text-bluegray-900',
											header.column.getCanSort() && 'cursor-pointer select-none',
											header.column.columnDef.meta?.columnClass,
											header.column.columnDef.meta?.headerClass
										)}
										onClick={header.column.getToggleSortingHandler()}
										onClickCapture={() => {
											setSortStack((prevState) => {
												return prevState.concat([header.column.id]);
											});
										}}
									>
										{header.isPlaceholder ? null : (
											<div
												className={`inline-flex items-center gap-1 ${
													header.column.columnDef.meta?.headerClass ? header.column.columnDef.meta?.headerClass : 'truncate'
												}`}
											>
												{headerIconHeaderMap &&
													(`${header.column.columnDef.header}` in headerIconHeaderMap && !expandedMode ? (
														<div className='flex flex-row'>
															<p>{headerIconHeaderMap[`${header.column.columnDef.header}`].element}</p>
															<span className={headerIconHeaderMap[`${header.column.columnDef.header}`].arrow_style}>
																{header.column.getCanSort() && (
																	<div className='material-symbol-sm'>
																		{header.column.getIsSorted() === 'asc'
																			? 'arrow_downward'
																			: header.column.getIsSorted() === 'desc'
																			? 'arrow_upward'
																			: 'unfold_more'}
																	</div>
																)}
															</span>
														</div>
													) : (
														flexRender(header.column.columnDef.header, header.getContext())
													))}

												{!headerIconHeaderMap &&
													header.column.columnDef.header !== '$SUMHEADER' &&
													header.column.columnDef.header !== '$AVGHEADER' &&
													flexRender(header.column.columnDef.header, header.getContext())}

												{header.column.columnDef.header === '$SUMHEADER' && (
													<div className='flex items-normal'>
														<span className='w-1.5 mb-2 h-2'>
															{sumState === 'percent' ? (
																<span className='material-symbols-outlined text-[1.7em] text-bluegray-500'>percent</span>
															) : (
																<span className='material-symbols-outlined font-thin text-[1.7em] text-bluegray-500'>functions</span>
															)}
														</span>
													</div>
												)}
												{header.column.columnDef.header === '$AVGHEADER' && (
													<div className='flex items-normal'>
														<span className='w-1.5 mb-2 h-2'>
															<span className='material-symbols-outlined font-thin text-[1.7em] text-bluegray-500'>function</span>
														</span>
													</div>
												)}

												{header.column.columnDef.meta?.tooltipContent && (
													<Tooltip content={header.column.columnDef.meta.tooltipContent} contentProps={{ className: 'normal-case' }}>
														<div className='material-symbol-sm'>info</div>
													</Tooltip>
												)}

												{headerIconHeaderMap &&
													(!(`${header.column.columnDef.header}` in headerIconHeaderMap && !expandedMode)
														? header.column.getCanSort() && (
																<div className='material-symbol-sm'>
																	{header.column.getIsSorted() === 'asc'
																		? 'arrow_downward'
																		: header.column.getIsSorted() === 'desc'
																		? 'arrow_upward'
																		: 'unfold_more'}
																</div>
														  )
														: null)}

												{!headerIconHeaderMap && header.column.getCanSort() && (
													<div
														className={`material-symbol-sm ${
															header.column.columnDef.header === '$SUMHEADER' || header.column.columnDef.header === '$AVGHEADER'
																? 'mt-[4px] ml-2 text-bluegray-500'
																: 'mt-0'
														}`}
													>
														{header.column.getIsSorted() === 'asc'
															? 'arrow_downward'
															: header.column.getIsSorted() === 'desc'
															? 'arrow_upward'
															: 'unfold_more'}
													</div>
												)}
											</div>
										)}
									</th>
								))}
							</tr>
						))}
					</thead>

					<tbody>
						{rowModel.rows.length > 0 ? (
							rowModel.rows.map((row) => (
								<Fragment key={row.id}>
									<tr
										className={classNames(
											'group border-t first:border-t-0 border-gray-100',
											!clickableRows
												? noHover
													? ''
													: 'hover:bg-blue-50 transition-colors'
												: 'hover:bg-blue-50 transition-colors cursor-pointer',
											highlightedRows && highlightedRows.includes(row.id) ? 'bg-slate-100' : ''
										)}
										onClick={() => {
											if (clickableRows) {
												clickableRows(row.original);
											}
											return undefined;
										}}
									>
										{row.getVisibleCells().map((cell, cell_index) => (
											<>
												<td
													key={cell.id}
													className={classNames(
														'h-12 p-0 px-2 first:pl-8 last:pr-8 text-p2',
														cell.column.columnDef.meta?.columnClass,
														cell.column.columnDef.meta?.bodyClass
													)}
												>
													{cell.column.id === '$SUMCOLUMN' && displayRowSums && (
														<p
															onClick={() => {
																if (sumState === 'sum') {
																	setSumState('percent');
																} else {
																	setSumState('sum');
																}
															}}
															className='ml-[0.3em] font-bold text-bluegray-500 cursor-pointer hover:text-blue-600'
														>
															{addCommaToSums
																? numberWithCommas(
																		`${sumState !== 'percent' ? addPrefixToSums : ''}${parseFloat(
																			parseFloat(`${sumRow[row.id]}`).toFixed(sumState === 'sum' ? 2 : 0)
																		)}`
																  )
																: `${sumState !== 'percent' ? addPrefixToSums : ''}${parseFloat(
																		parseFloat(`${sumRow[row.id]}`).toFixed(sumState === 'sum' ? 2 : 0)
																  )}`}
															{sumState === 'percent' && '%'}
														</p>
													)}
													{cell.column.id === '$AVGCOLUMN' && displayRowAvg && (
														// <p className='ml-[0.3em] font-bold text-bluegray-500 cursor-pointer hover:text-blue-600'>
														// 	{parseFloat(`${avgRow[row.id]}`).toFixed(2)}
														// </p>
														<p
															className='ml-[0.3em] font-bold text-bluegray-500 cursor-pointer hover:text-blue-600'
															onClick={() => {
																// console.log(row.id);
																// console.log(avgRow[row.id]);
																// console.log(avgRow);
															}}
														>
															{(parseFloat(avgRow[row.id]) / 24).toFixed(2)}
														</p>
													)}
													{cell.column.id !== '$SUMCOLUMN' &&
														cell.column.id !== '$AVGCOLUMN' &&
														flexRender(cell.column.columnDef.cell, cell.getContext())}
												</td>
											</>
										))}
									</tr>
									{rowSubview && (
										<tr hidden={!row.getIsSelected()}>
											<td className='px-8 py-6 bg-gray-50' colSpan={columns.length}>
												{typeof rowSubview === 'function' ? rowSubview(row) : rowSubview}
											</td>
										</tr>
									)}
								</Fragment>
							))
						) : (
							<tr>
								<td colSpan={columns.length} className='p-8 text-p2 text-gray-600'>
									<div className='flex flex-col w-full items-center gap-6'>
										<img className='w-44' alt='An empty clipboard' src={image} />
										{globalFilter ? 'No rows matched your filter.' : emptyMessage}
									</div>
								</td>
							</tr>
						)}
					</tbody>

					{displayColSums && (
						<tfoot>
							<tr className='border-t border-gray-100'>
								{headers.map((heade, index) => {
									// Last item is Accordion ToggleGroup, no summing required
									if (headers.length - 1 === index && rowSubview) {
										return null;
									}

									if (index === 0) {
										return (
											<th
												key={index}
												className={classNames('h-12 p-0 px-2 first:pl-7 last:pr-8 text-i3 font-semibold text-bluegray-500 w-fit')}
											>
												<div className='flex w-fit items-center'>
													<p className='mr-3'>Grand Total</p>
													<p>(</p>

													<span className='h-fit mt-1'>
														{sumState === 'percent' ? (
															<span className='material-symbols-outlined text-[1.7em]'>percent</span>
														) : (
															<span className='material-symbols-outlined font-thin text-[1.7em]'>functions</span>
														)}
													</span>
													<p>)</p>
												</div>
											</th>
										);
									} else {
										if (heade === '$SUMCOLUMN' || heade === 'select') {
											return (
												<th
													key={index}
													className={classNames(
														'h-12 p-0 px-2 first:pl-8 last:pr-8 text-p2 font-bold text-bluegray-500 cursor-pointer hover:text-blue-600 pl-3'
													)}
													onClick={() => {
														if (sumState === 'sum') {
															setSumState('percent');
														} else {
															setSumState('sum');
														}
													}}
												>
													<p>
														{addCommaToSums
															? numberWithCommas(
																	`${sumState !== 'percent' ? addPrefixToSums : ''}${parseFloat(
																		parseFloat(
																			`${headers
																				.map((k) => parseFloat(sumCol[k]))
																				.filter((e) => e)
																				.reduce((partialSum, a) => partialSum + a, 0)}`
																		).toFixed(sumState === 'sum' ? 2 : 0)
																	)}`
															  )
															: `${sumState !== 'percent' ? addPrefixToSums : ''}${parseFloat(
																	parseFloat(
																		`${headers
																			.map((k) => parseFloat(sumCol[k]))
																			.filter((e) => e)
																			.reduce((partialSum, a) => partialSum + a, 0)}`
																	).toFixed(sumState === 'sum' ? 2 : 0)
															  )}`}
														{sumState === 'percent' && '%'}
													</p>
												</th>
											);
										}

										return (
											<th
												key={index}
												className={classNames(
													'h-12 p-0 px-2 first:pl-8 last:pr-8 text-p2 font-bold text-bluegray-500 cursor-pointer hover:text-blue-600'
												)}
												onClick={() => {
													if (sumState === 'sum') {
														setSumState('percent');
													} else {
														setSumState('sum');
													}
												}}
											>
												<p>
													{addCommaToSums
														? numberWithCommas(
																`${sumState !== 'percent' ? addPrefixToSums : ''}${parseFloat(
																	parseFloat(`${sumCol[heade]}`).toFixed(sumState === 'sum' ? 2 : 0)
																)}`
														  )
														: `${sumState !== 'percent' ? addPrefixToSums : ''}${parseFloat(
																parseFloat(`${sumCol[heade]}`).toFixed(sumState === 'sum' ? 2 : 0)
														  )}`}
													{sumState === 'percent' && '%'}
												</p>
											</th>
										);
									}
								})}
							</tr>
						</tfoot>
					)}
					{displayColAvg && (
						<tfoot>
							<tr className='border-t border-gray-100'>
								{headers.map((heade, index) => {
									// Last item is Accordion ToggleGroup, no summing required
									if (headers.length - 1 === index && rowSubview) {
										return null;
									}

									if (index === 0) {
										return (
											<th
												key={index}
												className={classNames('h-12 p-0 px-2 first:pl-7 last:pr-8 text-i3 font-semibold text-bluegray-500 w-fit')}
											>
												<div className='flex w-fit items-center'>
													<p className='mr-3'>Average</p>
												</div>
											</th>
										);
									} else {
										if (heade === '$AVGCOLUMN' || heade === 'select') {
											return (
												<th
													key={index}
													className={classNames(
														'h-12 p-0 px-2 first:pl-8 last:pr-8 text-p2 font-bold text-bluegray-500 cursor-pointer hover:text-blue-600 pl-3'
													)}
												>
													<p>
														{/* {parseFloat(
															`${headers
																.map((k) => parseFloat(avgCol[k]))
																.filter((e) => e)
																.reduce(function (avg, value, _, { length }) {
																	return avg + value / length;
																}, 0)}`
														).toFixed(2)} */}
													</p>
												</th>
											);
										}

										return (
											<th
												key={index}
												className={classNames(
													'h-12 p-0 px-2 first:pl-8 last:pr-8 text-p2 font-bold text-bluegray-500 cursor-pointer hover:text-blue-600'
												)}
											>
												<p>{parseFloat(`${avgCol[heade] / headers.length}`).toFixed(2)}</p>
											</th>
										);
									}
								})}
							</tr>
						</tfoot>
					)}
				</table>
				{expansionHeaders && (
					<div
						className='h-100 w-11 bg-blue-50 cursor-pointer hover:opacity-80 rounded-sm ml-1 flex flex-col justify-around text-center'
						onClick={() => {
							setExpandedMode(!expandedMode);
						}}
					>
						{[...Array(4)].map((_, i) => (
							<span key={i} className={`material-symbols-outlined text-blue-300  ${!expandedMode ? 'rotate-180' : ''}`}>
								double_arrow
							</span>
						))}
					</div>
				)}
			</div>

			{displayControls && (
				<div className='flex items-center h-14 px-8 border-t border-gray-200 text-p2'>
					{!disableRowCounter && (
						<div className='font-semibold'>
							{table.getState().pagination.pageIndex * table.getState().pagination.pageSize + rowModel.rows.length} of{' '}
							{data.length} {data.length === 1 ? 'item' : 'items'}
						</div>
					)}

					{!disablePagination && (
						<div className='flex items-center gap-5 ml-auto'>
							{pageSizeOptions.length > 1 && (
								<div>
									<Select
										label='Rows per page'
										hideLabel
										options={pageSizeOptions}
										defaultValue={pageSizeOptions[0]}
										onChange={(newSelection) => {
											const currentPageSize = table.getState().pagination.pageSize;
											const newPageSize = newSelection?.value || pageSizeOptions[0].value;

											// Scroll up to the top of the table when reducing the page
											// size, since that can sometimes cause the table to disappear
											// from the viewport.
											if (newPageSize < currentPageSize) {
												tableContainerRef.current?.scrollIntoView();
											}

											table.setPageSize(newPageSize);
										}}
										disabled={data.length <= pageSizeOptions[0].value}
										reactSelectProps={{ isSearchable: false }}
									/>
								</div>
							)}

							<div className='flex items-center gap-2'>
								<Button
									sizeX='square'
									onClick={() => {
										if (keepPageIndexOnLeave) {
											// update url as user navigates through pages
											const url = new URL(window.location.href);
											const params = new URLSearchParams(url.search);
											params.set(keepPageIndexOnLeave, '0');
											url.search = params.toString();
											window.history.pushState({}, '', url.toString());
										}
										table.setPageIndex(0);
									}}
									disabled={!table.getCanPreviousPage()}
								>
									<div className='material-symbol'>first_page</div>
								</Button>

								<Button
									sizeX='square'
									onClick={() => {
										if (keepPageIndexOnLeave) {
											// update url as user navigates through pages
											const url = new URL(window.location.href);
											const params = new URLSearchParams(url.search);
											params.set(
												keepPageIndexOnLeave,
												`${table.getState().pagination.pageIndex === 0 ? 0 : (paramValue ? parseInt(paramValue) : 1) - 1}`
											);
											url.search = params.toString();
											window.history.pushState({}, '', url.toString());
										}
										table.previousPage();
									}}
									disabled={!table.getCanPreviousPage()}
								>
									<div className='material-symbol'>navigate_before</div>
								</Button>

								<span>
									Page {rowModel.rows.length === 0 ? '0' : table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
								</span>

								<Button
									sizeX='square'
									onClick={() => {
										if (keepPageIndexOnLeave) {
											// update url as user navigates through pages
											const url = new URL(window.location.href);
											const params = new URLSearchParams(url.search);
											params.set(
												keepPageIndexOnLeave,
												`${table.getState().pagination.pageIndex === 0 ? 2 : (paramValue ? parseInt(paramValue) : 1) + 1}`
											);
											url.search = params.toString();
											window.history.pushState({}, '', url.toString());
										}
										table.nextPage();
									}}
									disabled={!table.getCanNextPage()}
								>
									<div className='material-symbol'>navigate_next</div>
								</Button>

								<Button
									sizeX='square'
									onClick={() => {
										if (keepPageIndexOnLeave) {
											// update url as user navigates through pages
											const url = new URL(window.location.href);
											const params = new URLSearchParams(url.search);
											params.set(keepPageIndexOnLeave, `${table.getPageCount()}`);
											url.search = params.toString();
											window.history.pushState({}, '', url.toString());
										}

										table.setPageIndex(table.getPageCount() - 1);
									}}
									disabled={!table.getCanNextPage()}
								>
									<div className='material-symbol'>last_page</div>
								</Button>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
}

export { DataTable as default };
