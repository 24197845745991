import { useState } from 'react';

import { Button, ButtonLink, DataTable, LogoOverlay, UserSettingsDialog } from 'components';
import { FacilityLicense, reverseLicenseMap, UserRole } from 'models';
import { getFacility, getFacilityName, getLicenseNames, getRoleDesignationText, userIs } from 'utils';
import {
	getQueryError,
	handleApiResponse,
	useAppSelector,
	useDeleteUserMutation,
	useGetUsersQuery,
	useSystem,
} from 'store';
import { useAlert, useToast } from 'context';

export function AccountSettings() {
	const toast = useToast();
	const { getAlertResponse } = useAlert();
	// this keeps track of the id of the user being deleted
	const [userBeingDeleted, setUserBeingDeleted] = useState<number | null>(null);

	// get all needed data from state/API
	const { selectedSystem } = useAppSelector((state) => state.userState);
	const { data } = useSystem();
	const { data: usersData, isLoading } = useGetUsersQuery({
		healthsystem_id: selectedSystem,
	});
	const [deleteUser, { isLoading: isDeleting }] = useDeleteUserMutation();
	const facilities = data?.facilities;

	const facilityLicenseDetails =
		facilities?.map((facility) => ({
			name: getFacilityName(facility),
			license: facility.license,
			roomsCount: facility.rooms.length,
			rooms: facility.rooms,
		})) ?? [];

	const isAdministrator = userIs([UserRole.admin, UserRole.healthsystem_admin], data?.user);

	return (
		<>
			<div className='pt-4 relative'>
				{isLoading && <LogoOverlay backgroundColor='white' />}
				{facilities && (
					<div className='relative'>
						<DataTable
							title={isAdministrator ? 'Add & Manage Users' : 'Merlin Users'}
							tooltipContent={'Use this table to see which users have been added into Merlin'}
							goToHelpID='manageusers'
							headerContentRight={
								isAdministrator ? (
									<UserSettingsDialog facilities={facilities}>
										<Button sizeX='sm'>
											<span className='material-symbol'>person_add</span>
											Add User
										</Button>
									</UserSettingsDialog>
								) : undefined
							}
							columns={[
								{
									header: 'User',
									accessorFn: (row) => `${row.first_name} ${row.last_name}`,
									cell: ({ row }) => `${row.original.first_name} ${row.original.last_name}`,
								},
								{
									header: 'Email',
									accessorKey: 'email',
								},
								{
									header: 'Access Level',
									enableGlobalFilter: false,
									accessorKey: 'role',
									cell: ({ row }) => getRoleDesignationText(row.original.role),
								},
								{
									header: 'Facility Permissions',
									enableSorting: false,
									enableGlobalFilter: false,
									accessorKey: 'facilities',
									cell: ({ row }) => (
										<span className='truncate block max-w-xs'>
											{row.original.facilities.reduce(
												(prev, curr, idx) =>
													idx === 0
														? curr.healthsystem_id === selectedSystem
															? getFacilityName(curr)
															: ''
														: prev + (curr.healthsystem_id === selectedSystem ? ', ' + getFacilityName(curr) : ''),
												''
											)}
										</span>
									),
								},
								{
									header: 'Default Facility',
									accessorKey: 'default_facility',
									enableGlobalFilter: false,
									cell: ({ row }) => {
										const facility = getFacility(facilities, row.original.default_facility);

										return (
											facility && (
												<span className='px-4 py-1 rounded-full border border-blue-500 bg-blue-100 text-blue-700 text-p3'>
													{getFacilityName(facility)}
												</span>
											)
										);
									},
								},
								{
									id: 'actions',
									meta: {
										bodyClass: 'text-right',
									},
									cell: ({ row }) => {
										const userId = row.original.id;
										return isAdministrator && row.original.role >= data.user.role ? (
											<div className='transition-opacity opacity-0 group-hover:opacity-100'>
												<UserSettingsDialog user={row.original} facilities={facilities}>
													<ButtonLink>Edit</ButtonLink>
												</UserSettingsDialog>{' '}
												|{' '}
												<ButtonLink
													isWorking={isDeleting && userBeingDeleted === userId}
													onClick={async () => {
														// ask the user to confirm the deletion
														const alertResponse = await getAlertResponse({
															title: 'Confirm User Deletion',
															description: 'Are you sure you want to delete this user?',
															responseOptions: [
																{
																	value: 'delete',
																	label: 'Delete',
																},
															],
														});

														// handle the response from the user
														if (alertResponse === 'delete') {
															// adds the loading indicator NEXT to the specific item being deleted
															setUserBeingDeleted(userId);
															const response = await deleteUser({
																id: userId,
															});
															handleApiResponse(response, {
																success: (payload) => {
																	toast.createToast({
																		title: payload.message,
																		variant: 'success',
																	});
																},
																error: (payload) => {
																	toast.createToast({
																		title: getQueryError(payload),
																		variant: 'error',
																	});
																},
															});
															// remove the loading indicator next to the user row of user being deleted
															setUserBeingDeleted(null);
														}
													}}
												>
													Delete
												</ButtonLink>
											</div>
										) : null;
									},
								},
							]}
							data={usersData?.users ?? []}
						/>
					</div>
				)}
			</div>

			<div className='mt-8'>
				<DataTable
					title='Facility License Details'
					tooltipContent={
						'Use this table to see how licenses have been applied across your account’s facilities, locations, or departments.'
					}
					goToHelpID='facilitylicense'
					columns={[
						{
							header: 'Facility',
							accessorKey: 'name',
							cell: ({ row }) => (
								<span className='px-3 py-1 text-p3 text-center font-semibold bg-blue-50 border border-blue-500 rounded-full'>
									{row.original.name}
								</span>
							),
							enableGlobalFilter: false,
						},
						{
							header: 'License',
							accessorKey: 'license',
							cell: ({ row }) => <span className='capitalize'>{getLicenseNames(row.original.license)}</span>,
							enableGlobalFilter: false,
						},
						{
							header: 'Room Licenses',
							accessorKey: 'roomsCount',
							enableGlobalFilter: false,
						},
					]}
					rowSubview={(row) => (
						<>
							{row.original.roomsCount > 0 ? (
								<table className='border-separate border-spacing-x-10 w-full'>
									<thead>
										<tr>
											<th className='pb-4 text-left text-bluegray-900 w-0'>
												<span className='uppercase text-p3 font-semibold whitespace-nowrap'>Rooms Within {row.original.name}</span>
											</th>
											<th className='pb-4 text-left text-bluegray-900'>
												<span className='uppercase text-p3 font-semibold'>Status</span>
											</th>
										</tr>
									</thead>

									<tbody>
										{row.original.rooms.map((room, i) => (
											<tr key={i}>
												<td className='p-0 py-4 text-p2 border-b border-bluegray-100 whitespace-nowrap'>{room.name}</td>
												<td className='p-0 py-4 text-p2 border-b border-bluegray-100'>
													{room.licensed ? 'Licensed' : 'Unlicensed'}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							) : (
								<div className='text-p2 px-10'>This facility currently has no rooms.</div>
							)}
						</>
					)}
					data={facilityLicenseDetails}
				/>
			</div>
		</>
	);
}

export default AccountSettings;
