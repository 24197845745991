import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

// const cachedSystem = getFromLocalStorage('blockName');
// const cachedFacility = getFromLocalStorage('surgeon');

export interface BlockSettingState {
	selectedAction: 'add' | 'edit';
	blockName: string;
	surgeons: number[] | undefined;
}

const initialState: BlockSettingState = {
	selectedAction: 'add',
	blockName: '',
	surgeons: [],
};

/**
 * State that holds the currently selected facility and healthsystem
 */
export const blockSettingSlice = createSlice({
	name: 'blockSettingState',
	initialState,
	reducers: {
		setSelectedAction(state, action: PayloadAction<'add' | 'edit'>) {
			state.selectedAction = action.payload;
		},
		setBlockName(state, action: PayloadAction<string>) {
			state.blockName = action.payload;
			// TODO: uncomment these once we get the other block steps working
			// writeToLocalStorage('selectedFacility', action.payload);
		},
		setSurgeons(state, action: PayloadAction<number[]>) {
			state.surgeons = action.payload;
			// TODO: uncomment these once we get the other block steps working
			// writeToLocalStorage('selectedSystem', action.payload);
		},
	},
});

export const { setSelectedAction, setBlockName, setSurgeons } = blockSettingSlice.actions;

export default blockSettingSlice.reducer;
