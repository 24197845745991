import classNames from 'classnames';
import { forwardRef } from 'react';

import { Tooltip, WorkingIndicator } from 'components';

import type { InputHTMLAttributes, LabelHTMLAttributes, Ref } from 'react';

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
	/** The text label to apply to this text field. This is required for accessibility purposes, even if the label is hidden using the `hideLabel` option. */
	label: string;

	/** Whether to visually hide this text field's label from users. */
	hideLabel?: boolean;

	/** The preset width to apply to this text field. */
	sizeX?: 'sm' | 'lg' | 'full';

	/** The preset height to apply to this text field. */
	sizeY?: 'sm' | 'md' | 'lg';

	/** The name of a Material Symbol to display to the left of the user's text field input. */
	icon?: string;

	/** A string of additional classes to pass to the icon, if one is provided. This allows you to control the icon's color by passing a Tailwind color class. */
	iconClass?: string;

	/** A description of this text field's current validation error. This also applies styles to visually indicate the field contains an error. */
	errorMessage?: string;

	/** The manner in which the space occupied by an error message is handled. */
	errorMessageArea?: 'none' | 'reserved' | 'floating';

	/** Whether the field should display a symbol indicating that it is affected by an ongoing process. */
	isWorking?: boolean;

	/** Whether to visually indicate that the field contains a valid value. */
	isKnownValid?: boolean;

	/** A hint to the user on the purpose of this text field. This adds an information icon next to the field label, which can be hovered over to reveal the provided text. */
	instructions?: string;

	/** A prop object to pass to this text field's outermost `<label>` element. */
	containerProps?: LabelHTMLAttributes<HTMLLabelElement>;
}

export const TextFieldWithRef = forwardRef(function TextField(
	{
		label,
		hideLabel = false,
		sizeX = 'full',
		sizeY = 'sm',
		icon,
		iconClass,
		errorMessage,
		errorMessageArea = 'none',
		isWorking = false,
		isKnownValid = false,
		instructions,
		containerProps,
		className,
		disabled,
		readOnly,
		...props
	}: TextFieldProps,
	forwardedRef: Ref<HTMLInputElement>
) {
	const { className: containerClassName, ...otherContainerProps } = containerProps || {};

	return (
		<label className={classNames('block relative', containerClassName)} {...otherContainerProps}>
			<div
				className={classNames('mb-2', {
					hidden: hideLabel,
				})}
			>
				<span className='font-preset-label'>{label}</span>
				{instructions && (
					<Tooltip content={instructions}>
						<span className='material-symbol-[1rem] ml-2 align-bottom cursor-help'>info</span>
					</Tooltip>
				)}
			</div>

			<div className='relative'>
				{icon && (
					<div className={classNames('absolute top-0 bottom-0 left-1 grid items-center')}>
						<span
							className={classNames(
								{
									'material-symbol-sm': sizeY === 'sm',
									'material-symbol': sizeY === 'md',
									'material-symbol-lg': sizeY === 'lg',
								},
								iconClass
							)}
						>
							{icon}
						</span>
					</div>
				)}

				<input
					className={classNames(
						'block border rounded-sm font-primary border-gray-400 transition-colors',
						'focus:outline-none focus:border-blue-500',
						{
							'cursor-not-allowed': disabled,
							'bg-gray-50 text-gray-600': readOnly || disabled,
							'border-red-400': errorMessage,
							'border-green-500': isKnownValid,

							'w-[170px]': sizeX === 'sm',
							'w-[300px]': sizeX === 'lg',
							'w-full': sizeX === 'full',

							'h-8 text-i3 pr-2': sizeY === 'sm',
							'h-10 text-i2 pr-2': sizeY === 'md',
							'h-14 text-i1 pr-4': sizeY === 'lg',

							'pl-2': !icon && (sizeY === 'sm' || sizeY === 'md'),
							'pl-4': !icon && sizeY === 'lg',

							'pl-7': icon && sizeY === 'sm',
							'pl-8': icon && sizeY === 'md',
							'pl-12': icon && sizeY === 'lg',

							'pr-2': !isWorking && (sizeY === 'sm' || sizeY === 'md'),
							'pr-4': !isWorking && sizeY === 'lg',

							'pr-7': isWorking && sizeY === 'sm',
							'pr-9': isWorking && sizeY === 'md',
							'pr-12': isWorking && sizeY === 'lg',
						},
						className
					)}
					ref={forwardedRef}
					disabled={disabled}
					readOnly={readOnly}
					{...props}
				/>

				{isWorking && (
					<div
						className={classNames('absolute top-0 bottom-0 right-4 grid items-center', {
							'right-2': sizeY === 'sm' || sizeY === 'md',
							'right-4': sizeY === 'lg',
						})}
					>
						<WorkingIndicator size={sizeY === 'sm' ? 'sm' : undefined} />
					</div>
				)}
			</div>

			<p
				className={classNames('mt-2 font-primary text-p2 text-red-400', {
					'absolute top-full': errorMessageArea === 'floating',
					hidden: !errorMessage && errorMessageArea !== 'reserved',
				})}
			>
				{errorMessage}
				{!errorMessage && errorMessageArea === 'reserved' && <>&nbsp;</>}
			</p>
		</label>
	);
});

export { TextFieldWithRef as TextField, TextFieldWithRef as default };
