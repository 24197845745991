import { useEffect, useState } from 'react';
import * as Popover from '@radix-ui/react-popover';

import { Button, NumberField, TextField, Timepicker, Tooltip } from 'components';
import Dialog from 'components/Dialog';
import { useFilters } from 'context';
import { convertMinutesToMilitaryTime, days, stringTimeToDate } from 'utils/datetimes';
import { useAppSelector, useSystem } from 'store';

export interface StaffingGridProps {
	children: React.ReactNode;
}

export const defaultStaffingGrid = [
	{
		Sunday: 0,
		Monday: 0,
		Tuesday: 0,
		Wednesday: 0,
		Thursday: 0,
		Friday: 0,
		Saturday: 0,
		startMinute: 0,
		endMinute: 7 * 60,
		isPrimetime: 0,
	},
	{
		Sunday: 0,
		Monday: 0,
		Tuesday: 0,
		Wednesday: 0,
		Thursday: 0,
		Friday: 0,
		Saturday: 0,
		startMinute: 7 * 60,
		endMinute: 15 * 60,
		isPrimetime: 1,
	},
	{
		Sunday: 0,
		Monday: 0,
		Tuesday: 0,
		Wednesday: 0,
		Thursday: 0,
		Friday: 0,
		Saturday: 0,
		startMinute: 15 * 60,
		endMinute: 23 * 60 + 45,
		isPrimetime: 0,
	},
];

export interface StaffingGridItem {
	Sunday: number;
	Monday: number;
	Tuesday: number;
	Wednesday: number;
	Thursday: number;
	Friday: number;
	Saturday: number;
	startMinute: number;
	endMinute: number;
	isPrimetime: number; // any positive number is primetime, is number type for easier rendering in the UI
}

export function StaffingGrid({ children }: StaffingGridProps) {
	const dows = days;
	const { dropDowns, saveDropdown } = useFilters();
	const [working, setWorking] = useState(false); // there seems to be a delay, this makes it seem less laggy
	const [dialogOpen, setDialogOpen] = useState(false);
	const [isDirty, setIsDirty] = useState(false);
	const { data: systemData } = useSystem();
	const facilities = systemData?.facilities;
	const { selectedFacility } = useAppSelector((state) => state.userState);
	const currentFacility = facilities?.find((f) => f.id === selectedFacility);
	const [grid, setGrid] = useState<StaffingGridItem[]>(
		dropDowns?.staffing_grid ?? currentFacility?.draw_down_schedule ?? defaultStaffingGrid
	);

	const setStart = (index: number, newTime: string) => {
		// minutes from midnight
		const startMinute =
			(parseInt(newTime.split(':')[0]) === 24 ? 0 : parseInt(newTime.split(':')[0])) * 60 +
			parseInt(newTime.split(':')[1]);

		// update state
		setGrid((prevState) => {
			// Create a deep copy of the state, copying each individual object
			const newState = prevState.map((item) => ({ ...item }));
			newState[index].startMinute = startMinute; // modify the copy
			return newState; // return the modified copy
		});

		setIsDirty(true);
	};

	const setEnd = (index: number, newTime: string) => {
		// minutes from midnight
		const endMinute =
			(parseInt(newTime.split(':')[0]) === 24 ? 0 : parseInt(newTime.split(':')[0])) * 60 +
			parseInt(newTime.split(':')[1]);

		setGrid((prevState) => {
			// Create a deep copy of the state, copying each individual object
			const newState = prevState.map((item) => ({ ...item }));
			newState[index].endMinute = endMinute === 1425 ? 1440 : endMinute; // modify the copy
			return newState; // return the modified copy
		});
		setIsDirty(true);
	};

	const getMinMaxStart = (index: number) => {
		let min = 0;
		let max = 1425; // hours to minutes conversion
		let minMinute = 0;
		const maxMinute = parseInt(convertMinutesToMilitaryTime(grid[index].endMinute - 15).split(':')[1]);

		if (grid[index - 1]) {
			min = grid[index - 1].endMinute;
			// extract minute from time
			minMinute = parseInt(convertMinutesToMilitaryTime(grid[index - 1].endMinute).split(':')[1]);
		}

		if (grid[index + 1]) {
			max = grid[index + 1].startMinute;
		}

		// convert min and max to hour
		min = Math.floor(min / 60);
		max = Math.round(max / 60);

		return { min, max, minMinute, maxMinute };
	};

	const getMinMaxEnd = (index: number) => {
		let min = 0;
		let max = 1440; // hours to minutes conversion
		const minMinute = parseInt(convertMinutesToMilitaryTime(grid[index].startMinute + 15).split(':')[1]);
		let maxMinute = 0;

		if (grid[index + 1]) {
			max = grid[index + 1].startMinute;
			maxMinute = parseInt(convertMinutesToMilitaryTime(grid[index + 1].startMinute).split(':')[1]);
		}

		if (grid[index - 1]) {
			min = grid[index].startMinute;
		}

		// convert min and max to hour
		min = Math.round(min / 60);
		max = Math.floor(max / 60);

		return { min, max, minMinute, maxMinute };
	};

	// we need to manually update the state when the dropdown is saved because the component stays mounted for the whole time
	useEffect(() => {
		setGrid(dropDowns?.staffing_grid ?? defaultStaffingGrid);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dropDowns]);

	return (
		<Dialog
			actionButtons={[]}
			sizeX='lg_wide'
			title={'Anesthesia Staffing'}
			trigger={children}
			minimalHeaderStyle
			noTitle
			open={dialogOpen}
			onOpenChange={async (isBeingOpened) => {
				if (isBeingOpened) {
					setDialogOpen(true);
					setIsDirty(false);
					return;
				} else {
					setIsDirty(false);
				}
			}}
		>
			<div className='flex justify-center mt-12'>
				<div className='h-fit'>
					<div className='flex w-[68em]'>
						{grid.map((item, index) => (
							<div key={index} className='flex flex-col mb-5 bg-white'>
								{index === 0 && (
									<div className='flex flex-col justify-center text-center fixed mt-1 text-gray-400 ml-5'>
										<span className='material-symbols-outlined text-h3 m-0 h-fit'>groups</span>
										<p className='text-[0.7em] w-14'>Staffing Grid</p>
									</div>
								)}
								<p
									className={`text-[0.7em] ${
										index === 0 ? 'ml-24' : ''
									} text-gray-700 tracking-wider mb-1 font-secondary text-center`}
								>
									Time Window
								</p>
								<div
									className={`z-topMenu flex ${index === 0 ? 'ml-24' : 'ml-0'} items-baseline text-white rounded-sm pb-0 w-fit`}
								>
									<div className='flex gap-0.5 text-p3 justify-center items-center mx-2'>
										<Popover.Root>
											<Popover.Trigger asChild>
												<p className='text-blue-500 px-2 py-0.5 border border-blue-500 m-0 h-fit font-semibold rounded-md cursor-pointer hover:bg-blue-50'>
													{convertMinutesToMilitaryTime(item.startMinute)}
												</p>
											</Popover.Trigger>
											<Popover.Portal>
												<Popover.Content className='rounded-md w-fit bg-blue-50 z-[300]'>
													<div className='flex flex-col gap-2.5 border border-blue-500'>
														<Timepicker
															interval={15}
															minMinute={getMinMaxStart(index).minMinute} // special case if starting end is 00:15
															minHour={getMinMaxStart(index).min}
															maxHour={getMinMaxStart(index).max - 1}
															maxMinute={getMinMaxStart(index).maxMinute}
															onChange={(time) => {
																setStart(index, time.military);
															}}
															selected={stringTimeToDate(convertMinutesToMilitaryTime(item.startMinute))}
														/>
													</div>

													<Popover.Arrow className='fill-[#3b82f6]' />
												</Popover.Content>
											</Popover.Portal>
										</Popover.Root>

										<span className='material-symbols-outlined text-slate-500 text-[1.3em]'>trending_flat</span>
										<div className='flex justify-center items-center gap-1'>
											<div className='flex flex-col text-center'>
												<Popover.Root>
													<Popover.Trigger asChild>
														<p className='text-blue-500 px-2 py-0.5 border border-blue-500 m-0 h-fit font-semibold rounded-md cursor-pointer hover:bg-blue-50'>
															{/* // Special case to cast last 23:45 to 24:00 */}
															{convertMinutesToMilitaryTime(item.endMinute === 1425 ? 1440 : item.endMinute)}
														</p>
													</Popover.Trigger>
													<Popover.Portal>
														<Popover.Content className='rounded-md w-fit bg-blue-50 z-[300]'>
															<div className='flex flex-col gap-2.5 border border-blue-500'>
																<Timepicker
																	interval={15}
																	minHour={getMinMaxEnd(index).min}
																	minMinute={getMinMaxEnd(index).minMinute}
																	maxMinute={getMinMaxEnd(index).maxMinute}
																	maxHour={getMinMaxEnd(index).max}
																	onChange={(time) => {
																		setEnd(index, time.military);
																	}}
																	selected={stringTimeToDate(convertMinutesToMilitaryTime(item.endMinute))}
																/>
															</div>

															<Popover.Arrow className='fill-[#3b82f6]' />
														</Popover.Content>
													</Popover.Portal>
												</Popover.Root>
											</div>
											{index === grid.length - 1 ? (
												<span
													className={`material-symbols-outlined ${
														grid[index].endMinute === 1425 ? 'text-blue-500' : 'text-white'
													} text-[1.7em] w-0`}
												>
													last_page
												</span>
											) : (
												<span
													className={`material-symbols-outlined ${
														grid[index + 1] && grid[index + 1].startMinute !== item.endMinute ? 'text-red-500' : 'text-white'
													} text-[1.0em] w-0`}
												>
													link_off
												</span>
											)}
										</div>
									</div>
								</div>

								<div className='mt-8'>
									{dows.map((day, indx) => (
										<div className='flex items-baseline justify-center text-center' key={indx}>
											{index === 0 && (
												<p className='text-p3 text-gray-700 tracking-wider uppercase h-fit font-secondary w-24'>{day}</p>
											)}
											<p className={`flex mb-0 px-0 py-0 rounded-sm w-36 text-center`}>
												<TextField
													type='number'
													label=''
													hideLabel
													value={item[day as keyof StaffingGridItem] ?? 0}
													onChange={(int) => {
														let newInt = parseInt(int.currentTarget.value);

														// don't let start hour be less than 0
														if (newInt < 0) {
															newInt = 0;
														}

														// update state
														setGrid((prevState) => {
															// Create a deep copy of the state, copying each individual object
															const newState = prevState.map((item) => ({ ...item }));
															newState[index][day as keyof StaffingGridItem] = newInt; // modify the copy
															return newState; // return the modified copy
														});
														setIsDirty(true);
													}}
												/>
											</p>
										</div>
									))}
								</div>
							</div>
						))}
						<div className='flex flex-col h-100 justify-center ml-10'>
							<div className='mt-6'>
								<Button
									sizeX='square'
									sizeY='sm'
									className='mb-2'
									variant='primary-ghost'
									type='button'
									disabled={grid[grid.length - 1]?.endMinute >= 1425}
									onClick={() => {
										setGrid((prevState) => [
											...prevState,
											{
												Sunday: 0,
												Monday: 0,
												Tuesday: 0,
												Wednesday: 0,
												Thursday: 0,
												Friday: 0,
												Saturday: 0,
												startMinute: prevState[prevState.length - 1].endMinute,
												isPrimetime: 0,
												endMinute:
													prevState[prevState.length - 1].endMinute + 60 >= 1425
														? 1440
														: prevState[prevState.length - 1].endMinute + 60,
											},
										]);
										setIsDirty(true);
									}}
								>
									<span className='material-symbols-outlined'>add</span>
								</Button>
								<Button
									variant='secondary-ghost'
									sizeX='square'
									sizeY='sm'
									type='button'
									className='mb-2'
									disabled={grid.length < 2}
									onClick={() => {
										setGrid((prevState) => prevState.slice(0, -1));
										setIsDirty(true);
									}}
								>
									<span className='material-symbols-outlined'>remove</span>
								</Button>

								<div className='flex items-center'>
									<p
										className='text-gray-500 text-p3 cursor-pointer underline whitespace-nowrap'
										onClick={() => {
											setGrid(currentFacility?.draw_down_schedule ?? defaultStaffingGrid);
											setIsDirty(true);
										}}
									>
										Reset
									</p>
									<Tooltip
										content={
											'The default values in the custom grid are based on the drawdown schedule provided in the Facility Settings page. To make changes to the default values please adjust the drawdown schedule in the Facility Settings page'
										}
									>
										<div className='material-symbol-sm text-gray-500 pl-1'>info</div>
									</Tooltip>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='flex justify-end py-4 mx-4 pt-7'>
				<Button
					variant='secondary'
					className='mr-3'
					sizeX='sm'
					sizeY='sm'
					onClick={() => {
						setDialogOpen(false);
						return;
					}}
				>
					Cancel
				</Button>
				<Button
					sizeX='sm'
					sizeY='sm'
					disabled={!isDirty || (grid.at(-1)?.endMinute ?? 0) < 1425}
					onClick={() => {
						setWorking(true);

						// save staffing grid to drop downs filter
						dropDowns.update({ ...dropDowns, staffing_grid: grid });
						saveDropdown(
							{
								...dropDowns,
								staffing_grid: grid,
							},
							'Anesthesia'
						);

						// close after state updates
						setTimeout(() => {
							setWorking(false);
							setDialogOpen(false);
						}, 1000);
					}}
					isWorking={working}
				>
					Save
				</Button>
			</div>
		</Dialog>
	);
}

export default StaffingGrid;
