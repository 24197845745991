import * as RadixSlider from '@radix-ui/react-slider';
import { forwardRef, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { clamp, useCombinedRef } from 'utils';
import { Tooltip, WorkingIndicator } from 'components';

import type { InputHTMLAttributes, LabelHTMLAttributes, Ref } from 'react';

export interface NumberFieldProps extends InputHTMLAttributes<HTMLInputElement> {
	/** The text label to apply to this number field. This is required for accessibility purposes, even if the label is hidden using the `hideLabel` option. */
	label: string;

	/** Whether to visually hide this number field's label from users. */
	hideLabel?: boolean;

	/** The preset width to apply to this number field. */
	sizeX?: 'sm' | 'lg' | 'full';

	/** The preset height to apply to this number field. */
	sizeY?: 'sm' | 'md' | 'lg';

	/** The name of a Material Symbol to display to the left of the user's number field input. */
	icon?: string;

	/** A string of additional classes to pass to the icon, if one is provided. This allows you to control the icon's color by passing a Tailwind color class. */
	iconClass?: string;

	/** A description of this number field's current validation error. This also applies styles to visually indicate the field contains an error. */
	errorMessage?: string;

	/** The manner in which the space occupied by an error message is handled. */
	errorMessageArea?: 'none' | 'reserved' | 'floating';

	/** Whether the field should display a symbol indicating that it is affected by an ongoing process. */
	isWorking?: boolean;

	/** Whether to visually indicate that the field contains a valid value. */
	isKnownValid?: boolean;

	/** A hint to the user on the purpose of this number field. This adds an information icon next to the field label, which can be hovered over to reveal the provided text. */
	instructions?: string;

	/** Whether to display a companion range input beneath this number field. Also requires that the `min` and `max` attributes be provided. */
	showRangeInput?: boolean;

	/** A prop object to pass to this number field's outermost `<label>` element. */
	containerProps?: LabelHTMLAttributes<HTMLLabelElement>;
}

export const NumberFieldWithRef = forwardRef(function NumberField(
	{
		label,
		hideLabel = false,
		sizeX = 'full',
		sizeY = 'sm',
		icon,
		iconClass,
		errorMessage,
		errorMessageArea = 'none',
		isWorking = false,
		isKnownValid = false,
		instructions,
		showRangeInput = true,
		containerProps,
		className,
		disabled,
		readOnly,
		type,
		defaultValue = 0,
		value,
		onChange,
		...props
	}: NumberFieldProps,
	forwardedRef: Ref<HTMLInputElement>
) {
	const [numberValue, setNumberValue] = useState(value ?? defaultValue);
	const [internalError, setInternalError] = useState('');
	const inputEl = useRef<HTMLInputElement>(null);

	const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNumberValue(e.currentTarget.value);

		// Merge in any user-provided `onChange` functionality.
		if (typeof onChange === 'function') {
			onChange(e);
		}
	};

	// Update the internal state to match any externally-set value on change.
	useEffect(() => {
		if (typeof value !== 'string' && typeof value !== 'number') return;
		setNumberValue(Number(value));
	}, [value]);

	useEffect(() => {
		setInternalError(inputEl.current?.validationMessage ?? '');
	}, [numberValue]);

	const { className: containerClassName, ...otherContainerProps } = containerProps || {};

	return (
		<label className={classNames('block relative', containerClassName)} {...otherContainerProps}>
			<div
				className={classNames('mb-2', {
					hidden: hideLabel,
				})}
			>
				<span className='font-preset-label'>{label}</span>
				{instructions && (
					<Tooltip content={instructions}>
						<span className='material-symbol-[1rem] ml-2 align-bottom cursor-help'>info</span>
					</Tooltip>
				)}
			</div>

			<div className='relative'>
				{icon && (
					<div className={classNames('absolute top-0 bottom-0 left-1 grid items-center')}>
						<span
							className={classNames(
								{
									'material-symbol-sm': sizeY === 'sm',
									'material-symbol': sizeY === 'md',
									'material-symbol-lg': sizeY === 'lg',
								},
								iconClass
							)}
						>
							{icon}
						</span>
					</div>
				)}

				<input
					type='number'
					className={classNames(
						`block border rounded-sm font-primary border-gray-400 transition-colors ${className}`,
						'focus:outline-none focus:border-blue-500',
						'invalid:border-red-400',
						{
							'cursor-not-allowed': disabled,
							'bg-gray-50 text-gray-600': readOnly || disabled,
							'border-red-400': errorMessage || internalError,
							'border-green-500': isKnownValid,

							'w-[170px]': sizeX === 'sm',
							'w-[300px]': sizeX === 'lg',
							'w-full': sizeX === 'full',

							'h-8 text-i3 pr-2': sizeY === 'sm',
							'h-10 text-i2 pr-2': sizeY === 'md',
							'h-14 text-i1 pr-4': sizeY === 'lg',

							'pl-2': !icon && (sizeY === 'sm' || sizeY === 'md'),
							'pl-4': !icon && sizeY === 'lg',

							'pl-7': icon && sizeY === 'sm',
							'pl-8': icon && sizeY === 'md',
							'pl-12': icon && sizeY === 'lg',

							'pr-2': !isWorking && (sizeY === 'sm' || sizeY === 'md'),
							'pr-4': !isWorking && sizeY === 'lg',

							'pr-7': isWorking && sizeY === 'sm',
							'pr-9': isWorking && sizeY === 'md',
							'pr-12': isWorking && sizeY === 'lg',
						},
						className
					)}
					ref={useCombinedRef(inputEl, forwardedRef)}
					disabled={disabled}
					readOnly={readOnly}
					value={numberValue}
					onChange={handleNumberChange}
					{...props}
				/>

				{isWorking && (
					<div
						className={classNames('absolute top-0 bottom-0 right-4 grid items-center', {
							'right-2': sizeY === 'sm' || sizeY === 'md',
							'right-4': sizeY === 'lg',
						})}
					>
						<WorkingIndicator size={sizeY === 'sm' ? 'sm' : undefined} />
					</div>
				)}
			</div>

			{typeof props.min !== 'undefined' && typeof props.max !== 'undefined' && showRangeInput && (
				<RadixSlider.Root
					value={[clamp(+numberValue, +props.min, +props.max)]}
					onValueChange={(value) => {
						setNumberValue(value[0]);

						if (inputEl.current) {
							// Use the number input's native value setter to perform an
							// update, triggering its default `onChange` handling.
							const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;
							nativeInputValueSetter?.call(inputEl.current, value[0]);
							inputEl.current.dispatchEvent(new Event('input', { bubbles: true }));
						}
					}}
					min={+props.min}
					max={+props.max}
					step={+(props.step ?? 1)}
					minStepsBetweenThumbs={0.1}
					disabled={disabled}
					className={classNames(
						'relative flex items-center mt-3 w-full h-4',
						'data-disabled:cursor-not-allowed data-disabled:opacity-30'
					)}
				>
					<RadixSlider.Track className='relative grow h-1 bg-gray-100 rounded-full'>
						<RadixSlider.Range className='absolute h-full bg-blue-500 rounded-full' />
					</RadixSlider.Track>

					<RadixSlider.Thumb
						className={classNames(
							'block bg-blue-500 h-4 w-4 rounded-full',
							'focus:outline-none focus:outline-offset-1',
							'focus-visible:outline focus-visible:outline-2 focus-visible:outline-blue-400'
						)}
					/>
				</RadixSlider.Root>
			)}

			<p
				className={classNames('mt-2 font-primary text-p2 text-red-400', errorMessageArea === 'none' && 'hidden', {
					'absolute top-full': errorMessageArea === 'floating',
					hidden: !errorMessage && !internalError && errorMessageArea !== 'reserved',
				})}
			>
				{errorMessage || internalError}
				{!errorMessage && !internalError && errorMessageArea === 'reserved' && <>&nbsp;</>}
			</p>
		</label>
	);
});

export { NumberFieldWithRef as NumberField, NumberFieldWithRef as default };
