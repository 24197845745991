import { ApiEndpoints } from 'models/api';
import { standardApi } from 'store/api';
export interface BlockDashboardResponse {
	aggregate_block_utilization: { x: string; y: number }[];
	on_target_blocks: {
		previous_month: number;
		current_month: number;
	};
	number_of_blocks: {
		previous_month: number;
		current_month: number;
	};
	utilization_target_percent: number;
	latest_record_date: Date;
}
/**
 * Provides endpoints for retrieving block
 */
export const blockDashboardApi = standardApi.injectEndpoints({
	endpoints: (build) => ({
		getBlockDashboard: build.query<
			BlockDashboardResponse,
			{
				month: number;
				facility_id: number | null;
				healthsystem_id: number | null;
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_BLOCK_DASHBOARD,
				method: 'POST',
				body,
			}),
		}),
	}),
});
export const { useGetBlockDashboardQuery } = blockDashboardApi;
export const {
	endpoints: { getBlockDashboard },
} = blockDashboardApi;
