/**
 * Calculates the percentage that the "part" makes up of the "total".
 * @param total the total to consider for the percentage
 * @param part the part of the total to be converted to percentage
 * @returns number
 */
export function getPercentage(total: number, part: number) {
	return (part * 100) / total;
}

/**
 * Rounds value to the given number of decimals
 * @param num unrounded number
 * @param precision how many digits after the decimal
 * @returns number
 */
export function toFixed(num: number, precision: number) {
	return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
}

export const intersects = (a: any[], b: any[]) => a.some((x) => b.includes(x));
