import { useEffect, useState } from 'react';

import { Facility, FacilityLicense, HolidayItem, UserRole } from 'models';
import { FacilitySettingsAccordionItem } from 'pages/Settings/Tabs';
import { checkLicenseBits, userIs } from 'utils';
import { useSetFacilitySettingMutation, useSystem } from 'store';
import { Accordion, WorkingIndicator } from 'components';
import { defaultStaffingGrid } from 'pages/Anesthesia/components';

export function FacilitySettings() {
	// automatically gets current "selected" system information
	const { data, isFetching } = useSystem();
	const isAdministrator = userIs([UserRole.admin, UserRole.healthsystem_admin], data?.user);
	const [rebuildingArtificial, setRebuildingArtificial] = useState<boolean | number>(false);
	const [customHolidays, setCustomHolidays] = useState<HolidayItem[]>([]);
	const [setFacilitySetting] = useSetFacilitySettingMutation();

	// This is required to unmount the accordion when fetching new artificial facilities
	useEffect(() => {
		if (!isFetching) {
			setRebuildingArtificial(false);
		}
	}, [isFetching]);

	// Add a custom holiday to all facilities in the health system
	const appendCustomHolidaystoAllFacilities = async () => {
		const facilities = data?.facilities;
		if (facilities) {
			facilities.forEach(async (facility) => {
				const payload = {
					holiday_list_append: true,
					facility_id: facility?.id,
					fcots_target: facility?.fcots_target,
					addon_rate_target: facility?.addon_rate_target,
					draw_down_schedule: facility?.draw_down_schedule ?? defaultStaffingGrid,
					facility_utilization_target: facility?.facility_utilization_target,
					turnover_target: facility?.turnover_target,
					fcots_timestamp: facility?.fcots_timestamp,
					fcots_window_time_start: facility?.fcots_window_time_start,
					fcots_window_time_end: facility?.fcots_window_time_end,
					sunday_rooms_count: facility?.sunday_rooms_count,
					monday_rooms_count: facility?.monday_rooms_count,
					tuesday_rooms_count: facility?.tuesday_rooms_count,
					wednesday_rooms_count: facility?.wednesday_rooms_count,
					thursday_rooms_count: facility?.thursday_rooms_count,
					friday_rooms_count: facility?.friday_rooms_count,
					saturday_rooms_count: facility?.saturday_rooms_count,
					sunday_prime_time_start: facility?.sunday_prime_time_start,
					sunday_prime_time_end: facility?.sunday_prime_time_end,
					monday_prime_time_start: facility?.monday_prime_time_start,
					monday_prime_time_end: facility?.monday_prime_time_end,
					tuesday_prime_time_start: facility?.tuesday_prime_time_start,
					tuesday_prime_time_end: facility?.tuesday_prime_time_end,
					wednesday_prime_time_start: facility?.wednesday_prime_time_start,
					wednesday_prime_time_end: facility?.wednesday_prime_time_end,
					thursday_prime_time_start: facility?.thursday_prime_time_start,
					thursday_prime_time_end: facility?.thursday_prime_time_end,
					friday_prime_time_start: facility?.friday_prime_time_start,
					friday_prime_time_end: facility?.friday_prime_time_end,
					saturday_prime_time_start: facility?.saturday_prime_time_start,
					saturday_prime_time_end: facility?.saturday_prime_time_end,
					is_primetime_strict: !!facility?.is_primetime_strict,
					holidays_included: facility?.holidays_included.concat(customHolidays),
					procedure_filtering_options: facility?.procedure_filtering_options ?? [],
				};

				await setFacilitySetting(payload);
			});
		}
	};

	// Add a custom holiday to all facilities in the health system
	const deleteCustomHolidaysFromAllFacilities = async (holidays_to_remove: (number | undefined)[]) => {
		const facilities = data?.facilities;
		if (facilities) {
			facilities.forEach(async (facility) => {
				const payload = {
					holiday_list_append: true,
					facility_id: facility?.id,
					fcots_target: facility?.fcots_target,
					addon_rate_target: facility?.addon_rate_target,
					draw_down_schedule: facility?.draw_down_schedule ?? defaultStaffingGrid,
					facility_utilization_target: facility?.facility_utilization_target,
					turnover_target: facility?.turnover_target,
					fcots_timestamp: facility?.fcots_timestamp,
					fcots_window_time_start: facility?.fcots_window_time_start,
					fcots_window_time_end: facility?.fcots_window_time_end,
					sunday_rooms_count: facility?.sunday_rooms_count,
					monday_rooms_count: facility?.monday_rooms_count,
					tuesday_rooms_count: facility?.tuesday_rooms_count,
					wednesday_rooms_count: facility?.wednesday_rooms_count,
					thursday_rooms_count: facility?.thursday_rooms_count,
					friday_rooms_count: facility?.friday_rooms_count,
					saturday_rooms_count: facility?.saturday_rooms_count,
					sunday_prime_time_start: facility?.sunday_prime_time_start,
					sunday_prime_time_end: facility?.sunday_prime_time_end,
					monday_prime_time_start: facility?.monday_prime_time_start,
					monday_prime_time_end: facility?.monday_prime_time_end,
					tuesday_prime_time_start: facility?.tuesday_prime_time_start,
					tuesday_prime_time_end: facility?.tuesday_prime_time_end,
					wednesday_prime_time_start: facility?.wednesday_prime_time_start,
					wednesday_prime_time_end: facility?.wednesday_prime_time_end,
					thursday_prime_time_start: facility?.thursday_prime_time_start,
					thursday_prime_time_end: facility?.thursday_prime_time_end,
					friday_prime_time_start: facility?.friday_prime_time_start,
					friday_prime_time_end: facility?.friday_prime_time_end,
					saturday_prime_time_start: facility?.saturday_prime_time_start,
					saturday_prime_time_end: facility?.saturday_prime_time_end,
					is_primetime_strict: !!facility?.is_primetime_strict,
					holidays_included: facility?.holidays_included.filter((holiday) => !holidays_to_remove?.includes(holiday.id)),
					procedure_filtering_options: facility?.procedure_filtering_options ?? [],
				};

				await setFacilitySetting(payload);
			});
		}
	};

	return (
		<div>
			{data?.facilities?.map((facility: Facility, index: number) => {
				if (rebuildingArtificial !== false) {
					if (index !== rebuildingArtificial) {
						return (
							<FacilitySettingsAccordionItem
								key={index}
								facility={facility}
								healthsystem_custom_holidays={customHolidays}
								set_healthsystem_custom_holidays={setCustomHolidays}
								update_holidays_all_facilities={appendCustomHolidaystoAllFacilities}
								delete_holidays_all_facilities={deleteCustomHolidaysFromAllFacilities}
								readonly={!isAdministrator}
								fetching={isFetching}
								rebuildingArtificial={() => setRebuildingArtificial(index)}
							/>
						);
					} else {
						return (
							<Accordion type='multiple' itemStyle='yellow' className='mb-2'>
								<Accordion.Item value={`[Rebuilding] ${facility.name}`}></Accordion.Item>
							</Accordion>
						);
					}
				} else {
					return (
						<FacilitySettingsAccordionItem
							key={index}
							facility={facility}
							healthsystem_custom_holidays={customHolidays}
							set_healthsystem_custom_holidays={setCustomHolidays}
							update_holidays_all_facilities={appendCustomHolidaystoAllFacilities}
							readonly={!isAdministrator}
							fetching={isFetching}
							rebuildingArtificial={() => setRebuildingArtificial(index)}
						/>
					);
				}
			})}
		</div>
	);
}

export default FacilitySettings;
