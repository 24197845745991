import * as yup from 'yup';

import { doesPasswordContainPartOfEmail } from 'utils/authentication';

/**
 * Okta error-mappings - a simple structure that defines which error message we would like to show when receiving the error code from Okta.
 */
export const OktaErrorDescriptions: Record<string, string> = {
	// See https://developer.okta.com/docs/reference/error-codes/ for a list of Okta error codes.
	E0000004: 'Incorrect username or password. Please try again.',
	E0000005:
		'Something went wrong during your session. Please try again. If the issue persists, please end your session by logging out, and log back into the application to try again.',
	E0000006: 'You do not have permission to perform the requested action.',
	E0000011:
		'Your session token is invalid. Please try again. If the issue persists, please end your session by logging out, and log back into the application to try again.',
	E0000017: 'An error occurred while attempting to send the email to reset your password. Please try again.',
	generic: 'An error occurred. Please try again.',
};

/**
 * Password data structures and rules
 */
export const passwordRule = {
	password: yup
		.string()
		.required('Password is required')
		.min(8, 'Password must be at least 8 characters long')
		.matches(/[a-z]/, 'Password must contain at least 1 lower case letter')
		.matches(/[A-Z]/, 'Password must contain at least 1 upper case letter')
		.matches(/[0-9]/, 'Password must contain at least 1 number')
		.test('match', 'Passwords cannot contain part of email address', function () {
			return doesPasswordContainPartOfEmail(this.parent.password, this.parent.email, 4);
		}),
};

export const confirmPasswordRule = {
	confirmPassword: yup
		.string()
		.required('Passwords must match')
		.test('match', 'Passwords must match', function () {
			return this.parent.password === this.parent.confirmPassword;
		}),
};

export interface PasswordRule {
	text: string;
	test: (val: string, email?: string) => boolean;
}

export const passwordChecklistItems: PasswordRule[] = [
	{
		text: 'Minimum 8 characters',
		test: (value: string) => (value && value.length >= 8) as boolean,
	},
	{
		text: 'One uppercase character',
		test: (value: string) => /[A-Z]/.test(value),
	},
	{
		text: 'One lowercase character',
		test: (value: string) => /[a-z]/.test(value),
	},
	{
		text: 'One number',
		test: (value: string) => /[0-9]/.test(value),
	},
	{
		text: 'Cannot contain part of your email address',
		test: (value: string, email: string | undefined) => doesPasswordContainPartOfEmail(value, email, 4),
	},
];

/**
 * Login Page form structures
 */
export const loginFormSchema = yup.object({
	email: yup.string().email('Please enter a valid email address.').required('Please enter your email address.'),
	password: yup.string().required('Please enter your password.'),
});

export type LoginFormInputs = yup.InferType<typeof loginFormSchema>;

export const defaultLoginFormInputValues = {
	email: '',
	password: '',
};

/**
 * Register Page form structures
 */
export const registerFormSchema = yup.object({
	firstName: yup.string().required('Please enter your first name'),
	lastName: yup.string().required('Please enter your last name'),
	email: yup.string().email('Please enter a valid email address').required('Please enter your email address'),
	...passwordRule,
	...confirmPasswordRule,
	terms: yup.mixed().oneOf(['terms'], 'Please accept the terms and conditions'),
});

export type RegisterFormInputs = yup.InferType<typeof registerFormSchema>;

export const defaultRegisterFormInputValues = {
	firstName: '',
	lastName: '',
	email: '',
	password: '',
	confirmPassword: '',
	terms: '',
};

/**
 * Forgot password form structures
 */
export const requestResetPasswordFormSchema = yup.object({
	email: yup.string().email('Please enter a valid email address.').required('Please enter your email address.'),
});

export type RequestResetPasswordFormInputs = yup.InferType<typeof requestResetPasswordFormSchema>;

export const defaultRequestResetPasswordFormInputValues = {
	email: '',
};

export const resetPasswordFormSchema = yup.object({
	...passwordRule,
});

export type ResetPasswordFormInputs = yup.InferType<typeof resetPasswordFormSchema>;

export const defaultResetPasswordFormInputValues = {
	password: '',
};
