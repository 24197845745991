import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button, MultiSelect, TextField, ToggleGroup } from 'components';
import {
	getQueryError,
	handleApiResponse,
	setBlockName,
	setSurgeons,
	useAppDispatch,
	useAppSelector,
	useGetBlockExistsMutation,
	useGetSurgeonsQuery,
} from 'store';
import { useToast } from 'context';

import type { SubmitHandler } from 'react-hook-form';

/**
 * Block settings form structures
 */
const blockSettingsFormSchema = yup.object({
	name: yup.string().required('Please enter a valid block name.'),
	surgeons: yup.array().min(1, 'Please select at least one surgeon to be a part of this block.'),
});

type BlockSettingsFormInputs = yup.InferType<typeof blockSettingsFormSchema>;

const defaultBlockSettingsFormInputValues = {
	name: '',
	surgeons: [],
};

export function BlockStepOne() {
	const toast = useToast();
	const dispatch = useAppDispatch();
	const { selectedSystem } = useAppSelector((state) => state.userState);
	const { data } = useGetSurgeonsQuery({
		healthsystem_id: selectedSystem,
	});
	const [checkBlockExists] = useGetBlockExistsMutation();

	// form state
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<BlockSettingsFormInputs>({ resolver: yupResolver(blockSettingsFormSchema) });

	const onSubmit: SubmitHandler<BlockSettingsFormInputs> = async (formData) => {
		const surgeonList = formData.surgeons?.map((surgeon) => parseInt(surgeon.value) as number) ?? [];
		if (selectedSystem && surgeonList) {
			const response = await checkBlockExists({
				healthsystem_id: selectedSystem,
				block_name: formData.name,
				surgeons: surgeonList,
			});
			handleApiResponse(response, {
				success: (payload) => {
					if (payload.name_error) {
						toast.createToast({
							title: `${payload.name_error}`,
							variant: 'error',
						});
					}
					dispatch(setBlockName(formData.name));
					dispatch(setSurgeons(surgeonList ?? []));
					// move to step 2 here
				},
				error: (payload) => {
					toast.createToast({
						title: `${getQueryError(payload)}`,
						variant: 'error',
					});
				},
			});
		}
	};

	return (
		<>
			<h3 className='text-h5 font-secondary font-semibold'>Add New Block - Step 1</h3>
			<div className='flex my-8 items-center'>
				<div className='w-96 pr-12'>
					<p className='font-bold mb-2 uppercase'>Action</p>
					<p className='text-gray-400 italic'>
						Use this option to determine which action you would like to take to edit the block schedule.
					</p>
				</div>
				<div className='min-w-[275px]'>
					<ToggleGroup
						label='Add or Edit Block'
						hideLabel
						options={[
							{ label: 'Add Block', value: 'on' },
							{ label: 'Edit Block', value: 'off' },
						]}
					/>
				</div>
			</div>
			<form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
				<div className='flex my-8 items-center'>
					<div className='w-96 pr-12'>
						<p className='font-bold mb-2 uppercase'>Block Name</p>
						<p className='text-gray-400 italic'>Choose the name for the block you would like to create.</p>
					</div>
					<div className='min-w-[275px]'>
						<TextField
							label='Block Name'
							defaultValue={defaultBlockSettingsFormInputValues.name}
							// show error message if this field is empty or if the block name already exists within the selected healthsystem
							errorMessage={errors.name?.message}
							{...register('name')}
						/>
					</div>
				</div>
				<div className='flex my-8 items-center'>
					<div className='w-96 pr-12'>
						<p className='font-bold mb-2 uppercase'>Block Members</p>
						<p className='text-gray-400 italic'>
							Choose the surgeons to be included in this block. If you chose an existing block, you cannot modify the block
							members on this page. Use the Edit Block flow to change group membership.
						</p>
					</div>
					<div className='min-w-[275px]'>
						{data?.surgeons && (
							<Controller
								control={control}
								name='surgeons'
								defaultValue={defaultBlockSettingsFormInputValues.surgeons}
								render={({ field }) => (
									<MultiSelect
										reactSelectProps={{ isClearable: true }}
										label='Surgeon Name'
										{...field}
										options={(data?.surgeons ?? []).map((surgeon) => ({
											value: surgeon.id,
											label: surgeon.name,
										}))}
										// We had issues getting the message property to not throw an error.
										// This Github thread explains a potential fix while the react-hook-form library is addressing the issue in a future release:
										// https://github.com/react-hook-form/react-hook-form/issues/987
										// open issue for reference: https://github.com/react-hook-form/react-hook-form/issues/8036#issuecomment-1070966732
										// eslint-disable-next-line
										errorMessage={(errors.surgeons as any)?.message}
									/>
								)}
							/>
						)}
					</div>
				</div>
				<div className='flex justify-end'>
					<Button data-testid='block-step-1-submit' sizeX='sm' sizeY='sm' variant='primary'>
						Next
					</Button>
				</div>
			</form>
		</>
	);
}
