import { useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';

import { clone, toMilitaryTime, toStandardTime } from 'utils';

export interface TimepickerProps {
	/** The current selected time for the timepicker to reflect. */
	selected?: Date;

	/** The interval between each selection in the dropdown */
	interval?: 5 | 15 | 30 | 60;

	/** The minimum hour that can be selected in the time dropdown */
	minHour?: number;

	/** The minimum minute that can be selected in the time dropdown */
	minMinute?: number;

	/** The maximum minute that can be selected in the time dropdown */
	maxMinute?: number;

	/** The maximum hour that can be selected in the time dropdown */
	maxHour?: number;

	/** The editable state of the component */
	disabled?: boolean;

	/** The handler that is called when a time is chosen and valid (within the min/max) */
	onChange?: (d: { date: Date; standard: string; military: string }) => void;
}

export function Timepicker({
	interval = 60,
	minHour = 0,
	maxHour = 24,
	selected = new Date(),
	onChange,
	disabled,
	minMinute,
	maxMinute,
}: TimepickerProps) {
	// since this is a date-agnostic picker, treat all "dates" in this component as if it were happening "today"
	// this simplifies keeping track of the "times" selected by the user, comparisons always happen on the same "Date" from the language's perspective
	const today = new Date();
	const minTime = clone(today);
	minTime.setHours(minHour);
	minTime.setSeconds(0);
	minTime.setMinutes(minMinute ?? 0);
	minTime.setMilliseconds(0);
	const maxTime = clone(today);
	maxTime.setHours(maxHour);
	maxTime.setSeconds(0);
	maxTime.setMinutes(maxMinute ?? 0);
	maxTime.setMilliseconds(0);

	if (minHour < 0 || minHour > 22) {
		minHour = 0;
	}

	// maxHour always must be > minHour
	if (maxHour <= minHour) {
		if (minHour !== 24) {
			maxHour = minHour + 1;
		} else {
			maxHour = minHour;
		}
	} else if (maxHour > 24) {
		maxHour = 24;
	}

	// set initial state
	const [selectedTime, setSelectedTime] = useState<Date | null>(autoCorrectTimeByInterval(selected, interval));

	// handler for choosing a new time in the dropdown
	const handleChooseTime = useCallback(
		(d: Date | null) => {
			if (d) {
				d = autoCorrectTimeByInterval(d, interval);
				if (d.getTime() < minTime.getTime()) {
					d = minTime;
				}
				if (d.getTime() > maxTime.getTime()) {
					d = maxTime;
				}
				setSelectedTime(d);
				if (onChange) {
					onChange({
						date: d,
						standard: toStandardTime(d),
						military: toMilitaryTime(d),
					});
				}
			}
		},
		[interval, maxTime, minTime, onChange, setSelectedTime]
	);

	// filter away the times outside of the minTime and maxTime range
	const filterTimeOutOfRange = useCallback(
		(time: Date) => {
			return time.getTime() >= minTime.getTime() && time.getTime() <= maxTime.getTime();
		},
		[minTime, maxTime]
	);

	return (
		<div className='timepicker flex flex-col'>
			{
				<>
					<DatePicker
						filterTime={filterTimeOutOfRange}
						dateFormat='HH:mm'
						selected={selectedTime}
						showTimeSelect
						showTimeSelectOnly
						timeCaption='Time'
						timeIntervals={interval}
						timeFormat='HH:mm'
						onChange={handleChooseTime}
						disabled={disabled}
					/>
				</>
			}
		</div>
	);
}

/**
 * Corrects a date to be rounded to the nearest valid interval value
 * @param d date to be rounded
 * @param interval the interval selected
 * @returns Date
 */
function autoCorrectTimeByInterval(d: Date, interval: 5 | 15 | 30 | 60) {
	const minutes = interval;
	const ms = 1000 * 60 * minutes;
	return new Date(Math.round(d.getTime() / ms) * ms);
}

export default Timepicker;
