import * as Tabs from '@radix-ui/react-tabs';
import { useEffect, useState } from 'react';
import classNames from 'classnames';

import {
	BlockScheduleItem,
	BlockScheduleRoom,
	ReleaseLogItem,
	useGetBlockScheduleQuery,
	useGetVoluntaryReleaseLogQuery,
} from 'store/services/BlockScorecardService';
import { Button, Checkbox, DataTable, Dialog, Select, Timepicker, Tooltip, WorkingIndicator } from 'components';
import { shortDOWToNumber, stringTimeToDate, toFullDate } from 'utils';
import { ShortOption, useAppSelector, useSystem } from 'store';
import { useAlert } from 'context';

export interface ReleaseDialogProps {
	children: React.ReactNode;
	selectedBlock: ShortOption;
	blockOptions: ShortOption[];
	facility_id: number | null;
	healthsystem_id: number | null;
	selectableBlocks?: boolean;
	requestRelease: (release: BlockScheduleItem[]) => void;
	requestUndoRelease: (release: ReleaseLogItem[]) => void;
	filters: {
		days_of_week: string[];
		utilization_type: string;
		add_ons: string;
		turnover_threshold: number;
		start_date: string;
		end_date: string;
	};
}

export const tabTriggerClassname = classNames(
	'py-2 px-6 relative padding-x-3 text-bluegray-300',
	'data-active:text-blue-500 data-active:border-b-2 data-active:border-blue-500 data-active:top-[1px]'
);
export const tabContentClassname = 'pt-4';

export function ReleaseDialog({
	children,
	selectedBlock,
	blockOptions,
	facility_id,
	healthsystem_id,
	filters,
	requestRelease,
	requestUndoRelease,
	selectableBlocks,
}: ReleaseDialogProps) {
	const { getAlertResponse } = useAlert();
	const [dialogOpen, setDialogOpen] = useState(false);
	const [isDirty, setIsDirty] = useState(false);
	const [showPrevBlocks, setShowPrevBlocks] = useState(false);
	const [showFlipBlocks, setShowFlipBlocks] = useState(false);
	const [releasePayload, setReleasePayload] = useState<BlockScheduleItem[]>([]);
	const [undoReleasePayload, setUndoReleasePayload] = useState<ReleaseLogItem[]>([]);
	const [currentBlock, setCurrentBlock] = useState(selectedBlock);
	const [selectedTab, setSelectedTab] = useState('block_release');
	const [remoteLoading, setRemoteLoading] = useState(false);
	const { data: systemData } = useSystem();
	const facilities = systemData?.facilities;
	const { selectedFacility } = useAppSelector((state) => state.userState);
	const facility = facilities?.find((f) => f.id === selectedFacility);
	const allowPartialRelease = facility?.allow_partial_release ? facility?.allow_partial_release : false;

	useEffect(() => {
		setCurrentBlock(selectedBlock);
	}, [selectedBlock]);

	const { data, isFetching } = useGetBlockScheduleQuery({
		facility_id: facility_id,
		healthsystem_id: healthsystem_id,
		block: currentBlock,
		previous_blocks: showPrevBlocks,
		flip_room_blocks: showFlipBlocks,
		filters: filters,
	});

	const { data: releaseLogData, isFetching: isFetchingReleaseLog } = useGetVoluntaryReleaseLogQuery({
		facility_id: facility_id,
		healthsystem_id: healthsystem_id,
		block: currentBlock,
	});

	useEffect(() => {
		setRemoteLoading(false);
	}, [isFetchingReleaseLog, isFetching]);

	return (
		<Dialog
			actionButtons={[]}
			minimalHeaderStyle
			sizeX='lg_wide'
			title='Voluntary Release'
			trigger={children}
			open={dialogOpen}
			onOpenChange={async (isBeingOpened) => {
				if (isBeingOpened) {
					setDialogOpen(true);
					return;
				}

				const alertResponse = await getAlertResponse({
					description:
						"Closing the window will clear any of the information you've entered in the form, are you sure you'd like to continue?",
					responseOptions: [
						{
							value: 'yes',
							label: 'Yes, close',
						},
					],
				});

				if (alertResponse === 'yes') {
					setDialogOpen(false);
					setSelectedTab('block_release');
				}
			}}
		>
			<Tabs.Root
				defaultValue='block_release'
				orientation='vertical'
				onValueChange={(e) => {
					setSelectedTab(e);
					setIsDirty(false);
				}}
			>
				<Tabs.List aria-label='Settings Tabs' className='flex border-b border-bluegray-100 pl-7 pr-7'>
					<Tabs.Trigger value='block_release' className={tabTriggerClassname}>
						Block Schedule
					</Tabs.Trigger>
					<Tabs.Trigger value='release_log' className={tabTriggerClassname}>
						Release Log
					</Tabs.Trigger>
				</Tabs.List>
				<Tabs.Content value='block_release' className={tabContentClassname}>
					{isFetching || remoteLoading ? (
						<div className='w-full flex justify-center p-36'>
							<WorkingIndicator size='lg' />
						</div>
					) : (
						<>
							<BlockScheduleTable
								allowPartialRelease={allowPartialRelease}
								data={data?.blocks ?? []}
								selectedBlock={currentBlock}
								blockOptions={blockOptions}
								selectableBlocks={selectableBlocks ?? false}
								onDataChange={(data) => {
									if (data.length > 0) {
										setIsDirty(true);
									} else {
										setIsDirty(false);
									}
									setReleasePayload(data);
								}}
								setFlip={(bool) => {
									setShowFlipBlocks(bool);
									setIsDirty(false);
								}}
								setPrev={(bool) => {
									setShowPrevBlocks(bool);
									setIsDirty(false);
								}}
								defaultFlip={showFlipBlocks}
								defaultPrev={showPrevBlocks}
								onBlockChange={(newBlock) => {
									setCurrentBlock(newBlock);
								}}
							/>
							<div className='overflow-x-auto w-full bg-blue-100'>
								<div className='flex p-5 pb-0 pt-3'>
									{releasePayload.map((release, i) => {
										return (
											<div className='p-2 rounded-sm bg-bluegray-800 w-32 mr-4' key={i}>
												<p className='text-white text-p2 whitespace-nowrap mb-1'>
													{release.dow}{' '}
													{new Date(release.date).toLocaleString('en-TT', {
														month: 'short',
														day: '2-digit',
														year: '2-digit',
													})}
												</p>
												<p className='text-white text-i3'>{parseRoomReleaseTime(release.rooms)}</p>
											</div>
										);
									})}
								</div>
							</div>
						</>
					)}
				</Tabs.Content>
				<Tabs.Content value='release_log' className={tabContentClassname}>
					{isFetchingReleaseLog || remoteLoading ? (
						<div className='w-full flex justify-center p-36'>
							<WorkingIndicator size='lg' />
						</div>
					) : (
						<ReleaseHistory
							data={releaseLogData?.log ?? []}
							onDataChange={(data) => {
								if (data.length > 0) {
									setIsDirty(true);
								} else {
									setIsDirty(false);
								}
								setUndoReleasePayload(data);
							}}
						/>
					)}
				</Tabs.Content>
			</Tabs.Root>
			<ReleaseActionSection
				cancel={() => {
					setDialogOpen(false);
					setSelectedTab('block_release');
				}}
				release={() => {
					if (selectedTab === 'block_release') {
						requestRelease(
							releasePayload.map((payload) => {
								payload.block_name = currentBlock.name;
								return payload;
							})
						);
					} else {
						requestUndoRelease(undoReleasePayload);
					}
					setRemoteLoading(true);
					setIsDirty(false);
				}}
				totalHours={
					selectedTab === 'block_release'
						? data?.blocks?.map((block) => block.allocated_time).reduce((partialSum, a) => partialSum + a, 0) ?? 0
						: 0
				}
				totalBlocks={selectedTab === 'block_release' ? data?.blocks?.length ?? 0 : 0}
				isDirty={
					selectedTab === 'block_release'
						? isDirty && !isFetching && releasePayload.length > 0
						: isDirty && !isFetchingReleaseLog && undoReleasePayload.length > 0
				}
				releaseText={selectedTab === 'block_release' ? 'Release' : 'Undo Release'}
			/>
		</Dialog>
	);
}

export function ReleaseActionSection({
	cancel,
	release,
	isDirty,
	totalHours,
	totalBlocks,
	releaseText,
}: {
	cancel: () => void;
	release: () => void;
	totalHours?: number;
	totalBlocks?: number;
	isDirty?: boolean;
	releaseText?: string;
}) {
	return (
		<div className='flex bg-blue-100 p-5 pt-2 justify-between'>
			<div className='text-p3 uppercase'>
				<p>{totalHours && totalHours > 0 ? `Total Allocated Minutes: ${totalHours}m` : ''}</p>
				<p>{totalBlocks && totalBlocks > 0 ? `Total # of Blocks: ${totalBlocks}` : ''}</p>
			</div>
			<div className='flex'>
				<Button
					variant='secondary'
					sizeX='sm'
					sizeY='sm'
					className='mr-5'
					onClick={() => {
						cancel();
					}}
				>
					Cancel
				</Button>
				<Button
					variant='primary'
					sizeX='sm'
					sizeY='sm'
					disabled={!isDirty}
					onClick={() => {
						release();
					}}
				>
					{releaseText ? releaseText : 'Release'}
				</Button>
			</div>
		</div>
	);
}

export function militaryTimeDifference(start: string | undefined, end: string | undefined) {
	const startHour = parseInt(start?.split(':')[0] ?? '0');
	const startMin = parseInt(start?.split(':')[1] ?? '0');
	const endHour = parseInt(end?.split(':')[0] ?? '0');
	const endMin = parseInt(end?.split(':')[1] ?? '0');
	const startMinutes = startHour * 60 + startMin;
	const endMinutes = endHour * 60 + endMin;
	return endMinutes - startMinutes;
}

function parseRoomReleaseTime(rooms: BlockScheduleRoom[], page = 'default') {
	let totalMinutes = 0;
	for (const room of rooms) {
		if (room.newEnd || room.newStart || room.flag) {
			totalMinutes += militaryTimeDifference(room.newStart ?? room.start, room.newEnd ?? room.end);
		}
	}

	const minutes = totalMinutes % 60;
	const hours = Math.floor(totalMinutes / 60);
	return `${hours}h ${minutes}m`;
}

// Converts a date to a number for sorting expects date in M/D/Y format
function dateToNumber(date: string): number {
	const dateArray = date.split('/');
	const day = dateArray[1];
	const month = dateArray[0];
	const year = dateArray[2];

	return parseInt(`${year}${month}${day}`);
}

export function BlockScheduleTable({
	allowPartialRelease,
	selectedBlock,
	blockOptions,
	data,
	onDataChange,
	setPrev,
	setFlip,
	defaultFlip,
	defaultPrev,
	onBlockChange,
	selectableBlocks,
}: {
	allowPartialRelease: boolean;
	selectedBlock: ShortOption;
	blockOptions: ShortOption[];
	data: BlockScheduleItem[];
	onDataChange: (data: BlockScheduleItem[]) => void;
	setPrev: (boolean: boolean) => void;
	setFlip: (boolean: boolean) => void;
	onBlockChange: (data: ShortOption) => void;
	defaultPrev: boolean;
	defaultFlip: boolean;
	selectableBlocks: boolean;
}) {
	const [pendingReleaseList, setPendingReleaseList] = useState<BlockScheduleItem[]>([]);

	useEffect(() => {
		onDataChange(pendingReleaseList);
	}, [data, onDataChange, pendingReleaseList]);

	const getRoom = (schedule_id: number, room_number: number) => {
		let response = undefined;
		const currentBlock =
			pendingReleaseList.filter((inner) => {
				return inner.schedule_id === schedule_id;
			})[0] ??
			data.filter((inner) => {
				return inner.schedule_id === schedule_id;
			})[0];

		currentBlock.rooms.forEach((elem) => {
			if (elem.room_number === room_number) {
				response = elem;
			}
		});

		return response;
	};

	const updateRoom = (schedule_id: number, room_number: number, room_obj: BlockScheduleRoom) => {
		const currentRelease = JSON.parse(
			JSON.stringify(
				pendingReleaseList.filter((inner) => {
					return inner.schedule_id !== schedule_id;
				})
			)
		);
		let currentBlock =
			pendingReleaseList.filter((inner) => {
				return inner.schedule_id === schedule_id;
			})[0] ??
			data.filter((inner) => {
				return inner.schedule_id === schedule_id;
			})[0];

		currentBlock = JSON.parse(JSON.stringify(currentBlock));

		currentBlock.rooms = currentBlock.rooms.map((elem) => {
			if (elem.room_number === room_number) {
				return room_obj;
			} else {
				return elem;
			}
		});

		const activeRooms = currentBlock.rooms.filter((room) => {
			if ('flag' in room || 'newStart' in room || 'newEnd' in room) {
				return !(room.flag === undefined && room.newEnd === undefined && room.newStart === undefined);
			} else {
				return false;
			}
		});

		if (activeRooms.length === 0) {
			setPendingReleaseList([...currentRelease]);
		} else {
			setPendingReleaseList([...currentRelease, currentBlock]);
		}
	};

	const removeBlock = (schedule_id: number, resetRooms?: boolean) => {
		setPendingReleaseList(
			pendingReleaseList.filter((inner) => {
				return inner.schedule_id !== schedule_id;
			})
		);
	};
	const addBlock = (schedule_id: number, addRooms?: boolean) => {
		const currentBlock = JSON.parse(
			JSON.stringify(
				data.filter((inner) => {
					return inner.schedule_id === schedule_id;
				})
			)
		)[0];

		if (addRooms) {
			currentBlock.rooms = currentBlock.rooms.map((room: BlockScheduleRoom) => ({ ...room, flag: 'full_release' }));
		}
		setPendingReleaseList([...pendingReleaseList, { ...currentBlock }]);
	};

	return (
		<div
			className={classNames(
				{ 'max-h-72': window.innerHeight < 600, 'max-h-96': window.innerHeight > 600 },
				'overflow-y-auto'
			)}
		>
			<div className='flex pl-7 pr-7 border-b border-gray-200 pb-7'>
				<div className='mr-10'>
					<Select
						label='Block Name'
						sizeX='sm'
						sizeY='sm'
						disabled={selectableBlocks ? false : true}
						options={blockOptions.map(({ id, name }) => ({ value: id, label: name }))}
						defaultValue={{ label: selectedBlock.name, value: selectedBlock.id }}
						onChange={(e) => {
							onBlockChange({ id: e?.value ?? 0, name: e?.label ?? '' });
						}}
					/>
				</div>
				<div>
					<p className='text-p3 text-gray-700 tracking-wider uppercase mb-4 font-secondary'>Filter By Category</p>
					<div className='flex justify-between'>
						<ToggleButton
							text='Previous Block Days'
							toggle={(isActive) => {
								setPrev(isActive);
								setPendingReleaseList([]);
							}}
							isActive={defaultPrev}
						/>
						<div className='mr-2'></div>
						<ToggleButton
							text='Flip Room Blocks'
							toggle={(isActive) => {
								setFlip(isActive);
								setPendingReleaseList([]);
							}}
							isActive={defaultFlip}
						/>
					</div>
				</div>
			</div>
			<DataTable
				title=''
				disablePagination
				minimalStyle
				columns={[
					{
						header: 'Release',
						accessorKey: '',
						cell: ({ row }) =>
							row.original.rooms.filter((room) => room.released).length > 0 ? (
								<Tooltip content='This block schedule is in read-only mode. To unlock it, undo this release in the Release Log tab.'>
									<span className='material-symbols-outlined pl-0.5 text-blue-400 cursor-pointer'>lock_reset</span>
								</Tooltip>
							) : (
								<Checkbox
									label=''
									checked={
										pendingReleaseList.filter((inner) => {
											return inner.schedule_id === row.original.schedule_id;
										}).length > 0
											? true
											: false
									}
									onChange={(event) => {
										const isChecked = event.target.checked;
										if (isChecked) {
											addBlock(row.original.schedule_id, true);
										} else {
											removeBlock(row.original.schedule_id);
										}
									}}
								/>
							),
					},
					{
						header: 'Date',
						accessorKey: 'date',
						cell: ({ row }) => <span>{row.original.date.toLocaleString('en-US')}</span>,
						sortingFn: (rowA, rowB) => {
							const a = dateToNumber(rowA.original.date.toLocaleString('en-US'));
							const b = dateToNumber(rowB.original.date.toLocaleString('en-US'));
							return b > a ? 1 : -1;
						},
					},
					{
						accessorKey: 'dow',
						header: 'Dow',
						sortingFn: (rowA, rowB) => {
							const a = shortDOWToNumber(rowA.original.dow);
							const b = shortDOWToNumber(rowB.original.dow);
							return b > a ? 1 : -1;
						},
					},
					{
						accessorKey: 'allocated_time',
						header: 'Allocated Time',
						cell: ({ row }) => {
							return row.original.allocated_time + 'm';
						},
					},
					{
						accessorKey: 'block_type',
						header: 'Block Type',
					},
					{
						accessorKey: 'flip_room',
						header: 'Multi Room',
						cell: ({ row }) => {
							if (row.original.flip_room) {
								return <span className='material-symbol opacity-50'>check</span>;
							} else {
								return '';
							}
						},
					},
				]}
				data={data}
				disableRowCounter
				rowSubview={(outerRow) => {
					return (
						<>
							<div className='flex'>
								<div className='w-3/5'>
									<DataTable
										title=''
										minimalStyle
										disablePagination
										disableRowCounter
										columns={[
											{
												header: 'Room',
												accessorKey: '',
												cell: ({ row: innerRow }) => <p className='whitespace-nowrap'>{`Room ${innerRow.index + 1}`}</p>,
											},
											{
												header: 'Partial Release (Start/End)',
												accessorKey: 'id',
												cell: ({ row: innerRow }) => {
													const focusedRoom = getRoom(outerRow.original.schedule_id, innerRow.original.room_number);
													return (
														<RoomDropDownRow
															allowPartialRelease={allowPartialRelease}
															setNew={(room_obj) => {
																updateRoom(outerRow.original.schedule_id, room_obj.room_number, room_obj);
															}}
															room={focusedRoom}
															anotherRoomReleased={outerRow.original.rooms.filter((room) => room.released).length > 0}
														/>
													);
												},
											},
										]}
										data={outerRow.original.rooms}
									/>
								</div>
								<div className='w-2/5 justify-center ml-2'>
									<div className='flex flex-col text-p2'>
										<p className='bg-white text-center rounded-md p-3 pb-4 mb-2 text-p3 uppercase font-semibold text-bluegray-900'>
											New Block Time Window
										</p>
										<div className='flex'>
											<div className='bg-white px-4 py-2 rounded-md w-1/2 mr-1'>
												<p className='text-h3 font-bold'>
													{getNewBlockTimeWindow(
														outerRow.original.rooms.map((room) => {
															const roomData = getRoom(outerRow.original.schedule_id, room.room_number) ?? room;

															return roomData;
														}),
														'start'
													)}
												</p>
												<p className='text-p3 pt-0.5 whitespace-nowrap'>Earliest Block Start</p>
											</div>
											<div className='bg-white px-4 py-2 rounded-md w-1/2 ml-1'>
												<p className='text-h3 font-bold'>
													{getNewBlockTimeWindow(
														outerRow.original.rooms.map((room) => {
															const roomData = getRoom(outerRow.original.schedule_id, room.room_number) ?? room;

															return roomData;
														}),
														'end'
													)}
												</p>
												<p className='text-p3 pt-0.5 whitespace-nowrap'>Latest Block End</p>
											</div>

											<div className='bg-white px-4 py-2 rounded-md w-1/2 ml-1'>
												<p className='text-h3 font-bold whitespace-nowrap'>
													{parseRoomReleaseTime(
														outerRow.original.rooms.map((room) => {
															const roomData = getRoom(outerRow.original.schedule_id, room.room_number) ?? room;

															return roomData;
														}),
														'released_minutes'
													)}
												</p>
												<p className='text-p3 pt-0.5 whitespace-nowrap'>
													{outerRow.original.rooms.filter((room) => room.released).length === 0 ? `Time to be Released` : 'Released'}
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div>
								{!allowPartialRelease && (
									<div className='text-[0.8em] bg-blue-50 w-7/12 flex p-2 rounded-md justify-between py-3 items-center'>
										<span className='material-symbols-outlined text-blue-500 pr-2'>info</span>
										<p className='text-p3 text-gray-500'>
											Your settings do not currently permit partial release of blocks. You can change this in Settings &gt; Block
											Settings at any time.
										</p>
									</div>
								)}
							</div>
						</>
					);
				}}
			/>
		</div>
	);
}

export function ReleaseHistory({
	data,
	onDataChange,
}: {
	data: ReleaseLogItem[];
	onDataChange: (data: ReleaseLogItem[]) => void;
}) {
	const [undoReleaseList, setUndoReleaseList] = useState<ReleaseLogItem[]>([]);

	useEffect(() => {
		onDataChange(undoReleaseList);
	}, [onDataChange, undoReleaseList]);

	return (
		<div
			className={classNames(
				{ 'max-h-72': window.innerHeight < 600, 'max-h-96': window.innerHeight > 600 },
				'overflow-y-auto'
			)}
		>
			<DataTable
				title=''
				disablePagination
				maxHeight='80'
				minimalStyle
				columns={[
					{
						header: 'Undo',
						accessorKey: '',
						cell: ({ row }) => (
							<Checkbox
								label=''
								checked={
									undoReleaseList.filter((inner) => {
										return (
											inner.block_pattern_room_release_id === row.original.block_pattern_room_release_id &&
											inner.date === row.original.date &&
											inner.room_number === row.original.room_number
										);
									}).length > 0
										? true
										: false
								}
								onChange={(event) => {
									const isChecked = event.target.checked;
									if (isChecked) {
										setUndoReleaseList([
											...undoReleaseList,
											...data.filter(
												(release) =>
													release.block_pattern_room_release_id === row.original.block_pattern_room_release_id &&
													release.date === row.original.date &&
													release.room_number === row.original.room_number
											),
										]);
									} else {
										setUndoReleaseList([
											...undoReleaseList.filter(
												(release) =>
													!(
														release.block_pattern_room_release_id === row.original.block_pattern_room_release_id &&
														release.date === row.original.date &&
														release.room_number === row.original.room_number
													)
											),
										]);
									}
								}}
							/>
						),
					},
					{
						header: 'Date',
						accessorKey: 'date',
						cell: ({ row }) => <span>{toFullDate(new Date(row.original.date))}</span>,
						sortingFn: (rowA, rowB) => {
							const a = dateToNumber(rowA.original.date.toLocaleString('en-US'));
							const b = dateToNumber(rowB.original.date.toLocaleString('en-US'));
							return b > a ? 1 : -1;
						},
					},
					{
						accessorKey: 'dow',
						header: 'Dow',
						sortingFn: (rowA, rowB) => {
							const a = shortDOWToNumber(rowA.original.dow);
							const b = shortDOWToNumber(rowB.original.dow);
							return b > a ? 1 : -1;
						},
					},
					{
						header: 'Block',
						accessorKey: 'block_name',
					},
					{
						accessorKey: 'release_type',
						header: 'Release Type',
					},
					{
						accessorKey: 'date_released',
						header: 'Date Released',
						cell: ({ row }) => <span>{toFullDate(new Date(row.original.date_released))}</span>,
					},
					{
						header: 'Room Number',
						accessorKey: 'room_number',
					},
					{
						header: 'Release Start',
						accessorKey: 'start_time',
						sortingFn: (rowA, rowB) => {
							const a = parseInt(`${rowA.original.start_time.split(':')[0]}${rowA.original.start_time.split(':')[1]}`);
							const b = parseInt(`${rowB.original.start_time.split(':')[0]}${rowB.original.start_time.split(':')[1]}`);
							return b < a ? 1 : -1;
						},
					},
					{
						header: 'Release End',
						accessorKey: 'end_time',
						sortingFn: (rowA, rowB) => {
							const a = parseInt(`${rowA.original.end_time.split(':')[0]}${rowA.original.end_time.split(':')[1]}`);
							const b = parseInt(`${rowB.original.end_time.split(':')[0]}${rowB.original.end_time.split(':')[1]}`);
							return b < a ? 1 : -1;
						},
					},
					{
						header: 'Released Minutes',
						accessorKey: 'released_minutes',
						cell: ({ row }) => {
							return `${row.original.released_minutes}m`;
						},
					},
				]}
				data={data}
				disableRowCounter
			/>
		</div>
	);
}

export function RoomDropDownRow({
	allowPartialRelease,
	room,
	setNew,
	anotherRoomReleased,
}: {
	allowPartialRelease: boolean;
	room: BlockScheduleRoom | undefined;
	setNew: (room_obj: BlockScheduleRoom) => void;
	anotherRoomReleased: boolean;
}) {
	// We can only change start OR end time
	const releaseWholeRoom = room?.flag === 'full_release' ?? false;
	return (
		<div className='flex'>
			<div className='flex items-center w-96'>
				<div className='flex justify-between mb-2'>
					<div className='mr-3'>
						<Timepicker
							interval={15}
							minHour={parseInt(room?.start ?? '0')}
							minMinute={room?.start ? parseInt(room.start.split(':')[1]) : 0}
							maxHour={parseInt(room?.end ?? '24') - 1}
							onChange={(time) => {
								const tempRoom = JSON.parse(JSON.stringify(room ?? []));
								tempRoom.newStart = time.military;
								setNew(tempRoom);
							}}
							selected={room?.newStart ? stringTimeToDate(room?.newStart) : stringTimeToDate(room?.start)}
							disabled={
								(allowPartialRelease === false || room?.newEnd ? room?.newEnd !== room?.end : false) ||
								releaseWholeRoom ||
								room?.released ||
								anotherRoomReleased
							}
						/>
					</div>
					<div>
						<Timepicker
							interval={15}
							minHour={parseInt(room?.start ?? '0') + 1}
							maxHour={parseInt(room?.end ?? '24')}
							maxMinute={room?.end ? parseInt(room.end.split(':')[1]) : 0}
							onChange={(time) => {
								const tempRoom = JSON.parse(JSON.stringify(room ?? []));
								tempRoom.newEnd = time.military;
								setNew(tempRoom);
							}}
							selected={room?.newEnd ? stringTimeToDate(room?.newEnd) : stringTimeToDate(room?.end)}
							disabled={
								(allowPartialRelease === false || room?.newStart ? room?.newStart !== room?.start : false) ||
								releaseWholeRoom ||
								room?.released ||
								anotherRoomReleased
							}
						/>
					</div>
				</div>
				<div className='flex w-96'>
					{allowPartialRelease === false ||
						(!room?.released && !anotherRoomReleased && (
							<p
								onClick={() => {
									const tempRoom = JSON.parse(JSON.stringify(room ?? []));
									tempRoom.newEnd = undefined;
									tempRoom.newStart = undefined;
									tempRoom.flag = undefined;
									setNew(tempRoom);
								}}
								className='text-blue-500 text-p3 cursor-pointer underline pl-5 whitespace-nowrap'
							>
								Reset
							</p>
						))}

					{!room?.released &&
					!anotherRoomReleased &&
					(room?.flag === undefined || releaseWholeRoom) &&
					!(allowPartialRelease === false || room?.newStart || room?.newEnd) ? (
						<p
							onClick={() => {
								const tempRoom = JSON.parse(JSON.stringify(room ?? []));
								tempRoom.newEnd = undefined;
								tempRoom.newStart = undefined;
								tempRoom.flag = 'full_release';
								setNew(tempRoom);
							}}
							className={
								!releaseWholeRoom
									? 'text-blue-500 text-p3 cursor-pointer underline pl-5 whitespace-nowrap'
									: 'text-green-700 font-bold text-p3 pl-5 whitespace-nowrap'
							}
						>
							Release Whole Room
						</p>
					) : (
						allowPartialRelease === false ||
						(!room?.released && !anotherRoomReleased && (
							<p className='text-green-700 font-bold text-p3 pl-5 whitespace-nowrap'>Partial Release</p>
						))
					)}

					{room?.released && (
						<div className='flex items-center justify-between w-'>
							<Tooltip content='Undo this release in the Release Log tab.'>
								<p className='text-yellow-700 text-p3 pl-5 whitespace-nowrap underline'>Released </p>
							</Tooltip>
						</div>
					)}

					{!room?.released && anotherRoomReleased && (
						<div className='flex items-center justify-between w-'>
							<Tooltip content='This room is in read-only mode. To unlock it, undo this release in the Release Log tab.'>
								<p className='text-gray-400 text-p3 pl-5 whitespace-nowrap'>Read-only</p>
							</Tooltip>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export function ToggleButton({
	text,
	toggle,
	activeText,
	bgColor,
	isActive,
}: {
	text: string;
	toggle: (toggleState: boolean) => void;
	activeText?: string;
	bgColor?: 'blue' | 'green';
	isActive: boolean;
}) {
	return (
		<div
			onClick={() => {
				toggle(!isActive);
			}}
			className={classNames(
				{
					'bg-transparent text-blue-500': !isActive && !bgColor,
					'bg-blue-500 text-white': isActive && !bgColor,
					'bg-transparent text-green-600': !isActive && bgColor === 'green',
					'bg-green-600 text-white': isActive && bgColor === 'green',
					'border border-blue-500 ': bgColor === undefined || bgColor === 'blue',
					'border border-green-600 ': bgColor === 'green',
				},
				'text-p3 px-2 py-1 rounded-sm cursor-pointer text-center select-none'
			)}
		>
			{isActive && activeText ? activeText : text}
		</div>
	);
}

/**
 * Retuns min or max time in array of timestamps, expects military time.
 * Retuns max by default, set min to true to override.
 */
export function minMaxTime(times: string[], earliest = true): string {
	let timeTotal = earliest ? 24 * 100 + 60 : 1;
	let response = '---';

	for (let index = 0; index < times.length; index++) {
		const strHour = times[index].split(':')[0];
		const strMinute = times[index].split(':')[1];
		const total = parseInt(strHour) * 100 + parseInt(strMinute);

		if (earliest) {
			if (total < timeTotal) {
				response = `${strHour}:${strMinute}`;
				timeTotal = total;
			}
		} else {
			if (total > timeTotal) {
				response = `${strHour}:${strMinute}`;
				timeTotal = total;
			}
		}
	}
	return response;
}

/**
 * Retuns min or max time in array of timestamps, expects military time.
 * Retuns max by default, set min to true to override.
 */
function getNewBlockTimeWindow(rooms: BlockScheduleRoom[], slot: string): string {
	let min_block_start = null;
	let max_block_end = null;
	let room_temp_start = null;
	let room_temp_end = null;

	// full release
	const are_all_released =
		rooms.filter((room) => room.is_full_release).length === rooms.length ||
		rooms.filter((room) => room.flag === 'full_release').length === rooms.length;
	if (are_all_released) {
		return '---';
	}

	let release_present = false;

	for (const room of rooms) {
		if (room?.released) {
			release_present = true;
			break;
		}
	}

	if (release_present) {
		for (const room of rooms) {
			if (slot === 'start') {
				if (!room_temp_start) {
					room_temp_start = room?.start;
				}
				if (room?.newEnd && rooms.length > 1) {
					min_block_start = minMaxTime([room_temp_start, room?.newEnd]);
					room_temp_start = room?.newEnd;
				} else if (room?.newEnd) {
					min_block_start = room?.newEnd;
				} else {
					min_block_start = room?.start;
				}
			} else {
				if (!room_temp_end) {
					room_temp_end = room?.end;
				}
				if (room?.newStart && rooms.length > 1) {
					max_block_end = minMaxTime([room_temp_end, room?.newStart]);
					room_temp_start = room?.newStart;
				} else if (room?.newStart) {
					max_block_end = room?.newStart;
				} else {
					max_block_end = room?.end;
				}
			}
		}
	} else {
		let min_block_start_no_release = rooms[0].start;
		let max_block_end_no_release = rooms[0].end;
		for (const room of rooms) {
			if (slot === 'start') {
				min_block_start = minMaxTime([room?.start, min_block_start_no_release]);
				min_block_start_no_release = min_block_start;
			} else {
				max_block_end = minMaxTime([room?.end, max_block_end_no_release], false);
				max_block_end_no_release = max_block_end;
			}
		}
	}

	if (slot === 'start') {
		return min_block_start?.slice(0, 5) ?? '---';
	}
	return max_block_end?.slice(0, 5) ?? '---';
}

export default ReleaseDialog;
