import { ApiEndpoints, FacilityLicense } from 'models';
import { standardApi } from 'store/api';
import { StaffingGridItem } from 'pages/Anesthesia/components';
import { SortedColumns } from 'components';

import { ShortOption } from './FilterService';
import { AnesthesiaCoverageResponse, NurseCoverageResponse } from './CoreService';

import type { Facility, FieldMapping } from 'models/models';

const apiWithTags = standardApi.enhanceEndpoints({
	addTagTypes: ['Analytics'],
});

interface AnalyticsTableRequest {
	start_date: string;
	end_date: string;
	sort: SortedColumns[];
	healthsystem_id: number;
}

interface GraphAnalyticsRequest {
	start_date: string;
	end_date: string;
	group: string;
	healthsystem_id: number;
}

interface LoginTelemetry {
	days_since_last_login: number;
	interactions: number;
	number_of_unique_days: number;
	session_time: number;
	email: string;
	name: string;
	healthsystem_name: string;
}

interface PageTelemetry {
	page_url: string;
	interactions: number;
	visits: number;
	session_time: number;
}

interface GraphTelemetry {
	x: string;
	y: number;
}

/**
 * Provides endpoints for setting values in database
 */
export const analyticsService = apiWithTags.injectEndpoints({
	endpoints: (build) => ({
		getLoginAnalytics: build.query<{ stats: LoginTelemetry[] }, AnalyticsTableRequest>({
			query: (body) => ({
				url: ApiEndpoints.GET_LOGIN_EVENTS,
				method: 'POST',
				body,
			}),
			providesTags: ['Analytics'],
		}),
		getLoginGraph: build.query<{ data_points: GraphTelemetry[] }, GraphAnalyticsRequest>({
			query: (body) => ({
				url: ApiEndpoints.GET_LOGIN_EVENTS_GRAPH,
				method: 'POST',
				body,
			}),
			providesTags: ['Analytics'],
		}),
		getPageAnalytics: build.query<{ stats: PageTelemetry[] }, AnalyticsTableRequest>({
			query: (body) => ({
				url: ApiEndpoints.GET_PAGE_EVENTS,
				method: 'POST',
				body,
			}),
			providesTags: ['Analytics'],
		}),
		getPageGraph: build.query<{ data_points: GraphTelemetry[] }, GraphAnalyticsRequest>({
			query: (body) => ({
				url: ApiEndpoints.GET_PAGE_EVENTS_GRAPH,
				method: 'POST',
				body,
			}),
			providesTags: ['Analytics'],
		}),
		getUploadAnalytics: build.query<{ stats: PageTelemetry[] }, AnalyticsTableRequest>({
			query: (body) => ({
				url: ApiEndpoints.GET_UPLOAD_EVENTS,
				method: 'POST',
				body,
			}),
			providesTags: ['Analytics'],
		}),
		getUploadGraph: build.query<{ data_points: GraphTelemetry[] }, GraphAnalyticsRequest>({
			query: (body) => ({
				url: ApiEndpoints.GET_UPLOAD_EVENTS_GRAPH,
				method: 'POST',
				body,
			}),
			providesTags: ['Analytics'],
		}),
	}),
});

export const {
	useGetLoginAnalyticsQuery,
	useGetLoginGraphQuery,
	useGetPageAnalyticsQuery,
	useGetPageGraphQuery,
	useGetUploadAnalyticsQuery,
	useGetUploadGraphQuery,
} = analyticsService;

export const {
	endpoints: { getLoginAnalytics, getLoginGraph, getPageAnalytics, getPageGraph, getUploadGraph, getUploadAnalytics },
} = analyticsService;
