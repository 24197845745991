import * as RadixToggleGroup from '@radix-ui/react-toggle-group';
import { useId, useState } from 'react';
import classNames from 'classnames';

import { Tooltip } from 'components';

import type { HTMLAttributes } from 'react';
import type { ToggleGroupSingleProps } from '@radix-ui/react-toggle-group';

export interface ToggleGroupProps extends Omit<ToggleGroupSingleProps, 'type'> {
	/** The text label to apply to this toggle group. This is required for accessibility purposes, even if the label is hidden using the `hideLabel` option. */
	label?: string;

	/** Whether to visually hide this toggle group's label from users. */
	hideLabel?: boolean;

	/** The options to include within this toggle group. */
	options: ToggleGroupOption[];

	/** Whether to allow this toggle group to have no option selected, resulting in an empty string value. */
	allowDeselection?: boolean;

	/** A description of this toggle group's current validation error. This also applies styles to visually indicate the field contains an error. */
	errorMessage?: string;

	/** The manner in which the space occupied by an error message is handled. */
	errorMessageArea?: 'none' | 'reserved' | 'floating';

	/** A hint to the user on the purpose of this toggle group. This adds an information icon next to the field label, which can be hovered over to reveal the provided text. */
	instructions?: string;

	/** A prop object to pass to this toggle group's outermost `<div>` element. */
	containerProps?: HTMLAttributes<HTMLDivElement>;
}

interface ToggleGroupOption {
	/** The text to display for this option, or the name of the Material Symbol to display if using `labelAsIcon`. */
	label: string;

	/** Whether to convert the provided `label` to a Material Symbol. */
	labelAsIcon?: boolean;

	/** The return value to use when selecting this option. */
	value: string;

	/** Whether to prevent users from selecting this option. */
	disabled?: boolean;
}

function ToggleGroup({
	label,
	hideLabel = false,
	options,
	allowDeselection = false,
	errorMessage,
	errorMessageArea = 'none',
	instructions,
	containerProps,
	className,
	defaultValue,
	onValueChange,
	...props
}: ToggleGroupProps) {
	const initialValue = defaultValue || (allowDeselection ? '' : options[0].value);
	const [inputValue, setInputValue] = useState(initialValue);
	const componentId = `toggle-group-${useId()}`;
	const { className: containerClassName, ...otherContainerProps } = containerProps || {};

	return (
		<div className={classNames('block relative', containerClassName)} {...otherContainerProps}>
			{label && (
				<label htmlFor={componentId}>
					<div
						className={classNames('mb-2', {
							hidden: hideLabel,
						})}
					>
						<span className='font-preset-label'>{label}</span>
						{instructions && (
							<Tooltip content={instructions}>
								<span className='material-symbol-[1rem] ml-2 align-bottom cursor-help'>info</span>
							</Tooltip>
						)}
					</div>
				</label>
			)}

			<RadixToggleGroup.Root
				{...props}
				className={classNames('flex h-8 font-primary text-[0.8em]', className)}
				value={inputValue}
				id={componentId}
				type='single'
				onValueChange={(value) => {
					if (!value && !allowDeselection) return;
					setInputValue(value);
					if (onValueChange) onValueChange(value);
				}}
			>
				{options.map((option) => (
					<RadixToggleGroup.Item
						key={option.value}
						value={option.value}
						disabled={option.disabled}
						className={classNames(
							'neighbor basis-full flex items-center justify-center border-y border-blue-500 bg-white relative',
							'first:border-l first:rounded-l-sm last:border-r last:rounded-r-sm',
							'focus:outline-none',
							'disabled:cursor-not-allowed disabled:opacity-30',
							// Styles for the active option.
							'data-on:text-white data-on:bg-blue-500',
							'enabled:data-on:hover:bg-blue-700 enabled:data-on:hover:border-blue-700',
							'data-on:focus-visible:bg-blue-700 data-on:focus-visible:border-blue-700',
							// Styles for inactive options.
							'data-off:before:absolute data-off:before:left-0 data-off:before:top-0 data-off:before:w-px data-off:before:h-full data-off:before:bg-blue-100 disabled:data-off:before:bg-blue-400',
							'enabled:data-off:hover:bg-blue-100 ',
							'data-off:focus-visible:bg-blue-100',
							'first:before:hidden neighbor-data-on:before:hidden'
						)}
					>
						{option.labelAsIcon ? <span className='material-symbol-sm'>{option.label}</span> : option.label}
					</RadixToggleGroup.Item>
				))}
			</RadixToggleGroup.Root>

			<p
				className={classNames('mt-2 font-primary text-p2 text-red-400', {
					'absolute top-full': errorMessageArea === 'floating',
					hidden: !errorMessage && errorMessageArea !== 'reserved',
				})}
			>
				{errorMessage}
				{!errorMessage && errorMessageArea === 'reserved' && <>&nbsp;</>}
			</p>
		</div>
	);
}

export { ToggleGroup, ToggleGroup as default };
