import { ApiEndpoints } from 'models/api';
import { ShortOption } from 'store';
import { standardApi } from 'store/api';

export interface ReleasePatternObject {
	groupValue: number;
	values: {
		label: string;
		pattern: {
			allocated: number;
			automatic_release: number;
			voluntary_release: number;
		};
	}[];
}

export interface ReleasePatternsResponse {
	data: ReleasePatternObject[];
}

export interface ReleasePatternsRequest {
	facility_id: number | null;
	healthsystem_id: number | null;
	sortby: string;
	groupby: string;
	viewby: string;
	filters: {
		block_names: ShortOption[];
		days_of_week: string[];
		start_date: string;
		end_date: string;
		block_timeslot: ('Full Day' | 'Morning' | 'Afternoon')[];
	};
}

/**
 * Provides endpoints for retrieving and setting block settings
 */
export const releasePatternsApi = standardApi.injectEndpoints({
	endpoints: (build) => ({
		getReleasePatterns: build.query<ReleasePatternsResponse, ReleasePatternsRequest>({
			query: (body) => ({
				url: ApiEndpoints.GET_RELEASE_PATTERN,
				method: 'POST',
				body,
			}),
		}),
	}),
});

export const { useGetReleasePatternsQuery } = releasePatternsApi;

export const {
	endpoints: { getReleasePatterns },
} = releasePatternsApi;
