import classNames from 'classnames';
import { forwardRef } from 'react';

import { Tooltip, WorkingIndicator } from 'components';

import type { LabelHTMLAttributes, Ref, TextareaHTMLAttributes } from 'react';

export interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	/** The text label to apply to this textarea. This is required for accessibility purposes, even if it is hidden using the `hideLabel` option. */
	label: string;

	/** Whether to visually hide this textarea's label from users. */
	hideLabel?: boolean;

	/** Whether to allow the user to manually adjust the size of this textarea. */
	allowResizing?: boolean;

	/** A description of this textarea's current validation error. This also applies styles to visually indicate the field contains an error. */
	errorMessage?: string;

	/** The manner in which the space occupied by an error message is handled. */
	errorMessageArea?: 'none' | 'reserved' | 'floating';

	/** Whether the field should display a symbol indicating that it is affected by an ongoing process. */
	isWorking?: boolean;

	/** Whether to visually indicate that this textarea contains a valid value. */
	isKnownValid?: boolean;

	/** A hint to the user on the purpose of this textarea. This adds an information icon next to the field label, which can be hovered over to reveal the provided text. */
	instructions?: string;

	/** A prop object to pass to this textarea's outermost `<label>` element. */
	containerProps?: LabelHTMLAttributes<HTMLLabelElement>;
}

const TextAreaWithRef = forwardRef(function TextArea(
	{
		label,
		hideLabel = false,
		allowResizing = false,
		errorMessage,
		errorMessageArea = 'none',
		isWorking = false,
		isKnownValid = false,
		instructions,
		containerProps,
		className,
		disabled,
		readOnly,
		...props
	}: TextAreaProps,
	forwardedRef: Ref<HTMLTextAreaElement>
) {
	const { className: containerClassName, ...otherContainerProps } = containerProps || {};

	return (
		<label className={classNames('block relative', containerClassName)} {...otherContainerProps}>
			<div
				className={classNames('mb-2', {
					hidden: hideLabel,
				})}
			>
				<span className='font-preset-label'>{label}</span>
				{instructions && (
					<Tooltip content={instructions}>
						<span className='material-symbol-[1rem] ml-2 align-bottom cursor-help'>info</span>
					</Tooltip>
				)}
			</div>

			<div className='relative'>
				<textarea
					className={classNames(
						'block border rounded-sm font-primary border-gray-400 h-[130px] w-full p-2',
						'focus:outline-none focus:border-blue-500',
						'transition-colors',
						{
							'cursor-not-allowed': disabled,
							'bg-gray-50 text-gray-600': readOnly || disabled,
							'border-red-400': errorMessage,
							'border-green-500': isKnownValid,
							'resize-none': !allowResizing,
						},
						className
					)}
					ref={forwardedRef}
					disabled={disabled}
					readOnly={readOnly}
					{...props}
				/>

				{isWorking && <WorkingIndicator className='absolute bottom-2 right-2' />}
			</div>

			<p
				className={classNames('mt-2 font-primary text-p2 text-red-400', {
					'absolute top-full': errorMessageArea === 'floating',
					hidden: !errorMessage && errorMessageArea !== 'reserved',
				})}
			>
				{errorMessage}
				{!errorMessage && errorMessageArea === 'reserved' && <>&nbsp;</>}
			</p>
		</label>
	);
});

export { TextAreaWithRef as TextArea, TextAreaWithRef as default };
