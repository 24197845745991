import { Button, Drawer, FilterFields } from 'components';
import { useFilters } from 'context';

import type { DrawerProps, FilterFieldsProps } from 'components';

export interface FilterDrawerProps {
	/** The element to use to trigger the opening of this drawer. */
	trigger?: DrawerProps['trigger'];

	/** The filter fields to display. */
	filterFields: FilterFieldsProps['fields'];

	/** The number of year to limit range. */
	dateRangeLimit?: number;
}

function FilterDrawer({ trigger, filterFields, dateRangeLimit = 3 }: FilterDrawerProps) {
	const { resetFilters, clearFilters, applyFilters, filtersAreDirty, metadata } = useFilters();

	return (
		<Drawer
			metadata={metadata}
			trigger={
				trigger || (
					<Button sizeX='sm' sizeY='md' variant='primary-ghost'>
						<span className='material-symbol'>filter_alt</span>
						Filters
					</Button>
				)
			}
			quickActions={[
				{
					icon: 'undo',
					onClick: resetFilters,
					tooltipText: 'Discard unapplied filter changes',
					disabled: !filtersAreDirty,
				},
				{
					icon: 'restart_alt',
					onClick: clearFilters,
					tooltipText: 'Reset filters to default',
					disabled: !metadata.saved_at,
				},
			]}
			actionButtons={[
				{
					onClick: applyFilters,
					children: 'Views',
					disabled: !filtersAreDirty,
				},
				{
					onClick: applyFilters,
					children: 'Apply & Save',
					disabled: !filtersAreDirty,
				},
			]}
		>
			<FilterFields fields={filterFields} dateRangeLimit={dateRangeLimit} />
		</Drawer>
	);
}

export { FilterDrawer, FilterDrawer as default };
