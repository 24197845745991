import * as yup from 'yup';
import { FieldErrors, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { Accordion, Button, TextField, ToggleGroup, Tooltip } from 'components';
import {
	getQueryError,
	handleApiResponse,
	useGetFacilitySettingStatusInitQuery,
	useSetNurseStaffingFacilitySettingMutation,
	useSetFacilitySettingMutation,
} from 'store';
import { getFacilityName, stringTimeToDate } from 'utils';
import { Facility } from 'models';
import { LoadingIndicator } from 'components/Select/subcomponents';
import { useToast } from 'context';

interface FacilityNurseStaffingAccordionItemProps {
	facility?: Facility;
	readonly?: boolean;
	fetching?: boolean;
	rebuildingArtificial: () => void;
}

export const updateFacilityNurseStaffingSchema: any = yup.object({
	rn_weekly_clinical_hours: yup
		.number()
		.typeError('This value must be a number.')
		.min(35, 'This value must be greater than or equal to 35.')
		.max(65, 'This value must be less than 65.')
		.test('rn_weekly_clinical_hours', 'This hour should be in increments of 0.5', (value) => {
			value = value ?? 0;

			// Get the decimal part of the number
			const decimalPart = value - Math.floor(value);

			// Check if the decimal part is either 0 (integer) or 0.5
			return decimalPart === 0 || decimalPart === 0.5;
		}),
	surgical_tech_weekly_clinical_hours: yup
		.number()
		.typeError('This value must be a number.')
		.min(35, 'This value must be greater than or equal to 35.')
		.max(65, 'This value must be less than 65.')
		.test('surgical_tech_weekly_clinical_hours', 'This hour should be in increments of 0.5', (value) => {
			value = value ?? 0;

			// Get the decimal part of the number
			const decimalPart = value - Math.floor(value);

			// Check if the decimal part is either 0 (integer) or 0.5
			return decimalPart === 0 || decimalPart === 0.5;
		}),
	variable_staff_weekly_clinical_hours: yup
		.number()
		.typeError('This value must be a number.')
		.min(35, 'This value must be greater than or equal to 35.')
		.max(65, 'This value must be less than 65.')
		.test('variable_staff_weekly_clinical_hours', 'This hour should be in increments of 0.5', (value) => {
			value = value ?? 0;

			// Get the decimal part of the number
			const decimalPart = value - Math.floor(value);

			// Check if the decimal part is either 0 (integer) or 0.5
			return decimalPart === 0 || decimalPart === 0.5;
		}),
	indirect_staff_weekly_clinical_hours: yup
		.number()
		.typeError('This value must be a number.')
		.min(35, 'This value must be greater than or equal to 35.')
		.max(65, 'This value must be less than 65.')
		.test('indirect_staff_weekly_clinical_hours', 'This hour should be in increments of 0.5', (value) => {
			value = value ?? 0;

			// Get the decimal part of the number
			const decimalPart = value - Math.floor(value);

			// Check if the decimal part is either 0 (integer) or 0.5
			return decimalPart === 0 || decimalPart === 0.5;
		}),
	rn_vacation_weeks: yup
		.number()
		.typeError('This value must be a number.')
		.min(0, 'This value must be greater than or equal to 0.')
		.max(20, 'This value must be less than 21.')
		.test('rn_vacation_weeks', 'This hour should be in increments of 0.5', (value) => {
			value = value ?? 0;

			// Get the decimal part of the number
			const decimalPart = value - Math.floor(value);

			// Check if the decimal part is either 0 (integer) or 0.5
			return decimalPart === 0 || decimalPart === 0.5;
		}),
	surgical_tech_vacation_weeks: yup
		.number()
		.typeError('This value must be a number.')
		.min(0, 'This value must be greater than or equal to 0.')
		.max(20, 'This value must be less than 21.')
		.test('surgical_tech_vacation_weeks', 'This hour should be in increments of 0.5', (value) => {
			value = value ?? 0;

			// Get the decimal part of the number
			const decimalPart = value - Math.floor(value);

			// Check if the decimal part is either 0 (integer) or 0.5
			return decimalPart === 0 || decimalPart === 0.5;
		}),
	variable_staff_vacation_weeks: yup
		.number()
		.typeError('This value must be a number.')
		.min(0, 'This value must be greater than or equal to 0.')
		.max(20, 'This value must be less than 21.')
		.test('variable_staff_vacation_weeks', 'This hour should be in increments of 0.5', (value) => {
			value = value ?? 0;

			// Get the decimal part of the number
			const decimalPart = value - Math.floor(value);

			// Check if the decimal part is either 0 (integer) or 0.5
			return decimalPart === 0 || decimalPart === 0.5;
		}),
	indirect_staff_vacation_weeks: yup
		.number()
		.typeError('This value must be a number.')
		.min(0, 'This value must be greater than or equal to 0.')
		.max(20, 'This value must be less than 21.')
		.test('indirect_staff_vacation_weeks', 'This hour should be in increments of 0.5', (value) => {
			value = value ?? 0;

			// Get the decimal part of the number
			const decimalPart = value - Math.floor(value);

			// Check if the decimal part is either 0 (integer) or 0.5
			return decimalPart === 0 || decimalPart === 0.5;
		}),
	productivity_lower_bound: yup
		.number()
		.typeError('This value must be a number.')
		.min(0, 'This value must be greater than or equal to 0.')
		.max(1, 'This value must be less than 1.'),
	productivity_upper_bound: yup
		.number()
		.typeError('This value must be a number.')
		.min(0, 'This value must be greater than or equal to 0.')
		.max(1, 'This value must be less than 1.')
		.moreThan(yup.ref('productivity_lower_bound'), 'This value should be greater than the Productivity Lower Bound'),
});

// type we expect when form submitted, used to validate
export type UpdateFacilityNurseStaffingForm = yup.InferType<typeof updateFacilityNurseStaffingSchema>;

export function FacilityNurseStaffingAccordionItem({
	facility,
	readonly,
	fetching,
	rebuildingArtificial,
}: FacilityNurseStaffingAccordionItemProps) {
	const [setNurseStaffingFacilitySetting] = useSetNurseStaffingFacilitySettingMutation();
	const { createToast } = useToast();
	const [isWorking, setIsWorking] = useState<boolean>(false);

	// form state
	const {
		handleSubmit,
		setValue,
		reset,
		register,
		getValues,
		watch,
		formState: { errors, isDirty },
	} = useForm<UpdateFacilityNurseStaffingForm>({
		resolver: yupResolver(updateFacilityNurseStaffingSchema),
		defaultValues: {
			rn_vacation_weeks: facility?.rn_vacation_weeks,
			surgical_tech_vacation_weeks: facility?.surgical_tech_vacation_weeks,
			variable_staff_vacation_weeks: facility?.variable_staff_vacation_weeks,
			indirect_staff_vacation_weeks: facility?.indirect_staff_vacation_weeks,
			rn_weekly_clinical_hours: facility?.rn_weekly_clinical_hours,
			surgical_tech_weekly_clinical_hours: facility?.surgical_tech_weekly_clinical_hours,
			variable_staff_weekly_clinical_hours: facility?.variable_staff_weekly_clinical_hours,
			indirect_staff_weekly_clinical_hours: facility?.indirect_staff_weekly_clinical_hours,
			productivity_lower_bound: facility?.productivity_lower_bound,
			productivity_upper_bound: facility?.productivity_upper_bound,
		},
		mode: 'onTouched',
	});

	// need this for conditional rendering of weekends in primetime settings table, otherwise weekend rows stop being rendered
	// on checkbox events. Watches all inputs and refreshes when change detected.
	watch();

	const onSubmit = async (formData: UpdateFacilityNurseStaffingForm) => {
		// loading state
		setIsWorking(true);
		const response = await setNurseStaffingFacilitySetting({
			facility_id: facility?.id,
			rn_vacation_weeks: formData.rn_vacation_weeks,
			surgical_tech_vacation_weeks: formData.rn_vacation_weeks,
			variable_staff_vacation_weeks: formData.rn_vacation_weeks,
			indirect_staff_vacation_weeks: formData.rn_vacation_weeks,
			rn_weekly_clinical_hours: formData.rn_weekly_clinical_hours,
			surgical_tech_weekly_clinical_hours: formData.surgical_tech_weekly_clinical_hours,
			variable_staff_weekly_clinical_hours: formData.variable_staff_weekly_clinical_hours,
			indirect_staff_weekly_clinical_hours: formData.indirect_staff_weekly_clinical_hours,
			productivity_lower_bound: formData.productivity_lower_bound,
			productivity_upper_bound: formData.productivity_upper_bound,
		});

		handleApiResponse(response, {
			success: (data) => {
				setIsWorking(false);
				createToast({
					title: 'Successfully saved settings',
					variant: 'success',
				});
			},
			error: (error) => {
				createToast({
					title: getQueryError(error),
					variant: 'error',
				});
			},
		});
		reset(
			{},
			{
				keepValues: true,
			}
		); // resets isDirty but keeps values as is
		setIsWorking(false);
	};

	const is_artificial = facility?.name.includes('*');

	return (
		<Accordion type='multiple' itemStyle={is_artificial ? 'dark' : 'contained'} className='mb-2'>
			<Accordion.Item value={facility ? getFacilityName(facility) : ''}>
				<form autoComplete='off' noValidate onSubmit={handleSubmit(onSubmit)}>
					<section>
						{' '}
						<div className='flex my-8 items-middle h-auto'>
							<div className='w-80 mr-6'>
								<p className='font-bold text-p2 mb-0.5'>Average weekly clinical hours per staff type</p>
								<p className='mt-1 text-p3 text-gray-400 italic'>
									This value represents the average expected clinical hours by staff type.
								</p>
							</div>
							<div className='flex flex-col'>
								<div className='flex items-baseline w-96'>
									<p className='text-left mr-2 text-p2 w-36 text-gray-700 tracking-wider uppercase font-secondary'>
										Registered Nurse
									</p>
									<TextField
										className='w-44'
										label={''}
										errorMessage={errors?.rn_weekly_clinical_hours?.message}
										{...register(`rn_weekly_clinical_hours`)}
									/>
									<p className='text-left text-p2 text-gray-700 tracking-wider ml-1 font-secondary'>hrs</p>
								</div>
								<div className='flex items-baseline w-96'>
									<p className='text-left mr-2 text-p2 w-36 text-gray-700 tracking-wider uppercase font-secondary'>
										Surgical Tech
									</p>
									<TextField
										className='w-44'
										label={''}
										errorMessage={errors?.surgical_tech_weekly_clinical_hours?.message}
										{...register(`surgical_tech_weekly_clinical_hours`)}
									/>
									<p className='text-left text-p2 text-gray-700 tracking-wider ml-1 font-secondary'>hrs</p>
								</div>
								<div className='flex items-baseline w-96'>
									<p className='text-left mr-2 text-p2 w-36 text-gray-700 tracking-wider uppercase font-secondary'>
										Variable Staff
									</p>
									<TextField
										className='w-44'
										label={''}
										errorMessage={errors?.variable_staff_weekly_clinical_hours?.message}
										{...register(`variable_staff_weekly_clinical_hours`)}
									/>
									<p className='text-left text-p2 text-gray-700 tracking-wider ml-1 font-secondary'>hrs</p>
								</div>
								<div className='flex items-baseline w-96'>
									<p className='text-left mr-2 text-p2 w-36 text-gray-700 tracking-wider uppercase font-secondary'>
										Indirect Staff
									</p>
									<TextField
										className='w-44'
										label={''}
										errorMessage={errors?.indirect_staff_weekly_clinical_hours?.message}
										{...register(`indirect_staff_weekly_clinical_hours`)}
									/>
									<p className='text-left text-p2 text-gray-700 tracking-wider ml-1 font-secondary'>hrs</p>
								</div>
							</div>
						</div>
						<div className='flex my-8 items-middle mt-2'>
							<div className='w-80 mr-6'>
								<p className='font-bold text-p2 mb-0.5'>Average vacation weeks per staff type</p>
								<p className='mt-1 text-p3 text-gray-400 italic'>
									This value represents the average expected weeks of vacation by staff type. This decreases the amount of
									clinical staff coverage provided.
								</p>
							</div>
							<div className='flex flex-col w-96'>
								<div className='flex items-baseline'>
									<p className='text-left mr-2 text-p2 w-36 text-gray-700 tracking-wider uppercase font-secondary'>
										Registered Nurse
									</p>
									<TextField
										className='w-44'
										label={''}
										errorMessage={errors?.rn_vacation_weeks?.message}
										{...register(`rn_vacation_weeks`)}
									/>
									<p className='text-left text-p2 text-gray-700 tracking-wider ml-1 font-secondary'>weeks</p>
								</div>
								<div className='flex items-baseline'>
									<p className='text-left mr-2 text-p2 w-36 text-gray-700 tracking-wider uppercase font-secondary'>
										Surgical Tech
									</p>
									<TextField
										className='w-44'
										label={''}
										errorMessage={errors?.surgical_tech_vacation_weeks?.message}
										{...register(`surgical_tech_vacation_weeks`)}
									/>
									<p className='text-left text-p2 text-gray-700 tracking-wider ml-1 font-secondary'>weeks</p>
								</div>
								<div className='flex items-baseline'>
									<p className='text-left mr-2 text-p2 w-36 text-gray-700 tracking-wider uppercase font-secondary'>
										Variable Staff
									</p>
									<TextField
										className='w-44'
										label={''}
										errorMessage={errors?.variable_staff_vacation_weeks?.message}
										{...register(`variable_staff_vacation_weeks`)}
									/>
									<p className='text-left text-p2 text-gray-700 tracking-wider ml-1 font-secondary'>weeks</p>
								</div>
								<div className='flex items-baseline'>
									<p className='text-left mr-2 text-p2 w-36 text-gray-700 tracking-wider uppercase font-secondary'>
										Indirect Staff
									</p>
									<TextField
										className='w-44'
										label={''}
										errorMessage={errors?.indirect_staff_vacation_weeks?.message}
										{...register(`indirect_staff_vacation_weeks`)}
									/>
									<p className='text-left text-p2 text-gray-700 tracking-wider ml-1 font-secondary'>weeks</p>
								</div>
							</div>
						</div>
						<div className='flex my-8 items-middle mt-2'>
							<div className='w-80 mr-6'>
								<p className='font-bold text-p2 mb-0.5'>Productivity Bounds</p>
								<p className='mt-1 text-p3 text-gray-400 italic'>
									These bounds represent the target range for total staff productivity.
								</p>
							</div>
							<div className='flex flex-col w-80'>
								<div className='flex items-baseline'>
									<p className='text-left mr-2 text-p2 w-36 text-gray-700 tracking-wider uppercase font-secondary'>
										Productivity Lower Bound
									</p>
									<TextField
										className='w-44'
										label={''}
										errorMessage={errors?.productivity_lower_bound?.message}
										{...register(`productivity_lower_bound`)}
									/>
								</div>
								<div className='flex items-baseline'>
									<p className='text-left mr-2 text-p2 w-36 text-gray-700 tracking-wider uppercase font-secondary'>
										Productivity Upper Bound
									</p>
									<TextField
										className='w-44'
										label={''}
										errorMessage={errors?.productivity_upper_bound?.message}
										{...register(`productivity_upper_bound`)}
									/>
								</div>
							</div>
						</div>
					</section>

					<div className='flex flex-col items-end'>
						<div className='flex justify-end pt-8'>
							<Button
								variant={!is_artificial ? 'primary' : 'tertiary-ghost'}
								sizeX='md'
								sizeY='md'
								type='submit'
								disabled={!isDirty}
								isWorking={isWorking}
								onClick={() => {
									// // Values must at least be 1, these values are dropped in the onSubmit function
									// primetimeDowState.forEach(({ roomCountField, active }) => {
									// 	if (!active && getValues(roomCountField) === 0) {
									// 		setValue(roomCountField, 1);
									// 	}
									// });
									// // same goes for weekends
									// if (!getValues('primetime_weekends')) {
									// 	setValue('sunday_rooms_count', 1);
									// 	setValue('saturday_rooms_count', 1);
									// }
								}}
							>
								Save
							</Button>
						</div>
					</div>
				</form>
			</Accordion.Item>
		</Accordion>
	);
}
