import classNames from 'classnames';

import type { InputHTMLAttributes } from 'react';

export interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
	/** The text or markup to use as a label for this radio button. */
	label: string | React.ReactElement;

	/** The name of the group to which this radio button belongs. This is required to better enforce the differing use cases of radio buttons and checkboxes. */
	name: string;
}

export function RadioButton({ label, name, className, disabled, ...props }: RadioButtonProps) {
	return (
		<label
			className={classNames(
				'flex gap-4 items-center',
				{ 'cursor-not-allowed': disabled, 'cursor-pointer': !disabled },
				className
			)}
		>
			<input
				type='radio'
				name={name}
				className={classNames(
					'appearance-none relative h-[28px] w-[28px] p-[4px] grid items-center rounded-full cursor-[inherit]',
					'border-gray-200 hover:enabled:border-blue-600 active:enabled:bg-blue-50',
					'focus-visible:outline-none focus-visible:bg-blue-50 focus-visible:border-blue-600',
					'transition-[background-color,border-color]',
					// `:before` pseudo-element styles
					'before:block before:h-full before:w-full before:rounded-full',
					'before:border-2 before:border-inherit',
					'before:transition-[background-color,border-color]',
					'checked:before:border-blue-500',
					'disabled:before:bg-gray-50',
					'checked:disabled:before:border-gray-300',
					// `:after` pseudo-element styles
					'after:block after:h-[10px] after:w-[10px] after:rounded-full after:bg-blue-500 after:opacity-0',
					'after:absolute after:left-1/2 after:top-1/2 after:-translate-x-1/2 after:-translate-y-1/2',
					'after:transition-opacity',
					'checked:after:opacity-100',
					'checked:disabled:after:bg-gray-300'
				)}
				disabled={disabled}
				{...props}
			/>
			{label}
		</label>
	);
}

export default RadioButton;
