import classNames from 'classnames';

export function ReturnToTopButton() {
	return (
		<a
			className={classNames(
				'grid place-items-center fixed bottom-4 right-4 h-11 w-11',
				'rounded-full bg-blue-500 text-white',
				'hover:text-white hover:bg-blue-700 transition-colors'
			)}
			href='#top'
		>
			<span className='material-symbol'>vertical_align_top</span>
		</a>
	);
}

export default ReturnToTopButton;
