/**
 * Logs messages to the console that are only visible when the node environment
 * is set to 'development', allowing enhanced real-time feedback during
 * development without affecting the production experience.
 *
 * @param itemsToLog The items to log to the console.
 */
export function logToDev(...itemsToLog: unknown[]) {
	if (process.env.NODE_ENV !== 'development') {
		return;
	}
	// eslint-disable-next-line no-console
	console.log(`[Merlin]`, ...itemsToLog);
}
