import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import { env } from 'utils';

export const browserHistory = createBrowserHistory();
export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
	config: {
		instrumentationKey: env('APPINSIGHTS_INSTRUMENTATION_KEY'),
		extensions: [reactPlugin],
		extensionConfig: {
			[reactPlugin.identifier]: { history: browserHistory },
		},
	},
}).loadAppInsights();
