import image from 'assets/images/first-system.svg';

export function NoSystems() {
	return (
		<div className='flex justify-center align-center'>
			<div className='bg-gray-50 rounded text-center p-12'>
				<img className='mx-auto my-16 block max-w-xl' alt='No systems exist yet' src={image} />
				<h1 className='text-h4'>Create your first Health System</h1>
			</div>
		</div>
	);
}

export default NoSystems;
