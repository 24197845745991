import React, { ForwardedRef } from 'react';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryLine, VictoryPie } from 'victory';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { BlockPreviewHeader, BlockUsageBarChart, InBlockBarChart, Tag } from 'pages/Block';
import { ChartLegend, Panel, PrintableTable } from 'components';
import { DateRangeState, FilterContextField, RangeFilterFieldState, UtilizationTypeState } from 'context';
import { determineBarWidth, determineDomainPadding, getColor, shortDOWToNumber } from 'utils';
import { BlockDetailItem, BlockScorecardResponseItem } from 'store/services/BlockScorecardService';
import image from 'assets/images/empty.svg';
import Logo from 'assets/images/branding/logo.svg';

interface PrintableBlockScorecardProps {
	utilizationType: FilterContextField<UtilizationTypeState>;
	turnoverTimeThreshold: FilterContextField<RangeFilterFieldState>;
	data: BlockScorecardResponseItem[] | undefined;
	groupByLabel: string;
	date: FilterContextField<DateRangeState>;
	healthsystem_name: string | undefined;
	facility_name: string | undefined;
	blockUtilPercentage: number | undefined;
}

interface InnerPrintableBlockScorecardProps {
	utilizationType: FilterContextField<UtilizationTypeState>;
	turnoverTimeThreshold: FilterContextField<RangeFilterFieldState>;
	data: BlockScorecardResponseItem | undefined;
	groupByLabel: string;
	date: FilterContextField<DateRangeState>;
	healthsystem_name: string | undefined;
	facility_name: string | undefined;
	blockUtilPercentage: number | undefined;
}

export const PrintableBlockScorecard = React.forwardRef(function Printable(
	{
		utilizationType,
		turnoverTimeThreshold,
		data,
		groupByLabel,
		date,
		healthsystem_name,
		facility_name,
		blockUtilPercentage = 70,
	}: PrintableBlockScorecardProps,
	ref: ForwardedRef<HTMLDivElement>
) {
	return (
		<div ref={ref}>
			{data?.map((page, i) => {
				return (
					<React.Fragment key={i}>
						<InnerPrintableBlockScorecard
							utilizationType={utilizationType}
							turnoverTimeThreshold={turnoverTimeThreshold}
							data={page}
							groupByLabel={groupByLabel}
							date={date}
							healthsystem_name={healthsystem_name}
							facility_name={facility_name}
							blockUtilPercentage={blockUtilPercentage}
						/>
					</React.Fragment>
				);
			})}
		</div>
	);
});

export function InnerPrintableBlockScorecard({
	utilizationType,
	turnoverTimeThreshold,
	data,
	groupByLabel,
	date,
	healthsystem_name,
	facility_name,
	blockUtilPercentage = 70,
}: InnerPrintableBlockScorecardProps) {
	const navigate = useNavigate();

	let util_arr = data?.block_details.map((row) => row.utilization) ?? [1];

	if (util_arr.length < 1) {
		util_arr = [1];
	}

	const util_target_met =
		util_arr?.filter((x) => x >= 0).reduce((a, b) => a + b, 0) / util_arr?.filter((x) => x >= 0).length >=
		(data?.utilization_target_percent ?? 1);

	const legend = [
		{
			label: 'Needs Attention',
			color: getColor('red'),
		},
		{
			label: 'On Target',
			color: getColor('green'),
		},
		{
			label: 'Off Target',
			color: getColor('yellow'),
		},
	];
	const dataMaxY = (data && Math.ceil(Math.max(...data.aggregate_block_utilization_dow.map((d) => d.y)) + 10)) ?? 100;

	return (
		<div className='p-16 pb-0'>
			<div>
				<div className='flex justify-between items-center pb-16'>
					<div className='flex flex-col text-center w-fit'>
						<img className='h-18 w-18' src={Logo} alt='Merlin' />
						<p className='opacity-50 text-h3'>Merlin</p>
					</div>
					<div className='flex flex-col opacity-70'>
						<p className='text-h2 font-semibold'>{`Block Scorecard`}</p>
						<p className='text-right text-p1'>
							{format(date?.applied.startDate, 'M/d/yyyy')} - {format(date?.applied.endDate, 'M/d/yyyy')}
						</p>
					</div>
				</div>
				<div className='flex flex-col w-fit py-14 opacity-70'>
					<p className='text-h1 font-semibold'>{data?.block_name}</p>
					<p className='text-right text-p1'>
						{healthsystem_name} - {facility_name}
					</p>
				</div>

				<div className='flex justify-between pb-3 items-center'>
					<BlockPreviewHeader data={data?.block_schedule ?? []} />
				</div>

				<div className='py-4'></div>

				{data && (
					<>
						<Panel title='Overview' headerContentCenterAlignment='left'>
							<div className='flex justify-around py-2  h-[23em]'>
								<div className='px-8 flex flex-col justify-between'>
									<div className='flex items-center justify-center gap-2 pt-3'>
										<span className='font-semibold text-p2'>Aggregate Block Utilization</span>
									</div>
									{data.aggregate_util_percentage > 0 ? (
										<div className='m-auto'>
											<svg width={200} height={200}>
												<VictoryPie
													innerRadius={80}
													width={200}
													height={200}
													radius={95}
													standalone={false}
													data={[data.aggregate_util_percentage, 100 - data.aggregate_util_percentage]}
													colorScale={['rgb(157, 196, 251)', 'black']}
													style={{ labels: { opacity: 0 } }}
												/>
												<VictoryLabel
													textAnchor='middle'
													style={{ fontSize: 32, fontFamily: 'inter' }}
													x={100}
													y={100}
													text={`${data.aggregate_util_percentage}%`}
												/>
											</svg>
										</div>
									) : (
										<div className='flex flex-col items-center'>
											<div className='flex flex-col w-full items-center gap-6 p-8 text-p2 text-gray-600 pt-20'>
												<img className='w-44' alt='An empty clipboard' src={image} />
												{'There are no data to display.'}
											</div>
										</div>
									)}
								</div>

								<div className='justify-self-center w-3/5'>
									<div className='flex justify-between gap-2'>
										<p className='px-3 text-left mr-2 py-2 pb-2 flex items-center justify-left gap-2'>
											<span className='text-p2 font-semibold'>{`${data.block_name} Block Utilization by ${groupByLabel}`}</span>
										</p>
									</div>
									{!data.aggregate_block_utilization_dow.every((item) => item.y === 0) ? (
										<div className='pt-4'>
											<ChartLegend options={legend} />
											<VictoryChart
												height={window.innerHeight / 1.6}
												width={window.innerWidth / 1.5}
												standalone={true}
												domainPadding={determineDomainPadding(data.aggregate_block_utilization_dow?.length)}
												maxDomain={{ y: dataMaxY > 100 ? dataMaxY : 100 }}
												padding={{ top: 20, bottom: 80, left: 120, right: window.innerWidth / 15 }}
											>
												<VictoryLine
													y={() => 85}
													style={{ data: { strokeDasharray: 2, strokeWidth: 1, stroke: 'hsl(211 36% 59%)' } }}
												/>
												<VictoryAxis
													dependentAxis
													tickFormat={(t) => `${t}%`}
													style={{
														axis: { stroke: 'hsl(0 0% 90%)' },
														grid: { stroke: 'hsl(0 0% 90%)' },
														tickLabels: { fontFamily: 'Inter', fontSize: window.innerWidth / 75 },
													}}
												/>
												<VictoryAxis
													style={{
														axis: { stroke: 'hsl(0 0% 90%)' },
														tickLabels: {
															fontFamily: 'Inter',
															fontSize: 12,
														},
													}}
													tickLabelComponent={
														<VictoryLabel
															data={data.aggregate_block_utilization_dow}
															style={{
																fontFamily: 'Inter',
																fontSize: window.innerWidth / 75,
															}}
														/>
													}
												/>
												<VictoryLine
													y={() => blockUtilPercentage}
													style={{ data: { strokeDasharray: 2, strokeWidth: 1, stroke: 'hsl(211 36% 59%)' } }}
												/>
												<VictoryBar
													barWidth={determineBarWidth(data.aggregate_block_utilization_dow?.length)}
													style={{
														data: {
															// Use RGB values for the bar color to trigger a more natural color space interpolation while
															// animating, as HSL hue rotation tends to feel too extreme.
															// https://github.com/d3/d3-interpolate#color-spaces
															fill: ({ datum }) => {
																const value = datum.y;
																if (value < 40) {
																	return getColor('red');
																} else if ((value >= 40 && value < blockUtilPercentage) || value > 85) {
																	return getColor('yellow');
																} else {
																	return getColor('green');
																}
															},
														},
														labels: {
															fontFamily: 'Inter',
															fontSize: window.innerWidth / 85,
														},
													}}
													cornerRadius={{ top: 4 }}
													data={data.aggregate_block_utilization_dow}
													labels={({ datum }) => `${Math.round(datum.y)}%`}
												/>

												<VictoryAxis
													dependentAxis
													orientation='right'
													tickValues={[(blockUtilPercentage + 85) / 2]}
													tickFormat={() => [`${blockUtilPercentage}% - 85%`, `Target Range`]}
													style={{ axis: { strokeWidth: 0 }, tickLabels: { padding: 10 } }}
													tickLabelComponent={
														<VictoryLabel
															lineHeight={[1.3, 1.3]}
															style={[
																{ fontFamily: 'Inter', fontSize: window.innerWidth / 120, fontWeight: 600 },
																{ fontFamily: 'Inter', fontSize: window.innerWidth / 130 },
															]}
														/>
													}
												/>
											</VictoryChart>
										</div>
									) : (
										<div className='flex flex-col items-center'>
											<div className='flex flex-col w-full items-center gap-6 p-8 text-p2 text-gray-600 pt-20'>
												<img className='w-44' alt='An empty clipboard' src={image} />
												{'There are no data to display.'}
											</div>
										</div>
									)}
								</div>
							</div>
						</Panel>
						<div className='py-4'></div>
						<Panel
							title={''}
							noHeader={true}
							isEmpty={data.surgeon_block_minutes_contributions.length > 0 || data.release_patterns.length > 0 ? false : true}
							headerContentRight={
								<div className='flex items-center gap-2'>
									<Tag>01/01/2022</Tag> &ndash; <Tag>01/31/2022</Tag>
								</div>
							}
							headerContentCenterAlignment='left'
						>
							<div className='flex justify-around py-2 h-[22em]'>
								{data.surgeon_block_minutes_contributions?.length > 1 && (
									<div className='justify-self-center max-w-md'>
										<div className='flex items-center gap-2 pb-4 font-semibold'>In Block-Minutes Contributions</div>
										<div>
											<InBlockBarChart
												values={data.surgeon_block_minutes_contributions}
												target={Math.max(...data.surgeon_block_minutes_contributions.map((x) => x.y))}
												printMode
											/>
										</div>
									</div>
								)}
								<div className='px-0 w-full'>
									<div className='flex flex-row justify-between items-start'>
										<div className='flex items-center justify-between gap-2 font-semibold mb-10'>Block Usage Minutes</div>
									</div>
									{!data.release_patterns.every((item) => item.y === 0) ? (
										<div className={`m-auto ${!(data.surgeon_block_minutes_contributions?.length > 1) && 'py-4'}`}>
											<BlockUsageBarChart
												data={{ release_patterns: data.release_patterns }}
												groupBlock={data.surgeon_block_minutes_contributions?.length > 1}
												printMode
											/>
										</div>
									) : (
										<div className='flex flex-col items-center w-full'>
											<div className='flex flex-col w-full items-center gap-6 p-8 text-p2 text-gray-600 pt-20'>
												<img className='w-44' alt='An empty clipboard' src={image} />
												{'There are no data to display.'}
											</div>
										</div>
									)}
								</div>
							</div>
						</Panel>
						<div className='py-4'></div>
						{chunkArray(data.block_details, 25).map((item, index) => (
							<div className={`flex h-[90em] min-h-[90em] ${index > 0 ? 'mt-16' : ''}`} key={index}>
								<PrintableTable
									title='Block Details'
									tooltipContent={`This table provides a breakdown of the components that factor into block utilization, like in block minutes, turnover, and allocated time. It also includes other helpful values like out-block minutes, case volume, flips, and add-ons. Click on a row to see specific case details for the day.`}
									goToHelpID='commonDefinitions'
									clickableRows={(row) => {
										navigate(
											`/block-scorecard/case-details?block_detail_id=${row.block_id}&block_date=${row.date}&util_type=${utilizationType.applied}&turnover_threshold=${turnoverTimeThreshold.applied}&block_name=${data.block_name}`
										);
									}}
									pageSizes={[50, 50, 50]}
									columns={[
										{
											header: 'Date Found',
											accessorKey: 'date',
											cell: ({ row }) => (
												<span className='py-1 text-p2 text-center'>{row.original.date.toLocaleString('en-US')}</span>
											),
											sortingFn: (rowA, rowB, columnId) => {
												const a = new Date(rowA.getValue(columnId)).getTime();
												const b = new Date(rowB.getValue(columnId)).getTime();
												return b > a ? 1 : -1;
											},
											enableGlobalFilter: false,
											meta: {
												headerClass: 'whitespace-pre-line w-10',
											},
										},
										{
											header: 'Dow',
											accessorKey: 'day_of_week',
											enableGlobalFilter: false,
											sortingFn: (rowA, rowB) => {
												const a = shortDOWToNumber(rowA.original.day_of_week);
												const b = shortDOWToNumber(rowB.original.day_of_week);
												return b > a ? 1 : -1;
											},
										},
										{
											header: 'Allocated',
											accessorKey: 'allocated',
											enableGlobalFilter: false,
											cell: ({ row }) => `${row.original.allocated}m`,
										},
										{
											header: 'In-Block / Out-Block',
											accessorKey: 'in_block',
											enableGlobalFilter: false,
											cell: ({ row }) => {
												return <p className='whitespace-nowrap'>{`${row.original.in_block}m / ${row.original.out_block}m`}</p>;
											},
											meta: {
												headerClass: 'whitespace-pre-line',
											},
										},
										{
											header: 'Turnover',
											accessorKey: 'turnover',
											enableGlobalFilter: false,
											cell: ({ row }) => `${row.original.turnover}m`,
										},
										{
											header: 'Block Turnover',
											accessorKey: 'block_turnover',
											enableGlobalFilter: false,
											cell: ({ row }) => `${row.original.block_turnover}m`,
											meta: {
												headerClass: 'whitespace-pre-line',
											},
										},
										{
											header: 'Flips',
											accessorKey: 'flip_count',
											enableGlobalFilter: false,
										},
										{
											header: 'Cases',
											accessorKey: 'total_cases',
											enableGlobalFilter: false,
										},
										{
											header: 'Add Ons',
											accessorKey: 'add_on_vol',
											enableGlobalFilter: false,
											meta: {
												headerClass: 'whitespace-pre-line',
											},
										},
										{
											header: 'Voluntary Release',
											accessorKey: 'voluntary_release',
											enableGlobalFilter: false,
											cell: ({ row }) => {
												return <p>{row.original.voluntary_release >= 0 ? `${row.original.voluntary_release}m` : 'nullified'}</p>;
											},
											meta: {
												headerClass: 'whitespace-pre-line',
											},
										},
										{
											header: 'Automatic Release',
											accessorKey: 'automatic_release',
											enableGlobalFilter: false,
											cell: ({ row }) => {
												return <p>{row.original.automatic_release >= 0 ? `${row.original.automatic_release}m` : 'nullified'}</p>;
											},
											meta: {
												headerClass: 'whitespace-pre-line',
											},
										},
										{
											header: 'Rooms Used',
											accessorKey: 'unique_rooms_used',
											enableGlobalFilter: false,
											meta: {
												headerClass: 'whitespace-pre-line',
											},
										},
										{
											header: 'Utilization',
											accessorKey: 'utilization',
											enableGlobalFilter: false,
											cell: ({ row }) => {
												let color =
													row.original.utilization >= data.utilization_target_percent
														? 'text-green-500 text-center'
														: 'text-red-500 text-center ';

												const isValueGiven = row.original.utilization >= 0;

												if (!isValueGiven) {
													color = 'text-gray-300 text-center';
												}
												return <p className={color}>{isValueGiven ? `${row.original.utilization}%` : '---'}</p>;
											},
											meta: {
												footerClass: util_target_met ? 'bg-green-50' : 'bg-red-50',
												headerClass: util_target_met ? 'bg-green-50 text-center pl-3' : 'bg-red-50 text-center pl-3',
											},
										},
									]}
									data={item as BlockDetailItem[]}
								/>
							</div>
						))}
						<div className={`flex h-[90em] mt-16 opacity-0`}>
							<PrintableTable
								title='Block Details'
								tooltipContent={`This table provides a breakdown of the components that factor into block utilization, like in block minutes, turnover, and allocated time. It also includes other helpful values like out-block minutes, case volume, flips, and add-ons. Click on a row to see specific case details for the day.`}
								goToHelpID='commonDefinitions'
								clickableRows={(row) => {
									navigate(
										`/block-scorecard/case-details?block_detail_id=${row.block_id}&block_date=${row.date}&util_type=${utilizationType.applied}&turnover_threshold=${turnoverTimeThreshold.applied}&block_name=${data.block_name}`
									);
								}}
								pageSizes={[50, 50, 50]}
								columns={[
									{
										header: 'Date Found',
										accessorKey: 'date',
										cell: ({ row }) => (
											<span className='py-1 text-p2 text-center'>{row.original.date.toLocaleString('en-US')}</span>
										),
										sortingFn: (rowA, rowB, columnId) => {
											const a = new Date(rowA.getValue(columnId)).getTime();
											const b = new Date(rowB.getValue(columnId)).getTime();
											return b > a ? 1 : -1;
										},
										enableGlobalFilter: false,
										meta: {
											headerClass: 'whitespace-pre-line w-10',
										},
									},
									{
										header: 'Dow',
										accessorKey: 'day_of_week',
										enableGlobalFilter: false,
										sortingFn: (rowA, rowB) => {
											const a = shortDOWToNumber(rowA.original.day_of_week);
											const b = shortDOWToNumber(rowB.original.day_of_week);
											return b > a ? 1 : -1;
										},
									},
									{
										header: 'Allocated',
										accessorKey: 'allocated',
										enableGlobalFilter: false,
										cell: ({ row }) => `${row.original.allocated}m`,
									},
									{
										header: 'In-Block / Out-Block',
										accessorKey: 'in_block',
										enableGlobalFilter: false,
										cell: ({ row }) => {
											return <p className='whitespace-nowrap'>{`${row.original.in_block}m / ${row.original.out_block}m`}</p>;
										},
										meta: {
											headerClass: 'whitespace-pre-line',
										},
									},
									{
										header: 'Turnover',
										accessorKey: 'turnover',
										enableGlobalFilter: false,
										cell: ({ row }) => `${row.original.turnover}m`,
									},
									{
										header: 'Block Turnover',
										accessorKey: 'block_turnover',
										enableGlobalFilter: false,
										cell: ({ row }) => `${row.original.block_turnover}m`,
										meta: {
											headerClass: 'whitespace-pre-line',
										},
									},
									{
										header: 'Flips',
										accessorKey: 'flip_count',
										enableGlobalFilter: false,
									},
									{
										header: 'Cases',
										accessorKey: 'total_cases',
										enableGlobalFilter: false,
									},
									{
										header: 'Add Ons',
										accessorKey: 'add_on_vol',
										enableGlobalFilter: false,
										meta: {
											headerClass: 'whitespace-pre-line',
										},
									},
									{
										header: 'Voluntary Release',
										accessorKey: 'voluntary_release',
										enableGlobalFilter: false,
										cell: ({ row }) => {
											return <p>{row.original.voluntary_release >= 0 ? `${row.original.voluntary_release}m` : 'nullified'}</p>;
										},
										meta: {
											headerClass: 'whitespace-pre-line',
										},
									},
									{
										header: 'Automatic Release',
										accessorKey: 'automatic_release',
										enableGlobalFilter: false,
										cell: ({ row }) => {
											return <p>{row.original.automatic_release >= 0 ? `${row.original.automatic_release}m` : 'nullified'}</p>;
										},
										meta: {
											headerClass: 'whitespace-pre-line',
										},
									},
									{
										header: 'Rooms Used',
										accessorKey: 'unique_rooms_used',
										enableGlobalFilter: false,
										meta: {
											headerClass: 'whitespace-pre-line',
										},
									},
									{
										header: 'Utilization',
										accessorKey: 'utilization',
										enableGlobalFilter: false,
										cell: ({ row }) => {
											let color =
												row.original.utilization >= data.utilization_target_percent
													? 'text-green-500 text-center'
													: 'text-red-500 text-center ';

											const isValueGiven = row.original.utilization >= 0;

											if (!isValueGiven) {
												color = 'text-gray-300 text-center';
											}
											return <p className={color}>{isValueGiven ? `${row.original.utilization}%` : '---'}</p>;
										},
										meta: {
											footerClass: util_target_met ? 'bg-green-50' : 'bg-red-50',
											headerClass: util_target_met ? 'bg-green-50 text-center pl-3' : 'bg-red-50 text-center pl-3',
										},
									},
								]}
								data={[] as BlockDetailItem[]}
							/>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

function chunkArray(myArray: unknown[], chunk_size: number) {
	let index = 0;
	const arrayLength = myArray.length;
	const tempArray = [];

	for (index = 0; index < arrayLength; index += chunk_size) {
		const inner_temp = myArray.slice(index, index + chunk_size);
		tempArray.push(inner_temp);
	}

	return tempArray;
}
