import { useRef, useState } from 'react';
import { inputCSS } from 'react-select/dist/declarations/src/components/Input';

import {
	getQueryError,
	handleApiResponse,
	UpdateSystemApiRequest,
	useAppSelector,
	useGetSystemsQuery,
	useSetArtificialFacilityMutation,
	useSystem,
	useUpdateSystemMutation,
} from 'store';
import { Facility, Healthsystem } from 'models';
import { FacilityConfigAccordionItem, FacilityConfiguration, HealthSystemConfigAccordion } from 'pages/Settings/Tabs';
import { Accordion, Button, DataTable, Dialog, MultiSelect, TextField, WorkingIndicator } from 'components';
import { inputFieldRegex } from 'utils';
import { useToast } from 'context';
import { LoadingIndicator } from 'components/Select/subcomponents';

export function HealthSystemConfiguration() {
	// get the list of facilities under the current selected system
	const { isFetching } = useSystem();
	const { selectedSystem } = useAppSelector((state) => state.userState);
	const { data } = useGetSystemsQuery({
		healthsystem_id: selectedSystem,
	});
	const { createToast } = useToast();
	const [loading, setLoading] = useState<number | null>(null);

	// load mutation
	const [updateSystem] = useUpdateSystemMutation();

	const updateHealthsystem = async (postObj: UpdateSystemApiRequest) => {
		setLoading(postObj.id);
		const res = await updateSystem(postObj);
		handleApiResponse(res, {
			success: (payload) => {
				createToast({
					title: 'Health System Updated!',
					variant: 'success',
				});
				setLoading(null);
			},
			error: (payload) => {
				createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
				setLoading(null);
			},
		});
	};

	const n_facilities = data?.facilities ? data?.facilities.length : 0;

	return (
		<div>
			<DataTable
				columns={[
					{
						header: 'Status',
						accessorKey: 'id',
						enableGlobalFilter: true,
						cell: ({ row }) => {
							// if ! is present in name index 0, then it is archived
							const isActive = !(row.original.name.at(0) === '!');

							if (loading === row.original.id || isFetching) {
								return (
									<div className='h-4 w-4'>
										<LoadingIndicator />
									</div>
								);
							}

							if (!isActive) {
								return (
									<p
										className='text-orange-500 opacity-50 cursor-pointer hover:underline hover:font-bold w-28'
										onClick={() => {
											const updatedName = row.original.name.slice(1);
											updateHealthsystem({ id: row.original.id, name: updatedName });
										}}
									>
										Archived
									</p>
								);
							} else {
								return (
									<p
										className='text-green-600 font-semibold cursor-pointer hover:underline hover:font-bold w-28'
										onClick={() => {
											const updatedName = `!${row.original.name}`;
											updateHealthsystem({ id: row.original.id, name: updatedName });
										}}
									>
										Active
									</p>
								);
							}
						},
						enableSorting: false,
					},
					{
						header: 'Name',
						accessorKey: 'name',
						enableGlobalFilter: true,
						cell: ({ row }) => {
							// remove ! from zero index if presnet
							const name = row.original.name[0] === '!' ? row.original.name.slice(1) : row.original.name;
							return <p>{name}</p>;
						},
					},
					{
						header: 'Licenses',
						accessorKey: 'total_licenses',
						enableGlobalFilter: false,
					},
					{
						header: 'Users',
						accessorKey: 'total_users',
						enableGlobalFilter: false,
					},
					{
						header: 'Facilities',
						accessorKey: 'total_facilities',
						enableGlobalFilter: false,
					},
					{
						header: 'State',
						accessorKey: 'state',
						enableGlobalFilter: true,
					},
					{
						header: 'EHR',
						accessorKey: 'ehr',
						enableGlobalFilter: true,
					},
				]}
				rowSubview={(row) => {
					if (row.getIsSelected()) {
						return (
							<div className='flex flex-col'>
								<HealthSystemConfigAccordion system={row.original} />
								<p className='text-black mb-3 ml-3'>Facilities</p>
								<FacilityConfiguration providedSystem={row.original} />
							</div>
						);
					} else {
						return null;
					}
				}}
				data={
					data?.healthsystems?.map((system: Healthsystem, indx: number) => {
						return system;
					}) ?? []
				}
				title={'All Health Systems'}
			/>
		</div>
	);
}

export default HealthSystemConfiguration;
