import { useState } from 'react';

import { Dialog, MultiSelect, Select } from 'components';
import { useAlert, useFilters, useToast } from 'context';
import { ShortOption } from 'store';

interface AddEntityMappingDialogProps {
	children: React.ReactNode;
	entityOptions: ShortOption[];
	entityData?: ShortOption;
	aliasIDs: number[];
	save: (fromEntity: number, toEntity: number) => Promise<void>;
	selectedExisting?: number;
	selectedImported?: number;
	entityName: string;
	pickableEntites: ShortOption[];
}

export function AddEntityMappingDialog({
	entityOptions,
	entityData,
	save,
	entityName,
	aliasIDs,
	children,
	selectedExisting,
	pickableEntites,
}: AddEntityMappingDialogProps) {
	const { getAlertResponse } = useAlert();
	const toast = useToast();
	const [dialogOpen, setDialogOpen] = useState(false);
	const [isAdding, setIsAdding] = useState(false);

	const [existingName, setExistingName] = useState<ShortOption | undefined>(
		selectedExisting ? entityOptions.find((d) => d.id === selectedExisting) : undefined
	);
	const [importedName, setImportedName] = useState<ShortOption[]>(entityData ? [entityData] : []);

	const options = entityOptions
		.filter((d) => !(d.id in importedName.map((i) => i.id)))
		.filter((d) => !aliasIDs.includes(d.id))
		.map((item) => ({ value: item.id, label: item.name }));

	const onSubmit = async () => {
		setIsAdding(true);
		if (existingName) {
			for (const imported of importedName) {
				imported.id !== entityData?.id && (await save(imported.id, existingName.id));
			}
			toast.createToast({
				title: `${entityName} mapping saved.`,
				variant: 'success',
			});
			setIsAdding(false);
			setDialogOpen(false);
			setExistingName(undefined);
			setImportedName([]);
		}
	};
	return (
		<Dialog
			actionButtons={[
				{
					children: 'Submit',
					isWorking: isAdding,
					onClick: onSubmit,
				},
			]}
			sizeX='lg'
			title={`Add ${entityName} Mapping`}
			trigger={children}
			open={dialogOpen}
			onOpenChange={async (isBeingOpened) => {
				if (isBeingOpened) {
					setDialogOpen(true);
					return;
				}

				const alertResponse = await getAlertResponse({
					description:
						"Closing the window will clear any of the information you've entered in the form, are you sure you'd like to continue?",
					responseOptions: [
						{
							value: 'yes',
							label: 'Yes, close',
						},
					],
				});

				if (alertResponse === 'yes') {
					setDialogOpen(false);
					setExistingName(undefined);
					setImportedName([]);
				}
			}}
		>
			<div className={'min-h-[12rem]'}>
				<div className='flex justify-center gap-6'>
					<div className=''>
						<Select
							label={`Existing ${entityName}`}
							sizeY='sm'
							sizeX='lg'
							value={existingName ? { value: existingName?.id, label: existingName?.name } : undefined}
							options={pickableEntites.map((d) => ({ value: d.id, label: d.name }))}
							onChange={(newSelection) => {
								if (newSelection) {
									setExistingName({
										id: newSelection.value,
										name: newSelection.label,
									});
								}
								selectedExisting && setImportedName([]);
							}}
						/>

						{existingName && (
							<>
								<p className='font-secondary text-p2 my-2 text-gray-300'>Preview</p>
								<p className='bg-blue-50 text-black px-6 text-p3 font-primary rounded-md w-fit py-2 h-max'>
									{existingName.name}
								</p>
							</>
						)}
						<p className='text-p3 text-gray-400 py-1 italic max-w-[18rem] mt-2'>
							{`Note: once mapped to an existing ${entityName.toLowerCase()} name, aliases will no longer appear within Merlin (including filters,
							reports, blocks, etc). You will need to use the existing ${entityName.toLowerCase()} name going forward. Be sure to double check
							mappings before submitting and contact the Merlin team with any questions.`}
						</p>
					</div>
					<span className='material-symbols-outlined pt-8'>west</span>
					<div className=''>
						<MultiSelect
							label='Multiple Aliases'
							sizeY='sm'
							sizeX='lg'
							value={importedName.map((entity) => ({ label: entity.name, value: entity.id }))}
							options={
								existingName
									? options.filter((d) => d.value !== existingName.id).map((item) => ({ value: item.value, label: item.label }))
									: []
							}
							disabled={!existingName && !selectedExisting}
							onChange={(selection) => {
								setImportedName(selection.map((entity) => ({ name: entity.label, id: entity.value })));
							}}
						/>

						{importedName.length > 0 && (
							<div className=''>
								<p className='font-secondary text-p2 my-2 text-gray-300'>Preview</p>
								<div className='flex gap-2 max-h-56 overflow-y-auto'>
									<div className='flex flex-col gap-2'>
										{importedName.map((d) => (
											<p className='bg-blue-50 text-black px-6 text-p3 font-primary rounded-md w-fit py-2' key={d.id}>
												{d.name}
											</p>
										))}
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</Dialog>
	);
}

export default AddEntityMappingDialog;
