import { ApiEndpoints } from 'models/api';
import { BlockSetting, FacilityBlockSetting } from 'models/models';
import { standardApi } from 'store/api';
import { BlockPatternDetailsPOST } from 'pages/Block/components';

import { ShortOption } from './FilterService';

interface BlockForecastRequest {
	start_date: string;
	end_date: string;
	procedures: string[];
	tot_threshold: number;
	growth_factor: number;
	block_length: number;
	cysto_rooms: ShortOption[];
	facility_breakdown: boolean;
	facilities: number[];
}

export interface BlockFillRateRequest {
	facility_id: number | null;
	healthsystem_id: number | null;
	block_id: number;
}

type BlockScheduleWithAddOnProxyItem = {
	label: Date;
	scheduled_in_block_minutes: number;
	estimated_add_on_minutes: number;
	allocated_minutes: number;
};

// this corresponds to a row in the fill rate details table
type BlockScheduleDetailItem = {
	id: number;
	date: Date;
	scheduled_in_block_minutes: number;
	scheduled_out_block_minutes: number;
	scheduled_turnover_minutes: number;
	allocated_minutes: number;
	number_of_rooms: number;
	fill_rate: number;
	number_of_scheduled_cases: number;
	day_of_week: string;
};

export interface BlockFillRateResponse {
	block_fill_rate_with_add_on_proxy: BlockScheduleWithAddOnProxyItem[];
	block_schedule_details: BlockScheduleDetailItem[];
}

const blocks = standardApi.enhanceEndpoints({ addTagTypes: ['Core', 'Blocks'] });

/**
 * Provides endpoints for retrieving and setting block settings
 */
export const blockServiceApi = blocks.injectEndpoints({
	endpoints: (build) => ({
		requestBlockForecast: build.mutation<any, BlockForecastRequest>({
			query: (body) => ({
				url: ApiEndpoints.GET_BLOCK_FORECAST,
				method: 'POST',
				body,
			}),
		}),
		getBlockFillRate: build.query<BlockFillRateResponse, BlockFillRateRequest>({
			query: (body) => ({
				url: ApiEndpoints.GET_BLOCK_FILL_RATE,
				method: 'POST',
				body,
			}),
			providesTags: ['Blocks'],
		}),
	}),
});

export const { useRequestBlockForecastMutation, useGetBlockFillRateQuery } = blockServiceApi;

export const {
	endpoints: { requestBlockForecast, getBlockFillRate },
} = blockServiceApi;
