import classNames from 'classnames';
import { forwardRef } from 'react';

import type { InputHTMLAttributes, Ref } from 'react';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
	/** The text or markup to use as a label for this checkbox. */
	label: string | React.ReactElement;

	/** A description of this checkbox's current validation error. This also applies styles to visually indicate the field contains an error. */
	errorMessage?: string;

	/** The manner in which the space occupied by an error message is handled. */
	errorMessageArea?: 'none' | 'reserved' | 'floating';
}

export const Checkbox = forwardRef(function CheckboxWithRef(
	{ label, className, disabled, errorMessage, errorMessageArea = 'none', ...props }: CheckboxProps,
	forwardedRef: Ref<HTMLInputElement>
) {
	return (
		<>
			<label
				className={classNames(
					'flex gap-4 items-center',
					{ 'cursor-not-allowed': disabled, 'cursor-pointer': !disabled },
					className
				)}
			>
				<input
					ref={forwardedRef}
					type='checkbox'
					className={classNames(
						'appearance-none relative h-[28px] w-[28px] p-[5px] rounded-sm cursor-[inherit]',
						'border-gray-200 hover:enabled:border-blue-600 active:enabled:bg-blue-50',
						'focus-visible:outline-none focus-visible:bg-blue-50 focus-visible:border-blue-600',
						'transition-[background-color,border-color]',
						// `:before` pseudo-element styles
						'before:block before:h-full before:w-full before:rounded-sm',
						'before:border-2 before:border-inherit',
						'before:transition-[background-color,border-color]',
						'checked:before:bg-blue-500 checked:before:border-blue-500',
						'disabled:before:bg-gray-50',
						'checked:disabled:before:bg-gray-300 checked:disabled:before:border-gray-300',
						// `:after` pseudo-element styles
						'after:block after:h-[12px] after:w-[7px] after:border-b-2 after:border-r-2 after:border-white after:opacity-0',
						'after:absolute after:left-1/2 after:top-1/2 after:-translate-x-1/2 after:-translate-y-[65%] after:rotate-45',
						'after:transition-opacity',
						'checked:after:opacity-100'
					)}
					disabled={disabled}
					{...props}
				/>
				{label}
			</label>
			<p
				className={classNames('mt-2 font-primary text-p2 text-red-400', {
					'absolute top-full': errorMessageArea === 'floating',
					hidden: !errorMessage && errorMessageArea !== 'reserved',
				})}
			>
				{errorMessage}
				{!errorMessage && errorMessageArea === 'reserved' && <>&nbsp;</>}
			</p>
		</>
	);
});

export default Checkbox;
