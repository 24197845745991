import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel } from 'victory';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

import { fontFamilySecondary, getPercentage, hiddenAxisStyles, toFullDate } from 'utils';
import { GaugeChart, LogoOverlay, MetricPanel, PageHeading, Tooltip } from 'components';
import { useAppSelector, useGetCoreDashboardQuery } from 'store';
import image from 'assets/images/empty.svg';
import { Routes } from 'models';
import { useToast } from 'context';

const linkList = [
	{
		label: 'Timely Starts',
		description: 'Measure how often your operating rooms start on time',
		route: Routes.TIMELY_STARTS,
	},
	{
		label: 'Timeline View',
		description: 'Visualize how your OR runs day-to-day',
		route: Routes.TIMELINE_VIEW,
	},
	{
		label: 'Turnover Time',
		description: 'Track the time difference between cases for ORs and surgeons',
		route: Routes.TURNOVER_TIME,
	},
	{
		label: 'Facility Utilization',
		description: 'See how many surgical case minutes fall into prime time',
		route: Routes.FACILITY_UTILIZATION,
	},
	{
		label: 'Scheduling Patterns',
		description: 'Understand when your Add-Ons occur',
		route: Routes.SCHEDULING_PATTERNS,
	},
	{
		label: 'Surgeon Scorecard',
		description: 'Dissect surgeon performance',
		route: Routes.SURGEON_SCORECARD,
	},
	{
		label: 'Surgical Volume',
		description: 'See a breakdown of your surgical volume',
		route: Routes.SURGICAL_VOLUME,
	},
	{
		label: 'Flip Rooms',
		description: 'Track the performance of surgeons that run cases concurrently',
		route: Routes.FLIP_ROOMS,
	},
];

export function Core() {
	const { selectedFacility } = useAppSelector((state) => state.userState);
	const { data, isFetching, error } = useGetCoreDashboardQuery({ facility_id: selectedFacility });

	// Handle api response to create toast message on every error.
	const { createToast } = useToast();
	useEffect(() => {
		if (error) {
			if ('data' in error) {
				createToast({
					title: `${error.data}`,
					variant: 'error',
				});
			} else {
				createToast({
					title: `There was an error connecting to the server.`,
					variant: 'error',
				});
			}
		}
	}, [createToast, error]);
	/**
	 * It is possible for the API to return "null" values for many metrics, if the facility is missing data.
	 * Hence, the default state for much of the UI is to behave as if the API will return "empty" datasets, and only once
	 * we receive valid metrics do we show the UI with data.
	 */
	let volumeDifference = null;
	let addonDifference = null;
	let fcotsDifference = null;
	let turnoverDifference = null;
	if (data && data.previous_month) {
		volumeDifference = data.volume.previous ? data.volume.current - data.volume.previous : null;
		addonDifference = !!data.addons.previous && !!data.addons.current ? data.addons.current - data.addons.previous : null;
		fcotsDifference = data.fcots.previous ? data.fcots.current - data.fcots.previous : null;
		turnoverDifference = data.fcots.previous ? data.fcots.current - data.fcots.previous : null;
	}
	const roomCount = data?.fcots.top_rooms.length ?? 5;
	const barPosition = { from: roomCount, to: roomCount > 4 ? 5 : 0 };

	return (
		<div>
			<div className='flex justify-between'>
				<PageHeading>Welcome to Core!</PageHeading>
				{/* 
					TODO: Implement soon.
					<Button sizeX='md' sizeY='md' variant='primary'>
						Export
					</Button>
				*/}
			</div>
			<div className='flex flex-col xl:flex-row'>
				<div className='w-full xl:w-3/4 xl:pr-4 relative'>
					{isFetching && <LogoOverlay backgroundColor='white' />}
					{data && (
						<>
							<div className='bg-blue-50 p-6'>
								<p>
									{data.current_month ? (
										<>
											<span className='font-semibold mr-4'>{data.current_month} Overview</span>
											{/* should probably make this a reusable "badge" component, used pretty often */}
											<span className='bg-blue-500 text-white mx-1 px-2.5 py-0.5 rounded font-secondary text-p2'>
												{toFullDate(new Date(data.overview_date_start))}
											</span>
											-
											<span className='bg-blue-500 text-white mx-1 px-2.5 py-0.5 rounded font-secondary text-p2'>
												{toFullDate(new Date(data.overview_date_end))}
											</span>
										</>
									) : (
										<span className='font-semibold mr-4'>This facility does not have any periods to analyze.</span>
									)}
								</p>
							</div>
							<div className='flex flex-col xl:flex-row gap-8 my-8'>
								<div className='basis-full bg-gray-50 rounded-b-md'>
									<MetricPanel
										left={{
											type: 'gauge',
											chartColor: 'fcots',
											label: 'FCOTS Rate',
											value: data.fcots.current,
											metric: '%',
											effect: createEffectWhenValid({
												args: [fcotsDifference, data.addons.previous],
												validWhen: (args) => !!(args[0] && args[1]),
												output: (args) => `${Math.abs(args[0])}% mth`,
											}),
											effectDirection: createEffectDirectionWhenValid({
												whenPositive: 'up',
												whenNegative: 'down',
												difference: fcotsDifference,
											}),
											effectColor: createEffectColorWhenValid({
												whenPositive: 'green',
												whenNegative: 'red',
												difference: fcotsDifference,
											}),
										}}
										right={{
											type: 'gauge',
											label: 'Target FCOTS Rate',
											value: data.fcots.target,
											metric: '%',
										}}
									/>
									{data.fcots.top_rooms.length ? (
										<div className='p-4'>
											<ChartHeading
												title={`Top ${data.fcots.top_rooms.length} (%) by Room`}
												description='Shows the First Case On Time Start rate at the room level for the time period listed.'
											/>
											{data.current_month && (
												<div>
													<VictoryChart>
														<VictoryAxis style={hiddenAxisStyles} />
														<VictoryAxis style={hiddenAxisStyles} />
														<VictoryBar
															domain={{ x: [barPosition.from, barPosition.to] }}
															horizontal
															barWidth={45}
															cornerRadius={2}
															labels={data.fcots.top_rooms.map((r) => `${r.name} - ${r.current}%`)}
															style={{ data: { fill: 'hsl(215 93% 80%)' } }}
															data={data.fcots.top_rooms.map((r) => ({
																x: r.name,
																y0: 0,
																y: r.current,
															}))}
															labelComponent={
																<VictoryLabel
																	verticalAnchor='middle'
																	dx={-10}
																	textAnchor={'end'}
																	style={{ fontFamily: fontFamilySecondary, fill: 'black' }}
																/>
															}
														/>
													</VictoryChart>
												</div>
											)}
										</div>
									) : (
										<div className='flex flex-col items-center'>
											<div className='flex flex-col w-full items-center gap-6 p-8 text-p2 text-gray-600 pt-20'>
												<img className='w-44' alt='An empty clipboard' src={image} />
												{'There are no data to display.'}
											</div>
										</div>
									)}
								</div>
								<div className='basis-full bg-gray-50 rounded-b-md'>
									<MetricPanel
										left={{
											type: 'bar',
											label: 'Avg Room TOT',
											value: data.turnover.current,
											metric: 'm',
											effect: createEffectWhenValid({
												validWhen: (args) => !!(args[0] && args[1]),
												output: (args) => `${Math.round(getPercentage(args[1], Math.abs(args[0])))}% mth`,
												args: [turnoverDifference, data.turnover.previous],
											}),
											effectDirection: createEffectDirectionWhenValid({
												whenPositive: 'up',
												whenNegative: 'down',
												difference: turnoverDifference,
											}),
											effectColor: createEffectColorWhenValid({
												whenPositive: 'red',
												whenNegative: 'green',
												difference: turnoverDifference,
											}),
										}}
										right={{
											type: 'bar',
											label: 'Target Room TOT',
											value: data.turnover.target,
											metric: 'm',
										}}
									/>
									{data.turnover.on_target.previous || data.turnover.on_target.current ? (
										<div className='p-4'>
											<ChartHeading
												title='On-Target Turnovers (%)'
												description='Shows the percentage of turnover durations that were at or below the facility target duration. To change your facility’s target, navigate to Settings > Facility Settings.'
											/>
											{data.current_month && (
												<div className='flex items-center justify-center gap-6 my-6 font-semibold text-center'>
													{!!data.turnover.on_target.previous && (
														<div className='xl:p-6 p-2'>
															<span className='font-secondary text-gray-500'>
																{data.previous_month ? data.previous_month.substring(0, 3) : ''}
															</span>
															<div className='xl:p-6 p-2 mt-4'>
																<GaugeChart
																	percentage={data.turnover.on_target.previous}
																	textColor={'black'}
																	radius={50}
																	strokeWidth={8}
																/>
															</div>
														</div>
													)}
													{!!data.turnover.on_target.current && (
														<div className='xl:p-6 p-2'>
															<span className='font-secondary'>{data.current_month ? data.current_month.substring(0, 3) : ''}</span>
															<div className='xl:p-6 p-2 mt-4 bg-gray-100 rounded-lg'>
																<GaugeChart
																	percentage={data.turnover.on_target.current}
																	textColor={'black'}
																	radius={50}
																	strokeWidth={8}
																/>
															</div>
														</div>
													)}
												</div>
											)}
										</div>
									) : (
										<div className='flex flex-col items-center'>
											<div className='flex flex-col w-full items-center gap-6 p-8 text-p2 text-gray-600 pt-20'>
												<img className='w-44' alt='An empty clipboard' src={image} />
												{'There are no data to display.'}
											</div>
										</div>
									)}
								</div>
							</div>
							<div className='flex flex-col xl:flex-row gap-8 my-8'>
								<div className='basis-full bg-gray-50 rounded-b-md'>
									<MetricPanel
										left={{
											type: 'bar',
											label: 'Mthly Volume',
											value: data.volume.current,
											effect: createEffectWhenValid({
												validWhen: (args) => !!(args[0] && args[1]),
												output: (args) => `${Math.round(getPercentage(args[1], Math.abs(args[0])))}% mth`,
												args: [volumeDifference, data.volume.previous],
											}),
											effectDirection: createEffectDirectionWhenValid({
												whenPositive: 'up',
												whenNegative: 'down',
												difference: volumeDifference,
											}),
											effectColor: createEffectColorWhenValid({
												whenPositive: 'green',
												whenNegative: 'red',
												difference: volumeDifference,
											}),
										}}
										right={{
											type: 'bar',
											label: 'Avg Mthly Volume',
											value: data.volume.average ?? '-',
										}}
									/>
									{data.volume.top_surgeons.length > 0 ? (
										<div className='p-4'>
											<ChartHeading
												title='Top 5 Surgeons by Volume'
												description='Based on the count of case records over the time period listed.'
											/>
											{data.current_month && (
												<div className='flex my-4'>
													<ComparisonTable
														rows={data.volume.top_surgeons}
														currentPeriodLabel={data.current_month ?? '-'}
														previousPeriodLabel={data.previous_month ?? '-'}
													/>
												</div>
											)}
										</div>
									) : (
										<div className='flex flex-col items-center'>
											<div className='flex flex-col w-full items-center gap-6 p-8 text-p2 text-gray-600 pt-20'>
												<img className='w-44' alt='An empty clipboard' src={image} />
												{'There are no data to display.'}
											</div>
										</div>
									)}
								</div>
								<div className='basis-full bg-gray-50 rounded-b-md'>
									<MetricPanel
										left={{
											type: 'gauge',
											chartColor: 'addon',
											label: 'Add-On Rate',
											value: data.addons.weekdays.length > 0 ? data.addons.current : '--',
											metric: data.addons.weekdays.length > 0 ? '%' : undefined,
											effect:
												data.addons.weekdays.length > 0
													? createEffectWhenValid({
															validWhen: (args) => !!(args[0] && args[1]),
															output: (args) => `${Math.round(Math.abs(args[0]))}% mth`,
															args: [addonDifference, data.addons.previous],
													  })
													: undefined,
											effectDirection: createEffectDirectionWhenValid({
												whenPositive: 'up',
												whenNegative: 'down',
												difference: data.addons.weekdays.length > 0 ? addonDifference : null,
											}),
											effectColor: createEffectColorWhenValid({
												whenPositive: 'red',
												whenNegative: 'green',
												difference: data.addons.weekdays.length > 0 ? addonDifference : null,
											}),
										}}
										right={{
											type: 'gauge',
											label: 'Target Add-On Rate',
											value: data.addons.weekdays.length > 0 ? data.addons.target : '--',
											metric: data.addons.weekdays.length > 0 ? '%' : undefined,
										}}
									/>
									{data.addons.weekdays.length > 0 ? (
										<div className='p-4'>
											<ChartHeading
												title='Average Daily Add-Ons'
												description='Average number of add-on cases expected by day of week for the time period listed. Don’t see anything here? Your EHR may not label add-ons on a case-by-case basis.'
											/>
											{data.current_month && (
												<div className='flex my-4'>
													<ComparisonTable
														rows={data.addons.weekdays}
														currentPeriodLabel={data.current_month ?? '-'}
														previousPeriodLabel={data.previous_month ?? '-'}
														invertDiffColor={true}
													/>
												</div>
											)}
										</div>
									) : (
										<div className='flex flex-col items-center'>
											<div className='flex flex-col w-full items-center gap-6 p-8 text-p2 text-gray-600 pt-20'>
												<img className='w-44' alt='An empty clipboard' src={image} />
												{'There are no data to display.'}
											</div>
										</div>
									)}
								</div>
								<div className='basis-full bg-gray-50 rounded-b-md hidden'>
									<MetricPanel
										left={{
											type: 'gauge',
											chartColor: 'addon',
											label: 'Schedule Feed Record Type Frequency',
											value: data.addons.weekdays.length > 0 ? data.addons.current : '--',
											metric: data.addons.weekdays.length > 0 ? '%' : undefined,
											effect:
												data.addons.weekdays.length > 0
													? createEffectWhenValid({
															validWhen: (args) => !!(args[0] && args[1]),
															output: (args) => `${Math.round(Math.abs(args[0]))}% mth`,
															args: [addonDifference, data.addons.previous],
													  })
													: undefined,
											effectDirection: createEffectDirectionWhenValid({
												whenPositive: 'up',
												whenNegative: 'down',
												difference: data.addons.weekdays.length > 0 ? addonDifference : null,
											}),
											effectColor: createEffectColorWhenValid({
												whenPositive: 'red',
												whenNegative: 'green',
												difference: data.addons.weekdays.length > 0 ? addonDifference : null,
											}),
										}}
										right={{
											type: 'gauge',
											label: 'Target Add-On Rate',
											value: data.addons.weekdays.length > 0 ? data.addons.target : '--',
											metric: data.addons.weekdays.length > 0 ? '%' : undefined,
										}}
									/>
									{data.addons.weekdays.length > 0 ? (
										<div className='p-4'>
											<ChartHeading
												title='Average Daily Add-Ons'
												description='Average number of add-on cases expected by day of week for the time period listed. Don’t see anything here? Your EHR may not label add-ons on a case-by-case basis.'
											/>
											{data.current_month && (
												<div className='flex my-4'>
													<ComparisonTable
														rows={data.addons.weekdays}
														currentPeriodLabel={data.current_month ?? '-'}
														previousPeriodLabel={data.previous_month ?? '-'}
														invertDiffColor={true}
													/>
												</div>
											)}
										</div>
									) : (
										<div className='flex flex-col items-center'>
											<div className='flex flex-col w-full items-center gap-6 p-8 text-p2 text-gray-600 pt-20'>
												<img className='w-44' alt='An empty clipboard' src={image} />
												{'There are no data to display.'}
											</div>
										</div>
									)}
								</div>
							</div>
						</>
					)}
				</div>
				<div className='w-full xl:w-1/4 xl:pl-4'>
					<div className='bg-blue-50 p-6 font-semibold'>What would you like to see?</div>
					{linkList.map((link, i) => (
						<div key={i} className='my-12 px-6'>
							<Link className='group inline-flex items-center text-black font-semibold' to={link.route}>
								{link.label}
								<svg
									className='mt-0.5 ml-2 -mr-1 stroke-blue-500 stroke-2'
									fill='none'
									width='10'
									height='10'
									viewBox='0 0 10 10'
									aria-hidden='true'
								>
									<path className='opacity-0 transition group-hover:opacity-100' d='M0 5h7'></path>
									<path className='transition group-hover:translate-x-[3px]' d='M1 1l4 4-4 4'></path>
								</svg>
							</Link>
							<div className='h-px border border-bottom border-blue-500 my-1 mr-12 max-w-sm' />
							<p className='font-secondary'>{link.description}</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export default Core;

interface ChartHeadingProps {
	/** Title of the heading to render. */
	title: string;

	/** Description to render in the tooltip. */
	description: string;
}

function ChartHeading({ title, description }: ChartHeadingProps) {
	return (
		<div className='flex justify-start items-center'>
			<h4 className='font-semibold mr-3 mb-1'>{title}</h4>
			<Tooltip content={description}>
				<span className='text-blue-500 material-symbol'>info</span>
			</Tooltip>
		</div>
	);
}

interface ComparisonTableProps {
	/** Rows of data to render in the comparison table. */
	rows: { name: string; current: number | null; previous: number | null }[];

	/** The "current" period label, IE, the one that's highlighted with gray-background. */
	currentPeriodLabel: string | null;

	/** The "previous" period label, appearing on the left and with grayed text to push focus on the "current" period values. */
	previousPeriodLabel: string | null;

	/** The bool value which help to invert the color of difference percentage. */
	invertDiffColor?: boolean;
}

function ComparisonTable({
	rows,
	currentPeriodLabel,
	previousPeriodLabel,
	invertDiffColor = false,
}: ComparisonTableProps) {
	const comparisonRowSpacing = 'block truncate py-3';
	return (
		<div className='flex w-full my-4'>
			<div
				className={classNames(
					'w-4/12 pr-4 flex flex-col justify-end',
					'border-r border-blue-500',
					'font-secondary text-right'
				)}
			>
				{rows.map((row, i) => (
					<span key={i} className={comparisonRowSpacing}>
						{row.name}
					</span>
				))}
			</div>
			<div className='w-3/12 flex flex-col text-gray-500 text-center'>
				<span className={classNames(comparisonRowSpacing, 'font-secondary font-semibold')}>
					{previousPeriodLabel ? previousPeriodLabel.substring(0, 3) : '-'}
				</span>
				{rows.map((row) => (
					<span key={row.name} className={comparisonRowSpacing}>
						{/** Where values are null, display a "-" to indicate our calculation is "empty" or lacking data. */}
						{(row.previous && Number(row.previous.toFixed(2))) ?? '-'}
					</span>
				))}
			</div>
			<div className='w-3/12 flex flex-col text-center'>
				<span className={classNames(comparisonRowSpacing, 'font-secondary font-semibold')}>
					{currentPeriodLabel ? currentPeriodLabel.substring(0, 3) : ''}
				</span>
				<div className='mx-4 bg-gray-100 rounded-xl'>
					{rows.map((row) => (
						<span key={row.name} className={comparisonRowSpacing}>
							{row.current && Number(row.current.toFixed(2))}
						</span>
					))}
				</div>
			</div>
			<div className='w-2/12 flex flex-col justify-end font-secondary'>
				{rows.map((row) => {
					const nominalDifference =
						typeof row.previous === 'number' && typeof row.current === 'number' ? row.current - row.previous : null;
					const percentDifference =
						typeof nominalDifference === 'number'
							? Math.round(getPercentage(row.previous as number, Math.abs(nominalDifference)))
							: null;
					return (
						<div
							key={row.name}
							className={classNames(
								comparisonRowSpacing,
								'flex items-center justify-center',
								invertDiffColor
									? {
											'text-red-500': typeof nominalDifference === 'number' ? nominalDifference > 0 : false,
											'text-green-500': typeof nominalDifference === 'number' ? nominalDifference < 0 : false,
									  }
									: {
											'text-green-500': typeof nominalDifference === 'number' ? nominalDifference > 0 : false,
											'text-red-500': typeof nominalDifference === 'number' ? nominalDifference < 0 : false,
									  }
							)}
						>
							{/** Where values are null, display a "-" to indicate our calculation is "empty" or lacking data. */}
							{typeof nominalDifference === 'number' && percentDifference && !isNaN(percentDifference) ? (
								<>
									{percentDifference !== Infinity ? percentDifference + `%` : ''}
									<span className='material-symbol-sm'>
										{nominalDifference > 0 ? 'arrow_upward' : nominalDifference < 0 ? 'arrow_downward' : ''}
									</span>
								</>
							) : (
								'-'
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
}

/**
 * Function to generalize conditional logic to avoid repetitive code in the blocks above. Really only used in this component.
 * @param { validWhen, output, args }
 * @returns string | undefined
 */
export function createEffectWhenValid({
	validWhen,
	output,
	args,
}: {
	validWhen: (args: number[]) => boolean;
	output: (args: number[]) => string;
	args: (number | null)[];
}) {
	// if any args are null, cannot compute a "difference" between the two metrics
	if (args.indexOf(null) !== -1) return;
	return validWhen(args as number[]) ? output(args as number[]) : undefined;
}

/**
 * Function to generalize conditional logic to avoid repetitive code in the blocks above. Really only used in this component.
 * @param { whenPositive, whenNegative, difference }
 * @returns string | undefined
 */
export function createEffectDirectionWhenValid({
	whenPositive,
	whenNegative,
	difference,
}: {
	whenPositive: 'up' | 'down';
	whenNegative: 'up' | 'down';
	difference: number | null;
}): 'up' | 'down' | undefined {
	if (difference === null) return;
	if (difference > 0) return whenPositive;
	if (difference < 0) return whenNegative;
	return;
}

/**
 * Function to generalize conditional logic to avoid repetitive code in the blocks above. Really only used in this component.
 * @param { whenPositive, whenNegative, difference }
 * @returns string | undefined
 */
export function createEffectColorWhenValid({
	whenPositive,
	whenNegative,
	difference,
}: {
	whenPositive: 'green' | 'red';
	whenNegative: 'green' | 'red';
	difference: number | null;
}): 'green' | 'red' | undefined {
	if (difference === null) return;
	if (difference > 0) return whenPositive;
	if (difference < 0) return whenNegative;
	return;
}
