import * as Tabs from '@radix-ui/react-tabs';
import { useState } from 'react';
import { data } from 'cypress/types/jquery';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';

import Dialog from 'components/Dialog';
import { tabContentClassname, TableData, tabTriggerClassname } from 'pages';
import DataTable from 'components/DataTable';
import { Button } from 'components/Button';
import { SavedFilter, useAppSelector, useGetSavedViewQuery, useSystem } from 'store';
import { TextField, Tooltip } from 'components';
import { useFilters } from 'context';

export interface FiltersModalProps {
	children: React.ReactNode;
}

export const PATH_TO_TITLE_MAP: TableData = {
	'/timely-starts': 'Timely Starts',
	'/room-turnover': 'Timeline View',
	'/turnover': 'Turnover Time Overview',
	'/facility-utilization': 'Facility Utilization',
	'/scheduling-patterns': 'Scheduling Patterns',
	'/surgical-volume': 'Volume Detail Overview',
	'/flip-rooms': 'Flip Room Dashboard',
	'/surgeon-scorecard': 'Surgeon Scorecard',
	'/block': 'Block',
	'/block-scorecard': 'Block Scorecard',
	'/block-utilization-overview': 'Block Utilization Overview',
	'/release-patterns': 'Release Patterns',
	'/heatmap': 'Heatmap',
	'/anesthesia-staffing': 'Anesthesia Staffing',
	'/provider-patterns': 'Provider Patterns',
};
function FiltersModal({ children }: FiltersModalProps) {
	const [openViewModal, setOpenViewModal] = useState(false);
	const { data: userData } = useSystem();
	const { selectedFacility } = useAppSelector((state) => state.userState);
	const [nameQuery, setNameQuery] = useState('');
	const { pathname } = useLocation();
	// Used to get saved filters from server
	const { data, isFetching } = useGetSavedViewQuery(
		{
			facility_id: selectedFacility,
			page: pathname,
		},
		{
			skip: !selectedFacility,
		}
	);

	const { applyNewView } = useFilters();
	// changeViewPublicity, archiveView
	let rows =
		data?.filters?.map((filter_item) => {
			return {
				filter_name: filter_item?.filters?.name,
				date: new Date(filter_item?.filters?.created_at ?? new Date()).toLocaleDateString('en-US'),
				saved_by: filter_item?.filters?.author_name,
				notes: filter_item?.filters?.notes,
				is_public: filter_item?.filters?.is_public,
				is_applied: filter_item?.filters?.is_applied,
				is_archived: filter_item?.filters?.is_archived,
				filter_obj: filter_item?.filters,
				filter_hash: JSON.stringify({
					name: filter_item?.filters?.name?.includes('Copied')
						? filter_item?.filters?.name?.split(' ').slice(0, -1).join(' ')
						: filter_item?.filters?.name,
					notes: filter_item?.filters?.notes,
					created_at: filter_item?.filters?.created_at,
					updated_at: filter_item?.filters?.updated_at,
					date: filter_item?.filters?.date,
					date_range_start: filter_item?.filters?.date_range_start,
					date_range_end: filter_item?.filters?.date_range_end,
					is_trailing_date_range: filter_item?.filters?.is_trailing_date_range,
				}),
			};
		}) ?? [];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	rows = rows.filter((filter_item) => {
		if (nameQuery !== '') {
			return filter_item?.filter_name?.toLowerCase().includes(nameQuery.toLowerCase());
		} else {
			return true;
		}
	});

	const updateQueryText = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
		setNameQuery(e.target.value);
	}, 400);

	const sharedViews = rows.filter(
		(row) => row.is_public && row.filter_obj.author_id !== userData?.user.id && !row.is_archived
	);
	const shared_filter_name_map: { [key: string]: string } = {};
	const filter_hashes = sharedViews.map((row) => {
		shared_filter_name_map[row.filter_hash] = row.saved_by;
		return row.filter_hash;
	});
	const yourViews = rows
		.filter((row) => row.filter_obj.author_id === userData?.user.id && !row.is_archived && row.filter_name)
		.map((row) => {
			if (filter_hashes.includes(row.filter_hash)) {
				row.saved_by = shared_filter_name_map[row.filter_hash];
				return row;
			}

			return row;
		});

	const archivedViews = rows.filter((row) => row.is_archived);
	return (
		<Dialog
			actionButtons={[]}
			minimalHeaderStyle
			sizeX='lg_wide'
			title={`All Filters for ${PATH_TO_TITLE_MAP[pathname]}`}
			trigger={children}
			open={openViewModal}
			onOpenChange={(isBeingOpened) => {
				if (isBeingOpened) {
					setOpenViewModal(true);
				} else {
					setOpenViewModal(false);
				}
			}}
		>
			<Tabs.Root defaultValue='your_views' orientation='vertical'>
				<Tabs.List aria-label='Settings Tabs' className='flex border-b border-bluegray-100 pl-7 pr-7 whitespace-nowrap'>
					<Tabs.Trigger value='your_views' className={tabTriggerClassname}>
						Your Views ({yourViews.length})
					</Tabs.Trigger>
					<Tabs.Trigger value='shared_views' className={tabTriggerClassname}>
						Shared ({sharedViews.length})
					</Tabs.Trigger>
					<Tabs.Trigger value='archived' className={tabTriggerClassname}>
						Archived ({archivedViews.length})
					</Tabs.Trigger>
					<div className='ml-5 mb-2 flex flex-row justify-end w-2/3'>
						<TextField onChange={updateQueryText} sizeX='lg' className='mr-5' label='' placeholder='Search by view name' />
					</div>
				</Tabs.List>
				<Tabs.Content value='your_views' className='flex flex-col'>
					<div
						className={classNames(
							{ 'max-h-72': window.innerHeight < 600, 'max-h-96': window.innerHeight > 600 },
							`overflow-y-auto ${tabContentClassname}`
						)}
					>
						<DataTable
							title=''
							disablePagination
							minimalStyle
							columns={[
								{
									header: 'Name',
									accessorKey: 'filter_name',
									cell: ({ row }) =>
										row.original.is_applied && row.original.filter_obj.author_id === userData?.user.id ? (
											<>
												<span className='font-semibold text-blue-500 mb-0.5'>{'>'}</span>
												<span>{` ${row.original.filter_name ? row.original.filter_name : 'Unnamed'}`}</span>
											</>
										) : (
											row.original.filter_name
										),
								},
								{
									header: 'Date Created',
									accessorKey: 'date',
								},
								{
									header: 'Saved By',
									accessorKey: 'saved_by',
								},
								{
									header: 'Notes',
									accessorKey: 'notes',
									cell: ({ row }) => <p className='my-3'>{row.original.notes}</p>,
								},
								{
									header: 'Actions',
									accessorKey: 'actions',
									cell: ({ row }) => (
										<div className='flex items-center justify-end w-24'>
											{(!row.original.is_applied ||
												(row.original.is_public && row.original.filter_obj.author_id !== userData?.user.id)) &&
												row.original.filter_name &&
												(row.original.is_public && row.original.filter_obj.author_id !== userData?.user.id ? (
													<Tooltip content='Copy View'>
														<span
															className={`material-symbol-sm text-blue-500 cursor-pointer px-2`}
															onClick={() => applyNewView(row.original.filter_obj.filter_id)}
														>
															content_copy
														</span>
													</Tooltip>
												) : (
													<Tooltip content='Apply View'>
														<span
															className={`material-symbol-sm text-blue-500 cursor-pointer px-2`}
															onClick={() => applyNewView(row.original.filter_obj.filter_id)}
														>
															content_paste_go
														</span>
													</Tooltip>
												))}

											{row.original.is_public
												? row.original.filter_obj.author_id === userData?.user.id && (
														<Tooltip content='Make Private'>
															<span
																className='material-symbol-sm text-yellow-500 cursor-pointer px-2'
																onClick={() => applyNewView(row.original.filter_obj.filter_id, false)}
															>
																lock_open
															</span>
														</Tooltip>
												  )
												: row.original.filter_name && (
														<Tooltip content='Make Public'>
															<span
																className='material-symbol-sm text-blue-500 cursor-pointer px-2'
																onClick={() => applyNewView(row.original.filter_obj.filter_id, true)}
															>
																lock
															</span>
														</Tooltip>
												  )}

											{row.original.filter_obj.author_id === userData?.user.id && row.original.filter_name && (
												<Tooltip content='Archive View'>
													<span
														className='material-symbol-sm text-gray-500 cursor-pointer px-2'
														onClick={() => applyNewView(row.original.filter_obj.filter_id, undefined, true)}
													>
														inventory_2
													</span>
												</Tooltip>
											)}
										</div>
									),
								},
							]}
							data={yourViews}
							disableRowCounter
						/>
					</div>
					<div className='flex p-4 justify-end border border-t-1 border-gray-100'>
						<Button variant='primary' sizeX='sm' onClick={() => setOpenViewModal(false)} isWorking={isFetching}>
							Done
						</Button>
					</div>
				</Tabs.Content>
				<Tabs.Content value='shared_views' className='flex flex-col'>
					<div
						className={classNames(
							{ 'max-h-72': window.innerHeight < 600, 'max-h-96': window.innerHeight > 600 },
							`overflow-y-auto ${tabContentClassname}`
						)}
					>
						<DataTable
							title=''
							disablePagination
							minimalStyle
							columns={[
								{
									header: 'Name',
									accessorKey: 'filter_name',
									cell: ({ row }) =>
										row.original.is_applied && row.original.filter_obj.author_id === userData?.user.id ? (
											<>
												<span className='font-semibold text-blue-500 mb-0.5'>{'>'}</span>
												<span>{` ${row.original.filter_name ? row.original.filter_name : 'Unnamed'}`}</span>
											</>
										) : (
											row.original.filter_name
										),
								},
								{
									header: 'Date Created',
									accessorKey: 'date',
								},
								{
									header: 'Saved By',
									accessorKey: 'saved_by',
								},
								{
									header: 'Notes',
									accessorKey: 'notes',
									cell: ({ row }) => <p className='my-3'>{row.original.notes}</p>,
								},
								{
									header: 'Actions',
									accessorKey: 'actions',
									cell: ({ row }) => (
										<div className='flex items-center justify-end w-24'>
											{(!row.original.is_applied ||
												(row.original.is_public && row.original.filter_obj.author_id !== userData?.user.id)) &&
												row.original.filter_name &&
												(row.original.is_public && row.original.filter_obj.author_id !== userData?.user.id ? (
													<Tooltip content='Copy View'>
														<span
															className={`material-symbol-sm text-blue-500 cursor-pointer px-2`}
															onClick={() => applyNewView(row.original.filter_obj.filter_id, undefined, undefined, 'copy')}
														>
															content_copy
														</span>
													</Tooltip>
												) : (
													<Tooltip content='Apply View'>
														<span
															className={`material-symbol-sm text-blue-500 cursor-pointer px-2`}
															onClick={() => applyNewView(row.original.filter_obj.filter_id)}
														>
															content_paste_go
														</span>
													</Tooltip>
												))}

											{row.original.is_public
												? row.original.filter_obj.author_id === userData?.user.id && (
														<Tooltip content='Make Private'>
															<span
																className='material-symbol-sm text-yellow-500 cursor-pointer px-2'
																onClick={() => applyNewView(row.original.filter_obj.filter_id, false)}
															>
																lock_open
															</span>
														</Tooltip>
												  )
												: row.original.filter_name && (
														<Tooltip content='Make Public'>
															<span
																className='material-symbol-sm text-blue-500 cursor-pointer px-2'
																onClick={() => applyNewView(row.original.filter_obj.filter_id, true)}
															>
																lock
															</span>
														</Tooltip>
												  )}

											{row.original.filter_obj.author_id === userData?.user.id && row.original.filter_name && (
												<Tooltip content='Archive View'>
													<span
														className='material-symbol-sm text-gray-500 cursor-pointer px-2'
														onClick={() => applyNewView(row.original.filter_obj.filter_id, undefined, true)}
													>
														inventory_2
													</span>
												</Tooltip>
											)}
										</div>
									),
								},
							]}
							data={sharedViews}
							disableRowCounter
						/>
					</div>
					<div className='flex p-4 justify-end border border-t-1 border-gray-100'>
						<Button variant='primary' sizeX='sm' onClick={() => setOpenViewModal(false)} isWorking={isFetching}>
							Done
						</Button>
					</div>
				</Tabs.Content>
				<Tabs.Content value='archived' className='flex flex-col'>
					<div
						className={classNames(
							{ 'max-h-72': window.innerHeight < 600, 'max-h-96': window.innerHeight > 600 },
							`overflow-y-auto ${tabContentClassname}`
						)}
					>
						<DataTable
							title=''
							disablePagination
							minimalStyle
							columns={[
								{
									header: 'Name',
									accessorKey: 'filter_name',
									cell: ({ row }) =>
										row.original.is_applied && row.original.filter_obj.author_id === userData?.user.id
											? `> ${row.original.filter_name}`
											: row.original.filter_name,
								},
								{
									header: 'Date Created',
									accessorKey: 'date',
								},
								{
									header: 'Saved By',
									accessorKey: 'saved_by',
								},
								{
									header: 'Notes',
									accessorKey: 'notes',
									cell: ({ row }) => <p className='my-3'>{row.original.notes}</p>,
								},
								{
									header: 'Actions',
									accessorKey: 'actions',
									cell: ({ row }) => (
										<div className='flex items-center justify-end w-24 pr-4'>
											<Tooltip content='Restore View'>
												<span
													className='material-symbol-sm text-blue-500 cursor-pointer px-2'
													onClick={() => applyNewView(row.original.filter_obj.filter_id, undefined, false)}
												>
													settings_backup_restore
												</span>
											</Tooltip>
										</div>
									),
								},
							]}
							data={archivedViews}
							disableRowCounter
						/>
					</div>
					<div className='flex p-4 justify-end border border-t-1 border-gray-100'>
						<Button variant='primary' sizeX='sm' onClick={() => setOpenViewModal(false)} isWorking={isFetching}>
							Done
						</Button>
					</div>
				</Tabs.Content>
			</Tabs.Root>
		</Dialog>
	);
}

export default FiltersModal;
