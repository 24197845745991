import classNames from 'classnames';
import { forwardRef } from 'react';

import { WorkingIndicator } from 'components';

import type { ButtonHTMLAttributes, Ref } from 'react';

export interface ButtonLinkProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	/** Whether this button should display an icon indicating its "working" status. */
	isWorking?: boolean;

	/** The preset sizes to apply to this button. */
	size?: 'small' | 'normal';
}

const ButtonWithRef = forwardRef(function Button(
	{ isWorking = false, size = 'normal', children, className, ...props }: ButtonLinkProps,
	forwardedRef: Ref<HTMLButtonElement>
) {
	return (
		<button
			type='button'
			className={classNames(
				{
					'text-p2': size === 'small',
				},
				'inline-flex items-center text-blue-500 border-none hover:text-blue-700 bg-none',
				className
			)}
			ref={forwardedRef}
			data-is-working={isWorking}
			disabled={isWorking}
			{...props}
		>
			{children}
			{isWorking && <WorkingIndicator className='mx-2' size={size === 'small' ? 'sm' : undefined} />}
		</button>
	);
});

export { ButtonWithRef as ButtonLink, ButtonWithRef as default };
