import { AliasesData } from 'pages';
import { ApiEndpoints } from 'models';
import { apiWithCore } from 'store/api';

import { ShortOption } from './FilterService';

export interface MappingRequest {
	healthsystem_id: number | null;
	facility_id: number | null;
	method?: 'save_mapping' | 'create';
	fromId: number;
	toId?: number | string;
	group?: string;
}

export interface MapObject {
	date_found: Date;
	id: number;
	name: string;
	similar_id?: number;
	similarity_score?: number;
	similar_name?: string;
}

/**
 * Provides endpoints for setting values in database
 */
export const mappingService = apiWithCore.injectEndpoints({
	endpoints: (build) => ({
		getReconciliationRooms: build.query<
			{
				rooms: MapObject[];
				aliasesRooms: AliasesData[];
				allRooms: ShortOption[];
			},
			{ facility_id: number | null; healthsystem_id: number | null }
		>({
			query: ({ facility_id, healthsystem_id }) => ({
				url: ApiEndpoints.GET_RECONCILIATION_ROOMS,
				method: 'GET',
				params: { facility_id, healthsystem_id },
			}),
			providesTags: ['Core'],
		}),
		getReconciliation: build.query<
			{
				unMappedEntities: MapObject[];
				allEntities: ShortOption[];
				aliasEntities: AliasesData[];
			},
			{ facility_id: number | null; healthsystem_id: number | null; group: string }
		>({
			query: ({ facility_id, healthsystem_id, group }) => ({
				url: ApiEndpoints.GET_RECONCILIATION,
				method: 'GET',
				params: { facility_id, healthsystem_id, group },
			}),
			providesTags: ['Core'],
		}),
		setUndoReconciliation: build.mutation<{ data: string }, { alias_id: number; healthsystem_id: number | null }>({
			query: (body) => ({
				url: ApiEndpoints.SET_UNDO_RECONCILIATION,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Core'],
		}),
		setSurgeonMapping: build.mutation<string, MappingRequest>({
			query: (body) => ({
				url: ApiEndpoints.SET_SURGEON_MAPPING,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Core'],
		}),
		setEntityMapping: build.mutation<string, MappingRequest>({
			query: (body) => ({
				url: ApiEndpoints.SET_ENTITY_MAPPING,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Core'],
		}),
		setRoomMapping: build.mutation<string, MappingRequest>({
			query: (body) => ({
				url: ApiEndpoints.SET_ROOM_MAPPING,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Core'],
		}),
	}),
});

export const {
	useGetReconciliationRoomsQuery,
	useGetReconciliationQuery,
	useSetEntityMappingMutation,
	useSetUndoReconciliationMutation,
	useSetSurgeonMappingMutation,
	useSetRoomMappingMutation,
} = mappingService;

export const {
	endpoints: {
		getReconciliationRooms,
		getReconciliation,
		setUndoReconciliation,
		setSurgeonMapping,
		setEntityMapping,
		setRoomMapping,
	},
} = mappingService;
