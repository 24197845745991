import { useState } from 'react';
import { Row } from '@tanstack/react-table';
import { format, getWeekOfMonth } from 'date-fns';
import { debounce } from 'lodash';

import {
	handleApiResponse,
	ShortOption,
	useAppSelector,
	useCheckPatternCollisionMutation,
	useGetServiceLineNameQuery,
	useSystem,
} from 'store';
import { useAlert, useFilters } from 'context';
import { DAYS_OF_WEEK_LIST } from 'utils';
import { Button, Checkbox, DataTable, Datepicker, MultiSelect, Select } from 'components';
import { Facility } from 'models';

import { minMaxTime } from './ReleaseDialog';
import {
	AddRoomProps,
	BlockPatternDetailsPOST,
	BlockPatternRoom,
	ChangeRoomProps,
	ChangeSurgeonsProps,
	overlapAlert,
	PatternRowProps,
	RoomModule,
	Step1CacheProps,
} from './BlockPatternFlow';

function BlockPatternFlowDetails(props: {
	setFlowMode: (mode: 'Add' | 'Edit') => void;
	goBackModule: () => void;
	submit: (data: BlockPatternDetailsPOST) => void;
	previousTabData: Step1CacheProps | null;
	cachedData: BlockPatternDetailsPOST | null;
	healthsystem_id: number | null;
	facility_id: number | null;
	facilites: Facility[] | undefined;
}) {
	const { date, surgeons } = useFilters();

	// Template for data table
	const dataModel = {
		patterns: [
			{
				week: 1,
				rooms: [],
				surgeons: [],
			},
			{
				week: 2,
				rooms: [],
				surgeons: [],
			},
			{
				week: 3,
				rooms: [],
				surgeons: [],
			},
			{
				week: 4,
				rooms: [],
				surgeons: [],
			},
			{
				week: 5,
				rooms: [],
				surgeons: [],
			},
		],
		healthsystem_id: props.healthsystem_id,
		assigned_facility: props.facility_id,
		effective_date: date.selected,
		dow_selected: props.previousTabData?.dow_selected,
		every_other_week: false,
		selected_block: props.previousTabData?.block_option, // contains block_id
		// block_pattern_id: 2323, // only present upon edit
	};

	// Check to see if overbooking is allowed
	const { selectedSystem } = useAppSelector((state) => state.userState);
	const { data: systemData } = useSystem();
	const system = systemData?.healthsystems.find((h) => h.id === selectedSystem);
	const allowOverbooking = system?.allow_block_overbooking;

	// Alert window
	const { getAlertResponse } = useAlert();

	// Controls next button loading state
	const [checkingCollision, setCheckingCollision] = useState(false);
	const [checkCollision] = useCheckPatternCollisionMutation();
	const { data: serviceline_name_data } = useGetServiceLineNameQuery({
		serviceline_id: props.previousTabData?.service_line_id,
	});

	// What is edited when user interacts with the modal -  deep copy
	const [editablePayload, setEditablePayload] = useState<BlockPatternDetailsPOST>(
		JSON.parse(JSON.stringify(props.cachedData ?? dataModel))
	);

	// Controls which table is displayed - normal or EOW
	const [every_other_week, setEveryOtherWeek] = useState<boolean>(editablePayload.patterns[0].week === 0);

	// Identify primary week
	let primaryWeek: number | null = null;
	for (let i = 0; i < editablePayload.patterns.length; i++) {
		if (editablePayload.patterns[i].rooms.length > 0) {
			primaryWeek = editablePayload.patterns[i].week;
			break;
		}
	}

	// User interface functions
	const active_surgeons = editablePayload.patterns.filter((elem) => elem.surgeons?.length > 0);
	const addWeek = (
		row: Row<{
			week: number;
			rooms: BlockPatternRoom[];
			surgeons: ShortOption[];
		}>
	) => {
		setEditablePayload((prevState) => {
			return {
				...prevState,
				patterns: prevState.patterns.map((week, i) => {
					if (i === row.index) {
						// If no primary week, add a dummy day
						if (prevState.patterns[(primaryWeek ?? 1) - 1].rooms.length > 0) {
							week.rooms = JSON.parse(JSON.stringify(prevState.patterns[(primaryWeek ?? 1) - 1].rooms));
							week.surgeons = active_surgeons.length > 0 ? active_surgeons[0].surgeons : [];
						} else {
							week.rooms = [...week.rooms, { start_time: '07:00:00', end_time: '15:00:00' }];
							week.surgeons = active_surgeons.length > 0 ? active_surgeons[0].surgeons : [];
						}
					}

					return week;
				}),
			};
		});
	};

	const removeWeek = (
		row: Row<{
			week: number;
			rooms: BlockPatternRoom[];
			surgeons: ShortOption[];
		}>
	) => {
		setEditablePayload((prevState) => {
			return {
				...prevState,
				patterns: prevState.patterns.map((week, i) => {
					if (i === row.index) {
						week.rooms = [];
						week.surgeons = [];
					}

					return week;
				}),
			};
		});
	};
	const changeRoom = (args: { row: PatternRowProps; innerArgs: ChangeRoomProps }) => {
		setEditablePayload((prevState) => {
			return {
				...prevState,
				patterns: prevState.patterns.map((week, i) => {
					if (week.rooms.length > 0) {
						week.rooms = week.rooms.map((room, j) => {
							if (j === args.innerArgs.index) {
								if (args.innerArgs.is_start) {
									room.start_time = args.innerArgs.room_time;
								} else {
									room.end_time = args.innerArgs.room_time;
								}
							}

							return room;
						});
					}

					return week;
				}),
			};
		});
	};

	const addRoom = (args: { row: PatternRowProps; innerArg: AddRoomProps }) => {
		setEditablePayload((prevState) => {
			return {
				...prevState,
				patterns: prevState.patterns.map((week, i) => {
					if (week.rooms.length > 0) {
						week.rooms = [...week.rooms, { start_time: args.innerArg.room_start, end_time: args.innerArg.room_end }];
					}

					return week;
				}),
			};
		});
	};

	const removeRoom = (row: PatternRowProps) => {
		setEditablePayload((prevState) => {
			return {
				...prevState,
				patterns: prevState.patterns.map((week, i) => {
					if (week.rooms.length > 0) {
						if (!(week.rooms.length === 1 && i !== (primaryWeek ?? 1) - 1)) {
							week.rooms = week.rooms.slice(0, -1);

							if (week.rooms.length === 0) {
								week.surgeons = [];
							}
						}
					}

					return week;
				}),
			};
		});
	};

	const changeSurgeons = (args: { innerArgs: ChangeSurgeonsProps }) => {
		setEditablePayload((prevState) => {
			return {
				...prevState,
				patterns: prevState.patterns.map((week, i) => {
					week.surgeons = args.innerArgs.surgeons;
					return week;
				}),
			};
		});
	};

	// checks for collisions
	const submitData = async () => {
		setCheckingCollision(true);
		// set earliest and latest times
		const starts: string[] = [];
		const ends: string[] = [];

		for (let i = 0; i < editablePayload.patterns.length; i++) {
			for (let j = 0; j < editablePayload.patterns[i].rooms.length; j++) {
				starts.push(editablePayload.patterns[i].rooms[j].start_time);
				ends.push(editablePayload.patterns[i].rooms[j].end_time);
			}
		}

		// Set these fields for next modal
		editablePayload.earliest_start = minMaxTime(starts);
		editablePayload.latest_end = minMaxTime(ends, false);
		editablePayload.serviceline_name = serviceline_name_data?.serviceline_name;
		editablePayload.dow_selected =
			(typeof editablePayload.effective_date === 'string'
				? new Date(editablePayload.effective_date)
				: editablePayload.effective_date
			).getDay() + 1;
		const testDateFormatChange =
			typeof editablePayload.effective_date === 'string'
				? editablePayload.effective_date
				: format(editablePayload.effective_date, 'M/d/yyyy');
		editablePayload.effective_date = testDateFormatChange;

		const response = await checkCollision(editablePayload);

		handleApiResponse(response, {
			success: (payload) => {
				if (payload.collision) {
					user_alert_option(!allowOverbooking, payload.surgeons, props.previousTabData?.block_option?.name, editablePayload);
				} else {
					props.submit(editablePayload);
				}

				setCheckingCollision(false);
			},
			error: (payload) => {
				setCheckingCollision(false);
			},
		});
	};

	// Controls what happens with user alert box
	const user_alert_option = async (
		isError: boolean,
		surgeons: string[],
		blockName: string | undefined,
		payload: BlockPatternDetailsPOST
	) => {
		const user_picked = await getAlertResponse({
			title: isError ? 'Not Allowed' : 'Alert',
			type: isError ? 'Error' : 'Warning',
			body: overlapAlert(isError, surgeons, blockName ?? ''),
			responseOptions: isError
				? [
						{
							value: 'ok',
							label: 'Ok',
						},
				  ]
				: [
						{
							value: 'proceed',
							label: 'Yes, Proceed',
						},
				  ],
		});

		if (user_picked === 'proceed') {
			props.submit(payload);
		}
	};

	// consts for meta info
	const metaStyle = 'px-4 pl-0 text-p3 text-gray-700 tracking-wider uppercase mb-2 font-secondary';
	const metaDetail = 'bg-blue-50 text-black px-6 text-p3 font-primary rounded-md w-fit py-2';
	const dow_match =
		(typeof editablePayload.effective_date === 'string'
			? new Date(editablePayload.effective_date)
			: editablePayload.effective_date
		).getDay() ===
		(props.previousTabData?.dow_selected ?? 0) - 1;
	const is_week_populated = editablePayload.patterns.filter((week) => week.rooms.length > 0).length > 0;
	const week_of_month_match =
		editablePayload.patterns
			.filter((week) => week.rooms.length > 0)
			.map((week) => week.week)
			.includes(
				Math.ceil(
					(typeof editablePayload.effective_date === 'string'
						? new Date(editablePayload.effective_date)
						: editablePayload.effective_date
					).getDate() / 7
				)
			) || every_other_week;

	const disable_next =
		editablePayload.patterns.filter((pattern) => pattern.rooms.length > 0).length === 0 ||
		editablePayload.patterns.filter((pattern) => pattern.surgeons?.length === 0 && pattern.rooms?.length > 0).length >
			0 ||
		!dow_match ||
		!week_of_month_match;

	return (
		<div className='mb-8'>
			<div className='flex justify-between mr-2 items-center mb-4'>
				<div className='flex'>
					<div className='flex flex-col mr-4'>
						<p className={metaStyle}>Block Name</p>
						<p className={metaDetail}>{props.previousTabData?.block_option?.name}</p>
					</div>

					{serviceline_name_data?.serviceline_name ? (
						<div className='flex flex-col mr-4'>
							<p className={metaStyle}>Service Line</p>
							<p className={metaDetail}>{serviceline_name_data.serviceline_name}</p>
						</div>
					) : null}

					<div className='flex flex-col mr-4'>
						<p className={metaStyle}>Day of Week</p>
						<p className={metaDetail}>{DAYS_OF_WEEK_LIST[(props.previousTabData?.dow_selected ?? 1) - 1]}</p>
					</div>
					<div className='flex flex-col mr-4'>
						<p className={'px-4 pl-0 text-p3 text-gray-700 tracking-wider uppercase font-secondary'}>Assigned Facility</p>
						<Select
							options={props.facilites?.map((facility) => ({ value: facility.id, label: facility.name })) ?? []}
							value={
								editablePayload.assigned_facility
									? props.facilites
											?.filter((facility) => facility.id === editablePayload.assigned_facility)
											.map((facility) => ({ value: facility.id, label: facility.name }))
									: null
							}
							onChange={(selection) => {
								if (selection?.value) {
									setEditablePayload((prevState) => {
										return {
											...prevState,
											assigned_facility: selection.value,
										};
									});
								}
							}}
							label={''}
						/>
					</div>
					<div className='flex flex-col mr-4'>
						<p className={'px-4 pl-0 text-p3 text-gray-700 tracking-wider uppercase font-secondary'}>Surgeons</p>
						<MultiSelect
							label={''}
							options={surgeons.all.map((surgeon) => ({ label: surgeon.name, value: surgeon.id }))}
							sizeX='sm'
							value={
								active_surgeons.length > 0
									? active_surgeons[0].surgeons.map((surgeon) => ({ label: surgeon.name, value: surgeon.id }))
									: []
							}
							onChange={(selection) => {
								changeSurgeons({
									innerArgs: { surgeons: selection.map((surgeon) => ({ name: surgeon.label, id: surgeon.value })) },
								});
							}}
						/>
					</div>
				</div>
			</div>

			<div className='flex my-8 mb-4'>
				<div className='mr-1'>
					<Checkbox
						label={''}
						checked={every_other_week}
						onChange={(event) => {
							// if switching to, then wipe previous data
							if (event.target.checked) {
								setEditablePayload((prevState) => {
									return {
										...prevState,
										patterns: [
											{
												week: 0,
												rooms: [],
												surgeons: [],
											},
										],
									};
								});
							} else {
								setEditablePayload((prevState) => {
									return {
										...prevState,
										patterns: [
											{
												week: 1,
												rooms: [],
												surgeons: [],
											},
											{
												week: 2,
												rooms: [],
												surgeons: [],
											},
											{
												week: 3,
												rooms: [],
												surgeons: [],
											},
											{
												week: 4,
												rooms: [],
												surgeons: [],
											},
											{
												week: 5,
												rooms: [],
												surgeons: [],
											},
										],
									};
								});
							}

							setEveryOtherWeek(event.target.checked);
						}}
					/>
				</div>
				<div>
					<p className='text-gray-600 text-p1'>
						Block follows an “Every Other Week” (E/O) pattern. Double check the Effective Start Date!
					</p>
					<p className='text-p3 text-gray-400'>
						Be sure to double check the Effective Start Date on the next step. Incorrect allocations typically happen when
						this date is not selected appropriately.
					</p>
				</div>
			</div>

			<div className={`border border-blue-500 mr-5 rounded-md ${every_other_week}`}>
				{!every_other_week ? (
					<DataTable
						title=''
						disablePagination
						minHeight={'28em'}
						minimalStyle
						columns={[
							{
								header: 'Week of Month',
								accessorKey: '',
								cell: ({ row }) => {
									return (
										<div className='flex flex-col text-p3'>
											<div
												className={`w-fit h-fit p-1 px-5 rounded-sm flex flex-col ${
													row.original.rooms.length > 0
														? row.original.week === primaryWeek
															? 'bg-blue-500 text-white'
															: 'bg-gray-200 text-white'
														: 'text-gray-400 italic'
												}`}
											>
												<div className='w-fit m-0 flex items-center whitespace-nowrap'>{`Week ${row.original.week}`}</div>
											</div>
											{row.original.rooms.length > 0 ? (
												row.original.week === primaryWeek ? (
													<p className='text-gray-300 mt-0.5 ml-0.5'>Primary</p>
												) : (
													<p className='text-gray-300 mt-0.5 ml-0.5'>Clone</p>
												)
											) : null}
										</div>
									);
								},
							},
							{
								header: 'Room',
								accessorKey: 'rooms',
								cell: ({ row }) => {
									return (
										<RoomModule
											isEarliestWeek={row.original.week === primaryWeek}
											rooms={row.original.rooms}
											addWeek={() => addWeek(row)}
											removeWeek={() => removeWeek(row)}
											changeRoom={(args: ChangeRoomProps) => {
												changeRoom({
													row: { row: row },
													innerArgs: args,
												});
											}}
											addRoom={(args) => {
												addRoom({
													row: { row: row },
													innerArg: args,
												});
											}}
											removeRoom={() => {
												removeRoom({ row: row });
											}}
											selectedSurgeons={row.original.surgeons}
										/>
									);
								},
								meta: {
									headerClass: 'opacity-0',
								},
							},
							{
								header: 'Start Time',
								accessorKey: '',
							},
							{
								header: 'End Time',
								accessorKey: '',
							},
							{
								header: 'Hours',
								accessorKey: '',
							},
							{
								header: 'Total Hours',
								accessorKey: '',
							},
							{
								header: 'Surgeons',
								accessorKey: '',
								meta: {
									headerClass: 'w-42',
								},
							},
						]}
						data={editablePayload.patterns}
						disableRowCounter
					/>
				) : (
					<DataTable
						title=''
						disablePagination
						minimalStyle
						minHeight={'28em'}
						columns={[
							{
								header: 'Week of Month',
								accessorKey: '',
								cell: ({ row }) => {
									return <p className='w-16 m-0 flex items-center whitespace-nowrap'>Every Other Week</p>;
								},
							},
							{
								header: 'Room',
								accessorKey: 'rooms',
								cell: ({ row }) => {
									return (
										<RoomModule
											isEarliestWeek={row.original.week === primaryWeek}
											rooms={row.original.rooms}
											addWeek={() => addWeek(row)}
											removeWeek={() => removeWeek(row)}
											changeRoom={(args: ChangeRoomProps) => {
												changeRoom({
													row: { row: row },
													innerArgs: args,
												});
											}}
											addRoom={(args) => {
												addRoom({
													row: { row: row },
													innerArg: args,
												});
											}}
											removeRoom={() => {
												removeRoom({ row: row });
											}}
											selectedSurgeons={row.original.surgeons}
										/>
									);
								},
								meta: {
									headerClass: 'opacity-0',
								},
							},
							{
								header: 'Start Time',
								accessorKey: '',
							},
							{
								header: 'End Time',
								accessorKey: '',
							},
							{
								header: 'Hours',
								accessorKey: '',
							},
							{
								header: 'Total Hours',
								accessorKey: '',
							},
							{
								header: 'Surgeons',
								accessorKey: '',
								meta: {
									headerClass: 'w-42',
								},
							},
						]}
						data={editablePayload.patterns}
						disableRowCounter
					/>
				)}
			</div>

			{is_week_populated && (
				<div className='flex mt-8 mb-4 items-center'>
					<div className='pr-12 w-80'>
						<p className='font-bold mb-2 uppercase text-p2'>Effective Start Date</p>
						<p className='text-gray-400 italic text-p3'>
							The date these changes should take effect in the schedule, as it relates to block metrics.
						</p>
					</div>
					<div className='min-w-[250px] z-[300]'>
						<Datepicker
							type='date'
							popoverContentProps={{ side: 'left' }}
							onApply={(newDate) => {
								setEditablePayload((prevState) => {
									return { ...prevState, effective_date: newDate };
								});
							}}
							selected={
								typeof editablePayload.effective_date === 'string'
									? new Date(editablePayload.effective_date)
									: editablePayload.effective_date
							}
							customLimit={{ minLimit: false, maxLimit: false }}
						>
							<div className='flex justify-center border rounded-sm border-gray-300 p-2 cursor-pointer text-p3 w-48'>
								<div className='rounded text-white bg-blue-500 py-1 px-6'>
									{format(
										typeof editablePayload.effective_date === 'string'
											? new Date(editablePayload.effective_date)
											: editablePayload.effective_date,
										'M/d/yyyy'
									)}
								</div>
							</div>
						</Datepicker>
					</div>
				</div>
			)}

			{is_week_populated && (
				<div className='flex'>
					{!dow_match && (
						<div className='bg-yellow-100 flex p-2 rounded-md justify-start items-center mr-4 pr-4 w-fit'>
							<span className='material-symbols-outlined text-yellow-500 pr-2'>warning</span>
							<p className='text-p3 text-gray-500'>The day of week does not match the day of week selected in Step 1.</p>
						</div>
					)}
					{!week_of_month_match && (
						<div className='bg-yellow-100 flex p-2 rounded-md justify-between items-center mr-4 pr-4 w-fit'>
							<span className='material-symbols-outlined text-yellow-500 pr-2'>warning</span>
							<p className='text-p3 text-gray-500'>The week of month does not match the weeks of month selected above.</p>
						</div>
					)}
				</div>
			)}

			<div className='pr-7'>
				<div className='w-full flex justify-end mt-20'>
					<Button
						sizeX='md'
						sizeY='sm'
						variant='secondary'
						className='mr-4'
						onClick={() => {
							props.goBackModule();
						}}
					>
						Back
					</Button>
					<Button sizeX='md' sizeY='sm' disabled={disable_next} isWorking={checkingCollision} onClick={() => submitData()}>
						Next
					</Button>
				</div>
			</div>
		</div>
	);
}

export default BlockPatternFlowDetails;
