import { Facility, UserRole } from 'models';

/**
 * Function to pluck/select a facility from a list of facilities by passing the id and the bank to search through
 * @param facilities the bank/list of facilities received from the API that user has access to
 * @param id the id of the facility to pluck
 */
export function getFacility(facilities: Facility[], id: number): Facility | false {
	const facility = facilities?.find((f) => f.id === id);
	if (facility) return facility;
	return false;
}

/**
 * Returns the facility's display name if not empty, else returns the facility's regular name property.
 * @param facility the facility object
 * @returns the name of the facility as it should be rendered in the application
 */
export function getFacilityName(facility: Facility | undefined): string {
	return facility?.intraop_facility_name_alias ? facility.intraop_facility_name_alias : facility?.name ?? ''; // The function returns the product of p1 and p2
}

/**
 * Returns a label for the equivalent role that a user has
 * @param role the role from the enum
 * @returns string
 */
export function getRoleDesignationText(role: UserRole) {
	switch (role) {
		case UserRole.admin:
			return 'Merlin Administrator';
		case UserRole.delivery:
			return 'Merlin Delivery';
		case UserRole.delivery_basic:
			return 'Merlin Basic';
		case UserRole.healthsystem_admin:
			return 'Health System Administrator';
		case UserRole.facility_admin:
			return 'Facility Administrator';
		case UserRole.facility_basic:
			return 'Health System User';
	}
}
