import classNames from 'classnames';
import { forwardRef } from 'react';

import { WorkingIndicator } from 'components';

import type { ButtonHTMLAttributes, Ref } from 'react';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	/** The preset style to apply to this button. */
	variant?:
		| 'primary'
		| 'secondary'
		| 'tertiary'
		| 'primary-ghost'
		| 'secondary-ghost'
		| 'tertiary-ghost'
		| 'transparent';

	/** The preset width to apply to this button. The `square` option will attempt to match this button's width to its height. */
	sizeX?: 'sm' | 'md' | 'lg' | 'full' | 'square';

	/** The preset height to apply to this button. */
	sizeY?: 'sm' | 'md' | 'lg' | 'icon';

	/** Whether this button should display an icon indicating its "working" status. */
	isWorking?: boolean;

	/** Whether to square off some or all corners of this button. Useful when embedding a button in a larger interface. */
	squareCorners?: boolean | 'left' | 'right';

	/** Whether to completely round the corners to make a circular button shape. Useful for buttons that only contain an icon. */
	circle?: boolean;
}

const ButtonWithRef = forwardRef(function Button(
	{
		variant = 'primary',
		sizeX = 'full',
		sizeY = 'sm',
		isWorking = false,
		squareCorners = false,
		children,
		circle,
		className,
		...props
	}: ButtonProps,
	forwardedRef: Ref<HTMLButtonElement>
) {
	return (
		<button
			className={classNames(
				`flex items-center justify-center gap-1 font-primary`,
				`outline outline-2 outline-offset-4 outline-transparent focus-visible:outline-offset-0`,
				`working:opacity-50 working:cursor-wait`,
				`disabled:opacity-30 disabled:cursor-not-allowed`,
				`transition-[background-color,border-color,color,opacity,outline-color,outline-offset]`,
				{
					'rounded-sm': squareCorners === false,
					'rounded-l-sm': squareCorners === 'right',
					'rounded-r-sm': squareCorners === 'left',
					'rounded-full': circle === true,

					'text-white bg-blue-500 enabled:hover:bg-blue-700': variant === 'primary',
					'text-white bg-bluegray-900 enabled:hover:bg-bluegray-700': variant === 'secondary',
					'text-white bg-green-600 enabled:hover:bg-green-800': variant === 'tertiary',

					'focus-visible:outline-blue-300': variant.startsWith('primary') || variant === 'transparent',
					'focus-visible:outline-bluegray-300': variant.startsWith('secondary'),
					'focus-visible:outline-green-300': variant.startsWith('tertiary'),

					'bg-white text-blue-500 enabled:hover:text-blue-700 border-blue-500 enabled:hover:border-blue-700 enabled:active:bg-blue-500/10':
						variant === 'primary-ghost',
					'bg-white text-bluegray-900 enabled:hover:text-bluegray-600 border-bluegray-900 enabled:hover:border-bluegray-600 enabled:active:bg-bluegray-900/10':
						variant === 'secondary-ghost',
					'bg-white text-green-600 enabled:hover:text-green-800 border-green-600 enabled:hover:border-green-800 enabled:active:bg-green-600/10':
						variant === 'tertiary-ghost',

					'border-2': variant.endsWith('ghost'),

					'w-button-sm': sizeX === 'sm',
					'w-button-md': sizeX === 'md',
					'w-button-lg': sizeX === 'lg',
					'w-full': sizeX === 'full',
					'aspect-square': sizeX === 'square',

					'h-8 font-semibold text-b3 ': sizeY === 'sm',
					'h-10 working:gap-2 text-b2': sizeY === 'md',
					'h-14 working:gap-2 text-b1': sizeY === 'lg',
					'h-9 working:gap-2': sizeY === 'icon',
				},
				className
			)}
			ref={forwardedRef}
			data-is-working={isWorking}
			disabled={isWorking}
			{...props}
		>
			{isWorking && <WorkingIndicator size={sizeY === 'sm' ? 'sm' : undefined} />}
			{!(isWorking && sizeX === 'square') && children}
		</button>
	);
});

export { ButtonWithRef as Button, ButtonWithRef as default };
