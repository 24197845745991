import { parseStringValueAsBoolean } from 'utils';

/**
 * Returns the value of `process.env.NODE_ENV`.
 */
export function env(): typeof process.env.NODE_ENV;

/**
 * Returns the value of the provided environment variable without requiring the
 * `REACT_APP_` prefix necessitated by `create-react-app`. If a variable does
 * not exist, it will return as `undefined`.
 */
export function env(unprefixedVariable: string): typeof process.env[keyof typeof process.env];

/**
 * Returns the value of the provided environment variable without requiring the
 * `REACT_APP_` prefix necessitated by `create-react-app`. This signature
 * converts the variable's value (which is always a string) to a boolean,
 * returning `true` for the values "true", "1", "on", and "yes".
 */
export function env(unprefixedVariable: string, parseAsBoolean: true): boolean;

export function env(unprefixedVariable = '', parseAsBoolean = false) {
	if (!unprefixedVariable) {
		return process.env.NODE_ENV;
	}

	if (parseAsBoolean) {
		return parseStringValueAsBoolean(process.env[`REACT_APP_${unprefixedVariable}`] ?? '');
	}

	return process.env[`REACT_APP_${unprefixedVariable}`];
}
