import { useNavigate } from 'react-router-dom';

import { Button } from 'components/Button';
import image from 'assets/images/empty.svg';
import { Routes } from 'models/navigation';

export function EmptyFacilityData() {
	const navigate = useNavigate();
	return (
		<div className='flex justify-center align-center'>
			<div className='bg-gray-50 rounded text-center p-12'>
				<img className='mx-auto my-16 block max-w-sm' alt='Facility has no intraop data' src={image} />
				<h1 className='text-h4 mb-8'>No intraop data has been uploaded for this facility yet.</h1>
				<Button
					onClick={() => {
						navigate(Routes.UPLOAD);
					}}
					className='mx-auto'
					variant='primary-ghost'
					sizeX='lg'
					sizeY='md'
				>
					Go to File Upload
				</Button>
			</div>
		</div>
	);
}

export default EmptyFacilityData;
