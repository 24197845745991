/**
 * This file contains various subcomponent replacements for our implementation
 * of the React Select library.
 *
 * @see https://react-select.com/props#replacing-components
 */

import classNames from 'classnames';
import { components } from 'react-select';

import { Button, WorkingIndicator } from 'components';

import type {
	ControlProps,
	IndicatorsContainerProps,
	MenuListProps,
	MenuProps,
	MultiValueProps,
	OptionProps,
	SingleValueProps,
	ValueContainerProps,
} from 'react-select';

export function Control<Option = unknown>({ className, ...props }: ControlProps<Option>) {
	// This custom prop isn't required, but only because we provide it
	// automatically on the React Select component. This means it can technically
	// never be null, making this assertion safe.
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const { sizeX, sizeY, errorMessage, isKnownValid } = props.selectProps.wrapperProps!;

	let borderColorClass = 'border-gray-400';

	if (props.isFocused) {
		borderColorClass = 'border-blue-500';
	} else if (errorMessage) {
		borderColorClass = 'border-red-400';
	} else if (isKnownValid) {
		borderColorClass = 'border-green-500';
	}

	return (
		<components.Control
			className={classNames('flex items-center border rounded-sm transition-colors', borderColorClass, {
				'w-44': sizeX === 'sm',
				'w-72': sizeX === 'lg',
				'w-full': sizeX === 'full',

				'h-8 px-2': sizeY === 'sm',
				'h-10 px-3': sizeY === 'md',
				'h-14 px-4': sizeY === 'lg',

				'bg-white': !props.isDisabled,
				'bg-gray-50': props.isDisabled,
			})}
			{...props}
		/>
	);
}

export function ValueContainer<Option = unknown>(props: ValueContainerProps<Option>) {
	return <components.ValueContainer className='flex items-center overflow-hidden' {...props} />;
}

export function IndicatorsContainer<Option = unknown>({ className, ...props }: IndicatorsContainerProps<Option>) {
	return (
		<components.IndicatorsContainer
			className={classNames('flex items-center gap-1 ml-auto', {
				'text-blue-600': !props.isDisabled,
				'text-gray-600': props.isDisabled,
			})}
			{...props}
		/>
	);
}

export function IndicatorSeparator() {
	return null;
}

export function LoadingIndicator() {
	return <WorkingIndicator size='sm' />;
}

export function Menu<Option = unknown>({ className, ...props }: MenuProps<Option>) {
	return (
		<components.Menu
			className='absolute top-full w-full mt-1 z-selectMenu bg-white border border-blue-500 rounded-sm'
			{...props}
		/>
	);
}

export function MenuList<Option = unknown>({ className, ...props }: MenuListProps<Option>) {
	// This custom prop isn't required, but only because we provide it
	// automatically on the React Select component. This means it can technically
	// never be null, making this assertion safe.
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const { sizeY } = props.selectProps.wrapperProps!;

	return (
		<components.MenuList
			className={classNames('relative overflow-y-auto py-1', {
				'max-h-[315px]': sizeY === 'sm',
				'max-h-[340px]': sizeY === 'md',
				'max-h-[370px]': sizeY === 'lg',
			})}
			{...props}
		/>
	);
}

export function Option<Option = unknown>({ className, ...props }: OptionProps<Option>) {
	// This custom prop isn't required, but only because we provide it
	// automatically on the React Select component. This means it can technically
	// never be null, making this assertion safe.
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const { sizeY } = props.selectProps.wrapperProps!;

	return (
		<components.Option {...props}>
			<div
				className={classNames(
					'group flex items-center py-2 px-3 text-black',
					'data-unselected:data-focused:bg-gray-50 data-unselected:hover:bg-gray-50 ',
					'data-selected:bg-blue-50 data-selected:data-focused:bg-blue-100 data-selected:hover:bg-blue-100',
					'transition-colors duration-100'
				)}
				data-focused={props.isFocused}
				data-selected={props.isSelected}
			>
				{props.selectProps.isMulti && (
					<div
						className={classNames(
							sizeY === 'lg' && 'h-[18px] w-[18px] mr-3',
							sizeY === 'md' && 'h-[16px] w-[16px] mr-2',
							sizeY === 'sm' && 'h-[14px] w-[14px] mr-2',
							'relative shrink-0 rounded-sm cursor-[inherit]',
							'border-gray-200 hover:enabled:border-blue-600 active:enabled:bg-blue-50',
							'focus-visible:outline-none focus-visible:bg-blue-50 focus-visible:border-blue-600',
							'transition-[background-color,border-color] duration-100',
							// `:before` pseudo-element styles
							'before:block before:h-full before:w-full before:rounded-sm',
							'before:border-2 before:border-inherit',
							'before:transition-[background-color,border-color]',
							'group-data-selected:before:bg-blue-500 group-data-selected:before:border-blue-500',
							'disabled:before:bg-gray-50',
							'group-data-selected:disabled:before:bg-gray-300 group-data-selected:disabled:before:border-gray-300',
							// `:after` pseudo-element styles
							sizeY === 'lg' && 'after:h-[12px] after:w-[7px]',
							sizeY === 'md' && 'after:h-[10px] after:w-[6px]',
							sizeY === 'sm' && 'after:h-[9px] after:w-[5px]',
							'after:block after:border-b-2 after:border-r-2 after:border-white after:opacity-0',
							'after:absolute after:left-1/2 after:top-1/2 after:-translate-x-1/2 after:-translate-y-[65%] after:rotate-45',
							'after:transition-opacity',
							'group-data-selected:after:opacity-100'
						)}
					/>
				)}
				{props.children}
			</div>
		</components.Option>
	);
}

export function SingleValue<Option = unknown>({ className, ...props }: SingleValueProps<Option>) {
	return (
		<components.SingleValue
			className={classNames(
				'row-start-1 row-end-2 col-start-1 col-end-3',
				'max-w-full mx-[2px]',
				'overflow-hidden text-ellipsis whitespace-nowrap'
			)}
			{...props}
		/>
	);
}

export function MultiValue<Option = unknown>(props: MultiValueProps<Option>) {
	if (props.index > 0) return null;

	if (props.selectProps.inputValue) return null;

	const value = props.getValue();
	let selectedValueText = '';

	if (value.length === props.options.length) {
		selectedValueText = `All`;
	} else if (value.length === 1) {
		selectedValueText = props.selectProps.getOptionLabel(value[0]);
	} else {
		selectedValueText = `${value.length} of ${props.options.length}`;
	}

	return <div className='overflow-hidden text-ellipsis whitespace-nowrap'>{selectedValueText}</div>;
}

export function MenuListWithBulkSelect<Option = unknown>({ className, ...props }: MenuListProps<Option>) {
	const { filterOption, inputValue, getOptionLabel, getOptionValue } = props.selectProps;
	// This custom prop isn't required, but only because we provide it
	// automatically on the React Select component. This means it can technically
	// never be null, making this assertion safe.
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const { sizeY } = props.selectProps.wrapperProps!;

	const allOptions = props.selectProps.options as Option[];
	const selectedOptions = (props.selectProps.value || []) as Option[];

	const matchedOptions: Option[] = filterOption
		? allOptions.filter((option) =>
				filterOption(
					{
						label: getOptionLabel(option),
						value: getOptionValue(option),
						data: option,
					},
					inputValue || ''
				)
		  )
		: allOptions;

	const visibleOptions = matchedOptions.length > 0 ? matchedOptions : allOptions;
	const selectedOptionValues = selectedOptions.map(getOptionValue);
	const visibleOptionValues = visibleOptions.map(getOptionValue);
	const areAllVisibleOptionsSelected = visibleOptionValues.every((item) => selectedOptionValues.includes(item));

	let bulkSelectButtonText = `${areAllVisibleOptionsSelected ? `Deselect` : `Select`} All ${visibleOptions.length}`;

	if (inputValue) {
		bulkSelectButtonText += ` Matches`;
	}

	const handleBulkSelect = () => {
		// Start with any existing selections that aren't visible due to the user's
		// current search term.
		const newSelectedOptions = selectedOptions.filter((option) => !visibleOptionValues.includes(getOptionValue(option)));

		if (!areAllVisibleOptionsSelected) {
			newSelectedOptions.push(...visibleOptions);
		}

		props.setValue(newSelectedOptions, areAllVisibleOptionsSelected ? 'deselect-option' : 'select-option');
	};

	return (
		<div>
			<components.MenuList
				className={classNames('relative overflow-y-auto py-1', {
					'max-h-[315px]': sizeY === 'sm',
					'max-h-[340px]': sizeY === 'md',
					'max-h-[370px]': sizeY === 'lg',
				})}
				{...props}
			/>
			{matchedOptions.length > 1 && (
				<Button type='button' squareCorners onClick={handleBulkSelect}>
					{bulkSelectButtonText}
				</Button>
			)}
		</div>
	);
}
