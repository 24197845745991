import * as yup from 'yup';
import { useFieldArray, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as RadixToggleGroup from '@radix-ui/react-toggle-group';

import { Button, ButtonLink, Dialog, Select, TextField, ToggleGroup } from 'components';
import { getQueryError, handleApiResponse, setSelectedSystem, useAddSystemMutation, useAppDispatch } from 'store';
import {
	checkLicenseBits,
	cms_designations,
	createLicenseBits,
	ehr_systems,
	intTo16BitBin,
	now,
	requireValidFieldValue,
	us_states,
	writeToLocalStorage,
} from 'utils';
import { useAlert, useToast } from 'context';
import { FacilityLicense, license16Bit } from 'models';

import type { AddSystemApiRequest, ShortFacility } from 'store';
import type { SubmitHandler } from 'react-hook-form';

export interface FacilityInput {
	facilityName: string;
	license: FacilityLicense;
}

export interface AddSystemFormValues {
	name: string;
	facilities: FacilityInput[];
}

export const LicenseToggleGroup = (props: {
	defaultValue: string;
	onValueChange: (e: string) => void;
	disabled?: boolean;
	label: string;
	hideLabel: boolean;
	className?: string;
	options: {
		label: string;
		value: string;
		license_enum: number;
		active?: string;
	}[];
}) => {
	const { defaultValue, onValueChange, disabled, label, hideLabel, className, options } = props;
	const [toggleValue, setToggleValue] = useState(
		options.map((option) => {
			option.active = checkLicenseBits(defaultValue, option.license_enum) ? 'on' : 'off';
			return option;
		})
	);

	return (
		<RadixToggleGroup.Root
			className={classNames('flex h-8 font-primary text-i3', className)}
			// value={inputValue}
			id={'4343'}
			type='single'
		>
			{toggleValue.map((option) => (
				<RadixToggleGroup.Item
					key={option.value}
					value={option.value}
					data-state={option.active}
					onClick={() => {
						setToggleValue((prevState) => {
							const temp_value = prevState.map((innerOption) => {
								if (innerOption.value === option.value) {
									innerOption.active = innerOption.active === 'on' ? 'off' : 'on';
								}
								return innerOption;
							});

							const activeLicenses = toggleValue
								.filter((option) => option.active === 'on')
								.map((option) => {
									return parseInt(option.value, 2);
								});

							// or operation on bits
							const combinedLicense = activeLicenses.reduce((acc, curr) => acc | curr, 0);
							onValueChange(intTo16BitBin(combinedLicense));

							return temp_value;
						});
					}}
					//disabled={option.disabled}
					className={classNames(
						'neighbor basis-full flex items-center justify-center border-y border-blue-500 bg-white relative min-w-[10em]',
						'first:border-l first:rounded-l-sm last:border-r last:rounded-r-sm',
						'focus:outline-none',
						'disabled:cursor-not-allowed disabled:opacity-30',
						// Styles for the active option.
						'data-on:text-white data-on:bg-blue-500',
						'enabled:data-on:hover:bg-blue-700 enabled:data-on:hover:border-blue-700',
						'data-on:focus-visible:bg-blue-700 data-on:focus-visible:border-blue-700',
						// Styles for inactive options.
						'data-off:before:absolute data-off:before:left-0 data-off:before:top-0 data-off:before:w-px data-off:before:h-full data-off:before:bg-blue-100 disabled:data-off:before:bg-blue-400',
						'enabled:data-off:hover:bg-blue-100 ',
						'data-off:focus-visible:bg-blue-100',
						'first:before:hidden neighbor-data-on:before:hidden'
					)}
				>
					{option.label}
				</RadixToggleGroup.Item>
			))}
		</RadixToggleGroup.Root>
	);
};

/**
 * Add healthsystem form structures
 */
export const addSystemFormSchema = yup.object({
	systemName: yup
		.string()
		.trim()
		.required('A name is required to create a Health System')
		.test('valid', 'This field value is not valid.', requireValidFieldValue)
		.max(100, 'Health System name cannot be more than 100 characters.'),
	city: yup.string().required('Please enter a valid city.'),
	zip: yup.number().required('Please enter valid zip code'),
	street_address: yup.string().required('Please enter a valid street address.'),
	state: yup.string().required('Please enter a valid state.'),
	ehr: yup.string().required('Please select an EHR.'),
	cms_designation: yup.string().required('Please select a CMS Designation.'),
	number_of_beds: yup.number().required('Please enter the number of beds.'),
	facilities: yup.array().of(
		yup.object({
			facilityName: yup
				.string()
				.trim()
				.required('A facility name is required')
				.test('valid', 'This field value is not valid.', requireValidFieldValue)
				.max(100, 'Facility name cannot be more than 100 characters.'),
			license: yup.string().required('A license is required'),
		})
	),
});

export type AddSystemFormInputs = yup.InferType<typeof addSystemFormSchema>;

export interface AddSystemDialogProps {
	children: React.ReactNode;
}

export function AddSystemDialog({ children }: AddSystemDialogProps) {
	const [awaitingRefresh, setAwaitingRefresh] = useState(false);
	const toast = useToast();
	const dispatch = useAppDispatch();
	const [updatePost, { isLoading: isUpdating, error, isError }] = useAddSystemMutation();
	const [dialogOpen, setDialogOpen] = useState(false);
	const [changedFormSinceLastSubmission, setChangedForm] = useState(isError);

	const { getAlertResponse } = useAlert();

	// set up all of the form logic/handlers
	const {
		register,
		handleSubmit,
		control,
		watch,
		setValue,
		reset,
		formState: { errors },
	} = useForm<AddSystemFormInputs>({
		resolver: yupResolver(addSystemFormSchema),
		mode: 'onChange',
		defaultValues: {
			systemName: '',
			facilities: [
				{
					facilityName: '',
					license: createLicenseBits(FacilityLicense.core),
				},
			],
		},
	});

	const {
		fields: facilities,
		append: appendFacility,
		remove: removeFacility,
	} = useFieldArray({ name: 'facilities', control });
	const disableDeleteButton = facilities.length !== 1 ? false : true;
	const onSubmit: SubmitHandler<AddSystemFormInputs> = async (formData) => {
		const requestBody: AddSystemApiRequest = {
			healthsystem_name: formData.systemName,
			street_address: formData.street_address,
			ehr: formData.ehr,
			cms_designation: formData.cms_designation,
			number_of_beds: formData.number_of_beds,
			city: formData.city,
			state: formData.state,
			zip: formData.zip,
			facilities: [],
		};

		if (formData?.facilities?.length) {
			requestBody.facilities = formData.facilities.map((fac): ShortFacility => {
				return {
					facility_name: fac.facilityName,
					license: fac.license,
				};
			});
		}
		const response = await updatePost(requestBody);

		handleApiResponse(response, {
			success: (payload) => {
				setAwaitingRefresh(true);
				toast.createToast({
					title: `${payload.healthsystem_name} was successfully created.`,
					variant: 'success',
				});
				writeToLocalStorage('selectedSystem', payload.healthsystem_id);
				dispatch(setSelectedSystem(payload.healthsystem_id));
				// wait a short time so the user can read the toast, then refresh the page with the new healthsystem selected
				setTimeout(() => {
					window.location.reload();
				}, 3500);
			},
			error: (payload) => {
				toast.createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
				setChangedForm(false);
			},
		});
	};

	useEffect(() => {
		watch('facilities'); // used to keep UI updated based on license changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Dialog
			actionButtons={[
				{
					children: 'Create Health System + Facilities',
					isWorking: isUpdating || awaitingRefresh,
					onClick: handleSubmit(onSubmit),
				},
			]}
			sizeX='lg'
			title='Add New Health System'
			subtitle={`Date Created: ${now()}`}
			trigger={children}
			open={dialogOpen}
			onOpenChange={async (isBeingOpened) => {
				if (isBeingOpened) {
					setDialogOpen(true);
					return;
				}

				const alertResponse = await getAlertResponse({
					description:
						"Closing the window will clear any of the information you've entered in the form, are you sure you'd like to continue?",
					responseOptions: [
						{
							value: 'yes',
							label: 'Yes, close',
						},
					],
				});

				if (alertResponse === 'yes') {
					setDialogOpen(false);
					setChangedForm(true);
					reset();
				}
			}}
		>
			<div
				className={classNames(
					{ 'max-h-72': window.innerHeight < 600, 'max-h-96': window.innerHeight > 600 },
					'overflow-y-auto'
				)}
			>
				<form
					className='flex flex-col'
					onSubmit={handleSubmit(onSubmit)}
					onChange={() => setChangedForm(true)}
					noValidate
					autoComplete='off'
				>
					<div className='max-w-md'>
						<TextField
							label='Health System Name'
							type='text'
							sizeY='sm'
							defaultValue={''}
							errorMessage={errors.systemName?.message}
							{...register('systemName')}
						/>
					</div>

					<div className='flex gap-4 flex-wrap mt-3'>
						<TextField
							label='Street Address'
							errorMessage={errors.street_address?.message}
							{...register('street_address')}
							sizeX='lg'
						/>
						<TextField label='City' errorMessage={errors.city?.message} {...register('city')} sizeX='lg' />
						<Select
							label='State'
							sizeY='sm'
							sizeX='sm'
							errorMessage={errors.state?.message}
							options={us_states.map((state) => ({ label: state, value: state }))}
							onChange={(option) => setValue('state', option?.label ?? '')}
						/>

						<TextField type='number' label='Zip Code' errorMessage={errors.zip?.message} {...register('zip')} sizeX='sm' />
						<Select
							label='EHR'
							sizeY='sm'
							sizeX='lg'
							options={ehr_systems.map((system) => ({ label: system, value: system }))}
							onChange={(option) => setValue('ehr', option?.label ?? '')}
						/>
						<div className='mr-3'>
							<Select
								label='CMS Designation'
								sizeY='sm'
								sizeX='lg'
								errorMessage={errors.cms_designation?.message}
								options={cms_designations.map((cms) => ({ label: cms, value: cms }))}
								onChange={(option) => setValue('cms_designation', option?.label ?? '')}
							/>
						</div>
						<TextField
							type='number'
							label='Number of Beds'
							errorMessage={errors.number_of_beds?.message}
							{...register('number_of_beds')}
							sizeX='lg'
							sizeY='sm'
						/>
					</div>

					<div className='mt-6'>
						<span className='block mb-2 text-p3 text-gray-700 tracking-wider uppercase font-secondary'>Facility Names</span>
						<p className='max-w-sm text-p3 text-gray-400 italic'>
							Enter the names of each facility in this system exactly as they appear in this system&apos;s intraop files.
						</p>
					</div>

					<div className='my-2 pr-2'>
						{facilities.map((field, index) => (
							<div className='flex flex-col' key={index}>
								<div className='min-w-[350px] relative z-[300em]'>
									<LicenseToggleGroup
										label='License Type'
										hideLabel
										options={[
											{
												label: 'Core',
												value: createLicenseBits(FacilityLicense.core),
												license_enum: FacilityLicense.core,
											},
											{
												label: 'Block',
												value: createLicenseBits(FacilityLicense.block),
												license_enum: FacilityLicense.block,
											},
											{
												label: 'Anesthesia',
												value: createLicenseBits(FacilityLicense.anesthesia),
												license_enum: FacilityLicense.anesthesia,
											},
											{
												label: 'Nurse Staffing',
												value: createLicenseBits(FacilityLicense.nurse_staffing),
												license_enum: FacilityLicense.nurse_staffing,
											},
										]}
										onValueChange={(value: string) => {
											setValue(`facilities.${index}.license`, value, { shouldDirty: true });
										}}
										defaultValue={createLicenseBits(FacilityLicense.core)}
									></LicenseToggleGroup>
									{errors.facilities?.[index]?.license?.message && (
										<p className='mt-2 text-p2 text-red-400'>{errors.facilities?.[index]?.license?.message || <>&nbsp;</>}</p>
									)}
								</div>
								<div className='mt-2 flex items-start' key={field.id}>
									<div className='grow mr-2'>
										<TextField
											label='New facility Name'
											hideLabel
											type='text'
											sizeY='sm'
											placeholder='Your facility name'
											errorMessageArea='reserved'
											errorMessage={errors.facilities?.[index]?.facilityName?.message}
											{...register(`facilities.${index}.facilityName`)}
										/>
									</div>
									<div>
										<Button
											type='button'
											disabled={disableDeleteButton}
											variant='primary-ghost'
											sizeX='square'
											sizeY='icon'
											circle
											onClick={() => {
												removeFacility(index);
												setChangedForm(true);
											}}
										>
											<span className='material-symbol block'>delete</span>
										</Button>
									</div>
								</div>
							</div>
						))}
					</div>
				</form>
			</div>
			{!changedFormSinceLastSubmission && error && <p className='my-2 text-p2 text-red-400'>{getQueryError(error)}</p>}
			<ButtonLink
				size='small'
				onClick={() => {
					appendFacility({ facilityName: '', license: createLicenseBits(FacilityLicense.core) });
				}}
			>
				+ Add Facility
			</ButtonLink>
		</Dialog>
	);
}

export default AddSystemDialog;
