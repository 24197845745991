// Third-party modules
import { useState } from 'react';
import { format } from 'date-fns';

import { Accordion, Button, DataTable, Select, Timepicker } from 'components';
import { FacilityScheduleAccordionItem, LoadingScreen, scheduleRecordTypes } from 'pages';
import { Facility, FacilityLicense, UserRole } from 'models';
import { checkLicenseBits, getFacilityName } from 'utils';
import { getQueryError, handleApiResponse, useAppSelector, useSystem } from 'store';
import { useToast } from 'context';

// Type imports
import { useGetReconciliationQuery, useSetEntityMappingMutation } from 'store/services/MappingService';

function MapRecordType({
	record_id,
	selectedFacility,
	healthsystem_id,
}: {
	record_id: number;
	selectedFacility: number;
	healthsystem_id: number;
}) {
	const [recordType, setRecordType] = useState<string | null>(null);
	const [setRecordMapping] = useSetEntityMappingMutation();
	const [loading, setLoading] = useState(false);
	const toast = useToast();

	const saveRecordMapping = async () => {
		setLoading(true);
		const response = await setRecordMapping({
			healthsystem_id: healthsystem_id,
			facility_id: selectedFacility,
			method: 'save_mapping',
			fromId: record_id,
			toId: recordType ?? undefined,
			group: 'schedule_record_types',
		});

		handleApiResponse(response, {
			success: (payload) => {
				toast.createToast({
					title: `Record mapping saved.`,
					variant: 'success',
				});

				setLoading(false);
			},
			error: (payload) => {
				toast.createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
				setLoading(false);
			},
		});
	};

	return (
		<div className='flex w-fit justify-around'>
			<Select
				label={''}
				sizeX='sm'
				options={scheduleRecordTypes.map(({ name }, i) => ({ value: i + 1, label: name }))}
				onChange={(selection) => {
					setRecordType(selection?.label ?? null);
				}}
				value={
					scheduleRecordTypes
						.map(({ name }, i) => ({ value: i + 1, label: name }))
						.filter(({ label }) => label === recordType)[0] ?? 0
				}
				isWorking={loading}
			/>
			<Button
				onClick={saveRecordMapping}
				className='ml-8 mt-2'
				variant='primary-ghost'
				sizeX='sm'
				disabled={recordType === null}
				isWorking={loading}
			>
				Save
			</Button>
		</div>
	);
}

export function ScheduleSettings() {
	const group = 'schedule_record_types';
	const toast = useToast();
	const { data: facility_data } = useSystem();
	const [setAnesthesiaMapping] = useSetEntityMappingMutation();

	const isAdmin = facility_data?.user.role === UserRole.admin;
	const { selectedSystem, selectedFacility: selectedFacilityId } = useAppSelector((state) => state.userState);
	const selectedFacility = facility_data?.facilities?.filter((f) => f.id === selectedFacilityId)[0];

	const { data, isLoading } = useGetReconciliationQuery({
		facility_id: selectedFacility?.id ?? 0,
		healthsystem_id: selectedSystem,
		group: group,
	});

	// TODO: replace with loading animation state one day
	if (isLoading) {
		return <LoadingScreen message='Loading...' />;
	}

	return (
		<div>
			<div className='my-8 w-full'>
				<DataTable
					minHeight={'28em'}
					title='Record Type Mapping'
					tooltipContent={'Use this table to keep track of surgeon and alias mappings.'}
					data={data?.unMappedEntities ?? []}
					columns={[
						{
							accessorKey: 'name',
							meta: {
								headerClass: 'w-80',
							},
							header: 'Record Type Found',
							cell: ({ row }) => <span className='font-semibold w-'>{row.original.name}</span>,
							enableGlobalFilter: false,
						},
						{
							accessorKey: 'date_found',
							header: 'Date Found',
							meta: {
								headerClass: 'w-80',
							},
							cell: ({ row }) => (
								<span className='font-semibold w-'>{format(new Date(row.original.date_found), 'M/d/yyyy') ?? ''}</span>
							),
							enableGlobalFilter: false,
							enableSorting: false,
						},
						{
							accessorKey: 'none',
							header: 'Map to Known Record Type',
							meta: {
								headerClass: 'w-80',
							},
							cell: ({ row }) => (
								<MapRecordType
									record_id={row.original.id}
									selectedFacility={selectedFacility?.id ?? 0}
									healthsystem_id={selectedSystem ?? 0}
								/>
							),
							enableGlobalFilter: false,
							enableSorting: false,
						},
					]}
				/>
			</div>

			{facility_data?.facilities
				?.filter((f) => checkLicenseBits(f.license, FacilityLicense.anesthesia))
				.map((facility: Facility, indx: number) => (
					<FacilityScheduleAccordionItem
						key={indx}
						facility={facility}
						otherFacilities={facility_data?.facilities?.filter(
							(facility_data: Facility) => facility_data.id !== facility.id && facility_data.field_mapping !== '{}'
						)}
					/>
				))}
		</div>
	);
}
