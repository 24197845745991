import * as Tabs from '@radix-ui/react-tabs';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { throwError } from '@microsoft/applicationinsights-core-js';
import { event } from 'cypress/types/jquery';
import { format } from 'date-fns';
import { Row } from '@tanstack/table-core';

import {
	Button,
	Checkbox,
	DataTable,
	Datepicker,
	Dialog,
	MultiSelect,
	Select,
	TextField,
	Timepicker,
	ToggleGroup,
} from 'components';
import { FilterContextField, SurgeonsState, useAlert, useFilters } from 'context';
import {
	handleApiResponse,
	ShortOption,
	useAppSelector,
	useCheckPatternCollisionMutation,
	useGetBlockPatternPreviewQuery,
	useGetBlockServiceLineQuery,
	useGetServiceLineNameQuery,
	useSystem,
} from 'store';
import { DAYS_OF_WEEK_LIST, stringTimeToDate, truncateLabel } from 'utils';
import { LoadingIndicator } from 'components/Select/subcomponents';

import { BlockPreviewHeader } from '../BlockScorecard';
import { militaryTimeDifference, minMaxTime } from './ReleaseDialog';
import BlockPatternFlowChoice from './BlockPatternStep1';
import BlockPatternFlowDetails from './BlockPatternStep2';
import BlockPatternFlowSummary from './BlockPatternStep3';

const tabTriggerClassname = classNames(
	'py-2 px-6 relative padding-x-3 text-bluegray-300 cursor-default',
	'data-active:text-blue-500 data-active:border-b-2 data-active:border-blue-500 data-active:top-[1px]'
);
const tabContentClassname = 'pt-4 pl-8 h-[calc(100vh-200px)] overflow-y-scroll';

export const dowConst = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export interface BlockPatternFlowProps {
	children: React.ReactNode;
	facility_id: number | null;
	healthsystem_id: number | null;
	default_block_option?: ShortOption;
	default_pattern_flow?: 'Add' | 'Edit' | 'Delete' | 'End';
}

export interface Step1CacheProps {
	block_option: { id: number; name: string } | null;
	dow_selected: number | null;
	dow_selected_index?: number | null;
	patterns: {
		day_of_week: string;
		week_of_month: number[];
		range: string;
		total_min: number;
	}[];
	flow: string;
	service_line_id: number | undefined;
}

export interface BlockPatternRoom {
	room_index?: number;
	start_time: string;
	end_time: string;
}

export interface BlockPatternDetailsPOST {
	patterns: {
		week: number; // if 0 then every other week pattern
		rooms: BlockPatternRoom[];
		surgeons: ShortOption[];
	}[];
	healthsystem_id: number | null;
	assigned_facility: number | null;
	dow_selected?: number | null;
	effective_date: Date | string;
	every_other_week: boolean;
	selected_block: ShortOption; // contains block_id
	block_pattern_id?: number; // only present upon edit
	earliest_start?: string;
	latest_end?: string;
	end_date?: Date | string;
	serviceline_name?: string;
}

export interface ChangeRoomProps {
	is_start: boolean;
	room_time: string;
	index: number;
}

export interface AddRoomProps {
	room_start: string;
	room_end: string;
}

export interface ChangeSurgeonsProps {
	surgeons: ShortOption[];
}

export interface PatternRowProps {
	row: Row<{
		week: number;
		rooms: BlockPatternRoom[];
		surgeons: ShortOption[];
	}>;
}

export function BlockPatternFlow({
	children,
	facility_id,
	healthsystem_id,
	default_block_option,
	default_pattern_flow,
}: BlockPatternFlowProps) {
	const [flowMode, setFlowMode] = useState<'Add' | 'Edit' | 'Delete' | 'End'>(default_pattern_flow ?? 'Add');
	const [dialogOpen, setDialogOpen] = useState(false);
	const [currentTab, setCurrentTab] = useState('step_1');
	const tabOrder = ['step_1', 'step_2', 'step_3'];
	const { data } = useSystem();
	const facilities = data?.facilities;
	const [tabPayload, setTabPayload] = useState<{
		step_1: Step1CacheProps | null;
		step_2: BlockPatternDetailsPOST | null;
		step_3: Step1CacheProps | null;
	}>({
		step_1: default_block_option
			? {
					block_option: default_block_option,
					dow_selected: null,
					dow_selected_index: null,
					patterns: [],
					flow: default_pattern_flow ?? 'Add',
					service_line_id: undefined,
			  }
			: null,
		step_2: null,
		step_3: null,
	});

	const changeTab = (currentTab: string, direction: 'forward' | 'back') => {
		const currentIndex = tabOrder.indexOf(currentTab);

		if (!(tabOrder.length <= currentIndex + 1) && direction === 'forward') {
			setCurrentTab(tabOrder[currentIndex + 1]);
		}

		if (currentIndex > 0 && direction === 'back') {
			setCurrentTab(tabOrder[currentIndex - 1]);
		}
	};

	const restoreDefaults = () => {
		setFlowMode(default_pattern_flow ?? 'Add');
		setCurrentTab('step_1');
		setTabPayload({
			step_1: !default_block_option ? null : tabPayload.step_1,
			step_2: null,
			step_3: null,
		});
	};

	return (
		<Dialog
			actionButtons={[]}
			minimalHeaderStyle
			sizeX='lg_wide'
			title={`${flowMode === 'Add' ? 'Adding' : flowMode === 'Edit' ? 'Editing' : 'Ending'} Block Pattern`}
			trigger={children}
			open={dialogOpen}
			onOpenChange={async (isBeingOpened) => {
				if (isBeingOpened) {
					setDialogOpen(true);
					return;
				}
				if (!isBeingOpened) {
					restoreDefaults();
					setDialogOpen(false);
				}
			}}
		>
			<Tabs.Root value={currentTab} orientation='vertical'>
				<Tabs.List aria-label='Settings Tabs' className='flex border-b border-bluegray-100 pl-7 pr-7'>
					<Tabs.Trigger value='step_1' className={tabTriggerClassname}>
						1. Block Pattern
					</Tabs.Trigger>
					{flowMode !== 'End' && (
						<>
							<Tabs.Trigger value='step_2' className={tabTriggerClassname}>
								2. Block Pattern Details
							</Tabs.Trigger>
							<Tabs.Trigger value='step_3' className={tabTriggerClassname}>
								3. Block Pattern Summary
							</Tabs.Trigger>
						</>
					)}
				</Tabs.List>
				<Tabs.Content value='step_1' className={tabContentClassname}>
					<BlockPatternFlowChoice
						setFlowMode={(mode: 'Add' | 'Edit' | 'Delete' | 'End') => {
							setFlowMode(mode);
						}}
						facility_id={facility_id}
						closeModule={() => {
							restoreDefaults();
							setDialogOpen(false);
						}}
						wipeCache={() => {
							restoreDefaults();
						}}
						submit={(data) => {
							setTabPayload({ ...tabPayload, step_1: data });
							changeTab('step_1', 'forward');
						}}
						setSelectedBlockPattern={(data) => {
							setTabPayload({ ...tabPayload, step_2: data });
						}}
						cachedData={{
							mode: flowMode,
							block_option: tabPayload.step_1 ? tabPayload.step_1.block_option : null,
							dow_selected: tabPayload.step_1 ? tabPayload.step_1.dow_selected : null,
							dow_selected_index: tabPayload.step_1 !== null ? tabPayload.step_1.dow_selected_index : null,
						}}
						healthsystem_id={healthsystem_id}
					/>
				</Tabs.Content>
				<Tabs.Content value='step_2' className={tabContentClassname}>
					<BlockPatternFlowDetails
						setFlowMode={(mode: 'Add' | 'Edit') => {
							setFlowMode(mode);
						}}
						goBackModule={() => {
							changeTab('step_2', 'back');
						}}
						submit={(data) => {
							data.dow_selected = tabPayload.step_1?.dow_selected;
							setTabPayload({ ...tabPayload, step_2: data });
							changeTab('step_2', 'forward');
						}}
						previousTabData={tabPayload.step_1}
						cachedData={tabPayload.step_2}
						healthsystem_id={healthsystem_id}
						facility_id={facility_id}
						facilites={facilities}
					/>
				</Tabs.Content>
				<Tabs.Content value='step_3' className={tabContentClassname}>
					<BlockPatternFlowSummary
						setFlowMode={(mode: 'Add' | 'Edit') => {
							setFlowMode(mode);
						}}
						goBackModule={() => {
							changeTab('step_3', 'back');
						}}
						closeModule={() => {
							restoreDefaults();
							setDialogOpen(false);
						}}
						previousTabData={{
							step_1: tabPayload.step_1,
							step_2: tabPayload.step_2,
						}}
						facilites={facilities}
					/>
				</Tabs.Content>
			</Tabs.Root>
		</Dialog>
	);
}

function getNumberWithOrdinal(n: number) {
	const s = ['th', 'st', 'nd', 'rd'],
		v = n % 100;
	return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export function RoomModule(props: {
	rooms: BlockPatternRoom[];
	changeRoom: (args: ChangeRoomProps) => void;
	addRoom: (args: AddRoomProps) => void;
	removeRoom: () => void; // remove last room
	addWeek: () => void;
	removeWeek: () => void;
	selectedSurgeons: ShortOption[];
	isEarliestWeek: boolean;
}) {
	let total_hours = 0.0;

	props.rooms.forEach((_room) => {
		const start_minutes = parseInt(_room.start_time.split(':')[0]) * 60 + parseInt(_room.start_time.split(':')[1]);
		const end_minutes = parseInt(_room.end_time.split(':')[0]) * 60 + parseInt(_room.end_time.split(':')[1]);
		const hours = Math.round(((end_minutes - start_minutes) / 60) * 100) / 100;
		total_hours = total_hours + hours;
	});

	return (
		<div className='flex flex-col'>
			{props.rooms.length > 0 ? (
				props.rooms.map((_room, index) => {
					const start_minutes = parseInt(_room.start_time.split(':')[0]) * 60 + parseInt(_room.start_time.split(':')[1]);
					const end_minutes = parseInt(_room.end_time.split(':')[0]) * 60 + parseInt(_room.end_time.split(':')[1]);
					const hours = Math.round(((end_minutes - start_minutes) / 60) * 100) / 100;
					return (
						<div
							key={index}
							className={`flex items-baseline w-14 ${
								index === 0 && props.rooms.length > 1
									? 'mt-4 h-10'
									: index + 1 === props.rooms.length && props.rooms.length > 1 && props.isEarliestWeek
									? 'h-24'
									: props.rooms.length === 1
									? 'h-18 mt-4 mb-4'
									: index + 1 === props.rooms.length && props.rooms.length > 1
									? 'h-18'
									: 'h-10'
							}`}
						>
							<div className='mr-10'>
								<p className='whitespace-nowrap text-p3 w-10'>{getNumberWithOrdinal(index + 1)} Room</p>
								<p className='w-4 whitespace-nowrap opacity-0 mt-3'>+</p>
							</div>
							<div className='mr-7 mb-2'>
								<Timepicker
									interval={15}
									maxHour={parseInt(_room.end_time.split(':')[0])}
									maxMinute={parseInt(_room.end_time.split(':')[1]) !== 0 ? parseInt(_room.end_time.split(':')[1]) - 15 : 0}
									onChange={(time) => {
										props.changeRoom({
											is_start: true,
											room_time: time.military,
											index,
										});
									}}
									selected={stringTimeToDate(_room.start_time)}
									disabled={!props.isEarliestWeek}
								/>
								{index + 1 === props.rooms.length && <p className='w-4 whitespace-nowrap opacity-0 mt-3'>+ Add Another Room</p>}
							</div>
							<div className='mr-3 mb-2'>
								<Timepicker
									interval={15}
									onChange={(time) => {
										props.changeRoom({
											is_start: false,
											room_time: time.military,
											index,
										});
									}}
									minHour={parseInt(_room.start_time.split(':')[0])}
									minMinute={parseInt(_room.start_time.split(':')[1]) !== 0 ? parseInt(_room.start_time.split(':')[1]) + 15 : 15}
									selected={stringTimeToDate(_room.end_time)}
									disabled={!props.isEarliestWeek}
								/>
								{index + 1 === props.rooms.length && props.isEarliestWeek && (
									<>
										<div className='flex flex-col'>
											<p
												onClick={() =>
													props.addRoom({
														room_start: '07:00:00',
														room_end: '15:00:00',
													})
												}
												className='w-10 whitespace-nowrap mt-3 ml-2 text-blue-600 text-p3 cursor-pointer hover:underline'
											>
												+ Add Another Room
											</p>
											{props.rooms.length > 0 && (
												<p
													onClick={() => props.removeRoom()}
													className='w-10 whitespace-nowrap ml-[27px] text-red-600 text-p3 mb-2 cursor-pointer hover:underline'
												>
													- Remove a Room
												</p>
											)}
										</div>
									</>
								)}
								{index + 1 === props.rooms.length && props.rooms.length > 0 && !props.isEarliestWeek && (
									<>
										<div className='flex flex-col'>
											<p
												onClick={() => props.removeWeek()}
												className='w-10 mt-3 whitespace-nowrap ml-[2.2rem] text-red-600 text-p3 mb-2 cursor-pointer hover:underline'
											>
												- Remove Week
											</p>
										</div>
									</>
								)}
							</div>
							<div>
								<p className='text-center whitespace-nowrap mt-4 ml-[0.6rem] text-p3 mb-2 w-16'>{hours}</p>
							</div>
							<div>
								{index === 0 ? (
									<p className='text-center whitespace-nowrap mt-4 ml-10 text-p3 w-24'>{total_hours}</p>
								) : (
									<p className='text-center whitespace-nowrap mt-4 ml-10 text-p3 opacity-0'>0</p>
								)}
							</div>

							<div className='h-8'>
								{index === 0 ? (
									<div className='ml-[3rem] w-48'>
										{props.selectedSurgeons.slice(0, 2).map((surgeon, i) => (
											<p key={i} className='text-[0.8em] bg-gray-50 px-2 py-1 mb-1 whitespace-nowrap w-fit'>
												{surgeon.name}
											</p>
										))}
									</div>
								) : (
									<div className='opacity-0 ml-[2rem]'></div>
								)}
								{index === 0 && props.selectedSurgeons.length > 2 && (
									<p className='ml-[2.5rem] text-[0.8em] text-gray-500 px-2 py-1 mb-1 whitespace-nowrap w-fit'>{`+ ${
										props.selectedSurgeons.length - 2
									} More Surgeon(s)`}</p>
								)}
								{index === 0 && props.selectedSurgeons.length === 0 && (
									<div className='ml-[3rem] w-48'>
										<p className='text-[0.8em] bg-yellow-50 text-yellow-700 px-2 py-1 mb-1 whitespace-nowrap w-fit'>
											No Surgeons Selected
										</p>
									</div>
								)}
							</div>
						</div>
					);
				})
			) : (
				<div className='flex w-14'>
					<p className='text-gray-400 italic text-p3 whitespace-nowrap'>No Rooms</p>
					<p
						onClick={() => props.addWeek()}
						className='w-10 whitespace-nowrap ml-[13.7rem] text-blue-600 text-p3 cursor-pointer hover:underline'
					>
						+ Include Week
					</p>
				</div>
			)}
		</div>
	);
}

export function overlapAlert(isError: boolean, surgeons: string[], blockName: string) {
	return (
		<div className='flex flex-col'>
			<p className='text-p2'>
				<strong>Your proposed changes to this block will result in some surgeons being double booked.</strong> The block
				schedule you’ve chosen for {blockName} has overlapping allocated block days for the following surgeons.
			</p>
			<div className='flex flex-col max-h-24 h-24 overflow-y-auto text-p3 my-4 border-gray-200 rounded-sm border pl-2 py-2'>
				{surgeons.map((surgeon, i) => (
					<p key={i}>- {surgeon}</p>
				))}
			</div>
			<p className='text-p2'>
				{!isError ? (
					`Proceeding with this allocation can impact the block utilization value for blocks with the surgeons listed above.`
				) : (
					<strong>
						To change this setting, go to Settings and under Block Settings toggle Allow Block Overbooking to On.
					</strong>
				)}{' '}
				If you need assistance troubleshooting, contact the Merlin team for further assistance.{' '}
				{!isError && <strong>Are you sure you wish to proceed with this change?</strong>}
			</p>
		</div>
	);
}

export default BlockPatternFlow;
