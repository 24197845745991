import classNames from 'classnames';

import { colorMaps } from 'utils';
import { GaugeChart } from 'components';

import type { ChartColor } from 'utils';

export interface Metric {
	/** The label for the field/metric. */
	label: string;

	/** The value for the field/metric */
	value: string | number | null;

	/** The unit/metric to show next to the value ('%', 'mins', etc.) */
	metric?: string;

	/** The "effect" of this metric, the subtext shown next to value. Examples: '3% mth'. Use with color/direction props. */
	effect?: string | number;

	/** The color of the effect text. */
	effectColor?: 'red' | 'green';

	/** The direction of the arrow in the effect. */
	effectDirection?: 'up' | 'down';

	/** Whether to show a gauge chart with a metric or just the bar-chart icon with a metric. */
	type: 'gauge' | 'bar';

	/** The color of the gauge chart line if using a gauge chart. Can also reference preset "ranges" that determine what the color should be dynamically. */
	chartColor?: ChartColor | keyof typeof colorMaps;
}

export interface MetricPanelProps {
	left: Metric;
	right: Metric;
}

export function MetricPanel({ left, right }: MetricPanelProps) {
	const { chartColor: leftChartColor = 'light-blue' } = left;
	const { chartColor: rightChartColor = 'light-blue' } = right;
	return (
		<div className='h-16 flex items-center justify-evenly gap-3 p-3 bg-bluegray-900 text-white rounded-sm'>
			<div className='flex items-center gap-3'>
				<div>
					{left.type === 'gauge' && (
						<GaugeChart
							textColor='white'
							percentage={typeof left.value === 'number' ? left.value : 0}
							radius={20}
							strokeWidth={4}
							progressColor={leftChartColor}
						/>
					)}
					{left.type === 'bar' && <span className='material-symbol-xl text-bluegray-500'>bar_chart</span>}
				</div>
				<div>
					<div className='font-preset-label-white uppercase font-secondary text-p3'>{left.label}</div>
					<div className='flex items-center gap-2 mt-0.5'>
						<div className='text-p2 font-semibold'>
							{left.value ? `${left.value}${left.type === 'gauge' && left.metric ? '%' : left.metric ?? ''}` : '-'}
						</div>
						{left.effect && (
							<div
								className={classNames('flex items-center', {
									'text-green-500': left.effectColor === 'green',
									'text-red-500': left.effectColor === 'red',
								})}
							>
								{left.effectDirection === 'up' && <div className='material-symbol-[1rem]'>arrow_upward</div>}
								{left.effectDirection === 'down' && <div className='material-symbol-[1rem]'>arrow_downward</div>}
								<div className='text-p3'>{left.effect}</div>
							</div>
						)}
					</div>
				</div>
			</div>

			<div className='w-1 my-0.5 rotate-[18deg] rounded-full self-stretch bg-white' />

			<div className='flex items-center gap-3'>
				<div>
					{typeof right.value === 'number' && right.type === 'gauge' && (
						<GaugeChart
							textColor='white'
							percentage={right.value}
							radius={20}
							strokeWidth={4}
							progressColor={rightChartColor}
						/>
					)}
					{right.type === 'bar' && <span className='material-symbol-xl text-bluegray-500'>bar_chart</span>}
				</div>
				<div>
					<div className='font-preset-label-white uppercase font-secondary text-p3'>{right.label}</div>
					<div className='flex items-center gap-2 mt-0.5'>
						<div className='text-p2 font-semibold'>{`${right.value}${
							right.type === 'gauge' && right.metric ? '%' : right.metric ?? ''
						}`}</div>
						{right.effect && (
							<div
								className={classNames('flex items-center', {
									'text-green-500': right.effectColor === 'green',
									'text-red-500': right.effectColor === 'red',
								})}
							>
								{right.effectDirection === 'up' && <div className='material-symbol-[1rem]'>arrow_upward</div>}
								{right.effectDirection === 'down' && <div className='material-symbol-[1rem]'>arrow_downward</div>}
								<div className='text-p3'>{right.effect}</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
