import { useNavigate } from 'react-router-dom';

import { Button } from 'components/Button';
import image from 'assets/images/configless-facility.svg';
import { Routes } from 'models/navigation';

export function EmptyFacilityConfig() {
	const navigate = useNavigate();
	return (
		<div className='flex justify-center align-center'>
			<div className='bg-gray-50 rounded text-center p-12'>
				<img className='mx-auto my-16 block max-w-xl' alt='Facility needs configuration' src={image} />
				<h1 className='text-h4'>You haven&apos;t configured this facility yet.</h1>
				<p className='text-p mx-auto max-w-lg leading-6 mt-2 mb-12'>
					In order to view metrics here, you need to complete the configuration for the facility. This is so we know how to
					relate all of the intraop-level case data to the appropriate facility.
				</p>
				<Button
					onClick={() => {
						navigate(Routes.SETTINGS);
					}}
					className='mx-auto'
					variant='primary-ghost'
					sizeX='lg'
					sizeY='md'
				>
					Finish Config
				</Button>
			</div>
		</div>
	);
}

export default EmptyFacilityConfig;
