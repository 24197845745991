import { Children } from 'react';
import classNames from 'classnames';
import reactElementToJSXString from 'react-element-to-jsx-string';

import type { HTMLAttributes } from 'react';

interface MarkupTableProps extends HTMLAttributes<HTMLDivElement> {
	columnWidths?: [`${number}px` | `${number}%` | 'auto', `${number}px` | `${number}%` | 'auto'];
	children: React.ReactNode | React.ReactNode[];
}

/**
 * A table that displays a row for each child displaying both that child's
 * markup and its rendered output.
 */
export function MarkupTable({ columnWidths = ['auto', '100%'], className, children, ...props }: MarkupTableProps) {
	return (
		<div className={classNames('overflow-hidden rounded border border-gray-400 bg-white', className)} {...props}>
			<table className='w-full'>
				<colgroup>
					<col width={columnWidths[0]} />
					<col width={columnWidths[1]} />
				</colgroup>

				<thead>
					<tr className='font-bold border-b border-gray-400 bg-blue-50'>
						<td className='p-2'>Markup</td>
						<td className='p-2 border-l border-gray-400'>Output</td>
					</tr>
				</thead>

				<tbody>
					{children &&
						Children.toArray(children).map((child, i) => (
							<tr key={i} className='border-b border-gray-400 last:border-0'>
								<td className='p-2'>
									<pre>
										<code>
											{reactElementToJSXString(child, {
												showFunctions: true,
												sortProps: false,
												functionValue: (fn) => fn,
											})}
										</code>
									</pre>
								</td>
								<td className='p-2 border-l border-gray-400 text-center'>{child}</td>
							</tr>
						))}
				</tbody>
			</table>
		</div>
	);
}

export default MarkupTable;
