/* eslint-disable no-console */
import { useState } from 'react';

import {
	Accordion,
	Alert,
	Button,
	ButtonStack,
	Checkbox,
	DataTable,
	Datepicker,
	DateToggle,
	Dialog,
	Drawer,
	FilterDrawer,
	FilterFields,
	GaugeChart,
	Heatmap,
	MetricPanel,
	MultiSelect,
	NumberField,
	Panel,
	RadioButton,
	Select,
	TextArea,
	TextField,
	Timepicker,
	ToggleGroup,
	Tooltip,
} from 'components';
import {
	ApiTable,
	ButtonTable,
	CodeBlock,
	ColorTable,
	MarkupTable,
	MarkupTabs,
	PropTable,
	ReturnToTopButton,
	TableOfContents,
} from 'components/StyleGuide';
import { generateCaseDetailRecords, generateHeatmapData, generateSelectOptions } from 'utils/development';
import { useAlert, useFilters, useToast } from 'context';
import { DAYS_OF_WEEK_LIST } from 'utils';

const dateFormatter = new Intl.DateTimeFormat('en-US');
const dummySelectOptions = generateSelectOptions(3);

export function StyleGuide() {
	const { createToast } = useToast();
	const { getAlertResponse } = useAlert();
	const {
		date,
		dateRange,
		surgeon,
		surgeons,
		daysOfWeek,
		serviceLines,
		encounterTypes,
		rooms,
		turnoverTimeThreshold,
		fcotsGraceMinutes,
		percentile,
		primetime,
		addOns,
		utilizationType,
		timeslotType,
		blockTimeslot,
		metadata,
	} = useFilters();

	const [range, setRange] = useState<[Date, Date]>([new Date(), new Date()]);
	const [datepickerDate, setDatepickerDate] = useState(new Date());
	const [buttonDate, setButtonDate] = useState(new Date('12/31/2022'));
	const [numberValue, setNumberValue] = useState(0);

	const [dialogOpen, setDialogOpen] = useState(false);

	return (
		<div className='m-auto'>
			<ReturnToTopButton />

			<h1 className='text-h2'>Merlin Styleguide &amp; Component Library</h1>

			<section className='mt-4'>
				<h2 id='toc' className='text-h3 scroll-mt-4'>
					Table of Contents
				</h2>

				<TableOfContents
					entries={[
						{ name: 'Colors', url: '#colors' },
						{ name: 'Typography', url: '#typography' },
						{ name: 'Icons', url: '#icons' },
						{
							name: 'Layout Components',
							url: '#layout_components',
							subEntries: [
								{ name: 'Panels', url: '#panels' },
								{ name: 'Metric Panels (Dark)', url: '#metrics' },
								{ name: 'Accordions', url: '#accordions' },
								{ name: 'Drawers', url: '#drawers' },
								{ name: 'Dialogs', url: '#dialogs' },
							],
						},
						{
							name: 'Chart Components',
							url: '#chart_components',
							subEntries: [
								{ name: 'Gauges', url: '#gauge' },
								{ name: 'Heatmaps', url: '#heatmaps' },
							],
						},
						{ name: 'Buttons', url: '#buttons' },
						{ name: 'Button Stacks', url: '#button_stacks' },
						{ name: 'Toggle Groups', url: '#toggle_groups' },
						{
							name: 'Inputs',
							url: '#inputs',
							subEntries: [
								{ name: 'Checkboxes & Radio Buttons', url: '#checkboxes_and_radio_buttons' },
								{ name: 'Text Fields', url: '#text_fields' },
								{ name: 'Text Areas', url: '#text_areas' },
								{ name: 'Number Fields', url: '#number_fields' },
								{ name: 'Select Fields', url: '#select_fields' },
								{ name: 'Datepickers', url: '#datepickers' },
							],
						},
						{ name: 'Data Tables', url: '#data_tables' },
						{ name: 'Toasts', url: '#toasts' },
						{ name: 'Tooltips', url: '#tooltips' },
						{ name: 'Dropdown Navigation Menus', url: '#dropdowns' },
						{ name: 'Alerts', url: '#alerts' },
						{
							name: 'Filter Components',
							url: '#filter_components',
							subEntries: [
								{ name: 'Filter Fields', url: '#filter_fields' },
								{ name: 'Filter Drawer', url: '#filter_drawer' },
								{ name: 'The "useFilters" Hook', url: '#usefilters_hook' },
							],
						},
					]}
				/>
			</section>

			<section className='mt-4'>
				<h2 id='colors' className='text-h3 scroll-mt-4'>
					Colors
				</h2>

				<div className='mt-2'>
					<p>
						Most colors used within the project fall within one of several color groups, each used to indicate a particular
						state or sentiment. There are also a few &quot;one-off&quot; colors that don&apos;t directly relate to one of the
						established groups.
					</p>

					<p className='mt-2'>
						To apply a specific color to an element, you can use the appropriate Tailwind utility class, incorporating one of
						the color names and shades below. Tailwind includes classes for controlling text, border, background, and other
						color properties on a per-element basis; you can find a full list at{' '}
						<a href='https://tailwindcss.com/docs/text-color'>Tailwind&apos;s documentation site</a>.
					</p>
				</div>

				<Accordion type='multiple' itemStyle='contained' className='mt-4'>
					<Accordion.Item value='Yellow'>
						<p>Yellow is used to express a warning or area of concern to the user.</p>

						<ColorTable
							className='mt-4'
							colors={[
								{ classSuffix: '-yellow-50', bg: 'bg-yellow-50', text: 'text-yellow-50' },
								{ classSuffix: '-yellow-100', bg: 'bg-yellow-100', text: 'text-yellow-100' },
								{ classSuffix: '-yellow-200', bg: 'bg-yellow-200', text: 'text-yellow-200' },
								{ classSuffix: '-yellow-300', bg: 'bg-yellow-300', text: 'text-yellow-300' },
								{ classSuffix: '-yellow-400', bg: 'bg-yellow-400', text: 'text-yellow-400' },
								{ classSuffix: '-yellow-500', bg: 'bg-yellow-500', text: 'text-yellow-500' },
								{ classSuffix: '-yellow-600', bg: 'bg-yellow-600', text: 'text-yellow-600' },
								{ classSuffix: '-yellow-700', bg: 'bg-yellow-700', text: 'text-yellow-700' },
								{ classSuffix: '-yellow-800', bg: 'bg-yellow-800', text: 'text-yellow-800' },
								{ classSuffix: '-yellow-900', bg: 'bg-yellow-900', text: 'text-yellow-900' },
							]}
						/>
					</Accordion.Item>

					<Accordion.Item value='Green'>
						<p>Green is used to indicate a state of success or completion to the user.</p>

						<ColorTable
							className='mt-4'
							colors={[
								{ classSuffix: '-green-50', bg: 'bg-green-50', text: 'text-green-50' },
								{ classSuffix: '-green-100', bg: 'bg-green-100', text: 'text-green-100' },
								{ classSuffix: '-green-200', bg: 'bg-green-200', text: 'text-green-200' },
								{ classSuffix: '-green-300', bg: 'bg-green-300', text: 'text-green-300' },
								{ classSuffix: '-green-400', bg: 'bg-green-400', text: 'text-green-400' },
								{ classSuffix: '-green-500', bg: 'bg-green-500', text: 'text-green-500' },
								{ classSuffix: '-green-600', bg: 'bg-green-600', text: 'text-green-600' },
								{ classSuffix: '-green-700', bg: 'bg-green-700', text: 'text-green-700' },
								{ classSuffix: '-green-800', bg: 'bg-green-800', text: 'text-green-800' },
								{ classSuffix: '-green-900', bg: 'bg-green-900', text: 'text-green-900' },
							]}
						/>
					</Accordion.Item>

					<Accordion.Item value='Red'>
						<p>Red is used to notify the user of an error or mistake.</p>

						<ColorTable
							className='mt-4'
							colors={[
								{ classSuffix: '-red-50', bg: 'bg-red-50', text: 'text-red-50' },
								{ classSuffix: '-red-100', bg: 'bg-red-100', text: 'text-red-100' },
								{ classSuffix: '-red-200', bg: 'bg-red-200', text: 'text-red-200' },
								{ classSuffix: '-red-300', bg: 'bg-red-300', text: 'text-red-300' },
								{ classSuffix: '-red-400', bg: 'bg-red-400', text: 'text-red-400' },
								{ classSuffix: '-red-500', bg: 'bg-red-500', text: 'text-red-500' },
								{ classSuffix: '-red-600', bg: 'bg-red-600', text: 'text-red-600' },
								{ classSuffix: '-red-700', bg: 'bg-red-700', text: 'text-red-700' },
								{ classSuffix: '-red-800', bg: 'bg-red-800', text: 'text-red-800' },
								{ classSuffix: '-red-900', bg: 'bg-red-900', text: 'text-red-900' },
							]}
						/>
					</Accordion.Item>

					<Accordion.Item value='Blue'>
						<p>Blue is used to help the user identify important or interactive elements.</p>

						<ColorTable
							className='mt-4'
							colors={[
								{ classSuffix: '-blue-50', bg: 'bg-blue-50', text: 'text-blue-50' },
								{ classSuffix: '-blue-100', bg: 'bg-blue-100', text: 'text-blue-100' },
								{ classSuffix: '-blue-200', bg: 'bg-blue-200', text: 'text-blue-200' },
								{ classSuffix: '-blue-300', bg: 'bg-blue-300', text: 'text-blue-300' },
								{ classSuffix: '-blue-400', bg: 'bg-blue-400', text: 'text-blue-400' },
								{ classSuffix: '-blue-500', bg: 'bg-blue-500', text: 'text-blue-500' },
								{ classSuffix: '-blue-600', bg: 'bg-blue-600', text: 'text-blue-600' },
								{ classSuffix: '-blue-700', bg: 'bg-blue-700', text: 'text-blue-700' },
								{ classSuffix: '-blue-800', bg: 'bg-blue-800', text: 'text-blue-800' },
								{ classSuffix: '-blue-900', bg: 'bg-blue-900', text: 'text-blue-900' },
							]}
						/>
					</Accordion.Item>

					<Accordion.Item value='Blue-Gray'>
						<p>Blue-gray is used to highlight items of secondary importance to the user.</p>

						<ColorTable
							className='mt-4'
							colors={[
								{ classSuffix: '-bluegray-50', bg: 'bg-bluegray-50', text: 'text-bluegray-50' },
								{ classSuffix: '-bluegray-100', bg: 'bg-bluegray-100', text: 'text-bluegray-100' },
								{ classSuffix: '-bluegray-200', bg: 'bg-bluegray-200', text: 'text-bluegray-200' },
								{ classSuffix: '-bluegray-300', bg: 'bg-bluegray-300', text: 'text-bluegray-300' },
								{ classSuffix: '-bluegray-400', bg: 'bg-bluegray-400', text: 'text-bluegray-400' },
								{ classSuffix: '-bluegray-500', bg: 'bg-bluegray-500', text: 'text-bluegray-500' },
								{ classSuffix: '-bluegray-600', bg: 'bg-bluegray-600', text: 'text-bluegray-600' },
								{ classSuffix: '-bluegray-700', bg: 'bg-bluegray-700', text: 'text-bluegray-700' },
								{ classSuffix: '-bluegray-800', bg: 'bg-bluegray-800', text: 'text-bluegray-800' },
								{ classSuffix: '-bluegray-900', bg: 'bg-bluegray-900', text: 'text-bluegray-900' },
							]}
						/>
					</Accordion.Item>

					<Accordion.Item value='Gray'>
						<p>Gray is used to deemphasize elements or indicate a state of inactivity to the user.</p>

						<ColorTable
							className='mt-4'
							colors={[
								{ classSuffix: '-gray-50', bg: 'bg-gray-50', text: 'text-gray-50' },
								{ classSuffix: '-gray-100', bg: 'bg-gray-100', text: 'text-gray-100' },
								{ classSuffix: '-gray-200', bg: 'bg-gray-200', text: 'text-gray-200' },
								{ classSuffix: '-gray-300', bg: 'bg-gray-300', text: 'text-gray-300' },
								{ classSuffix: '-gray-400', bg: 'bg-gray-400', text: 'text-gray-400' },
								{ classSuffix: '-gray-500', bg: 'bg-gray-500', text: 'text-gray-500' },
								{ classSuffix: '-gray-600', bg: 'bg-gray-600', text: 'text-gray-600' },
								{ classSuffix: '-gray-700', bg: 'bg-gray-700', text: 'text-gray-700' },
								{ classSuffix: '-gray-800', bg: 'bg-gray-800', text: 'text-gray-800' },
								{ classSuffix: '-gray-900', bg: 'bg-gray-900', text: 'text-gray-900' },
							]}
						/>
					</Accordion.Item>

					<Accordion.Item value='Other'>
						<ColorTable
							colors={[
								{ classSuffix: '-black', bg: 'bg-black', text: 'text-black' },
								{ classSuffix: '-white', bg: 'bg-white', text: 'text-white' },
								{ classSuffix: '-merlinBlue', bg: 'bg-merlinBlue', text: 'text-merlinBlue' },
								{ classSuffix: '-merlinGreen', bg: 'bg-merlinGreen', text: 'text-merlinGreen' },
							]}
						/>
					</Accordion.Item>
				</Accordion>
			</section>

			<section className='mt-4'>
				<h2 id='typography' className='text-h3 scroll-mt-4'>
					Typography
				</h2>

				<div className='mt-2'>
					<p>
						There are two fonts used within the project:{' '}
						<a href='https://fonts.google.com/specimen/Inter' target='_blank' rel='noreferrer'>
							Inter
						</a>
						, our primary font that is used for the majority of text elements, and{' '}
						<a href='https://fonts.google.com/specimen/Barlow' target='_blank' rel='noreferrer'>
							Barlow
						</a>
						, a secondary font that is mostly used for fine text like input labels and disclaimers. Both fonts are available
						in a variety of predefined sizes and weights that can be applied using Tailwind utility classes, as demonstrated
						below.
					</p>
				</div>

				<Accordion type='multiple' itemStyle='contained' className='mt-4'>
					<Accordion.Item value='Primary, Regular'>
						<PropTable columnWidths={['300px', 'auto']}>
							<span className='text-h1'>Lorem ipsum</span>
							<span className='text-h2'>Lorem ipsum</span>
							<span className='text-h3'>Lorem ipsum</span>
							<span className='text-h4'>Lorem ipsum</span>
							<span className='text-p1'>Lorem ipsum</span>
							<span className='text-p2'>Lorem ipsum</span>
							<span className='text-p3'>Lorem ipsum</span>
						</PropTable>
					</Accordion.Item>

					<Accordion.Item value='Primary, Semibold'>
						<PropTable columnWidths={['300px', 'auto']}>
							<span className='font-semibold text-h1'>Lorem ipsum</span>
							<span className='font-semibold text-h2'>Lorem ipsum</span>
							<span className='font-semibold text-h3'>Lorem ipsum</span>
							<span className='font-semibold text-h4'>Lorem ipsum</span>
							<span className='font-semibold'>Lorem ipsum</span>
							<span className='font-semibold text-p2'>Lorem ipsum</span>
							<span className='font-semibold text-p3'>Lorem ipsum</span>
						</PropTable>
					</Accordion.Item>

					<Accordion.Item value='Primary, Bold'>
						<PropTable columnWidths={['300px', 'auto']}>
							<span className='font-bold text-h1'>Lorem ipsum</span>
							<span className='font-bold text-h2'>Lorem ipsum</span>
							<span className='font-bold text-h3'>Lorem ipsum</span>
							<span className='font-bold text-h4'>Lorem ipsum</span>
							<span className='font-bold'>Lorem ipsum</span>
							<span className='font-bold text-p2'>Lorem ipsum</span>
							<span className='font-bold text-p3'>Lorem ipsum</span>
						</PropTable>
					</Accordion.Item>

					<Accordion.Item value='Secondary, Regular' className='mt-4'>
						<PropTable columnWidths={['425px', 'auto']}>
							<span className='font-secondary text-h1'>Lorem ipsum</span>
							<span className='font-secondary text-h2'>Lorem ipsum</span>
							<span className='font-secondary text-h3'>Lorem ipsum</span>
							<span className='font-secondary text-h4'>Lorem ipsum</span>
							<span className='font-secondary'>Lorem ipsum</span>
							<span className='font-secondary text-p2'>Lorem ipsum</span>
							<span className='font-secondary text-p3'>Lorem ipsum</span>
						</PropTable>
					</Accordion.Item>

					<Accordion.Item value='Secondary, Semibold'>
						<PropTable columnWidths={['425px', 'auto']}>
							<span className='font-secondary font-semibold text-h1'>Lorem ipsum</span>
							<span className='font-secondary font-semibold text-h2'>Lorem ipsum</span>
							<span className='font-secondary font-semibold text-h3'>Lorem ipsum</span>
							<span className='font-secondary font-semibold text-h4'>Lorem ipsum</span>
							<span className='font-secondary font-semibold'>Lorem ipsum</span>
							<span className='font-secondary font-semibold text-p2'>Lorem ipsum</span>
							<span className='font-secondary font-semibold text-p3'>Lorem ipsum</span>
						</PropTable>
					</Accordion.Item>

					<Accordion.Item value='Secondary, Bold'>
						<PropTable columnWidths={['425px', 'auto']}>
							<span className='font-secondary font-bold text-h1'>Lorem ipsum</span>
							<span className='font-secondary font-bold text-h2'>Lorem ipsum</span>
							<span className='font-secondary font-bold text-h3'>Lorem ipsum</span>
							<span className='font-secondary font-bold text-h4'>Lorem ipsum</span>
							<span className='font-secondary font-bold'>Lorem ipsum</span>
							<span className='font-secondary font-bold text-p2'>Lorem ipsum</span>
							<span className='font-secondary font-bold text-p3'>Lorem ipsum</span>
						</PropTable>
					</Accordion.Item>
				</Accordion>

				<p className='mt-4'>
					Because the project&apos;s icons are implemented using an icon font, it&apos;s possible to apply this font directly
					to an element using a Tailwind class just as you would with our primary or secondary fonts. While this should be
					avoided in most cases, it&apos;s useful if you want to use an icon as part of an HTML pseudo-element like{' '}
					<code>::before</code> or <code>::marker</code>. For usage tips on the more common cases where a discrete element
					can be used for your icon, see <a href='#icons'>the Icons section</a>.
				</p>

				<Accordion type='multiple' itemStyle='contained' className='mt-4'>
					<Accordion.Item value='Icon font'>
						<PropTable>
							<span className='before:font-symbols before:content-["explore"]'>Lorem ipsum</span>
							<span className='before:font-symbols before:content-["search"]'>Lorem ipsum</span>
						</PropTable>

						<p className='mt-4'>
							When working with pseudo-elements, remember that flex positioning can be useful for controlling alignment with
							any sibling text nodes.
						</p>

						<PropTable className='mt-4'>
							<span className='before:font-symbols before:content-["search"] flex items-center'>Lorem ipsum</span>
						</PropTable>
					</Accordion.Item>
				</Accordion>
			</section>

			<section className='mt-4'>
				<h2 id='icons' className='text-h3 scroll-mt-4'>
					Icons
				</h2>

				<div className='mt-2'>
					<p>
						Icons for the project are from{' '}
						<a href='https://fonts.google.com/icons'>Google&apos;s Material Symbols icon library</a>. Note that this is{' '}
						<em>not</em> the same as the Material Icons library—the two are very similar, but are implemented differently and
						do not fully overlap in terms of the icons available.
					</p>

					<p className='mt-2'>
						For implementation, we use the{' '}
						<a href='https://developers.google.com/fonts/docs/material_symbols/'>Material Symbols variable icon font</a> in
						combination with a custom TailwindCSS plugin that provides utility classes for controlling an icon&apos;s size and
						fill state. This allows us to follow Tailwind&apos;s utility-first conventions for icons just as we do for most
						other styles in the project.
					</p>
				</div>

				<Accordion type='multiple' itemStyle='contained' className='mt-4'>
					<Accordion.Item value='Choosing an icon'>
						<p>
							The &quot;material-symbol&quot; class applies all the necessary styles for an icon to properly display. Elements
							with this class applied use a typography feature known as ligatures to convert their text content into a matching
							icon from the Material Symbols library.
						</p>

						<MarkupTable className='mt-4'>
							<span className='material-symbol'>home</span>
							<span className='material-symbol'>monitor_heart</span>
							<span className='material-symbol'>key_visualizer</span>
							<span className='material-symbol'>mode_heat</span>
							<span className='material-symbol'>explore</span>
							<span className='material-symbol'>help</span>
						</MarkupTable>
					</Accordion.Item>

					<Accordion.Item value='Controlling icon size'>
						<p>
							Similar to other Tailwind classes, you can set the size of an icon by adding one of the size suffixes defined in
							the project&apos;s Tailwind theme. Without a size suffix, an icon will default to the &quot;medium&quot; size.
						</p>

						<MarkupTable className='mt-4'>
							<span className='material-symbol-sm'>explore</span>
							<span className='material-symbol-md'>explore</span>
							<span className='material-symbol'>explore</span>
							<span className='material-symbol-lg'>explore</span>
							<span className='material-symbol-xl'>explore</span>
						</MarkupTable>
					</Accordion.Item>

					<Accordion.Item value='Controlling icon fill state'>
						<p>
							To display a filled version of the icon, simply add &quot;-fill&quot; to any &quot;material-symbol&quot; utility
							class. Note that not all symbols have a filled variant!
						</p>

						<MarkupTable className='mt-4'>
							<span className='material-symbol'>home</span>
							<span className='material-symbol-fill'>home</span>
							<span className='material-symbol-lg-fill'>home</span>
							<span className='material-symbol'>check_box</span>
							<span className='material-symbol-fill'>check_box</span>
							<span className='material-symbol-lg-fill'>check_box</span>
							<span className='material-symbol'>check_box_outline_blank</span>
							<span className='material-symbol-fill'>check_box_outline_blank</span>
							<span className='material-symbol-lg-fill'>check_box_outline_blank</span>
						</MarkupTable>
					</Accordion.Item>

					<Accordion.Item value='Controlling icon color'>
						<p>
							Because Material Symbols are implemented using an icon font, you can control any icon&apos;s color just by
							applying the appropriate &quot;text-&quot; utility class. See the Colors section above for a comprehensive color
							reference.
						</p>

						<MarkupTable className='mt-4'>
							<span className='material-symbol text-red-500'>monitor_heart</span>
							<span className='material-symbol-fill text-green-600'>monitor_heart</span>
							<span className='material-symbol-lg text-gray-400'>monitor_heart</span>
							<span className='material-symbol-lg-fill text-blue-500'>monitor_heart</span>
						</MarkupTable>
					</Accordion.Item>
				</Accordion>
			</section>

			<section className='mt-4'>
				<h2 id='layout_components' className='text-h3 scroll-mt-4'>
					Layout Components
				</h2>

				<section className='mt-4'>
					<h3 id='panels' className='text-h4 scroll-mt-4'>
						Panels
					</h3>

					<p className='mt-2'>
						Panels are simple layout components that help to organize page content within visually constrained sections. Like
						a standard <code>{'<div>'}</code> element, a panel will always attempt to occupy the full width of its container.
					</p>

					<Accordion type='multiple' itemStyle='contained' className='mt-4'>
						<Accordion.Item value='Component API'>
							<ApiTable
								items={[
									{
										name: 'title',
										type: 'React.ReactNode',
										required: true,
										description: "The title to display in this panel's header.",
									},
									{
										name: 'tooltipContent',
										type: "TooltipProps['content']",
										description: "The tooltip content to attach to an information icon next to this panel's title.",
									},
									{
										name: 'headerContentCenter',
										type: 'React.ReactNode',
										description: "Additional content or controls to display in the center area of this panel's header.",
									},
									{
										name: 'headerContentCenterAlignment',
										type: ['"center"', '"left"'],
										default: '"center"',
										description: (
											<>
												The desired positioning of content in the center area of this panel&apos;s header. When set to{' '}
												<code>center</code>, the content will stay aligned with the center of this panel; when set to{' '}
												<code>left</code>, the content will align itself next to this panel&apos;s title.
											</>
										),
									},
									{
										name: 'headerContentRight',
										type: 'React.ReactNode',
										description: "Additional content or controls to display to the rightmost area of this panel's header.",
									},
								]}
							/>
						</Accordion.Item>

						<Accordion.Item value='Basic Example'>
							<p>
								The only strict requirement for a Panel component is that you provide a title to display in its header.
								Otherwise, a panel can contain any other arbitrary content you wish to place within it.
							</p>

							<PropTable className='mt-4'>
								<Panel title='Basic Panel'>
									Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatum maxime impedit cupiditate velit totam nemo
									laboriosam dolorem accusamus sunt error reiciendis officia, suscipit excepturi neque animi dolore temporibus?
									Nisi, amet.
								</Panel>
							</PropTable>
						</Accordion.Item>

						<Accordion.Item value='Applying Tooltips'>
							<p>
								If your panel contains information that would benefit from additional context, you can provide some content to
								display as a tooltip. Providing tooltip content will cause an information icon to appear next to your
								panel&apos;s title, hovering over which will reveal your tooltip. Your tooltip value can be a simple string, or
								you can provide custom JSX for displaying more structured content.
							</p>

							<PropTable className='mt-4'>
								<Panel title='Panel with Tooltip' tooltipContent='Information about this panel'>
									Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatum maxime impedit cupiditate velit totam nemo
									laboriosam dolorem accusamus sunt error reiciendis officia, suscipit excepturi neque animi dolore temporibus?
									Nisi, amet.
								</Panel>
							</PropTable>
						</Accordion.Item>

						<Accordion.Item value='Adding Header Content'>
							<p>
								A panel&apos;s header can display extra content in addition to the title via the{' '}
								<code>headerContentCenter</code> and <code>headerContentRight</code> props which, as their names suggest, give
								you access to areas in the middle and right-hand sides of the header, respectively. They can be passed plain
								strings or custom JSX, making them ideal for providing panel-related controls or contextual information.
							</p>

							<PropTable className='mt-4'>
								<Panel title='Panel' headerContentCenter='Center content'>
									Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatum maxime impedit cupiditate velit totam nemo
									laboriosam dolorem accusamus sunt error reiciendis officia, suscipit excepturi neque animi dolore temporibus?
									Nisi, amet.
								</Panel>

								<Panel title='Panel' headerContentRight='Right content'>
									Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatum maxime impedit cupiditate velit totam nemo
									laboriosam dolorem accusamus sunt error reiciendis officia, suscipit excepturi neque animi dolore temporibus?
									Nisi, amet.
								</Panel>

								<Panel title='Panel' headerContentCenter='Center content' headerContentRight='Right content'>
									Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatum maxime impedit cupiditate velit totam nemo
									laboriosam dolorem accusamus sunt error reiciendis officia, suscipit excepturi neque animi dolore temporibus?
									Nisi, amet.
								</Panel>
							</PropTable>

							<p className='mt-4'>
								The <code>headerContentCenter</code> area has a related prop named <code>headerContentCenterAlignment</code>,
								which controls how content within the middle area should be displayed relative to the panel. The default
								&quot;center&quot; value keeps any provided content in this area centered on the panel as a whole, while the
								&quot;left&quot; value aligns any provided content directly after the panel&apos;s title.
							</p>

							<PropTable className='mt-4'>
								<Panel
									title='Panel'
									headerContentCenter='Center content'
									headerContentCenterAlignment='left'
									headerContentRight='Right content'
								>
									Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatum maxime impedit cupiditate velit totam nemo
									laboriosam dolorem accusamus sunt error reiciendis officia, suscipit excepturi neque animi dolore temporibus?
									Nisi, amet.
								</Panel>
							</PropTable>
						</Accordion.Item>
					</Accordion>
				</section>

				<section className='mt-4'>
					<h3 id='metrics' className='text-h4 scroll-mt-4'>
						Metric Panels
					</h3>

					<Accordion type='multiple' itemStyle='contained' className='mt-4'>
						<Accordion.Item value='Props'>
							<ApiTable
								items={[
									{
										name: 'label',
										type: 'string',
										required: true,
										description: 'The label for the field/metric.',
									},
									{
										name: 'value',
										type: 'number',
										required: true,
										description: 'The value for the field/metric',
									},
									{
										name: 'metric',
										type: 'string',
										description: "The unit/metric to show next to the value ('%', 'mins', etc.)",
									},
									{
										name: 'chartColor',
										type: "'red' | 'green' | 'blue' | 'light-blue'",
										default: 'light-blue',
										description: 'Optional, the color of the gauge chart line if using a gauge chart. Blue is default value.',
									},
									{
										name: 'effect',
										type: 'string | number',
										description: "Optional. The unit/metric to show next to the value ('%', 'mins', etc.)",
									},
									{
										name: 'effectColor',
										type: "'red' | 'green'",
										description: 'Optional, not providing a value will render a blue line.',
									},
									{
										name: 'effectDirection',
										type: "'up' | 'down'",
										description: 'Optional, the direction of the arrow in the effect. No arrow is rendered if not provided.',
									},
									{
										name: 'type',
										type: "'bar' | 'gauge'",
										required: true,
										description: 'Whether to show a gauge chart with a metric or just the bar-chart icon with a metric.',
									},
								]}
							/>
						</Accordion.Item>
						<Accordion.Item value='Metric Panels'>
							<MarkupTabs>
								<MetricPanel
									left={{
										label: 'Some Metric',
										value: 980,
										type: 'bar',
									}}
									right={{
										label: 'Another Metric',
										value: 1026,
										type: 'bar',
										effect: 'Uncolored effect',
									}}
								/>
							</MarkupTabs>

							<MarkupTabs>
								<MetricPanel
									left={{
										label: 'Some Metric',
										value: 980,
										type: 'bar',
										effect: 'Neutral',
										effectDirection: 'up',
									}}
									right={{
										label: 'Another Metric',
										value: 1026,
										type: 'bar',
										effect: 'Neutral',
										effectDirection: 'up',
									}}
								/>
							</MarkupTabs>

							<MarkupTabs>
								<MetricPanel
									left={{
										label: 'Some Metric',
										value: 980,
										type: 'bar',
										effect: 'Good news',
										effectDirection: 'down',
										effectColor: 'green',
									}}
									right={{
										label: 'Another Metric',
										value: 1026,
										type: 'bar',
										effect: 'Bad news',
										effectDirection: 'down',
										effectColor: 'red',
									}}
								/>
							</MarkupTabs>

							<MarkupTabs>
								<MetricPanel
									left={{
										label: 'Some Metric',
										value: 980,
										metric: 'm',
										type: 'bar',
										effect: 'Good news',
										effectColor: 'green',
									}}
									right={{
										label: 'Another Metric',
										value: 1026,
										metric: 's',
										type: 'bar',
										effect: 'Bad news',
										effectColor: 'red',
									}}
								/>
							</MarkupTabs>

							<MarkupTabs>
								<MetricPanel
									left={{
										label: 'Some Metric',
										value: 65,
										type: 'gauge',
									}}
									right={{
										label: 'Another Metric',
										value: 75,
										type: 'gauge',
										effect: 'Neutral effect',
									}}
								/>
							</MarkupTabs>

							<MarkupTabs>
								<MetricPanel
									left={{
										label: 'Some Metric',
										value: 65,
										type: 'gauge',
										effect: 'Change',
										effectDirection: 'down',
									}}
									right={{
										label: 'Another Metric',
										value: 75,
										type: 'gauge',
										effect: 'Change',
										effectDirection: 'up',
									}}
								/>
							</MarkupTabs>

							<MarkupTabs>
								<MetricPanel
									left={{
										label: 'Some Metric',
										value: 65,
										type: 'gauge',
										effect: 'Change',
										effectDirection: 'down',
										effectColor: 'red',
									}}
									right={{
										label: 'Another Metric',
										value: 75,
										type: 'gauge',
										effect: 'Change',
										effectDirection: 'up',
										effectColor: 'red',
									}}
								/>
							</MarkupTabs>

							<MarkupTabs>
								<MetricPanel
									left={{
										label: 'Some Metric',
										value: 65,
										type: 'gauge',
										chartColor: 'green',
										effect: 'Change',
										effectDirection: 'down',
										effectColor: 'green',
									}}
									right={{
										label: 'Another Metric',
										value: 75,
										type: 'gauge',
										chartColor: 'red',
										effect: 'Change',
										effectDirection: 'up',
										effectColor: 'red',
									}}
								/>
							</MarkupTabs>
						</Accordion.Item>
					</Accordion>
				</section>

				<section className='mt-4'>
					<h3 id='accordions' className='text-h4 scroll-mt-4'>
						Accordions
					</h3>

					<p className='mt-2'>
						Accordions are similar to panels, but can be opened and closed by the user to limit the amount information visible
						at one time. Merlin&apos;s accordions are implemented using the <code>{'<Accordion>'}</code> component and its{' '}
						<code>{'<Accordion.Item>'}</code> subcomponent. This component is a customized implementation of{' '}
						<a href='https://www.radix-ui.com/docs/primitives/components/accordion' target='_blank' rel='noreferrer'>
							the Accordion primitive from Radix
						</a>
						.
					</p>

					<Accordion type='multiple' itemStyle='contained' className='mt-4'>
						<Accordion.Item value='Component API'>
							<p>
								Most of the relevant props for this component can be found in{' '}
								<a href='https://www.radix-ui.com/docs/primitives/components/accordion' target='_blank' rel='noreferrer'>
									the Radix documentation for Accordions
								</a>
								. The props listed here are customizations laid on top of the existing props to accommodate the needs of the
								project.
							</p>

							<p className='mt-2'>
								Note that Merlin&apos;s accordion implementation is slightly simpler than the Radix version it is based on,
								requiring only the <code>{'<Accordion>'}</code> and <code>{'<Accordion.Item>'}</code> components. Any child node
								within an <code>{'<Accordion>'}</code> other than an <code>{'<Accordion.Item>'}</code> will be ignored, and
								attempting to use an <code>{'<Accordion.Item>'}</code> outside of an <code>{'<Accordion>'}</code> component will
								result in an error.
							</p>

							<h3 className='mt-4'>
								<code>Accordion</code>
							</h3>

							<ApiTable
								className='mt-2'
								items={[
									{
										name: 'itemStyle',
										type: ['"bordered"', '"contained"'],
										default: '"bordered"',
										description:
											'Determines the presentation of the items within this accordion. The "bordered" style features simple borders between each item, while the "contained" style sets each item within its own colored container.',
									},
								]}
							/>

							<h3 className='mt-4'>
								<code>Accordion.Item</code>
							</h3>

							<ApiTable
								className='mt-2'
								items={[
									{
										name: 'title',
										type: 'string',
										default: 'props.value',
										description:
											'The title to apply to this accordion item, if it should be different than the "value" prop. This can be useful if you for some reason need two accordion items with the same title, since "value" must be unique.',
									},
									{
										name: 'contentClasses',
										type: 'string',
										description:
											"A replacement class string to apply to this accordion item's content area. Providing your own classes allows you to completely override any built-in styles, such as padding or text sizing.",
									},
								]}
							/>
						</Accordion.Item>
					</Accordion>

					<h3 className='mt-4 text-h5'>Examples</h3>

					<h4 className='mt-4'>Single-open accordion with &quot;bordered&quot; item-style:</h4>

					<Accordion type='single' className='mt-4'>
						<Accordion.Item value='Section 1'>
							<p>
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur ipsam exercitationem asperiores fugiat
								commodi alias dignissimos magnam laudantium veritatis earum repellendus, ut quos at laborum, vero aut, eaque
								reprehenderit aspernatur?
							</p>
						</Accordion.Item>

						<Accordion.Item value='Section 2'>
							<p>
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur ipsam exercitationem asperiores fugiat
								commodi alias dignissimos magnam laudantium veritatis earum repellendus, ut quos at laborum, vero aut, eaque
								reprehenderit aspernatur?
							</p>
						</Accordion.Item>
					</Accordion>

					<h4 className='mt-4'>Multi-open accordion with &quot;contained&quot; item-style:</h4>

					<Accordion type='multiple' itemStyle='contained' className='mt-4'>
						<Accordion.Item value='Section 1'>
							<p>
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur ipsam exercitationem asperiores fugiat
								commodi alias dignissimos magnam laudantium veritatis earum repellendus, ut quos at laborum, vero aut, eaque
								reprehenderit aspernatur?
							</p>
						</Accordion.Item>

						<Accordion.Item value='Section 2'>
							<p>
								Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur ipsam exercitationem asperiores fugiat
								commodi alias dignissimos magnam laudantium veritatis earum repellendus, ut quos at laborum, vero aut, eaque
								reprehenderit aspernatur?
							</p>
						</Accordion.Item>
					</Accordion>
				</section>

				<section className='mt-4'>
					<h3 id='drawers' className='text-h4 scroll-mt-4'>
						Drawers
					</h3>

					<p className='mt-2'>
						Drawers are content panels that slide out from the side of the screen and are typically used to display contextual
						information or complex interface controls related to the current page or view. You can create a drawer using the{' '}
						<code>{'<Drawer>'}</code> component, which is actually a customized implementation of{' '}
						<a href='https://www.radix-ui.com/docs/primitives/components/dialog' target='_blank' rel='noreferrer'>
							the Dialog primitive from Radix
						</a>{' '}
						since drawers and dialogs share many fundamental UX/UI principles.
					</p>

					<Accordion type='multiple' itemStyle='contained' className='mt-4'>
						<Accordion.Item value='Component API'>
							<p>
								In addition to the props documented below, you may find some more helpful options and context in{' '}
								<a href='https://www.radix-ui.com/docs/primitives/components/dialog' target='_blank' rel='noreferrer'>
									the Radix documentation for Dialogs
								</a>
								, from which the <code>{'<Drawer>'}</code> component is abstracted.
							</p>

							<ApiTable
								className='mt-4'
								items={[
									{
										name: 'title',
										type: 'string',
										description: 'The title text to display at the top of this drawer.',
									},
									{
										name: 'trigger',
										type: 'ReactNode',
										description: 'The element to use to trigger the opening of this drawer.',
									},
									{
										name: 'quickActions',
										type: 'DrawerQuickAction[]',
										description:
											'An array of prop objects representing additional quick actions, or icon-style buttons, to include at the top of this drawer alongside the "close" button.',
									},
									{
										name: 'actionButtons',
										type: 'Omit<ButtonProps, "sizeX" | "sizeY" | "squareCorners">[]',
										description:
											'An array of `<Button>` prop objects representing actions to include at the bottom of this drawer.',
									},
									{
										name: 'preventClose',
										type: '() => boolean | Promise<boolean>',
										description: 'A function resolving to whether the drawer should be prevented from closing.',
									},
								]}
							/>

							<div className='mt-4 font-bold'>
								<code>DrawerQuickAction</code>
							</div>

							<ApiTable
								className='mt-4'
								items={[
									{
										name: 'icon',
										type: 'string',
										description: 'The Material Symbol icon to display for this quick action.',
									},

									{
										name: 'fillIcon',
										type: 'boolean',
										default: 'false',
										description: "Whether this quick action's icon should use a filled version, if available.",
									},

									{
										name: 'tooltipText',
										type: 'string',
										description: 'The tooltip text to apply to this quick action.',
									},
								]}
							/>
						</Accordion.Item>

						<Accordion.Item value='General usage'>
							<p>
								This example shows how you might configure a one-off <code>{'<Drawer>'}</code> component, which will initially
								display whatever is passed to the <code>trigger</code> render prop and render/reveal the actual drawer when that
								trigger is activated. More likely, however, you will want to implement your own version of a{' '}
								<code>{'<Drawer>'}</code> by abstracting it as a new component, which will allow you to reuse it and add
								features and effects like additional state management.
							</p>

							<MarkupTable className='mt-4'>
								<Drawer
									metadata={metadata}
									trigger={<Button>Open Drawer</Button>}
									quickActions={[
										{
											icon: 'tips_and_updates',
											tooltipText: 'Perform Quick Action',
											onClick: () => console.log('Drawer: Quick Action'),
										},
									]}
									actionButtons={[
										{
											onClick: () => console.log('Drawer: Main Action'),
											children: 'Main Action',
										},
										{
											onClick: () => console.log('Drawer: Other Action'),
											children: 'Other Action',
										},
									]}
								>
									<div className='p-4'>Any drawer content, such as neatly folded socks.</div>
									<div className='py-6 px-4'>
										<Datepicker type='range' popoverContentProps={{ side: 'left' }} shortcuts>
											<Button sizeX='md' sizeY='lg' variant='primary'>
												Range Picker w/ Shortcuts
											</Button>
										</Datepicker>
									</div>
								</Drawer>
							</MarkupTable>

							<p className='mt-4'>
								This example is very similar to the previous one, but uses the `preventClose` prop to conditionally determine
								whether the drawer should be prevented from closing as usual through the use of the project&apos;s{' '}
								<a href='#alerts'>asynchronous Alert feature</a>. This pattern can be very useful in cases where the user is at
								risk of losing work when the drawer closes.
							</p>

							<MarkupTable className='mt-4'>
								<Drawer
									metadata={metadata}
									trigger={<Button>Open Drawer</Button>}
									preventClose={async () => {
										const alertResponse = await getAlertResponse({
											description: 'Are you sure you want to close the filter drawer?',
											responseOptions: [
												{
													value: 'close_drawer',
													label: 'Yes, Close Drawer',
												},
											],
										});

										return alertResponse !== 'close_drawer';
									}}
									quickActions={[
										{
											icon: 'tips_and_updates',
											tooltipText: 'Perform Quick Action',
											onClick: () => console.log('Drawer: Quick Action'),
										},
									]}
									actionButtons={[
										{
											onClick: () => console.log('Drawer: Main Action'),
											children: 'Main Action',
										},
										{
											onClick: () => console.log('Drawer: Other Action'),
											children: 'Other Action',
										},
									]}
								>
									<div className='p-4'>Any drawer content, such as neatly folded socks.</div>
								</Drawer>
							</MarkupTable>
						</Accordion.Item>
					</Accordion>
				</section>

				<section className='mt-4'>
					<h3 id='dialogs' className='text-h4 scroll-mt-4'>
						Dialogs
					</h3>

					<p className='mt-2'>
						Dialogs are content panels that appear in front of the current view and often contain supplementary controls or
						even entire encapsulated workflows. You can create a dialog using the <code>{'<Dialog>'}</code> component, which
						is a customized implementation of{' '}
						<a href='https://www.radix-ui.com/docs/primitives/components/dialog' target='_blank' rel='noreferrer'>
							the Dialog primitive from Radix
						</a>
						.
					</p>

					<Accordion type='multiple' itemStyle='contained' className='mt-4'>
						<Accordion.Item value='Component API'>
							<p>
								Several relevant props for this component can be found in{' '}
								<a href='https://www.radix-ui.com/docs/primitives/components/dialog' target='_blank' rel='noreferrer'>
									the Radix documentation for Dialogs
								</a>
								, from which our dialog is abstracted.
							</p>

							<ApiTable
								className='mt-4'
								items={[
									{
										name: 'sizeX',
										type: ['"sm"', '"lg"'],
										description: 'The preset width to apply to this dialog.',
									},
									{
										name: 'title',
										type: 'string',
										description: 'The title text to display at the top of this dialog.',
									},
									{
										name: 'subtitle',
										type: 'string',
										description: 'The subtitle text to accompany the title at the top of this dialog.',
									},
									{
										name: 'trigger',
										type: 'ReactNode',
										description: 'The element to use to trigger the opening of this dialog.',
									},
									{
										name: 'actionButtons',
										type: 'DialogActionButtonProps[]',
										description:
											'An array of `<Button>` prop objects representing actions to include at the bottom of this dialog.',
									},
								]}
							/>

							<div className='mt-4'>
								<code className='font-bold'>DialogActionButton</code>

								<p className='mt-2'>
									Equates to <code>{"Omit<ButtonProps, 'sizeX' | 'sizeY' | 'squareCorners'>"}</code>, or all{' '}
									<code>{'<Button>'}</code> component props but those listed.
								</p>
							</div>
						</Accordion.Item>

						<Accordion.Item value='General usage'>
							<p>
								This example shows how you might configure a one-off <code>{'<Dialog>'}</code> component, which will initially
								display whatever is passed to the <code>trigger</code> render prop and render/reveal the actual dialog when that
								trigger is activated. More likely, however, you will want to implement your own version of a{' '}
								<code>{'<Dialog>'}</code> by abstracting it as a new component, which will allow you to reuse it and add
								features and effects like internal state management.
							</p>

							<MarkupTable className='mt-4'>
								<Dialog
									sizeX='sm'
									title='Title'
									subtitle='Optional Subtitle'
									trigger={<Button>Open Dialog</Button>}
									actionButtons={[
										{
											onClick: () => console.log('Dialog: Main Action'),
											children: 'Main Action',
										},
										{
											onClick: () => console.log('Dialog: Other Action'),
											children: 'Other Action',
										},
									]}
								>
									Dialog content!
								</Dialog>
							</MarkupTable>
						</Accordion.Item>

						<Accordion.Item value='Confirming on Close'>
							<p>
								If your dialog contains a form or other kind of user input, you may wish to confirm the user&apos;s intent when
								closing the dialog so they don&apos;t unintentionally lose any in-progress work. This can be done by controlling
								your dialog&apos;s <code>open</code> prop via React state and using an alert to double-check the user&apos;
								desire to close it via the <code>onOpenChange</code> prop.
							</p>

							<p className='mt-2'>
								Note that the example below uses state specific to this page; for the full code context, take a look at this
								page&apos;s React source code.
							</p>

							<MarkupTable className='mt-4'>
								<Dialog
									sizeX='sm'
									title='Title'
									subtitle='Optional Subtitle'
									trigger={<Button>Open Dialog</Button>}
									open={dialogOpen}
									onOpenChange={async (isBeingOpened) => {
										if (isBeingOpened) {
											setDialogOpen(true);
											return;
										}

										const alertResponse = await getAlertResponse({
											description: 'Are you sure?',
											responseOptions: [
												{
													value: 'yes',
													label: 'Yes, close dialog',
												},
											],
										});

										if (alertResponse === 'yes') {
											setDialogOpen(false);
										}
									}}
									actionButtons={[
										{
											onClick: () => {
												setDialogOpen(false);
											},
											children: 'Submit Data',
										},
									]}
								>
									<TextField label='Favorite Food' />
								</Dialog>
							</MarkupTable>
						</Accordion.Item>
					</Accordion>
				</section>
			</section>

			<section className='mt-4'>
				<h2 id='chart_components' className='text-h3 scroll-mt-4'>
					Charts
				</h2>

				<section className='mt-4'>
					<h3 id='gauge' className='text-h4 scroll-mt-4'>
						Gauges
					</h3>
					<Accordion type='multiple' itemStyle='contained' className='mt-4'>
						<Accordion.Item value='Props'>
							<ApiTable
								items={[
									{
										name: 'percentage',
										type: 'number',
										required: true,
										description: 'The percentage amount that the chart should fill to.',
									},
									{
										name: 'progressColor',
										default: 'light-blue',
										type: "'red' | 'green' | 'blue' | 'light-blue'",
										description: 'The color the line needs to be.',
									},
									{
										name: 'textColor',
										type: "'white' | 'black'",
										required: true,
										description: 'The color of the text.',
									},
									{
										name: 'radius',
										type: 'number',
										required: true,
										description: 'The size of the radius of this gauge.',
									},
									{
										name: 'strokeWidth',
										type: 'number',
										required: true,
										description: 'The stroke width to be used when rendering the circular SVG.',
									},
								]}
							/>
						</Accordion.Item>
						<Accordion.Item value='Gauges'>
							<MarkupTabs>
								<div className='bg-blue-900 p-3'>
									<GaugeChart textColor='white' percentage={15} radius={20} strokeWidth={4} progressColor={'blue'} />
								</div>
							</MarkupTabs>
							<MarkupTabs className='mt-2'>
								<GaugeChart textColor='black' percentage={28} radius={35} strokeWidth={4} progressColor={'red'} />
							</MarkupTabs>
							<MarkupTabs className='mt-2'>
								<GaugeChart textColor='black' percentage={76} radius={45} strokeWidth={7} />
							</MarkupTabs>
						</Accordion.Item>
					</Accordion>
				</section>

				<section className='mt-4'>
					<h3 id='heatmaps' className='text-h4 scroll-mt-4'>
						Heatmaps
					</h3>

					<p className='mt-2'>
						The <code>{'<Heatmap />'}</code> component is a custom visualization for applying a gradient color scale to
						hour-of-day-based data.
					</p>

					<Accordion type='multiple' itemStyle='contained' className='mt-4'>
						<Accordion.Item value='Component API'>
							<ApiTable
								items={[
									{
										name: 'axisLabels',
										type: '{ y?: string; x?: string }',
										description: 'The labels to apply to the x- and y-axes of this heatmap.',
									},
									{
										name: 'data',
										type: '{ y: string; x: number[] }[]',
										required: true,
										description:
											'The data to display in this heatmap, where y-values represent row headings and x-values represent arrays of 24 datum to display as values in each hourly column.',
									},
									{
										name: 'datumFormat',
										type: '(datum: number) => string',
										description:
											'A formatting function to apply to each datum. Useful for truncating long values into more readable formats.',
									},
									{
										name: 'emptyMessage',
										type: 'string',
										default: '"This heatmap has no data to display."',
										description: 'A custom message to show in place of this heatmap when it has no data to display.',
									},
									{
										name: 'scaleLowerBound',
										type: "number | 'lowest-value'",
										default: '0',
										description:
											"A custom value at which the minimum intensity of this heatmap's gradient scale should be applied. Defaults to 0. Passing 'lowest-value' will instead use the lowest individual datum value passed to this heatmap.",
									},
									{
										name: 'scaleUpperBound',
										type: 'number',
										description:
											"A custom value at which the maximum intensity of this heatmap's gradient scale should be applied. Defaults to the highest individual datum value passed to this heatmap.",
									},
								]}
							/>
						</Accordion.Item>

						<Accordion.Item value='Basic Example'>
							<MarkupTabs>
								<Heatmap data={generateHeatmapData([...DAYS_OF_WEEK_LIST], 0, 12)} />
							</MarkupTabs>
						</Accordion.Item>

						<Accordion.Item value='Providing Axis Labels'>
							<MarkupTabs>
								<Heatmap
									axisLabels={{ x: 'Hour of Day', y: 'Day of Week' }}
									data={generateHeatmapData([...DAYS_OF_WEEK_LIST], 0, 12)}
								/>
							</MarkupTabs>
						</Accordion.Item>

						<Accordion.Item value='Formatting Datum Values'>
							<p>
								The <code>datumFormat</code> prop can be used to supply a function that will transform the value within each
								heatmap cell. This example formats any value of magnitude 1,000 or more to use a metric thousands abbreviation.
							</p>

							<MarkupTabs className='mt-4'>
								<Heatmap
									datumFormat={(x) => (Math.abs(x) < 1000 ? `${x}` : `${(x / 1000).toFixed(1)}K`)}
									axisLabels={{ x: 'Hour of Day', y: 'Rooms' }}
									data={generateHeatmapData(['OR 01', 'OR 02', 'OR 03', 'OR 04'], 0, 20000)}
								/>
							</MarkupTabs>
						</Accordion.Item>

						<Accordion.Item value='Customizing Gradient Bounds'>
							<p>
								By default, a heatmap&apos;s gradient applies its minimum intensity at 0 and its maximum intensity at the
								highest data value passed to it. It&apos;s possible to control these upper and lower thresholds using the{' '}
								<code>scaleLowerBound</code> and <code>scaleUpperBound</code> props. This is useful in cases where the maximum
								and/or minimum values of your data can fall outside the window you wish to apply the gradient to, or when your
								data can span both negative and positive values.
							</p>

							<p className='mt-2'>
								This example sets a custom lower bound of 5 and a custom upper bound of 15 on a dataset that can include values
								between 0 and 20. This means the minimum gradient intensity will apply up to a value of 5, and a maximum
								gradient intensity will apply at and beyond a value of 15.
							</p>

							<MarkupTabs className='mt-4'>
								<Heatmap
									scaleLowerBound={5}
									scaleUpperBound={15}
									axisLabels={{ x: 'Hour of Day', y: 'Day of Week' }}
									data={generateHeatmapData([...DAYS_OF_WEEK_LIST], 0, 20)}
								/>
							</MarkupTabs>

							<p className='mt-4'>
								By setting the <code>scaleLowerBound</code> prop to <code>{"'lowest-value'"}</code>, the heatmap will
								dynamically apply its gradient&apos;s minimum intensity at the lowest data value passed to it, rather than 0.
								This is particularly useful for datasets with negative values like the example below, which can range from -100
								to 100.
							</p>

							<MarkupTabs className='mt-4'>
								<Heatmap
									scaleLowerBound='lowest-value'
									axisLabels={{ x: 'Hour of Day', y: 'Day of Week' }}
									data={generateHeatmapData([...DAYS_OF_WEEK_LIST], -100, 100)}
								/>
							</MarkupTabs>
						</Accordion.Item>
					</Accordion>
				</section>
			</section>

			<section className='mt-4'>
				<h2 id='buttons' className='text-h3 scroll-mt-4'>
					Buttons
				</h2>

				<p className='mt-2'>
					To add a button, you can use the <code>{'<Button>'}</code> component. This component has several variations, all of
					which can be achieved using different combinations of the props documented in the table below.
				</p>

				<Accordion type='multiple' itemStyle='contained' className='mt-4'>
					<Accordion.Item value='Component API'>
						<ApiTable
							items={[
								{
									name: 'variant',
									type: ['"primary"', '"secondary"', '"tertiary"', '"primary-ghost"', '"secondary-ghost"', '"tertiary-ghost"'],
									default: '"primary"',
									description: 'The preset style to apply to this button.',
								},
								{
									name: 'sizeX',
									type: ['"sm"', '"md"', '"lg"', '"full"', '"square"'],
									default: '"full"',
									description:
										"The preset width to apply to this button. The `square` option will attempt to match this button's width to its height.",
								},
								{
									name: 'sizeY',
									type: ['"sm"', '"lg"'],
									default: '"sm"',
									description: 'The preset height to apply to this button.',
								},
								{
									name: 'isWorking',
									type: 'boolean',
									default: 'false',
									description: 'Whether this button should display an icon indicating its "working" status.',
								},
								{
									name: 'squareCorners',
									type: 'boolean',
									default: 'false',
									description:
										'Whether to square off the corners of this button. Useful when embedding a button in a larger interface.',
								},
							]}
						/>

						<p className='mt-4'>
							Any additional props provided to a <code>{'<Button>'}</code> component are passed directly to the underlying HTML{' '}
							<code>{'<button>'}</code> element. This means that you can set any standard JSX button attribute (like{' '}
							<code>{'disabled'}</code> or <code>{'onClick'}</code>) just as you would if you were working directly with the
							element.
						</p>

						<p className='mt-2'>
							Also note that classes passed to a <code>{'<Button />'}</code> component via the standard{' '}
							<code>{'className'}</code> prop are appended to the component&apos;s built-in classes. While this means you can
							augment the preexisting Tailwind classes with your own, keep in mind that you may see unexpected results if both
							classes are attempting to set the same property.
						</p>
					</Accordion.Item>
				</Accordion>

				<p className='mt-4'>
					The following tables illustrate various combinations of props on a <code>{'<Button>'}</code> component and the
					resulting output. Note that any props that are using the default value have been surrounded with square brackets;
					these can be omitted in your source code, but have been included here for completeness and clarity.
				</p>

				<Accordion type='multiple' itemStyle='contained' className='mt-4'>
					<Accordion.Item value='Primary'>
						<ButtonTable />
					</Accordion.Item>

					<Accordion.Item value='Secondary'>
						<ButtonTable variant='secondary' />
					</Accordion.Item>

					<Accordion.Item value='Tertiary'>
						<ButtonTable variant='tertiary' />
					</Accordion.Item>

					<Accordion.Item value='Primary, Ghost'>
						<ButtonTable variant='primary-ghost' />
					</Accordion.Item>

					<Accordion.Item value='Secondary, Ghost'>
						<ButtonTable variant='secondary-ghost' />
					</Accordion.Item>

					<Accordion.Item value='Tertiary, Ghost'>
						<ButtonTable variant='tertiary-ghost' />
					</Accordion.Item>
				</Accordion>
			</section>

			<section className='mt-4'>
				<h2 id='button_stacks' className='text-h3 scroll-mt-4'>
					Button Stacks
				</h2>

				<p className='mt-4'>
					Button stacks are a method of combining multiple buttons into a more compact form factor. In a button stack, the
					topmost button becomes the only one that is immediately visible, with a small “more” arrow added to its right edge.
					Clicking the “more” arrow will reveal a popover menu containing the remaining buttons in the stack.
				</p>

				<p className='mt-4'>
					Button stacks are intentionally very simple, and don&apos;t offer a complex API of configuration options. Instead,
					you simply nest any desired <code>{'<Button />'}</code> components—complete with any desired props—within the{' '}
					<code>{'<ButtonStack>'}</code> component, and allow it to handle the necessary layout changes.
				</p>

				<Accordion type='multiple' itemStyle='contained' className='mt-4'>
					<Accordion.Item value='General Usage'>
						<p>
							Since the style of a button stack is primarily driven by the buttons it contains, the stacks main/topmost button
							will use its default styling and fill up the available block-level space unless configured otherwise. However,
							for technical reasons related to absolute/fixed positioning, additional buttons aren&apos;t aware of the topmost
							button&apos;s width and thus can&apos;t match it exactly. Instead, additional buttons are defaulted to their
							smallest preset width, which you can easily override.
						</p>

						<MarkupTable className='mt-4'>
							<ButtonStack>
								<Button onClick={() => console.log('A')}>Button A</Button>
								<Button onClick={() => console.log('B')}>Button B</Button>
								<Button onClick={() => console.log('C')}>Button C</Button>
							</ButtonStack>
						</MarkupTable>

						<p className='mt-4'>
							These examples use the existing props on the <code>{'<Button />'}</code> component to override the default sizing
							and keep the entire stack a consistent width. Notice that the dropdown arrow on the topmost button takes some
							space from that button&apos;s footprint to keep its sizing consistent with the buttons lower in the stack.
						</p>

						<MarkupTable className='mt-4'>
							<ButtonStack>
								<Button onClick={() => console.log('A')} sizeX='lg'>
									Button A
								</Button>
								<Button onClick={() => console.log('B')} sizeX='lg'>
									Button B
								</Button>
								<Button onClick={() => console.log('C')} sizeX='lg'>
									Button C
								</Button>
							</ButtonStack>

							<ButtonStack>
								<Button onClick={() => console.log('A')} sizeX='md' sizeY='md'>
									Button A
								</Button>
								<Button onClick={() => console.log('B')} sizeX='md' sizeY='md'>
									Button B
								</Button>
								<Button onClick={() => console.log('C')} sizeX='md' sizeY='md'>
									Button C
								</Button>
							</ButtonStack>
						</MarkupTable>

						<p className='mt-4'>
							This example, while extreme, shows how each button in a stack can be configured as needed using the standard API
							for <code>{'<Button />'}</code> components. Naturally, while this level of inconsistency is possible, it&apos;s
							recommended that all buttons in a stack be kept stylistically uniform for the best UX.
						</p>

						<MarkupTable className='mt-4'>
							<ButtonStack>
								<Button onClick={() => console.log('A')} sizeX='lg' sizeY='lg' variant='primary-ghost'>
									Button A
								</Button>
								<Button onClick={() => console.log('B')} sizeX='lg' sizeY='md' variant='tertiary'>
									Button B
								</Button>
								<Button onClick={() => console.log('C')} sizeX='md' variant='secondary-ghost' isWorking>
									Button C
								</Button>
								<Button onClick={() => console.log('D')} sizeX='lg' variant='primary' disabled>
									Button D
								</Button>
							</ButtonStack>
						</MarkupTable>
					</Accordion.Item>
				</Accordion>
			</section>

			<section className='mt-4'>
				<h2 id='toggle_groups' className='text-h3 scroll-mt-4'>
					Toggle Groups
				</h2>

				<p className='mt-2'>
					A toggle group is a series of connected buttons, only one of which can be selected at any given time. Functionally
					they provide a very similar user experience to a radio group, but in what is often a more compact form factor.
					Toggle groups can be placed using the <code>{'<ToggleGroup />'}</code> component, passing in an{' '}
					<code>options</code> prop to control the individual items to include.
				</p>

				<p className='mt-2'>
					Merlin&apos;s toggle groups are based on{' '}
					<a href='https://www.radix-ui.com/docs/primitives/components/toggle-group' target='_blank' rel='noreferrer'>
						the Toggle Group primitive from Radix
					</a>
					. You can check out their documentation to gain a better understanding of any available props and how they can be
					used in conjunction with our custom implementation.
				</p>

				<Accordion type='multiple' itemStyle='contained' className='mt-4'>
					<Accordion.Item value='Component API'>
						<ApiTable
							items={[
								{
									name: 'label',
									type: ['string'],
									description:
										'The text label to apply to this toggle group. This is required for accessibility purposes, even if the label is hidden using the `hideLabel` option.',
								},
								{
									name: 'hideLabel',
									type: ['boolean'],
									default: 'false',
									description: "Whether to visually hide this toggle group's label from users.",
								},
								{
									name: 'options',
									type: ['ToggleGroupOption[]'],
									description: 'The options to include within this toggle group',
								},
								{
									name: 'allowDeselection',
									type: ['boolean'],
									default: 'false',
									description:
										'Whether to allow this toggle group to have no option selected, resulting in an empty string value.',
								},
								{
									name: 'errorMessage',
									type: 'string',
									description:
										"A description of the field's current validation error. Also visually indicates the field contains an error.",
								},
								{
									name: 'errorMessageArea',
									type: ['"none"', '"reserved"', '"floating"'],
									default: '"none"',
									description:
										'Controls how the space occupied by an error message is handled. See the Error State examples below for details.',
								},
								{
									name: 'instructions',
									type: 'string',
									description:
										'Adds an information indicator next to the field label, which can be hovered over to reveal the provided instructional text.',
								},
								{
									name: 'containerProps',
									type: 'object',
									description: "Props to pass to the label element that acts as the field's outermost container.",
								},
							]}
						/>

						<div className='mt-4 font-bold'>
							<code>ToggleGroupOption</code>
						</div>

						<ApiTable
							className='mt-4'
							items={[
								{
									name: 'label',
									type: ['string'],
									description:
										'The text to display for this option, or the name of the Material Symbol to display if using the labelAsIcon option.',
								},

								{
									name: 'labelAsIcon',
									type: ['boolean'],
									default: 'false',
									description: 'Whether to convert the provided label to a Material Symbol.',
								},

								{
									name: 'value',
									type: ['string'],
									description: 'The return value to use when selecting this option.',
								},

								{
									name: 'disabled',
									type: ['boolean'],
									default: 'false',
									description: 'Whether to prevent users from selecting this option.',
								},
							]}
						/>
					</Accordion.Item>
				</Accordion>

				<p className='mt-4'>
					The following tables demonstrate various ways a <code>{'<ToggleGroup />'}</code> can be configured. Keep in mind
					that while toggle groups share many functional similarities with radio groups, they have fundamentally different
					development interfaces and may not always be simple one-to-one replacements for each other.
				</p>

				<Accordion type='multiple' itemStyle='contained' className='mt-4'>
					<Accordion.Item value='General Usage'>
						<PropTable>
							<ToggleGroup
								label='Toggle group'
								options={[
									{ label: 'First', value: 'first' },
									{ label: 'Second', value: 'second' },
									{ label: 'Third', value: 'third' },
								]}
							/>

							<ToggleGroup
								label='Toggle group'
								className='w-64'
								options={[
									{ label: 'First', value: 'first' },
									{ label: 'Second', value: 'second' },
									{ label: 'Third', value: 'third' },
									{ label: 'Fourth', value: 'fourth' },
								]}
							/>

							<ToggleGroup
								label='Toggle group'
								className='w-28'
								options={[
									{ label: 'On', value: 'on' },
									{ label: 'Off', value: 'off' },
								]}
								defaultValue='on'
							/>

							<ToggleGroup
								label='Toggle group'
								className='w-48'
								options={[
									{ label: 'First', value: 'first' },
									{ label: 'Second', value: 'second' },
									{ label: 'Third', value: 'third' },
								]}
								allowDeselection
							/>
						</PropTable>
					</Accordion.Item>

					<Accordion.Item value='Disabling One or All Options'>
						<PropTable>
							<ToggleGroup
								label='Toggle group'
								className='w-48'
								disabled
								options={[
									{ label: 'First', value: 'first' },
									{ label: 'Second', value: 'second' },
									{ label: 'Third', value: 'third' },
								]}
							/>

							<ToggleGroup
								label='Toggle group'
								className='w-48'
								options={[
									{ label: 'First', value: 'first' },
									{ label: 'Second', value: 'second', disabled: true },
									{ label: 'Third', value: 'third' },
								]}
								allowDeselection
							/>
						</PropTable>
					</Accordion.Item>

					<Accordion.Item value='Using Icons as Labels'>
						<PropTable>
							<ToggleGroup
								label='Toggle group'
								className='w-24'
								options={[
									{ label: 'format_align_left', value: 'left', labelAsIcon: true },
									{ label: 'format_align_center', value: 'center', labelAsIcon: true },
									{ label: 'format_align_right', value: 'right', labelAsIcon: true },
								]}
							/>
						</PropTable>
					</Accordion.Item>

					<Accordion.Item value='Responding to Changes'>
						<PropTable>
							<ToggleGroup
								label='Toggle group'
								className='w-48'
								options={[
									{ label: 'First', value: 'first' },
									{ label: 'Second', value: 'second' },
									{ label: 'Third', value: 'third' },
								]}
								allowDeselection
								onValueChange={(value) => createToast({ title: `Value is now "${value}"` })}
							/>
						</PropTable>
					</Accordion.Item>
				</Accordion>
			</section>

			<section className='mt-4'>
				<h2 id='inputs' className='text-h3 scroll-mt-4'>
					Inputs
				</h2>

				<section className='mt-4'>
					<h3 id='checkboxes_and_radio_buttons' className='text-h4 scroll-mt-4'>
						Checkboxes &amp; Radio Buttons
					</h3>

					<p className='mt-2'>
						Checkbox and radio inputs are implemented using the <code>{'<Checkbox />'}</code> and{' '}
						<code>{'<RadioButton />'}</code> components. These components are ultimately just styled wrappers around default
						HTML <code>input</code> elements of the appropriate type; you can pass all of the standard React props and HTML
						attributes to them that you normally would, as well as decide whether you want to use them as controlled or
						uncontrolled inputs based on your needs.
					</p>

					<Accordion type='multiple' itemStyle='contained' className='mt-4'>
						<Accordion.Item value='Component API'>
							<p>
								The only notable departure from the standard DOM API of these inputs is that each{' '}
								<code>{'<RadioButton />'}</code> component requires a <code>name</code> attribute, which is normally optional.
								The <code>name</code> attribute is used to combine multiple radio inputs into a single radio group, and
								requiring it helps to better enforce the differing use cases of radio buttons and checkboxes. Checkboxes can of
								course also be grouped in this way, but doing so isn&apos;t necessary in every situation.
							</p>

							<p className='mt-2'>
								As a general reminder, keep in mind that React provides the <code>defaultChecked</code> prop as an alternative
								to the <code>checked</code> attribute to prevent re-renders from changing the user&apos;s selection. For more on
								this topic, refer to{' '}
								<a href='https://reactjs.org/docs/uncontrolled-components.html#default-values' target='_blank' rel='noreferrer'>
									the React documentation regarding controlled versus uncontrolled components
								</a>
								.
							</p>
						</Accordion.Item>
					</Accordion>

					<p className='mt-4'>
						The various states displayed below are achieved by applying the <code>disabled</code> attribute and/or{' '}
						<code>defaultChecked</code> prop to the <code>{'<Checkbox />'}</code> and <code>{'<RadioButton />'}</code>{' '}
						components.
					</p>

					<Accordion type='multiple' itemStyle='contained' className='mt-4'>
						<Accordion.Item value='Checkbox States'>
							<Checkbox label='Option A' value='A' />
							<Checkbox label='Option B' value='B' />
							<Checkbox label='Option C (defaultChecked)' value='C' defaultChecked />
							<Checkbox label='Option D (disabled)' value='D' disabled />
							<Checkbox label='Option E (disabled, defaultChecked)' value='E' disabled defaultChecked />
						</Accordion.Item>

						<Accordion.Item value='Radio Button States'>
							<RadioButton label='Radio Group 1, Option A' value='A' name='example_group_1' />
							<RadioButton label='Radio Group 1, Option B' value='B' name='example_group_1' />
							<RadioButton label='Radio Group 1, Option C (disabled)' value='C' name='example_group_1' disabled />
							<RadioButton label='Radio Group 1, Option D (defaultChecked)' value='D' name='example_group_1' defaultChecked />

							<hr className='my-2' />

							<RadioButton label='Radio Group 2, Option A (disabled)' value='A' name='example_group_2' disabled />
							<RadioButton
								label='Radio Group 2, Option B (disabled, defaultChecked)'
								name='example_group_2'
								value='B'
								disabled
								defaultChecked
							/>
							<RadioButton label='Radio Group 2, Option C (disabled)' value='C' name='example_group_2' disabled />
						</Accordion.Item>
					</Accordion>
				</section>

				<section className='mt-4'>
					<h3 id='text_fields' className='text-h4 scroll-mt-4'>
						Text Fields
					</h3>

					<p className='mt-2'>
						Text inputs are implemented using the <code>{'<TextField />'}</code> component. This component consists of an
						outer <code>label</code> element containing an <code>input[type=&quot;text&quot;]</code> element and several
						others used to implement a number of special features.
					</p>

					<Accordion type='multiple' itemStyle='contained' className='mt-4'>
						<Accordion.Item value='Component API'>
							<ApiTable
								items={[
									{
										name: 'label',
										type: 'string',
										description:
											"Sets the field's text label. This is required for accessibility purposes, even if the label is hidden using the 'hideLabel' option.",
									},
									{
										name: 'hideLabel',
										type: 'boolean',
										default: 'false',
										description: "Whether to visually hide the field's label.",
									},
									{
										name: 'sizeX',
										type: ['"sm"', '"lg"', '"full"'],
										default: '"full"',
										description: 'Determines the preset width of the field.',
									},
									{
										name: 'sizeY',
										type: ['"sm"', '"md"', '"lg"'],
										default: '"sm"',
										description: 'Determines the preset height of the field. Also affects text size and padding.',
									},
									{
										name: 'icon',
										type: 'string',
										description: "The name of a Material Symbol to include to the left of the user's input.",
									},
									{
										name: 'iconClass',
										type: 'string',
										description:
											"A string of additional classes to pass to the icon, if one is provided. This allows you to control the icon's color by passing a Tailwind color class.",
									},
									{
										name: 'errorMessage',
										type: 'string',
										description:
											"A description of the field's current validation error. Also visually indicates the field contains an error.",
									},
									{
										name: 'errorMessageArea',
										type: ['"none"', '"reserved"', '"floating"'],
										default: '"none"',
										description:
											'Controls how the space occupied by an error message is handled. See the Error State examples below for details.',
									},
									{
										name: 'isWorking',
										type: 'boolean',
										default: 'false',
										description: 'Whether the field should display a spinning "loading" indicator.',
									},
									{
										name: 'isKnownValid',
										type: 'boolean',
										default: 'false',
										description: 'Whether to visually indicate that the field contains a valid value.',
									},
									{
										name: 'instructions',
										type: 'string',
										description:
											'Adds an information indicator next to the field label, which can be hovered over to reveal the provided instructional text.',
									},
									{
										name: 'containerProps',
										type: 'object',
										description: "Props to pass to the label element that acts as the field's outermost container.",
									},
								]}
							/>

							<p className='mt-4'>
								Any additional props provided to a <code>{'<TextField />'}</code> component are passed directly to the
								underlying HTML <code>{'<input[type="text"]>'}</code> element. This includes not only standard JSX text input
								attributes like <code>{'disabled'}</code>, <code>{'readOnly'}</code>, or <code>{'onUpdate'}</code>, but also any
								ref you choose to attach.
							</p>

							<p className='mt-2'>
								Also note that classes passed to a <code>{'<TextField />'}</code> component via the standard{' '}
								<code>{'className'}</code> prop are appended to the component&apos;s built-in classes. While this means you can
								augment the preexisting Tailwind classes with your own, keep in mind that you may see unexpected results if both
								classes are attempting to set the same property.
							</p>

							<p className='mt-4'>
								Finally, as a general reminder, be aware that React provides the <code>defaultValue</code> prop as an
								alternative to the <code>value</code> attribute to prevent re-renders from changing field values. For more on
								this topic, refer to{' '}
								<a href='https://reactjs.org/docs/uncontrolled-components.html#default-values' target='_blank' rel='noreferrer'>
									the React documentation regarding controlled versus uncontrolled components
								</a>
								.
							</p>
						</Accordion.Item>
					</Accordion>

					<p className='mt-4'>
						The following tables illustrate various combinations of props on a <code>{'<TextField />'}</code> component and
						the resulting output. Note that any props that are using the default value have been surrounded with square
						brackets; these can be omitted in your source code, but have been included here for completeness and clarity.
					</p>

					<Accordion type='multiple' itemStyle='contained' className='mt-4'>
						<Accordion.Item value='General Settings'>
							<p>
								Aside from the built-in props for controlling the label, basic implementations of a{' '}
								<code>{'<TextField />'}</code> component won&apos;t feel far-removed from a standard{' '}
								<code>{'<input[type="text"]>'}</code> element.
							</p>

							<PropTable className='mt-4'>
								<TextField label='Text Field' />
								<TextField label='Text Field' hideLabel={true} />
								<TextField label='Text Field' defaultValue='Pre-filled text' />
								<TextField label='Text Field' placeholder='Placeholder text' />
								<TextField label='Text Field' disabled={true} />
								<TextField label='Text Field' readOnly={true} defaultValue='Read-only text' />
							</PropTable>

							<p className='mt-4'>
								To provide instructional text for your field, you can use the <code>instructions</code> prop. This prop will
								cause an information icon to appear next to the field label, hovering which will reveal your provided
								instructional text.
							</p>

							<PropTable className='mt-4'>
								<TextField label='Text Field' instructions='Use your keyboard.' />
							</PropTable>

							<p className='mt-4'>
								You add an icon to your text field using the <code>icon</code> prop, passing in the name of an icon from the{' '}
								<a href='https://developers.google.com/fonts/docs/material_symbols/'>Material Symbols library</a>. The icon will
								scale in size to match the height setting of the text field. You can also use the <code>iconClass</code> prop to
								apply additional classes to your icon, such as a Tailwind color class to control the icon&apos;s color.
							</p>

							<PropTable className='mt-4'>
								<TextField label='Search' icon='search' placeholder='Your search query' />
								<TextField label='Search' icon='search' iconClass='text-blue-500' placeholder='Your search query' />
								<TextField label='Search' icon='search' sizeY='md' placeholder='Your search query' />
								<TextField label='Search' icon='search' sizeY='lg' placeholder='Your search query' />
							</PropTable>
						</Accordion.Item>

						<Accordion.Item value='Error, Working, and Success States'>
							<p>
								When a <code>{'<TextField />'}</code> component receives an <code>errorMessage</code> value, it will apply some
								error styles to the input and display the provided message to the user.
							</p>

							<PropTable className='mt-4'>
								<TextField label='Text Field' errorMessage='Error text.' />
							</PropTable>

							<p className='mt-4'>
								To help mitigate the common problem of error text &quot;popping in&quot; and causing a layout shift in page
								content, you can use the <code>errorMessageArea</code> prop.
							</p>

							<p className='mt-4'>
								The <code>reserved</code> setting ensures that the space that would otherwise be occupied by a one-line error
								message is always present, as opposed to disappearing when there is no error message content. This gives the
								error message somewhere to go when and if it does appear, preventing any subsequent content from shifting
								downward to accommodate it.
							</p>

							<p className='mt-4'>
								The <code>floating</code> setting takes the error text out of the flow of the document and positions it relative
								to the input itself. This can be useful if you&apos;re not able to explicitly reserve the whitespace below a
								field, but also don&apos;t expect the error message to clash with the subsequent page element.
							</p>

							<PropTable className='mt-4'>
								<TextField label='Text Field' errorMessageArea='reserved' />
								<TextField label='Text Field' errorMessageArea='reserved' errorMessage='Error text.' />
								<TextField label='Text Field' errorMessageArea='floating' />
								<TextField label='Text Field' errorMessageArea='floating' errorMessage='Error text.' />
							</PropTable>

							<p className='mt-4'>
								To indicate that the contents of a field are being retrieved or otherwise part of an ongoing process, you can
								use the <code>isWorking</code> prop to display a &quot;working&quot; indicator. The indicator&apos; size and
								position will adjust to best accommodate the height of the text field.
							</p>

							<p className='mt-4'>
								Note that <code>isWorking</code> doesn&apos;t make any other assumptions about how to treat the input—you may
								want to combine it with the <code>disabled</code>, <code>readOnly</code>, or <code>placeholder</code> attributes
								to achieve the right user experience.
							</p>

							<PropTable className='mt-4'>
								<TextField label='Text Field' isWorking />
								<TextField label='Text Field' sizeY='lg' isWorking />
								<TextField label='Text Field' isWorking disabled placeholder='Loading...' />
							</PropTable>

							<p className='mt-4'>
								Adding the <code>isKnownValid</code> prop will apply some success styles to the the field to signal to the user
								that the current value fulfills a particular set of requirements.
							</p>

							<PropTable className='mt-4'>
								<TextField label='Text Field' isKnownValid />
							</PropTable>
						</Accordion.Item>

						<Accordion.Item value='Sizing'>
							<p>
								The <code>{'<TextField />'}</code> component has several size presets available which you can select from using
								the <code>sizeX</code> and <code>sizeY</code> props. By default, a field&apos;s width will expand to match its
								container size (i.e. 100%), as this is generally the most ergonomic way to work with interfaces utilizing text
								inputs.
							</p>

							<PropTable className='mt-4' defaults={{ sizeX: 'full', sizeY: 'sm' }}>
								<TextField label='Text Field' sizeX='full' sizeY='sm' />
								<TextField label='Text Field' sizeX='sm' sizeY='sm' />
								<TextField label='Text Field' sizeX='lg' sizeY='sm' />
								<TextField label='Text Field' sizeX='full' sizeY='md' />
								<TextField label='Text Field' sizeX='sm' sizeY='md' />
								<TextField label='Text Field' sizeX='lg' sizeY='md' />
								<TextField label='Text Field' sizeX='full' sizeY='lg' />
								<TextField label='Text Field' sizeX='sm' sizeY='lg' />
								<TextField label='Text Field' sizeX='lg' sizeY='lg' />
							</PropTable>
						</Accordion.Item>
					</Accordion>
				</section>

				<section className='mt-4'>
					<h3 id='text_areas' className='text-h4 scroll-mt-4'>
						Text Areas
					</h3>

					<p className='mt-2'>
						Text areas are implemented using the <code>{'<TextArea />'}</code> component. This component consists of an outer{' '}
						<code>label</code> element containing an <code>{'<textarea>'}</code> element and several others used to implement
						a number of special features.
					</p>

					<Accordion type='multiple' itemStyle='contained' className='mt-4'>
						<Accordion.Item value='Component API'>
							<ApiTable
								items={[
									{
										name: 'label',
										type: 'string',
										description:
											"Sets the field's text label. This is required for accessibility purposes, even if the label is hidden using the 'hideLabel' option.",
									},
									{
										name: 'hideLabel',
										type: 'boolean',
										default: 'false',
										description: "Whether to visually hide the field's label.",
									},
									{
										name: 'allowResizing',
										type: 'boolean',
										default: 'false',
										description: 'Whether to allow the user to manually adjust the size of the textarea.',
									},
									{
										name: 'errorMessage',
										type: 'string',
										description:
											"A description of the field's current validation error. Also visually indicates the field contains an error.",
									},
									{
										name: 'errorMessageArea',
										type: ['"none"', '"reserved"', '"floating"'],
										default: '"none"',
										description:
											'Controls how the space occupied by an error message is handled. See the Error State examples below for details.',
									},
									{
										name: 'isWorking',
										type: 'boolean',
										default: 'false',
										description: 'Whether the field should display a spinning "loading" indicator.',
									},
									{
										name: 'isKnownValid',
										type: 'boolean',
										default: 'false',
										description: 'Whether to visually indicate that the field contains a valid value.',
									},
									{
										name: 'instructions',
										type: 'string',
										description:
											'Adds an information indicator next to the field label, which can be hovered over to reveal the provided instructional text.',
									},
									{
										name: 'containerProps',
										type: 'object',
										description: "Props to pass to the label element that acts as the field's outermost container.",
									},
								]}
							/>

							<p className='mt-4'>
								Any additional props provided to a <code>{'<TextArea />'}</code> component are passed directly to the underlying
								HTML <code>{'<textarea>'}</code> element. This includes not only standard JSX text textarea attributes like{' '}
								<code>{'disabled'}</code>, <code>{'readOnly'}</code>, or <code>{'onUpdate'}</code>, but also any ref you choose
								to attach.
							</p>

							<p className='mt-2'>
								Also note that classes passed to a <code>{'<TextArea />'}</code> component via the standard{' '}
								<code>{'className'}</code> prop are appended to the component&apos;s built-in classes. While this means you can
								augment the preexisting Tailwind classes with your own, keep in mind that you may see unexpected results if both
								classes are attempting to set the same property.
							</p>

							<p className='mt-4'>
								Finally, as a general reminder, be aware that React provides the <code>defaultValue</code> prop as an
								alternative to the <code>value</code> attribute to prevent re-renders from changing field values. For more on
								this topic, refer to{' '}
								<a href='https://reactjs.org/docs/uncontrolled-components.html#default-values' target='_blank' rel='noreferrer'>
									the React documentation regarding controlled versus uncontrolled components
								</a>
								.
							</p>
						</Accordion.Item>
					</Accordion>

					<p className='mt-4'>
						The following tables illustrate various combinations of props on a <code>{'<TextArea />'}</code> component and the
						resulting output. Note that any props that are using the default value have been surrounded with square brackets;
						these can be omitted in your source code, but have been included here for completeness and clarity.
					</p>

					<Accordion type='multiple' itemStyle='contained' className='mt-4'>
						<Accordion.Item value='General Settings'>
							<p>
								Aside from the built-in props for controlling the label, basic implementations of a{' '}
								<code>{'<TextArea />'}</code> component won&apos;t feel far-removed from a standard <code>{'<textarea>'}</code>{' '}
								element.
							</p>

							<PropTable className='mt-4'>
								<TextArea label='Text Area' />
								<TextArea label='Text Area' hideLabel={true} />
								<TextArea label='Text Area' defaultValue='Pre-filled text' />
								<TextArea label='Text Area' placeholder='Placeholder text' />
								<TextArea label='Text Area' disabled={true} />
								<TextArea label='Text Area' readOnly={true} defaultValue='Read-only text' />
							</PropTable>

							<p className='mt-4'>
								To provide instructional text for your field, you can use the <code>instructions</code> prop. This prop will
								cause an information icon to appear next to the field label, hovering which will reveal your provided
								instructional text.
							</p>

							<PropTable className='mt-4'>
								<TextArea label='Text Area' instructions='Use your keyboard.' />
							</PropTable>
						</Accordion.Item>

						<Accordion.Item value='Error, Working, and Success States'>
							<p>
								When a <code>{'<TextArea />'}</code> component receives an <code>errorMessage</code> value, it will apply some
								error styles to the input and display the provided message to the user.
							</p>

							<PropTable className='mt-4'>
								<TextArea label='Text Area' errorMessage='Error text.' />
							</PropTable>

							<p className='mt-4'>
								To help mitigate the common problem of error text &quot;popping in&quot; and causing a layout shift in page
								content, you can use the <code>errorMessageArea</code> prop.
							</p>

							<p className='mt-4'>
								The <code>reserved</code> setting ensures that the space that would otherwise be occupied by a one-line error
								message is always present, as opposed to disappearing when there is no error message content. This gives the
								error message somewhere to go when and if it does appear, preventing any subsequent content from shifting
								downward to accommodate it.
							</p>

							<p className='mt-4'>
								The <code>floating</code> setting takes the error text out of the flow of the document and positions it relative
								to the input itself. This can be useful if you&apos;re not able to explicitly reserve the whitespace below a
								field, but also don&apos;t expect the error message to clash with the subsequent page element.
							</p>

							<PropTable className='mt-4'>
								<TextArea label='Text Area' errorMessageArea='reserved' />
								<TextArea label='Text Area' errorMessageArea='reserved' errorMessage='Error text.' />
								<TextArea label='Text Area' errorMessageArea='floating' />
								<TextArea label='Text Area' errorMessageArea='floating' errorMessage='Error text.' />
							</PropTable>

							<p className='mt-4'>
								To indicate that the contents of a field are being retrieved or otherwise part of an ongoing process, you can
								use the <code>isWorking</code> prop to display a &quot;working&quot; indicator.
							</p>

							<p className='mt-4'>
								Note that <code>isWorking</code> doesn&apos;t make any other assumptions about how to treat the input—you may
								want to combine it with the <code>disabled</code>, <code>readOnly</code>, or <code>placeholder</code> attributes
								to achieve the right user experience.
							</p>

							<PropTable className='mt-4'>
								<TextArea label='Text Area' isWorking />
								<TextArea label='Text Area' isWorking disabled placeholder='Loading...' />
							</PropTable>

							<p className='mt-4'>
								Adding the <code>isKnownValid</code> prop will apply some success styles to the the field to signal to the user
								that the current value fulfills a particular set of requirements.
							</p>

							<PropTable className='mt-4'>
								<TextArea label='Text Area' isKnownValid />
							</PropTable>
						</Accordion.Item>
					</Accordion>
				</section>

				<section className='mt-4'>
					<h3 id='number_fields' className='text-h4 scroll-mt-4'>
						Number Fields
					</h3>

					<p className='mt-2'>
						Number inputs are implemented using the <code>{'<NumberField />'}</code> component. This component consists of an
						outer <code>label</code> element containing an <code>input[type=&quot;number&quot;]</code> element and several
						others used to implement a number of special features.
					</p>

					<Accordion type='multiple' itemStyle='contained' className='mt-4'>
						<Accordion.Item value='Component API'>
							<ApiTable
								items={[
									{
										name: 'label',
										type: 'string',
										description:
											"Sets the field's text label. This is required for accessibility purposes, even if the label is hidden using the 'hideLabel' option.",
									},
									{
										name: 'hideLabel',
										type: 'boolean',
										default: 'false',
										description: "Whether to visually hide the field's label.",
									},
									{
										name: 'sizeX',
										type: ['"sm"', '"lg"', '"full"'],
										default: '"full"',
										description: 'Determines the preset width of the field.',
									},
									{
										name: 'sizeY',
										type: ['"sm"', '"md"', '"lg"'],
										default: '"sm"',
										description: 'Determines the preset height of the field. Also affects text size and padding.',
									},
									{
										name: 'icon',
										type: 'string',
										description: "The name of a Material Symbol to include to the left of the user's input.",
									},
									{
										name: 'iconClass',
										type: 'string',
										description:
											"A string of additional classes to pass to the icon, if one is provided. This allows you to control the icon's color by passing a Tailwind color class.",
									},
									{
										name: 'errorMessage',
										type: 'string',
										description:
											"A description of the field's current validation error. Also visually indicates the field contains an error.",
									},
									{
										name: 'errorMessageArea',
										type: ['"none"', '"reserved"', '"floating"'],
										default: '"none"',
										description:
											'Controls how the space occupied by an error message is handled. See the Error State examples below for details.',
									},
									{
										name: 'isWorking',
										type: 'boolean',
										default: 'false',
										description: 'Whether the field should display a spinning "loading" indicator.',
									},
									{
										name: 'isKnownValid',
										type: 'boolean',
										default: 'false',
										description: 'Whether to visually indicate that the field contains a valid value.',
									},
									{
										name: 'instructions',
										type: 'string',
										description:
											'Adds an information indicator next to the field label, which can be hovered over to reveal the provided instructional text.',
									},
									{
										name: 'showRangeInput',
										type: 'boolean',
										default: 'true',
										description:
											'Whether to display a companion range input beneath this number field. Also requires that the `min` and `max` attributes be provided.',
									},
									{
										name: 'containerProps',
										type: 'object',
										description: "Props to pass to the label element that acts as the field's outermost container.",
									},
								]}
							/>

							<p className='mt-4'>
								Any additional props provided to a <code>{'<NumberField />'}</code> component are passed directly to the
								underlying HTML <code>{'<input[type="number"]>'}</code> element. This includes not only standard JSX text input
								attributes like <code>{'disabled'}</code>, <code>{'readOnly'}</code>, or <code>{'onUpdate'}</code>, but also any
								ref you choose to attach.
							</p>

							<p className='mt-2'>
								Also note that classes passed to a <code>{'<NumberField />'}</code> component via the standard{' '}
								<code>{'className'}</code> prop are appended to the component&apos;s built-in classes. While this means you can
								augment the preexisting Tailwind classes with your own, keep in mind that you may see unexpected results if both
								classes are attempting to set the same property.
							</p>

							<p className='mt-4'>
								Finally, as a general reminder, be aware that React provides the <code>defaultValue</code> prop as an
								alternative to the <code>value</code> attribute to prevent re-renders from changing field values. For more on
								this topic, refer to{' '}
								<a href='https://reactjs.org/docs/uncontrolled-components.html#default-values' target='_blank' rel='noreferrer'>
									the React documentation regarding controlled versus uncontrolled components
								</a>
								.
							</p>
						</Accordion.Item>
					</Accordion>

					<p className='mt-4'>
						The following tables illustrate various combinations of the props unique to the <code>{'<NumberField />'}</code>{' '}
						component. For more exhaustive examples featuring props common to several types of input fields, refer to{' '}
						<a href='#text_fields'>
							the documentation for the <code>{'<TextField />'}</code> component
						</a>
						.
					</p>

					<Accordion type='multiple' itemStyle='contained' className='mt-4'>
						<Accordion.Item value='General Settings'>
							<p>
								Aside from the built-in props for controlling the label and optional companion range input, basic
								implementations of a <code>{'<NumberField />'}</code> component won&apos;t feel far-removed from a standard{' '}
								<code>{'<input[type="number"]>'}</code> element. The range input is based on{' '}
								<a href='https://www.radix-ui.com/docs/primitives/components/slider' target='_blank' rel='noreferrer'>
									the Slider primitive from Radix
								</a>
								, and will display if you provide the <code>min</code> and <code>max</code> props to establish its upper and
								lower bounds. It can be explicitly disabled using the <code>showRangeInput</code> prop.
							</p>

							<PropTable className='mt-4'>
								<NumberField label='Number Field' />
								<NumberField label='Number Field' min={0} max={60} />
								<NumberField label='Number Field' min={0} max={60} showRangeInput={false} />
								<NumberField label='Number Field' min={-70} max={50} step={5} />
								<NumberField label='Number Field' min={-20} max={100} step={5} defaultValue={80} />
								<NumberField label='Number Field' min={-20} max={100} step={5} defaultValue={80} disabled />
							</PropTable>

							<PropTable className='mt-4'>
								{numberValue}
								<NumberField
									showRangeInput={true}
									min={0}
									max={100}
									step={5}
									label='Keep track of number in local state'
									defaultValue={numberValue}
									onChange={(e) => setNumberValue(parseInt(e.target.value))}
								/>
							</PropTable>
						</Accordion.Item>
					</Accordion>
				</section>

				<section className='mt-4'>
					<h2 id='select_fields' className='text-h3 scroll-mt-4'>
						Select Fields
					</h2>

					<p className='mt-2'>
						Select fields are implemented using the <code>{'<Select>'}</code> and <code>{'<MultiSelect>'}</code> components.
						These component are highly customized abstractions of{' '}
						<a href='https://github.com/JedWatson/react-select#readme' target='_blank' rel='noreferrer'>
							the React-Select library
						</a>
						.
					</p>

					<Accordion type='multiple' itemStyle='contained' className='mt-4'>
						<Accordion.Item value='Selecting Single Values'>
							<h3 className='text-h4'>States</h3>

							<PropTable className='mt-4'>
								<Select
									label='Select field'
									options={[...Array(2)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<Select
									label='Select field (Working)'
									isWorking
									options={[...Array(2)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<Select
									label='Select field (Disabled)'
									disabled
									options={[...Array(2)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<Select
									label='Select field (Known valid)'
									isKnownValid
									options={[...Array(2)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<Select
									label='Select field (With instructions)'
									instructions='Select something!'
									options={[...Array(2)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>
							</PropTable>

							<h3 className='mt-4 text-h4'>Custom data structures</h3>

							<PropTable className='mt-4'>
								<Select
									label='Select field'
									options={[...Array(2)].map((item, i) => ({ foo: `option_${i + 1}`, bar: `Option ${i + 1}` }))}
									getOptionLabel={(opt) => opt.foo}
									getOptionValue={(opt) => opt.bar}
								/>
							</PropTable>

							<h3 className='mt-4 text-h4'>Sizing</h3>

							<PropTable className='mt-4' defaults={{ sizeX: 'full', sizeY: 'sm' }}>
								<Select
									label='Select field'
									sizeX='sm'
									sizeY='sm'
									options={[...Array(2)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<Select
									label='Select field'
									sizeX='lg'
									sizeY='sm'
									options={[...Array(2)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<Select
									label='Select field'
									sizeY='sm'
									sizeX='full'
									options={[...Array(2)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<Select
									label='Select field'
									sizeX='sm'
									sizeY='md'
									options={[...Array(2)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<Select
									label='Select field'
									sizeX='lg'
									sizeY='md'
									options={[...Array(2)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<Select
									label='Select field'
									sizeY='md'
									sizeX='full'
									options={[...Array(2)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<Select
									label='Select field'
									sizeX='sm'
									sizeY='lg'
									options={[...Array(2)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<Select
									label='Select field'
									sizeX='lg'
									sizeY='lg'
									options={[...Array(2)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<Select
									label='Select field'
									sizeY='lg'
									sizeX='full'
									options={[...Array(2)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<Select
									label='Select field'
									defaultValue={{ value: 'option_2', label: 'Option Two' }}
									sizeY='lg'
									sizeX='full'
									options={[...Array(2)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>
							</PropTable>
						</Accordion.Item>

						<Accordion.Item value='Selecting Multiple Values'>
							<h3 className='text-h4'>States</h3>

							<PropTable className='mt-4'>
								<MultiSelect
									label='Select field'
									options={[...Array(3)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<MultiSelect
									label='Select field (Working)'
									isWorking
									options={[...Array(3)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<MultiSelect
									label='Select field (Disabled)'
									disabled
									options={[...Array(3)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<MultiSelect
									label='Select field (Known valid)'
									isKnownValid
									options={[...Array(3)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<MultiSelect
									label='Select field (With instructions)'
									instructions='Select something!'
									options={[...Array(3)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>
							</PropTable>

							<h3 className='mt-4 text-h4'>Custom data structures</h3>

							<PropTable className='mt-4'>
								<MultiSelect
									label='Select field'
									options={[...Array(3)].map((item, i) => ({ foo: `option_${i + 1}`, bar: `Option ${i + 1}` }))}
									getOptionLabel={(opt) => opt.foo}
									getOptionValue={(opt) => opt.bar}
								/>
							</PropTable>

							<h3 className='mt-4 text-h4'>Sizing</h3>

							<PropTable className='mt-4' defaults={{ sizeX: 'full', sizeY: 'sm' }}>
								<MultiSelect
									label='Select field'
									sizeX='sm'
									sizeY='sm'
									options={[...Array(3)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<MultiSelect
									label='Select field'
									sizeX='lg'
									sizeY='sm'
									options={[...Array(3)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<MultiSelect
									label='Select field'
									sizeY='sm'
									sizeX='full'
									options={[...Array(3)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<MultiSelect
									label='Select field'
									sizeX='sm'
									sizeY='md'
									options={[...Array(3)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<MultiSelect
									label='Select field'
									sizeX='lg'
									sizeY='md'
									options={[...Array(3)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<MultiSelect
									label='Select field'
									sizeY='md'
									sizeX='full'
									options={[...Array(3)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<MultiSelect
									label='Select field'
									sizeX='sm'
									sizeY='lg'
									options={[...Array(3)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<MultiSelect
									label='Select field'
									sizeX='lg'
									sizeY='lg'
									options={[...Array(3)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>

								<MultiSelect
									label='Select field'
									sizeY='lg'
									sizeX='full'
									options={[...Array(3)].map((item, i) => ({ value: `option_${i + 1}`, label: `Option ${i + 1}` }))}
								/>
							</PropTable>
						</Accordion.Item>
					</Accordion>
				</section>

				<section className='mt-4'>
					<h2 id='datepickers' className='text-h3 scroll-mt-4'>
						Datepickers
					</h2>

					<p className='mt-2'>
						Date selections can be handled with the <code>{'<Datepicker>'}</code> and <code>{'<Timepicker>'}</code> component.
						The component is built as a wrapper on top of the{' '}
						<a href='https://www.npmjs.com/package/react-datepicker' target='_blank' rel='noreferrer'>
							React Datepicker
						</a>{' '}
						open source library.
					</p>

					<Accordion type='multiple' itemStyle='contained' className='mt-4'>
						<Accordion.Item value='Timepicker Component API'>
							<ApiTable
								className='mt-2'
								items={[
									{
										name: 'selected',
										type: ['Date'],
										default: 'new Date()',
										description: 'The current selected time for the timepicker to reflect.',
									},
									{
										name: 'interval',
										type: ['5 | 15 | 30 | 60'],
										default: '60',
										description: 'The interval between each selection in the dropdown',
									},
									{
										name: 'minHour',
										type: ['number'],
										default: '0',
										description: 'The minimum hour that can be selected in the time dropdown',
									},
									{
										name: 'maxHour',
										type: ['number'],
										default: '23',
										description: 'The maximum hour that can be selected in the time dropdown',
									},

									{
										name: 'onChange',
										type: ['(time: { standard: string; military: string }) => void'],
										description: 'The handler that is called when a time is chosen and valid (within the min/max)',
									},
								]}
							/>
						</Accordion.Item>
						<Accordion.Item value='Datepicker Component API'>
							<ApiTable
								className='mt-2'
								items={[
									{
										name: 'children',
										type: ['JSX.Element'],
										description: 'The content that makes the trigger for the datepicker popover.',
									},
									{
										name: 'hideTitle',
										type: ['boolean'],
										default: 'false',
										description: 'The option to hide the title altogether.',
									},
									{
										name: 'popoverContentProps',
										type: ['Partial<Popover.PopoverContentProps>'],
										default: '{side: "bottom", sideOffset: 5, align: "start"}',
										description: 'The properties to be passed to the Popover.Content component inside this component.',
									},
									{
										name: 'selected',
										type: ['Date | null'],
										default: 'null',
										description: 'The pre-selected date/dates for the pickers to have.',
									},
									{
										name: 'selectedRange',
										type: ['[Date | null, Date | null]'],
										default: '[null, null]',
										description: 'The pre-selected date/dates for the range-pickers to have.',
									},
									{
										name: 'shortcuts',
										type: ['boolean'],
										default: 'false',
										description: 'The option to include the shortcuts in a sidebar for the rangepicker (Last 7 Days, etc.)',
									},
									{
										name: 'showSelectedDate',
										type: ['boolean'],
										default: 'false',
										description:
											'The option to show the currently selected date below the title (can be shown even if title is hidden).',
									},
									{
										name: 'title',
										type: ['string'],
										default: 'null',
										description:
											'A unique title to apply to the header of the datepicker component, otherwise defaults to generics like "Date Selector", "Date Range Selector", etc.',
									},
									{
										name: 'type (required)',
										type: ["'date' | 'month' | 'quarter' | 'range' | 'week' | 'year' | 'time'"],
										default: 'none',
										description: 'The type of the datepicker, the only required property',
									},
									{
										name: 'onApply',
										type: ['(d: Date) => void;'],
										description: 'The handler function to invoke when clicking "apply" and using a date-picker',
									},
									{
										name: 'onApplyRange',
										type: ['(d: Date, d2: Date) => void;'],
										description: 'The handler function to invoke when clicking "apply" and using any range-picker',
									},
									{
										name: 'onChooseDate',
										type: ['(d: Date) => void;'],
										description: 'The handler function to invoke when selecting a new single date.',
									},
									{
										name: 'onChooseMonth',
										type: ['(monthYear: { m: number; y: number }) => void;'],
										description: 'The handler function to invoke when selecting a month in the month picker.',
									},
									{
										name: 'onChooseQuarter',
										type: ['(quarterYear: { q: number; y: number }) => void;'],
										description: 'The handler function to invoke when selecting a quarter in the quarter picker.',
									},
									{
										name: 'onChooseRange',
										type: ['(start: Date, end: Date) => void;'],
										description: 'The handler function to invoke when selecting a range of dates in the range picker.',
									},
								]}
							/>
						</Accordion.Item>
						<Accordion.Item value='Examples'>
							<h3 className='text-h4'>Simplest invocation</h3>

							<PropTable className='mt-4'>
								<Datepicker type='date'>
									<Button sizeX='md' sizeY='lg' variant='primary'>
										Select a Date
									</Button>
								</Datepicker>
							</PropTable>

							<h3 className='mt-4 text-h4'>Choosing other datepicker types</h3>

							<PropTable className='mt-4'>
								<Datepicker type='range'>
									<Button sizeX='md' sizeY='lg' variant='primary'>
										Range Picker
									</Button>
								</Datepicker>
								<Datepicker type='range' shortcuts>
									<Button sizeX='md' sizeY='lg' variant='primary'>
										Range Picker w/ Shortcuts
									</Button>
								</Datepicker>
								<Datepicker type='week'>
									<Button sizeX='md' sizeY='lg' variant='primary'>
										Select any week
									</Button>
								</Datepicker>
								<Datepicker type='month'>
									<Button sizeX='md' sizeY='lg' variant='primary'>
										Select a Month
									</Button>
								</Datepicker>
								<Datepicker type='quarter'>
									<Button sizeX='md' sizeY='lg' variant='primary'>
										Select a Quarter
									</Button>
								</Datepicker>
							</PropTable>

							<h3 className='mt-4 text-h4'>Selecting times</h3>

							<PropTable className='mt-4'>
								<Timepicker
									interval={5}
									onChange={(time) => {
										console.log(time);
									}}
								/>
								<Timepicker
									interval={15}
									minHour={7}
									maxHour={19}
									onChange={(time) => {
										console.log(time);
									}}
								/>
								<Timepicker
									interval={30}
									minHour={8}
									maxHour={20}
									onChange={(time) => {
										console.log(time);
									}}
								/>
								<Timepicker
									interval={60}
									minHour={9}
									onChange={(time) => {
										console.log(time);
									}}
								/>
							</PropTable>

							<h3 className='mt-4 text-h4'>Keeping track of selected dates</h3>

							<PropTable className='mt-4'>
								<Datepicker
									type='range'
									selectedRange={range}
									onApplyRange={(start, end) => {
										setRange([start, end]);
										console.log(start, end);
									}}
								>
									<Button sizeX='md' sizeY='lg' variant='primary'>
										Range Picker
									</Button>
								</Datepicker>
								<Datepicker
									type='date'
									selected={datepickerDate}
									onApply={(selected) => {
										setDatepickerDate(selected);
										console.log(selected);
									}}
								>
									<Button sizeX='md' sizeY='lg' variant='primary'>
										Date Picker
									</Button>
								</Datepicker>
							</PropTable>
						</Accordion.Item>

						<Accordion.Item value='Wrapper Datepicker Components'>
							<div className='my-8'>
								<p className='my-2'>You can start with a preset date, just by passing the same props into the picker.</p>
								<MarkupTabs className='mt-2'>
									<DateToggle selected={buttonDate} onApply={(d) => setButtonDate(d)} />
								</MarkupTabs>
							</div>
							<div className='my-8'>
								<p className='my-2'>Or you can default to the current day.</p>
								<MarkupTabs className='mt-2'>
									<DateToggle />
								</MarkupTabs>
							</div>
							<div className='my-8'>
								<p className='my-2'>You can start with a preset date, just by passing the same props into the picker.</p>
								<MarkupTabs className='mt-2'>
									<DateToggle selectedRange={[new Date(2022, 9, 1), new Date(2022, 9, 28)]} disabled />
								</MarkupTabs>
								<MarkupTabs className='mt-2'>
									<DateToggle selected={new Date(2022, 9, 1)} disabled />
								</MarkupTabs>
							</div>
						</Accordion.Item>
					</Accordion>
				</section>
			</section>

			<section className='mt-4'>
				<h2 id='data_tables' className='text-h3 scroll-mt-4'>
					Data Tables
				</h2>

				<p className='mt-2'>
					Data tables are specialized-yet-flexible components designed to display different kinds of tabular data. This
					component is based on <a href='https://tanstack.com/table/v8'>the TanStack Table library</a>, knowledge of which
					will be very helpful in structuring your table&apos;s raw data to look and feel the way you want.
				</p>

				<Accordion type='multiple' itemStyle='contained' className='mt-4'>
					<Accordion.Item value='Component API'>
						<p>
							In most cases, the first step in using a <code>{'<DataTable />'}</code> component will be defining the shape of
							the raw data that will be passed in as a new TypeScript type/interface. You can then pass this interface to the
							component in order to
						</p>

						<ApiTable
							className='mt-2'
							items={[
								{
									name: 'title',
									type: ['string'],
									required: true,
									description: 'The title to apply to this data table.',
								},
								{
									name: 'data',
									type: ['TableRow[]'],
									required: true,
									description: 'An array of data points with which to populate the rows of this data table.',
								},
								{
									name: 'columns',
									type: ['ColumnDef<TableRow>[]'],
									required: true,
									description: (
										<>
											The column definitions for this data table, which determine how the raw data are parsed and displayed. For
											more information on column definitions, see{' '}
											<a href='https://tanstack.com/table/v8/docs/guide/column-defs' target='_blank' rel='noreferrer'>
												the TanStack Table documentation
											</a>
											.
										</>
									),
								},
								{
									name: 'pageSizes',
									type: ['number[]'],
									default: '[10, 25, 50]',
									description:
										'The options for how many rows should be displayed on each page of this data table. The first option will always be the default selection.',
								},
								{
									name: 'emptyMessage',
									type: ['string'],
									default: '"This table has no data to display."',
									description: 'A custom message to show in this table when it has no data to display.',
								},
								{
									name: 'rowSubview',
									type: ['React.ReactNode', '(row: Row<TableRow>) => React.ReactNode'],
									description:
										"A layout to display when expanding one of this data table's rows. In callback form, the row's data are supplied as the first argument, allowing them to be referenced within the subview.",
								},
								{
									name: 'disablePagination',
									type: ['boolean'],
									default: 'false',
									description: 'Whether to disable pagination for this data table and display all available rows at once.',
								},
								{
									name: 'disableRowCounter',
									type: ['boolean'],
									default: 'false',
									description: 'Whether to disable the "X of N items" row counter for this data table.',
								},
							]}
						/>

						<h3 className='mt-4'>
							<code>columns[].meta</code>
						</h3>

						<p className='mt-2'>
							While the required structure for column definitions is dictated by the TanStack Table library, there are some
							Merlin-specific options that can be passed via the customizable <code>meta</code> property to alter a
							column&apos;s output. If you need to regularly achieve a specific kind of column output, consider further
							extending this property to make your option readily available to columns in any table.
						</p>

						<ApiTable
							className='mt-2'
							items={[
								{
									name: 'bodyClass',
									type: ['string'],
									description: "A string of additional classes to apply to this column's body cells.",
								},
								{
									name: 'columnClass',
									type: ['string'],
									description: 'A string of additional classes to apply to all cells in this column.',
								},
								{
									name: 'footerClass',
									type: ['string'],
									description: "A string of additional classes to apply to this column's footer cells.",
								},
								{
									name: 'headerClass',
									type: ['string'],
									description: "A string of additional classes to apply to this column's header cells.",
								},
								{
									name: 'tooltipContent',
									type: ["TooltipProps['content']"],
									description: "Tooltip content to apply to this column's header.",
								},
							]}
						/>
					</Accordion.Item>

					<Accordion.Item value='Basic Example'>
						<p>
							This example represents a very simple table that displays the raw data by directly referencing the name of each
							desired column. By default, all data table columns are sortable and can be used to rearrange a table&apos;s rows.
							Most are also searchable by default, meaning they can be used to filter the table&apos;s visible rows.
						</p>

						<p className='mt-2'>
							A sortable column has an icon next to its header indicating its current sort status. Clicking a sortable
							column&apos;s header will arrange all the rows in the table by that column (including rows on pages that
							aren&apos;t currently visible, if applicable). There are several sorting algorithms that can be applied based on
							the type of data in the column; the table will attempt to automatically use the most appropriate, but this value
							can be set manually or overriden with a custom sorting function if necessary. In the example below, notice how
							the Date column (which is made up of Date objects) and the Flip Indicator column (which is based on boolean
							values) have non-alphabetical sorting behavior that better suits the underlying data types.
						</p>

						<p className='mt-2'>
							The searchable columns for a given table are listed in the tooltip attached to the information icon next to the
							search field. If no columns are searchable, the search field and icon will automatically be removed. The Date and
							Flip Indicator columns are again standouts here—neither one of them is searchable by default, as their values
							aren&apos;t technically strings and therefore aren&apos;t straightforward to compare against a search term.
						</p>

						<MarkupTabs className='mt-2'>
							<DataTable
								title='Example Table'
								columns={[
									{ header: 'Case ID', accessorKey: 'case_id' },
									{ header: 'Date', accessorKey: 'date' },
									{ header: 'Surgeon', accessorKey: 'surgeon' },
									{ header: 'Procedure', accessorKey: 'procedure' },
									{
										header: 'Flip Indicator',
										accessorKey: 'flip_indicator',
									},
								]}
								data={generateCaseDetailRecords(5)}
							/>
						</MarkupTabs>
					</Accordion.Item>

					<Accordion.Item value='Customizing Column Output'>
						<p>
							In many cases, it&apos;s desirable to format a column&apos;s underlying data into something more readable, or
							otherwise better for display. In these cases, you&apos;ll usually want to reach for the <code>accessorFn</code>{' '}
							or <code>cell</code> properties to either manipulate the data themselves or change the way those data are
							presented to the user.
						</p>

						<p className='mt-2'>
							In the example below, the Date column has been reformatted using the <code>accessorFn</code> property, altering
							the underlying data into a more human-readable string format. However, this also means that the table will treat
							the column&apos;s data as if they were strings instead of Date objects. Using string values for dates is helpful
							in that the column is now filterable, since the values can be compared to the user&apos;s search term—but it also
							causes the dates to be sorted alphabetically, which is not ideal.
						</p>

						<p className='mt-2'>
							The Flip Indicator column has also been transformed into a simple checkmark/empty format, but rather than
							altering the underlying boolean data with <code>accessorFn</code>, the <code>cell</code> property has been used
							to control the column&apos;s visual output independently of the data. This allows the column to maintain its
							datatype-specific sorting behavior (which sorts true before false by default) while also controlling how it is
							presented visually.
						</p>

						<MarkupTabs className='mt-2'>
							<DataTable
								title='Example Table'
								columns={[
									{ header: 'Case ID', accessorKey: 'case_id' },
									{ header: 'Date', accessorFn: (row) => dateFormatter.format(row.date) },
									{ header: 'Surgeon', accessorKey: 'surgeon' },
									{ header: 'Procedure', accessorKey: 'procedure' },
									{
										header: 'Flip Indicator',
										accessorKey: 'flip_indicator',
										cell: ({ row }) => (row.getValue('flip_indicator') ? <div className='material-symbol'>check</div> : ''),
									},
								]}
								data={generateCaseDetailRecords(5)}
							/>
						</MarkupTabs>

						<p className='mt-2'>
							In addition to the standard column definition properties provided by the TanStack Table library, data tables have
							some custom properties that allow you to better control the style of specific columns, or control custom column
							features. The following example demonstrates some of these &quot;meta&quot; options.
						</p>

						<MarkupTabs className='mt-2'>
							<DataTable
								title='Example Table'
								columns={[
									{ header: 'Case ID (Right aligned)', accessorKey: 'case_id', meta: { columnClass: 'text-right' } },
									{
										header: 'Surgeon (With tooltip)',
										accessorKey: 'surgeon',
										meta: { tooltipContent: 'The name of the surgeon.' },
									},
									{ header: 'Procedure (With class "w-0")', accessorKey: 'procedure', meta: { headerClass: 'w-0' } },
									{
										header: 'Date (With color classes)',
										accessorFn: (row) => dateFormatter.format(row.date),
										meta: {
											columnClass: 'text-yellow-900',
											headerClass: 'bg-red-100',
											bodyClass: 'bg-blue-100',
											footerClass: 'bg-green-100',
										},
										footer: 'Footer Content',
									},
								]}
								data={generateCaseDetailRecords(5)}
							/>
						</MarkupTabs>
					</Accordion.Item>

					<Accordion.Item value='Combining Sorting &amp; Formatting'>
						<p>
							In the previous example, formatting the Date column data from objects into &quot;MM/DD/YYYY&quot; strings made
							the data more readable and allowed for filtering, but caused the table&apos;s default sorting to organize the
							column alphabetically, which isn&apos;t ideal for dates. This example demonstrates how you can use the{' '}
							<code>sortingFn</code> property to customize the way a column&apos;s data should be sorted, allowing you to make
							a column like Date both filterable and sortable at the same time.
						</p>

						<MarkupTabs className='mt-2'>
							<DataTable
								title='Example Table'
								columns={[
									{ header: 'Date (Filterable)', accessorFn: (row) => dateFormatter.format(row.date) },
									{
										header: 'Date (Sortable)',
										accessorKey: 'date',
										cell: ({ row }) => dateFormatter.format(row.getValue('date')),
									},
									{
										header: 'Date (Both)',
										accessorFn: (row) => dateFormatter.format(row.date),
										sortingFn: (rowA, rowB, columnId) => {
											const a = new Date(rowA.getValue(columnId)).getTime();
											const b = new Date(rowB.getValue(columnId)).getTime();
											return b > a ? 1 : -1;
										},
									},
								]}
								data={generateCaseDetailRecords(5)}
							/>
						</MarkupTabs>
					</Accordion.Item>

					<Accordion.Item value='Adding Functional Columns'>
						<p>
							Columns within data tables aren&apos;t limited to displaying the data provided to the table—you can also add
							arbitrary columns with any kind of content in them. For example, you can include buttons or other controls that
							perform actions based on the row&apos;s data.
						</p>

						<MarkupTabs className='mt-2'>
							<DataTable
								title='Example Table'
								columns={[
									{ header: 'Case ID', accessorKey: 'case_id' },
									{ header: 'Surgeon', accessorKey: 'surgeon' },
									{ header: 'Procedure', accessorKey: 'procedure' },
									{ header: 'Patient In', accessorKey: 'patient_in' },
									{
										header: 'Flip',
										accessorKey: 'flip_indicator',
										cell: ({ row }) => (row.getValue('flip_indicator') ? <div className='material-symbol'>check</div> : ''),
									},
									{
										header: 'Select',
										cell: ({ row }) => (
											<Select
												label='Select field'
												hideLabel
												options={dummySelectOptions}
												onChange={(newSelection) =>
													console.log(`Selected ${newSelection?.label || 'nothing'} for row with Case ID ${row.getValue('case_id')}`)
												}
											/>
										),
									},
									{
										header: 'Button',
										cell: ({ row }) => (
											<Button onClick={() => console.log(`Activated row with Case ID ${row.getValue('case_id')}`)}>Action</Button>
										),
									},
								]}
								data={generateCaseDetailRecords(5)}
							/>
						</MarkupTabs>
					</Accordion.Item>

					<Accordion.Item value='Displaying Multiple Pages &amp; Aggregating Data'>
						<p>
							Data tables will automatically apply pagination to large datasets to ensure tables don&apos;t become too long.
							The bottom of the table features page controls and several metrics on the table&apos;s data to help orient the
							user and allow them to customize the amount of data displayed.
						</p>

						<p className='mt-2'>
							The following example features a larger dataset that can be paged through. It also demonstrates the use of a
							footer cell to aggregate a column&apos;s data. Notice how the aggregate value simultaneously references not only
							all the rows in the table, but also just the rows that are visible—whether they are rows from the current page or
							rows that match the current search term. On large datasets, these kinds of live computations can be very
							powerful, but they are often also very computationally expensive. Always be aware of how they might impact a
							table&apos;s performance.
						</p>

						<MarkupTabs className='mt-2'>
							<DataTable
								title='Example Table'
								columns={[
									{
										header: 'Case ID',
										accessorKey: 'case_id',
										footer: ({ table }) => {
											const visibleTotal = table
												.getRowModel()
												.rows.reduce((total, row) => total + +row.getValue<string>('case_id'), 0);
											const total = table
												.getCoreRowModel()
												.rows.reduce((total, row) => total + +row.getValue<string>('case_id'), 0);
											const percentage = `${((visibleTotal / total) * 100).toFixed(1)}%`;

											return `${visibleTotal} out of ${total} (${percentage})`;
										},
									},
									{ header: 'Surgeon', accessorKey: 'surgeon' },
									{ header: 'Procedure', accessorKey: 'procedure' },
									{
										header: 'Flip',
										accessorKey: 'flip_indicator',
										cell: ({ row }) => (row.getValue('flip_indicator') ? <div className='material-symbol'>check</div> : ''),
									},
								]}
								data={generateCaseDetailRecords(105)}
							/>
						</MarkupTabs>
					</Accordion.Item>

					<Accordion.Item value='Expanding Table Rows'>
						<p>
							In situations where the rows of your data table could benefit from extra context or information, you can provide
							your data table with a <code>rowSubview</code>, enabling every row to expand and display an additional,
							self-contained layout. These subviews can be used for anything from a simple analysis of the row&apos;s contents
							to entire nested tables that work with separate datasets.
						</p>

						<MarkupTabs className='mt-2'>
							<DataTable
								title='Example Table'
								columns={[
									{ header: 'Case ID', accessorKey: 'case_id' },
									{ header: 'Surgeon', accessorKey: 'surgeon' },
									{ header: 'Procedure', accessorKey: 'procedure' },
									{
										header: 'Flip',
										accessorKey: 'flip_indicator',
										cell: ({ row }) => (row.getValue('flip_indicator') ? <div className='material-symbol'>check</div> : ''),
									},
								]}
								rowSubview={(row) => `This is a simple subview for the row with Case ID "${row.getValue('case_id')}".`}
								data={generateCaseDetailRecords(5)}
							/>
						</MarkupTabs>
					</Accordion.Item>

					<Accordion.Item value='Disabling Control Bar Items'>
						<p>
							The bottom area of each data table is called the &quot;control bar&quot; (to differentiate it from the footer,
							which is potentially a part of the actual table). The control bar contains various features such as the
							table&apos;s pagination controls and a row counter that identifies how many of the table&apos;s total rows are
							visible in the current view, taking into account any applied pagination or search filters.
						</p>

						<p className='mt-2'>
							In some cases, you may want some or all of these features to be disabled, such as for tables where the number of
							rows is always static. There are relevant props for disabling each of the control bar&apos;s features. When all
							of these features are disabled, the control bar is automatically hidden.
						</p>

						<MarkupTabs className='mt-2'>
							<DataTable
								title='Example Table'
								columns={[
									{ header: 'Case ID', accessorKey: 'case_id' },
									{ header: 'Date', accessorKey: 'date' },
									{ header: 'Surgeon', accessorKey: 'surgeon' },
									{ header: 'Procedure', accessorKey: 'procedure' },
									{ header: 'Flip Indicator', accessorKey: 'flip_indicator' },
								]}
								data={generateCaseDetailRecords(11)}
								disablePagination
							/>
						</MarkupTabs>

						<MarkupTabs className='mt-2'>
							<DataTable
								title='Example Table'
								columns={[
									{ header: 'Case ID', accessorKey: 'case_id' },
									{ header: 'Date', accessorKey: 'date' },
									{ header: 'Surgeon', accessorKey: 'surgeon' },
									{ header: 'Procedure', accessorKey: 'procedure' },
									{ header: 'Flip Indicator', accessorKey: 'flip_indicator' },
								]}
								data={generateCaseDetailRecords(11)}
								disableRowCounter
							/>
						</MarkupTabs>

						<MarkupTabs className='mt-2'>
							<DataTable
								title='Example Table'
								columns={[
									{ header: 'Case ID', accessorKey: 'case_id' },
									{ header: 'Date', accessorKey: 'date' },
									{ header: 'Surgeon', accessorKey: 'surgeon' },
									{ header: 'Procedure', accessorKey: 'procedure' },
									{ header: 'Flip Indicator', accessorKey: 'flip_indicator' },
								]}
								data={generateCaseDetailRecords(11)}
								disablePagination
								disableRowCounter
							/>
						</MarkupTabs>
					</Accordion.Item>

					<Accordion.Item value='Adding Header Content'>
						<p>
							A data table&apos;s header can display extra content in addition to the title via the{' '}
							<code>headerContentCenter</code> and <code>headerContentRight</code> props which, as their names suggest, give
							you access to areas in the middle and right-hand sides of the header, respectively. They can be passed plain
							strings or custom JSX, making them ideal for providing table-related controls or contextual information.
						</p>

						<p className='my-2'>
							Note that content passed to <code>headerContentRight</code> will be displayed slightly to the left of your
							table&apos;s search field (if applicable).
						</p>

						<MarkupTabs>
							<DataTable
								title='Example Table'
								headerContentCenter={<DateToggle />}
								columns={[
									{ header: 'Case ID', accessorKey: 'case_id' },
									{ header: 'Date', accessorFn: (row) => dateFormatter.format(row.date) },
									{ header: 'Surgeon', accessorKey: 'surgeon' },
									{ header: 'Procedure', accessorKey: 'procedure' },
									{
										header: 'Flip Indicator',
										accessorKey: 'flip_indicator',
										cell: ({ row }) => (row.getValue('flip_indicator') ? <div className='material-symbol'>check</div> : ''),
									},
								]}
								data={generateCaseDetailRecords(5)}
							/>
						</MarkupTabs>

						<MarkupTabs>
							<DataTable
								title='Example Table'
								headerContentRight={
									<Button sizeX='sm' className='mr-2'>
										<span className='material-symbol-sm'>person_add</span>Add User
									</Button>
								}
								columns={[
									{ header: 'Case ID', accessorKey: 'case_id' },
									{ header: 'Date', accessorFn: (row) => dateFormatter.format(row.date) },
									{ header: 'Surgeon', accessorKey: 'surgeon' },
									{ header: 'Procedure', accessorKey: 'procedure' },
									{
										header: 'Flip Indicator',
										accessorKey: 'flip_indicator',
										cell: ({ row }) => (row.getValue('flip_indicator') ? <div className='material-symbol'>check</div> : ''),
									},
								]}
								data={generateCaseDetailRecords(5)}
							/>
						</MarkupTabs>

						<p className='my-2'>
							The <code>headerContentCenter</code> area has a related prop named <code>headerContentCenterAlignment</code>,
							which controls how content within the middle area should be displayed relative to the table. The default
							&quot;center&quot; value keeps any provided content in this area centered on the table as a whole, while the
							&quot;left&quot; value aligns any provided content directly after the table&apos;s title.
						</p>

						<MarkupTabs>
							<DataTable
								title='Example Table'
								headerContentCenter={
									<Select
										label='View by'
										options={[
											{ value: 'day_of_week', label: 'Day of Week' },
											{ value: 'month', label: 'Month' },
										]}
										sizeX='sm'
									/>
								}
								headerContentCenterAlignment='left'
								columns={[
									{ header: 'Case ID', accessorKey: 'case_id' },
									{ header: 'Date', accessorFn: (row) => dateFormatter.format(row.date) },
									{ header: 'Surgeon', accessorKey: 'surgeon' },
									{ header: 'Procedure', accessorKey: 'procedure' },
									{
										header: 'Flip Indicator',
										accessorKey: 'flip_indicator',
										cell: ({ row }) => (row.getValue('flip_indicator') ? <div className='material-symbol'>check</div> : ''),
									},
								]}
								data={generateCaseDetailRecords(5)}
							/>
						</MarkupTabs>
					</Accordion.Item>
				</Accordion>
			</section>

			<section className='mt-4'>
				<h2 id='toasts' className='text-h3 scroll-mt-4'>
					Toasts
				</h2>

				<p className='mt-2'>
					Toasts are a notification mechanism used to provide feedback to users based on actions they take while using
					Merlin. At a base level, our toasts are generated using the <code>{'<Toast />'}</code> component, which is a
					customized implementation of{' '}
					<a href='https://www.radix-ui.com/docs/primitives/components/toast' target='_blank' rel='noreferrer'>
						the Toast primitive from Radix
					</a>
					.
				</p>

				<p className='mt-2'>
					You&apos;ll likely never need to use the <code>{'<Toast />'}</code> component directly, however, as doing so would
					effectively create a &quot;permanent&quot; toast that would be re-rendered unexpectedly as the component tree
					changes. Instead, you can use the custom <code>useToast()</code> hook to access a global context for tracking
					toasts. This context provides a <code>createToast()</code> function that accepts the <code>{'<Toast />'}</code>{' '}
					component&apos;s props as an object, creating your toast for you at the appropriate place in the component tree.
				</p>

				<Accordion type='multiple' itemStyle='contained' className='mt-4'>
					<Accordion.Item value='Component API'>
						<p>
							Most of the relevant props for this component can be found in{' '}
							<a href='https://www.radix-ui.com/docs/primitives/components/toast' target='_blank' rel='noreferrer'>
								the Radix documentation for Toasts
							</a>
							. The props listed here are customizations laid on top of the existing props to accommodate the needs of the
							project.
						</p>

						<ApiTable
							className='mt-4'
							items={[
								{
									name: 'variant',
									type: ['"info"', '"success"', '"warning"', '"error"'],
									default: '"info"',
									description: 'The visual treatment to apply to this toast.',
								},
								{
									name: 'icon',
									type: ['string', 'false'],
									description:
										'The name of a Material Symbol to use as an icon for this toast. Pass false or an empty string to disable the icon.',
								},
								{
									name: 'fillIcon',
									type: 'boolean',
									default: 'true',
									description: "Whether this toast's icon should use a filled version, if available.",
								},
								{
									name: 'title',
									type: 'string',
									description: 'The title text to apply to this toast.',
								},
							]}
						/>
					</Accordion.Item>

					<Accordion.Item value='Choosing a variant'>
						<p>
							These examples demonstrate how to generate a simple toast of each variant using the <code>createToast()</code>{' '}
							function as part of a button&apos;s <code>onClick()</code> handler.
						</p>

						<PropTable className='mt-4'>
							<Button onClick={() => createToast({ title: 'Information!' })}>Info Toast</Button>
							<Button onClick={() => createToast({ title: 'Success!', variant: 'success' })}>Success Toast</Button>
							<Button onClick={() => createToast({ title: 'Warning!', variant: 'warning' })}>Warning Toast</Button>
							<Button onClick={() => createToast({ title: 'Error!', variant: 'error' })}>Error Toast</Button>
						</PropTable>
					</Accordion.Item>

					<Accordion.Item value='Controlling icons'>
						<p>
							These examples demonstrate how to change a toast&apos;s icon, including removing the default icon applied to each
							toast variant. Note that toasts use filled icons by default for increased contrast and legibility.
						</p>

						<PropTable className='mt-4'>
							<Button onClick={() => createToast({ title: 'Information!', icon: 'notifications' })}>
								Info toast with new icon
							</Button>
							<Button onClick={() => createToast({ title: 'Information!', icon: 'notifications', fillIcon: false })}>
								Info toast with new, unfilled icon
							</Button>
							<Button onClick={() => createToast({ title: 'Information!', icon: false })}>Info toast with no icon</Button>
						</PropTable>
					</Accordion.Item>
				</Accordion>
			</section>

			<section className='mt-4'>
				<h2 id='tooltips' className='text-h3 scroll-mt-4'>
					Tooltips
				</h2>

				<p className='mt-2'>
					Tooltips are an informational mechanism used to provide context and tips to users related to Merlin&apos;s user
					interface. Our tooltips are applied using the <code>{'<Tooltip>'}</code> component, which is a customized
					implementation of{' '}
					<a href='https://www.radix-ui.com/docs/primitives/components/tooltip' target='_blank' rel='noreferrer'>
						the Tooltip primitive from Radix
					</a>
					.
				</p>

				<Accordion type='multiple' itemStyle='contained' className='mt-4'>
					<Accordion.Item value='Component API'>
						<p>
							Most of the relevant props for this component can be found in{' '}
							<a href='https://www.radix-ui.com/docs/primitives/components/tooltip' target='_blank' rel='noreferrer'>
								the Radix documentation for Tooltips
							</a>
							. The props listed here are customizations laid on top of the existing props to accommodate the needs of the
							project.
						</p>

						<ApiTable
							className='mt-4'
							items={[
								{
									name: 'content',
									type: 'string',
									description: 'The text to display within this tooltip.',
								},
								{
									name: 'icon',
									type: 'string',
									description: 'The name of a Material Symbol to use as an icon for this tooltip.',
								},
								{
									name: 'fillIcon',
									type: 'boolean',
									description: "Whether this tooltip's icon should use a filled version, if available.",
								},
								{
									name: 'triggerType',
									type: ['"button"', '"span"', '"div"'],
									default: '"button"',
									description:
										"The type of HTML element to use for the tooltip's trigger. This is a button by default, but can be changed to allow tooltips to work in different layout scenarios.",
								},
							]}
						/>
					</Accordion.Item>

					<Accordion.Item value='General usage'>
						<p>
							A tooltip can be applied to nearly any element simply by placing that element within a <code>{'<Tooltip>'}</code>{' '}
							component. To provide the best user experience, however, you should try to only apply tooltips to elements
							dedicated to that purpose, such as an &quot;information&quot; icon near a form field&apos;s label. Adding a
							tooltip on an element that has some other form of interactivity, such as a button or link, will make that tooltip
							more difficult to organically discover, particularly for mobile users who have no interactive equivalent to a
							desktop user&apos;s &quot;hover&quot; state.
						</p>

						<p className='mt-2'>
							Tooltips are context-aware and will appear on or move to the side of the element where there is enough room for
							it to properly display.
						</p>

						<PropTable className='mt-4'>
							<Tooltip content='This link takes you to the top of the page.'>
								<a href='#top'>I go somewhere</a>
							</Tooltip>
							<Tooltip content='Lorem ipsum dolor sit amet.'>
								<span className='material-symbol'>info</span>
							</Tooltip>
						</PropTable>

						<p className='mt-4'>
							Behind the scenes, tooltips work by applying a button to the item that they surround. While this is good for
							accessibility, it can in rare cases cause issues with nesting interactive elements within other interactive
							elements, which is invalid in HTML. If you run into one of these situations, you can use the{' '}
							<code>triggerType</code> prop to alter this behavior so you can, for example, apply a tooltip to a button.
						</p>

						<PropTable className='mt-4'>
							<Tooltip content="This button isn't nested within another button!" triggerType='span'>
								<Button sizeX='lg' sizeY='lg'>
									It&apos; a button!
								</Button>
							</Tooltip>
						</PropTable>
					</Accordion.Item>

					<Accordion.Item value='Controlling icons'>
						<p>These examples demonstrate how to add an icon to a tooltip.</p>

						<PropTable className='mt-4'>
							<Tooltip content='Lorem ipsum dolor sit amet.' icon='info'>
								Hover me!
							</Tooltip>
							<Tooltip content='Lorem ipsum dolor sit amet.' icon='info' fillIcon>
								Hover me!
							</Tooltip>
						</PropTable>
					</Accordion.Item>
				</Accordion>
			</section>

			<section className='mt-4'>
				<h2 id='dropdowns' className='text-h3 scroll-mt-4'>
					Dropdown Menus
				</h2>

				<p className='mt-2'>
					Dropdown menus are implemented using the <code>{'<DropdownMenu>'}</code> component. This component is a wrapper
					with some predetermined styles based on{' '}
					<a href='https://www.radix-ui.com/docs/primitives/components/popover'>the Popover primitive from Radix</a>.
				</p>

				<Accordion type='multiple' itemStyle='contained' className='mt-4'>
					<Accordion.Item value='Component API'>
						<ApiTable
							items={[
								{
									name: 'options | required',
									type: 'string',
									description: 'Array containing the options to be rendered in the menu',
								},
								{
									name: 'className | required',
									type: 'string',
									description: 'The tailwind classes to be applied to the <button> rendered in the Popover.Trigger API.',
								},
								{
									name: 'onOpenOrClose | optional',
									type: 'function',
									description: 'Callback for when the dropdown menu opens or closes.',
								},
								{
									name: '(none) children | required',
									type: 'ReactNode',
									description: "Whatever JSX you pass into the DropdownMenu will be it's trigger to open the menu.",
								},
							]}
						/>
					</Accordion.Item>

					<Accordion.Item value='Example'>
						<CodeBlock>
							{`
								<DropdownMenu
									className='mx-1 relative border border-transparent bg-bluegray-50 rounded-full transition-all hover:shadow-lg w-10 h-10 hover:bg-merlinBlue hover:border-blue-500 hover:text-blue-500'
									options={[your array]}
								>
									<!-- button content -->
									ED
								</DropdownMenu>
							`}
						</CodeBlock>
					</Accordion.Item>
				</Accordion>
			</section>

			<section className='mt-4'>
				<h2 id='alerts' className='text-h3 scroll-mt-4'>
					Alerts
				</h2>

				<p className='mt-2'>
					Alerts are modal dialogs used to interrupt the user with important information and solicit a response from them
					before committing to an action. Our alerts are applied using the <code>{'<Alert>'}</code> component, which is a
					customized implementation of{' '}
					<a href='https://www.radix-ui.com/docs/primitives/components/alert-dialog' target='_blank' rel='noreferrer'>
						the Alert Dialog primitive from Radix
					</a>
					.
				</p>

				<Accordion type='multiple' itemStyle='contained' className='mt-4'>
					<Accordion.Item value='Component API'>
						<p>
							Most of the relevant props for this component can be found in{' '}
							<a href='https://www.radix-ui.com/docs/primitives/components/alert-dialog' target='_blank' rel='noreferrer'>
								the Radix documentation for Alert Dialogs
							</a>
							. The props listed here are customizations laid on top of the existing props to accommodate the needs of the
							project.
						</p>

						<ApiTable
							className='mt-4'
							items={[
								{
									name: 'title',
									type: 'string',
									default: '"Alert"',
									description: 'The title text to display at the top of this alert.',
								},
								{
									name: 'description',
									type: 'string',
									description: 'The content of this alert, outlining the decision the user needs to make before proceeding.',
								},
								{
									name: 'responseOptions',
									type: 'AlertResponseOption[]',
									description: 'The response options to present to the user in this alert.',
								},
								{
									name: 'doNothingResponse',
									type: 'AlertResponseOption',
									description:
										'The settings for the "do nothing" (or otherwise least-destructive) response option in this alert.',
								},
								{
									name: 'onResponse',
									type: '(value: string) => void',
									description: 'The handler function to invoke when the user selects a response from this alert.',
								},
							]}
						/>

						<div className='mt-4 font-bold'>
							<code>AlertResponseOption</code>
						</div>

						<ApiTable
							className='mt-4'
							items={[
								{
									name: 'value',
									type: 'string',
									description: 'The value to assign to this alert response.',
								},
								{
									name: 'label',
									type: 'string',
									description: 'The label to use for the button that returns the associated response value.',
								},
							]}
						/>
					</Accordion.Item>

					<Accordion.Item value='General usage'>
						<p>
							There are two ways to invoke an alert: By wrapping an element with the <code>{'<Alert>'}</code> component, or by
							using the `getAlertResponse()` method provided by the `useAlert()` hook. The option you choose will likely come
							down to simple preference based on your situation.
						</p>

						<p className='mt-4 font-bold'>
							The <code>{'<Alert>'}</code> Component
						</p>

						<p className='mt-2'>
							This example summons a simple alert with three options: Two defined responses and the default &quot;do
							nothing&quot; response, which is always present. The function passed to the <code>onResponse</code> prop, which
							receives the value of the selected response, will log that value to the console.
						</p>

						<MarkupTable className='mt-4'>
							<Alert
								description='Are you sure?'
								responseOptions={[
									{ value: 'maybe', label: 'Maybe' },
									{ value: 'definitely', label: 'Definitely' },
								]}
								onResponse={(value) => console.log(`User selected "${value}"`)}
							>
								<Button>Do a Thing</Button>
							</Alert>
						</MarkupTable>

						<p className='mt-4 font-bold'>
							The <code>{'getAlertResponse'}</code> Function
						</p>

						<p className='mt-2'>
							Similarly to the previous example, this example summons a simple alert with three options: Two defined responses
							and the default &quot;do nothing&quot; response, which is always present. However, notice that the component
							being used is actually a <code>{'<Button>'}</code>, which summons the alert by calling the{' '}
							<code>getAlertResponse</code> function within its <code>onClick</code> handler. Because the handler is
							asynchronous and calls <code>getAlertResponse</code> with <code>await</code>, the function creates the alert and
							then interrupts the handler until the user selects a response. When it resumes, the <code>alertResponse</code>{' '}
							variable will contain the value of the user&apos;s response and can be used as part of any subsequent logic.
						</p>

						<MarkupTable className='mt-4'>
							<Button
								onClick={async () => {
									console.log(`Awaiting user response...`);

									const alertResponse = await getAlertResponse({
										description: 'Are you sure?',
										responseOptions: [
											{ value: 'maybe', label: 'Maybe' },
											{ value: 'definitely', label: 'Definitely' },
										],
									});

									console.log(`User responded with "${alertResponse}"`);
								}}
							>
								Do a Thing
							</Button>
						</MarkupTable>
					</Accordion.Item>

					<Accordion.Item value='Customizing the "do nothing" option'>
						<p>
							Depending on the decision you&apos;re asking the user to make, you may find that &quot;Cancel&quot; isn&apos;t an
							appropriate label to use (such as when confirming the cancellation of a subscription, for example). To customize
							this option&apos;s label or value, pass the same shape of <code>AlertResponseOption</code> object as you would to
							your other <code>responseOptions</code> directly to the <code>doNothingResponse</code> prop, as demonstrated
							below.
						</p>

						<MarkupTable className='mt-4'>
							<Button
								onClick={async () => {
									console.log(`Awaiting user response...`);

									const alertResponse = await getAlertResponse({
										description: 'Are you sure?',
										responseOptions: [
											{ value: 'definitely', label: 'Definitely' },
											{ value: 'maybe', label: 'Maybe' },
										],
										doNothingResponse: { value: 'no_way', label: 'No Way!' },
									});

									console.log(`User responded with "${alertResponse}".`);
								}}
							>
								Do a Thing
							</Button>
						</MarkupTable>
					</Accordion.Item>
				</Accordion>
			</section>

			<section className='mt-4'>
				<h2 id='filter_components' className='text-h3 scroll-mt-4'>
					Filter Components
				</h2>

				<section className='mt-4'>
					<h3 id='filter_fields' className='text-h4 scroll-mt-4'>
						Filter Fields
					</h3>

					<p className='mt-2'>
						The <code>{'<FilterFields />'}</code> component is designed to be a simple way to include consistent controls and
						inputs for adjusting the state of filters throughout Merlin.
					</p>

					<Accordion type='multiple' itemStyle='contained' className='mt-4'>
						<Accordion.Item value='Component API'>
							<ApiTable
								items={[
									{
										name: 'fields',
										required: true,
										type: 'FilterFieldName[]',
										description: (
											<div>
												The names of the fields to display. One or more of:
												<ul className='list-disc pt-1 pl-6'>
													<li>
														<code>{`'date'`}</code>
													</li>
													<li>
														<code>{`'dateRange'`}</code>
													</li>
													<li>
														<code>{`'surgeon'`}</code>
													</li>
													<li>
														<code>{`'surgeons'`}</code>
													</li>
													<li>
														<code>{`'daysOfWeek'`}</code>
													</li>
													<li>
														<code>{`'serviceLines'`}</code>
													</li>
													<li>
														<code>{`'encounterTypes'`}</code>
													</li>
													<li>
														<code>{`'rooms'`}</code>
													</li>
													<li>
														<code>{`'turnoverTimeThreshold'`}</code>
													</li>
													<li>
														<code>{`'fcotsGraceMinutes'`}</code>
													</li>
													<li>
														<code>{`'percentile'`}</code>
													</li>
													<li>
														<code>{`'primetime'`}</code>
													</li>
													<li>
														<code>{`'addOns'`}</code>
													</li>
													<li>
														<code>{`'utilizationType'`}</code>
													</li>
													<li>
														<code>{`'timeslotType'`}</code>
													</li>
													<li>
														<code>{`'blockTimeslot'`}</code>
													</li>
												</ul>
											</div>
										),
									},
								]}
							/>
						</Accordion.Item>

						<Accordion.Item value='Basic Example'>
							<p>
								You can use the <code>{'<FilterFields />'}</code> component anywhere that you want to display controls for
								Merlin&apos;s filters. You simply need to specify which fields you want to display. The value for each field
								comes from the global filter context (discussed below), so no additional code to control the values is
								necessary.
							</p>

							<p className='mt-2'>
								Note that the order of the fields is static, and not affected by the order in which you list them in the{' '}
								<code>fields</code> prop.
							</p>

							<MarkupTabs className='mt-4'>
								<FilterFields
									fields={[
										'date',
										'dateRange',
										'surgeon',
										'surgeons',
										'daysOfWeek',
										'serviceLines',
										'encounterTypes',
										'rooms',
										'turnoverTimeThreshold',
										'fcotsGraceMinutes',
										'percentile',
										'primetime',
										'addOns',
										'utilizationType',
										'timeslotType',
										'blockTimeslot',
									]}
								/>
							</MarkupTabs>
						</Accordion.Item>
					</Accordion>
				</section>

				<section className='mt-4'>
					<h3 id='filter_drawer' className='text-h4 scroll-mt-4'>
						Filter Drawer
					</h3>

					<p className='mt-2'>
						While you can place filter fields anywhere using the <code>{'<FilterFields />'}</code> component, they are most
						commonly displayed as part of a drawer. Because this pattern is so common, the <code>{'<FilterDrawer />'}</code>{' '}
						component exists as a way to consistently utilize it across multiple views. Filter drawers also leverage some of
						the extra functionality provided by the <code>useFilters</code> hook to include buttons for undoing filter changes
						or resetting them to their default values.
					</p>

					<Accordion type='multiple' itemStyle='contained' className='mt-4'>
						<Accordion.Item value='Component API'>
							<ApiTable
								items={[
									{
										name: 'trigger',
										type: 'ReactNode',
										default: 'A pre-styled Filter button.',
										description: 'The element to use to trigger the opening of this drawer.',
									},
									{
										name: 'fields',
										required: true,
										type: 'FilterFieldName[]',
										description: (
											<div>
												The names of the fields to display, passed directly to an embedded filter field component. One or more of:
												<ul className='list-disc pt-1 pl-6'>
													<li>
														<code>{`'date'`}</code>
													</li>
													<li>
														<code>{`'dateRange'`}</code>
													</li>
													<li>
														<code>{`'surgeon'`}</code>
													</li>
													<li>
														<code>{`'surgeons'`}</code>
													</li>
													<li>
														<code>{`'daysOfWeek'`}</code>
													</li>
													<li>
														<code>{`'serviceLines'`}</code>
													</li>
													<li>
														<code>{`'encounterTypes'`}</code>
													</li>
													<li>
														<code>{`'rooms'`}</code>
													</li>
													<li>
														<code>{`'turnoverTimeThreshold'`}</code>
													</li>
													<li>
														<code>{`'fcotsGraceMinutes'`}</code>
													</li>
													<li>
														<code>{`'percentile'`}</code>
													</li>
													<li>
														<code>{`'primetime'`}</code>
													</li>
													<li>
														<code>{`'addOns'`}</code>
													</li>
													<li>
														<code>{`'utilizationType'`}</code>
													</li>
													<li>
														<code>{`'timeslotType'`}</code>
													</li>
													<li>
														<code>{`'blockTimeslot'`}</code>
													</li>
												</ul>
											</div>
										),
									},
								]}
							/>
						</Accordion.Item>

						<Accordion.Item value='Basic Example'>
							<p>
								Placing a <code>{'<FilterDrawer />'}</code> component follows the same logic as placing a standard{' '}
								<code>{'<Drawer />'}</code> component: Because they are technically built surrounding the button that triggers
								them, your filter drawer should usually be placed where you would expected to see a &quot;Filter&quot; button in
								your interface.
							</p>

							<MarkupTabs className='mt-4'>
								<FilterDrawer
									filterFields={[
										'date',
										'dateRange',
										'surgeon',
										'surgeons',
										'daysOfWeek',
										'serviceLines',
										'encounterTypes',
										'rooms',
										'turnoverTimeThreshold',
										'fcotsGraceMinutes',
										'percentile',
										'primetime',
										'addOns',
										'utilizationType',
										'timeslotType',
										'blockTimeslot',
									]}
								/>
							</MarkupTabs>
						</Accordion.Item>
					</Accordion>
				</section>

				<section className='mt-4'>
					<h3 id='usefilters_hook' className='text-h4 scroll-mt-4'>
						The <code>useFilters</code> Hook
					</h3>

					<p className='mt-2'>
						The <code>useFilters</code> hook can be used within any component in the app to access the FilterContext, which
						always contains the current state of Merlin&apos;s filters.
					</p>

					<Accordion type='multiple' itemStyle='contained' className='mt-4'>
						<Accordion.Item value='Hook API'>
							<p>
								The recommended way to access the values in the <code>useFilters</code> hook is by object destructuring, which
								is typical for complex React hooks. This allows you to directly reference only the fields and functions that you
								specifically need for your component.
							</p>

							<CodeBlock className='mt-4'>
								{`
									const {
										// Individual filter fields
										date,
										dateRange,
										surgeon,
										surgeons,
										daysOfWeek,
										serviceLines,
										encounterTypes,
										rooms,
										turnoverTimeThreshold,
										fcotsGraceMinutes,
										percentile,
										primetime,
										addOns,
										utilizationType,
										timeslotType,
										blockTimeslot,

										// Filter state methods
										resetFilters,
										clearFilters,
										applyFilters,

										// Filter state values
										filtersAreDirty,
										filtersAreFetching,
									} = useFilters();
								`}
							</CodeBlock>

							<p className='mt-4'>
								The properties of the individual filter fields that you reference from the <code>useFilters</code> hook will be
								slightly different depending on the type of input the field represents, but will always contain the following
								common members.
							</p>

							<div className='mt-4 overflow-hidden rounded border border-gray-400 bg-white'>
								<table className='w-full'>
									<colgroup>
										<col width='auto' />
										<col width='auto' />
										<col width='100%' />
									</colgroup>

									<thead>
										<tr className='font-bold border-b border-gray-400 bg-blue-50'>
											<td className='p-2'>Property</td>
											<td className='p-2 border-l border-gray-400'>Type</td>
											<td className='p-2 border-l border-gray-400'>Description</td>
										</tr>
									</thead>

									<tbody>
										{[
											{
												name: 'selected',
												type: 'varies',
												description: 'The value that the user has supplied for this field, but not yet applied.',
											},
											{
												name: 'applied',
												type: 'varies',
												description:
													'The value that has been applied to this field and which should be used to perform any API calls that you want to affect your data.',
											},
											{
												name: 'update',
												type: 'function',
												description:
													"A reducer that can be used to directly alter this field's selected or applied value by passing an object containing new values.",
											},
											{
												name: 'isDirty',
												type: 'boolean',
												description:
													"Whether this field's selected value is different than its applied value, indicating an unapplied change.",
											},
											{
												name: 'isFetching',
												type: 'boolean',
												description:
													"Whether this field is currently retrieving value data from the Merlin API. For fields that don't connect to the API, this will always return false.",
											},
										].map((item, i) => (
											<tr key={i} className='border-b border-gray-400 last:border-0'>
												<td className='p-2'>
													<pre>
														<code>{item.name}</code>
													</pre>
												</td>

												<td className='p-2 border-l border-gray-400'>{item.type}</td>

												<td className='p-2 border-l border-gray-400'>{item.description}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>

							<p className='mt-4'>
								The remaining top-level methods and values available within the <code>useFilters</code> hook offer ways to
								interact with or monitor all of the filter fields at once.
							</p>

							<div className='mt-4 overflow-hidden rounded border border-gray-400 bg-white'>
								<table className='w-full'>
									<colgroup>
										<col width='auto' />
										<col width='auto' />
										<col width='100%' />
									</colgroup>

									<thead>
										<tr className='font-bold border-b border-gray-400 bg-blue-50'>
											<td className='p-2'>Property</td>
											<td className='p-2 border-l border-gray-400'>Type</td>
											<td className='p-2 border-l border-gray-400'>Description</td>
										</tr>
									</thead>

									<tbody>
										{[
											{
												name: 'resetFilters',
												type: 'function',
												description:
													'Reverts the selected values of all filter fields back to their applied values, undoing any unapplied changes.',
											},
											{
												name: 'clearFilters',
												type: 'function',
												description: 'Reverts the selected and applied values of all filter fields back to their default values.',
											},
											{
												name: 'applyFilters',
												type: 'function',
												description: 'Replaces the applied values of all filter fields with their current selected values.',
											},
											{
												name: 'filtersAreDirty',
												type: 'boolean',
												description:
													'Whether any of the filters currently have selected values that differ from their applied values.',
											},
											{
												name: 'filtersAreFetching',
												type: 'boolean',
												description: 'Whether any of the filters are currently retrieving data from the Merlin API.',
											},
										].map((item, i) => (
											<tr key={i} className='border-b border-gray-400 last:border-0'>
												<td className='p-2'>
													<pre>
														<code>{item.name}</code>
													</pre>
												</td>

												<td className='p-2 border-l border-gray-400'>{item.type}</td>

												<td className='p-2 border-l border-gray-400'>{item.description}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</Accordion.Item>

						<Accordion.Item value='Live Example'>
							<p>
								This example takes the current values from the global filter context and displays them in readonly fields,
								allowing you to see them update in real time while interacting with filter controls that implement the various
								fields&apos; <code>update</code> functions.
							</p>

							<div className='mt-4 flex items-center gap-6'>
								<FilterDrawer
									filterFields={[
										'date',
										'dateRange',
										'surgeon',
										'surgeons',
										'daysOfWeek',
										'serviceLines',
										'encounterTypes',
										'rooms',
										'turnoverTimeThreshold',
										'fcotsGraceMinutes',
										'percentile',
										'primetime',
										'addOns',
										'utilizationType',
										'timeslotType',
										'blockTimeslot',
									]}
								/>

								<div>
									Use the button to the left to open a Filter Drawer and watch how the values below change as you interact with
									it.
								</div>
							</div>

							<div className='mt-4 px-8 py-4 flex flex-col gap-4 max-h-80 overflow-auto border border-blue-500 rounded-sm'>
								<div className='grid grid-cols-2 gap-4'>
									<TextField label='Selected Date' className='font-[monospace]' readOnly value={JSON.stringify(date.selected)} />
									<TextField label='Applied Date' className='font-[monospace]' readOnly value={JSON.stringify(date.applied)} />
								</div>

								<div className='grid grid-cols-2 gap-4'>
									<TextField
										label='Selected Date Range'
										className='font-[monospace]'
										readOnly
										value={`${JSON.stringify(dateRange.selected.startDate)} – ${JSON.stringify(dateRange.selected.endDate)}`}
										isWorking={dateRange.isFetching}
									/>

									<TextField
										label='Applied Date Range'
										className='font-[monospace]'
										readOnly
										value={`${JSON.stringify(dateRange.applied.startDate)} – ${JSON.stringify(dateRange.applied.endDate)}`}
										isWorking={dateRange.isFetching}
									/>
								</div>

								<div className='grid grid-cols-2 gap-4 text-i3'>
									<TextField
										label='Selected Surgeon'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(surgeon.selected?.id ?? null)}
										isWorking={surgeon.isFetching}
									/>

									<TextField
										label='Applied Surgeon'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(surgeon.applied?.id ?? null)}
										isWorking={surgeon.isFetching}
									/>
								</div>

								<div className='grid grid-cols-2 gap-4 text-i3'>
									<TextField
										label='Selected Surgeons'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(surgeons.selected.map(({ id }) => id))}
										isWorking={surgeons.isFetching}
									/>

									<TextField
										label='Applied Surgeons'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(surgeons.applied.map(({ id }) => id))}
										isWorking={surgeons.isFetching}
									/>
								</div>

								<div className='grid grid-cols-2 gap-4'>
									<TextField
										label='Selected Days of Week'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(daysOfWeek.selected)}
									/>

									<TextField
										label='Applied Days of Week'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(daysOfWeek.applied)}
									/>
								</div>

								<div className='grid grid-cols-2 gap-4 text-i3'>
									<TextField
										label='Selected Service Lines'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(serviceLines.selected.map(({ id }) => id))}
										isWorking={serviceLines.isFetching}
									/>

									<TextField
										label='Applied Service Lines'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(serviceLines.applied.map(({ id }) => id))}
										isWorking={serviceLines.isFetching}
									/>
								</div>

								<div className='grid grid-cols-2 gap-4 text-i3'>
									<TextField
										label='Selected Encounter Types'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(encounterTypes.selected.map(({ id }) => id))}
										isWorking={encounterTypes.isFetching}
									/>

									<TextField
										label='Applied Encounter Types'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(encounterTypes.applied.map(({ id }) => id))}
										isWorking={encounterTypes.isFetching}
									/>
								</div>

								<div className='grid grid-cols-2 gap-4 text-i3'>
									<TextField
										label='Selected Rooms'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(rooms.selected.map(({ id }) => id))}
										isWorking={rooms.isFetching}
									/>

									<TextField
										label='Applied Rooms'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(rooms.applied.map(({ id }) => id))}
										isWorking={rooms.isFetching}
									/>
								</div>

								<div className='grid grid-cols-2 gap-4 text-i3'>
									<TextField
										label='Selected Turnover Time Threshold'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(turnoverTimeThreshold.selected)}
									/>

									<TextField
										label='Applied Turnover Time Threshold'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(turnoverTimeThreshold.applied)}
									/>
								</div>

								<div className='grid grid-cols-2 gap-4 text-i3'>
									<TextField
										label='Selected FCOTS Grace Minutes'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(fcotsGraceMinutes.selected)}
									/>

									<TextField
										label='Applied FCOTS Grace Minutes'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(fcotsGraceMinutes.applied)}
									/>
								</div>

								<div className='grid grid-cols-2 gap-4 text-i3'>
									<TextField
										label='Selected Percentile'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(percentile.selected)}
									/>

									<TextField
										label='Applied Percentile'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(percentile.applied)}
									/>
								</div>

								<div className='grid grid-cols-2 gap-4'>
									<TextField
										label='Selected Primetime Setting'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(primetime.selected)}
									/>

									<TextField
										label='Applied Primetime Setting'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(primetime.applied)}
									/>
								</div>

								<div className='grid grid-cols-2 gap-4'>
									<TextField
										label='Selected Add-Ons Setting'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(addOns.selected)}
									/>

									<TextField
										label='Applied Add-Ons Setting'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(addOns.applied)}
									/>
								</div>

								<div className='grid grid-cols-2 gap-4'>
									<TextField
										label='Selected Utilization Type Setting'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(utilizationType.selected)}
									/>

									<TextField
										label='Applied Utilization Type Setting'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(utilizationType.applied)}
									/>
								</div>

								<div className='grid grid-cols-2 gap-4'>
									<TextField
										label='Selected Timeslot Type Setting'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(timeslotType.selected)}
									/>

									<TextField
										label='Applied Timeslot Type Setting'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(timeslotType.applied)}
									/>
								</div>

								<div className='grid grid-cols-2 gap-4'>
									<TextField
										label='Selected Block Timeslot Setting'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(blockTimeslot.selected)}
									/>

									<TextField
										label='Applied Block Timeslot Setting'
										className='font-[monospace]'
										readOnly
										value={JSON.stringify(blockTimeslot.applied)}
									/>
								</div>
							</div>
						</Accordion.Item>
					</Accordion>
				</section>
			</section>
		</div>
	);
}

export default StyleGuide;
