import { useState } from 'react';

import { Button, Datepicker, TextField } from 'components';

export function BlockStepThree() {
	const [date, setDate] = useState(new Date());
	return (
		<>
			<p className='font-bold text-p2 mb-10 text-blue-700'>Add New Block - Step 3</p>
			<hr></hr>
			<p className='font-bold mt-4 mb-6 w-80'>BLOCK NAME</p>
			<div className='w-80 mb-12'>
				<TextField label='BLOCK NAME' readOnly />
				<p className='italic text-gray-400 mt-2'>
					This was the original name of the selected block. You might want to reconsider naming the block if there was a
					meaningful change to the block members.
				</p>
			</div>
			<p className='font-bold mb-4'>BLOCK MEMBERS</p>
			<div className='flex mb-8 mr-12'>
				<p className='font-medium text-p1'>Surgeon A, Surgeon B, Surgeon C</p>
			</div>
			<p className='font-bold mb-4'>BLOCK PATTERNS</p>
			<div className='flex mb-12'>
				<p className='italic text-gray-400 w-28 mr-12'>These are all existing block patterns for the current block</p>
				<div className='border border-bluegray-100'>This is going to be where the pattern is shown</div>
			</div>
			<p className='font-bold mb-4'>EFFECTIVE DATE OF THESE CHANGES</p>
			<div className='flex'>
				<p className='italic text-gray-400 w-28 mr-12'>
					The date these changes should take effect in the schedule, as it relates to block metrics
				</p>
				<div>
					<Datepicker
						type='date'
						selected={date}
						onApply={(selected) => {
							setDate(selected);
						}}
					>
						<Button sizeX='sm' sizeY='sm' variant='primary'>
							Date Picker
						</Button>
					</Datepicker>
				</div>
			</div>
			<div className='flex'>
				<div className='w-80'></div>
				<div className='mt-40 ml-80 justifyContent: right'>
					<Button sizeX='sm' sizeY='sm' variant='primary'>
						Next
					</Button>
				</div>
			</div>
		</>
	);
}
