import { Close, Root, Title } from '@radix-ui/react-toast';
import classNames from 'classnames';
import { useRef } from 'react';

import type { ToastProps as RadixToastProps } from '@radix-ui/react-toast';

export interface ToastProps extends RadixToastProps {
	/** The visual treatment to apply to this toast. */
	variant?: 'info' | 'success' | 'warning' | 'error';

	/** The name of a Material Symbol to use as an icon for this toast. Pass `false` or an empty string to disable the icon. */
	icon?: string | false;

	/** Whether this toast's icon should use a filled version, if available. */
	fillIcon?: boolean;

	/** The title text to apply to this toast. */
	title: string;
}

/**
 * Generates a temporary notification that displays a short feedback message to
 * the user in response to an action they've taken.
 *
 * You probably don't want to use this component directly; consider using the
 * `createToast` method (imported from the `useToast` hook) to create a toast.
 */
export function Toast({ icon, fillIcon = true, title, variant = 'info', ...props }: ToastProps) {
	const toastRef = useRef<HTMLLIElement>(null);

	let defaultIcon;

	if (variant === 'info') {
		defaultIcon = 'info';
	} else if (variant === 'success') {
		defaultIcon = 'check_circle';
	} else if (variant === 'warning' || variant === 'error') {
		defaultIcon = 'warning';
	}

	icon = icon === false ? '' : icon || defaultIcon;

	return (
		<Root
			className={classNames(
				'flex items-center font-semibold text-p2 rounded-md px-3 mt-3 border',
				'data-open:animate-displayToast data-closed:animate-dismissToast',
				variant === 'info' && 'bg-blue-50 text-blue-600 border-blue-200',
				variant === 'success' && 'bg-green-100 text-green-800 border-green-300',
				variant === 'warning' && 'bg-yellow-50 text-yellow-800 border-yellow-200',
				variant === 'error' && 'bg-red-50 text-red-700 border-red-100'
			)}
			ref={toastRef}
			onOpenChange={(isOpen) => {
				// When dismissing a toast, capture its current height and margin as CSS
				// properties so they can be smoothly animated to zero.
				if (!isOpen && toastRef.current) {
					const currentStyle = getComputedStyle(toastRef.current);
					toastRef.current.style.setProperty('--toast-height', currentStyle.height);
					toastRef.current.style.setProperty('--toast-margin-top', currentStyle.marginTop);
				}
			}}
			{...props}
		>
			{icon && <span className={classNames('p-3', fillIcon ? 'material-symbol-fill' : 'material-symbol')}>{icon}</span>}
			<Title className='basis-full py-3 px-4 text-ellipsis overflow-hidden'>{title}</Title>
			<Close className='material-symbol p-3'>close</Close>
		</Root>
	);
}

export default Toast;
