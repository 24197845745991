import { ApiEndpoints } from 'models/api';
import { BlockSetting, FacilityBlockSetting } from 'models/models';
import { apiWithCore, standardApi } from 'store/api';
import { BlockPatternDetailsPOST } from 'pages/Block/components';

import { ShortOption } from './FilterService';

export interface BlockSettingsApiResponse {
	block_settings: BlockSetting;
}

export interface BlockSettingsApiRequest {
	block_settings: BlockSetting;
	healthsystem_id: number | null;
}

export interface CreateBlockRequest {
	healthsystem_id: number | null;
	serviceline_id: number | null;
	selected_block: ShortOption | undefined;
}

export interface BlockServicelineRequest {
	healthsystem_id: number | null;
	selected_block: ShortOption | undefined;
}

interface BlockPatternPreviewRequest {
	selected_block: ShortOption | null; // contains block_id
	window_start?: Date;
	window_end?: Date;
	facility_id?: number | null;
	healthsystem_id?: number | null;
	show_previous_only?: boolean;
}

export interface BlockPatternPreviewObject {
	day_of_week: string;
	week_of_month: number[];
	range: string;
	total_min: number;
	block_pattern_id: number;
	start_date: Date;
	end_date: Date;
	assigned_facility_id?: number;
}
export interface BlockPatternReleaseItem {
	date: string;
	block_id: number;
	block_pattern_id: number;
	updated_timestamp: number;
	released_minutes: number;
	facility_id: number;
}
export interface BlockPatternsApiResponse {
	releases: BlockPatternReleaseItem[];
}

export interface BlockPatternPreviewResponse {
	data: BlockPatternPreviewObject[];
}

const apiWithTags = apiWithCore.enhanceEndpoints({ addTagTypes: ['BlockSettings', 'System', 'BlockPatterns'] });

/**
 * Provides endpoints for retrieving and setting block settings
 */
export const blockSettingsApi = apiWithTags.injectEndpoints({
	endpoints: (build) => ({
		getBlockSettings: build.query<BlockSettingsApiResponse, { healthsystem_id?: number | null }>({
			query: ({ healthsystem_id }) => ({
				url: ApiEndpoints.GET_BLOCK_SETTINGS,
				params: { healthsystem_id: healthsystem_id !== null ? healthsystem_id : undefined },
			}),
			providesTags: ['BlockSettings'],
		}),
		setBlockSettings: build.mutation<BlockSettingsApiResponse, BlockSettingsApiRequest>({
			query: (body) => ({
				url: ApiEndpoints.SET_BLOCK_SETTINGS,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['BlockSettings', 'System'],
		}),
		setFacilityBlockSettings: build.mutation<FacilityBlockSetting, FacilityBlockSetting>({
			query: (body) => ({
				url: ApiEndpoints.SET_FACILITY_BLOCK_SETTINGS,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['BlockSettings', 'System'],
		}),
		addBlock: build.mutation<undefined, CreateBlockRequest>({
			query: (body) => ({
				url: ApiEndpoints.ADD_BLOCK,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Core'],
		}),
		editBlock: build.mutation<undefined, CreateBlockRequest>({
			query: (body) => ({
				url: ApiEndpoints.EDIT_BLOCK,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Core'],
		}),
		getBlockServiceLine: build.query<{ serviceline_id: number }, BlockServicelineRequest>({
			query: (body) => ({
				url: ApiEndpoints.GET_BLOCK_SERVICELINE,
				method: 'POST',
				body,
			}),
		}),
		getServiceLineName: build.query<{ serviceline_name: string }, { serviceline_id: number | undefined }>({
			query: (body) => ({
				url: ApiEndpoints.GET_SERVICE_LINE_NAME,
				method: 'POST',
				body,
			}),
		}),
		getBlockPatternPreview: build.query<BlockPatternPreviewResponse, BlockPatternPreviewRequest>({
			query: (body) => ({
				url: ApiEndpoints.GET_BLOCK_PATTERN_PREVIEW,
				method: 'POST',
				body,
			}),
			providesTags: ['BlockPatterns'],
		}),
		checkPatternCollision: build.mutation<{ collision: boolean; surgeons: string[] }, BlockPatternDetailsPOST>({
			query: (body) => ({
				url: ApiEndpoints.CHECK_PATTERN_COLLISION,
				method: 'POST',
				body,
			}),
		}),
		addBlockPattern: build.mutation<{ success: boolean }, BlockPatternDetailsPOST | null>({
			query: (body) => ({
				url: ApiEndpoints.ADD_BLOCK_PATTERN,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['BlockPatterns'],
		}),
		getBlockPattern: build.mutation<BlockPatternDetailsPOST, { block_pattern_id: number | undefined }>({
			query: (body) => ({
				url: ApiEndpoints.GET_BLOCK_PATTERN,
				method: 'POST',
				body,
			}),
		}),
		getBlockPatternReleases: build.query<BlockPatternsApiResponse, { healthsystem_id: number | null }>({
			query: (body) => ({
				url: ApiEndpoints.GET_BLOCK_PATTERN_RELEASES,
				method: 'POST',
				body,
			}),
			providesTags: ['BlockPatterns'],
		}),
		editBlockPattern: build.mutation<{ success: boolean }, BlockPatternDetailsPOST | null>({
			query: (body) => ({
				url: ApiEndpoints.EDIT_BLOCK_PATTERN,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['BlockPatterns'],
		}),
		deleteBlockPattern: build.mutation<{ success: boolean }, { block_pattern_id: number | null }>({
			query: (body) => ({
				url: ApiEndpoints.DELETE_BLOCK_PATTERN,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['BlockPatterns'],
		}),
		endBlockPattern: build.mutation<
			{ success: boolean },
			{ block_pattern_id: number | null; effective_date: Date | string }
		>({
			query: (body) => ({
				url: ApiEndpoints.END_BLOCK_PATTERN,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['BlockPatterns'],
		}),
	}),
});

export const {
	useGetBlockSettingsQuery,
	useSetBlockSettingsMutation,
	useSetFacilityBlockSettingsMutation,
	useAddBlockMutation,
	useEditBlockMutation,
	useGetBlockServiceLineQuery,
	useGetBlockPatternPreviewQuery,
	useGetServiceLineNameQuery,
	useCheckPatternCollisionMutation,
	useAddBlockPatternMutation,
	useGetBlockPatternMutation,
	useEditBlockPatternMutation,
	useDeleteBlockPatternMutation,
	useEndBlockPatternMutation,
	useGetBlockPatternReleasesQuery,
} = blockSettingsApi;

export const {
	endpoints: {
		getBlockSettings,
		setBlockSettings,
		setFacilityBlockSettings,
		addBlock,
		editBlock,
		getBlockServiceLine,
		getBlockPatternPreview,
		getServiceLineName,
		checkPatternCollision,
		addBlockPattern,
		editBlockPattern,
		deleteBlockPattern,
		endBlockPattern,
		getBlockPatternReleases: getBlockPatterns,
	},
} = blockSettingsApi;
