import { useEffect, useState } from 'react';
import classNames from 'classnames';

import type { HTMLAttributes } from 'react';

interface CodeBlockProps extends HTMLAttributes<HTMLDivElement> {
	children: string;
}

function CodeBlock({ children, className }: CodeBlockProps) {
	const [formattedChildren, setFormattedChildren] = useState(children);

	// Normalize the indentation of entered text by using the first line's spacing
	// as an indicator for how much to reduce the spacing on subsequent lines.
	useEffect(() => {
		const lines = children.split('\n');

		// Ignore the first line if empty.
		if (lines[0] === '') lines.shift();

		let matches;
		const indentation = (matches = /^\s+/.exec(lines[0])) !== null ? matches[0] : null;

		if (!indentation) return;

		const unindentedLines = lines.map((line) => line.replace(indentation, ''));
		setFormattedChildren(unindentedLines.join('\n').trim());
	}, [children]);

	return (
		<div className={classNames('bg-bluegray-900 text-gray-50 rounded overflow-x-auto', className)}>
			<pre className='inline-block p-4 [tab-size:2]'>
				<code>{formattedChildren}</code>
			</pre>
		</div>
	);
}

export { CodeBlock, CodeBlock as default };
