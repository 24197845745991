import { LocalStorageKey, LocalStorageState, merlinStorageKey } from 'models/storage';

/**
 * Simple utility to retrieve values that are saved in local storage.
 * @param key the key of the value saved
 * @returns the value of the cached variable
 */
export function getFromLocalStorage(key: LocalStorageKey): LocalStorageState[keyof LocalStorageState] | undefined {
	const attempt = JSON.parse(localStorage.getItem(merlinStorageKey) ?? '{}');
	if (key in attempt) return attempt[key];
	return undefined;
}

/**
 * Simple utility to set values in local storage.
 * @param key the key of the value being saved
 * @param value the data being saved
 * @returns the value of the local storage state
 */
export function writeToLocalStorage(key: LocalStorageKey, value: unknown): LocalStorageState {
	const attempt = JSON.parse(localStorage.getItem(merlinStorageKey) ?? '{}');
	attempt[key] = value;
	localStorage.setItem(merlinStorageKey, JSON.stringify(attempt));
	return attempt;
}

/**
 * Simple utility to delete values in local storage.
 * @param key the key of the value being deleted
 * @returns the value of the local storage state after the deletion
 */
export function deleteFromLocalStorage(key: LocalStorageKey): LocalStorageState {
	const attempt = JSON.parse(localStorage.getItem(merlinStorageKey) ?? '{}');
	delete attempt[key];
	localStorage.setItem(merlinStorageKey, JSON.stringify(attempt));
	return attempt;
}
