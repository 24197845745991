import { createContext, useContext, useReducer } from 'react';
import { Provider } from '@radix-ui/react-toast';

import { Toast } from 'components';

import type { ToastProps } from 'components';
import type { ToastProviderProps } from '@radix-ui/react-toast';

interface ToastContextInterface {
	toasts: ReturnType<typeof Toast>[];
	createToast: ({ title, ...props }: ToastProps) => void;
}

const ToastContext = createContext<ToastContextInterface>({} as ToastContextInterface);

const initialToasts: ToastContextInterface['toasts'] = [];

function reducer(currentToasts: typeof initialToasts, newToastProps: ToastProps) {
	const newToast = <Toast {...newToastProps} key={Date.now()} />;

	return [...currentToasts, newToast];
}

export function ToastProvider({ children, ...props }: ToastProviderProps) {
	const [toasts, createToast] = useReducer(reducer, initialToasts);

	return (
		<Provider {...props}>
			<ToastContext.Provider value={{ toasts, createToast }}>
				{children}
				{toasts.map((toast) => toast)}
			</ToastContext.Provider>
		</Provider>
	);
}

export const useToast = () => useContext(ToastContext);

export default ToastProvider;
