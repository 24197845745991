import { Button } from 'components';
import { ReactComponent as Icon } from 'assets/images/signal_searching.svg';

interface LoadingScreenProps {
	message?: string;
	offlinePage?: boolean;
	setConnected?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function LoadingScreen({ message = '', offlinePage = false, setConnected }: LoadingScreenProps) {
	return (
		<div className='flex flex-col content-center h-screen'>
			<div className='m-auto'>
				{message && <p className='animate-pulse'>{message}</p>}
				{offlinePage && (
					<div className='bg-gray-50 rounded text-center p-12'>
						<Icon className='my-16 block max-h-80' />
						<h1 className='text-h4'>Connect to the internet</h1>
						<p className='text-p w-lg leading-6 mt-2 mb-12'>You&apos;re offline. Check your connection.</p>
						<Button
							className='mx-auto'
							sizeX='sm'
							sizeY='md'
							variant='primary-ghost'
							onClick={() => {
								navigator.onLine && setConnected && setConnected(false);
							}}
						>
							<span className='material-symbols-outlined'>refresh</span>RETRY
						</Button>
					</div>
				)}
			</div>
		</div>
	);
}

export default LoadingScreen;
