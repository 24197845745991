import { MappingInput, NewRoomData } from 'pages/Settings';
import { DataTable } from 'components';
import { ShortOption } from 'store';

interface NewRoomTableProps {
	data: NewRoomData[];
	saveRoomAs: (fromRoom: number, toRoom: number) => void;
	roomOptions: ShortOption[];
}

export function NewRoomTable({ data, saveRoomAs, roomOptions }: NewRoomTableProps) {
	return (
		<DataTable
			title='New Rooms'
			tooltipContent={
				'Use this table to validate the accuracy of newly found room names. Small, hard to notice changes like a missing comma or an extra space can affect how data are inserted into Merlin. We developed a similarity score so you can easily map a newly found name to an existing room name.'
			}
			goToHelpID='newrooms'
			minHeight={'28em'}
			columns={[
				{
					header: 'Imported As',
					accessorKey: 'imported_as',
					cell: ({ row }) => <span className='py-1 text-p2 text-center font-semibold'>{row.original.imported_as.name}</span>,
				},
				{
					header: 'Date Found',
					accessorKey: 'date_found',
					cell: ({ row }) => (
						<span className='py-1 text-p2 text-center'>
							{new Date(row.original.date_found).toLocaleString('en-US', {
								month: 'numeric',
								day: 'numeric',
								year: '2-digit',
								hour: 'numeric',
								minute: 'numeric',
								hour12: true,
							})}
						</span>
					),
					sortingFn: (rowA, rowB, columnId) => {
						const a = new Date(rowA.getValue(columnId)).getTime();
						const b = new Date(rowB.getValue(columnId)).getTime();
						return b > a ? 1 : -1;
					},
					enableGlobalFilter: false,
				},
				{
					header: 'Map to Existing Room Name',
					accessorKey: '',
					cell: ({ row }) => (
						<MappingInput
							allOptions={roomOptions}
							save={(selection) => {
								saveRoomAs(row.original.imported_as.id, selection.id);
							}}
							createText={''}
						/>
					),
					enableGlobalFilter: false,
				},
			]}
			data={data}
		/>
	);
}

export default NewRoomTable;
