/**
 * Limits a given number to within an upper and lower bound.
 *
 * @param value The given number to apply bounds to.
 * @param min The lower bound to apply to the given number.
 * @param max The upper bound to apply to the given number.
 * @returns The given number bounded to a value between `min` and `max`.
 */
export function clamp(value: number, min: number, max: number) {
	return Math.max(min, Math.min(value, max));
}
