import { env } from 'utils/env';
import logo from 'assets/images/branding/logo.svg';

interface AuthPageProps {
	children: React.ReactNode;
}

export function AuthPage({ children }: AuthPageProps) {
	return (
		<div className='h-screen grid grid-cols-2 grid-rows-[1fr_auto_1fr]'>
			<div className='bg-merlinBlue'></div>

			<div></div>

			<div className='bg-merlinBlue flex flex-row-reverse'>
				<div className='flex flex-row-reverse w-full items-center max-w-[34rem]'>
					<div className='my-auto pt-7 px-8 w-full max-w-lg text-center'>
						<img className='m-auto text-center' src={logo} alt='The Merlin logo.' />
						<h1 className='mt-7 pb-7 font-semibold text-center text-h2'>Merlin</h1>
						{env('SHOW_ARTICLE', true) && (
							<aside className='mt-7'>
								<div className='font-semibold text-p3 text-bluegray-800 text-center tracking-wider uppercase '>
									{env('BLOG_HEADLINE')}
								</div>

								<article className='mt-8 py-3 px-4 bg-blue-500/10 text-left'>
									<div>{env('ARTICLE_TITLE')}</div>

									<div className='mt-3 flex'>
										<div className='text-p3 text-gray-600'>Posted {env('ARTICLE_DATE')}</div>
										<a href={env('BLOG_URL')} className='ml-auto text-p2'>
											{env('BLOG_LINK_TEXT')} &rarr;
										</a>
									</div>
								</article>
							</aside>
						)}
					</div>
				</div>
			</div>

			{children}
		</div>
	);
}

export default AuthPage;
