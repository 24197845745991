import * as RadixDialog from '@radix-ui/react-dialog';
import classNames from 'classnames';
import { useState } from 'react';

import { Button, ButtonStack, Tooltip } from 'components';
import { FilterMetadata, useFilters } from 'context';
import FiltersModal from 'components/FiltersModal';
import SaveView from 'components/SaveView/SaveView';
import { truncateLabel } from 'utils';

import type { ButtonHTMLAttributes } from 'react';
import type { ButtonProps } from 'components';
import type { DialogProps as RadixDialogProps } from '@radix-ui/react-dialog';

export interface DrawerProps extends RadixDialogProps {
	/** The title text to display at the top of this drawer and information to display in badge */
	metadata: FilterMetadata;

	/** The element to use to trigger the opening of this drawer. */
	trigger: React.ReactNode;

	/** An array of prop objects representing additional quick actions, or icon-style buttons, to include at the top of this drawer alongside the "close" button. */
	quickActions?: DrawerQuickAction[];

	/** An array of `<Button>` prop objects representing actions to include at the bottom of this drawer. */
	actionButtons?: Omit<ButtonProps, 'sizeX' | 'sizeY' | 'squareCorners'>[];

	/** A function resolving to whether the drawer should be prevented from closing. */
	preventClose?: () => boolean | Promise<boolean>;

	filtersAreDirty?: boolean;
}

interface DrawerQuickAction extends ButtonHTMLAttributes<HTMLButtonElement> {
	/** The Material Symbol icon to display for this quick action. */
	icon: string;

	/** Whether this quick action's icon should use a filled version, if available. */
	fillIcon?: boolean;

	/** The tooltip text to apply to this quick action. */
	tooltipText: string;
}

/**
 * A content panel that slides out from the side of the screen.
 */
export function Drawer({
	metadata,
	trigger,
	quickActions,
	actionButtons,
	preventClose,
	open,
	onOpenChange,
	children,
	filtersAreDirty,
	...props
}: DrawerProps) {
	const [isOpen, setIsOpen] = useState(open);
	const { updateSavedView } = useFilters();

	return (
		<RadixDialog.Root
			open={isOpen}
			onOpenChange={async (isOpen) => {
				// Execute the default `onOpenChange` function, if provided.
				if (onOpenChange) onOpenChange(isOpen);

				// Prevent this drawer from closing if the `preventClose` function is
				// provided and resolves to `true`.
				if (!isOpen && preventClose && (await preventClose())) {
					return;
				}

				setIsOpen(isOpen);
			}}
			{...props}
		>
			{trigger && <RadixDialog.Trigger asChild>{trigger}</RadixDialog.Trigger>}

			<RadixDialog.Portal>
				<RadixDialog.Overlay
					className={classNames(
						'fixed inset-0 bg-black/40 z-drawer',
						'data-open:animate-displayOverlay data-closed:animate-dismissOverlay'
					)}
				/>

				<RadixDialog.Content
					className={classNames(
						'flex flex-col fixed top-0 right-0 w-72 h-full z-drawer',
						'bg-white border-l border-gray-300',
						'data-open:animate-displayDrawer data-closed:animate-dismissDrawer'
					)}
				>
					<div className='flex items-center gap-3 h-20 py-2 pl-6 pr-4 border-b border-inherit'>
						<RadixDialog.Title className='text-h5 font-semibold'>
							<div>
								{metadata ? (
									<div>
										<div className='text-p3 h-fit flex items-center mb-1'>{truncateLabel(metadata.title ?? 'Filters', 36)}</div>
										{metadata.saved_at && (
											<div className='flex align-middle absolute'>
												{/* <div className='font-secondary text-[0.55em] text-white bg-green-600 rounded-sm text-center px-1 pb-0.5 whitespace-nowrap'>
													Applied
												</div> */}
												<div className='font-secondary text-[0.55em] text-gray-300 whitespace-nowrap text-center'>
													<span className='pr-1'>[Created]</span>
													<span>
														{new Date(metadata.saved_at).toLocaleString('en-US', {
															month: 'numeric',
															day: 'numeric',
															year: '2-digit',
															hour: 'numeric',
															minute: 'numeric',
															hour12: true,
														})}
													</span>
												</div>
											</div>
										)}
									</div>
								) : (
									'Filters'
								)}
							</div>
						</RadixDialog.Title>

						<div className='ml-auto flex'>
							{quickActions &&
								quickActions.map(({ icon, fillIcon = false, tooltipText, className, ...props }, i) => (
									<button
										key={i}
										className={classNames('p-2', props.disabled && 'text-gray-400 cursor-not-allowed', className)}
										{...props}
									>
										<Tooltip content={tooltipText} triggerType='div' disableHelpCursor>
											<div
												className={classNames({
													'material-symbol-fill': fillIcon,
													'material-symbol': !fillIcon,
												})}
											>
												{icon}
											</div>
										</Tooltip>
									</button>
								))}

							<RadixDialog.Close className='p-2'>
								<Tooltip content='Close drawer' triggerType='div' disableHelpCursor>
									<div className='material-symbol'>close</div>
								</Tooltip>
							</RadixDialog.Close>
						</div>
					</div>

					<div className='basis-full overflow-y-auto mb-4'>{children}</div>

					{actionButtons && (
						<div className='flex flex-row-reverse mt-auto'>
							{actionButtons.map(({ variant, children, ...props }, i) => {
								// TODO: The 2nd button and beyond will eventually be reimplemented using a "button menu" UI pattern;
								// for now, they'll just line up on the bottom of the drawer.
								if (!variant) {
									if (i === 0) {
										variant = 'secondary';
									} else if (i === 1) {
										variant = 'primary';
									} else {
										variant = 'primary-ghost';
									}
								}

								if (children === 'Views') {
									return (
										<ButtonStack key={i} inverted>
											<Button className='w-[10em]' sizeX='md' disabled={!metadata.saved_at || filtersAreDirty} variant={variant}>
												{metadata?.saved_at && !filtersAreDirty ? (
													<SaveView>
														<Button sizeX='md' variant={variant} squareCorners {...props}>
															Save View
														</Button>
													</SaveView>
												) : (
													<span className='w-[10em]'>Save View</span>
												)}
											</Button>
											<Button
												onClick={() => {
													updateSavedView();
												}}
												className='w-[10em]'
												sizeX='md'
												disabled={!metadata.is_branched_filter}
												variant={variant}
											>
												Update View
											</Button>
											<Button variant={variant} className='w-[10em]'>
												<FiltersModal>
													<Button sizeX='md' variant={variant} squareCorners {...props}>
														{children}
													</Button>
												</FiltersModal>
											</Button>
										</ButtonStack>
									);
								} else {
									return (
										<Button key={i} sizeX='md' variant={variant} squareCorners {...props}>
											{children}
										</Button>
									);
								}
							})}
						</div>
					)}
				</RadixDialog.Content>
			</RadixDialog.Portal>
		</RadixDialog.Root>
	);
}

export default Drawer;
