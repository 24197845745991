import classNames from 'classnames';

import { Breadcrumbs } from 'components';

interface PageHeadingProps {
	/** Optional css classes to apply to the <h1> tag. */
	className?: string;

	/** The text to render. */
	children: React.ReactNode;

	/** Uses URL to create breadcrumbs */
	useURL?: boolean;
}

/**
 * A reusable heading to be used for consistency throughout the application.
 */
export function PageHeading({ children, className, useURL }: PageHeadingProps) {
	return (
		<div>
			<Breadcrumbs useURL={useURL} />
			<h1 className={classNames('text-h3 mb-8', className)}>{children}</h1>
		</div>
	);
}

export default PageHeading;
