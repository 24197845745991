import React, { useCallback, useEffect, useState } from 'react';
import {
	LineSegment,
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryLabel,
	VictoryStack,
	VictoryTooltip,
	VictoryZoomContainer,
} from 'victory';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { truncate } from 'cypress/types/lodash';
import { nonWhiteSpace } from 'html2canvas/dist/types/css/syntax/parser';

import Logo from 'assets/images/branding/logo.svg';
import {
	border,
	dayEndMinute,
	dayStartMinute,
	defaultZoomState,
	fontFamily,
	hourAxisTicks,
	timelineChartLegendOptions,
	truncateLabel,
} from 'utils';
import {
	Button,
	ButtonLink,
	ButtonStack,
	ChartLegend,
	DateToggle,
	Drawer,
	ExportButton,
	FilterFields,
	LogoOverlay,
	PageHeading,
	Panel,
	ToggleGroup,
	Tooltip,
} from 'components';
import { ShortOption, TimelineEvent, useAppSelector, useGetRoomTurnoverQuery, useSystem } from 'store';
import { useFilters, useToast } from 'context';

import type { FlattenedTimelineData } from 'utils';

class CustomFlyout extends React.Component<{
	type: string;
	surgeon?: string;
	procedure?: string;
	x?: number;
	y?: number;
	datum?: { metadata: string; xName: string };
}> {
	render() {
		const { x, y, datum, type, surgeon, procedure } = this.props;
		const isSchedule = datum?.xName?.includes('_schedule');
		return (
			<foreignObject x={x} y={y} width='100%' height='100%' className='overflow-visible'>
				<div className='bg-white flex flex-col drop-shadow-md w-20'>
					<div className='bg-white p-1 px-3 pl-1'>
						<p className='text-left text-[0.27em] font-semibold'>{datum?.xName?.replace('_schedule', '')}</p>
					</div>
					{type === 'fcots_delay' && (
						<div className='bg-blue-900 p-1 flex justify-between'>
							<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>FCOTS Delay</p>
							<p className='text-right text-[0.25em] text-white'>{datum?.metadata}</p>
						</div>
					)}

					{(type === 'case' || type === 'case_schedule') && (
						<>
							<div className='bg-blue-900 p-1 flex justify-between items-center'>
								<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white w-3/4'>Surgeon</p>
								<p className='text-right text-[0.25em] text-white'>{surgeon}</p>
							</div>
							<div className='bg-blue-900 p-1 flex justify-between items-center'>
								<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white w-3/4'>Procedure</p>
								<p className='text-right text-[0.25em] text-white'>{truncateLabel(procedure ?? '', 40)}</p>
							</div>
							<div className='bg-blue-900 p-1 flex justify-between'>
								<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white'>Case Time</p>
								<p className='text-right text-[0.25em] text-white'>{datum?.metadata}</p>
							</div>
						</>
					)}

					{type === 'turnover' && (
						<div className='bg-blue-900 p-1 flex justify-between items-center'>
							<p className='text-left text-[0.25em] font-semibold text-white w-1/2'>
								{isSchedule ? 'Estimated Turnover' : 'Turnover Under Target'}
							</p>
							<p className='text-right text-[0.25em] text-white'>{datum?.metadata}</p>
						</div>
					)}

					{type === 'turnover_warning' && (
						<div className='bg-blue-900 p-1 flex justify-between items-center'>
							<p className='text-left text-[0.25em] font-semibold text-white w-1/3'>Turnover Warning</p>
							<p className='text-right text-[0.25em] text-white'>{datum?.metadata}</p>
						</div>
					)}

					{type === 'case_addon' && (
						<>
							<div className='bg-blue-900 p-1 flex justify-between items-center'>
								<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white w-3/4'>Surgeon</p>
								<p className='text-right text-[0.25em] text-white'>{surgeon}</p>
							</div>
							<div className='bg-blue-900 p-1 flex justify-between items-center'>
								<p className='whitespace-nowrap text-left text-[0.25em] font-semibold text-white w-3/4'>Procedure</p>
								<p className='text-right text-[0.25em] text-white'>{procedure}</p>
							</div>
							<div className='bg-blue-900 p-1 flex justify-between items-center'>
								<p className='text-left text-[0.25em] font-semibold text-white'>Add-on</p>
								<p className='text-right text-[0.25em] text-white'>{datum?.metadata}</p>
							</div>
						</>
					)}
				</div>
			</foreignObject>
		);
	}
}

class CustomTicker extends React.Component<{
	x?: number;
	y?: number;
	text?: any;
	is_surgeon?: boolean;
	only_schedule_timelines?: boolean;
}> {
	render() {
		const { x, y, text, only_schedule_timelines } = this.props;
		const formatted_text = truncateLabel(only_schedule_timelines ? `${text}`.replace('_schedule', '') : `${text}`, 11);
		const xOffset = 30;
		return (
			<>
				{!text || only_schedule_timelines ? (
					<foreignObject x={(x ?? 0) - xOffset} y={(y ?? 0) - 8.5} width='100%' height='100%' className={`overflow-visible`}>
						<div className='bg-purple-50 whitespace-pre-line w-fit py-0.5 px-1 rounded-[0.2em] text-[0.3em] text-left text-black'>
							{only_schedule_timelines ? `${formatted_text}` : 'Scheduled'}
						</div>
					</foreignObject>
				) : (
					<foreignObject x={(x ?? 0) - xOffset} y={(y ?? 0) + 1} width='100%' height='100%' className={`overflow-visible`}>
						<div className='bg-gray-50 w-fit py-0.5 px-1 whitespace-pre-line rounded-[0.2em] text-[0.3em] text-left text-black'>
							{formatted_text}
						</div>
					</foreignObject>
				)}
			</>
		);
	}
}

interface StringDict {
	[key: string]: string | undefined;
}

interface NumberDict {
	[key: string]: number;
}

interface TimelineProps {
	hideFilters?: boolean;
	hideDatePicker?: boolean;
	parentDate?: string;
	parentRooms?: ShortOption[];
	parentSurgeons?: string[];
}

export function Timeline({ hideFilters, hideDatePicker, parentDate, parentRooms, parentSurgeons }: TimelineProps) {
	const { selectedSystem, selectedFacility } = useAppSelector((state) => state.userState);
	const { data: userData } = useSystem();
	const system = userData?.healthsystems.find((h) => h.id === selectedSystem);
	const current_facility = userData?.facilities?.find((f) => f.id === selectedFacility);
	const has_scheduled = current_facility?.has_scheduled ?? false;

	// flattened API response dataset for export
	const [csvDataset, setCsvDataset] = useState<FlattenedTimelineData[]>([]);
	const [zoomState, setZoomState] = useState({ y: defaultZoomState });
	const zoomStateRange = Math.abs(zoomState.y[1] - zoomState.y[0]);

	// handle schedule display
	const [shownLines, setShownLines] = useState<string[]>(has_scheduled ? ['actual', 'schedule'] : ['actual']);

	const {
		date,
		fcotsGraceMinutes,
		turnoverTimeThreshold,
		rooms,
		procedures,
		endDate,
		resetFilters,
		applyFilters,
		clearFilters,
		currentPageLoaded,
		filtersAreDirty,
		metadata,
	} = useFilters();

	// There is sometimes a delay in our filters when a user switches pages
	// (which is why we check if currentPageLoaded is equal to our current page),
	// To account for the delay, we tell our RTK Query to skip until we set skipRequest to false.
	const [skipRequest, setSkipRequest] = useState(parentDate ? false : true);
	useEffect(() => {
		setTimeout(() => {
			if (currentPageLoaded === '/room-turnover') {
				setSkipRequest(false);
			}
		}, 0);
	}, [currentPageLoaded]);

	// Used to hide certain elements printing purposes
	const [searchParams] = useSearchParams();
	const printable = searchParams.get('printable') === 'true' ?? false;

	const { data, isFetching, error } = useGetRoomTurnoverQuery(
		{
			facility_id: selectedFacility,
			healthsystem_id: selectedSystem,
			filters: {
				date: parentDate
					? parentDate
					: date?.applied
							.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' })
							.replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2'),
				turnover_threshold: turnoverTimeThreshold?.applied,
				fcots_grace_minutes: fcotsGraceMinutes?.applied,
				rooms: parentRooms ? parentRooms : rooms?.applied,
				surgeons: parentSurgeons,
				procedures: procedures?.applied.map((procedure) => procedure.name),
			},
			useCached: !printable ? false : true,
		},
		{
			skip: skipRequest,
		}
	);

	// Handle api response to create toast message on every error.
	const { createToast } = useToast();
	useEffect(() => {
		if (error) {
			if ('data' in error) {
				createToast({
					title: `${error.data}`,
					variant: 'error',
				});
			} else {
				createToast({
					title: `There was an error connecting to the server.`,
					variant: 'error',
				});
			}
		}
	}, [createToast, error]);
	// the charting library has a jagged render when bringing in new data, so we keep
	// the state below to show our loading indicator a few ms longer than its actually loading to cover that up
	const [delayedFetching, setDelayedFetching] = useState(isFetching);
	// set events to data from API or empty
	const arrayToSort = data?.events ?? [];
	const events = [...arrayToSort]
		.sort((a, b) => (a.data[0].x > b.data[0].x ? -1 : b.data[0].x > a.data[0].x ? 1 : 0))
		.filter((event) => {
			if (shownLines.includes('schedule') && shownLines.includes('actual')) {
				return true;
			} else if (shownLines.includes('schedule')) {
				return event.details?.room?.includes('_schedule') ?? false;
			} else if (shownLines.includes('actual')) {
				return !event.details?.room?.includes('_schedule') ?? false;
			}
		});

	// Groups all events by their x-value (which corresponds to the room in which
	// they took place), filtering out any "none" events.
	const eventsByX =
		events.reduce<Record<string, typeof events>>((groupedEvents, event) => {
			const {
				event_type,
				data: [{ x }],
			} = event;

			if (event_type === 'none') return groupedEvents;

			if (!Object.hasOwn(groupedEvents, x)) {
				groupedEvents[x] = [];
			}

			groupedEvents[x].push(event);

			return groupedEvents;
		}, {}) ?? {};

	// Create batch for each page in printable mode
	let eventsByXBatches = [];
	const batchSize = 12;
	if (printable) {
		const chunk_size = batchSize;
		for (const cols = Object.entries(eventsByX).reverse(); cols.length; ) {
			const colsArr = cols.splice(0, chunk_size);

			if (colsArr.length < batchSize) {
				const oldLen = batchSize - colsArr.length;
				for (let i = 0; i < oldLen; i++) {
					colsArr.push([
						`temp$_${i}`,

						[
							{
								data: [
									{
										metadata: '0',
										surgeon: '',
										x: `temp$_${i}`,
										y: 0,
										y0: 0,
									},
								],
								details: {
									room: `temp$_${i}`,
									surgeon: '',
									time: '0',
								},
								event_type: 'case',
								procedure: '0',
								surgeon: '',
								title: '0',
								color: '#000000',
							},
						],
					]);
				}
			}

			// This is just code to stop surgeon labels from overlapping in printable mode
			const clonedArray = JSON.parse(JSON.stringify(colsArr)) as typeof colsArr;
			const rowSurgeons: StringDict = {};
			const rowLabel: NumberDict = {};
			for (let i = 0; i < colsArr.length; i++) {
				if (colsArr[i][1].length > 0) {
					for (let j = 0; j < colsArr[i][1].length; j++) {
						if (colsArr[i][1][j].surgeon && colsArr[i][1][j].data.length > 0) {
							for (let k = 0; k < colsArr[i][1][j].data.length; k++) {
								if (rowSurgeons[clonedArray[i][0]] !== clonedArray[i][1][j].data[k].surgeon) {
									rowSurgeons[clonedArray[i][0]] = clonedArray[i][1][j].data[k].surgeon;

									if (rowLabel[clonedArray[i][0]]) {
										const last_valid_index = k - 1 > -1 ? k - 1 : 0;
										const previous_name_length = (clonedArray[i][1][j].data[last_valid_index].surgeon?.length ?? 0) + 10;

										const previous = clonedArray[i][1][j].data[k].y0 - rowLabel[clonedArray[i][0]] + previous_name_length;

										if (previous < 250) {
											clonedArray[i][1][j].data[k].distance_from_last_event = 25;
										}
									}
									rowLabel[clonedArray[i][0]] =
										clonedArray[i][1][j].data[k].y0 + (clonedArray[i][1][j].data[k].surgeon?.length ?? 0);
								} else {
									clonedArray[i][1][j].data[k].surgeon = '';
								}
							}
						}
					}
				}
			}

			const batch = clonedArray.reverse().reduce((o: Record<string, TimelineEvent[]>, [k, v]) => ((o[k] = v), o), {});

			eventsByXBatches.push(batch);
		}
	} else {
		eventsByXBatches = [eventsByX];
	}

	// when isFetching is switching to true, immediately show the loading indicator
	// when isFetching is switching to false, wait 250ms after done loading to hide the indicator
	useEffect(() => {
		if (isFetching) {
			setDelayedFetching(isFetching);
		} else {
			setTimeout(() => {
				setDelayedFetching(isFetching);
			}, 250);
		}
	}, [isFetching, setDelayedFetching]);

	// zoom in by 1 hour on either side of the chart
	const handleClickZoomIn = useCallback(() => {
		const [start, end] = zoomState.y;
		const newStart = Math.max(start + 60, 0);
		const newEnd = Math.min(end - 60, 1440);
		setZoomState({ y: [newStart, newEnd] });
	}, [zoomState, setZoomState]);

	// un-magnify by 1 hour on either side of the chart
	const handleClickZoomOut = useCallback(() => {
		const [start, end] = zoomState.y;
		let newStart = start - 60;
		let newEnd = end + 60;
		if (newStart <= 0) newStart = 0;
		if (newEnd >= 1440) newEnd = 1440;
		setZoomState({ y: [newStart, newEnd] });
	}, [zoomState, setZoomState]);

	// when we get new data from the API, create the downloadable file
	useEffect(() => {
		if (data?.events && data.events.length > 0) {
			// create a flat-map of the events, so that it's exportable to CSV
			const filteredEvents = data?.events.filter((e) => e.event_type !== 'none');
			setCsvDataset(
				filteredEvents.map(({ event_type, details, data: [firstDataItem] }) => ({
					event_type,
					...firstDataItem,
					...details,
				}))
			);
		}
	}, [data]);

	// reset to default state, same as on a page refresh
	const handleClickReset = useCallback(() => {
		setZoomState({ y: defaultZoomState });
	}, [setZoomState]);

	// determine the font sizes of the axis labels/ticks by checking the zoom-level and the window width
	let roomAxisLabelBaseFontSize: number;
	let timeAxisLabelBaseFontSize: number;

	if (zoomStateRange < 720) {
		roomAxisLabelBaseFontSize = 6;
		timeAxisLabelBaseFontSize = 6;
	} else if (zoomStateRange < 1200) {
		roomAxisLabelBaseFontSize = 6;
		timeAxisLabelBaseFontSize = 5;
	} else {
		roomAxisLabelBaseFontSize = 6;
		timeAxisLabelBaseFontSize = 4.5;
	}

	const roomAxisStyles = {
		axis: { stroke: border },
		tickLabels: {
			fontFamily: fontFamily,
			fontSize: roomAxisLabelBaseFontSize - 2,
		},
	};

	const timeAxisStyles = {
		axis: { stroke: border },
		grid: { stroke: border },
		ticks: { stroke: border, size: 5 },
		tickLabels: { fontFamily: fontFamily, fontSize: timeAxisLabelBaseFontSize, padding: 5 },
	};

	const minLimit = new Date(`${endDate.getFullYear() - 5}-Jan`);
	const arr = data?.events ? Array.from(new Set(data.events.map((value) => value.details.room ?? ''))) : [];
	const avg = arr && Math.round(arr.reduce((r, c) => r + c.length, 0) / arr.length);
	const regx = new RegExp('.{1,avg}'.replace('avg', avg > 20 ? avg.toString() : '8'), 'g');

	// Checks if printable page version, then print
	useEffect(() => {
		const generatePDF = async () => {
			// Get the desired element to be captured
			const element = document.getElementById('page-container');

			if (element) {
				// Calculate the number of pages needed for the content
				const numberOfPages = eventsByXBatches.length + 1; // + 1 is for filters

				// Create a new jsPDF instance
				const doc = new jsPDF('p', 'pt', 'letter');

				// Iterate through each page and render the content
				for (let i = 0; i < numberOfPages; i++) {
					const graph = document.getElementById(`graph-${i}`);

					if (graph) {
						// Render the content in a canvas
						const canvas = await html2canvas(graph, {
							scale: 2,
						});

						// Add the canvas image to the PDF
						const imgData = canvas.toDataURL('image/png');
						if (i > 0) {
							doc.addPage();
						}
						doc.addImage(imgData, 'PNG', 0, 0, 595, 770);
					}
				}

				// Save the generated PDF
				doc.save('timeline-view.pdf');
			}
			window.close();
		};

		if (printable && data && data?.events && data.events.length > 0 && !(delayedFetching || skipRequest || isFetching)) {
			setTimeout(() => {
				generatePDF();
			}, 0);
		}
	}, [data, delayedFetching, eventsByXBatches.length, isFetching, printable, skipRequest]);

	const updateShownLines = (option: string) => {
		if (!shownLines.includes(option)) {
			setShownLines([...shownLines, option]);
		} else {
			setShownLines(shownLines.filter((item) => item !== option));
		}
	};

	const only_schedule_timelines_shown = Object.keys(eventsByX).filter((key) => !key.includes('_schedule')).length === 0;

	return (
		<div className='flex flex-col gap-10' id='page-container'>
			{eventsByXBatches.map((eventsByXBatch, i) => {
				// Operations to get n unique rooms for this batch, helps with verticle height
				const distinctRooms = Array.from(
					new Set(
						Object.values(eventsByXBatch)
							.map((event) => event.map((event) => event.details.room))
							.reduce((accumulator, value) => accumulator.concat(value), [])
					)
				).length;

				return (
					<div id={`graph-${i}`} className={`${printable ? 'rotate-90 h-full w-[100em] block' : ''}`} key={i}>
						<div className='flex justify-between items-end mb-4'>
							{!printable && !hideFilters && <PageHeading className='mb-2'>Timeline View</PageHeading>}

							{!hideFilters && !printable ? (
								<div className='flex justify-end'>
									<div className='mr-2'>
										{!printable && !hideDatePicker && (
											<DateToggle
												key={date?.applied.getTime()}
												selected={date?.applied}
												onApply={(d) => {
													date.update({
														applied: d,
														selected: d,
													});
												}}
												minLimit={minLimit}
											/>
										)}
									</div>
									<div className='mr-2'>
										<Tooltip content={'Actual Cases'}>
											<Button
												sizeX='square'
												sizeY='md'
												variant={shownLines.includes('actual') ? 'primary' : 'primary-ghost'}
												className='border-r-0 rounded-none'
												onClick={() => updateShownLines('actual')}
											>
												<span className='material-symbols-outlined'>history</span>
											</Button>
										</Tooltip>
										<Tooltip content={'Scheduled Cases'}>
											<Button
												sizeX='square'
												sizeY='md'
												disabled={!has_scheduled}
												variant={shownLines.includes('schedule') ? 'primary' : 'primary-ghost'}
												className='border-l-[0.01em] rounded-none'
												onClick={() => updateShownLines('schedule')}
											>
												<span className='material-symbols-outlined'>schedule</span>
											</Button>
										</Tooltip>
									</div>
									<Drawer
										metadata={metadata}
										filtersAreDirty={filtersAreDirty}
										trigger={
											<Button sizeX='sm' sizeY='md' variant={'primary-ghost'}>
												<span className='material-symbol'>filter_alt</span>
												Filters
											</Button>
										}
										quickActions={[
											{
												icon: 'undo',
												onClick: resetFilters,
												tooltipText: 'Discard unapplied filter changes',
												disabled: !filtersAreDirty,
											},
											{
												icon: 'restart_alt',
												onClick: clearFilters,
												tooltipText: 'Reset filters to default',
												disabled: !metadata.saved_at,
											},
										]}
										actionButtons={[
											{
												onClick: applyFilters,
												children: 'Apply',
												disabled: !filtersAreDirty,
											},
											{
												// eslint-disable-next-line @typescript-eslint/no-empty-function
												onClick: () => {},
												children: 'Views',
												disabled: false,
											},
										]}
									>
										<FilterFields fields={['date', 'fcotsGraceMinutes', 'turnoverTimeThreshold', 'rooms', 'procedures']} />
									</Drawer>
									<div className='ml-2'>
										{events.length > 0 && (
											<ButtonStack>
												<Button sizeX='md' sizeY='md' variant='primary'>
													<ExportButton no_button={true} sizeX='md' sizeY='md' variant='primary' contents={csvDataset}>
														Export CSV
													</ExportButton>
												</Button>

												<Button
													sizeX='md'
													sizeY='md'
													variant='primary'
													onClick={() => {
														window.open('/room-turnover?printable=true');
													}}
												>
													Export PDF
												</Button>
											</ButtonStack>
										)}
									</div>
								</div>
							) : (
								!hideFilters && (
									<div className='flex w-full justify-end'>
										<div className='px-4 py-2 pb-4 bg-white text-[3em] font-bold w-fit rounded-md mt-6 self-center absolute left-10'>
											Timeline View
										</div>
										<div className='px-4 py-2 pb-4 bg-blue-200 text-p1 w-fit rounded-md mt-6 self-center mr-6'>
											{`${format(date?.applied, 'M/d/yyyy')}`}
										</div>
										<div className='px-4 py-2 pb-4 bg-blue-200 text-p1 w-fit rounded-md mt-6 self-center'>
											{`${
												current_facility?.intraop_facility_name_alias
													? current_facility.intraop_facility_name_alias
													: current_facility?.name
											} - ${system?.name}`}
										</div>
									</div>
								)
							)}
						</div>

						<Panel
							headerContentCenter={
								!printable &&
								!hideDatePicker && (
									<DateToggle
										key={date?.applied.getTime()}
										selected={date?.applied}
										onApply={(d) => {
											date.update({
												applied: d,
												selected: d,
											});
										}}
										minLimit={minLimit}
									/>
								)
							}
							noHeader={true}
							isEmpty={data?.events.length === 0}
							className={`${printable && 'border-none pb-3 text-h4'}`}
						>
							<div className={`${!printable ? 'max-w-screen-2xl' : 'w-100 h-100'} m-auto`}>
								<div className='flex justify-end items-center py-4 '>
									<div className='flex items-center justify-end w-100'>
										<ChartLegend options={timelineChartLegendOptions.filter((o) => o.label !== 'none')} />
									</div>
									<div className={!printable ? 'flex items-center justify-end ml-4 pl-4 border-blue-500 border-l' : 'hidden'}>
										<ButtonLink className='mr-3' onClick={handleClickReset}>
											Reset Zoom
										</ButtonLink>
										<Button
											onClick={handleClickZoomOut}
											disabled={zoomStateRange >= 1440}
											sizeY='sm'
											sizeX='square'
											variant='primary-ghost'
											className='mr-2'
										>
											<span className='material-symbol'>zoom_out</span>
										</Button>
										<Button
											onClick={handleClickZoomIn}
											disabled={zoomStateRange <= 120}
											sizeY='sm'
											sizeX='square'
											variant='primary-ghost'
										>
											<span className='material-symbol'>zoom_in</span>
										</Button>
									</div>
								</div>
								<div className='cursor-grab relative'>
									{(delayedFetching || skipRequest || isFetching) && <LogoOverlay backgroundColor='white' />}
									<VictoryChart
										key={date?.applied.getTime()}
										domainPadding={10}
										horizontal
										height={distinctRooms > 0 ? 22 * distinctRooms + 50 : 125}
										//width={550}
										domain={{ y: [dayStartMinute, dayEndMinute], x: [0, distinctRooms] }}
										padding={{
											top: 20,
											bottom: 20,
											right: 10,
											left: avg > 20 ? avg * 2.8 : Math.max(!parentSurgeons ? avg * 3.07 : 50, 40), // may need to change in future based on more testing
										}}
										containerComponent={
											<VictoryZoomContainer
												onZoomDomainChange={(domain) => {
													// domain is { x: DomainTuple, y: DomainTuple }
													// not quite sure how to convert that to numbers?
													const [start, end] = domain.y;
													if (typeof start === 'number' && typeof end === 'number') {
														const newStart = 60 * Math.ceil(start / 60.0);
														const newEnd = 60 * Math.ceil(end / 60.0);
														// TODO: it would probably be much more performant to do a debounce here, after like 50-100ms of delay
														// but didn't have time to do that when first implementing
														setZoomState({
															y: [newStart, newEnd],
														});
													}
												}}
												allowPan={true}
												allowZoom={false}
												zoomDomain={zoomState}
												zoomDimension='y'
											/>
										}
									>
										<VictoryAxis
											style={roomAxisStyles}
											tickValues={events.length === 0 ? ['Rooms'] : undefined}
											tickFormat={(t) =>
												// logic for printing, we want to remove the _schedule suffix
												t &&
												!t.toString().includes('temp$_') &&
												(!t.toString().includes('_schedule') || only_schedule_timelines_shown)
													? only_schedule_timelines_shown && printable
														? t.replace('_schedule', '')
														: t
													: ''
											}
											sortOrder='descending'
											tickLabelComponent={
												printable ? undefined : (
													<CustomTicker is_surgeon={!!parentSurgeons} only_schedule_timelines={only_schedule_timelines_shown} />
												)
											}
										/>

										<VictoryAxis
											label='Time'
											orientation='top'
											style={timeAxisStyles}
											dependentAxis
											tickValues={hourAxisTicks}
											tickFormat={(m: number) => `${m / 60}:00`}
										/>

										{Object.values(eventsByXBatch).map((eventGroup, i) => (
											<VictoryStack key={i}>
												{eventGroup.map((event, i) => (
													<VictoryBar
														key={i}
														alignment={event.details.room.includes('_schedule') ? 'start' : 'end'}
														barWidth={event.details.room.includes('_schedule') ? 8.0 : 13.5}
														labels={({ datum }) => {
															if (printable && datum.surgeon !== '' && datum.surgeon !== undefined && datum.y !== null) {
																return datum.surgeon;
															} else {
																return null;
															}
														}}
														style={{
															data: {
																fill: fillColorPicker(eventGroup, event, i),
															},
														}}
														data={event.data}
														labelComponent={
															!printable ? (
																<VictoryTooltip
																	active={false}
																	flyoutComponent={
																		<CustomFlyout type={event.event_type} surgeon={event.surgeon} procedure={event.procedure} />
																	}
																	orientation='left'
																/>
															) : !event.details.room?.includes('_schedule') ? (
																<VictoryLabel
																	style={{
																		fontSize: 4.5, // Set the font size to make the label smaller
																		opacity: 0.5, // Make the label transparent
																	}}
																	direction={'rtl'}
																	dy={has_scheduled ? 27 : 16} // Move the label down by 9px
																	dx={event.data[0].distance_from_last_event ? event.data[0].distance_from_last_event : undefined}
																/>
															) : only_schedule_timelines_shown ? (
																<VictoryLabel
																	style={{
																		fontSize: 4.5, // Set the font size to make the label smaller
																		opacity: 0.5, // Make the label transparent
																	}}
																	direction={'rtl'}
																	dy={5} // Move the label down by 9px
																	dx={event.data[0].distance_from_last_event ? event.data[0].distance_from_last_event : undefined}
																/>
															) : (
																<p></p>
															)
														}
													/>
												))}
											</VictoryStack>
										))}
									</VictoryChart>
								</div>
							</div>
							{!printable && <div className='pt-12'></div>}
							{printable && (
								<div className='pl-10 pb-6 opacity-50'>
									&copy; {` Surgical Directions, LLC ${new Date().getFullYear()}. All Rights Reserved.`}
								</div>
							)}
						</Panel>
					</div>
				);
			})}
			{printable && data && (
				<div id={`graph-${eventsByXBatches.length}`} className='h-[125em] pl-12 pt-12 pr-12'>
					<h1 className='text-h2 pb-12 font-bold'>Active Filters</h1>
					{[
						{ name: 'Date', context: format(date.applied, 'M/d/yyyy'), type: 'static' },
						{ name: 'FCOTS Grace Minutes', context: `${fcotsGraceMinutes.applied} mins`, type: 'static' },
						{ name: 'Turnover Time Threshold', context: `${turnoverTimeThreshold.applied} mins`, type: 'static' },
						{ name: 'Included Rooms', context: rooms.applied, type: 'array' },
					].map((filter, i) => {
						return (
							<div key={i} className='pb-12 flex flex-row flex-wrap items-center'>
								<h3 className='px-4 py-2 pb-6 bg-blue-200 text-p1 w-fit rounded-md mt-6 self-center mr-6'>{filter.name}</h3>

								{typeof filter.context == 'object' ? (
									<div className='flex flex-row flex-wrap'>
										{filter.context.map((room, i) => (
											<div
												key={i}
												className='px-4 py-2 pb-4 mr-2 border border-blue-300 text-p2 w-fit rounded-md mt-6 self-center'
											>
												{room.name}
											</div>
										))}
									</div>
								) : (
									<p className='pt-2'>{`${filter.context}`}</p>
								)}
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
}

function fillColorPicker(eventGroup: TimelineEvent[], event: TimelineEvent, index: number) {
	return timelineChartLegendOptions.find((o) => o.type === event.event_type)?.color ?? 'bg-gray-100';
}

export default Timeline;
