import { ApiEndpoints } from 'models/api';
import { apiWithCore } from 'store/api';
import { ShortOption } from 'store';

export interface SchedulingPatternsResponse {
	data: { x: string; y: number; total_addons: number; total_days: number }[];
	start_date: string;
	end_date: string;
	group_by: string;
	heatmap: { x: number[]; y: string }[];
}

export interface SchedulingPatternsRequest {
	facility_id: number | null;
	groupby: string;
	filters: {
		surgeons: ShortOption[];
		days_of_week: string[];
		service_lines: ShortOption[];
		rooms: ShortOption[];
		procedures: string[];
		start_date: string;
		end_date: string;
	};
}

/**
 * Provides endpoints for data values in database
 */
export const schedulingPatternsService = apiWithCore.injectEndpoints({
	endpoints: (build) => ({
		getSchedulingPatterns: build.query<SchedulingPatternsResponse, SchedulingPatternsRequest>({
			query: (body) => ({
				url: ApiEndpoints.GET_SCHEDULING_PATTERNS,
				method: 'POST',
				body,
			}),
			providesTags: ['Core'],
		}),
	}),
});

export const { useGetSchedulingPatternsQuery } = schedulingPatternsService;

export const {
	endpoints: { getSchedulingPatterns },
} = schedulingPatternsService;
