import * as Tabs from '@radix-ui/react-tabs';
import classNames from 'classnames';
import merge from 'lodash.merge';
import reactElementToJSXString from 'react-element-to-jsx-string';

import { CodeBlock } from 'components/StyleGuide';

import type { Options as ReactElementToJsxStringOptions } from 'react-element-to-jsx-string';
import type { TabsProps } from '@radix-ui/react-tabs';

interface MarkupTabsProps extends TabsProps {
	stringifierOptions?: ReactElementToJsxStringOptions;
}

const defaultStringifierOptions: MarkupTabsProps['stringifierOptions'] = {
	showFunctions: true,
	sortProps: false,
};

const tabTriggerClassname = classNames(
	'relative py-2 px-6 border-b-2 border-transparent text-blue-500 opacity-50',
	'data-active:opacity-100 data-active:border-blue-500',
	'transition-colors transition-opacity'
);
const tabContentClassname = 'py-4';

/**
 * A simple set of tabs that displays the provided child as both its rendered
 * output and its authored markup for documentation purposes.
 */
export function MarkupTabs({ stringifierOptions, children, ...props }: MarkupTabsProps) {
	const mergedStringifierOptions = merge(defaultStringifierOptions, stringifierOptions);

	return (
		<Tabs.Root defaultValue='output' {...props}>
			<Tabs.List aria-label='Code Sample' className='flex border-b border-bluegray-100 font-primary'>
				<Tabs.Trigger value='output' className={tabTriggerClassname}>
					Output
				</Tabs.Trigger>

				<Tabs.Trigger value='code' className={tabTriggerClassname}>
					Code
				</Tabs.Trigger>
			</Tabs.List>

			<Tabs.Content value='output' className={tabContentClassname}>
				{children}
			</Tabs.Content>

			<Tabs.Content value='code' className={tabContentClassname}>
				<CodeBlock>{reactElementToJSXString(children, mergedStringifierOptions)}</CodeBlock>
			</Tabs.Content>
		</Tabs.Root>
	);
}

export default MarkupTabs;
