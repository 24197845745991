import { createSlice } from '@reduxjs/toolkit';

import { deleteFromLocalStorage, getFromLocalStorage, writeToLocalStorage } from 'utils/storage';

import type { PayloadAction } from '@reduxjs/toolkit';

const cachedSystem = getFromLocalStorage('selectedSystem');
const cachedFacility = getFromLocalStorage('selectedFacility');

export interface UserState {
	selectedFacility: number | null;
	selectedSystem: number | null;
}

const initialState: UserState = {
	selectedFacility: cachedFacility || null,
	selectedSystem: cachedSystem || null,
};

/**
 * State that holds the currently selected facility and healthsystem
 */
export const userStateSlice = createSlice({
	name: 'userState',
	initialState,
	reducers: {
		setSelectedFacility(state, action: PayloadAction<number>) {
			state.selectedFacility = action.payload;
			writeToLocalStorage('selectedFacility', action.payload);
		},
		setSelectedSystem(state, action: PayloadAction<number>) {
			state.selectedFacility = null;
			state.selectedSystem = action.payload;
			writeToLocalStorage('selectedSystem', action.payload);
			deleteFromLocalStorage('selectedFacility');
		},
	},
});

export const { setSelectedFacility, setSelectedSystem } = userStateSlice.actions;

export default userStateSlice.reducer;
