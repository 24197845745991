interface WrapProps {
	/** The condition to check to determine whether this component should wrap its children as described in the `with` prop or render them directly. */
	if: boolean;

	/** A function describing the desired wrapper component to render when the `if` prop returns `true`. */
	with: (children: React.ReactNode) => JSX.Element;

	children: React.ReactNode;
}

/** Utility component that conditionally wraps one component with another. */
export function Wrap({ if: shouldWrap, with: applyWrapper, children }: WrapProps) {
	return shouldWrap ? applyWrapper(children) : <>{children}</>;
}

export default Wrap;
