import image from 'assets/images/choose-facility.svg';

export function NoFacilitySelected() {
	return (
		<div className='flex justify-center align-center'>
			<div className='bg-gray-50 rounded text-center p-12'>
				<img className='mx-auto my-16 block max-w-xl' alt='No facility is selected' src={image} />
				<h1 className='text-h4'>Choose a facility to explore metrics</h1>
				<p className='text-p mx-auto max-w-lg leading-6 my-4'>
					Once you&apos;ve chosen a facility, the links in the navigation menu to the left will load real data.
				</p>
			</div>
		</div>
	);
}

export default NoFacilitySelected;
