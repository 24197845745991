import { border, fontFamily } from 'utils';

export function determineBarWidth(numBars: number) {
	if (numBars <= 2) {
		return 100;
	} else if (numBars > 2 && numBars <= 5) {
		return 50;
	} else if (numBars > 5 && numBars <= 7) {
		return 30;
	} else if (numBars > 7 && numBars <= 11) {
		return 18;
	} else if (numBars > 11) {
		return 12;
	}
	return 30;
}

export function determineDomainPadding(numBars: number) {
	if (numBars <= 4) {
		return 100;
	} else if (numBars > 2 && numBars <= 5) {
		return 50;
	} else if (numBars > 5) {
		return 25;
	}
	return 25;
}

// determines vertical spacing between bars
export function determineHeight(valuesLength: number, finer?: boolean) {
	if (!finer) {
		if (valuesLength <= 5) {
			return 40 * valuesLength;
		} else if (valuesLength > 5) {
			return 25 * valuesLength;
		} else {
			return 28 * valuesLength;
		}
	} else {
		if (valuesLength === 1) {
			return 60;
		}
		if (valuesLength <= 4) {
			return 40 * valuesLength;
		} else if (valuesLength <= 5) {
			return 30 * valuesLength;
		} else if (valuesLength > 5) {
			return 25 * valuesLength;
		} else {
			return 25 * valuesLength;
		}
	}
}

export function truncateLabel(str: string, n: number) {
	return str.length > n ? str.slice(0, n - 1) + '...' : str;
}

export function capitalizeFirstLetter(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function dynamicFontSizeStyles() {
	// determine the font sizes of the axis labels/ticks by checking the window width
	const axisLabelBaseFontSize = 6;
	const timeAxisLabelBaseFontSize = 4.5;

	const generalAxisStyles = {
		axis: { stroke: border },
		axisLabel: { fontFamily: fontFamily, fontSize: axisLabelBaseFontSize - 1, fontWeight: 600 },
		tickLabels: { fontFamily: fontFamily, fontSize: axisLabelBaseFontSize - 1, padding: 5 },
	};

	const timeAxisStyles = {
		axis: { stroke: border },
		axisLabel: { fontFamily: fontFamily, fontSize: axisLabelBaseFontSize + 1, fontWeight: 600 },
		grid: { stroke: border },
		ticks: { stroke: border, size: 5 },
		tickLabels: { fontFamily: fontFamily, fontSize: timeAxisLabelBaseFontSize, padding: 5 },
	};

	const axisLabelStyle = { fontFamily: fontFamily, fontSize: axisLabelBaseFontSize, fontWeight: 700 };
	const smallerAxisLabelStyle = { fontFamily: fontFamily, fontSize: axisLabelBaseFontSize - 2, fontWeight: 700 };

	return {
		generalAxisStyles,
		timeAxisStyles,
		axisLabelStyle,
		smallerAxisLabelStyle,
	};
}
