import { ApiEndpoints } from 'models/api';
import { standardApi } from 'store/api';
import { Surgeon } from 'models/models';

export interface ShortSurgeon {
	name: string;
	id: number;
}

export interface SurgeonApiRequest {
	surgeon: Surgeon;
}

export interface SurgeonApiResponse {
	surgeons: ShortSurgeon[];
}

export interface BlockExistsRequest {
	healthsystem_id: number;
	block_name: string;
	surgeons: number[];
}

export interface BlockExistsResponse {
	error: boolean;
	name_error?: string;
	surgeons_error?: string;
}

const apiWithTags = standardApi.enhanceEndpoints({ addTagTypes: ['BlockSchedule'] });

/**
 * Provides endpoints for retrieving and setting block settings
 */
export const blockScheduleApi = apiWithTags.injectEndpoints({
	endpoints: (build) => ({
		// addBlock: build.mutation<string, any>({
		// 	query: (body) => ({
		// 		url: ApiEndpoints.CREATE_BLOCK,
		// 		method: 'POST',
		// 		body,
		// 	}),
		// }),
		getSurgeons: build.query<SurgeonApiResponse, { healthsystem_id?: number | null }>({
			query: ({ healthsystem_id }) => ({
				url: ApiEndpoints.SURGEONS,
				params: { healthsystem_id: healthsystem_id !== null ? healthsystem_id : undefined },
			}),
		}),
		getBlockExists: build.mutation<BlockExistsResponse, BlockExistsRequest>({
			query: (body) => ({
				url: ApiEndpoints.CHECK_BLOCK_EXISTS,
				method: 'POST',
				body,
			}),
		}),
	}),
});

export const { useGetSurgeonsQuery, useGetBlockExistsMutation } = blockScheduleApi;

export const {
	endpoints: { getSurgeons, getBlockExists },
} = blockScheduleApi;
