import { getFacilityName, getRoleDesignationText } from 'utils';
import { useAppSelector, useSystem } from 'store';
import { TextField } from 'components';
import { useAuth } from 'context';
import { UserRole } from 'models';

export function UserProfile() {
	const { data: authData } = useAuth();
	const { data } = useSystem();
	const { selectedSystem } = useAppSelector((state) => state.userState);
	const system = data?.healthsystems.find((h) => h.id === selectedSystem);
	const facilities = data?.facilities ?? [];
	const designation = data?.user?.role ? getRoleDesignationText(data.user.role) : 'Unknown';
	const profileData = authData?.user;
	const isAdmin = data?.user.role === UserRole.admin;
	const systemName = isAdmin ? system?.name : data?.healthsystems[0].name;

	return (
		<div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 mt-10'>
			<div className='col-span-1'>
				<div className='h-32 w-32 p-4 '>
					<div className='inline-flex overflow-hidden relative justify-center items-center bg-gray-300 rounded-full dark:bg-gray-200 w-full h-full'>
						<span className='text-black text-h3'>
							{`${authData.user.given_name?.[0] ?? ''}${authData.user.family_name?.[0] ?? ''}`}
						</span>
					</div>
				</div>
				<p className='mb-6 text-h4 font-bold'>{profileData && profileData.name}</p>
			</div>
			<div className='col-span-2'>
				<div className='mb-14'>
					<p className='mb-8 font-bold'>Personal Information</p>
					<div>
						<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5 mb-6'>
							<TextField className='col-span-1' label='First Name' defaultValue={profileData.given_name} readOnly />
							<TextField className='col-span-1' label='Last Name' defaultValue={profileData.family_name} readOnly />
						</div>
						<TextField className='mb-6' label='Email' defaultValue={profileData.email} readOnly />

						<TextField
							className='mb-6'
							label='Designation'
							instructions='My Designation'
							defaultValue={designation}
							readOnly
						/>
						<TextField className='mb-6' label='Health System' instructions='My Health System' value={systemName} readOnly />
						<div className='mb-4'>
							<span className='font-preset-label'>Facilities</span>
							<ul className='list-disc list-inside mt-2'>
								{facilities.map((facility) => (
									<li className='font-secondary text-p2 mb-1' key={facility.id}>
										{getFacilityName(facility)}
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default UserProfile;
