import { format } from 'date-fns';
import { useState } from 'react';

import { useFilters, useToast } from 'context';
import { DAYS_OF_WEEK_LIST, truncateLabel } from 'utils';
import { Button } from 'components';
import { getQueryError, handleApiResponse, useAddBlockPatternMutation, useEditBlockPatternMutation } from 'store';
import { Facility } from 'models';

import { BlockPatternDetailsPOST, dowConst, Step1CacheProps } from './BlockPatternFlow';
import { militaryTimeDifference } from './ReleaseDialog';

const metaStyle = 'px-4 pl-0 text-p3 text-gray-700 tracking-wider uppercase mb-2 font-secondary whitespace-nowrap';
const metaDetail = 'bg-blue-50 text-black px-6 text-p3 font-primary rounded-md w-fit py-2 whitespace-nowrap';
const readonly = 'text-black text-p2 font-primary rounded-md w-fit pb-2 whitespace-nowrap';

function BlockPatternFlowSummary(props: {
	setFlowMode: (mode: 'Add' | 'Edit') => void;
	goBackModule: () => void;
	closeModule: () => void;
	previousTabData: { step_1: Step1CacheProps | null; step_2: BlockPatternDetailsPOST | null };
	facilites: Facility[] | undefined;
}) {
	const [addBlockPattern] = useAddBlockPatternMutation();
	const [editBlockPattern] = useEditBlockPatternMutation();
	const [setPatternLoader, setPatternLoaderState] = useState(false);

	// Create a new dow patterns array to loop through
	// creating preview cards for dow patterns
	let dow_patterns: ({
		new?: boolean;
		day_of_week: string;
		week_of_month: number[];
		range: string;
		total_min: number;
	} | null)[] = [null, null, null, null, null, null, null];

	// Create a new dow patterns array to loop through
	// creating preview cards for dow patterns
	const dow_patterns_nested: (
		| {
				new?: boolean;
				day_of_week: string;
				week_of_month: number[];
				range: string;
				total_min: number;
		  }[]
		| null
	)[] = [null, null, null, null, null, null, null];

	props.previousTabData.step_1?.patterns.forEach((_pattern, index) => {
		const pos = dowConst.indexOf(_pattern.day_of_week);
		if (dow_patterns_nested[pos]) {
			dow_patterns_nested[pos]?.push(_pattern);
		} else {
			dow_patterns_nested[pos] = [_pattern];
		}
	});

	const new_data = {
		new: true,
		day_of_week: dowConst[(props.previousTabData.step_1?.dow_selected ?? 1) - 1],
		week_of_month: props.previousTabData.step_2?.patterns.filter((p) => p.rooms.length > 0).map((p) => p.week) ?? [],
		range: `${props.previousTabData.step_2?.earliest_start} - ${props.previousTabData.step_2?.latest_end}`,
		total_min: 0,
	};

	// Calculating total minutes for pattern
	const step_2_patterns = props.previousTabData.step_2?.patterns ?? [];
	let first_populated_pattern = 0; // need this for summary
	for (let i = 0; i < step_2_patterns.length; i++) {
		if (step_2_patterns[i].rooms.length > 0) {
			first_populated_pattern = i;

			for (let j = 0; j < step_2_patterns[i].rooms.length; j++) {
				const minutes = militaryTimeDifference(
					step_2_patterns[i].rooms[j].start_time,
					step_2_patterns[i].rooms[j].end_time
				);
				new_data.total_min = new_data.total_min + minutes;
			}

			break;
		}
	}

	// settings preview pane data
	if (
		dow_patterns_nested?.[(props.previousTabData.step_1?.dow_selected ?? 1) - 1] != null &&
		Array.isArray(dow_patterns_nested[(props.previousTabData.step_1?.dow_selected ?? 1) - 1])
	) {
		const dowArray = dow_patterns_nested[(props.previousTabData.step_1?.dow_selected ?? 1) - 1];
		if (dowArray !== null) {
			//dowArray.pop();
			dow_patterns_nested[(props.previousTabData.step_1?.dow_selected ?? 1) - 1]?.push(new_data);
		}
	} else {
		dow_patterns_nested[(props.previousTabData.step_1?.dow_selected ?? 1) - 1] = [new_data];
	}
	dow_patterns = dow_patterns_nested.flat();

	const maxLen = 17;

	const { date } = useFilters();
	const { createToast } = useToast();

	const submitPattern = async () => {
		setPatternLoaderState(true);
		const response = await addBlockPattern(props.previousTabData.step_2);

		handleApiResponse(response, {
			success: (payload) => {
				createToast({
					title: 'Block Pattern Added',
					variant: 'success',
				});

				// last thing to execute
				setTimeout(() => {
					props.closeModule();
				}, 0);
			},
			error: (payload) => {
				createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
			},
		});

		setPatternLoaderState(false);
	};

	const submitPatternEdit = async () => {
		setPatternLoaderState(true);
		const response = await editBlockPattern(props.previousTabData.step_2);

		handleApiResponse(response, {
			success: (payload) => {
				createToast({
					title: 'Block Pattern Modified',
					variant: 'success',
				});

				// last thing to execute
				setTimeout(() => {
					props.closeModule();
				}, 0);
			},
			error: (payload) => {
				createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
			},
		});

		setPatternLoaderState(false);
	};

	const summaryDate = props.previousTabData?.step_2?.effective_date ?? new Date();

	return (
		<div className='mb-8'>
			<p className='text-h4 mb-6 mt-2 font-bold'>Summary</p>
			<div className='flex'>
				<div className='flex flex-col w-[40rem]'>
					<div className='flex justify-between mr-2 items-center mb-4'>
						<div className='flex'>
							<div className='flex flex-col mr-4'>
								<p className={metaStyle}>Block Name</p>
								<p className={readonly}>{truncateLabel(`${props.previousTabData?.step_1?.block_option?.name}`, maxLen)}</p>
							</div>

							{props.previousTabData.step_2?.serviceline_name ? (
								<div className='flex flex-col mr-4'>
									<p className={metaStyle}>Service Line</p>
									<p className={readonly}>{truncateLabel(`${props.previousTabData.step_2?.serviceline_name}`, maxLen)}</p>
								</div>
							) : null}

							<div className='flex flex-col mr-4'>
								<p className={metaStyle}>Day of Week</p>
								<p className={readonly}>{DAYS_OF_WEEK_LIST[(props.previousTabData?.step_1?.dow_selected ?? 1) - 1]}</p>
							</div>
							{props.previousTabData?.step_2?.assigned_facility && (
								<div className='flex flex-col mr-4'>
									<p className={metaStyle}>Assigned Facility</p>
									<p className={readonly}>
										{truncateLabel(
											`${
												props.facilites
													?.filter((facility) => facility.id === props.previousTabData?.step_2?.assigned_facility)
													.map((facility) => ({ value: facility.id, label: facility.name }))[0]?.label
											}`,
											maxLen
										)}
									</p>
								</div>
							)}
						</div>
					</div>

					<div className='flex flex-col w-full pr-6'>
						<p className='text-p3 text-gray-700 tracking-wider uppercase font-secondary mt-0.5 mb-2'>
							Surgeons in Block Pattern
						</p>
						<div className='flex flex-wrap overflow-y-auto max-h-52 mr-8'>
							{props.previousTabData?.step_2?.patterns[first_populated_pattern].surgeons.map((surgeon, i) => {
								if (surgeon) {
									return (
										<p key={i} className={`${metaDetail} m-1 h-fit`}>
											{surgeon?.name}
										</p>
									);
								} else {
									return null;
								}
							})}
						</div>
					</div>

					<div className='flex my-8 items-center'>
						<div className='pr-12 w-80'>
							<p className='text-p3 text-gray-700 tracking-wider uppercase font-secondary mt-0.5 mb-2'>
								Effective Date of these changes
							</p>
							<div className={metaDetail}>
								{format(typeof summaryDate === 'string' ? new Date(summaryDate) : summaryDate, 'M/d/yyyy')}
							</div>
						</div>
					</div>
				</div>
				<div className='flex flex-col w-1/2'>
					<p className='text-p3 text-gray-700 tracking-wider uppercase font-secondary mb-7'>Block Patterns</p>
					<div className='flex flex-wrap h-fit'>
						{!new_data.week_of_month.includes(0) ? (
							dow_patterns.map((d, i) => {
								if (d) {
									return (
										<div
											className={`flex rounded-md flex-col gap-2 pl-0 mr-4 px-2 h-fit py-5 pt-0 border ${
												d.new ? 'border-blue-500' : 'border-white'
											}`}
											key={i}
										>
											<p
												className={`text-blue-600 pb-1 font-secondary text-p3 pl-1.5 pt-0.5 ${
													d.new ? (props.previousTabData.step_1?.flow === 'Add' ? '' : '') : 'opacity-0'
												}`}
											>
												{d.new ? (props.previousTabData.step_1?.flow === 'Add' ? 'New' : 'Modified') : 'Old'}
											</p>
											<div className='flex justify-between px-2'>
												<p className='text-right font-semibold uppercase text-p2'>{d.day_of_week}</p>
												<p className='pl-1 text-p2'>{String(d.week_of_month)}</p>
											</div>
											<p className='text-p3 bg-blue-50 w-fit pt-1 pb-0.5 px-2 font-semibold rounded-2xl whitespace-nowrap self-center'>
												{d.range}
											</p>
											<p className='px-2 text-p2'>({d.total_min} min)</p>
										</div>
									);
								} else {
									return null;
								}
							})
						) : (
							<div className={`flex rounded-md flex-col gap-2 mr-4 px-4 h-fit py-5 pt-0 mt-6 border border-blue-500`}>
								<p
									className={`text-blue-600 pb-1 font-secondary text-p3 pl-0 pt-0.5 ${
										new_data.new ? (props.previousTabData.step_1?.flow === 'Add' ? '' : '') : 'opacity-0'
									}`}
								>
									{new_data.new ? (props.previousTabData.step_1?.flow === 'Add' ? 'New' : 'Modified') : 'Old'}
								</p>
								<div className='flex justify-between px-2'>
									<p className='text-right font-semibold uppercase text-p2'>E/O Week</p>
									<p className='pl-1 text-p2'>{''}</p>
								</div>
								<p className='text-p3 bg-blue-50 w-fit pt-1 pb-0.5 px-2 font-semibold rounded-2xl whitespace-nowrap self-center'>
									{new_data.range}
								</p>
								<p className='px-2 text-p2'>({new_data.total_min} min)</p>
							</div>
						)}
					</div>
				</div>
			</div>

			<div className='pr-7'>
				<div className='w-full flex justify-end mt-20'>
					<Button
						sizeX='md'
						sizeY='sm'
						variant='secondary'
						className='mr-4'
						onClick={() => {
							props.goBackModule();
						}}
					>
						Back
					</Button>
					<Button
						sizeX='md'
						sizeY='sm'
						disabled={props.previousTabData?.step_2?.patterns.filter((pattern) => pattern.rooms.length > 0).length === 0}
						onClick={() => {
							if (props.previousTabData.step_1?.flow === 'Add') {
								submitPattern();
							} else if (props.previousTabData.step_1?.flow === 'Edit') {
								submitPatternEdit();
							}
						}}
						isWorking={setPatternLoader}
					>
						Submit
					</Button>
				</div>
			</div>
		</div>
	);
}

export default BlockPatternFlowSummary;
