import { Link } from 'react-router-dom';

import { Routes } from 'models/navigation';

export function Terms() {
	return (
		<div className='bg-blue-50 pb-14 left-0'>
			<div className='pt-7 px-8 w-full'>
				<div className='mb-6'>
					<Link to={Routes.HOME} className='self-start font-secondary text-p2'>
						<span className='material-symbol-sm relative top-[5px] mr-1'>keyboard_backspace</span>
						Back to Login
					</Link>
				</div>
				<div className='container w-3/4 mx-auto rounded-3xl drop-shadow-2xl border-white p-14 text-p1 text-justify tracking-wider bg-white'>
					<h1 className='text-h2 mb-8 mx-auto text-center text-blue-600'>Terms and Conditions</h1>
					<p className='mb-4'>
						Your company has subscribed to the Merlin service for the purposes of increasing the efficiency of its surgical
						services operations, and specifically the use of procedural spaces. Your use of the Merlin service is subject to
						the following terms. By using the Merlin service, you indicate your acceptance of the terms; <br />
						<span className='pl-8 mt-4 block'>If you do not agree to the terms you should not use the service.</span>
					</p>
					<ol className='px-8 mt-2 space-y-4 list-decimal list-inside marker:font-bold'>
						<li>
							You must use the Merlin service through an account provided to you by your site administrator and you must be an
							employee, contractor, consultant or otherwise authorized by your company. If you are unable to login or are
							having other problems with Merlin, please use the Help Center feature in the service. You may also contact your
							site administrator or a member of the Merlin team at{' '}
							<a href='mailto:MerlinHelp@surgicaldirections.com' className='text-blue font-semibold'>
								merlinhelp@surgicaldirections.com.
							</a>
						</li>
						<li>
							Merlin is a service owned and provided on a subscription basis to your company by Surgical Directions LLC. You
							may use the service on a strictly internal basis for company purposes only. The service is not intended or
							designed to handle personal information of any kind, including Protected Health Information (PHI). Do not submit
							personal data or information to the Merlin service.
						</li>
						<li>
							You may not reverse engineer, modify, copy or hack the Merlin service or otherwise attempt unauthorized access.
							You may not alter or remove any proprietary trademark or copyright markings incorporated in, marked on, or
							affixed to the Merlin service. You may not use Merlin to send or store infringing, obscene, threatening, or
							otherwise unlawful material, including material violative of third-party privacy rights, or material containing
							viruses, worms, Trojan horses or other harmful code, files or scripts. You may not allow access to or use of
							Merlin to any unauthorized person.
						</li>
						<li>
							If you become aware of any unauthorized use of Merlin, alert your site administrator or Surgical Directions
							immediately. Your account may be subject to cancellation for unauthorized use.
						</li>
						<li>
							If you use an application or other third-party service through Merlin, you are subject to the terms and
							requirements of the third-party provider.
						</li>
						<li>Important notices may be provided to you upon sign-in to Merlin; please read these notices.</li>
					</ol>
					<p className='mt-8 tracking-normal whitespace-pre'>
						Surgical Directions <br />
						180 N Stetson Ave <br />
						Chicago IL 60601 <br />
						(312) 870-5600
					</p>
				</div>
			</div>
		</div>
	);
}

export default Terms;
