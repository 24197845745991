import * as Tabs from '@radix-ui/react-tabs';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { Button, Dialog, LogoOverlay, MultiSelect, Select, TextField, ToggleGroup } from 'components';
import { useFilters, useToast } from 'context';
import {
	CreateBlockRequest,
	getQueryError,
	handleApiResponse,
	useAddBlockMutation,
	useAppSelector,
	useEditBlockMutation,
	useGetAllServiceLineOptionsQuery,
	useGetBlockServiceLineQuery,
} from 'store';
import { LoadingIndicator } from 'components/Select/subcomponents';
import { inputFieldRegex } from 'utils';

export interface BlockFlowProps {
	children: React.ReactNode;
	facility_id: number | null;
	healthsystem_id: number | null;
}

export function BlockFlow({ children, facility_id, healthsystem_id }: BlockFlowProps) {
	const { selectedSystem } = useAppSelector((state) => state.userState);
	const [flowMode, setFlowMode] = useState<'Add' | 'Edit' | 'Delete' | undefined>(undefined);
	const [moduleTitle, setModuleTitle] = useState<string>('Block Module');
	const [dialogOpen, setDialogOpen] = useState(false);
	const [addBlock] = useAddBlockMutation();
	const [editBlock] = useEditBlockMutation();
	const [status, setStatus] = useState<null | 'loading' | 'success' | 'failure'>(null);
	const { createToast } = useToast();

	const restoreDefaultsandClose = () => {
		setFlowMode(undefined);
		setModuleTitle('Block Module');
		setStatus(null);
		setDialogOpen(false);
	};

	const addBlockSubmit = async (data: CreateBlockRequest) => {
		setStatus('loading');
		setModuleTitle('Loading...');

		const response = await addBlock(data);

		handleApiResponse(response, {
			success: () => {
				setStatus('success');
				setModuleTitle('Block Created');
				createToast({
					title: 'Block created successfully',
					variant: 'success',
				});
				restoreDefaultsandClose();
			},
			error: (payload) => {
				setStatus('failure');
				setModuleTitle('Failed to create block');
				createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
			},
		});
	};

	const editBlockSubmit = async (data: CreateBlockRequest) => {
		setStatus('loading');
		setModuleTitle('Loading...');

		const response = await editBlock(data);

		handleApiResponse(response, {
			success: () => {
				setStatus('success');
				setModuleTitle('Block Updated');
				createToast({
					title: 'Block edited successfully',
					variant: 'success',
				});
				restoreDefaultsandClose();
			},
			error: (payload) => {
				setStatus('failure');
				setModuleTitle('Failed to update block');
				createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
			},
		});
	};

	return (
		<Dialog
			actionButtons={[]}
			sizeX='lg'
			title={moduleTitle}
			trigger={children}
			open={dialogOpen}
			onOpenChange={async (isBeingOpened) => {
				if (isBeingOpened) {
					setDialogOpen(true);
					return;
				}
				if (!isBeingOpened) {
					restoreDefaultsandClose();
				}
			}}
		>
			<div className='flex w-auto'>
				{flowMode === undefined && status === null && (
					<div className='flex items-center justify-between'>
						<div className='pr-14'>
							<p className='font-bold mb-2 uppercase text-p2'>block</p>
							<p className='text-gray-400 italic text-p3 w-72'>
								Use this flow to create a new block name or edit an existing block’s name. You can also add or edit which
								service line is assigned to the block(s) you create.
							</p>
						</div>
						<div className='flex'>
							<Button
								sizeX='md'
								sizeY='sm'
								variant='primary'
								onClick={() => {
									setFlowMode('Add');
									setModuleTitle('Adding Block');
								}}
								className='mr-4'
							>
								Add Block
							</Button>
							<Button
								sizeX='md'
								sizeY='sm'
								variant='primary'
								onClick={() => {
									setFlowMode('Edit');
									setModuleTitle('Editing Block');
								}}
							>
								Edit Block
							</Button>
						</div>
					</div>
				)}

				{flowMode === 'Add' && (
					<CreateBlock
						submit={(data) => {
							addBlockSubmit(data);
						}}
						healthsystem_id={selectedSystem}
						closeModule={() => {
							restoreDefaultsandClose();
						}}
						loading={status === 'loading'}
					/>
				)}

				{flowMode === 'Edit' && (
					<EditBlock
						submit={(data) => {
							editBlockSubmit(data);
						}}
						healthsystem_id={selectedSystem}
						closeModule={() => {
							restoreDefaultsandClose();
						}}
						loading={status === 'loading'}
					/>
				)}
			</div>
		</Dialog>
	);
}

interface CreateBlockProps {
	submit: (data: CreateBlockRequest) => void;
	healthsystem_id: number | null;
	closeModule: () => void;
	loading: boolean;
}

function CreateBlock(props: CreateBlockProps) {
	const [serviceLine, setServiceLine] = useState<{ id: number; name: string } | null>(null);
	const [blockNameInput, setBlockNameInput] = useState<string>('');
	const [errorMessage, setErrorMessage] = useState<undefined | string>(undefined);
	const { data: serviceLinesOptions, isFetching } = useGetAllServiceLineOptionsQuery({
		healthsystem_id: props.healthsystem_id,
	});

	const restoreDefaults = () => {
		setServiceLine(null);
		setBlockNameInput('');
	};

	const submitData = () => {
		props.submit({
			healthsystem_id: props.healthsystem_id,
			selected_block: { name: blockNameInput, id: 0 },
			serviceline_id: serviceLine?.id ?? null,
		});
	};

	return (
		<div className='flex flex-col'>
			<div className='flex items-center'>
				<div className='pr-14'>
					<p className='font-bold mb-2 uppercase text-p2'>block creation</p>
					<p className='text-gray-400 italic text-p3 w-72'>
						Name the block you are creating and assign it a service line. You can change the name and service line later by
						using the “Edit Block Name” option.
					</p>
				</div>
				<div className='flex'>
					<TextField
						label='Block Name'
						value={blockNameInput}
						placeholder='Block Name'
						onChange={(e) => {
							if (inputFieldRegex.test(e.target.value) && e.target.value.length < 254) {
								setErrorMessage(undefined);
							} else {
								setErrorMessage('Please enter a valid block name');
							}
							setBlockNameInput(e.target.value);
						}}
						errorMessage={errorMessage}
					></TextField>
					<div className='mr-4'></div>
					<Select
						label={`Service Line`}
						sizeX='sm'
						options={serviceLinesOptions?.service_lines?.map(({ id, name }) => ({ value: id, label: name })) ?? []}
						onChange={(selection) => {
							if (!selection) return;
							const selectedServiceLine = { id: selection.value, name: selection.label };
							setServiceLine(selectedServiceLine);
						}}
						value={serviceLine ? { value: serviceLine.id, label: serviceLine.name } : null}
						isWorking={isFetching}
					/>
				</div>
			</div>
			<div className='w-full flex justify-end mt-20'>
				<Button
					sizeX='md'
					sizeY='sm'
					variant='secondary'
					className='mr-4'
					onClick={() => {
						restoreDefaults();
						props.closeModule();
					}}
				>
					Cancel
				</Button>
				<Button
					sizeX='md'
					sizeY='sm'
					disabled={blockNameInput === '' || !!errorMessage}
					onClick={() => {
						submitData();
					}}
					isWorking={props.loading}
				>
					Submit
				</Button>
			</div>
		</div>
	);
}

function EditBlock(props: CreateBlockProps) {
	const [blockSelected, setSelectedBlock] = useState<{ id: number; name: string } | null>(null);
	const { data, isFetching } = useGetBlockServiceLineQuery(
		{
			healthsystem_id: props.healthsystem_id,
			selected_block: blockSelected ?? undefined,
		},
		{
			skip: !blockSelected,
		}
	);

	const [blockNameInput, setBlockNameInput] = useState<string>('');
	const { blockName } = useFilters();
	const [errorMessage, setErrorMessage] = useState<undefined | string>(undefined);
	const { data: serviceLinesOptions, isFetching: fetchingService } = useGetAllServiceLineOptionsQuery({
		healthsystem_id: props.healthsystem_id,
	});

	const [serviceLine, setServiceLine] = useState<{ id: number; name: string } | null>();

	useEffect(() => {
		setServiceLine({
			id: data?.serviceline_id ?? 0,
			name: serviceLinesOptions?.service_lines?.filter((e) => e.id === data?.serviceline_id)[0]?.name ?? '',
		});
	}, [data, serviceLinesOptions?.service_lines]);

	const restoreDefaults = () => {
		setServiceLine(null);
		setSelectedBlock(null);
		setBlockNameInput('');
	};

	const submitData = () => {
		props.submit({
			healthsystem_id: props.healthsystem_id,
			selected_block: { name: blockNameInput, id: blockSelected?.id ?? 0 },
			serviceline_id: serviceLine?.id ? (serviceLine.id !== 0 ? serviceLine.id : null) : null,
		});
	};

	return (
		<div className='flex flex-col'>
			<div className='pr-14 pb-8'>
				<p className='font-bold mb-2 uppercase text-p2'>Edit Block</p>
				<p className='text-gray-400 italic text-p3 w-auto'>
					Edit the name and/or service line of an existing block. You can always change this later.
				</p>
			</div>
			<div className='flex justify-between mb-12'>
				<Select
					label={`Existing Block Names`}
					sizeX='sm'
					options={blockName.all.map(({ id, name }) => ({ value: id, label: name }))}
					onChange={(selection) => {
						setServiceLine(null);
						if (!selection) return;
						const selectedBlock = { id: selection.value, name: selection.label };
						setSelectedBlock(selectedBlock);
						setBlockNameInput(selection.label);
					}}
					value={blockSelected ? { value: blockSelected.id, label: blockSelected.name } : null}
					isWorking={blockName.isFetching}
				/>
				<div className='mr-4'></div>
				<TextField
					label='Editable Block Name'
					value={blockNameInput}
					disabled={!blockSelected}
					placeholder='Select Existing Block'
					onChange={(e) => {
						if (inputFieldRegex.test(e.target.value) && e.target.value.length < 254) {
							setErrorMessage(undefined);
						} else {
							setErrorMessage('Please enter a valid block name');
						}
						setBlockNameInput(e.target.value);
					}}
					errorMessage={errorMessage}
				></TextField>
				<div className='mr-4'></div>
				<Select
					label={`Service Line`}
					sizeX='sm'
					options={serviceLinesOptions?.service_lines?.map(({ id, name }) => ({ value: id, label: name })) ?? []}
					onChange={(selection) => {
						if (!selection) return;
						const selectedServiceLine = { id: selection.value, name: selection.label };
						setServiceLine(selectedServiceLine);
					}}
					value={serviceLine ? (serviceLine.id !== 0 ? { value: serviceLine.id, label: serviceLine.name } : null) : null}
					isWorking={fetchingService || isFetching}
				/>
			</div>
			<div className='w-full flex justify-end mt-20'>
				<Button
					sizeX='md'
					sizeY='sm'
					variant='secondary'
					className='mr-4'
					onClick={() => {
						restoreDefaults();
						props.closeModule();
					}}
				>
					Cancel
				</Button>
				<Button
					sizeX='md'
					sizeY='sm'
					disabled={blockNameInput === '' || !blockSelected || !!errorMessage}
					onClick={() => {
						submitData();
					}}
					isWorking={props.loading}
				>
					Submit
				</Button>
			</div>
		</div>
	);
}

export default BlockFlow;
