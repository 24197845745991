import { useAuth } from 'context/AuthProvider';

export function Home() {
	const { data, logout } = useAuth();

	return (
		<div>
			<div>
				<h1>Welcome!</h1>
				<h2>You’re logged in! As proof, here’s your user data:</h2>
				<pre>{JSON.stringify(data.user, null, 4)}</pre>
			</div>
			<button className='bg-merlinGreen text-white rounded-sm mt-4 px-4 py-2' onClick={logout}>
				Log out
			</button>
		</div>
	);
}

export default Home;
