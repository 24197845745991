import * as yup from 'yup';
import { FieldErrors, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { Accordion, Button, TextField, ToggleGroup, Tooltip } from 'components';
import {
	getQueryError,
	handleApiResponse,
	useGetFacilitySettingStatusInitQuery,
	useSetAnesthesiaFacilitySettingMutation,
	useSetFacilitySettingMutation,
} from 'store';
import { getFacilityName, stringTimeToDate } from 'utils';
import { Facility } from 'models';
import { LoadingIndicator } from 'components/Select/subcomponents';
import { useToast } from 'context';

interface FacilityAnesthesiaAccordionItemProps {
	facility?: Facility;
	readonly?: boolean;
	fetching?: boolean;
	rebuildingArtificial: () => void;
}

const medicalDirectionsToggleOptions = [
	{ label: 'Physician Only', value: 'md_only' },
	{ label: 'CRNA Only', value: 'crna_only' },
	{ label: 'Care Team', value: 'team_care' },
];

const canMDACoverToggleOptions = [
	{ label: 'Yes', value: 'yes' },
	{ label: 'No', value: 'no' },
];

export const updateFacilityAnesthesiaSchema = yup.object({
	medical_direction_model: yup.string().required(),
	mda_weekly_clinical_hours: yup
		.number()
		.typeError('This value must be a number.')
		.min(36, 'This value must be greater than 35.')
		.max(65, 'This value must be less than 65.')
		.required()
		.test('mda_weekly_clinical_hours', 'This hour should be in increments of 0.5', (value) => {
			value = value ?? 0;

			// Get the decimal part of the number
			const decimalPart = value - Math.floor(value);

			// Check if the decimal part is either 0 (integer) or 0.5
			return decimalPart === 0 || decimalPart === 0.5;
		}),
	crna_weekly_clinical_hours: yup
		.number()
		.typeError('This value must be a number.')
		.min(36, 'This value must be greater than 35.')
		.max(65, 'This value must be less than 65.')
		.required()
		.test('crna_weekly_clinical_hours', 'This hour should be in increments of 0.5', (value) => {
			value = value ?? 0;

			// Get the decimal part of the number
			const decimalPart = value - Math.floor(value);

			// Check if the decimal part is either 0 (integer) or 0.5
			return decimalPart === 0 || decimalPart === 0.5;
		}),
	mda_vacation_weeks: yup
		.number()
		.typeError('This value must be a number.')
		.min(0, 'This value must be positive or 0.')
		.max(20, 'This value must be less than 21.')
		.required()
		.test('mda_vacation_weeks', 'This hour should be in increments of 0.5', (value) => {
			value = value ?? 0;

			// Get the decimal part of the number
			const decimalPart = value - Math.floor(value);

			// Check if the decimal part is either 0 (integer) or 0.5
			return decimalPart === 0 || decimalPart === 0.5;
		}),
	crna_vacation_weeks: yup
		.number()
		.typeError('This value must be a number.')
		.min(0, 'This value must be positive or 0.')
		.max(20, 'This value must be less than 21.')
		.required()
		.test('crna_vacation_weeks', 'This hour should be in increments of 0.5', (value) => {
			value = value ?? 0;

			// Get the decimal part of the number
			const decimalPart = value - Math.floor(value);

			// Check if the decimal part is either 0 (integer) or 0.5
			return decimalPart === 0 || decimalPart === 0.5;
		}),
});

// type we expect when form submitted, used to validate
export type UpdateFacilityAnesthesiaForm = yup.InferType<typeof updateFacilityAnesthesiaSchema>;

export function FacilityAnesthesiaAccordionItem({
	facility,
	readonly,
	fetching,
	rebuildingArtificial,
}: FacilityAnesthesiaAccordionItemProps) {
	const [setAnesthesiaFacilitySetting] = useSetAnesthesiaFacilitySettingMutation();
	const { createToast } = useToast();
	const [isWorking, setIsWorking] = useState<boolean>(false);

	// form state
	const {
		handleSubmit,
		setValue,
		reset,
		register,
		getValues,
		watch,
		formState: { errors, isDirty },
	} = useForm<UpdateFacilityAnesthesiaForm>({
		resolver: yupResolver(updateFacilityAnesthesiaSchema),
		defaultValues: {
			crna_vacation_weeks: facility?.crna_vacation_weeks,
			mda_vacation_weeks: facility?.mda_vacation_weeks,
			crna_weekly_clinical_hours: facility?.crna_weekly_clinical_hours,
			mda_weekly_clinical_hours: facility?.mda_weekly_clinical_hours,
			medical_direction_model: facility?.medical_direction_model ?? 'md_only',
		},
		mode: 'onTouched',
	});

	// need this for conditional rendering of weekends in primetime settings table, otherwise weekend rows stop being rendered
	// on checkbox events. Watches all inputs and refreshes when change detected.
	watch();

	const onSubmit = async (formData: UpdateFacilityAnesthesiaForm) => {
		// loading state
		setIsWorking(true);
		const response = await setAnesthesiaFacilitySetting({
			facility_id: facility?.id,
			crna_vacation_weeks: formData.crna_vacation_weeks,
			mda_vacation_weeks: formData.mda_vacation_weeks,
			crna_weekly_clinical_hours: formData.crna_weekly_clinical_hours,
			mda_weekly_clinical_hours: formData.mda_weekly_clinical_hours,
			medical_direction_model: formData.medical_direction_model,
		});

		handleApiResponse(response, {
			success: (data) => {
				setIsWorking(false);
				createToast({
					title: 'Successfully saved settings',
					variant: 'success',
				});
			},
			error: (error) => {
				createToast({
					title: getQueryError(error),
					variant: 'error',
				});
			},
		});
		reset(
			{},
			{
				keepValues: true,
			}
		); // resets isDirty but keeps values as is
		setIsWorking(false);
	};

	const is_artificial = facility?.name.includes('*');

	return (
		<Accordion type='multiple' itemStyle={is_artificial ? 'dark' : 'contained'} className='mb-2'>
			<Accordion.Item value={facility ? getFacilityName(facility) : ''}>
				<form autoComplete='off' noValidate onSubmit={handleSubmit(onSubmit)}>
					<section>
						<div className='flex my-8 items-middle'>
							<div className='w-80 mr-16'>
								<p className='font-bold text-p2 mb-0.5'>Medical Direction or Supervision Model</p>
								<p className='mt-1 text-p3 text-gray-400 italic'>
									This setting changes whether the practice is staffed in a physician only or care team setting. Care team
									settings are usually comprised of physicians and APPs, with physicians either medically directing or
									supervising the APPs.
								</p>
							</div>
							<div>
								<ToggleGroup
									label=''
									hideLabel
									className='w-64'
									options={medicalDirectionsToggleOptions}
									defaultValue={getValues('medical_direction_model')}
									onValueChange={(selected) => {
										setValue('medical_direction_model', selected, {
											shouldDirty: true,
										});
									}}
								/>
							</div>
						</div>
						<div className='flex my-8 items-middle'>
							<div className='w-80 mr-6'>
								<p className='font-bold text-p2 mb-0.5'>Average weekly clinical hours per provider</p>
								<p className='mt-1 text-p3 text-gray-400 italic'>
									This value represents the average expected clinical hours by provider type. Generally, higher values will
									result in lower FTEs.
								</p>
							</div>
							<div className='flex flex-col'>
								<div className='flex items-baseline w-96'>
									<p className='text-left mr-2 text-p2 w-36 text-gray-700 tracking-wider uppercase font-secondary'>
										Anesthesiologist
									</p>
									<TextField
										className='w-44'
										label={''}
										errorMessage={errors?.mda_weekly_clinical_hours?.message}
										{...register(`mda_weekly_clinical_hours`)}
									/>
									<p className='text-left text-p2 text-gray-700 tracking-wider ml-1 font-secondary'>hrs</p>
								</div>
								<div className='flex items-baseline w-96'>
									<p className='text-left mr-2 text-p2 w-36 text-gray-700 tracking-wider uppercase font-secondary'>
										Anesthetist
									</p>
									<TextField
										className='w-44'
										label={''}
										errorMessage={errors?.crna_weekly_clinical_hours?.message}
										{...register(`crna_weekly_clinical_hours`)}
									/>
									<p className='text-left text-p2 text-gray-700 tracking-wider ml-1 font-secondary'>hrs</p>
								</div>
							</div>
						</div>
						<div className='flex my-8 items-middle'>
							<div className='w-80 mr-6'>
								<p className='font-bold text-p2 mb-0.5'>Average vacation weeks per provider</p>
								<p className='mt-1 text-p3 text-gray-400 italic'>
									This value represents the average expected weeks of vacation by provider type. This decreases the amount of
									clinical coverage provided. Generally, higher values will result in higher FTEs.
								</p>
							</div>
							<div className='flex flex-col w-96'>
								<div className='flex items-baseline'>
									<p className='text-left mr-2 text-p2 w-36 text-gray-700 tracking-wider uppercase font-secondary'>
										Anesthesiologist
									</p>
									<TextField
										className='w-44'
										label={''}
										errorMessage={errors?.mda_vacation_weeks?.message}
										{...register(`mda_vacation_weeks`)}
									/>
									<p className='text-left text-p2 text-gray-700 tracking-wider ml-1 font-secondary'>weeks</p>
								</div>
								<div className='flex items-baseline'>
									<p className='text-left mr-2 text-p2 w-36 text-gray-700 tracking-wider uppercase font-secondary'>
										Anesthetist
									</p>
									<TextField
										className='w-44'
										label={''}
										errorMessage={errors?.crna_vacation_weeks?.message}
										{...register(`crna_vacation_weeks`)}
									/>
									<p className='text-left text-p2 text-gray-700 tracking-wider ml-1 font-secondary'>weeks</p>
								</div>
							</div>
						</div>
					</section>

					<div className='flex flex-col items-end'>
						<div className='flex justify-end pt-8'>
							<Button
								variant={!is_artificial ? 'primary' : 'tertiary-ghost'}
								sizeX='md'
								sizeY='md'
								type='submit'
								disabled={!isDirty}
								isWorking={isWorking}
								onClick={() => {
									// // Values must at least be 1, these values are dropped in the onSubmit function
									// primetimeDowState.forEach(({ roomCountField, active }) => {
									// 	if (!active && getValues(roomCountField) === 0) {
									// 		setValue(roomCountField, 1);
									// 	}
									// });
									// // same goes for weekends
									// if (!getValues('primetime_weekends')) {
									// 	setValue('sunday_rooms_count', 1);
									// 	setValue('saturday_rooms_count', 1);
									// }
								}}
							>
								Save
							</Button>
						</div>
					</div>
				</form>
			</Accordion.Item>
		</Accordion>
	);
}
