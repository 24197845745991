import classNames from 'classnames';

import { Tooltip } from 'components';

import type { HTMLAttributes } from 'react';

interface ApiTableProps extends HTMLAttributes<HTMLDivElement> {
	items: {
		name: string;
		type: string | string[];
		default?: string;
		required?: boolean;
		description: React.ReactNode;
	}[];
}

/**
 * A table that organizes documentation for each prop that is part of a
 * component's API.
 */
export function ApiTable({ items, className, ...props }: ApiTableProps) {
	return (
		<div className={classNames('overflow-hidden rounded border border-gray-400 bg-white', className)} {...props}>
			<table className='w-full'>
				<colgroup>
					<col width='auto' />
					<col width='auto' />
					<col width='auto' />
					<col width='100%' />
				</colgroup>

				<thead>
					<tr className='font-bold border-b border-gray-400 bg-blue-50'>
						<td className='p-2'>Prop</td>
						<td className='p-2 border-l border-gray-400'>Type</td>
						<td className='p-2 border-l border-gray-400'>Default</td>
						<td className='p-2 border-l border-gray-400'>Description</td>
					</tr>
				</thead>

				<tbody>
					{items.map((item, i) => (
						<tr key={i} className='border-b border-gray-400 last:border-0'>
							<td className='p-2'>
								<pre>
									<code>
										{item.name}
										{item.required ? (
											<Tooltip content='This is a required prop.'>
												<span className='text-red-600 ml-2'>*</span>
											</Tooltip>
										) : null}
									</code>
								</pre>
							</td>

							<td className='p-2 border-l border-gray-400'>
								{(Array.isArray(item.type) ? item.type : [item.type]).map((typeValue, i) => (
									<pre key={i}>
										<code>{typeValue}</code>
									</pre>
								))}
							</td>

							<td className='p-2 border-l border-gray-400'>
								<pre>
									<code>{item.default || <div className='text-center'>—</div>}</code>
								</pre>
							</td>

							<td className='p-2 border-l border-gray-400'>{item.description}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

export default ApiTable;
