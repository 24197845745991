import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';

import { clearSessionAndLogout, env } from 'utils';

export const getToken = () => JSON.parse(localStorage.getItem('okta-token-storage') || '')?.accessToken.accessToken;

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
	baseUrl: env('API_BASE'),
	prepareHeaders: (headers) => {
		// By default, if we have a token in the store, let's use that for authenticated requests
		const token = getToken();
		if (token) {
			headers.set('Authorization', `Bearer ${token}`);
		}
		return headers;
	},
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const standardApi = createApi({
	/**
	 * `reducerPath` is optional and will not be required by most users.
	 * This is useful if you have multiple API definitions,
	 * e.g. where each has a different domain, with no interaction between endpoints.
	 * Otherwise, a single API definition should be used in order to support tag invalidation,
	 * among other features
	 */
	reducerPath: 'merlinApi',
	/**
	 * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
	 */
	baseQuery: baseQueryWithRetry,
	/**
	 * Tag types must be defined in the original API definition
	 * for any tags that would be provided by injected endpoints
	 */
	tagTypes: [],
	/**
	 * This api has endpoints injected in adjacent files,
	 * which is why no endpoints are shown below.
	 * If you want all endpoints defined in the same file, they could be included here instead
	 */
	endpoints: () => ({}),
});

export const autoLogoutOnTokenExpire: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
	if (isRejectedWithValue(action) && action?.payload?.data.includes('token is required')) {
		clearSessionAndLogout();
	}
	if (isRejectedWithValue(action) && process.env.NODE_ENV !== 'development' && action?.payload?.originalStatus === 500) {
		action.payload.data =
			'Something went wrong. Please try reloading the page, or contact your administrator if the issue persists.';
		return next(action);
	}
	return next(action);
};

export const apiWithCore = standardApi.enhanceEndpoints({
	addTagTypes: ['Core', 'ScheduleMetadata', 'ScheduleMap', 'IntraopMap', 'DailyHuddle'],
});
