import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { autoLogoutOnTokenExpire, standardApi } from './api';
import { blockSettingSlice, userStateSlice } from './slices';

export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
	configureStore({
		reducer: {
			[standardApi.reducerPath]: standardApi.reducer,
			userState: userStateSlice.reducer,
			blockSettingState: blockSettingSlice.reducer,
		},
		devTools: process.env.NODE_ENV !== 'production',
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({ serializableCheck: false }).concat([standardApi.middleware, autoLogoutOnTokenExpire]),
		...options,
	});

export const store = createStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export * from './api';
export * from './hooks';
export * from './services';
export * from './slices';

export default store;
