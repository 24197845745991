import classNames from 'classnames';

import { Button } from 'components';

import type { ButtonProps } from 'components';
import type { HTMLAttributes } from 'react';

interface ButtonTableProps extends HTMLAttributes<HTMLDivElement> {
	variant?: ButtonProps['variant'];
}

/**
 * Converts an object containing simple string or boolean props to an array of
 * prop-value strings suitable for use with documentation.
 *
 * @param propObject An object of simple boolean or string props.
 * @returns An array of each prop and its value as a string.
 */
const getSimplePropObjectAsArray = (propObject?: Record<string, string | boolean>) => {
	if (!propObject) return [];

	return Object.entries(propObject).map(([propName, propValue]) => {
		// For boolean prop values, only explicitly output the value if it's false.
		if (typeof propValue === 'boolean' && propValue) {
			return propValue ? propName : `${propName}={false}`;
		}

		return `${propName}='${propValue}'`;
	});
};

/**
 * A table that displays all permutations of a particular variant of Button
 * component.
 */
export function ButtonTable({ variant, className, ...props }: ButtonTableProps) {
	const variantDefault: NonNullable<ButtonProps['variant']> = 'primary';

	const sizeXValues: NonNullable<ButtonProps['sizeX']>[] = ['sm', 'md', 'lg', 'square'];
	const sizeXDefault: typeof sizeXValues[number] = 'full';

	const sizeYValues: NonNullable<ButtonProps['sizeY']>[] = ['sm', 'md', 'lg'];
	const sizeYDefault: typeof sizeXValues[number] = 'sm';

	const buttonStates: { label: string; props?: Record<string, string | boolean> }[] = [
		{ label: 'Enabled' },
		{ label: 'Working', props: { isWorking: true } },
		{ label: 'Disabled', props: { disabled: true } },
	];

	return (
		<div className={classNames('overflow-hidden rounded border border-gray-400 bg-white', className)} {...props}>
			<table className='w-full'>
				<colgroup>
					<col width='100%' />
					{sizeXValues.map((xValue) => (
						<col key={xValue} width='auto' />
					))}
				</colgroup>

				<thead>
					<tr className='text-p2 font-semibold bg-blue-50 border-b border-gray-400'>
						<td className='p-2'>
							<code>{!variant || variant === variantDefault ? `[variant='${variantDefault}']` : `variant='${variant}'`}</code>
						</td>
						{sizeXValues.map((xValue) => (
							<td key={xValue} className='p-2 border-l border-gray-400 text-center'>
								<code>{xValue === sizeXDefault ? `[sizeX='${xValue}']` : `sizeX='${xValue}'`}</code>
							</td>
						))}
					</tr>
				</thead>

				<tbody>
					{sizeYValues.map((yValue) =>
						buttonStates.map((buttonState, i) => (
							<tr key={`${yValue}-${i}`} className='border-b border-gray-400 last:border-0'>
								<td className='p-2 text-p2 font-semibold bg-blue-50'>
									{[
										yValue === sizeYDefault ? `[sizeY='${yValue}']` : `sizeY='${yValue}'`,
										...getSimplePropObjectAsArray(buttonState.props),
									].map((prop, j) => (
										<pre key={prop} className={j > 0 ? 'mt-1' : ''}>
											<code>{prop}</code>
										</pre>
									))}
								</td>
								{sizeXValues.map((xValue) => (
									<td key={xValue} className='p-2 border-l border-gray-400 text-center'>
										<Button
											variant={variant}
											sizeX={xValue}
											sizeY={yValue}
											// eslint-disable-next-line no-console
											onClick={() => console.log('Example button clicked!')}
											{...buttonState.props}
										>
											{xValue === 'square' ? buttonState.label[0] : buttonState.label}
										</Button>
									</td>
								))}
							</tr>
						))
					)}
				</tbody>
			</table>
		</div>
	);
}

export default ButtonTable;
