import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button, TextField } from 'components';
import { defaultLoginFormInputValues, loginFormSchema } from 'models/authentication';
import { AuthPage } from 'pages/Auth';
import { getOktaErrorMessage } from 'utils/authentication';
import { Routes } from 'models/navigation';
import { useAuth } from 'context';

import type { AnyAuthContext } from 'context';
import type { LoginFormInputs } from 'models/authentication';
import type { SubmitHandler } from 'react-hook-form';

export function Login() {
	const auth = useAuth<AnyAuthContext>();
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors, isDirty, isSubmitting, isSubmitted },
	} = useForm<LoginFormInputs>({ resolver: yupResolver(loginFormSchema) });
	const [errorMessage, setErrorMessage] = useState('');

	const onSubmit: SubmitHandler<LoginFormInputs> = async (formData) => {
		await auth.login(formData.email, formData.password);
	};

	// When an authentication error occurs, keep the form's values but reset its
	// dirty state. Then when the form becomes dirty again, clear the error since
	// the user has indicated they are trying again.
	useEffect(() => {
		const clearAuthError = auth.clearError;

		if (auth.status === 'api_error') {
			if (isSubmitted) {
				reset(defaultLoginFormInputValues, { keepValues: true });
			} else if (isDirty) {
				clearAuthError();
			}
		}
	}, [auth.status, auth.clearError, isSubmitted, isDirty, reset]);

	// Provide a more detailed authentication error message based on the error
	// code returned by the Okta API.
	useEffect(() => {
		if (auth.status === 'api_error') {
			setErrorMessage(getOktaErrorMessage(auth.data.error.errorCode));
		} else {
			setErrorMessage('');
		}
	}, [auth.status, auth.data]);

	return (
		<AuthPage>
			<div className='flex'>
				<div className='flex max-w-xl w-full'>
					<div className='m-auto pt-7 px-8 max-w-md w-full'>
						<h1 className='font-semibold text-h4'>Welcome!</h1>

						<form className='flex flex-col mt-8' onSubmit={handleSubmit(onSubmit)} noValidate autoComplete='off'>
							<TextField
								data-testid='login-username'
								label='Email Address'
								type='email'
								sizeY='lg'
								defaultValue={defaultLoginFormInputValues.email}
								errorMessage={errors.email?.message}
								{...register('email')}
							/>

							<TextField
								containerProps={{ className: 'mt-8' }}
								label='Password'
								type='password'
								sizeY='lg'
								defaultValue={defaultLoginFormInputValues.password}
								errorMessage={errors.password?.message}
								{...register('password')}
							/>

							<div className='flex justify-between'>
								<Link to={Routes.REQUEST_RESET_PASSWORD} className='self-end mt-3 font-secondary text-p2'>
									Forgot Password?
								</Link>
							</div>

							<Button data-testid='login-submit' className='mt-12' sizeY='lg' isWorking={isSubmitting} disabled={!isDirty}>
								{isSubmitting ? 'Logging In' : 'Login'}
							</Button>

							<p className='mt-2 text-p2 text-red-400'>{errorMessage || <>&nbsp;</>}</p>
						</form>
					</div>
				</div>
			</div>

			<div className='bg-merlinBlue'></div>

			<div className='flex'>
				<div className='flex max-w-xl w-full'>
					<div className='flex flex-col items-center gap-2 mt-auto m-auto mb-10 pt-7 px-8 w-full max-w-md font-secondary text-p3'>
						<span className='text-gray-400'>By logging in, I agree to Surgical Directions&apos;</span>
						<span className='flex flex-nowrap'>
							<Link to={Routes.TERMS}>Terms and Conditions</Link>
						</span>
					</div>
				</div>
			</div>
		</AuthPage>
	);
}

export default Login;
