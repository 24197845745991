import { UserInfo, UserRole } from 'models/models';
import { ApiEndpoints } from 'models/api';
import { standardApi } from 'store/api';

export interface GetUsersApiResponse {
	users: UserInfo[];
}

export interface AddUserApiRequest {
	first_name: string;
	last_name: string;
	email: string;
	role: UserRole;
	default_facility: number;
	facility_access: (number | undefined)[];
}

export interface UpdateUserApiRequest {
	id: number;
	first_name: string;
	last_name: string;
	email: string;
	role: UserRole;
	default_facility: number;
	facility_access: (number | undefined)[];
}

export interface UserApiResponse {
	user: UserInfo;
}

export interface DeleteUserApiResponse {
	message: string;
}

const apiWithTags = standardApi.enhanceEndpoints({ addTagTypes: ['Users'] });

/**
 * Provides endpoints for retrieving initial state (healthsystems, facilities) and creating new healthsystems
 */
export const userSettingsApi = apiWithTags.injectEndpoints({
	endpoints: (build) => ({
		getUsers: build.query<
			GetUsersApiResponse,
			{ healthsystem_id?: number | null; short?: boolean; short_with_local?: boolean }
		>({
			query: ({ healthsystem_id, short, short_with_local }) => ({
				url: ApiEndpoints.USERS,
				params: { healthsystem_id: healthsystem_id !== null ? healthsystem_id : undefined, short, short_with_local },
			}),
			providesTags: ['Users'],
		}),
		addUser: build.mutation<UserApiResponse, AddUserApiRequest>({
			query: (body) => ({
				url: ApiEndpoints.ADD_USER,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Users'],
		}),
		updateUser: build.mutation<UserApiResponse, UpdateUserApiRequest>({
			query: (body) => ({
				url: ApiEndpoints.UPDATE_USER,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Users'],
		}),
		deleteUser: build.mutation<DeleteUserApiResponse, Partial<UpdateUserApiRequest>>({
			query: (body) => ({
				url: ApiEndpoints.UPDATE_USER,
				method: 'DELETE',
				body,
			}),
			invalidatesTags: ['Users'],
		}),
	}),
});

export const { useGetUsersQuery, useAddUserMutation, useDeleteUserMutation, useUpdateUserMutation } = userSettingsApi;

export const {
	endpoints: { addUser, deleteUser, getUsers, updateUser },
} = userSettingsApi;
