import { useEffect, useRef } from 'react';

import type { MutableRefObject, Ref } from 'react';

/**
 * Combines any provided refs, whether objects or functions, into a single ref
 * that can be applied to an element. Useful in cases where a single component
 * needs to make use of both forwarded and internal refs.
 *
 * @param refs The refs to combine.
 * @returns A new, combined ref comprised of the supplied refs.
 *
 * @see https://stackoverflow.com/questions/62238716/using-ref-current-in-react-forwardref
 */
export function useCombinedRef<T>(...refs: Ref<T | null>[]) {
	const combinedRef = useRef(null);

	useEffect(() => {
		refs.forEach((ref) => {
			if (!ref) return;

			if (typeof ref === 'function') {
				ref(combinedRef.current);
			} else {
				(ref as MutableRefObject<T | null>).current = combinedRef.current;
			}
		});
	}, [refs]);

	return combinedRef;
}
