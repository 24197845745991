import { Button, ButtonLink, DataTable } from 'components';
import { getQueryError, handleApiResponse, ShortOption, useAppSelector, useSystem } from 'store';
import { useAlert, useToast } from 'context';
import { AliasesData } from 'pages/Settings';
import { userIs } from 'utils';
import { UserRole } from 'models';
import { useSetUndoReconciliationMutation } from 'store/services/MappingService';

import AddRoomMappingDialog from './AddRoomMappingDialog ';

interface ExistingRoomsTableProps {
	aliasesData: AliasesData[];
	save: (fromSurgeon: number, toSurgeon: number) => Promise<void>;
	roomOptions: ShortOption[];
}

export function ExistingRoomsTable({ aliasesData, roomOptions, save }: ExistingRoomsTableProps) {
	const toast = useToast();
	const { getAlertResponse } = useAlert();
	const { data } = useSystem();
	const { selectedSystem } = useAppSelector((state) => state.userState);

	const [setUndoReconcilation, { isLoading }] = useSetUndoReconciliationMutation();
	const setUndoAlias = async (alias_id: number) => {
		const response = await setUndoReconcilation({
			healthsystem_id: selectedSystem,
			alias_id: alias_id,
		});

		handleApiResponse(response, {
			success: () => {
				toast.createToast({
					title: `Alias undo successful.`,
					variant: 'success',
				});
			},
			error: (payload) => {
				toast.createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
			},
		});
	};

	const isAdministrator = userIs([UserRole.admin, UserRole.healthsystem_admin], data?.user);

	return (
		<div className='h-50'>
			<DataTable
				title='Existing Room Mapping'
				tooltipContent={'Use this table to keep track of room and alias mappings'}
				goToHelpID='newrooms'
				minHeight={'28em'}
				headerContentRight={
					<AddRoomMappingDialog
						roomOptions={roomOptions}
						save={save}
						aliasIDs={aliasesData.map((d) => d.target_id).concat(aliasesData.map((d) => d.alias_id))}
					>
						<Button sizeX='sm'>Add Mapping</Button>
					</AddRoomMappingDialog>
				}
				columns={[
					{
						header: 'Existing Room Name',
						accessorKey: 'target_name',
						enableGlobalFilter: true,
						cell: ({ row }) => (
							<span className='py-1 text-p2 text-center'>
								{roomOptions.find((d) => d.id === row.original.target_id)?.name ?? (
									<span className='text-gray-400 italic text-p2'>No results found for this Room name</span>
								)}
							</span>
						),
					},
					{
						header: 'Multiple Room Aliases',
						accessorKey: 'alias_name',
						enableGlobalFilter: true,
						cell: ({ row }) => <span className='py-1 text-p2 text-center'>{row.original.alias_name}</span>,
					},
					{
						header: 'Date of Mapping',
						accessorKey: 'inserted_at',
						cell: ({ row }) => (
							<span className='py-1 text-p2 text-center'>
								{new Date(row.original.inserted_at).toLocaleString('en-US', {
									month: 'numeric',
									day: 'numeric',
									year: '2-digit',
									hour: 'numeric',
									minute: 'numeric',
									hour12: true,
								})}
							</span>
						),
						sortingFn: (rowA, rowB, columnId) => {
							const a = new Date(rowA.getValue(columnId)).getTime();
							const b = new Date(rowB.getValue(columnId)).getTime();
							return b > a ? 1 : -1;
						},
						enableGlobalFilter: false,
					},
					{
						id: 'actions',
						meta: {
							bodyClass: 'text-left',
						},
						cell: ({ row }) => {
							return isAdministrator ? (
								<div className='transition-opacity opacity-0 group-hover:opacity-100'>
									<ButtonLink
										isWorking={isLoading}
										onClick={async () => {
											// ask the user to confirm the deletion
											const alertResponse = await getAlertResponse({
												title: 'Confirm Undo Reconcilation',
												description: 'Are you sure you want to Undo this Room?',
												responseOptions: [
													{
														value: 'delete',
														label: 'Delete',
													},
												],
											});

											// handle the response from the user
											if (alertResponse === 'delete') {
												// adds the loading indicator NEXT to the specific item being deleted
												await setUndoAlias(row.original.id);
												// remove the loading indicator next to the user row of user being deleted
											}
										}}
									>
										Delete
									</ButtonLink>
								</div>
							) : null;
						},
					},
				]}
				data={aliasesData}
			/>
		</div>
	);
}

export default ExistingRoomsTable;
