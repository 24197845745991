import { useEffect, useState } from 'react';
import { format } from 'date-fns';

import { Button, DataTable, Drawer, ExportButton, FilterFields, LogoOverlay, PageHeading } from 'components';
import { useAppSelector, useGetFlipRoomsQuery } from 'store';
import { useFilters, useToast } from 'context';

import type { FlipRoomsResponse } from 'store';

export function FlipRooms() {
	const { selectedFacility } = useAppSelector((state) => state.userState);
	const {
		dateRange,
		daysOfWeek,
		serviceLines,
		encounterTypes,
		rooms,
		procedures,
		primetime,
		addOns,
		resetFilters,
		applyFilters,
		clearFilters,
		filtersAreDirty,
		currentPageLoaded,
		metadata,
	} = useFilters();
	const { createToast } = useToast();
	const [tableData, setTableData] = useState<FlipRoomsResponse['data']>([]);

	// There is sometimes a delay in our filters when a user switches pages
	// (which is why we check if currentPageLoaded is equal to our current page),
	// To account for the delay, we tell our RTK Query to skip until we set skipRequest to false.
	const [skipRequest, setSkipRequest] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			if (currentPageLoaded === '/flip-rooms') {
				setSkipRequest(false);
			}
		}, 0);
	}, [currentPageLoaded]);

	const {
		data: intraop_data,
		error,
		isFetching,
	} = useGetFlipRoomsQuery(
		{
			facility_id: selectedFacility,
			filters: {
				days_of_week: daysOfWeek?.applied,
				service_lines: serviceLines?.applied,
				encounter_types: encounterTypes?.applied,
				rooms: rooms?.applied,
				procedures: procedures?.applied.map((procedure) => procedure.name),
				start_date: format(dateRange?.applied.startDate, 'M/d/yyyy'),
				end_date: format(dateRange?.applied.endDate, 'M/d/yyyy'),
				primetime: primetime?.applied,
				add_ons: addOns?.applied,
			},
		},
		{
			skip: skipRequest,
		}
	);

	// When data from the endpoint changes or an error is encountered, update the
	// table data appropriately.
	useEffect(() => {
		if (error) {
			setTableData([]);

			if ('data' in error) {
				createToast({
					title: `${error.data}`,
					variant: 'error',
				});
			} else {
				createToast({
					title: 'There was an error connecting to the server.',
					variant: 'error',
				});
			}

			return;
		}

		setTableData(intraop_data?.data ?? []);
	}, [intraop_data, error, createToast]);

	return (
		<div>
			<PageHeading>Flip Room Dashboard</PageHeading>

			<div className='flex justify-end gap-2 pb-3 overflow-auto'>
				<Drawer
					metadata={metadata}
					filtersAreDirty={filtersAreDirty}
					trigger={
						<Button sizeX='sm' sizeY='md' variant={'primary-ghost'} className='mr-2'>
							<span className='material-symbol'>filter_alt</span>
							Filters
						</Button>
					}
					quickActions={[
						{
							icon: 'undo',
							onClick: resetFilters,
							tooltipText: 'Discard unapplied filter changes',
							disabled: !filtersAreDirty,
						},
						{
							icon: 'restart_alt',
							onClick: clearFilters,
							tooltipText: 'Reset filters to default',
							disabled: !metadata.saved_at,
						},
					]}
					actionButtons={[
						{
							onClick: applyFilters,
							children: 'Apply',
							disabled: !filtersAreDirty,
						},
						{
							// eslint-disable-next-line @typescript-eslint/no-empty-function
							onClick: () => {},
							children: 'Views',
							disabled: false,
						},
					]}
				>
					<FilterFields
						fields={[
							'dateRange',
							'daysOfWeek',
							'serviceLines',
							'rooms',
							'primetime',
							'addOns',
							'encounterTypes',
							'procedures',
						]}
					/>
				</Drawer>
				<ExportButton contents={intraop_data?.data ?? []} />
			</div>

			{isFetching || skipRequest ? (
				<LogoOverlay backgroundColor={'transparent'} />
			) : (
				<DataTable
					title='Flip Details by Surgeon'
					tooltipContent={`	
					Use this table to view surgeons with overlapping or concurrent cases. In general, judicious use of flip rooms takes into account overall schedule efficiency and the opportunity cost of potential downtime in a room reserved for a flipped case.`}
					goToHelpID={'fliprooms'}
					headerContentRight={
						<div className='inline-flex items-center justify-center relative drop-shadow border border-blue-500 rounded text-blue-900 w-11/12'>
							<span className='text-b3 font-semibold py-4 px-6'>
								{`${format(dateRange.selected.startDate, 'M/d/yyyy')} – ${format(dateRange.selected.endDate, 'M/d/yyyy')}`}
							</span>
						</div>
					}
					headerContentCenterAlignment='left'
					columns={[
						{ header: 'Surgeon Name', accessorKey: 'surgeon_name' },
						{ header: 'Total Case Vol', accessorKey: 'total_case_vol', enableGlobalFilter: false },
						{ header: 'Number of Flips', accessorKey: 'number_of_flips', enableGlobalFilter: false },
						{
							header: 'Flip Rate',
							accessorKey: 'flip_rate',
							cell: ({ row }) => `${row.original.flip_rate.toFixed(1)}%`,
							enableGlobalFilter: false,
						},
						{
							header: 'Avg Case Duration When Flipped',
							accessorKey: 'avg_case_duration_when_flipped',
							cell: ({ row }) => `${row.original.avg_case_duration_when_flipped} min`,
							enableGlobalFilter: false,
						},
						{
							header: 'Avg Procedure Time When Flipped',
							accessorKey: 'avg_procedure_time_when_flipped',
							cell: ({ row }) => `${row.original.avg_procedure_time_when_flipped} min`,
							enableGlobalFilter: false,
						},
						{ header: 'Avg Case Vol On Flip Days', accessorKey: 'avg_case_vol_on_flip_days', enableGlobalFilter: false },
					]}
					data={tableData}
				/>
			)}
		</div>
	);
}

export default FlipRooms;
