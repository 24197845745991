import * as Tabs from '@radix-ui/react-tabs';
import classNames from 'classnames';

import { Button, Checkbox, MultiSelect, Select, Timepicker, ToggleGroup } from 'components';

const tabTriggerClassname = classNames(
	'py-2 px-5 relative padding-x-3 text-bluegray-100',
	'data-active:text-blue-500 data-active:border-b-2 data-active:border-blue-500 data-active:top-[1px]'
);

export function BlockStepTwo() {
	return (
		<>
			<p className='font-bold text-p2 mb-1 text-blue-700'>Add New Block - Step 2</p>
			<p className='font-bold mt-4 mb-6 text-p3'>ADDING: [BLOCK_NAME]</p>
			<hr></hr>
			<div className='w-60 mb-6 mt-6'>
				<Select
					label='SERVICE LINE'
					options={[
						{
							value: 'option_1',
							label: 'Option 1',
						},
						{
							value: 'option_2',
							label: 'Option 2',
						},
						{
							value: 'option_3',
							label: 'Option 3',
						},
					]}
				/>
			</div>
			<Tabs.Root defaultValue='user' orientation='vertical'>
				<Tabs.List
					aria-label='Settings Tabs'
					className='flex border border-bluegray-100'
					style={{ justifyContent: 'space-evenly' }}
				>
					<Tabs.Trigger value='sunday' className={tabTriggerClassname}>
						Sunday
					</Tabs.Trigger>
					<Tabs.Trigger value='monday' className={tabTriggerClassname}>
						Monday
					</Tabs.Trigger>
					<Tabs.Trigger value='tuesday' className={tabTriggerClassname}>
						Tuesday
					</Tabs.Trigger>
					<Tabs.Trigger value='wednesday' className={tabTriggerClassname}>
						Wednesday
					</Tabs.Trigger>
					<Tabs.Trigger value='thursday' className={tabTriggerClassname}>
						Thursday
					</Tabs.Trigger>
					<Tabs.Trigger value='friday' className={tabTriggerClassname}>
						Friday
					</Tabs.Trigger>
					<Tabs.Trigger value='saturday' className={tabTriggerClassname}>
						Saturday
					</Tabs.Trigger>
				</Tabs.List>
				<Tabs.Content value='sunday'>
					<div className='mt-8'>
						<div className='w-60 mb-10'>
							<MultiSelect
								label='SURGEON NAME'
								options={[
									{
										value: 'option_1',
										label: 'Option 1',
									},
									{
										value: 'option_2',
										label: 'Option 2',
									},
									{
										value: 'option_3',
										label: 'Option 3',
									},
								]}
							/>
						</div>
						<div className='flex w-4/5'>
							<div className='mr-8'>
								<ToggleGroup
									label='Every other toggle'
									hideLabel
									className='w-28'
									options={[
										{ label: 'Yes', value: 'on' },
										{ label: 'No', value: 'off' },
									]}
								/>
							</div>
							<p className='text-p1 whiteSpace: pre'>Block follows an &ldquo; Every Other Week &ldquo; (E/O) pattern</p>
						</div>
						<div className='flex'>
							<div className='ml-36 italic w-4/5 text-gray-400'>
								Be sure to double check the Effective Start Date on the next page. Incorrect allocations typically happen when
								this day is not selected appropriately.
							</div>
						</div>
						<div className='mt-6 border border-bluegray-200'>
							<div className='flex font-bold mb-3'>
								<p className='mt-3 ml-3 mr-9'>WEEK OF MONTH</p>
								<p className='mt-3 ml-6 mr-9'></p>
								<p className='mt-3 ml-9'>START TIME</p>
								<p className='mt-3 ml-9'>END TIME</p>
								<p className='mt-3 ml-9'>HOURS</p>
								<p className='mt-3 ml-9'>TOTAL HOURS</p>
							</div>
							<div className='flex ml-3'>
								<Checkbox label='Week 1' value='E' />
								<p className='mt-1 ml-14'>1st Room</p>
								<div className='ml-3 w-3/5'>
									<Timepicker
										interval={15}
										minHour={7}
										maxHour={19}
										onChange={(time) => {
											// eslint-disable-next-line
											return null;
										}}
									/>
								</div>
								<div className='ml-3 w-3/5'>
									<Timepicker
										interval={15}
										minHour={7}
										maxHour={19}
										onChange={(time) => {
											// eslint-disable-next-line
											return null;
										}}
									/>
								</div>
								<div className='ml-3'>
									<p>8</p>
								</div>
								<div className='ml-3'>
									<p>12</p>
								</div>
							</div>
						</div>
					</div>
				</Tabs.Content>
				<Tabs.Content value='account'>Test</Tabs.Content>
				<Tabs.Content value='facility-settings'>Test</Tabs.Content>
				<Tabs.Content value='reconcile'>Test</Tabs.Content>
				<Tabs.Content value='block'>Test</Tabs.Content>
				<Tabs.Content value='facility-config'>Test</Tabs.Content>
			</Tabs.Root>
			<div className='flex'>
				<div className='w-80'></div>
				<div className='mt-40 ml-80 justifyContent: right'>
					<Button sizeX='sm' sizeY='sm' variant='primary'>
						Next
					</Button>
				</div>
			</div>
		</>
	);
}
