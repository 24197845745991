// Third-party modules
import { useCallback, useEffect, useRef, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import classNames from 'classnames';
import { debounce } from 'lodash';

// Local modules and components
import CalendarHeatmap from 'react-calendar-heatmap';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
	addMonths,
	differenceInCalendarWeeks,
	eachDayOfInterval,
	endOfMonth,
	format,
	getDay,
	startOfMonth,
	subMonths,
} from 'date-fns';
import { string } from 'yup';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
	Accordion,
	Button,
	Checkbox,
	DataTable,
	DateToggle,
	Dialog,
	NumberField,
	TextField,
	ToggleGroup,
	Tooltip,
} from 'components';
import { BlockFlow, BlockPatternFlow, BlockPreviewHeader, ColumnMap, LoadingScreen } from 'pages';
import {
	BulkReleaseItem,
	useGetBlockScorecardQuery,
	useGetBulkReleaseLogQuery,
	useSetBulkReleaseMutation,
	useUndoBulkReleaseMutation,
} from 'store/services/BlockScorecardService';
import { Facility, FacilityBlockSetting, FacilityLicense, UserRole } from 'models';
import {
	checkLicenseBits,
	clamp,
	dayStartMinute,
	getFacilityName,
	getNameOfDay,
	shortDOWToNumber,
	toFullDate,
} from 'utils';
import {
	getQueryError,
	handleApiResponse,
	useAppSelector,
	useGetBlockPatternPreviewQuery,
	useGetBlockPatternReleasesQuery,
	useGetBlockSettingsQuery,
	useGetSystemsQuery,
	useSetBlockSettingsMutation,
	useSetFacilityBlockSettingsMutation,
	useSystem,
} from 'store';
import { useAlert, useFilters, useToast } from 'context';
import { NoFacilitySelected } from 'components/EmptyStates';

// Type imports
import type { BlockSetting } from 'models';
import type { BlockPatternPreviewObject, BlockPatternReleaseItem, BlockSettingsApiRequest, ShortOption } from 'store';

const blockToggleOptions = [
	{ label: 'On', value: 'on' },
	{ label: 'Off', value: 'off' },
];

const today = new Date();
const endDate = addMonths(today, 4);
const startDate = subMonths(today, 4);
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const januaryFirst = new Date(currentYear, 0, 1);

export function normalize(value: number, oldMin: number, oldMax: number, newMin: number, newMax: number) {
	return Math.round(((value - oldMin) / (oldMax - oldMin)) * (newMax - newMin) + newMin);
}

export const max_mins = 1800;
export const min_mins = 300;
export interface ReleaseMap {
	[key: number]: BlockPatternReleaseItem[];
}

export function BlockSettings() {
	const toast = useToast();
	const [updateBlockSettings] = useSetBlockSettingsMutation();
	const [updateFacilityBlockSettings] = useSetFacilityBlockSettingsMutation();
	const { data: facility_data } = useSystem();

	const isAdmin = facility_data?.user.role === UserRole.admin;
	const { selectedSystem, selectedFacility: selectedFacilityId } = useAppSelector((state) => state.userState);
	const selectedFacility = facility_data?.facilities?.filter((f) => f.id === selectedFacilityId)[0];
	const { data, isLoading, isSuccess } = useGetBlockSettingsQuery({
		healthsystem_id: selectedSystem,
	});

	// we are using this to prevent a toast from rendering on initial load
	const [isChanged, setIsChanged] = useState(false);
	const [isFacilityChanges, setIsFacilityChanges] = useState(false);

	// ordinarily we'd use camelCase for local state properties, but since this needs to mirror the API request/response bodies
	// it's simpler to just break the naming convention in this particular area
	const [toggleStates, setToggleStates] = useState<BlockSetting>({
		block_utilization_facility_agnostic: !!data?.block_settings.block_utilization_facility_agnostic,
		allow_block_overbooking: !!data?.block_settings.allow_block_overbooking,
	});
	const initialData: FacilityBlockSetting[] =
		facility_data?.facilities.map((d) => ({
			release_precedence: d.release_precedence,
			allow_partial_release: d.allow_partial_release,
			block_utilization_target: d.block_utilization_target,
			facility_id: d.id,
		})) ?? [];

	const [facilityState, setFacilityState] = useState<FacilityBlockSetting>();

	/**
	 * When we load the response object (data) we need to update toggleStates on success. We need this so that toggleStates gets updated on initial load and any refetching of the block settings state via the API
	 * We need this so that toggleStates gets updated on initial load and any refetching of the block settings state via the API
	 */
	useEffect(() => {
		if (isSuccess && data) {
			setToggleStates({
				block_utilization_facility_agnostic: !!data?.block_settings.block_utilization_facility_agnostic,
				allow_block_overbooking: !!data?.block_settings.allow_block_overbooking,
			});
		}
	}, [isSuccess, data, setToggleStates]);

	const debouncedUpdate = useRef(
		debounce(async (payload: BlockSetting) => {
			const requestBody: BlockSettingsApiRequest = {
				block_settings: {
					block_utilization_facility_agnostic: payload.block_utilization_facility_agnostic,
					allow_block_overbooking: payload.allow_block_overbooking,
				},
				healthsystem_id: selectedSystem,
			};

			const response = await updateBlockSettings(requestBody);
			handleApiResponse(response, {
				success: () => {
					toast.createToast({
						title: `Block settings for the current Health System were successfully updated.`,
						variant: 'success',
					});
				},
				error: (payload) => {
					toast.createToast({
						title: getQueryError(payload),
						variant: 'error',
					});
				},
			});
		}, 2000)
	).current;

	const debouncedFacilityUpdate = useRef(
		debounce(async (payload: FacilityBlockSetting) => {
			const requestBody: FacilityBlockSetting = {
				release_precedence: payload.release_precedence,
				allow_partial_release: payload.allow_partial_release,
				block_utilization_target: payload.block_utilization_target,
				facility_id: payload.facility_id,
			};

			const response = await updateFacilityBlockSettings(requestBody);
			handleApiResponse(response, {
				success: () => {
					toast.createToast({
						title: `Block settings for the current Health System were successfully updated.`,
						variant: 'success',
					});
				},
				error: (payload) => {
					toast.createToast({
						title: getQueryError(payload),
						variant: 'error',
					});
				},
			});
		}, 2000)
	).current;

	// Only send update to debouncer function
	useEffect(() => {
		if (isChanged) {
			debouncedUpdate(toggleStates);
			// Since there's no submit button on this page, we need to set a flag to prevent an API 'update' call on initial render
			setIsChanged(false);
		}
		if (isFacilityChanges) {
			facilityState && debouncedFacilityUpdate(facilityState);
			setIsFacilityChanges(false);
		}
	}, [
		toggleStates,
		updateBlockSettings,
		isChanged,
		setIsChanged,
		toast,
		debouncedUpdate,
		debouncedFacilityUpdate,
		facilityState,
		isFacilityChanges,
	]);

	async function handleChange(selected: string, field: keyof BlockSetting) {
		setToggleStates({
			...toggleStates,
			[field]: selected === 'on' ? true : false,
		});
		setIsChanged(true);
	}
	async function handleFacilityChange(selected: string, field: string, facility_id: number) {
		const data = {
			...(initialData.find((f) => f.facility_id === facility_id) as FacilityBlockSetting),
			[field]: selected === 'on' ? true : false,
		};
		data && setFacilityState(data);

		setIsFacilityChanges(true);
	}
	async function handleBlockPercentChange(selected: number, field: keyof FacilityBlockSetting, facility_id: number) {
		const data = {
			...(initialData.find((f) => f.facility_id === facility_id) as FacilityBlockSetting),
			[field]: selected,
		};
		setFacilityState(data);
		setIsFacilityChanges(true);
	}

	// TODO: replace with loading animation state one day
	if (isLoading) {
		return <LoadingScreen message='Loading...' />;
	}

	if (!checkLicenseBits(selectedFacility?.license, FacilityLicense.block)) {
		return <p>You don&rsquo;t have access to this tab.</p>;
	}
	if (!selectedFacility) {
		return <NoFacilitySelected />;
	}

	return (
		<div>
			<h2 className='text-h4 mt-6 mb-10'>Health System Settings</h2>
			<div className='flex my-8'>
				<div className='w-80 mr-12'>
					<p className='font-bold text-p2'>Give Block Credit Regardless of Facility</p>
					<p className='mt-1 text-p3 text-gray-400 italic'>
						Credit blocks regardless of which facility a case was performed in, as long as it happened within the bounds of
						the allocated date and time range.
					</p>
				</div>
				<div>
					<ToggleGroup
						label='Credit blocks regardless of which facility a case was performed in, as long as it happened within the bounds of the allocated date and time range'
						hideLabel
						className='w-28'
						options={blockToggleOptions}
						defaultValue={convertSettingToToggleOption(data?.block_settings.block_utilization_facility_agnostic)}
						onValueChange={(selected) => handleChange(selected, 'block_utilization_facility_agnostic')}
					/>
				</div>
			</div>

			<div className='flex my-8'>
				<div className='w-80 mr-12'>
					<p className='font-bold text-p2'>Allow Block Overbooking</p>
					<p className='mt-1 text-p3 text-gray-400 italic'>
						Set whether a configuration of the schedule should allow/disallow the multi-booking of a surgeon/surgeons on the
						same day if they belong to multiple blocks.
					</p>
				</div>
				<div>
					<ToggleGroup
						label='Allow Block Overbooking'
						hideLabel
						className='w-28'
						options={blockToggleOptions}
						defaultValue={convertSettingToToggleOption(data?.block_settings.allow_block_overbooking)}
						onValueChange={(selected) => handleChange(selected, 'allow_block_overbooking')}
					/>
				</div>
			</div>

			{facility_data?.facilities
				?.filter((f) => checkLicenseBits(f.license, FacilityLicense.block))
				.map((facility: Facility, indx: number) => (
					<Accordion
						type='multiple'
						itemStyle={facility?.name.includes('*') ? 'dark' : 'contained'}
						key={indx}
						className='my-2'
					>
						<Accordion.Item value={getFacilityName(facility)}>
							{!facility?.name.includes('*') && (
								<>
									<div className='flex my-8'>
										<div className='w-80 mr-12'>
											<p className='font-bold text-p2'>Negate voluntary release credit</p>
											<p className='mt-1 text-p3 text-gray-400 italic'>
												Negate voluntary release credit if a case is performed in the released block.
											</p>
										</div>
										<div>
											<ToggleGroup
												label='Negate voluntary release credit if a case is performed in the released block'
												hideLabel
												className='w-28'
												options={blockToggleOptions}
												defaultValue={convertSettingToToggleOption(facility.release_precedence)}
												onValueChange={(selected) => handleFacilityChange(selected, 'release_precedence', facility.id)}
											/>
										</div>
									</div>
									<div className='flex my-8'>
										<div className='w-80 mr-12'>
											<p className='font-bold text-p2'>Allow Partial Voluntary Block Releases</p>
											<p className='mt-1 text-p3 text-gray-400 italic'>This will include release for any second or flip rooms.</p>
										</div>
										<div>
											<ToggleGroup
												label='Allow Partial Voluntary Block Releases (including second/flip rooms)'
												hideLabel
												className='w-28'
												options={blockToggleOptions}
												defaultValue={convertSettingToToggleOption(facility.allow_partial_release)}
												onValueChange={(selected) => handleFacilityChange(selected, 'allow_partial_release', facility.id)}
											/>
										</div>
									</div>
								</>
							)}
							<div className='flex my-8'>
								<div className='w-80 mr-12'>
									<p className='font-bold text-p2'>Block Utilization Target Percentage</p>
									<p className='mt-1 text-p3 text-gray-400 italic'>
										Change this setting to modify what the minimum target percentage should be.
									</p>
								</div>
								<div>
									<NumberField
										label='Target Percentage (%)'
										min={40}
										max={85}
										step={5}
										sizeX={'sm'}
										onChange={(e) => {
											if (!e.currentTarget.value) return;
											handleBlockPercentChange(Number(e.currentTarget.value), 'block_utilization_target', facility.id);
										}}
										defaultValue={facility.block_utilization_target}
									/>
								</div>
							</div>
							{!facility?.name.includes('*') && isAdmin && <></>}
						</Accordion.Item>
					</Accordion>
				))}
		</div>
	);
}

export function FacilityNameDisplay({ block_option }: { block_option: ShortOption }) {
	const { data: blockPatternPreview } = useGetBlockPatternPreviewQuery({
		selected_block: block_option, // contains block_id
	});
	const { selectedSystem } = useAppSelector((state) => state.userState);
	const { data: allFacilities } = useGetSystemsQuery({
		healthsystem_id: selectedSystem,
	});
	const pattern_facilities = blockPatternPreview?.data.map((f) => f.assigned_facility_id);
	const facility_names = allFacilities?.facilities.filter((f) => pattern_facilities?.includes(f.id));
	const names_length = facility_names?.length;
	return (
		<div className='flex flex-row text-gray-500 flex-wrap text-p3 w-28'>
			{facility_names?.map(
				(facility: Facility, indx: number) =>
					`${facility.intraop_facility_name_alias ? facility.intraop_facility_name_alias : facility.name}${
						indx !== (names_length ?? 1) - 1 ? ', ' : ''
					}`
			)}
		</div>
	);
}

interface UtilItem {
	utilization: number;
	voluntary_release: number;
	automatic_release: number;
}

interface ProjectionValue {
	date: string;
	type?: string;
	day_of_week: string;
	week_of_month: number | string;
	count?: number;
	facility?: Facility;
	released_mins?: number;
	passed_utilization?: boolean;
	util_details?: {
		utilization: number;
		voluntary_release: number;
		automatic_release: number;
	};
	meta?: BlockPatternPreviewObject;
}

interface ProjectionMap {
	[key: string]: ProjectionValue[];
}

export function getDatesWithWeekAndDayInfo(): ProjectionMap {
	const daysInterval = eachDayOfInterval({ start: startDate, end: endDate });
	const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

	const dates = daysInterval.map((date) => {
		const day_of_week = daysOfWeek[getDay(date)];
		const week_of_month = Math.ceil(date.getDate() / 7);

		return {
			date: date.toLocaleDateString(),
			day_of_week,
			week_of_month,
		};
	});

	const pattern_map: ProjectionMap = {};

	for (let i = 0; i < dates.length; i++) {
		// Day of week and week of month mapping
		// Fri_1 = [array of all first Friday of the week]
		const key_value = `${dates[i].day_of_week}_${dates[i].week_of_month}`;

		if (pattern_map[key_value] === undefined) {
			pattern_map[key_value] = [{ ...dates[i], count: 1 }];
		} else {
			pattern_map[key_value].push({ ...dates[i], count: 1 });
		}
	}

	return pattern_map;
}

export function BlockPatternRowItem({
	block_option,
	dates,
	release_map,
	facility_id,
	healthsystem_id,
}: {
	block_option: ShortOption;
	dates: ProjectionMap;
	release_map: ReleaseMap;
	facility_id: number;
	healthsystem_id: number;
}) {
	const { daysOfWeek, utilizationType, turnoverTimeThreshold } = useFilters();
	const { data: blockPatternPreview, isFetching: fetchingPattern } = useGetBlockPatternPreviewQuery({
		selected_block: block_option, // contains block_id
		window_start: startDate,
		window_end: endDate,
	});
	const { data: facility_data } = useSystem();
	const { data: blockDetails, isFetching: fetchingDetails } = useGetBlockScorecardQuery({
		facility_id: facility_id,
		healthsystem_id: healthsystem_id,
		block_id: [block_option.id],
		groupBy: 'day_of_week',
		filters: {
			days_of_week: daysOfWeek.applied,
			utilization_type: utilizationType.applied,
			turnover_threshold: turnoverTimeThreshold.applied,
			start_date: format(startDate, 'M/d/yyyy'),
			end_date: format(today, 'M/d/yyyy'),
			abandoned_days_toggle: 'Included',
		},
	});

	// Parsing block details
	const day_block_detail_map: {
		[key: string]: {
			utilization: number;
			voluntary_release: number;
			automatic_release: number;
		};
	} = {};

	blockDetails?.forEach((d) => {
		d.block_details.forEach((b) => {
			day_block_detail_map[new Date(`${b.date} 00:00:00`).toLocaleDateString()] = {
				utilization: b.utilization,
				voluntary_release: b.voluntary_release,
				automatic_release: b.automatic_release,
			};
		});
	});

	// Creating dates object to feed into heatmap
	const show_dates = blockPatternPreview?.data.map((pattern) => {
		const release_dates =
			release_map[pattern.block_pattern_id]?.map((release) => new Date(release.date).toLocaleDateString()) ?? [];

		const final_arr = pattern.week_of_month.map((week) => {
			// dates hash table only contains values for all non E/O combos
			// so we need to project forward for the pattern by generating the dates based on ones already in case_details
			let eo_dates: ProjectionValue[] = [];
			if (`${week}` === 'E/O') {
				// populate passed days worked
				eo_dates = Object.keys(day_block_detail_map)
					.map((key) => ({
						date: key,
						day_of_week: getNameOfDay(key),
						week_of_month: `E/O`,
					}))
					.filter((d) => d.day_of_week === pattern.day_of_week);

				// find the closest E/O date to today based on start date
				const date1: Date = new Date(pattern.start_date);
				const date2: Date = new Date(); // today
				const diffTime: number = Math.abs(date2.getTime() - date1.getTime());
				const diffDays: number = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
				const diffWeeks: number = Math.ceil(diffDays / 7);
				const date: Date = new Date(pattern.start_date);
				date.setDate(date.getDate() + 1); // it seems to be always off by 1 day, it's a javascript bug

				// project forward x weeks to create light blue tiles
				date.setDate(date.getDate() + diffWeeks * 7);

				for (let i = 0; i < 24; i++) {
					// only show every other dow
					if ((i + 1) % 2 === 0) {
						if (`${date}` !== 'Invalid Date') {
							eo_dates.push({
								date: format(date, 'M/d/yyyy'),
								day_of_week: getNameOfDay(format(date, 'M/d/yyyy')),
								week_of_month: `E/O`,
							});
						}
					}

					// go to next week
					date.setDate(date.getDate() + 7);
				}
			}

			return (`${week}` === 'E/O' ? eo_dates : dates[`${pattern.day_of_week}_${week}`])?.map((date: ProjectionValue) => {
				date.type = 'normal';
				date.meta = pattern;
				date.count = pattern.total_min;
				date.facility = facility_data?.facilities?.filter((f) => f.id === pattern.assigned_facility_id)[0];

				// check for release:
				if (release_dates.length > 0 && release_dates.includes(date.date)) {
					const release_date = release_dates.indexOf(date.date);
					date.type = 'release';
					date.released_mins = release_map[pattern.block_pattern_id][release_date].released_minutes;
				}

				// check for usage
				if (day_block_detail_map[date.date]) {
					if (
						day_block_detail_map[date.date].utilization === -1 ||
						(day_block_detail_map[date.date].voluntary_release === 0 && day_block_detail_map[date.date].voluntary_release > 0)
					) {
						date.type = 'release';
					} else if (
						day_block_detail_map[date.date].utilization === 0 &&
						day_block_detail_map[date.date].voluntary_release === 0
					) {
						date.type = 'automatic_release';
					} else if (day_block_detail_map[date.date].utilization > 0) {
						date.type = 'used';
						date.passed_utilization =
							day_block_detail_map[date.date].utilization >= (date.facility?.block_utilization_target ?? 0);
					}
					date.util_details = day_block_detail_map[date.date];
				}

				return date;
			});
		});
		return final_arr;
	});

	const scheduled_dates = show_dates?.flat().flat();
	const adjusted_dates = scheduled_dates?.map((data) => {
		const new_date = adjustDatesForDST(new Date(`${data?.date ?? '1/1/2023'} 00:00:00`));
		const ended = new_date > new Date(data?.meta?.end_date ?? new Date());
		return {
			date: ended ? new Date() : new_date,
			in_past: new_date < today,
			ended: ended,
			passed_utilization: data?.passed_utilization,
			type: data?.type,
			facility: data?.facility,
			released_mins: data?.released_mins,
			day_of_week: data?.day_of_week,
			week_of_month: data?.week_of_month,
			count: data?.count,
			util_details: data?.util_details,
			meta: data?.meta,
		};
	});

	// upcoming for different facilites is colored differently
	const color_palette = ['fill-[#00cec9] opacity-30', 'fill-[#9c88ff85]', 'fill-[#e84393] opacity-40'];
	const navigate = useNavigate();

	return (
		<>
			{fetchingPattern && fetchingDetails && <div className='fastPulse bg-gray-50 rounded-md h-[8em] w-[45em]'></div>}
			{!(fetchingPattern && fetchingDetails) && (scheduled_dates?.length ?? 0) > 0 && (
				<>
					<ReactTooltip id='my-tooltip' style={{ backgroundColor: 'rgb(255, 255, 255)' }} />
					<CalendarHeatmap
						startDate={startDate}
						endDate={endDate}
						values={adjusted_dates ?? []}
						onClick={(data) => {
							if (data) {
								if (
									data.in_past &&
									data.type !== 'automatic_release' &&
									(data.type === 'release'
										? parseInt(data.util_details.voluntary_release) !== parseInt(data.meta.total_min)
										: true) &&
									data.util_details
								) {
									navigate(
										`/block-scorecard/case-details?block_detail_id=${
											block_option.id
										}&block_date=${data.date.toLocaleDateString()}&util_type=${'Adjusted'}&turnover_threshold=${60}&block_name=${
											block_option.name
										}`
									);
								}
							}
						}}
						classForValue={(value) => {
							// handle null values
							if (!value) {
								return 'color-empty';
							}
							if (value.ended) {
								return 'color-empty';
							}

							// handle color map for automatic release, voluntary release and used
							if (value?.type) {
								if (value?.type !== 'normal') {
									if (value.type === 'used') {
										if (value?.passed_utilization) {
											return `color-scale-util-passed-${normalize(
												value.util_details?.utilization > 100 ? 100 : value.util_details?.utilization ?? 1,
												1,
												100,
												1,
												7
											)}`;
										} else {
											return `color-scale-util-failed-${normalize(
												value.util_details?.utilization > 100 ? 100 : value.util_details?.utilization ?? 1,
												1,
												100,
												1,
												7
											)}`;
										}
									}

									if (value.type === 'automatic_release') {
										return `color-scale-automatic-${normalize(
											value.util_details?.automatic_release > 1400 ? 1400 : value.util_details?.automatic_release ?? 60,
											60,
											1400,
											1,
											7
										)}`;
									}

									if (value.type === 'release') {
										return `color-scale-release-${normalize(
											(value?.util_details?.voluntary_release ?? value.released_mins) > 1400
												? 1400
												: value?.util_details?.voluntary_release ?? value.released_mins ?? 60,
											60,
											1400,
											1,
											6
										)}`;
									}

									return `color-scale-${value.type}`;
								}
							}

							// handle color map for past
							if (value.in_past) {
								return 'color-empty';
							}

							// handle color map for different facilities
							if (value.facility.id !== facility_id) {
								return color_palette[value.facility.id % (color_palette.length - 1)];
							}

							// handle release heat
							const scale = normalize(value?.count ?? 1, min_mins, max_mins, 1, 4);

							return `color-scale-${scale}`;
						}}
						showOutOfRangeDays={true}
						showWeekdayLabels={true}
						tooltipDataAttrs={(value: {
							type: string;
							util_details: UtilItem;
							date: Date;
							passed_utilization: boolean;
							in_past: boolean;
							count: number;
							meta: BlockPatternPreviewObject;
							released_mins: number;
							facility: Facility;
						}) => {
							return {
								'data-tooltip-id': 'my-tooltip',
								'data-tooltip-html': value.date
									? (value.type === 'normal' &&
											!value.in_past &&
											renderToStaticMarkup(
												<>
													<div className='flex flex-col gap-2 text-black w-fit mt-1 mb-1'>
														<div className='flex flex-col mb-2'>
															<p className='text-left font-bold uppercase text-blue-500 pl-2 m-0'>Upcoming</p>
															<p className='text-left text-black text-[0.8em] opacity-50 pl-2'>{value.date.toLocaleDateString()}</p>
														</div>
														<div className='flex justify-between px-2'>
															<p className='text-right font-semibold uppercase text-black'>{value.meta?.day_of_week}</p>
															<p className='pl-1 text-black'>{String(value.meta?.week_of_month)}</p>
														</div>
														<p className='text-p3 bg-blue-50 w-fit pt-1 pb-0.5 px-4 font-semibold rounded-2xl whitespace-nowrap self-center'>
															{value.meta?.range}
														</p>
														<p className='px-2 text-black'>({value.count} min)</p>
													</div>
													<p className='text-left text-black text-[0.8em] opacity-50 pl-2 mt-2'>
														{(value.facility.intraop_facility_name_alias !== ''
															? value.facility.intraop_facility_name_alias
															: undefined) ?? value.facility.name}
													</p>
												</>
											)) ||
									  (value.type === 'release' &&
											renderToStaticMarkup(
												<>
													<div className='flex flex-col text-black w-fit pl-2'>
														<div className='flex flex-row items-center'>
															<p className='text-black text-left font-semibold text-p1 pb-0.5 pr-1'>
																{value?.util_details?.voluntary_release ?? value.released_mins}
															</p>
															<p className='text-left text-black text-[0.8em] opacity-50'>of {value.meta.total_min} minutes</p>
														</div>

														<p className='text-left font-bold uppercase text-yellow-400 m-0 p-0'>Released</p>
														<p className='text-left text-black text-[0.8em] opacity-50'>{value.date.toLocaleDateString()}</p>
													</div>
													<div className='flex flex-col gap-2 text-black w-fit mt-5 mb-3'>
														<div className='flex justify-between px-2'>
															<p className='text-right font-semibold uppercase text-black'>{value.meta?.day_of_week}</p>
															<p className='pl-1 text-black'>{String(value.meta?.week_of_month)}</p>
														</div>
														<p className='text-p3 bg-blue-50 w-fit pt-1 pb-0.5 px-4 font-semibold rounded-2xl whitespace-nowrap self-center'>
															{value.meta?.range}
														</p>
														<p className='px-2 text-black'>({value.count} min)</p>
													</div>
													<p className='text-left text-black text-[0.8em] opacity-50 pl-2 mt-2'>
														{(value.facility.intraop_facility_name_alias !== ''
															? value.facility.intraop_facility_name_alias
															: undefined) ?? value.facility.name}
													</p>
												</>
											)) ||
									  (value.type === 'automatic_release' &&
											renderToStaticMarkup(
												<>
													<div className='flex flex-col text-black w-fit pl-2'>
														<div className='flex flex-row items-center'>
															<p className='text-black text-left font-semibold text-p1 pb-0.5 pr-1'>
																{value?.util_details?.automatic_release}
															</p>
															<p className='text-left text-black text-[0.8em] opacity-50'>minutes</p>
														</div>

														<p className='text-left font-bold uppercase text-[#353b48] opacity-80 m-0 p-0'>Abandoned</p>
														<p className='text-left text-black text-[0.8em] opacity-50'>On {value.date.toLocaleDateString()}</p>
													</div>
													<div className='flex flex-col gap-2 text-black w-fit mt-5 mb-3'>
														<div className='flex justify-between px-2'>
															<p className='text-right font-semibold uppercase text-black'>{value.meta?.day_of_week}</p>
															<p className='pl-1 text-black'>{String(value.meta?.week_of_month)}</p>
														</div>
														<p className='text-p3 bg-blue-50 w-fit pt-1 pb-0.5 px-4 font-semibold rounded-2xl whitespace-nowrap self-center'>
															{value.meta?.range}
														</p>
														<p className='px-2 text-black'>({value.count} min)</p>
													</div>
													<p className='text-left text-black text-[0.8em] opacity-50 pl-2 mt-2'>
														{(value.facility.intraop_facility_name_alias !== ''
															? value.facility.intraop_facility_name_alias
															: undefined) ?? value.facility.name}
													</p>
												</>
											)) ||
									  (value.type === 'used' &&
											renderToStaticMarkup(
												<>
													<div className='flex flex-col text-black w-fit pl-2'>
														<p className='text-black text-left font-semibold text-p1 pb-0.5'>{value?.util_details?.utilization}%</p>

														<p
															className={`text-left font-bold uppercase ${
																value.passed_utilization ? 'text-green-500' : 'text-red-500'
															} p-0 m-0`}
														>
															utilization
														</p>
														<p className='text-left text-black text-[0.8em] opacity-50'>On {value.date.toLocaleDateString()}</p>
													</div>
													<div className='flex flex-col gap-2 text-black w-fit mt-5 mb-3'>
														<div className='flex justify-between px-2'>
															<p className='text-right font-semibold uppercase text-black'>{value.meta?.day_of_week}</p>
															<p className='pl-1 text-black'>{String(value.meta?.week_of_month)}</p>
														</div>
														<p className='text-p3 bg-blue-50 w-fit pt-1 pb-0.5 px-4 font-semibold rounded-2xl whitespace-nowrap self-center'>
															{value.meta?.range}
														</p>
														<p className='px-2 text-black'>({value.count} min)</p>
													</div>
													<p className='text-left text-black text-[0.8em] opacity-50 pl-2 mt-2'>
														{(value.facility.intraop_facility_name_alias !== ''
															? value.facility.intraop_facility_name_alias
															: undefined) ?? value.facility.name}
													</p>
												</>
											)) ||
									  (value.in_past &&
											value.type === 'normal' &&
											renderToStaticMarkup(
												<div className='flex flex-col gap-2 text-black w-fit'>
													<p className='text-left text-black pl-2'>No pattern found</p>
												</div>
											))
									: renderToStaticMarkup(
											<div className='flex flex-col gap-2 text-black w-fit'>
												<p className='text-left text-black pl-2'>No pattern found</p>
											</div>
									  ),
							};
						}}
					/>
				</>
			)}
			{!(fetchingPattern && fetchingDetails) && (scheduled_dates?.length ?? 0) === 0 && (
				<p className='text-gray-300 text-i3 h-[8em] w-[45em] m-auto pt-9'>No block patterns exist for this block.</p>
			)}
		</>
	);
}

// Converts a date to a number for sorting expects date in M/D/Y format
function dateToNumber(date: string): number {
	const dateArray = date.split('/');
	const day = dateArray[1];
	const month = dateArray[0];
	const year = dateArray[2];

	return parseInt(`${year}${month}${day}`);
}

export function BulkReleaseDates({
	children,
	facility_id,
	healthsystem_id,
}: {
	children: React.ReactNode;
	facility_id: number | null;
	healthsystem_id: number | null;
}) {
	const { data: releaseLog } = useGetBulkReleaseLogQuery({
		facility_id: facility_id ?? 0,
		healthsystem_id: healthsystem_id,
	});
	const data = releaseLog?.log ?? [];
	const { createToast } = useToast();
	const [dialogOpen, setDialogOpen] = useState(false);
	const [undoReleaseList, setUndoReleaseList] = useState<BulkReleaseItem[]>([]);
	const [addBulkRelease, { status: addStatus }] = useSetBulkReleaseMutation();
	const [undoBulkRelease, { status: undoStatus }] = useUndoBulkReleaseMutation();
	const [addBulkReleaseConfirmed, setAddBulkReleaseConfirmed] = useState(false);
	const [undoBulkReleaseConfirmed, setUndoBulkReleaseConfirmed] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date());

	const addBulkReleaseSubmit = async () => {
		const response = await addBulkRelease({ healthsystem_id, facility_id, date: toFullDate(selectedDate) });

		handleApiResponse(response, {
			success: () => {
				createToast({
					title: 'Bulk release successfully inserted',
					variant: 'success',
				});
				setAddBulkReleaseConfirmed(false);
			},
			error: (payload) => {
				createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
			},
		});
	};

	const undoBulkReleaseSubmit = async () => {
		const response = await undoBulkRelease({
			healthsystem_id,
			facility_id,
			dates: undoReleaseList.map((release) => toFullDate(release.date)),
		});

		handleApiResponse(response, {
			success: () => {
				createToast({
					title: 'Bulk release successfully undone',
					variant: 'success',
				});
				setUndoBulkReleaseConfirmed(false);
			},
			error: (payload) => {
				createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
			},
		});
	};

	useEffect(() => {
		if (undoStatus === 'fulfilled') {
			setUndoReleaseList([]);
		}
	}, [undoStatus]);

	const { getAlertResponse } = useAlert();

	const handleApply = (d: Date) => {
		setSelectedDate(d);
	};

	const [globalFilter, setGlobalFilter] = useState<string>('');

	const updateFilterText = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
		setGlobalFilter(e.target.value);
	}, 1000);

	const filteredData = data.filter((element) => {
		if (globalFilter?.length > 0) {
			const date = toFullDate(new Date(element.date));
			if (date.includes(globalFilter)) {
				return true;
			}
			return false;
		} else {
			return true;
		}
	});
	const [releaseButtonDisabled, setReleaseButtonDisabled] = useState(false);
	const bulkReleasedDates = filteredData.map((fd) => toFullDate(fd.date));

	return (
		<Dialog
			actionButtons={[]}
			minimalHeaderStyle
			sizeX='lg_wide'
			title={`Bulk Release Log`}
			trigger={children}
			open={dialogOpen}
			onOpenChange={async (isBeingOpened) => {
				if (isBeingOpened) {
					setDialogOpen(true);
					return;
				}
				if (!isBeingOpened) {
					setDialogOpen(false);
				}
			}}
		>
			<div
				className={classNames(
					{ 'max-h-72': window.innerHeight < 600, 'max-h-96': window.innerHeight > 600 },
					'overflow-y-auto'
				)}
			>
				<div className='flex pl-7 pr-7 border-b border-gray-200 pb-7'>
					<div className='mr-10'>
						<div className='flex space-x-5'>
							{' '}
							<DateToggle key={selectedDate.getTime()} selected={selectedDate} onApply={handleApply} />
							<Button
								sizeX='md'
								sizeY='md'
								onClick={async () => {
									const alertResponse = await getAlertResponse({
										description: `You are about to release ${toFullDate(selectedDate)} for all block patterns. 
										Please note that bulk release only impacts block patterns with no existing voluntary release on this day. 
										Do you want to proceed?`,
										responseOptions: [
											{
												value: '1',
												label: 'Yes',
											},
										],
										doNothingResponse: {
											value: '0',
											label: 'No',
										},
									});
									setAddBulkReleaseConfirmed(alertResponse === '1' ?? false);
									if (alertResponse === '1') {
										addBulkReleaseSubmit();
									}
								}}
								isWorking={addStatus === 'pending' ? true : false}
								disabled={releaseButtonDisabled}
							>
								Release Date
							</Button>
							<div className='flex shrink-0 items-center'>
								<TextField
									label='Search'
									icon='search'
									iconClass='text-blue-500'
									hideLabel
									onChange={updateFilterText}
									placeholder='Search rows'
								/>

								<Tooltip content={<div className='text-p2'>This table allows searching within the date column</div>}>
									<div className='material-symbol-sm text-gray-500 pl-1'>info</div>
								</Tooltip>
							</div>
						</div>
					</div>
				</div>
				<DataTable
					title=''
					disablePagination
					maxHeight='80'
					minHeight='32em'
					minimalStyle
					columns={[
						{
							header: 'Undo',
							accessorKey: '',
							cell: ({ row }) => (
								<Checkbox
									label=''
									checked={
										undoReleaseList.filter((inner) => {
											return inner.date === row.original.date;
										}).length > 0
											? true
											: false
									}
									onChange={(event) => {
										const isChecked = event.target.checked;
										if (isChecked) {
											setUndoReleaseList([...undoReleaseList, ...data.filter((release) => release.date === row.original.date)]);
										} else {
											setUndoReleaseList([...undoReleaseList.filter((release) => !(release.date === row.original.date))]);
										}
									}}
								/>
							),
						},
						{
							header: 'Date Released',
							accessorKey: 'date',
							enableGlobalFilter: false,
							cell: ({ row }) => <span>{toFullDate(new Date(row.original.date.toLocaleString('en-US')))}</span>,
							sortingFn: (rowA, rowB) => {
								const a = dateToNumber(rowA.original.date.toLocaleString('en-US'));
								const b = dateToNumber(rowB.original.date.toLocaleString('en-US'));
								return b > a ? 1 : -1;
							},
						},
						{
							header: 'Day of Week',
							accessorKey: 'day_of_week',
							enableGlobalFilter: true,
							sortingFn: (rowA, rowB) => {
								const a = shortDOWToNumber(rowA.original.day_of_week);
								const b = shortDOWToNumber(rowB.original.day_of_week);
								return b > a ? 1 : -1;
							},
						},
						{
							accessorKey: 'date_released',
							header: 'Release Entry Date',
							enableGlobalFilter: true,
							cell: ({ row }) => <span>{toFullDate(new Date(row.original.date_released))}</span>,
						},
						{
							header: 'Number of Blocks Affected',
							accessorKey: 'number_of_blocks_released',
							enableGlobalFilter: true,
						},
					]}
					data={filteredData}
					disableRowCounter
					rowSubview={(row) => (
						<div className='flex'>
							<DataTable
								title=''
								disablePagination
								maxHeight='80'
								minimalStyle
								columns={[
									{
										header: 'Block Name',
										accessorKey: 'block_name',
									},
									{
										header: 'Released Minutes',
										accessorKey: 'released_minutes',
										cell: ({ row }) => {
											return `${row.original.released_minutes}m`;
										},
									},
									{
										header: 'Number of  Surgeons in Block',
										accessorKey: 'number_of_surgeons',
									},
								]}
								data={row.original.detail}
								disableRowCounter
							/>
						</div>
					)}
				/>
			</div>
			<div className='flex bg-blue-100 p-5 justify-between'>
				<div className='text-p3 pt-2 uppercase'>
					<p>{data && data.length > 0 ? `Total Bulk Released Days: ${data.length}` : ''}</p>
				</div>
				<div className='flex'>
					<Button
						variant='secondary'
						sizeX='sm'
						sizeY='sm'
						className='mr-5'
						onClick={() => {
							setDialogOpen(false);
						}}
					>
						Cancel
					</Button>
					<Button
						variant='primary'
						sizeX='sm'
						sizeY='sm'
						disabled={undoReleaseList.length === 0}
						onClick={async () => {
							const alertResponse = await getAlertResponse({
								description: `You are about to undo bulk release for ${undoReleaseList.length} day${
									undoReleaseList.length > 1 ? 's' : ''
								}. Do you want to proceed?`,
								responseOptions: [
									{
										value: '1',
										label: 'Yes',
									},
								],
								doNothingResponse: {
									value: '0',
									label: 'No',
								},
							});
							setUndoBulkReleaseConfirmed(alertResponse === '1' ?? false);
							if (alertResponse === '1') {
								undoBulkReleaseSubmit();
							}
						}}
						isWorking={undoStatus === 'pending' ? true : false}
					>
						Undo Release
					</Button>
				</div>
			</div>
		</Dialog>
	);
}

export default BlockSettings;

/**
 * Converts a boolean value (true/false) to 'on' or 'off' for use with the toggleGroup component
 * @param bool boolean value to be converted to 'on' or 'off'
 * @returns string
 */
export function convertSettingToToggleOption(bool: boolean | undefined) {
	return bool === true ? 'on' : 'off';
}

export function adjustDatesForDST(date: Date) {
	const dstOffset = januaryFirst.getTimezoneOffset();
	const dateOffset = date.getTimezoneOffset();

	// Adjust the date if DST is in effect
	if (dateOffset < dstOffset) {
		date.setHours(date.getHours() + 1);
	}

	// Return a new object with the adjusted date
	return date;
}
