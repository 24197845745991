import * as RadixDialog from '@radix-ui/react-dialog';
import classNames from 'classnames';

import { Button } from 'components';

import type { ArrayOf } from 'types';
import type { ButtonProps } from 'components';
import type { DialogProps as RadixDialogProps } from '@radix-ui/react-dialog';

export interface DialogProps extends RadixDialogProps {
	/** The preset width to apply to this dialog. */
	sizeX?: 'sm' | 'lg' | 'lg_wide';

	/** The title text to display at the top of this dialog. */
	title: string | React.ReactNode;

	//** Decides whether to display title or not. */
	noTitle?: boolean;

	//** Disabled primary button */
	disableFirstButton?: boolean;

	/** The subtitle text to accompany the title at the top of this dialog. */
	subtitle?: string;

	/** The element to use to trigger the opening of this dialog. */
	trigger: React.ReactNode;

	/** An array of `<Button>` prop objects representing actions to include at the bottom of this dialog. */
	actionButtons: ArrayOf<'at least', 0, DialogActionButtonProps>;

	/** If true, removes verticle line below title and compresses padding. */
	minimalHeaderStyle?: boolean;
}

type DialogActionButtonProps = Omit<ButtonProps, 'sizeX' | 'sizeY' | 'squareCorners'>;

/**
 * A content panel that slides out from the side of the screen.
 */
export function Dialog({
	sizeX,
	title,
	subtitle,
	noTitle,
	trigger,
	actionButtons,
	disableFirstButton,
	children,
	minimalHeaderStyle,
	...props
}: DialogProps) {
	return (
		<RadixDialog.Root {...props}>
			{trigger && <RadixDialog.Trigger asChild>{trigger}</RadixDialog.Trigger>}

			<RadixDialog.Portal>
				<RadixDialog.Overlay
					className={classNames(
						'fixed inset-0 bg-black/40 z-dialog',
						'data-open:animate-displayOverlay data-closed:animate-dismissOverlay'
					)}
				/>

				<div className='fixed inset-0 grid place-items-center p-6 z-dialog'>
					<RadixDialog.Content
						className={classNames(
							{
								'w-full max-w-sm': sizeX === 'sm',
								'w-full max-w-3xl': sizeX === 'lg',
								'w-[72em]': sizeX === 'lg_wide',
							},
							'bg-white rounded-lg',
							'data-open:animate-displayAlert data-closed:animate-dismissAlert'
						)}
					>
						<div className='border-t border-l border-r border-blue-500 rounded-t-lg'>
							{!noTitle && (
								<div
									className={classNames(
										{ 'border-b-0 pb-0': minimalHeaderStyle, 'h-20': subtitle, 'h-14': !subtitle },
										'flex items-center gap-3 h-20 py-2 pl-6 pr-4 border-b border-inherit'
									)}
								>
									<div>
										<RadixDialog.Title className='font-bold'>{title}</RadixDialog.Title>
										{subtitle && <div className='font-secondary text-p2 mt-2'>{subtitle}</div>}
									</div>

									<RadixDialog.Close className='ml-auto p-2'>
										<div className='material-symbol'>close</div>
									</RadixDialog.Close>
								</div>
							)}

							<div className={classNames({ 'p-7': !minimalHeaderStyle, 'p-0': minimalHeaderStyle }, 'basis-full')}>
								{children}
							</div>
						</div>

						<div className='flex flex-row-reverse mt-auto rounded-b-lg overflow-hidden'>
							{actionButtons.map(({ variant, children, ...props }, i) => {
								if (!variant) {
									if (i === 0) {
										variant = 'primary';
									} else if (i === 1) {
										variant = 'secondary';
									} else {
										variant = 'primary-ghost';
									}
								}

								return (
									<Button
										key={i}
										variant={variant}
										squareCorners
										disabled={i === 0 && disableFirstButton ? true : false}
										{...props}
									>
										{children}
									</Button>
								);
							})}
						</div>
					</RadixDialog.Content>
				</div>
			</RadixDialog.Portal>
		</RadixDialog.Root>
	);
}

export default Dialog;
